import { Divider, Segmented } from 'antd';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React from 'react';
import CustomSegmentedTab from './CustomSegmentedTab';
import { changeObjKey } from './DataCustomizer';

require('highcharts/indicators/indicators')(Highcharts)
require('highcharts/indicators/pivot-points')(Highcharts)
require('highcharts/indicators/macd')(Highcharts)
require('highcharts/modules/exporting')(Highcharts)
require('highcharts/modules/map')(Highcharts)
require('highcharts/indicators/vwap')(Highcharts)

window.Highcharts = Highcharts;

const PriceVolumeChart = ({ chartData = [], portfolioType = 0, defYrSelect = "", isOneDay = [] }) => {
    // portfolioType 1 = curated , 2 = custom, 0 = for individual funds
    // portfolioType = -1 => for showing only one amount value 
    const fundColors = ['#4284f3', '#fabc05', '#24c1e0', '#fa7b16', '#b74447']

    chartData = JSON.parse(chartData)
    var a = chartData !== null && chartData !== undefined ? Object.keys(chartData[0])?.filter((i) => !i.includes("ret_") && i.toLowerCase() !== "date" && i.toLowerCase() !== "50_sma" && i.toLowerCase() !== "200_sma" && i.toLowerCase() !== "vwap_adj_split" && i.toLowerCase() !== 'volume_adj') : []

    var finalChartData = {
        "1D": isOneDay,
        "365D": [...chartData].filter((item) => new Date(item?.Date) >= new Date().setFullYear(new Date().getFullYear() - 1)),
        "1095D": [...chartData].filter((item) => new Date(item?.Date) >= new Date().setFullYear(new Date().getFullYear() - 3)),
        "1825D": [...chartData].filter((item) => new Date(item?.Date) >= new Date().setFullYear(new Date().getFullYear() - 5)),
        "3650D": [...chartData].filter((item) => new Date(item?.Date) >= new Date().setFullYear(new Date().getFullYear() - 10)),
        "max": [...chartData]
    }

    console.log(finalChartData)
    const yrtabs = finalChartData !== undefined ? [
        { key: "AA", label: "1D", value: "1D", show: isOneDay !== undefined ? isOneDay.length > 1 : false },
        { key: "A", label: "1Y", value: "365D", show: finalChartData["365D"] !== undefined ? finalChartData?.["365D"].length > 0 : false },
        { key: "b", label: "3Y", value: "1095D", show: finalChartData["1095D"] !== undefined ? finalChartData?.["1095D"].length !== finalChartData?.["365D"].length : false },
        { key: "c", label: "5Y", value: "1825D", show: finalChartData["1825D"] !== undefined ? finalChartData?.["1825D"].length !== finalChartData?.["1095D"].length : false },
        { key: "d", label: "10Y", value: "3650D", show: finalChartData["3650D"] !== undefined ? finalChartData?.["3650D"].length !== finalChartData?.["1825D"].length : false },
        { key: "dm", label: "Max", value: "max", show: finalChartData["max"] !== undefined ? finalChartData?.["max"].length !== finalChartData?.["3650D"].length : false },
    ] : []

    var ohlc = [],
        volume = []

    const [chartType, setChartType] = React.useState(portfolioType === 1 ? defYrSelect || yrtabs[4]?.value : defYrSelect || yrtabs[0]?.value)

    console.log(chartType)
    var options = {
        chart: {
            height: 385 // 16:9 ratio
        },
        title: {
            text: ''
        },
        navigator: {
            enabled: true
        },
        legend: {
            enabled: false
        },

        exporting: {
            enabled: false
        },
        credits: {
            enabled: false
        },
        boost: {
            useGPUTranslations: true,
        },
        yAxis: [{
            visible: false,
            tickAmount: 5,
            gridLineDashStyle: 'longdash',
            title: {
                text: ''
            },
            gridLineColor: 'var(--border)',
            // height: '75%',
            resize: {
                enabled: true
            }
        }, {
            visible: false,
            tickAmount: 0,
            gridLineDashStyle: 'longdash',
            title: {
                text: ''
            },
            gridLineColor: 'var(--border)',
            top: '70%',
            height: '30%',
            offset: 0
        }],
        plotOptions: {
            areaspline: {
                threshold: null
            },
            series: {
                label: {
                    connectorAllowed: false
                },
                marker: {
                    enabled: false
                }
            }
        },
        xAxis: [{
            tickPixelInterval: 110,
            type: 'datetime',
            dateTimeLabelFormats: {
                millisecond: '%H:%M:%S.%L',
                second: '%H:%M:%S',
                minute: '%H:%M',
                hour: '%H:%M',
                day: '%eth %b',
                week: '%eth %b',
                month: '%b \'%y',
                year: '%Y'
            },
            lineColor: 'var(--border)',
            tickColor: 'var(--border)',
        }
        ],
        tooltip: {
            shared: true,

        },
        series: [{
            name: "Price",
            id: chartType === "1D" ? "LTP" : "price_adj_split",
            type: 'areaspline',
            yAxis: 0,
            turboThreshold: 9e9,
            color: fundColors[0],
            tooltip: {
                valueDecimals: 2,
                valuePrefix: '₹',
            },
            marker: {
                enabled: false
            },
            fillColor: {
                linearGradient: {
                    x1: 0,
                    x2: 0,
                    y1: 0,
                    y2: 1
                },
                stops: [
                    [0, fundColors[0] + "77"],
                    [0.1, fundColors[0] + "10"]
                ]
            },
            data: chartType === "1D" ? changeObjKey(JSON.parse(JSON.stringify(finalChartData?.[chartType])), ["LTP", "TimeStamp"], ["y", "x"], "stockLiveDaily", 100) : changeObjKey(JSON.parse(JSON.stringify(finalChartData?.[chartType])), ["price_adj_split", "Date"], ["y", "x"], "type", 1)
        }]
    };
    try {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', gap: '1rem', }}>
                <div style={{ flex: 1, display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <span>
                        <Divider style={{ margin: 0, marginBottom: "0.5rem" }} />
                        <CustomSegmentedTab
                            isBlock={false}
                            className='performanceChartYrSegmented'
                            style={{ backgroundColor: "transparent", padding: "0rem 0.5rem" }}
                            options={yrtabs}
                            value={chartType}
                            onChange={(e) => setChartType(e)}
                        />
                        <Divider style={{ margin: 0, marginTop: "0.5rem" }} />
                    </span>
                </div>
                <HighchartsReact highcharts={Highcharts} options={options} />
            </div>
        );
    } catch (error) {
        console.log(error)
        return <div className='textSM' style={{ margin: "4%", textAlign: "center" }}>
            No chart data available!
        </div>
    }
};
export default PriceVolumeChart;