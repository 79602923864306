import { Card } from 'antd';
import alphaAnalysis from 'Assets/images/illustrations/alpha.svg';
import compareImg from 'Assets/images/illustrations/compare.svg';
import createScreener from 'Assets/images/illustrations/newscreener.svg';
import ActivityLoaderMintbox from 'Components/ActivityLoaderMintbox';
import { CardHeader } from 'Components/atoms/SmallComponents';
import CustomSegmentedTab from 'Components/CustomSegmentedTab';
import DashboardChartComponent from 'Components/DashboardChartComponent';
import DashboardResearchTools from 'Components/DashboardResearchTools';
import DashboardTopPerformers from 'Components/DashboardTopPerformers';
import InstrumentListTable from 'Components/InstrumentListTable';
import RightTopContainerDashboard from 'Components/RightTopContainerDashboard';
import Head from 'Layout/Head';
import { useGetAssetType } from 'Libs/utils';
import React from "react";
import { useNavigate } from 'react-router';
import TopGainersAndLosers from 'Services/api/TopGainersAndLosers';
import StockSectors from './Sectors/StockSectors';

const Dashboard = (props) => {

  const navigate = useNavigate()
  const [loading, setLoading] = React.useState(true);
  const type = useGetAssetType()
  const researchTools = {
    1: {
      sectionHeader: '',
      children: [
        {
          UID: 'a',
          heading: "Create ETFs screen",
          content: "You can create the ETFs screen",
          onClick: () => {
            navigate(type.basePath + '/screener')
          },
          bgColor: 'var(--highestRisk)',
          icon: createScreener,
          show: true,
        },
        {
          UID: 'b',
          heading: "Compare ETFs",
          content: "You can compare the two or more ETFs screener",
          onClick: () => {
            navigate(type.basePath + '/compare')
          },
          bgColor: 'var(--highestRisk)',
          icon: compareImg,
          show: true,
        },
        {
          UID: 'c',
          heading: "ETFs alpha analysis",
          content: "You can check the ETFs Alpha Analysis",
          onClick: () => {
            navigate(type.basePath + '/alpha-analysis')
          },
          bgColor: 'var(--highestRisk)',
          icon: alphaAnalysis,
          show: true,
          ribbon: 'comming soon'
        }
      ]
    },
    2: {
      sectionHeader: '',
      children: [
        {
          UID: 'a',
          heading: "Create mutual fund screen",
          content: "Content  content content content content",
          onClick: () => {
            navigate(type.basePath + '/screener')
          },
          bgColor: 'var(--highestRisk)',
          icon: createScreener,
          show: true,
        },
        {
          UID: 'b',
          heading: "Compare mutual funds",
          content: "Content  content content content content",
          onClick: () => {
            navigate(type.basePath + '/compare')
          },
          bgColor: 'var(--highestRisk)',
          icon: compareImg,
          show: true,
        },
        {
          UID: 'c',
          heading: "Mutual fund alpha analysis",
          content: "Content  content content content content",
          onClick: () => {
            navigate(type.basePath + '/alpha-analysis')
          },
          bgColor: 'var(--highestRisk)',
          icon: alphaAnalysis,
          show: true,
          ribbon: 'comming soon'
        }
      ]
    },
    4: {
      sectionHeader: '',
      children: [
        {
          UID: 'a',
          heading: "Create stock screen",
          content: "Content  content content content content",
          onClick: () => {
            navigate(type.basePath + '/screener')
          },
          bgColor: 'var(--highestRisk)',
          icon: createScreener,
          show: true,
        },
        {
          UID: 'aa',
          heading: "Create stock signals",
          content: "Content  content content content content",
          onClick: () => {
            navigate(type.basePath + '/signal')
          },
          bgColor: 'var(--highestRisk)',
          icon: createScreener,
          show: true,
        },
        {
          UID: 'b',
          heading: "Compare Stocks",
          content: "Content  content content content content",
          onClick: () => {
            navigate(type.basePath + '/compare')
          },
          bgColor: 'var(--highestRisk)',
          icon: compareImg,
          show: true,
        },
        {
          UID: 'c',
          heading: "Stock StyleBoxes",
          content: "Content  content content content content",
          onClick: () => {
            navigate(type.basePath + '/styleBox')
          },
          bgColor: 'var(--highestRisk)',
          icon: alphaAnalysis,
          show: true,
        },
        {
          UID: 'aaa',
          heading: "Sectors",
          content: "Content  content content content content",
          onClick: () => {
            navigate(type.basePath + '/sectors')
          },
          bgColor: 'var(--highestRisk)',
          icon: createScreener,
          show: true,
        },
        {
          UID: 'f',
          heading: "Custom stock portfolio",
          content: "Content  content content content content",
          onClick: () => {
            navigate('/stocks/stock-portfolio')
          },
          bgColor: 'var(--highestRisk)',
          icon: compareImg,
          show: true,
        }
      ]
    },
    5: {
      sectionHeader: '',
      children: [
        {
          UID: 'd',
          heading: "Sectors",
          content: "Content  content content content content",
          onClick: () => {
            navigate(type.basePath + '/sectors')
          },
          bgColor: 'var(--highestRisk)',
          icon: alphaAnalysis,
          show: true,
        },
        {
          UID: 'e',
          heading: "Stock Signals",
          content: "Content  content content content content",
          onClick: () => {
            navigate(type.basePath + '/sectors')
          },
          bgColor: 'var(--highestRisk)',
          icon: createScreener,
          show: true,
        },
        {
          UID: 'f',
          heading: "Custom strategies",
          content: "Content  content content content content",
          onClick: () => {
            navigate('/sectors')
          },
          bgColor: 'var(--highestRisk)',
          icon: compareImg,
          show: true,
        }
      ]
    },
  }
  const mf_magic_items = [
    {
      label: `Mutual funds`,
      value: '1',
      key: '1',
      children: <span>
        <InstrumentListTable
          tableType={0}
          isCard={false}
          showCategoryDropDown={true}
          magicBoardtype={"mf"}
          tableHeader={'Magicboard'}
          paginationSize={11}
        />
      </span>,
    },
    {
      label: `Index funds`,
      value: '2',
      key: '2',
      children: <span>
        <InstrumentListTable
          tableType={0}
          isCard={false}
          showCategoryDropDown={true}
          defFilters={[
            ["is_index_fund", 1],
          ]}
          magicBoardtype={"index"}
          tableHeader={'Magicboard'}
          paginationSize={11}
        />
      </span>,
    },
    {
      label: `FOFs`,
      value: '3',
      key: '3',
      children: <span>
        <InstrumentListTable
          isCard={false}
          tableType={0}
          showCategoryDropDown={true}
          defFilters={[
            ["is_fof", 1],
          ]}
          magicBoardtype={"mf"}
          tableHeader={'Magicboard'}
          paginationSize={11}
        />
      </span>,
    },
  ]

  const [selectedTab, setselectedTab] = React.useState(mf_magic_items.filter((i) => i !== false)[0]?.value || '');

  // const dum = async () => {
  //   var screener_datas = await screenerMetricData(type)
  //   // let a = screener_datas?.allFunds
  //   // setAllFunds(a)
  //   setStockSectorCLF(screener_datas?.fabricatedData?.stockSectorCLF)
  //   setLoading(false)
  // }
  React.useEffect(() => {
    setLoading(false)
  }, [type])

  if (loading) {
    return <React.Fragment>
      <div className='mainContainer' style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
        <ActivityLoaderMintbox />
      </div>
    </React.Fragment>
  }



  return (
    <React.Fragment>
      <Head title="Dashboard" />

      <div className='mainContainer'>
        <div className='leftContainer'>
          {/* market status and alert cta */}
          {/* <div className='d-flex align-items-center'>
            <span className='textMD dark3' style={{ textTransform: 'uppercase', flex: 1 }}>{liveMarketStatus ? "Markets are live" : "Market opens at 09:15 am tomorrow"}</span>
            <button className='btnSecondary'>
              Create new alert
            </button>
          </div> */}

          {/* market watch card */}
          <DashboardChartComponent type={type} header={"Markets"} />
          <DashboardResearchTools data={researchTools[type.value]} />
          <span style={{ position: "relative" }}>
            {
              type.value === 2 && <Card>

                <span>
                  <CardHeader heading={'Magicboard'} />
                  <InstrumentListTable
                    tableType={0}
                    isCard={false}
                    hideCheckBox={true}
                    defFilters={selectedTab === '1' ? [
                      ["is_index_fund", 0],
                      ["is_fof", 0]
                    ] : selectedTab === '2' ? [
                      ["is_index_fund", 1]
                    ] : [
                      ["is_fof", 1]
                    ]}
                    showCategoryDropDown={true}
                    magicBoardtype={selectedTab === '2' ? "index" : "mf"}
                    hideHeaderBlueLine={true}
                    tableHeader={<CustomSegmentedTab
                      isBlock={false}
                      value={selectedTab}
                      onChange={(e) => {
                        setselectedTab(e)
                      }}
                      options={mf_magic_items}
                    />}
                    paginationSize={11}
                  />
                </span>
                {/* {mf_magic_items.filter((i) => i.value === selectedTab)[0]?.children} */}
              </Card>
            }
            {
              type.value === 1 && <InstrumentListTable
                tableType={0}
                hideCheckBox={true}
                showCategoryDropDown={true}
                tableHeader={'ETFs magicboard'}
                paginationSize={11}
              />
            }
            {type.value === 4 && <StockSectors paginationSize={11} />}
          </span>
        </div>
        <div className='rightContainer'>
          <RightTopContainerDashboard type={type} />
          {
            type.value === 1 ? <DashboardTopPerformers header={<CardHeader heading={'Top performers'} />} type={type} />
              : type.value === 2 ? <DashboardTopPerformers header={<CardHeader heading={'Top equity funds'} />} type={type} filter={[['primary_category_name', 'Equity']]} />
                : type.value === 4 ? <DashboardTopPerformers header={<CardHeader heading={'Market watch'} />} type={type} dropDown={0}
                  allFunds={[{ proper_name: 'Nifty 50', symbol: 'Nifty 50' }, { proper_name: 'Nifty Bank', symbol: 'Nifty Bank' }, { proper_name: 'India VIX', symbol: 'INDIA VIX' }, { proper_name: 'Nifty Fin Service', symbol: 'Nifty Fin Service' }, { proper_name: 'Nifty IT', symbol: 'Nifty IT' }]} />
                  : <></>
          }
          {type.value !== 2 ? <TopGainersAndLosers type={type} /> : <></>}
          {type.value !== 2 ? <TopGainersAndLosers type={type} format='bot' /> : <></>}
        </div>
      </div>
    </React.Fragment >
  );
};

export default React.memo(Dashboard);
