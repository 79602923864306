import { Card, Cascader, DatePicker, Divider, Drawer, Input, InputNumber, Modal, Popover, Select, Switch, Table, Tabs, Tag, Timeline } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import ActivityLoaderMintbox from 'Components/ActivityLoaderMintbox';
import { CardHeader } from 'Components/atoms/SmallComponents';
import { CurrencyFormat } from 'Components/CurrencyFormat';
import { changeObjKey } from 'Components/DataCustomizer';
import { convertAnyDatetoDDMMYYYY, formatDate } from 'Components/DateFormatter';
import { DecimalValueFormat } from 'Components/DecimalValueFormat';
import InstrumentListTable from 'Components/InstrumentListTable';
import PerformanceChart2 from 'Components/PerformanceChart2';
import dayjs from 'dayjs';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsHeatmap from "highcharts/modules/heatmap";
import Head from 'Layout/Head';
import { toggleloginModal } from 'Libs/redux/UserAuth/reduxSlice';
import { inputBoxFormatter, inputBoxParser, investingStylelist, perFilterValues, quantile, queries, risk_box_codes, screenerMetricData, sortFunds, sortOptions, stock_universe, useGetAssetType } from 'Libs/utils';
import BuildCustomPortfolio from 'Pages/CustomPortfolio';
import React, { useState } from 'react';
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { FaFilter } from 'react-icons/fa';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { IoIosArrowForward, IoIosClose, IoIosCloseCircleOutline } from 'react-icons/io';
import { MdArrowDropDown, MdDragIndicator } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import ShowMoreText from "react-show-more-text";
import { toast } from 'react-toastify';
import { Col, Collapse, Row } from 'reactstrap';
import apiHelper from 'Services/api/ApiHelper';
import apiHelperPY from 'Services/api/ApiHelperPY';

HighchartsHeatmap(Highcharts)

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    margin: "0.5rem 0rem",
    borderRadius: 5,
    ...draggableStyle
});

const getListStyle = (isDraggingOver) => ({
    borderRadius: 5,
    display: "flex",
    flexDirection: "column"
});

const Screener = (props) => {

    const navigate = useNavigate()
    const dispatch = useDispatch();
    const { state } = useLocation()
    const type = useGetAssetType()
    var user = useSelector((state) => state?.updateUserData?.value)

    var currentCheckListId = state?.screener_final_query?.[0]?.checklist_id || 0
    var currentCheckListName = state?.screener_final_query?.[0]?.checklist_name || ""
    var currentCheckListDesc = state?.screener_final_query?.[0]?.checklist_desc || ""

    const [saveAsChecklist, setsaveAsChecklist] = useState(true)
    const [alertsEnabled, setalertsEnabled] = useState(true)
    const [screenerMetric, setscreenerMetric] = useState([])
    const [screenerFinalData, setscreenerFinalData] = useState([])
    const [styleBoxes, setStyleBoxes] = React.useState([]);
    const [allFilteredFundsData, setallFilteredFundsData] = useState([])
    const [allFundsData, setallFundsData] = useState([])
    const [selectedCategory, setselectedCategory] = useState("")
    const [selectedMetric, setselectedMetric] = useState("")
    const [screener_final_query, set_screener_final_query] = useState(state?.screener_final_query || [])
    const [screener_name, setscreener_name] = useState(currentCheckListName)
    const [screener_desc, setscreener_desc] = useState(currentCheckListDesc)
    const [newFiltersList, setnewFiltersList] = useState([])
    const [checkFiltersList, setcheckFiltersList] = useState([])
    const [mfCategories, setmfCategories] = useState([])
    const [stock_macro_sectors, setMacroSectors] = useState([])
    const [stock_sectors, setSectors] = useState([])
    const [stock_industries, setIndustries] = useState([])
    const [stock_basic_industries, setBasicIndustries] = useState([])
    const [loading, setloading] = useState(true)
    const [modal, setmodal] = useState(false)
    const [modalSave, setmodalSave] = useState(false)
    const [backTestModal, setbackTestModal] = useState(false)
    const [investInScreenerModal, setinvestInScreenerModal] = useState(false)
    const [backTestModalLoading, setbackTestModalLoading] = useState(true)
    const [backTestData, setBackTestData] = useState({})
    const [metricbackTestRes, setMetricbackTestRes] = useState({})
    const [metricbackTestResName, setMetricbackTestResName] = useState('')
    const [toggleMetricBackTest, settoggleMetricBackTest] = useState(false)
    const [tableColumns, setTableColumns] = useState([])
    const [appliedMetrics, setappliedMetrics] = useState([])
    const [instrumentList, setinstrumentList] = React.useState([]);
    const [selectedStockUniverse, setselectedStockUniverse] = useState([])
    const [selectedStockUniverseMetricBack, setselectedStockUniverseMetricBack] = useState([])
    const [excludeSurveillance, setexcludeSurveillance] = useState(true)
    const [popopen, setpopOpen] = useState();

    var selectedm = screenerMetric.filter((i) => i.metric_col_code === selectedMetric)[0]

    const metric_data = (key, value) => {
        return screenerMetric.filter((met) => met[key] === value)[0]
    }

    const convertScreenerQueryToNormal = () => {
        var categories = mfCategories
        var current_q = [...screener_final_query]
        var final_q = []

        final_q = sortFunds(current_q, 'pos', 'bot').map((query) => {
            query = { ...metric_data('metric_col_code', query.primary_col), ...query }
            var fquery = {
                metric_col_code: query.primary_col
            }
            if (query.oper === "abs_comp") {
                //abs
                console.log(query)
                if (query.metric_data_type === "boolean") {
                    if (stock_universe.map((i) => i.value).includes(query.primary_col) && type.value === 4) {
                        setselectedStockUniverse([query.primary_col])
                    } else if (query.primary_col === 'is_exclusion_list') {
                        setexcludeSurveillance(Number(query.abs_val_1) === 0 ? true : false)
                    } else {
                        fquery = {
                            ...fquery,
                            queryValue: Number(query.abs_val_1) === 1 ? "yes" : "no"
                        }
                    }
                } else {
                    fquery = {
                        ...fquery,
                        value: Number(query.abs_val_1),
                        queryValue: query.comp_operator
                    }
                }
            } else if (query.oper === "rank_comp") {
                //rank per or abs
                var a = query.comp_operator.split('_')
                //a[0] => top or bot (queryValue)
                //a[2] => per or abs
                //a[1] => 25,50,75,100 => a[1]/100 => if per => pervalue
                //a[1] => 25,50,75,100 => a[1] => if abs => value => top 25 stocks
                if (a[2] === 'per') {
                    fquery = {
                        ...fquery,
                        pervalue: String(Number(a[1]) / 100),
                        queryValue: 'in',//default
                        filter: a[0]
                    }
                } else {
                    // fquery = {
                    //     ...fquery,
                    //     value: query.abs_val_1,
                    //     queryValue: query.comp_operator
                    // }
                }
            } else if (query.oper === "col_comp") {
                //compare other column
            } else if (query.oper === "rel_comp") {
                //rel with other table
            } else if (query.oper === "isin_comp") {
                //in arr
                if (query.primary_col === "category_id") {
                    var sub_category_id = query.abs_val_1.split(",")
                    var fi = []
                    categories.map((i, index) => {
                        var ak = []
                        if (i["subCategories"] !== undefined) {
                            i["subCategories"]?.map((ii, indexi) => {
                                if (sub_category_id.includes(String(ii.categoryId))) {
                                    ak = [i.categoryName, String(ii.categoryId)]
                                    fi.push(ak)
                                }
                            })
                        }
                    })
                    fquery = {
                        ...fquery,
                        queryValue: 'isOne',//default
                        value: fi
                    }
                } else {
                    fquery = {
                        ...fquery,
                        queryValue: 'isOne',//default
                        value: query.abs_val_1.split(",")
                    }
                }
            } else if (query.oper === "isnotin_comp") {
                //not arr
                if (query.primary_col === "category_id") {
                    var sub_category_id = query.abs_val_1.split(",")
                    var fi = []
                    categories.map((i, index) => {
                        var ak = []
                        if (i["subCategories"] !== undefined) {
                            i["subCategories"]?.map((ii, indexi) => {
                                if (sub_category_id.includes(String(ii.categoryId))) {
                                    ak = [i.categoryName, String(ii.categoryId)]
                                    fi.push(ak)
                                }
                            })
                        }
                    })
                    fquery = {
                        ...fquery,
                        queryValue: 'notOne',//default
                        value: fi
                    }
                } else {
                    fquery = {
                        ...fquery,
                        queryValue: 'notOne',//default
                        value: query.abs_val_1.split(",")
                    }
                }
            } else {
                // issue
            }


            return fquery
        })

        setnewFiltersList(final_q.filter((query) => !(stock_universe.map((i) => i.value).includes(query.metric_col_code)) && "is_exclusion_list" !== query.metric_col_code))

    }

    const convertNormalToScreenerQuery = () => {

        var initalQueries = []
        if (type.value === 4 && selectedStockUniverse[0] !== "all") {
            initalQueries.push({
                "screener_type": type.strVal,
                "pos": 1,
                "oper": 'abs_comp',
                "primary_col": selectedStockUniverse[0],
                "sec_col": null,
                "adj_operator": null,
                "abs_val_1": 1,
                "abs_val_2": null,
                "comp_operator": 'eq',
                "rel_comp_stat": null,
                "rel_comp_name": null,
                "rule_name": stock_universe.filter((i) => i.value === selectedStockUniverse[0])[0]?.label
            })
        }

        if (type.value === 4 && excludeSurveillance === true) {
            initalQueries.push({
                "screener_type": type.strVal,
                "pos": initalQueries.length + 1,
                "oper": 'abs_comp',
                "primary_col": 'is_exclusion_list',
                "sec_col": null,
                "adj_operator": null,
                "abs_val_1": 0,
                "abs_val_2": null,
                "comp_operator": 'eq',
                "rel_comp_stat": null,
                "rel_comp_name": null,
                "rule_name": 'Exclude Surveillance stocks'
            })
        }


        var final = newFiltersList.map((rule, index) => {
            console.log(rule)
            rule = { ...metric_data('metric_col_code', rule?.metric_col_code), ...rule }
            var finalRule = {
                "screener_type": type.strVal,
                "pos": index + 2,
                "oper": null,
                "primary_col": rule?.metric_col_code || null,
                "sec_col": null,
                "adj_operator": null,
                "abs_val_1": null,
                "abs_val_2": null,
                "comp_operator": null,
                "rel_comp_stat": null,
                "rel_comp_name": null,
                "rule_name": rule?.metric_name || null
            }
            if (rule?.queryValue === 'isOne' || rule?.queryValue === 'notOne') {
                //"isin_comp" , "isnotin_comp"
                finalRule = {
                    ...finalRule,
                    "oper": rule?.queryValue === 'isOne' ? "isin_comp" : "isnotin_comp",
                    "abs_val_1": rule?.value?.join(",") || null,
                }
            } else if (rule?.queryValue === 'in') {
                //"rank"
                finalRule = {
                    ...finalRule,
                    "oper": "rank_comp",
                    "comp_operator": (rule?.filter || rule?.direction_flag
                    ) + "_" + Number(rule?.pervalue) * 100 + "_" + "per" || null,
                }
            } else if (rule?.queryValue === 'gt' || rule?.queryValue === 'lt' || rule?.queryValue === 'gteq' || rule?.queryValue === 'lteq') {
                //abs
                finalRule = {
                    ...finalRule,
                    "oper": "abs_comp",
                    "abs_val_1": String(rule?.value) || null,
                    "comp_operator": rule?.queryValue || null,
                }
            } else if (rule?.queryValue === 'yes' || rule?.queryValue === 'no') {
                //bool
                finalRule = {
                    ...finalRule,
                    "oper": "abs_comp",
                    "abs_val_1": String(rule?.queryValue === 'yes' ? 1 : 0) || null,
                    "comp_operator": 'eq',
                }
            }

            return finalRule

        })

        final = [...initalQueries].concat(final)

        console.log(final)
        set_screener_final_query(final)
        return final


    }

    const saveScreener = async () => {
        if (user !== undefined && user !== null) {
            if (screener_name.length > 0) {
                var screener = {
                    "ClientId": user.id,
                    "ShowAsScreen": 1,
                    "ShowAsChecklist": saveAsChecklist === true ? 1 : 0,
                    "IsAlertsEnabled": saveAsChecklist === true ? 1 : 0,
                    "ChecklistId": currentCheckListId,
                    "ScreenerName": screener_name,
                    "Description": screener_desc,
                    "InstrumentType": type.strVal,
                }

                var savedScreener = await apiHelper({ apiName: 'saveScreener', data: screener })
                console.log(savedScreener)
                if (savedScreener[0]?.checklist_id !== undefined) {

                    var final = convertNormalToScreenerQuery()
                    console.log(final)

                    final.map(async (rule) => {
                        var saverule = await apiHelper({ apiName: 'saveScreenerRule', data: JSON.stringify({ ...rule, "client_id": user.id, "rule_id": 0, "checklist_id": savedScreener[0]?.checklist_id }) })
                        console.log(saverule)
                    })
                    toast.success("Screen saved")
                    let screeners = await apiHelper({ apiName: "getUserScreeners", saveResponse: "sessionStorage", data: { id: user.id, type: type.omkar }, getFresh: true });
                    setmodalSave(false)
                } else {
                    toast.error("Something went wrong. Try again later!!")
                }

            } else {
                toast.error("Please enter screen name")
            }
        } else {
            toast.error("Please login first")
            dispatch(toggleloginModal(true))
        }


    }

    const loadData = async () => {
        var screener_datas = await screenerMetricData(type)
        setselectedCategory(screener_datas?.levelVals?.m_category_id)
        setStyleBoxes(screener_datas?.fabricatedData?.stockStyles)
        setMacroSectors(screener_datas?.fabricatedData?.macroSectors)
        setSectors(screener_datas?.fabricatedData?.sectors)
        setIndustries(screener_datas?.fabricatedData?.industries)
        setBasicIndustries(screener_datas?.fabricatedData?.basicIndustries)
        setmfCategories(screener_datas?.fabricatedData?.mf_categories)
        setscreenerMetric(screener_datas?.screenerMetricData)
        setscreenerFinalData(screener_datas?.screenerFinalData)
        setallFundsData(screener_datas?.allFunds)
        setallFilteredFundsData(screener_datas?.allFunds)
        setselectedStockUniverse([stock_universe[0].value])
        setselectedStockUniverseMetricBack([stock_universe[1].value])
        setloading(false)
        setpopOpen(false)
    }

    const applyFilters = () => {
        var upDatedFilters = [...newFiltersList]
        var allFunds = JSON.parse(JSON.stringify(allFundsData))
        var excludedKeys = [
            "clientId", "screener_name", "is_fof", "is_index_fund", "category_id", "investing_style", "is_etf_fund"
        ]
        var finalTableColumns = []

        if (selectedStockUniverse.length !== 0 && selectedStockUniverse[0] !== 'all' && type.value === 4) {
            allFunds = [...allFunds].filter((i) => i[selectedStockUniverse[0]] === 1)
        }

        if (type.value === 4 && excludeSurveillance === true) {
            allFunds = [...allFunds].filter((i) => i['is_exclusion_list'] === 0)
        }

        upDatedFilters.map((data) => {
            var currentMetricData = { ...metric_data('metric_col_code', data.metric_col_code), ...data }

            var floatType = currentMetricData.metric_data_type === "float" && currentMetricData.show_percentile === 1 && currentMetricData.show_absolute === 1 ? "top" :
                currentMetricData.metric_data_type === "float" && currentMetricData.show_percentile === 1 ? "bot" : null

            var queriesFinal = currentMetricData.metric_data_type === "string" ? queries[0] : currentMetricData.metric_data_type === "boolean" ? queries[3] : (currentMetricData.metric_data_type === "Date" || currentMetricData.metric_data_type === "date") ? queries[4] :
                floatType === "top" ? queries[1]?.concat(queries[2]) :
                    floatType === "bot" ? queries[1] :
                        floatType === null ? queries[2] : queries[0]


            if (currentMetricData.queryValue === undefined) {
                currentMetricData = {
                    ...data,
                    queryValue: queriesFinal[0]?.value
                }

                data = {
                    ...data,
                    queryValue: queriesFinal[0]?.value
                }
            }

            if (allFunds?.length !== 0) {

                if (!excludedKeys.includes(currentMetricData.metric_col_code)) {
                    finalTableColumns.push(currentMetricData)
                }
                if (Array.isArray(data.value)) {
                    if (Array.isArray(data.value[0])) {
                        //multiselect
                        var str = []
                        if (currentMetricData.metric_col_code === "category_id") {
                            data.value.map((valfilter) => {
                                if (valfilter.length === 2) {
                                    str.push(`fund['category_id'] ${currentMetricData.queryValue === "isOne" ? "===" : "!=="} ${Number(valfilter[1])}`)
                                } else {
                                    str.push(`fund['primary_category_name'] ${currentMetricData.queryValue === "isOne" ? "===" : "!=="} "${String(valfilter[0])}"`)
                                }
                            })

                            allFunds = allFunds.filter((fund) => eval(str.join(currentMetricData.queryValue === "isOne" ? " || " : " && ")))
                        }
                    } else {
                        //select
                        if (currentMetricData.metric_col_code === "investing_style") {
                            var str = []
                            if (currentMetricData.value !== undefined && currentMetricData?.value?.length !== undefined && currentMetricData?.value?.length !== 0) {
                                if (currentMetricData.value[0] !== "all") {
                                    currentMetricData.value.map((fItem) => {
                                        str.push(`fund['${String(fItem)}'] ${currentMetricData.queryValue === "isOne" ? "===" : "!=="} 1`)
                                    })
                                    allFunds = allFunds.filter((fund) => eval(str.join(currentMetricData.queryValue === "isOne" ? " || " : " && ")))
                                }
                            }
                        } else {
                            var str = []
                            if (currentMetricData.value !== undefined && currentMetricData?.value?.length !== undefined && currentMetricData?.value?.length !== 0) {

                                currentMetricData.value.map((fItem) => {
                                    str.push(`String(fund['${String(currentMetricData.metric_col_code)}']) ${currentMetricData.queryValue === "isOne" ? "===" : "!=="} '${String(fItem)}'`)
                                })
                                allFunds = allFunds.filter((fund) => eval(str.join(currentMetricData.queryValue === "isOne" ? " || " : " && ")))

                            }
                        }
                    }
                } else if (currentMetricData.metric_data_type === "float") {
                    //float
                    var filterVal = currentMetricData.filter
                    if (currentMetricData.direction_flag !== null || currentMetricData.direction_flag !== undefined) {
                        if (currentMetricData.filter !== undefined) {
                            allFunds = sortFunds(allFunds, currentMetricData.metric_col_code, currentMetricData.filter)
                        } else {
                            filterVal = currentMetricData.direction_flag
                            allFunds = sortFunds(allFunds, currentMetricData.metric_col_code, currentMetricData.direction_flag)
                        }
                    }

                    if (currentMetricData?.queryValue === "in") {
                        var list = allFunds.map(function (d) {
                            return d[currentMetricData.metric_col_code]
                        })

                        if (list.length > 0) {
                            var val = quantile(list, Number(currentMetricData?.pervalue))
                            allFunds = allFunds.filter((fund, index) => eval(`Number(fund[currentMetricData.metric_col_code]) ${filterVal === "top" ? '>=' : '<='} ${Number(val)}`))
                        }

                    } else if (currentMetricData?.queryValue === "gt" || currentMetricData?.queryValue === "lt" || currentMetricData?.queryValue === "gteq" || currentMetricData?.queryValue === "lteq") {
                        allFunds = allFunds.filter((fund) =>
                            eval(`fund[currentMetricData.metric_col_code] ${currentMetricData?.queryValue === "gt" ? '>' : currentMetricData?.queryValue === "gteq" ? '>=' : currentMetricData?.queryValue === "lt" ? '<' : '<='} ${currentMetricData.value}`))
                    } else {

                    }
                } else if (currentMetricData.metric_data_type === "Date" || currentMetricData.metric_data_type === "date") {
                    //Date
                    if (currentMetricData.value !== undefined && currentMetricData?.value?.length !== undefined && currentMetricData?.value?.length !== 0) {
                        allFunds = allFunds.filter((fund) => {
                            if (currentMetricData.queryValue === "gt") {
                                return new Date(Number(fund[currentMetricData.metric_col_code])) >= new Date(Number(currentMetricData.value) / 10)
                            } else {
                                return new Date(Number(fund[currentMetricData.metric_col_code])) < new Date(Number(currentMetricData.value) / 10)
                            }
                        })
                    }
                } else if (currentMetricData.metric_data_type === "boolean") {
                    //boolean
                    if (currentMetricData.metric_col_code === "exit_load") {
                        allFunds = allFunds.filter((fund, index) => eval(`Number(fund[currentMetricData.metric_col_code]) ${currentMetricData.queryValue === "yes" ? "===" : "!=="} 0`))
                    } else {
                        allFunds = allFunds.filter((fund, index) => eval(`fund[currentMetricData.metric_col_code] ${currentMetricData.queryValue === "yes" ? "===" : "!=="} 1`))
                    }
                }
            }
        })
        setallFilteredFundsData([...allFunds])
        setappliedMetrics(finalTableColumns)
        setTableColumns(finalTableColumns)
    }

    const performBackTest = async () => {
        setbackTestModal(!backTestModal)
        setbackTestModalLoading(true)
        var final = [...newFiltersList].map((rule, index) => {
            var metricData = metric_data("metric_col_code", rule?.metric_col_code)
            var finalRule = {
                "rule_id": index + 1,
                "client_id": 0,
                "checklist_id": 0,
                "pos": index + 1,
                "oper": null,
                "primary_col": rule?.metric_col_code,
                "primary_col_table": "S",
                "sec_col": null,
                "adj_operator": null,
                "sec_col_table": null,
                "abs_val_1": null,
                "abs_val_2": null,
                "comp_operator": null,
                "rel_comp_stat": null,
                "rel_comp_name": null,
                "rule_name": rule?.metric_col_code,
                "is_active": 1,
            }
            if (rule?.queryValue === 'isOne' || rule?.queryValue === 'notOne') {
                //"isin_comp" , "isnotin_comp"
                finalRule = {
                    ...finalRule,
                    "oper": rule?.queryValue === 'isOne' ? "isin_comp" : "isnotin_comp",
                    "abs_val_1": rule?.value.join(","),
                }
            } else if (rule?.queryValue === 'in') {
                //"rank"
                finalRule = {
                    ...finalRule,
                    "oper": "rank_comp",
                    "comp_operator": (rule?.filter || metricData.direction_flag) + "_" + Number(rule?.pervalue) * 100 + "_" + "per",
                }
            } else if (rule?.queryValue === 'gt' || rule?.queryValue === 'lt' || rule?.queryValue === 'gteq' || rule?.queryValue === 'lteq') {
                //abs
                finalRule = {
                    ...finalRule,
                    "oper": "abs_comp",
                    "abs_val_1": String(rule?.value),
                    "comp_operator": rule?.queryValue,
                }
            } else if (rule?.queryValue === 'yes' || rule?.queryValue === 'no') {
                //bool
                finalRule = {
                    ...finalRule,
                    "oper": "abs_comp",
                    "abs_val_1": String(rule?.queryValue === 'yes' ? 1 : 0),
                    "comp_operator": 'eq',
                }
            }

            return finalRule

        })
        var backTestData = await apiHelperPY({
            apiName: 'getScreenerBacktestData', data: {
                rules: final
            }
        })
        backTestData = await JSON.parse(backTestData.replace(/NaN/g, null))
        setBackTestData(backTestData)
        setbackTestModalLoading(false)
    }

    const getScreenerMetricBackTest = async (metric_col_code) => {
        if (metric_col_code === "style_box_code") {
            let backTestRes = await apiHelperPY({ apiName: 'getSharpelyStyleBoxBacktest' })
            backTestRes = await JSON.parse(backTestRes?.replace(/NaN/g, null))
            setMetricbackTestRes(backTestRes?.prices)
            setMetricbackTestResName(metric_col_code)
        } else {
            if (metric_col_code !== undefined) {
                var backTestRes = await apiHelperPY({ apiName: 'getScreenerMetricBacktestData', data: { metric_code: metric_col_code, universe_code: selectedStockUniverseMetricBack[0] } })
                backTestRes = await JSON.parse(backTestRes?.replace(/NaN/g, null))
                setMetricbackTestRes(backTestRes?.prices)
                setMetricbackTestResName(metric_col_code)
            }
        }
    }

    const MetricFilterView = ({ data, index, viewType = 0 }) => {
        //viewType = 0 => normal filter view , 1 => drawer view
        var upDatedFilters = []

        if (viewType === 0) {
            //for screen filters view we are using final filter i.e newFiltersList
            upDatedFilters = [...newFiltersList]
        } else {
            //for drawer (add rule) filters view we are using checked filter i.e checkFiltersList
            upDatedFilters = [...checkFiltersList]
        }

        var currentMetricData = { ...metric_data('metric_col_code', data.metric_col_code), ...data }

        var floatType = currentMetricData.metric_data_type === "float" && currentMetricData.show_percentile === 1 && currentMetricData.show_absolute === 1 ? "top" :
            currentMetricData.metric_data_type === "float" && currentMetricData.show_percentile === 1 ? "bot" : null

        var queriesFinal = currentMetricData.metric_data_type === "string" ? queries[0] : currentMetricData.metric_data_type === "boolean" ? queries[3] : (currentMetricData.metric_data_type === "Date" || currentMetricData.metric_data_type === "date") ? queries[4] :
            floatType === "top" ? queries[1]?.concat(queries[2]) :
                floatType === "bot" ? queries[1] :
                    floatType === null ? queries[2] : queries[0]


        var level1Width = currentMetricData.metric_data_type === "string" ? '7rem' : currentMetricData.metric_data_type === "boolean" ? '6rem' : (currentMetricData.metric_data_type === "Date" || currentMetricData.metric_data_type === "date") ? '8rem' :
            floatType === "top" ? '7rem' :
                floatType === "bot" ? '7rem' :
                    floatType === null ? '10rem' : '7rem'

        const setFinalData = (data) => {
            console.log(data, viewType)
            if (viewType === 0) {
                setnewFiltersList(data)
            } else {
                setcheckFiltersList(data)
            }
        }

        const onQueryValueChange = (queryValue) => {
            upDatedFilters[index] = {
                ...data,
                queryValue: queryValue
            }

            setFinalData(upDatedFilters)
        }


        const FilterViewLevel1 = () => {
            const onValueChange = (value) => {
                if (Array.isArray(value) && value.length !== 0) {
                    if (value[value.length - 1] === "all") {
                        value = ["all"]
                    } else {
                        if (value.length > 1 && value[0] === "all") {
                            value.splice(0, 1)
                        }
                    }
                    upDatedFilters[index] = {
                        ...data,
                        value: value
                    }
                    setFinalData(upDatedFilters)
                } else {
                    upDatedFilters[index] = {
                        ...data,
                        value: value
                    }
                    setFinalData(upDatedFilters)
                }
            }

            // etf anf mf special cases
            if (type.value === 1 || type.value === 2) {
                if (currentMetricData.metric_col_code === "investing_style") {
                    return <Select removeIcon={<IoIosCloseCircleOutline size={12} />}
                        className='textSM black  selectWhite'
                        mode="multiple"
                        allowClear
                        suffixIcon={<MdArrowDropDown size={17} />}
                        defaultValue={currentMetricData.value || currentMetricData.metric_col_code === "investing_style" ? ['all'] : currentMetricData.value}
                        style={{
                            width: "15rem",
                        }}
                        placeholder="Select style"
                        onChange={(value) => {
                            onValueChange(value)
                        }}
                        options={investingStylelist}
                    />
                }
                if (currentMetricData.metric_col_code === "category_id") {
                    var categories = JSON.parse(JSON.stringify(mfCategories)).map((i, index) => {
                        i["value"] = i.categoryName
                        i["label"] = i.categoryName
                        i["title"] = i.categoryName
                        if (i["subCategories"] !== undefined) {
                            i["subCategories"]?.map((ii, indexi) => {
                                ii["value"] = String(ii.categoryId)
                                ii["label"] = ii.subCategoryName
                                return ii
                            })
                            i["children"] = i["subCategories"]
                        }
                        return i
                    })

                    return <Cascader
                        className='selectWhite'
                        style={{
                            width: "15rem",
                        }}
                        placeholder='Select categories'
                        options={categories}
                        defaultValue={currentMetricData.value || []}
                        onChange={(value) => {
                            onValueChange(value)
                        }}
                        multiple
                        maxTagCount="responsive"
                    />
                }
            } else if (type.value === 4) {
                if ((currentMetricData.metric_col_code === "macro_sec_code" ||
                    currentMetricData.metric_col_code === "sector_code" ||
                    currentMetricData.metric_col_code === "industry_code" ||
                    currentMetricData.metric_col_code === "nse_basic_ind_code" ||
                    currentMetricData.metric_col_code === "risk_box_code" ||
                    currentMetricData.metric_col_code === "style_box_code")) {
                    var checkAllData = currentMetricData.metric_col_code === "macro_sec_code" ? stock_macro_sectors :
                        currentMetricData.metric_col_code === "sector_code" ? stock_sectors :
                            currentMetricData.metric_col_code === "industry_code" ? stock_industries :
                                currentMetricData.metric_col_code === "nse_basic_ind_code" ? stock_basic_industries :
                                    currentMetricData.metric_col_code === "risk_box_code" ? risk_box_codes : currentMetricData.metric_col_code === "style_box_code" ? styleBoxes : []
                    return <Select removeIcon={<IoIosCloseCircleOutline size={12} />}
                        className='textSM black  selectWhite'
                        mode="multiple"
                        maxTagCount='responsive'
                        allowClear
                        suffixIcon={<MdArrowDropDown size={17} />}
                        dropdownStyle={{

                        }}
                        popupClassName="textSM black"
                        defaultValue={currentMetricData.value || []}
                        style={{
                            width: "15rem",
                        }}
                        placeholder={"Select " + currentMetricData.metric_name}
                        onChange={(value) => {
                            onValueChange(value)
                        }}
                        options={checkAllData}
                    />
                }
            }

            if (currentMetricData.metric_data_type === "float") {
                if ((currentMetricData?.queryValue || queriesFinal[0]?.value) === "in" || (currentMetricData?.queryValue || queriesFinal[0]?.value) === "notin") {
                    return <Select removeIcon={<IoIosCloseCircleOutline size={12} />}
                        className='textSM black  selectWhite'
                        allowClear
                        suffixIcon={<MdArrowDropDown size={17} />}
                        defaultValue={currentMetricData.filter || (currentMetricData.direction_flag === "top" ? sortOptions[0] : sortOptions[1])}
                        style={{
                            width: "6rem",
                        }}
                        placeholder="Select"
                        onChange={(value) => {
                            upDatedFilters[index] = {
                                ...data,
                                filter: value
                            }

                            setFinalData(upDatedFilters)
                        }}
                        options={sortOptions}
                    />
                } else {
                    return <></>
                }
            } else if (currentMetricData.metric_data_type === "boolean") {
                return <></>
            } else if (currentMetricData.metric_data_type === "Date" || currentMetricData.metric_data_type === "date") {
                // convertAnyDatetoDDMMYYYY(currentMetricData?.filter)
                return <DatePicker className='textSM black  selectWhite' style={{
                    width: "8rem",
                }} onChange={(e) => {
                    if (e !== null) {
                        onValueChange(String(new Date(e).getTime()) + "0")
                    }
                }} defaultValue={dayjs('01-01-1990', 'DD-MM-YYYY')} value={dayjs(currentMetricData?.value !== undefined ? convertAnyDatetoDDMMYYYY(Number(currentMetricData?.value) / 10) : '01-01-1990', 'DD-MM-YYYY')} format={['DD-MM-YYYY', 'DD-MM-YY']} />
            } else {
                var checkAllData = Array.from(new Set(allFundsData.map(item => item[currentMetricData.metric_col_code])));
                checkAllData = checkAllData.map((i, index) => {
                    var x = {}
                    x["value"] = i
                    x["label"] = i
                    return x
                })
                checkAllData = checkAllData.filter((x) => x?.label !== null && x?.label !== "" && x?.label !== undefined)
                return <Select removeIcon={<IoIosCloseCircleOutline size={12} />}
                    className='textSM black  selectWhite'
                    mode="multiple"
                    suffixIcon={<MdArrowDropDown size={17} />}
                    maxTagCount='responsive'
                    allowClear
                    dropdownStyle={{

                    }}
                    popupClassName="textSM black"
                    defaultValue={currentMetricData.value || []}
                    style={{
                        width: "11rem",
                    }}
                    placeholder={"Select " + currentMetricData.metric_name}
                    onChange={(value) => {
                        onValueChange(value)
                    }}
                    options={checkAllData}
                />
            }

        }

        const FilterViewLevel2 = () => {
            // currentMetricData?.queryValue?.value || queriesFinal[0]?.value === isOne,notOne  => Multi select
            // currentMetricData?.queryValue?.value || queriesFinal[0]?.value === in,notin  => percentile
            // currentMetricData?.queryValue?.value || queriesFinal[0]?.value === gt,lt,lteq,gteq  => absolute
            // currentMetricData?.queryValue?.value || queriesFinal[0]?.value === query7  => absolute and 

            const [metricVal, setmetricVal] = React.useState(currentMetricData.value)
            const onValueChange = (value, level = 1) => {
                if (value[value.length - 1] === "all") {
                    value = ["all"]
                } else {
                    if (value.length > 1 && value[0] === "all") {
                        value.splice(0, 1)
                    }
                }
                upDatedFilters[index] = {
                    ...data,
                    value: value
                }
                setFinalData(upDatedFilters)
            }

            if (currentMetricData.metric_data_type === "float") {
                if ((currentMetricData?.queryValue || queriesFinal[0]?.value) === "in" || (currentMetricData?.queryValue || queriesFinal[0]?.value) === "notin") {
                    return <Select removeIcon={<IoIosCloseCircleOutline size={12} />}
                        className='textSM black  selectWhite'
                        suffixIcon={<MdArrowDropDown size={17} />}
                        defaultValue={currentMetricData.pervalue || perFilterValues[0]?.value}
                        style={{
                            width: "100px",
                        }}
                        placeholder="Select"
                        onChange={(value) => {
                            upDatedFilters[index] = {
                                ...data,
                                pervalue: value
                            }
                            setFinalData(upDatedFilters)
                        }}
                        options={perFilterValues}
                    />
                } else {
                    return <InputNumber
                        formatter={(value) => inputBoxFormatter({
                            text: value,
                            metricObj: currentMetricData
                        })}

                        parser={(value) => inputBoxParser({
                            text: value,
                            metricObj: currentMetricData
                        })}


                        precision={2} style={{ width: "100px", }}
                        className='textSM black  selectWhite'
                        value={Number(metricVal)}
                        defaultValue={Number(currentMetricData.value) || currentMetricData.metricDefVal}
                        onKeyDown={(e) => {
                            if (e.keyCode === 13) {
                                e.target.blur();
                                //Write you validation logic here
                            }
                        }}
                        onChange={(value) => {
                            setmetricVal(Number(value))
                        }}
                        onBlur={() => {
                            onValueChange(Number(metricVal))
                        }}
                    />
                }
            } else {
                return <></>
            }
        }

        if (viewType === 0) {
            return <Card bodyStyle={{ padding: "0rem", display: "flex", gap: "0.5rem", alignItems: "center" }}>
                <span style={{ flex: 1, alignItems: "center", display: "flex", gap: "0.25rem" }}>
                    <MdDragIndicator size={25} color="var(--primary)" />
                    <span className='textSM black'>{currentMetricData.metric_name}</span>
                </span>

                <Select removeIcon={<IoIosCloseCircleOutline size={12} />}
                    className='textSM black selectWhite'
                    suffixIcon={<MdArrowDropDown size={17} />}
                    defaultValue={currentMetricData?.queryValue || queriesFinal[0]?.value}
                    value={currentMetricData?.queryValue || queriesFinal[0]?.value}
                    style={{
                        width: level1Width,
                    }}
                    onChange={(value) => {
                        onQueryValueChange(value)
                    }}
                    options={queriesFinal}
                />
                <FilterViewLevel1 />
                {(currentMetricData?.filter?.length !== 0 && currentMetricData?.filter?.length !== undefined) || currentMetricData.metric_data_type === "float" ?
                    <FilterViewLevel2 /> : <></>}
                <IoIosCloseCircleOutline className='textSM black' color='var(--dark3)' size={18} onClick={() => {
                    var a = [...newFiltersList]
                    a.splice(index, 1)
                    setFinalData(a)
                }} />

            </Card>
        } else {
            return <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem", marginTop: "0.5rem" }}>
                <span className='textSM dark3'>{currentMetricData.metric_name}</span>
                <div style={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
                    <div style={{ display: "flex", gap: "0.5rem", alignItems: "center", flex: 1 }}>
                        <Select removeIcon={<IoIosCloseCircleOutline size={12} />}
                            className='textSM black selectWhite'
                            suffixIcon={<MdArrowDropDown size={17} />}
                            defaultValue={currentMetricData?.queryValue || queriesFinal[0]?.value}
                            value={currentMetricData?.queryValue || queriesFinal[0]?.value}
                            style={{
                                width: level1Width,
                            }}
                            onChange={(value) => {
                                onQueryValueChange(value)
                            }}
                            options={queriesFinal}
                        />
                        <FilterViewLevel1 />
                        {(currentMetricData?.filter?.length !== 0 && currentMetricData?.filter?.length !== undefined) || currentMetricData.metric_data_type === "float" ?
                            <FilterViewLevel2 /> : <></>}
                    </div>
                </div>

            </div>
        }


    }

    const RenderIntialFlatlist = ({ item }) => {

        const [checked, setChecked] = React.useState()

        return item.sub_category.filter((ele) => ele.isVisibleNormally !== false).map((subCategory, subCategoryIndex) => {
            return <div key={"asd" + subCategoryIndex} style={{ borderRadius: 2, marginBottom: "2rem" }}>
                <span className="textSM black w-500"> {subCategory.sub_category_name}</span>

                <span style={{ display: "flex", flexDirection: "column", gap: "0.5rem", marginTop: "0.625rem" }}>
                    {subCategory.metric.map((metric, metricIndex) => {

                        // var upDatedFilters = [...checkFiltersList]
                        var upDatedFilters = []
                        var itemIndex = [...newFiltersList].findIndex((met) => met.metric_col_code === metric.metric_col_code)
                        var isPresentIndex = itemIndex !== -1

                        if (metric.isVisibleNormally !== false) {
                            return <div onClick={() => {
                                // console.log(e.target.checked)
                                if (!isPresentIndex) {
                                    // setselectedSubCategory(subCategory.sub_category_id)
                                    setselectedMetric(metric.metric_col_code)
                                    isPresentIndex = true
                                    var selectedm = screenerMetric.filter((i) => i.metric_col_code === metric.metric_col_code)[0]
                                    upDatedFilters.push({
                                        metric_col_code: metric.metric_col_code,
                                        value: selectedm?.metricDefVal,
                                        pervalue: selectedm?.pervalue,
                                        queryValue: selectedm?.queryValue,
                                    })
                                    setcheckFiltersList(upDatedFilters)
                                } else {
                                    toast.info("Metric already added")
                                }
                            }} key={metric.metric_col_code} style={{ cursor: isPresentIndex ? 'not-allowed' : "pointer", color: metric.metric_col_code === selectedMetric ? "var(--primary)" : isPresentIndex ? "var(--dark1)" : "var(--dark3)", display: "flex", alignItems: "center", gap: "0.5rem" }} className='textXS'>
                                {metric.metric_name}
                            </div>
                        } else {
                            return <div key={"DSF" + metricIndex
                            } ></div >
                        }
                    })}
                </span >
            </div >
        })
    }

    const onDragEnd = (result, viewType = 0) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        if (viewType === 0) {

            const reorderedItems = reorder(
                newFiltersList,
                result.source.index,
                result.destination.index
            );

            setnewFiltersList(reorderedItems)
        } else {

            const reorderedItems = reorder(
                checkFiltersList,
                result.source.index,
                result.destination.index
            );

            setcheckFiltersList(reorderedItems)
        }

    };

    const isBackTestAvailable = () => {
        var con1 = allFilteredFundsData.length >= 10 && allFilteredFundsData.length <= 100
        var con2 = appliedMetrics.length === appliedMetrics.filter((item) => {
            //console.log(item)
            return item.back_test_available === 1
        }).length
        //console.log(con1, con2)
        return con1 && con2 && newFiltersList.length > 0
    }

    const hide = () => {
        setpopOpen(false);
    };

    const handleOpenChange = (newOpen) => {
        console.log("hey")
        setpopOpen(newOpen);
    };

    React.useEffect(() => {
        loadData()
    }, [])

    React.useEffect(() => {
        if (screener_final_query.length > 0 && loading === false) {
            convertScreenerQueryToNormal()
        }
    }, [loading])

    React.useEffect(() => {
        applyFilters()
    }, [newFiltersList, selectedStockUniverse, excludeSurveillance])

    React.useEffect(() => {
        setMetricbackTestRes({})
        if (selectedm?.metric_col_code !== undefined) {
            getScreenerMetricBackTest(selectedm?.metric_col_code)
        }
    }, [selectedStockUniverseMetricBack])

    const CheckedFilterView = () => {
        return <span style={{ padding: '0rem 1.25rem' }}>
            <span className='textSM black w-700'>Customise rule</span>
            {checkFiltersList.map((item, idx) => {
                return <MetricFilterView key={idx} viewType={1} data={item} index={idx} />
            })}
        </span>
    }

    const DrawerMetricDescComp = () => {
        return <div style={{ padding: "1.25rem", display: "flex", flexDirection: "column", gap: "0.5rem" }}>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                <span className='textSM black w-700'>{selectedm?.metric_name}</span>
                {(selectedm?.back_test_available === 1 && selectedm?.metric_data_type === 'float') || selectedm?.metric_col_code === "style_box_code" ? <button className='btnWhite btnSM mb-1' onClick={() => {
                    getScreenerMetricBackTest(selectedm?.metric_col_code)
                    settoggleMetricBackTest(true)
                }}>
                    Metric performance
                </button> : <></>}
            </div>
            <ShowMoreText
                /* Default options */
                lines={10}
                more="Know more"
                less="Know less"
                className="textSM"
                anchorClass="show-more-less-clickable"
                onClick={(e) => {
                    console.log(e)
                }}
                expanded={false}
                // width={280}
                truncatedEndingComponent={"... "}
            >
                {selectedm?.metric_desc}
            </ShowMoreText>
        </div>
    }

    const AppliedFilterView = () => (
        <Card title={<div style={{ display: "flex", gap: "0.5rem" }}>
            <CardHeader heading={screener_final_query?.[0]?.checklist_name || "Create screen"} />
            {type.value === 4 && <button className='btnWhite' title='Backtest screen' disabled={!isBackTestAvailable()} onClick={() => {
                performBackTest()
            }}>Backtest screen</button>}
            <button className='btnBlack' title='Save' disabled={newFiltersList.length === 0} onClick={() => {
                setmodalSave(true)
            }}>{screener_final_query?.length > 0 ? "Update" : "Save"}</button>
        </div>} bodyStyle={{ display: "flex", flexDirection: "column", gap: "1.5rem", paddingTop: 0, paddingBottom: 0 }}>

            <div style={{ display: "flex", gap: "0.75rem", alignItems: "center", paddingLeft: 0, paddingRight: 0 }}>
                {type.value === 4 && <div className='' style={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
                    <span className='textSM w-500' >Select stock universe</span>
                    {/* <Switch className='' size="small" checked={excludeSurveillance} onChange={(e) => {
                        setexcludeSurveillance(e)
                    }} /> */}
                    <Select removeIcon={<IoIosCloseCircleOutline size={12} />}
                        className='textSM black '
                        style={{
                            width: "10rem"
                        }}
                        suffixIcon={<MdArrowDropDown size={17} />}
                        defaultValue={selectedStockUniverse}
                        placeholder="Select stock universe"
                        onChange={(value) => {
                            setselectedStockUniverse([value])
                        }}
                        options={stock_universe}
                    />
                </div>}

                <span className='textXS' color='var(--dark3)' style={{ flex: 1, textAlign: "right" }}>Add rule to find the best {type.dname}</span>
                {newFiltersList.length > 0 ? <Popover

                    content={
                        <div >
                            <div style={{
                                position: "relative", minHeight: "40vh",
                                maxHeight: "40vh", overflowY: "scroll"
                            }}>
                                <DragDropContext onDragEnd={(e) => onDragEnd(e, 0)}>
                                    <Droppable droppableId={"droppable"}>
                                        {(provided, snapshot) => (
                                            <div
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                                style={getListStyle(snapshot.isDraggingOver)}
                                            >
                                                {newFiltersList.map((item, idx) => {
                                                    return <Draggable key={item.metric_col_code + "metric"} draggableId={item.metric_col_code + "metric"} index={idx}>
                                                        {(provided, snapshot) => (
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                style={getItemStyle(
                                                                    snapshot.isDragging,
                                                                    provided.draggableProps.style
                                                                )}
                                                            >
                                                                <MetricFilterView data={item} index={idx} />
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                })}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", gap: "1.5rem", alignItems: "center" }}>
                                <div style={{ padding: '1.25rem', flex: 1 }} className='textSX dark2'>{`showing ${allFilteredFundsData.length} out of ${allFundsData.length} ${type.dname}`}</div>
                                <span>
                                    <button className='btnBlack' title='Add rule' onClick={() => {
                                        hide()
                                        if (allFilteredFundsData.length === 0) {
                                            alert(`No ${type.dname} left to apply filter. Please adjust your current filters to proceed`)
                                        } else {
                                            setmodal(!modal)
                                        }
                                    }}>Add rule</button>
                                </span>
                            </div>
                        </div>
                    }
                    // title="Title"
                    trigger="click"
                    open={popopen}
                    overlayStyle={{
                        position: "relative",
                        width: "55%",
                        height: "50%",
                        padding: 0
                    }}
                    showArrow={false}
                    style={{ position: "relative", width: "65vw" }}
                    placement="bottomRight"
                    onOpenChange={(e) => handleOpenChange(e)}
                >
                    <button style={{ width: "10vw" }} className="btnWhite"><span style={{ display: "flex", alignItems: "center" }}><FaFilter className='mx-2' /> Rules <Tag className='mx-2' color="var(--grey5)" style={{ color: "var(--black)" }}>{newFiltersList.length}</Tag></span></button>
                </Popover> :
                    <button className='btnBlack' title='Add rule' onClick={() => {
                        if (allFilteredFundsData.length === 0) {
                            alert(`No ${type.dname} left to apply filter. Please adjust your current filters to proceed`)
                        } else {
                            setmodal(!modal)
                        }
                    }}>Add rule</button>}
            </div>
        </Card>
    )

    const renderRow = ({ item, index }) => {
        return <span key={item.category_id} style={{
            padding: "0.75rem 1.25rem",
            borderRadius: "4px 0px 0px 4px",
            display: "flex",
            alignItems: "center",
            backgroundColor: item.category_id === selectedCategory ? "var(--blueShade)" : "transparent", cursor: "pointer"
        }}
            onClick={() => {
                setselectedCategory(item.category_id)
                // setselectedSubCategory("")
                setselectedMetric("")
            }}
        >
            <span style={{ flex: 1, color: item.category_id === selectedCategory ? "var(--primary)" : "var(--dark3)" }} className="textSM w-500">{item.category_name}</span>
            <IoIosArrowForward color={item.category_id === selectedCategory ? "var(--primary)" : "var(--dark3)"} />
        </span>
    };

    const WorstDrawdowns = () => {
        const dataSource = Object.values(backTestData?.perfromance_tables?.worst_5_drawdowns)

        const columns = [
            {
                title: 'Started on',
                dataIndex: 'Started',
                key: 'Started',
                width: 150,
                fixed: 'left'
            },
            {
                title: 'Drawdown',
                dataIndex: 'Drawdown',
                key: 'Drawdown',
                align: "right",
                render: (text) => DecimalValueFormat({ num: Number(text), suffix: "%", placeholder: "-" })
            },
            {
                title: 'Lasted for (days)',
                dataIndex: 'Days',
                key: 'Days',
                align: "right",
            },
            {
                title: 'Recovered on',
                dataIndex: 'Recovered',
                key: 'Recovered',
                align: "right",
            }
        ]
        return <Table dataSource={dataSource} columns={columns} size={'small'} bordered className='textSM black' pagination={false} />
    }

    const TableConstructor = ({ tableName = "", tableData = {}, showNameColumn = false, isTranspose = false }) => {
        if (isTranspose) {
            var dataSource = Object.values(tableData)
            var dataSourceKeys = Object.keys(tableData)

            var smDataSource = Object.keys(dataSource[0])
            smDataSource = smDataSource.map((key, index) => {
                var i = {
                    "key": key,
                    "name": key
                }
                dataSourceKeys.map((finalkey, index) => {
                    i = {
                        ...i,
                        [finalkey]: new Date(dataSource[index][key]) !== "Invalid Date" && !dataSource[index][key]?.includes("%") && !dataSource[index][key]?.includes(".") ? formatDate(dataSource[index][key]) : dataSource[index][key]
                    }
                })
                return i
            })
            dataSourceKeys = ["name"]?.concat(dataSourceKeys)
            var columns = dataSourceKeys.map((key, index) => {
                return {
                    title: key === "name" ? "" : key,
                    dataIndex: key,
                    key: key,
                    width: 100,
                    align: key === "name" ? "left" : "right",
                }
            })
            return <div className='my-4'>
                <div className='fontH2 w-700 mb-2'>{tableName}</div><Table scroll={{ x: isTranspose ? 300 : 800 }} dataSource={smDataSource} columns={columns} size={'small'} bordered className='textSM black' pagination={false} /></div>
        } else {
            var dataSource = Object.values(tableData)
            var dataSourceKeys = Object.keys(tableData)

            if (showNameColumn) {
                dataSource = dataSource.map((item, index) => {
                    return {
                        ...item,
                        "col1_name_custom": dataSourceKeys[index]
                    }
                })
            }

            var columns = Object.entries(dataSource[0]).map(([key, val], index) => {
                return {
                    title: key,
                    dataIndex: key,
                    key: key,
                    width: 100,
                    align: () => {
                        if (new Date(val) !== "Invalid Date" || val.includes("%") || val.includes(".") || isNaN(Number(val))) {
                            return "right"
                        } else {
                            return "left"
                        }
                    },
                    fixed: key === "col1_name_custom" ? "left" : false,
                    render: (text) => {
                        if (new Date(text) !== "Invalid Date" && !text.includes("%") && !text.includes(".")) {
                            return formatDate(text)
                        } else {
                            return text
                        }
                    }
                }
            })


            if (showNameColumn) {
                columns.splice(columns.indexOf((i) => i.key === "col1_name_custom"), 1)
                columns = [{
                    title: "",
                    width: 150,
                    dataIndex: "col1_name_custom",
                    key: "col1_name_custom",
                    fixed: "left",
                }]?.concat(columns)
            }
            return <div className='my-3'>
                <div className='fontH2 w-700 mb-2'>{tableName}</div><Table scroll={{ x: 800 }} dataSource={dataSource} columns={columns} size={'small'} bordered className='textSM black' pagination={false} /></div>
        }
    }

    const ReturnsHistogram = () => {
        var heatmapdata = backTestData?.perfromance_charts?.histogram_monthly_rets.map((res) => {
            return Number((res * 100).toFixed(2))
        })

        const xiData = [];
        const animationDuration = 1;//4000;
        const range = Math.max(...heatmapdata) - Math.min(...heatmapdata) * 2,
            startPoint = Math.min(...heatmapdata);
        let i = 0,
            j = 0;
        for (i = 0; i < range; i++) {
            xiData[i] = startPoint + i;
        }
        const data = [];

        function GaussKDE(xi, x) {
            return (1 / Math.sqrt(2 * Math.PI)) * Math.exp(Math.pow(xi - x, 2) / -2);
        }

        const N = heatmapdata.length;

        for (i = 0; i < xiData.length; i++) {
            let temp = 0;
            for (j = 0; j < heatmapdata.length; j++) {
                temp = temp + GaussKDE(xiData[i], heatmapdata[j]);
            }
            data.push([xiData[i], (1 / N) * temp * 100]);
        }

        const chart = {
            chart: {
                type: "areaspline",
                animation: true,
                height: 300,
                marginTop: 10
            },
            title: {
                text: '',
                style: {
                    color: 'var(--black)'
                }
            },
            scrollbar: {
                enabled: false
            },
            exporting: {
                enabled: false
            },
            credits: {
                enabled: false
            },

            title: {
                text: ""
            },
            xAxis: {
                title: { text: "" }
            },
            yAxis: {
                visible: false,
                tickAmount: 5,
                gridLineDashStyle: 'longdash',
                title: { text: 'Density' }
            },
            tooltip: {
                enabled: false
            },
            legend: {
                enabled: false
            },

            plotOptions: {
                areaspline: {
                    threshold: null
                },
                series: {
                    marker: {
                        enabled: false
                    },
                    dashStyle: "shortdot",
                    color: "#ff8d1e",
                    pointStart: xiData[0],
                    animation: {
                        duration: animationDuration
                    }
                }
            },
            series: [
                {
                    name: "Return density",
                    dashStyle: "solid",
                    lineWidth: 2,
                    color: 'var(--primary)',
                    data: data
                }
            ]
        }

        const options = {
            global: {
                useUTC: false
            },
            lang: {
                decimalPoint: '.',
                thousandsSep: ','
            }
        };

        return <HighchartsReact highcharts={Highcharts} options={chart} />
    }

    const HeatMap = () => {
        var heatmapdata = backTestData?.perfromance_charts?.heatmap
        var years = backTestData?.perfromance_charts?.heatmap.map((data) => { return data.year })
        var months = Object.keys(backTestData?.perfromance_charts?.heatmap[0]).slice(1)


        var dataFinal = months.map((month, index) => {
            return years.map((year, yindex) => {
                return {
                    name: month.charAt(0).toUpperCase() + month.toLowerCase().slice(1) + " " + year,
                    x: index,
                    y: yindex,
                    value: Number((heatmapdata[yindex][month] * 100).toFixed(1))
                }
            })
        })


        var chart = {

            chart: {
                type: 'heatmap',
                plotBorderWidth: 0,
                borderWidth: 0,
                height: 300,

            },


            title: {
                text: '',
                style: {
                    color: "var(--black)"
                }
            },
            scrollbar: {
                enabled: false
            },
            exporting: {
                enabled: false
            },
            credits: {
                enabled: false
            },

            xAxis: {
                categories: months
            },

            yAxis: {
                tickAmount: 5,
                gridLineDashStyle: 'longdash',
                categories: years,
                title: null,
                reversed: true
            },

            colorAxis: {
                min: 0,
                max: 100,
                minColor: "var(--red)",
                maxColor: "var(--green)"
            },

            legend: {
                enabled: false
            },

            tooltip: {
                headerFormat: '',
                pointFormat: '{point.name} returns: {point.value}%',
            },
            series: [{
                name: 'Monthly returns',
                borderWidth: 0.2,
                borderColor: 'black',
                data: dataFinal.flat(1),
                dataLabels: {
                    enabled: true,
                    color: '#000000',
                    style: {
                        fontWeight: 'normal',
                        fontSize: 8,
                        textOutline: false
                    },
                    pointFormat: '{point.value}%',
                }
            }],

            responsive: {
                rules: [{
                    condition: {
                        maxWidth: 300
                    },
                    chartOptions: {
                        yAxis: {
                            labels: {
                                formatter: function () {
                                    return this.value.charAt(0);
                                }
                            }
                        }
                    }
                }]
            }

        }

        const options = {
            global: {
                useUTC: false
            },
            lang: {
                decimalPoint: '.',
                thousandsSep: ','
            }
        };

        return <HighchartsReact highcharts={Highcharts} options={chart} />
    }

    const RollingVol = () => {

        var heatmapdata = backTestData?.perfromance_charts?.rolling_vol

        try {
            var conf = {
                chart: {
                    backgroundColor: "transparent",
                    height: 250,
                },
                title: {
                    text: ''
                },
                exporting: {
                    enabled: false
                },
                yAxis: {
                    tickAmount: 5,
                    gridLineDashStyle: 'longdash',
                    title: {
                        text: ''
                    },
                    gridLineColor: "var(--border)",
                    plotLines: [{
                        color: '#000', // Red
                        value: heatmapdata[0]["Average_6M_vol"], // Position, you'll have to translate this to the values on your x axis
                        dashStyle: 'dash',
                        width: 1,
                        label: {
                            text: "Average 6M vol", rotation: 0, x: 10,
                            style: {
                                color: "var(--black)",
                                fontWeight: 'bold'
                            }
                        }
                    }]
                },
                xAxis: [{
                    type: 'datetime',
                    dateTimeLabelFormats: {
                        millisecond: '%H:%M:%S.%L',
                        second: '%H:%M:%S',
                        minute: '%H:%M',
                        hour: '%H:%M',
                        day: '%eth %b',
                        week: '%eth %b',
                        month: '%b \'%y',
                        year: '%Y'
                    },
                    lineColor: "var(--border)",
                    tickColor: "var(--border)",
                }
                ],
                legend: {
                    enabled: false
                },
                plotOptions: {
                    series: {
                        lineWidth: 1.5,
                        label: {
                            connectorAllowed: false
                        },
                        marker: {
                            enabled: false
                        }
                    }
                },
                tooltip: {
                    shared: true,
                    valueDecimals: 2,
                    valuePrefix: '',
                    valueSuffix: '%'
                },
                credits: {
                    enabled: false
                },
                boost: {
                    useGPUTranslations: true,
                },
                series: [
                    {
                        name: "Portfolio", turboThreshold: 9e9,
                        color: '#4284f3',
                        data: changeObjKey(heatmapdata, ["Portfolio", "Date"], ["y", "x"], "rollingTearchart", 1)
                    }, {
                        name: "Benchmark", turboThreshold: 9e9,
                        color: "var(--primary)",
                        data: changeObjKey(heatmapdata, ["Benchmark", "Date"], ["y", "x"], "rollingTearchart", 1)
                    }
                ]?.reverse(),
                scrollbar: {
                    enabled: false
                },
                responsive: {
                    rules: [{
                        condition: {
                            maxWidth: 400
                        },
                    }]
                },

            };
        } catch (error) {
            //console.log("SDF", error)
        }

        const options = {
            global: {
                useUTC: false
            },
            lang: {
                decimalPoint: '.',
                thousandsSep: ','
            }
        };

        return <HighchartsReact highcharts={Highcharts} options={conf} />
    }

    const SharpeChart = () => {
        var heatmapdata = backTestData?.perfromance_charts?.rolling_sharpe

        try {
            var conf = {
                chart: {
                    backgroundColor: "transparent",
                    height: 250,
                },
                title: {
                    text: ''
                },
                exporting: {
                    enabled: false
                },
                yAxis: {
                    tickAmount: 5,
                    gridLineDashStyle: 'longdash',
                    title: {
                        text: ''
                    },
                    gridLineColor: "var(--border)",
                    plotLines: [{
                        color: '#000', // Red
                        value: heatmapdata[0]["Average_Sharpe"], // Position, you'll have to translate this to the values on your x axis
                        dashStyle: 'dash',
                        width: 1,
                        label: {
                            text: "Average Sharpe", rotation: 0, x: 10,
                            style: {
                                color: "var(--black)",
                                fontWeight: 'bold',
                                zIndex: 1
                            }
                        }
                    }]
                },
                xAxis: [{
                    type: 'datetime',
                    dateTimeLabelFormats: {
                        millisecond: '%H:%M:%S.%L',
                        second: '%H:%M:%S',
                        minute: '%H:%M',
                        hour: '%H:%M',
                        day: '%eth %b',
                        week: '%eth %b',
                        month: '%b \'%y',
                        year: '%Y'
                    },
                    lineColor: "var(--border)",
                    tickColor: "var(--border)",
                }
                ],
                legend: {
                    enabled: false
                },
                plotOptions: {
                    series: {
                        lineWidth: 1.5,
                        label: {
                            connectorAllowed: false
                        },
                        marker: {
                            enabled: false
                        }
                    }
                },
                tooltip: {
                    shared: true,
                    valueDecimals: 0,
                    valuePrefix: '₹',
                },
                credits: {
                    enabled: false
                },
                boost: {
                    useGPUTranslations: true,
                },
                series: [
                    {
                        name: "Portfolio", turboThreshold: 9e9,
                        color: "#24c1e0",
                        tooltip: {
                            valueSuffix: ' ({point.Portfolio}%)',
                        },
                        data: changeObjKey(heatmapdata, ["Portfolio", "Date"], ["y", "x"], "rollingTearchart", 1)
                    }
                ],
                scrollbar: {
                    enabled: false
                },
                responsive: {
                    rules: [{
                        condition: {
                            maxWidth: 400
                        },
                    }]
                },

            };
        } catch (error) {
            //console.log("SDF", error)
        }

        const options = {
            global: {
                useUTC: false
            },
            lang: {
                decimalPoint: '.',
                thousandsSep: ','
            }
        };

        return <HighchartsReact highcharts={Highcharts} options={conf} />
    }

    const UnderWater = () => {
        var heatmapdata = backTestData?.perfromance_charts?.underwater

        try {
            var conf = {
                chart: {
                    backgroundColor: "transparent",
                    height: 250,
                },
                title: {
                    text: ''
                },
                exporting: {
                    enabled: false
                },
                yAxis: {
                    tickAmount: 5,
                    gridLineDashStyle: 'longdash',
                    labels: {
                        format: '{value}%'
                    },
                    title: {
                        text: ''
                    },
                    gridLineColor: "var(--border)",
                },
                xAxis: [{
                    type: 'datetime',
                    dateTimeLabelFormats: {
                        millisecond: '%H:%M:%S.%L',
                        second: '%H:%M:%S',
                        minute: '%H:%M',
                        hour: '%H:%M',
                        day: '%eth %b',
                        week: '%eth %b',
                        month: '%b \'%y',
                        year: '%Y'
                    },
                    lineColor: "var(--border)",
                    tickColor: "var(--border)",
                }
                ],
                legend: {
                    enabled: false
                },
                plotOptions: {
                    series: {
                        lineWidth: 1.5,
                        label: {
                            connectorAllowed: false
                        },
                        marker: {
                            enabled: false
                        }
                    }
                },
                tooltip: {
                    shared: true,
                    valueDecimals: 2,
                    valuePrefix: '',
                    valueSuffix: '%'
                },
                credits: {
                    enabled: false
                },
                boost: {
                    useGPUTranslations: true,
                },
                series: [
                    {
                        name: "Portfolio", turboThreshold: 9e9,
                        color: "var(--red)",
                        data: changeObjKey(heatmapdata, ["Portfolio", "Date"], ["y", "x"], "rollingTearchart", 1).map((i) => {
                            return {
                                ...i,
                                y: i.y * 100
                            }
                        })
                    }
                ],
                scrollbar: {
                    enabled: false
                },
                responsive: {
                    rules: [{
                        condition: {
                            maxWidth: 400
                        },
                    }]
                },

            };
        } catch (error) {
            //console.log("SDF", error)
        }

        const options = {
            global: {
                useUTC: false
            },
            lang: {
                decimalPoint: '.',
                thousandsSep: ','
            }
        };

        return <HighchartsReact highcharts={Highcharts} options={conf} />
    }

    const RebalanceData = ({ rebalData }) => {
        const dataSource = rebalData

        const columns = [
            {
                title: 'Symbol',
                dataIndex: 'symbol',
                key: 'symbol',
                width: 150,
                fixed: 'left'
            },
            {
                title: 'Market cap',
                dataIndex: 'mcap',
                key: 'mcap',
                align: "right",
                render: (text) => DecimalValueFormat({ num: Number(text.toFixed(2)), suffix: " Cr", placeholder: "-" })
            },
            {
                title: 'Weights',
                dataIndex: 'weights',
                key: 'weights',
                align: "right",
                render: (text) => DecimalValueFormat({ num: Number(text.toFixed(2)), suffix: "%", placeholder: "-" })
            },
            {
                title: 'Next day avg. price',
                dataIndex: 'next_day_price_avg',
                key: 'next_day_price_avg',
                align: "right",
                render: (text) => CurrencyFormat(Number(text))
            },
            {
                title: 'No. of shares',
                dataIndex: 'num_shares',
                key: 'num_shares',
                align: "right",
            }
        ]
        return <Table dataSource={dataSource} columns={columns} size={'small'} bordered className='textSM black my-2' pagination={false} />
    }

    const CustomAccordionItem = ({ x }) => {

        const [isOpen, setIsOpen] = React.useState(false)
        return <Timeline.Item key={x.index}>
            <div className="compare-collapse" style={{ backgroundColor: "transparent", borderBottom: "0px", padding: "0px" }} id={`id${x.index}`} key={`id${x.index}`} onClick={() => {
                setIsOpen(!isOpen)
            }}>
                <div className="fontH2 black" >{x.header}</div>
                <div className="black">{isOpen ? <FiChevronUp size={'18px'} /> : <FiChevronDown size={'18px'} />}</div>
            </div>
            <Collapse
                style={{ border: "0px" }}
                isOpen={isOpen}
                toggler={`#id${x.index}`} id={`#body${x.index1}`}>

                <RebalanceData rebalData={x.rebalData} />

            </Collapse>
        </Timeline.Item>

    }

    const CashPositions = () => {
        var heatmapdata = backTestData?.cash_positions

        try {
            var conf = {
                chart: {
                    backgroundColor: "transparent"
                },
                title: {
                    text: ''
                },
                exporting: {
                    enabled: false
                },
                yAxis: {
                    tickAmount: 5,
                    gridLineDashStyle: 'longdash',
                    labels: {
                        format: '{value}'
                    },
                    title: {
                        text: ''
                    },
                    gridLineColor: "var(--border)",
                },
                xAxis: [{
                    type: 'datetime',
                    dateTimeLabelFormats: {
                        millisecond: '%H:%M:%S.%L',
                        second: '%H:%M:%S',
                        minute: '%H:%M',
                        hour: '%H:%M',
                        day: '%eth %b',
                        week: '%eth %b',
                        month: '%b \'%y',
                        year: '%Y'
                    },
                    lineColor: "var(--border)",
                    tickColor: "var(--border)",
                }
                ],
                legend: {
                    enabled: false
                },
                plotOptions: {
                    series: {
                        lineWidth: 1.5,
                        label: {
                            connectorAllowed: false
                        },
                        marker: {
                            enabled: false
                        }
                    }
                },
                tooltip: {
                    shared: true,
                    valueDecimals: 2,
                    valuePrefix: '',
                    valueSuffix: ''
                },
                credits: {
                    enabled: false
                },
                boost: {
                    useGPUTranslations: true,
                },
                series: [
                    {
                        name: "Cash position", turboThreshold: 9e9,
                        color: "var(--red)",
                        data: changeObjKey(heatmapdata, ["cash", "date"], ["y", "x"], "rollingTearchart", 1).map((i) => {
                            return {
                                ...i,
                                y: i.y
                            }
                        })
                    }
                ],
                scrollbar: {
                    enabled: false
                },
                responsive: {
                    rules: [{
                        condition: {
                            maxWidth: 400
                        },
                    }]
                },

            };
        } catch (error) {
            //console.log("SDF", error)
        }

        const options = {
            global: {
                useUTC: false
            },
            lang: {
                decimalPoint: '.',
                thousandsSep: ','
            }
        };

        return <HighchartsReact highcharts={Highcharts} options={conf} />
    }

    const tabItems = Object.keys(backTestData).length !== 0 ? [
        {
            label: <span className='textSM w-500'>{`Performance`}</span>,
            key: "id1",
            children: (
                <div className='my-3'>
                    <Row>
                        <Col sm={6}>
                            <div className='fontH2 w-700 mb-3'>Portfolio vs Benchmark performance</div>
                            <PerformanceChart2 chartData={backTestData?.perfromance_against_bm?.prices} />
                            <TableConstructor tableName='Performance metrics' tableData={backTestData?.perfromance_tables?.perf_metrics} showNameColumn={true} isTranspose={true} />
                        </Col>
                        <Col sm={6}>
                            <TableConstructor tableName='Performance summary' tableData={backTestData?.perfromance_tables?.perf_summary} showNameColumn={true} isTranspose={true} />
                            <div className='my-4'>
                                <div className='fontH2 w-700 mb-2'>Worst 5 Drawdowns</div>
                                <WorstDrawdowns />
                            </div>
                            <TableConstructor tableName='Trailing Returns' tableData={backTestData?.perfromance_tables?.trailing_returns} showNameColumn={true} isTranspose={true} />
                            <TableConstructor tableName='Calender year returns' tableData={backTestData?.perfromance_tables?.cal_year_returns} showNameColumn={true} isTranspose={true} />
                        </Col>
                    </Row>
                </div>
            ),
        }, {
            label: <span className='textSM w-500'>{`Performance charts`}</span>,
            key: "id11",
            children: (
                <div className='my-3'>
                    <Row>
                        <Col className='mb-3' sm={6}>
                            <div className='fontH2 w-700 mb-3'>Distribution of monthly returns</div>
                            <ReturnsHistogram />
                        </Col>
                        <Col className='mb-3' sm={6}>
                            <div className='fontH2 w-700 mb-3'>Monthly returns heatmap</div>
                            <HeatMap />
                        </Col>
                        <Col className='my-3' sm={4}>
                            <div className='fontH2 w-700 mb-3'>6-month rolling volatility</div>
                            <RollingVol />
                        </Col>
                        <Col className='my-3' sm={4}>
                            <div className='fontH2 w-700 mb-3'>6-month rolling Sharpe ratio</div>
                            <SharpeChart />
                        </Col>
                        <Col className='my-3' sm={4}>
                            <div className='fontH2 w-700 mb-3'>Underwater plot</div>
                            <UnderWater />
                        </Col>
                    </Row>
                </div>
            ),
        }, {
            label: <span className='textSM w-500'>{`Rebalance history`}</span>,
            key: "id2",
            children: (
                <div className='my-4'>
                    <Timeline>
                        {Object.entries(backTestData?.rebal_hsitory)?.map(([Date, rebalData], index1) => {
                            return <CustomAccordionItem x={{
                                "Date": Date,
                                "header": "Rebalance data as on " + formatDate(Date),
                                "rebalData": rebalData,
                                "key": Date + "" + index1,
                                "index": index1
                            }} />
                        })}
                    </Timeline>
                </div>
            ),
        }] : [];


    if (loading) {
        return <React.Fragment>
            <div className='mainContainer' style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
                <ActivityLoaderMintbox />
            </div>
        </React.Fragment>
    } else {
        return (
            <React.Fragment>
                <Head title="Screen" />

                <div className='normalContainer'>
                    {AppliedFilterView()}
                    <InstrumentListTable
                        finalList={JSON.parse(JSON.stringify(allFilteredFundsData))}
                        tableType={1}
                        onChangeExcludeStocksSwitch={(e)=>setexcludeSurveillance(e)}
                        defExcludeStocks={excludeSurveillance}
                        disableInvestBtn={allFilteredFundsData.length < 5}
                        // disableInvestBtn={allFilteredFundsData.length < 5 || newFiltersList.length < 1}
                        initialColumns={tableColumns.map((i) => i.metric_col_code)}
                        tableHeader={`Showing ${allFilteredFundsData.length} out of ${allFundsData.length} ${type.dname}`}
                        investInScreener={(rowSelection) => {
                            if (rowSelection.length < (type.value === 4 ? 5 : 2)) {
                                toast.info(<span className='textSM dark3'>To invest in screen <br></br>Select <span className='black w-500'>Min. {type.value === 4 ? "5" : "2"}</span> or <span className='black w-500'>Max. {type.value === 4 ? "50" : "10"}</span> {type.dname}</span>)
                            } else {
                                setinstrumentList(rowSelection)
                                convertNormalToScreenerQuery()
                                setinvestInScreenerModal(!investInScreenerModal)
                            }
                        }}
                    />
                </div>

                <Drawer
                    width={"75vw"}
                    closable={false}
                    title={false} placement="right"
                    onClose={() => {
                        setmodal(!modal)
                        settoggleMetricBackTest(false)
                    }}
                    open={modal}
                    footer={false}>
                    <Card
                        actions={[<div style={{ display: "flex", gap: "0.5rem", alignItems: "center", justifyContent: "flex-end", paddingRight: "1rem" }}><button className="btnWhite" onClick={() => {
                            setmodal(!modal)
                            settoggleMetricBackTest(false)
                        }}>Cancel</button>,
                            <button className="btnBlack" disabled={selectedm === undefined} onClick={() => {
                                setselectedMetric("")
                                setmodal(!modal)
                                settoggleMetricBackTest(false)
                                // setnewFiltersList(checkFiltersList)
                                setnewFiltersList(newFiltersList.concat(checkFiltersList))
                            }}>Apply changes</button></div>]}

                        bodyStyle={{ display: "flex", flex: 1, height: "100%", padding: 0, overflowY: "scroll" }} style={{ display: "flex", flexDirection: "column", flex: 1, height: "100%" }}
                        title={<span style={{ display: "flex", alignItems: "center" }}>
                            <span className='textLG w-500' style={{ flex: 1 }}>Select Metric</span>
                            <IoIosClose onClick={() => {
                                setmodal(!modal)
                                settoggleMetricBackTest(false)
                            }}
                                size={"1.75rem"} style={{ cursor: "pointer" }} />
                        </span>}
                        bordered>
                        <Card bordered bodyStyle={{ display: "flex", flexDirection: "column", flex: 1, height: "100%", paddingRight: 0, overflowY: "scroll" }} style={{ display: "flex", flexDirection: "column", flex: 1.2, height: "100%", borderRadius: 0, borderWidth: 0.5 }}>
                            {screenerFinalData.map((item, index) => {
                                if (item.isVisibleNormally === true) {
                                    return renderRow({
                                        item, index
                                    })
                                } else {
                                    return <></>
                                }
                            }
                            )}
                        </Card>
                        <Card bordered bodyStyle={{ display: "flex", flexDirection: "column", flex: 1, height: "100%", overflowY: "scroll" }} style={{ display: "flex", flexDirection: "column", flex: 1.1, height: "100%", borderRadius: 0, borderWidth: 0.5 }}>
                            <RenderIntialFlatlist item={screenerFinalData.filter((i) => i.category_id === selectedCategory)[0]} />
                        </Card>
                        <Card bordered bodyStyle={{ display: "flex", flexDirection: "column", flex: 1, height: "100%", overflowY: "scroll", padding: 0 }} style={{ display: "flex", flexDirection: "column", flex: 2.7, height: "100%", borderRadius: 0, borderWidth: 0.5 }}>
                            {selectedm?.metric_name !== undefined ? <>
                                <DrawerMetricDescComp />
                                <Divider style={{ marginBottom: "0.5rem" }} />
                                {checkFiltersList.length > 0 && <CheckedFilterView />}
                            </> : <div className="mt-5" style={{ display: "flex", flexDirection: "column", flex: 1, height: "100%", alignItems: "center" }}>
                                <img
                                    src='https://cdn-icons-png.flaticon.com/512/3344/3344553.png'
                                    height={80}
                                    width={80}
                                />
                                <br></br>
                                <div>Select a metric</div>
                            </div>
                            }
                        </Card>
                    </Card>

                    <Drawer
                        width={800}
                        height={"40vh"}
                        title={<span className='fontH1 black m-1 mb-5' >{toggleMetricBackTest ? <>
                            <span>'{selectedm?.metric_name}' metric backtest</span>
                        </> : "Select metric"}</span>} placement="right"
                        onClose={() => {
                            //setmodal(!modal)
                            settoggleMetricBackTest(false)
                        }}
                        open={toggleMetricBackTest}
                        footerStyle={{ textAlign: 'right' }}
                        footer={<button className="btnBlack ms-auto" onClick={() => {
                            settoggleMetricBackTest(false)
                        }}>Close</button>}>
                        <div className="">
                            <div className='p-2' style={{ display: "flex", flexDirection: "column", gap: "20px" }}>

                                <div className='' style={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
                                    <span className='textSM w-500' >Select stock universe</span>

                                    <Select removeIcon={<IoIosCloseCircleOutline size={12} />}
                                        className='textSM black '
                                        style={{
                                            width: "250px"
                                        }}
                                        suffixIcon={<MdArrowDropDown size={17} />}
                                        defaultValue={selectedStockUniverseMetricBack}
                                        placeholder="Select stock universe"
                                        onChange={(value) => {
                                            setselectedStockUniverseMetricBack([value])
                                        }}
                                        options={stock_universe.filter((i) => i.value !== 'all')}
                                    />
                                </div>

                                {Object.keys(metricbackTestRes).length !== 0 && selectedm?.metric_col_code === metricbackTestResName ?
                                    <PerformanceChart2 portfolioType={-1} defYrSelect="max" xAxisKey={metricbackTestResName === "style_box_code" ? "Date" : "date"} chartData={metricbackTestRes} defAmtSelect={100000} />
                                    : <ActivityLoaderMintbox />}

                            </div>
                        </div>
                    </Drawer>
                </Drawer>

                <Modal
                    title={<span className='textLG w-700' >Save screen</span>}
                    centered
                    open={modalSave}
                    okButtonProps={{
                        className: "btnBlack"
                    }}
                    cancelButtonProps={{
                        className: "btnWhite"
                    }}
                    onOk={() => {
                        saveScreener()
                    }}
                    closable={false}
                    onCancel={() => setmodalSave(!modalSave)}
                    width={"30vw"}
                    zIndex={1000}
                    style={{ top: -70 }}
                    okText={"Save"}
                    cancelText={"Cancel"}
                >

                    <div style={{ display: "flex", flexDirection: "column", gap: "1.25rem", marginTop: "1rem" }}>
                        <div style={{ display: "flex", gap: "1.25rem" }}>
                            <span className='d-flex gap-3 align-items-center'><span className='textXS w-500 dark3'>Alert</span><Switch checked={alertsEnabled} onChange={(e) => {
                                setalertsEnabled(!alertsEnabled)
                            }} /></span>
                            <span className='d-flex gap-3 align-items-center'><span className='textXS w-500 dark3'>Also use as checklist</span><Switch checked={saveAsChecklist} onChange={(e) => {
                                setsaveAsChecklist(!saveAsChecklist)
                            }} /></span>
                        </div>

                        <span className='d-flex flex-column gap-1'>
                            <span className='textXS w-500 dark3'>Screen Name</span>
                            <Input size='large' value={screener_name} onChange={(e) => {
                                setscreener_name(e.target.value)
                            }} placeholder='Enter your screen name' />
                        </span>

                        <span className='d-flex flex-column gap-1'>
                            <span className='textXS w-500 dark3'>Description <span className='dark1'>(Optional)</span></span>
                            <TextArea size='large' rows={4} value={screener_desc} onChange={(e) => {
                                setscreener_desc(e.target.value)
                            }} placeholder='Enter your screen description' />
                        </span>

                    </div>
                </Modal>

                <Drawer
                    width={"80vw"}
                    title={<span className='fontH1 black m-1 mb-5' >Backtest results</span>} placement="right"
                    onClose={() => {
                        setbackTestModal(!backTestModal)
                    }}
                    open={backTestModal}
                    footerStyle={{}}
                    footer={false}>
                    <Row className='px-2' style={{ gap: "20px", minHeight: "90vh" }}>
                        {backTestModalLoading ? <ActivityLoaderMintbox /> :
                            Object.keys(backTestData).length !== 0 ?
                                <Tabs tabBarGutter={20} className='textSM' size='small' items={tabItems} /> : null}
                    </Row>
                </Drawer>

                <Drawer
                    width={"85vw"}
                    closable={false}
                    placement="right"
                    onClose={() => {
                        setinvestInScreenerModal(!investInScreenerModal)
                    }}

                    open={investInScreenerModal}
                    footerStyle={{}}
                    footer={false}>
                    <BuildCustomPortfolio
                        isModalView={true}
                        instrumentList={[...instrumentList]}
                        allFilteredFundsData={[...allFilteredFundsData]}
                        instrument_method={2}
                        screenerQuery={screener_final_query.length > 0 ? screener_final_query : []}
                        newFiltersList={newFiltersList}
                        tableColumns={tableColumns}
                        screenerMetric={screenerMetric}
                    />
                </Drawer>

            </React.Fragment >
        );
    }

}

export default Screener