import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";
import db from "Libs/db";
import AdminServices from "./api/AdminServices";
import apiHelper from "./api/ApiHelper";
import { getUser } from "Libs/utils";

const GATEWAY_NAME = "mintbox"

const intializeWithAuthId = async (authId = '') => {
    console.log("intialize with auth id :", authId)
    const res = await AdminServices.getSmallcasetoken(authId).then(rs => rs)
    if (res.type) {
        console.log("Initializing with New Token =>>", res.data)
        await db.set(db.model.smallcaseSDKToken, JSON.stringify(res.data))
        window.sc = new window.scDK({
            gateway: GATEWAY_NAME,
            smallcaseAuthToken: res.data,
        })
    } else {
        await intializeWithAuthId()
    }
}

const initializeSmallCase = async () => {
    await db.get(db.model.smallcaseSDKToken).then(async (sdkToken) => {
        console.log("sdkToken", sdkToken)
        if (sdkToken === null || jwt_decode(sdkToken).exp * 1000 < new Date().getTime()) {
            await getUser(true)
                .then(async (user) => {
                    console.log(user)
                    await apiHelper({ apiName: 'getClient', data: { id: user.id } }).then(async (res) => {
                        if (res) {
                            console.log('getClient', JSON.parse(res))
                            if (JSON.parse(res)?.SmallcaseInfo?.length) {
                                await intializeWithAuthId(JSON.parse(res)?.[6]?.SmallcaseInfo[0]?.AuthId || '')
                            } else {
                                await intializeWithAuthId()
                            }
                        } else {
                            await intializeWithAuthId()
                        }
                    })
                })
                .catch(error => {
                    toast.error(error);
                })
        } else {
            if (window.sc === undefined) {
                console.log("Initializing with existing sdktoken -> ", sdkToken)
                window.sc = new window.scDK({
                    gateway: GATEWAY_NAME,
                    smallcaseAuthToken: sdkToken,
                })
            } else {
                console.log('smallcase already initialized')
            }
        }
    })
        .catch(e => console.log(e))
}

const getsmallCaseToken = async (authId = '') => {
    const res = await AdminServices.getSmallcasetoken(authId).then(rs => rs)
    if (res.type) {
        console.log("New Token =>>", res.data)
        await db.set(db.model.smallcaseSDKToken, JSON.stringify(res.data))
        window.sc.init({ smallcaseAuthToken: res.data })
            .then((a) => {
                console.log("Smallcase init complete", a)
            })
            .catch((e) => {
                console.log(e.message)
                getsmallCaseToken()
            });
    }
}

const updateSmallCaseAuthId = async (clientId, smallcase_authId, broker) => {
    const d = {
        "clientId": clientId,
        "broker": broker,
        "smallcaseAuthToken": smallcase_authId
    }
    console.log(d)
    const smallAuthRes = await AdminServices.saveSmallCaseAuthId(d).then(res => res)
    console.log(smallAuthRes)
    if (smallAuthRes.type) {
        if (smallAuthRes.data.Result === 'failure') {
            console.log("failedddddddd")
            return false
        } else {
            await getsmallCaseToken(smallAuthRes.data.AuthId)
            console.log("yeahhhhh")
            return true
        }
    } else {
        return false
    }
}
export { initializeSmallCase, updateSmallCaseAuthId, getsmallCaseToken }
