import { Card } from "antd"
import React from "react";
import { BsChevronDown, BsChevronUp } from "react-icons/bs"
import { Col, Collapse, Row, TabContent, TabPane } from "reactstrap"

const CustomCollapseTabs = ({ tabData }) => {

    const [accordion, setAccordion] = React.useState("1");
    const [selected, setSelected] = React.useState(tabData?.[0]?.children?.[0].key);

    const SideAccordionItem = ({ x }) => {
        return <div key={x.key} className="accordion-item" style={{ borderBottom: '1px solid var(--border)' }}>
            <div
                className={`hoverBackground accordion-head p-2 ${accordion === x.key ? "" : "collapsed"}`}
                onClick={() => { accordion === x.key ? setAccordion('') : setAccordion(x.key); setSelected(x.children.filter((ele) => ele.show)[0].key) }}
                style={{ cursor: 'pointer', border: 'none', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            >
                <div className="fontH2 ps-3 w-500">{x.title}</div>
                {accordion === x.key ? <BsChevronUp className="me-1 textSM" /> : <BsChevronDown className="me-1 textSM" />}
            </div>
            <Collapse
                className="accordion-body"
                isOpen={accordion === x.key}
                in={accordion === x.key}
                style={{ border: 'none' }}
            >
                <div>
                    <div className="accordion-inner p-0" style={{ border: 'none', marginBottom: '1rem' }}>
                        {x.children.filter((ele) => ele.show).map((i) => {
                            return <div key={i.key} onClick={() => setSelected(i.key)}
                                className="textSM hoverBackground"
                                style={{
                                    padding: selected === i.key ? "0.5rem 0 0.5rem calc(2rem - 5px)" : "0.5rem 0 0.5rem 2rem",
                                    fontWeight: selected === i.key ? "500" : "400",
                                    borderLeft: selected === i.key ? "5px solid var(--primary)" : "none",
                                    cursor: 'pointer'
                                }}
                            >
                                {i.header}
                            </div>
                        })}

                    </div>
                </div>
            </Collapse >
        </div >
    }

    const CustomAccordionBody = ({ x }) => {
        return <React.Fragment key={x.key}>
            {x.hideHeader ? <></> : <div id={`id${x.key}`} className="fontH2 w-500" style={{ padding: '1.5rem 2rem 0.5rem' }}>
                {x.header}
            </div>}
            <div style={{ padding: '0.5rem 2rem' }}>
                {x.html}
            </div>
        </React.Fragment>
    }

    return <Row style={{ minHeight: '500px' }}>
        <Col sm={3} md={3} lg={3} xxl={3} className="pe-0">
            <Card bordered={false}  bodyStyle={{ padding: 0, paddingTop: '1rem' }} style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0, height: '100%', borderRight: 'none' }}>
                {
                    tabData.map((tab, i) => {
                        if (tab?.children?.some(x => x.show)) {
                            return <SideAccordionItem x={tab} key={i} />
                        }
                    })
                }
            </Card>
        </Col>
        <Col sm={9} md={9} lg={9} xxl={9} className="ps-0">
            <Card bordered={false}  bodyStyle={{ padding: 0 }} style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0, height: '100%' }}>
                <TabContent activeTab={selected}>
                    {
                        tabData.map(tab => {
                            if (tab.children.some(x => x.show)) {
                                return <React.Fragment key={tab.key}>
                                    {tab.children.map(x => {
                                        if (x.show) {
                                            return <TabPane tabId={x.key} className="" key={x.key}>
                                                <CustomAccordionBody x={x} />
                                            </TabPane>
                                        }
                                    })}
                                </React.Fragment>
                            }
                        })
                    }
                </TabContent>
            </Card>
        </Col>
    </Row>
}

export default CustomCollapseTabs