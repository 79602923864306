import { Card, Skeleton } from "antd";
import AMCIcon from "Components/AMCIcon";
import { CardHeader } from "Components/atoms/SmallComponents";
import { CurrencyFormat } from "Components/CurrencyFormat";
import { addWeek, formatDateyyyymmdd } from "Components/DateFormatter";
import { DecimalValueFormat } from "Components/DecimalValueFormat";
import RedGreenText from "Components/RedGreenText";
import { fetchFundData } from "Libs/fetchFundData";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

const TopGainersAndLosers = ({ type, format = 'top', indexCode = 'is_nifty_100' }) => {
    // format
    // top => gainers
    // bot => losers
    const navigate = useNavigate();
    var stockprices = useSelector((state) => state?.stockprices?.value)
    var prevstockprices = useSelector((state) => state?.prevStockPrices?.value)


    const [allFunds, setAllfunds] = React.useState([])
    const [allFilteredFunds, setAllFilteredfunds] = React.useState([])
    const [load, setLoad] = React.useState(true)
    // console.log(prevstockprices)
    // console.log(stockprices)

    const getChange = (current, prev) => {
        return {
            changePer: ((current - prev) / prev) * 100,
            changeVal: current - prev
        }
    }

    const sortFunds = (arr, key, order = "top") => {
        var arrr = [...arr]?.filter((i) => (i[key] !== null))
        if (order === "bot") {
            arrr = arrr.sort((a, b) => a[key] - b[key])
        } else {
            arrr = arrr.sort((a, b) => b[key] - a[key])
        }

        return arrr
    }




    const loadData = async () => {
        var allfundsc = await fetchFundData(type.value)

        allfundsc = type.value === 4 ? allfundsc.filter((i) => i[indexCode] === 1) : allfundsc
        allfundsc = allfundsc.map((fund) => {
            var currentLTP = (stockprices.filter((i) => i.Symbol === (fund.symbol || fund.nse_symbol))?.[0]?.LTP || stockprices.filter((i) => i.Symbol === (fund.symbol || fund.nse_symbol))?.[0]?.ClosePrice) / 100
            // console.log(prevstockprices.filter((i) => (i.Symbol === (fund.symbol || fund.nse_symbol))))
            var { changePer, changeVal } = getChange(currentLTP, (prevstockprices.filter((i) => (i.Symbol === (fund.symbol || fund.nse_symbol)) && i.Date.replace('T00:00:00', '') === formatDateyyyymmdd(addWeek(new Date, -(1 / 7))))?.[0]?.ClosePrice) / 100)

            if (isNaN(changePer) && isNaN(changeVal)) {
                var { changePer, changeVal } = getChange(currentLTP, (prevstockprices.filter((i) => (i.Symbol === (fund.symbol || fund.nse_symbol)))?.[0]?.ClosePrice) / 100)
            }

            // console.log(currentLTP,
            //     changePer,
            //     changeVal)
            return {
                ...fund,
                "currentLTP": currentLTP,
                "changePer": changePer,
                "changeVal": changeVal
            }
        })

        // console.log(allfundsc)
        setAllfunds(sortFunds(allfundsc, 'changePer', format))
        setLoad(false)
    }


    React.useEffect(() => {
        if (stockprices.length !== 0)
            loadData()
    }, [stockprices, prevstockprices, type])
    const fundsToShow = 5

    if (load) {
        return <Skeleton />
    } else {
        return <Card bordered={false} style={{ padding: 0 }} bodyStyle={{ padding: 0 }}>
            <div className='pad20Container' style={{ display: "flex", alignItems: "center" }}>
                <CardHeader heading={format === 'top' ? 'Top gainers' : 'Top losers'} />
            </div>
            <div>
                {
                    allFunds.splice(0, fundsToShow).map((f, i) => {
                        // console.log(f)
                        return <div key={i} className="borderAll" style={{ backgroundColor: i % 2 !== 0 ? 'var(--white)' : 'var(--grey4)' }}>
                            <div className="pad20Side6VerContainer" style={{ display: "flex", gap: "0.5rem", alignItems: "center" }} onClick={() => navigate(type.basePath + `${type.value === 4 ? '/stock-detail/' + f.symbol : '/fund-detail/' + f?.basic_name?.replace(" - Direct Plan", "")?.replaceAll(' ', '-') + '?code=' + f.plan_id}`)}>
                                <AMCIcon amcName={type.value === 4 ? f.symbol : f.amc_full_name} fundType={type.value} height='1.5rem' width="1.5rem" />
                                <div className="textXS black hoverBold" style={{ flex: 2, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }} >
                                    {type.value === 4 ? f?.proper_name : f?.basic_name?.replace(" - Direct Plan", "")}
                                </div>
                                <span className="textXS w-500 black" style={{ display: "flex", flexDirection: "column", gap: "0.1rem", alignItems: "flex-end", flex: 1, textAlign: "right" }}>
                                    <span style={{ textAlign: "right", minWidth: "3vw" }}>{CurrencyFormat(f.currentLTP, 'long', 2)}</span>
                                    <span className="dark3" style={{ flex: 1, textAlign: "right" }}>
                                        <RedGreenText text={`${DecimalValueFormat({ num: f.changeVal, suffix: '', decimals: 1 }).replace("NaN", "0.00")} (${DecimalValueFormat({ num: f.changePer, suffix: "%", decimals: 2 }).replace("NaN", "0.00")})`} value={f.changePer} />
                                    </span>
                                </span>
                            </div>
                        </div>
                    })
                }
            </div>
        </Card>
    }


}

export default TopGainersAndLosers;