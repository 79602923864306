import { Badge, Button, Checkbox, Input, Popover, Table } from "antd";
import ActivityLoaderMintbox from "Components/ActivityLoaderMintbox";
import AMCIcon from "Components/AMCIcon";
import { CurrencyFormat } from "Components/CurrencyFormat";
import { DecimalValueFormat } from "Components/DecimalValueFormat";
import Head from "Layout/Head";
import { fetchFundData } from "Libs/fetchFundData";
import { useGetAssetType } from "Libs/utils";
import React, { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { BsFilter } from "react-icons/bs";
import { useLocation, useNavigate } from "react-router";
import { Col, Row } from "reactstrap";
import apiHelper from "Services/api/ApiHelper";

const FundList = (props) => {
    const navigate = useNavigate()
    const location = useLocation()
    const [stockSectorCLF, setStockSectorCLF] = useState([])
    const [displayData, setDisplayData] = useState([]);
    const [allData, setAllData] = useState([]);
    const [loading, setLoading] = useState(true)
    const [tablesm, updateTableSm] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [appliedFilters, setAppliedFilters] = useState(location?.state?.filter || {})
    const [currentPage, setCurrentPage] = useState(1);
    const [itemPerPage, setItemPerPage] = useState(50);

    const type = useGetAssetType();

    React.useEffect(() => {
        const dem = async () => {
            setLoading(true)
            let a = await fetchFundData(type.value, false)
            console.log('allfunds', a)
            setAllData(a)
            let res = await apiHelper({ apiName: 'getStockSectorCLF', saveResponse: 'localStorage' })
            console.log(res)
            setStockSectorCLF(res)
            setLoading(false)
        }
        dem();
    }, [type.value])

    // Get current list, pagination
    const indexOfLastItem = currentPage * itemPerPage;
    const indexOfFirstItem = indexOfLastItem - itemPerPage;
    const currentItems = displayData.slice(indexOfFirstItem, indexOfLastItem);

    // Change Page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const onChangeFilter = ({ filter, value, checked }) => {

        if (checked) {
            if (appliedFilters[filter]) {
                setAppliedFilters({ ...appliedFilters, [filter]: [...appliedFilters[filter], value] })
            } else {
                setAppliedFilters({ ...appliedFilters, [filter]: [value] })
            }
        } else {
            setAppliedFilters({ ...appliedFilters, [filter]: appliedFilters[filter]?.filter(a => a !== value) || [] })
        }
    }

    React.useEffect(() => {
        let d = allData
        if (type)
            if (searchText !== "") {
                d = allData.filter((item) => {
                    return item[type.value === 4 ? 'proper_name' : 'basic_name'].toLowerCase().includes(searchText.toLowerCase());
                });
            }
        Object.keys(appliedFilters).forEach((fil) => {
            if (appliedFilters[fil].length) {
                if (fil === 'popular') {
                    console.log("pop")
                    appliedFilters[fil].map(z => {
                        console.log(z)
                        switch (z) {
                            case "Consistent performers": { d = d.filter(fund => fund.primary_category_name === 'Equity' && fund.quat_consistency_12q >= 6); return; }
                            case "Positive alpha funds": { d = d.filter(fund => fund.primary_category_name === 'Equity' && fund.alpha >= 5); return; }
                            case "Recent out performers": { d = d.filter(fund => fund.primary_category_name === 'Equity' && fund.per_rank_1y < 0.25); return; }
                            case "5-year out perfromers": { d = d.filter(fund => fund.primary_category_name === 'Equity' && fund.per_rank_5y < 0.25); console.log('asdasdasd'); return; }
                            case "Nifty 50 index funds": { d = d.filter(fund => fund.is_index_fund === 1 && Number(fund.benchmark_id) === 333); console.log('asdasdasd'); return; }
                            case "International Equity FOFs": { d = d.filter(fund => (fund.is_index_fund === 1 || fund.is_fof === 1) && fund.is_etf_fund === 1 && fund.category_name === 'Equity: International'); console.log('adsasd', d); return; }
                        }
                    })
                } else if (fil === 'market_cap') {
                    console.log("pop")
                    appliedFilters[fil].map(z => {
                        switch (z) {
                            case "Nifty 50": { d = d.filter(fund => fund.is_nifty_50 === 1); return; }
                            case "Nifty 100": { d = d.filter(fund => fund.is_nifty_100 === 1); return; }
                            case "Nifty 500": { d = d.filter(fund => fund.is_nifty_500 === 1); return; }
                            case "Nifty Midcap 100": { d = d.filter(fund => fund.is_nifty_midcap100 === 1); return; }
                            case "Nifty Smallcap 100": { d = d.filter(fund => fund.is_nifty_smallcap100 === 1); return; }
                        }
                    })
                } else if (fil === 'sector') {
                    console.log("pop")
                    appliedFilters[fil].map(z => {
                        switch (z) {
                            case "Banking": { d = d.filter(fund => fund.is_nifty_50 === 1); return; }
                            case "Energy": { d = d.filter(fund => fund.is_nifty_50 === 1); return; }
                            case "Healthcare": { d = d.filter(fund => fund.is_nifty_50 === 1); return; }
                            case "FMCG": { d = d.filter(fund => fund.is_nifty_50 === 1); return; }
                            case "Metal": { d = d.filter(fund => fund.is_nifty_50 === 1); return; }
                        }
                    })
                } else if (fil === 'macro_sec_code') {
                    var x = []
                    appliedFilters[fil].map(z => {
                        switch (z) {
                            case "Financial Services": { x.push('IN05'); return; }
                            case "IT": { x.push('IN08'); return; }
                            case "Healthcare": { x.push('IN06'); return; }
                            case "FMCG": { x.push('IN04'); return; }
                            case "Commodities": { x.push('IN01'); return; }
                            case "Energy": { x.push('IN03'); return; }
                        }
                    })
                    d = d.filter(fund => x.includes(fund[fil]))
                } else if (fil === 'risk_id') {
                    var x = []
                    appliedFilters[fil].map(z => {
                        switch (z) {
                            case "Low risk": { x.push(4); return; }
                            case "Low to moderate risk": { x.push(10); return; }
                            case "Moderate risk": { x.push(6); return; }
                            case "Moderately high risk": { x.push(7); return; }
                            case "High risk": { x.push(8); return; }
                            case "Very high risk": { x.push(9); return; }
                        }
                    })
                    d = d.filter(fund => x.includes(fund[fil]))
                } else {
                    d = d.filter(fund => appliedFilters[fil].includes(fund[fil]))
                }
            }
        });
        console.log(d)
        setDisplayData(d);
    }, [appliedFilters, searchText, allData])

    const allfilters = type.value === 4 ?
        [
            {
                key: "market_cap",
                name: "Market Cap",
                filters: [
                    {
                        key: "Nifty 50",
                        label: "Nifty 50",
                    },
                    {
                        key: "Nifty 100",
                        label: "Nifty 100",
                    },
                    {
                        key: "Nifty 500",
                        label: "Nifty 500",
                    },
                    {
                        key: "Nifty Midcap 100",
                        label: "Nifty Midcap 100",
                    },
                    {
                        key: "Nifty Smallcap 100",
                        label: "Nifty Smallcap 100",
                    }
                ]
            },
            {
                key: "macro_sec_code",
                name: "Sectors",
                filters: [
                    {
                        key: "Financial Services",
                        label: "Financial Services",
                    },
                    {
                        key: "IT",
                        label: "IT",
                    },
                    {
                        key: "Healthcare",
                        label: "Healthcare",
                    },
                    {
                        key: "FMCG",
                        label: "FMCG",
                    },
                    {
                        key: "Commodities",
                        label: "Commodities",
                    },
                    {
                        key: "Energy",
                        label: "Energy",
                    },
                ]
            },
        ]
        : [
            {
                key: "primary_category_name",
                name: "Category",
                filters: [
                    {
                        key: "Equity",
                        label: "Equity"
                    },
                    {
                        key: "Debt",
                        label: "Debt",
                    },
                    {
                        key: "Commodities",
                        label: "Commodities"
                    },
                    {
                        key: "Hybrid",
                        label: "Hybrid"
                    },
                ]
            },
            {
                key: "risk_id",
                name: "Risk level",
                filters: [
                    {
                        key: "Low risk",
                        label: "Low risk",
                    },
                    {
                        key: "Low to moderate risk",
                        label: "Low to moderate risk",
                    },
                    {
                        key: "Moderate risk",
                        label: "Moderate risk",
                    },
                    {
                        key: "Moderately high risk",
                        label: "Moderately high risk",
                    },
                    {
                        key: "High risk",
                        label: "High risk",
                    },
                    {
                        key: "Very high risk",
                        label: "Very high risk",
                    },
                ]
            },
            {
                key: "popular",
                name: "Popular filters",
                filters: [
                    {
                        key: "Consistent performers",
                        label: "Consistent performers",
                    },
                    {
                        key: "Positive alpha funds",
                        label: "Positive alpha funds",
                    },
                    {
                        key: "Recent out performers",
                        label: "Recent out performers",
                    },
                    {
                        key: "5-year out perfromers",
                        label: "5-year out perfromers",
                    },
                    {
                        key: "Nifty 50 index funds",
                        label: "Nifty 50 index funds",
                    },
                    {
                        key: "International Equity FOFs",
                        label: "International Equity FOFs",
                    },
                ]
            }
        ]

    const dataTableColumns = type.value === 4 ? [
        {
            "title": "Name",
            "dataIndex": "basic_name",
            render: (text, record, index) => <div style={{ display: 'flex', gap: '12px', cursor: "pointer" }} onClick={() => navigate({ pathname: type.basePath + `${type.value === 4 ? '/stock-detail/' + record.symbol : '/fund-detail/' + record?.basic_name?.replace(" - Direct Plan", "")?.replaceAll(' ', '-') + '?code=' + record.plan_id}` })}>
                <AMCIcon amcName={record.symbol} fundType={type.value} height='1.2rem' width="1.2rem" />
                <div>
                    <div className="textSM black">{record?.proper_name}</div>
                    <div className="text-left textXS ">{stockSectorCLF?.find(s => s.industry_code === record.industry_code)?.industry_desc}</div>
                </div>
            </div>,
            width: 250,
            fixed: 'left',
            show: true
        },
        {
            "title": "Market cap",
            "dataIndex": "mcap",
            align: "right",
            width: 130,
            "sorter": (a, b) => a.mcap - b.mcap,
            render: (text) => CurrencyFormat(Number(text), 'long', 0) + " Cr."
        },
        {
            "title": "1 month returns",
            "dataIndex": "one_mth_pct",
            width: 130,
            align: "right",
            "sorter": (a, b) => a.one_mth_pct - b.one_mth_pct,
            render: (text) => DecimalValueFormat({ num: Number(text), suffix: "%", placeholder: "-" }),
            show: true
        },
        {
            "title": "3 month returns",
            "dataIndex": "three_yr_pct",
            width: 130,
            align: "right",
            "sorter": (a, b) => a.three_yr_pct - b.three_yr_pct,
            render: (text) => DecimalValueFormat({ num: Number(text), suffix: "%", placeholder: "-" }),
            show: true
        },
        {
            "title": "1 year returns",
            "dataIndex": "one_yr_pct",
            width: 130,
            align: "right",
            "sorter": (a, b) => a.one_yr_pct - b.one_yr_pct,
            render: (text) => DecimalValueFormat({ num: Number(text), suffix: "%", placeholder: "-" }),
            show: true
        },
        {
            "title": "3 year returns",
            "dataIndex": "three_yr_pct",
            width: 130,
            align: "right",
            "sorter": (a, b) => a.three_yr_pct - b.three_yr_pct,
            render: (text) => DecimalValueFormat({ num: Number(text), suffix: "%", placeholder: "-" })
        }
    ]
        :
        [
            {
                "title": "Name",
                "dataIndex": "basic_name",
                render: (text, record, index) => <div style={{ display: 'flex', gap: '12px', cursor: "pointer" }} onClick={() => navigate({ pathname: type.basePath + `${type.value === 4 ? '/stock-detail/' + record.symbol : '/fund-detail/' + record?.basic_name?.replace(" - Direct Plan", "")?.replaceAll(' ', '-') + '?code=' + record.plan_id}` })}>
                    <AMCIcon amcName={record.amc_full_name} height='1.2rem' width="1.2rem" />
                    <div>
                        <div className="textSM black">{record?.basic_name?.replace(" - Direct Plan", "")}</div>
                        <div className="text-left textXS ">{record?.category_name}</div>
                    </div>
                </div>,
                width: 250,
                fixed: 'left',
                show: true
            },
            {
                "title": "1 year CAGR",
                "dataIndex": "cagr_1y",
                width: 130,
                align: "right",
                "sorter": (a, b) => a.cagr_1y - b.cagr_1y,
                render: (text) => DecimalValueFormat({ num: Number(text), suffix: "%", placeholder: "-" }),
                show: true
            },
            {
                "title": "3 year CAGR",
                "dataIndex": "cagr_3y",
                width: 130,
                align: "right",
                "sorter": (a, b) => a.cagr_3y - b.cagr_3y,
                render: (text) => DecimalValueFormat({ num: Number(text), suffix: "%", placeholder: "-" })
            },
            {
                "title": "5 year CAGR",
                "dataIndex": "cagr_5y",
                width: 130,
                align: "right",
                "sorter": (a, b) => a.cagr_5y - b.cagr_5y,
                render: (text) => DecimalValueFormat({ num: Number(text), suffix: "%", placeholder: "-" })
            },
            {
                "title": "NAV",
                "dataIndex": "latest_nav",
                width: 130,
                align: "right",
                "sorter": (a, b) => a.latest_nav - b.latest_nav,
                render: (text) => CurrencyFormat(Number(text), 'long', 2)
            },
            {
                "title": "AUM",
                "dataIndex": "aum",
                align: "right",
                width: 130,
                "sorter": (a, b) => a.aum - b.aum,
                render: (text) => CurrencyFormat(Number(text), 'long', 0) + " Cr."
            },
            {
                "title": "Risk level",
                "dataIndex": "risk_level",
                width: 130,
                align: "right",
                "sorter": (a, b) => a.risk_level - b.risk_level,
                render: (text) => <div className="textSM black">{text}</div>

            }
        ]

    const dropdownContent = () => {
        return <>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {
                    allfilters.map(section => {
                        return <div style={{ flex: 1 }} key={section.key}>
                            <div className="textSM">{section.name}</div>
                            {
                                section.filters.map(f => {
                                    return <div>
                                        <Checkbox
                                            id={f.key}
                                            checked={appliedFilters[section.key]?.some(fil => fil === f.key)}
                                            onChange={(e) => { e.preventDefault(); onChangeFilter({ filter: section.key, value: f.key, checked: e.target.checked }) }}
                                        >
                                            <span className="textSM">{f.label}</span>
                                        </Checkbox><br />
                                    </div>
                                })
                            }
                        </div>
                    })
                }
            </div>
            <div className="dropdown-foot between">
                <a
                    href="#reset"
                    onClick={(ev) => {
                        ev.preventDefault();
                        setAppliedFilters({})
                    }}
                    className="clickable"
                >
                    Reset Filters
                </a>
            </div>
        </>
    }
    const TableHeader = () => {
        return <>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '0.5rem' }}>
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '0.5rem' }}>
                    {
                        Object.keys(appliedFilters).map(fil => {
                            return appliedFilters[fil].map(a => {
                                return <button size="sm"
                                    onClick={() => { onChangeFilter({ filter: fil, value: a, checked: false }) }}
                                >{a} <AiOutlineClose className="ms-2" /></button>
                            })
                        })
                    }
                </div>
                <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                    <Input
                        type="text"
                        className=""
                        placeholder="Search by name"
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        autoFocus
                        style={{ width: '300px' }}
                    />
                    <Popover placement="bottomRight" title={`Filter ${type.dname}`} content={dropdownContent} trigger="click">
                        <Badge dot={Object.keys(appliedFilters).some(f => appliedFilters[f].length)} color="#333" offset={[-5, 5]}>
                            <button type="text" shape="circle" size="large" icon={<BsFilter size={20} />}>
                            </button>
                        </Badge>
                    </Popover>
                </div>
            </div>
            {/* <span className='fontH2'>showing <span className='fontH1'>{displayData.length}</span> out of {allData.length} funds</span> */}
        </>
    }
    if (loading) {
        return <ActivityLoaderMintbox margin="20%" />
    }
    return (
        <React.Fragment>
            <Head title={type.name} />
            <Row>
                <Col>
                    <Table pagination={{ position: ['none', 'bottomCenter'] }} defaultSortOrder='descend'
                        style={{
                            // backgroundColor: 'var(--backgroundCard) !important'
                        }} bordered={true} size='small' className='textXS black'
                        dataSource={displayData} columns={dataTableColumns} rowKey={(record) => record.plan_id} scroll={{ x: "max-content" }}
                        title={() => <TableHeader />} />
                </Col>
            </Row>

        </React.Fragment>
    );
};

export default FundList;
