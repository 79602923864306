import db from "Libs/db"
import { getUser } from "Libs/utils"
import { toast } from "react-toastify"
import AdminServices from "Services/api/AdminServices"
import { initializeSmallCase } from "Services/smallcase"

const saveOrder = async ({ navigation, order, clientId, mintboxId, goalId, fundCode, amount, isSIP, sipDate, isUnitsBased, units, isInvest }) => {
    // updateSmallCaseAuthId(clientId, order.smallcaseAuthToken, order.broker)
    let dd = {}
    if (isInvest) {
        dd = {
            "clientId": clientId,
            "mintboxId": mintboxId,
            "goalId": Number(goalId),
            "transactionType": 1,
            "fromFundCode": "",
            "toFundCode": fundCode + "",
            "folioNumber": "",
            "sipAmount": isSIP ? Number(amount) : 0,
            "units": 0,
            "LumpsumAmount": isSIP ? 0 : Number(amount),
            "sipDate": isSIP ? sipDate : 0,
            "investedAmount": order.orderBatches[0].buyAmount,
            "investmentDate": order.orderBatches[0].completedDate,
        }
    } else {
        dd = {
            "clientId": clientId,
            "mintboxId": mintboxId,
            "goalId": Number(goalId),
            "transactionType": 2,
            "fromFundCode": "",
            "toFundCode": fundCode + "",
            "folioNumber": "",
            "sipAmount": 0,
            "units": isUnitsBased ? Number(units) : 0,
            "lumpsumAmount": isUnitsBased ? 0 : amount,
            "sipDate": 0,
            "investedAmount": order.orderBatches[0].sellAmount,
            "investmentDate": order.orderBatches[0].completedDate,
        }
    }
    console.log("Save trnx log", dd)
    const logRes = await AdminServices.saveTransactionLogs(dd).then(res => res)
    console.log("Save Trx log API response", JSON.stringify(logRes))

    const d = {
        "clientId": clientId,
        "mintboxId": mintboxId,
        "goalId": Number(goalId),
        "smallcaseAuthToken": order.smallcaseAuthToken,
        "broker": order.broker,
        "transactionId": order.transactionId,
        "data": {
            "batches": order.orderBatches
        }
    }
    console.log("Save small case order", JSON.stringify(d))
    const smallOrderRes = await AdminServices.saveSmallCaseOrder(clientId, JSON.stringify(d)).then(res => res)
    console.log("saveSmallCaseOrder API response", smallOrderRes)
    if (smallOrderRes.type) {
        // await db.remove(db.model.portfolio);
        // await db.remove(db.model.portfolioXirr);
        // await db.remove(db.model.portfolioPerformance);
        // await db.remove(db.model.assetAllocation);
        // await db.remove(db.model.transactions)
        // await db.remove(db.model.sip)
        // navigation.push('/portfolio/success');
    } else {
        // navigation.push('/portfolio/failed')
    }
}

export default async function executeSmallCaseTransaction({ navigation, securities, amount, isSIP, isUnitsBased, units, sipDate, SCREENTYPE, FUNDDATA, isInvest }) {
    console.log("exec smcs trx")
    const order = {
        "intent": "TRANSACTION",
        "orderConfig": {
            "type": "SECURITIES",
            "securities": securities
        }
    }
    console.log("order", order)
    console.log("get smallcase trxn id")
    const res = await AdminServices.getSmallcaseTransactionId(order, "", FUNDDATA.MintboxId || 0, FUNDDATA.GoalId || 0).then(rs => rs)
    if (res.type) {
        console.log("Trx id =>>", res.data.data.transactionId)
        try {
            if (window.sc === undefined) {
                console.log("Initializing Smallcase")
                await initializeSmallCase();
            }
            console.log(window.sc)
            return await window.sc.triggerTransaction({ transactionId: res.data.data.transactionId })
                .then(async (a) => {
                    console.log(a)
                    if (a.orderBatches[0].status === "COMPLETED") {
                        await getUser(false).then(async (p) => {
                            if (SCREENTYPE === 3) {
                                //mintbox
                                return await saveOrder({
                                    navigation,
                                    order: a,
                                    clientId: p.id,
                                    mintboxId: FUNDDATA.MintboxId || 0,
                                    goalId: FUNDDATA.GoalId || 0,
                                    fundCode: FUNDDATA.MintboxId,
                                    amount: amount,
                                    isSIP: isSIP,
                                    sipDate: sipDate,
                                    isUnitsBased: isUnitsBased,
                                    units: units,
                                    isInvest: isInvest
                                })
                            } else if (SCREENTYPE === 1) {
                                //etf
                                return await saveOrder({
                                    navigation,
                                    order: a,
                                    clientId: p.id,
                                    mintboxId: FUNDDATA.MintboxId || 0,
                                    goalId: FUNDDATA.GoalId || 0,
                                    fundCode: FUNDDATA.FundCode,
                                    amount: amount,
                                    isSIP: isSIP,
                                    sipDate: sipDate,
                                    isUnitsBased: isUnitsBased,
                                    units: units,
                                    isInvest: isInvest
                                })
                            }
                        });
                    }
                })
                .catch((p) => {
                    if (p.toString().includes("market_closed")) {
                        console.log('mkt closed')
                        return 'market_closed'
                    } else if (p.message === 'user_cancelled') {
                        return
                    } else {
                        toast.error('Something went wrong! Try again.')
                    }
                });
        } catch (e) {
            toast.error("Something went wrong! Try again.")
        }
    } else {
        console.log('Error getting Transaction Id');
    }
}
