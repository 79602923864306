import { isNumber } from "lodash";

function normalFormatterWithoutPrefix(num, tofix = 0) {
    const a = new Intl.NumberFormat("en-IN", {
        // minimumFractionDigits: tofix,
        // maximumFractionDigits: tofix,
    }).format(num);
    return a
    // return num.toFixed(tofix).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function kFormatter(val) {
    if (val >= 10000000) { val = `${(val / 10000000).toFixed(2)} cr` }
    else if (val >= 100000) { val = `${(val / 100000).toFixed(2)} lac` }
    else if (val >= 1000) { val = `${(val / 1000).toFixed(2)} k` }
    else { val = normalFormatterWithoutPrefix(val) }
    return val.replace('-', '');
}

export function DecimalValueFormat({ num = 0, suffix = "", prefix = "", placeholder = "-", decimals = 2, type = "long" }) {
    num = +parseFloat(num).toFixed(decimals)
    return isNaN(num) ? placeholder : (prefix + (type === "long" ? normalFormatterWithoutPrefix(num, decimals) : kFormatter(num)) + suffix);
}