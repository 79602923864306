import { Cascader, Empty, Input, Select } from "antd"
import { select } from "d3";
import { isObject } from "lodash";
import React, { useEffect, useRef, useState } from "react"
import { BiSearch } from "react-icons/bi";
import { IoIosArrowDown } from "react-icons/io";
import { MdOutlineCancel } from "react-icons/md";
import { v4 } from "uuid";
import checkmark from '../../Assets/icons/checkmark.png';

export const Dropdown = ({ ...selectProps }) => {
    let updPlaceholder = selectProps.showSearch ? (
        <React.Fragment>
            <div>
                <BiSearch className='dark2 textSM' style={{ marginRight: "0.2rem" }} />
                <span className='dark2 textSM'>{selectProps?.placeholder}</span>
            </div>
        </React.Fragment>
    ) : <span className='dark2 textSM'>{selectProps?.placeholder}</span>;
    let styles = isObject(selectProps?.style) ? selectProps?.style : {};
    let headStyles = isObject(selectProps?.headStyles) ? selectProps?.headStyles : {};
    return (
        <React.Fragment>
            <Select
                placeholder={updPlaceholder}
                value={selectProps?.value || null}
                style={{ width: "20rem", ...styles }}
                className={`customSelect `}
                allowClear={selectProps?.allowClear}
                clearIcon={<MdOutlineCancel color='var(--white)' size='0.8rem' style={{
                    marginTop: "-0.2rem", background: "var(--dark3)",
                    borderRadius: "50%"
                }} />}
                showSearch={selectProps?.showSearch} showArrow={!selectProps?.showSearch}
                suffixIcon={selectProps.suffixIcon || <IoIosArrowDown className="textSM dark2" />}
                options={selectProps?.options || []}
                onChange={selectProps?.onChange || (() => { })}
                filterOption={selectProps?.filterOption}
                mode={selectProps.multiple ? "multiple" : selectProps?.tags ? "tags" : ""}
                dropdownStyle={{
                    '--scrollbar': `${selectProps.hideScrollbar ? "none" : "auto"}`, '--width': selectProps?.dropdownWidth ||
                        styles.width || '20rem'
                }}
                placement={selectProps?.placement || 'bottomLeft'}
            />
        </React.Fragment>
    )
}

export const Dropdown2 = ({
    placeholder = '',
    options = options2,
    showSearch = false,
    isMultiple = false,
    allowClear = false,
    value,
    onChange = () => { }
}) => {

    const [showList, setShowList] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [selectedValues, setSelectedValues] = useState([]);
    const inputRef = useRef();
    const [uniqueId] = useState('su' + v4());

    const handleOnSelect = (item, isSelected) => {
        if (!isMultiple) {
            setSelectedValues([item]);
            setSearchText('');
            onChange(item?.value)
            setShowList(false);
            inputRef.current?.focus();
        }
        else {
            if (isSelected) {
                setSelectedValues(selectedValues?.filter((ele) => ele?.value !== item?.value) || []);
                onChange(selectedValues?.filter((ele) => ele?.value !== item?.value)?.map(ele => ele?.value))
            }
            else {
                setSelectedValues([...selectedValues, item]);
                onChange([...selectedValues, item]?.map((ele) => ele?.value));
            }
            setSearchText('');
            inputRef?.current?.focus();
        }
    }

    const onClear = (e) => {
        setSelectedValues([]);
        setSearchText('');
        if (!isMultiple) onChange('');
        else onChange([]);
        setShowList(false);
        e?.stopPropagation();
    }

    const removeItem = (e) => {
        let temp = [...selectedValues];
        temp?.shift();
        setSelectedValues(temp);
        onChange(temp?.map((item) => item?.value))
        e.stopPropagation();
    }
    
    const handleOnSearch = (e) => {
        setSearchText(e.target.value);
    }

    const handleDocumentClick = (e) => {
        let target = document.getElementById(`${uniqueId}`);
        let currTarget = e.target
        do {
            if (target === currTarget) {
                return;
            }
            currTarget = currTarget.parentNode
        }
        while (currTarget) {
            setShowList(false)
        }
    }

    useEffect(() => {
        document.addEventListener('click', handleDocumentClick);
        return () => {
            document.removeEventListener('click', handleDocumentClick);
        }
    }, []);

    useEffect(() => {
        if (!isMultiple) {
            if (!value) setSelectedValues([]);
            else {
                setSelectedValues(options?.filter((ele) => ele?.value === value) || []);
            }
        }
        else {
            if (!value?.length) setSelectedValues([]);
            else {
                setSelectedValues(options?.filter((ele) => value?.includes(ele?.value)) || []);
            }
        }
    }, [value])
    return (
        <React.Fragment>
            <div className='customDropdown' id={uniqueId}>
                <div className={`customDropdownInput focus${showList}`}
                    onClick={() => {
                        setShowList(prev => {
                            return !prev;
                        });
                        inputRef?.current?.focus()
                    }} >
                    <div className="customDropdownInpCont">
                        {isMultiple === true && selectedValues?.length > 0 && <div className='multipleSelectedContainer'>
                            <div className='firstItem'>
                                <span>{selectedValues?.[0]?.label}</span>
                                <MdOutlineCancel color='var(--white)' size='0.8rem' style={{
                                    marginTop: "-0.2rem", background: "var(--dark3)",
                                    borderRadius: "50%", flexShrink: 0
                                }} onClick={removeItem} />
                            </div>
                            {selectedValues?.length > 1 && <div className='otherItems'>+{selectedValues?.length - 1}</div>}
                        </div>}
                        <div className='inputCDT'>
                            {selectedValues?.length <= 0 && <BiSearch className='dark2 textSM' />}
                            {isMultiple === false && selectedValues.length > 0 && searchText.length <= 0 && (
                                <div className={`customDropdownSelectedValue ${showList ? "inactive" : ""}`}>
                                    {selectedValues[0]?.label}
                                </div>)}
                            <input autoComplete="off" ref={inputRef} placeholder={placeholder} readOnly={!showSearch}
                                style={{ cursor: showSearch ? "initial" : "pointer" }} value={searchText} onChange={handleOnSearch} />
                        </div>
                        {showSearch === false && (allowClear ? selectedValues?.length ? false : true : true) &&
                            <IoIosArrowDown className={`textSM dark2`} style={{ flexShrink: 0 }} />}
                        {selectedValues?.length > 0 && allowClear && <MdOutlineCancel color='var(--white)' size='0.8rem' style={{
                            marginTop: "-0.2rem", background: "var(--dark3)",
                            borderRadius: "50%", flexShrink: 0
                        }} onClick={(e) => onClear(e)} />}
                    </div>
                </div>
                {showList && <div className="customDropdownList">
                    {options?.filter((item) => {
                        if (!searchText?.trim()) return true;
                        else {
                            return item?.value?.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
                        }
                    })?.map((item) => {
                        let isSelected = selectedValues?.filter((ele) => ele?.value === item?.value)?.[0] ? true : false;
                        return (
                            <div className={`customDropdownItem selected-item-${isSelected}`} onClick={() => handleOnSelect(item, isSelected)}>
                                <span>{item.label}</span>
                                {isSelected && <img src={checkmark} />}
                            </div>
                        )
                    })}
                    {options?.filter((item) => {
                        if (!searchText?.trim()) return true;
                        else {
                            return item?.value?.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
                        }
                    })?.length ? null : (
                        <div className='textSM d-flex align-items-center justify-content-center p-2'>
                            <Empty className='textSM' imageStyle={{ height: "5rem" }} />
                        </div>
                    )}
                </div>}
            </div>
        </React.Fragment>
    )
}

let options2 = [
    {
        "value": "NIFTY 50 Total Return Index",
        "label": "NIFTY 50 Total Return Index"
    },
    {
        "value": "S&P BSE Sensex Total Return Index",
        "label": "S&P BSE Sensex Total Return Index"
    },
    {
        "value": "NIFTY Next 50 Total Return Index",
        "label": "NIFTY Next 50 Total Return Index"
    },
    {
        "value": "NIFTY 1D Rate Index",
        "label": "NIFTY 1D Rate Index"
    },
    {
        "value": "NIFTY Bank Total Return Index",
        "label": "NIFTY Bank Total Return Index"
    },
    {
        "value": "Domestic Price of Gold",
        "label": "Domestic Price of Gold"
    },
    {
        "value": "Domestic Price of Gold Index",
        "label": "Domestic Price of Gold Index"
    },
    {
        "value": "NIFTY PSU Bank Total Return Index",
        "label": "NIFTY PSU Bank Total Return Index"
    },
    {
        "value": "NIFTY 50 Shariah Total Return Index",
        "label": "NIFTY 50 Shariah Total Return Index"
    },
    {
        "value": "Hang Seng Total Return Index",
        "label": "Hang Seng Total Return Index"
    },
    {
        "value": "NIFTY Infrastructure Total Return Index",
        "label": "NIFTY Infrastructure Total Return Index"
    },
    {
        "value": "NIFTY Midcap 100 Total Return Index",
        "label": "NIFTY Midcap 100 Total Return Index"
    },
    {
        "value": "Nasdaq 100 Total Return Index",
        "label": "Nasdaq 100 Total Return Index"
    },
    {
        "value": "NIFTY 100 Total Return Index",
        "label": "NIFTY 100 Total Return Index"
    },
    {
        "value": "NIFTY India Consumption Total Return Index",
        "label": "NIFTY India Consumption Total Return Index"
    },
    {
        "value": "NIFTY CPSE Total Return Index",
        "label": "NIFTY CPSE Total Return Index"
    },
    {
        "value": "NIFTY Dividend Opportunities 50 Total Return Index",
        "label": "NIFTY Dividend Opportunities 50 Total Return Index"
    },
    {
        "value": "NIFTY 8-13 yr G-Sec Index",
        "label": "NIFTY 8-13 yr G-Sec Index"
    },
    {
        "value": "NIFTY 50 Value 20 Total Return Index",
        "label": "NIFTY 50 Value 20 Total Return Index"
    },
    {
        "value": "NIFTY 10 yr Benchmark G-Sec Index",
        "label": "NIFTY 10 yr Benchmark G-Sec Index"
    },
    {
        "value": "S&P BSE Midcap Select Total Return Index",
        "label": "S&P BSE Midcap Select Total Return Index"
    },
    {
        "value": "NIFTY 100 Low Volatility 30 Total Return Index",
        "label": "NIFTY 100 Low Volatility 30 Total Return Index"
    },
    {
        "value": "S&P BSE Bharat 22 Total Return Index",
        "label": "S&P BSE Bharat 22 Total Return Index"
    },
    {
        "value": "S&P BSE 500 Total Return Index",
        "label": "S&P BSE 500 Total Return Index"
    },
    {
        "value": "S&P BSE Liquid Rate Index",
        "label": "S&P BSE Liquid Rate Index"
    },
    {
        "value": "NIFTY 200 Quality 30 Total Return Index",
        "label": "NIFTY 200 Quality 30 Total Return Index"
    },
    {
        "value": "NIFTY Midcap 150 Total Return Index",
        "label": "NIFTY Midcap 150 Total Return Index"
    },
    {
        "value": "S&P BSE Sensex Next 50 Total Return Index",
        "label": "S&P BSE Sensex Next 50 Total Return Index"
    },
    {
        "value": "Nifty Private Bank TRI",
        "label": "Nifty Private Bank TRI"
    },
    {
        "value": "NIFTY Bharat Bond Index Series - April 2023",
        "label": "NIFTY Bharat Bond Index Series - April 2023"
    },
    {
        "value": "NIFTY Bharat Bond Index Series - Apri 2030",
        "label": "NIFTY Bharat Bond Index Series - Apri 2030"
    },
    {
        "value": "NIFTY IT Total Return Index",
        "label": "NIFTY IT Total Return Index"
    },
    {
        "value": "NIFTY Bharat Bond Index Series - April 2025",
        "label": "NIFTY Bharat Bond Index Series - April 2025"
    },
    {
        "value": "NIFTY Bharat Bond Index Series - Apri 2031",
        "label": "NIFTY Bharat Bond Index Series - Apri 2031"
    },
    {
        "value": "Nifty Alpha Low-Volatility 30 Total Return Index",
        "label": "Nifty Alpha Low-Volatility 30 Total Return Index"
    },
    {
        "value": "Nifty 100 ESG Sector Leaders Total Return Index",
        "label": "Nifty 100 ESG Sector Leaders Total Return Index"
    },
    {
        "value": "Nifty CPSE Bond Plus SDL Sep 2024 50:50 Index",
        "label": "Nifty CPSE Bond Plus SDL Sep 2024 50:50 Index"
    },
    {
        "value": "Nifty 5yr Benchmark G-Sec Index",
        "label": "Nifty 5yr Benchmark G-Sec Index"
    },
    {
        "value": "Nifty SDL Apr 2026 Top 20 Equal Weight Index",
        "label": "Nifty SDL Apr 2026 Top 20 Equal Weight Index"
    },
    {
        "value": "NYSE FANG+ Total Return Index",
        "label": "NYSE FANG+ Total Return Index"
    },
    {
        "value": "Nifty AAA Bond Plus SDL Apr 2026 50:50 Index",
        "label": "Nifty AAA Bond Plus SDL Apr 2026 50:50 Index"
    },
    {
        "value": "NIFTY Healthcare Total Return Index.",
        "label": "NIFTY Healthcare Total Return Index."
    },
    {
        "value": "NIFTY Pharma Total Return Index",
        "label": "NIFTY Pharma Total Return Index"
    },
    {
        "value": "NIFTY FMCG Total Return Index",
        "label": "NIFTY FMCG Total Return Index"
    },
    {
        "value": "NIFTY Financial Services Total Return Index",
        "label": "NIFTY Financial Services Total Return Index"
    },
    {
        "value": "S&P 500 Top 50 Total Return Index",
        "label": "S&P 500 Top 50 Total Return Index"
    },
    {
        "value": "NIFTY 50 Equal Weight Total Return Index",
        "label": "NIFTY 50 Equal Weight Total Return Index"
    },
    {
        "value": "Hang Seng TECH Total Return Index",
        "label": "Hang Seng TECH Total Return Index"
    },
    {
        "value": "Nifty Alpha 50 Total Return Index",
        "label": "Nifty Alpha 50 Total Return Index"
    },
    {
        "value": "Nifty BHARAT Bond Index - April 2032",
        "label": "Nifty BHARAT Bond Index - April 2032"
    },
    {
        "value": "NIFTY Midcap150 Quality 50 Total Return Index",
        "label": "NIFTY Midcap150 Quality 50 Total Return Index"
    },
    {
        "value": "NASDAQ Q-50 Total Return Index",
        "label": "NASDAQ Q-50 Total Return Index"
    },
    {
        "value": "NIFTY Auto Total Return Index",
        "label": "NIFTY Auto Total Return Index"
    },
    {
        "value": "Domestic Price of Silver",
        "label": "Domestic Price of Silver"
    },
    {
        "value": "NIFTY Midcap 50 Total Return Index",
        "label": "NIFTY Midcap 50 Total Return Index"
    },
    {
        "value": "Domestic Price of Silver Index",
        "label": "Domestic Price of Silver Index"
    },
    {
        "value": "Nifty India Manufacturing Total Return Index",
        "label": "Nifty India Manufacturing Total Return Index"
    },
    {
        "value": "Nifty 200 Momentum 30 Total Return Index",
        "label": "Nifty 200 Momentum 30 Total Return Index"
    },
    {
        "value": "S&P BSE Low Volatility Total Return Index",
        "label": "S&P BSE Low Volatility Total Return Index"
    },
    {
        "value": "Nifty India Digital Total Return Index",
        "label": "Nifty India Digital Total Return Index"
    },
    {
        "value": "S&P BSE Healthcare Total Return Index",
        "label": "S&P BSE Healthcare Total Return Index"
    },
    {
        "value": "NIFTY MNC Total Return Index",
        "label": "NIFTY MNC Total Return Index"
    },
    {
        "value": "S&P BSE Quality Total Return Index",
        "label": "S&P BSE Quality Total Return Index"
    },
    {
        "value": "S&P BSE Enhanced Value Total Return Index",
        "label": "S&P BSE Enhanced Value Total Return Index"
    },
    {
        "value": "NIFTY 100 Quality 30 Total Return Index",
        "label": "NIFTY 100 Quality 30 Total Return Index"
    },
    {
        "value": "NIFTY Growth Sectors 15 Total Return Index",
        "label": "NIFTY Growth Sectors 15 Total Return Index"
    },
    {
        "value": "NIFTY Commodities Total Return Index",
        "label": "NIFTY Commodities Total Return Index"
    },
    {
        "value": "NIFTY Smallcap 250 Total Return Index",
        "label": "NIFTY Smallcap 250 Total Return Index"
    }
]