import React from 'react';
import etf from '../Assets/images/Categories/etf.svg';
import mf from '../Assets/images/Categories/mf.svg';
import stocks from '../Assets/images/Categories/stocks.svg';

export const CategoryIcon = ({ title = '' }) => {

    const iconslist = [
        { title: "ETF", image: etf },
        { title: "Mutual Funds", image: mf },
        { title: "Stocks", image: stocks }
    ]
    return (
        <React.Fragment>
            <div>
                <img src={iconslist?.filter((ele) => ele.title?.toLowerCase() === title.toLowerCase())?.[0]?.image || iconslist[0]?.image}
                    style={{ width: "5.5rem", height: "5.5rem", borderRadius: "50%" }} />
            </div>
        </React.Fragment>
    )
}