import { Card } from 'antd';
import { AiFillRightCircle } from 'react-icons/ai';

const DashboardCards1 = ({ data }) => {
    const SmallBox = ({ slide }) => {
        return (
            <Card bordered={false} 
                bodyStyle={{
                    display: "flex",
                    alignItems: 'center',
                }}
                key={slide.UID}
                style={{
                    width: '48%',
                }}
                onClick={() => slide.onClick()}
                hoverable
            >
                <div style={{ flex: 1 }}>{slide.heading}</div>
                <AiFillRightCircle
                    style={{
                        color: slide.btnColor,
                        height: '1rem', width: '1rem'
                    }}
                />
            </Card>
        );
    };
    return <div key={data.UID}>
        <div className='fontH2 mb-2 w-500'>
            {data.heading}
        </div>
        <div style={{ display: 'flex', columnGap: '4%',rowGap:'1rem', flexWrap: 'wrap' }}>
            {
                data.slides.map((component, i) => {
                    return <SmallBox key={i + "sadf"} slide={component} />
                })
            }
        </div>
    </div>
}

export default DashboardCards1;