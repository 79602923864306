import { Button, Card, Segmented, Table, Tag, Timeline, Tooltip } from "antd";
import ActivityLoaderMintbox from "Components/ActivityLoaderMintbox.js";
import AMCIcon from "Components/AMCIcon";
import { CurrencyFormat } from "Components/CurrencyFormat.js";
import CustomCollapseTabs from "Components/CustomCollapseTabs";
import CustomLabelValueCards from 'Components/CustomLabelValue';
import CustomListTable from "Components/CustomListTable";
import CustomSharpelyScoreProgressBar from "Components/CustomSharpelyScoreProgressBar";
import { changeObjKey } from "Components/DataCustomizer";
import { formatDate } from "Components/DateFormatter";
import { DecimalValueFormat } from "Components/DecimalValueFormat";
import ErrorComponent from "Components/ErrorComponent";
import { InsightsModal } from 'Components/Insights';
import InvestmentCard from 'Components/InvestmentCard';
import MintboxIcon from "Components/MintboxIcon";
import PerformanceChart2 from "Components/PerformanceChart2";
import PerformanceChart3 from 'Components/PerformanceChart3';
import ReadMoreText from "Components/ReadMoreText";
import { RiskFormat, RiskTextColor } from "Components/RiskFunctions";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Head from "Layout/Head";
import { screenerMetricData, useGetAssetType } from "Libs/utils";
import moment from "moment";
import React, { useState } from "react";
import { AiOutlineLineChart } from "react-icons/ai";
import { BsBookmark, BsFillBookmarkFill } from 'react-icons/bs';
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { useLocation, useNavigate } from "react-router";
import { Col, Collapse as CollapseReact, Row } from "reactstrap";
import apiHelper from 'Services/api/ApiHelper';
import apiHelperPY from 'Services/api/ApiHelperPY';

const StockPortfolioDetail = (props) => {
    // const MINTBOX_ID = Number(useParams().code)
    const MINTBOX_ID = 52
    const type = useGetAssetType() // 1 = etf, 2 = mf
    const navigate = useNavigate()
    const { state } = useLocation()
    const [user, setUser] = useState()

    const [loading, setLoading] = useState(true);

    const [fundData, setFundData] = useState({});
    const [fundDetails, setfundDetails] = useState({});
    const [isWatchlisted, setIsWatchlisted] = useState(false);

    const [apiError, setApiError] = useState(false)

    const [industries, setIndustries] = useState({});
    const [basicIndustries, setBasicIndustries] = useState({});
    const [sectors, setSectors] = useState({});
    const [macroSectors, setMacroSectors] = useState({});
    const [industriesCurrent, setIndustriesCurrent] = useState('');
    const [basicIndustriesCurrent, setBasicIndustriesCurrent] = useState('');
    const [sectorsCurrent, setSectorsCurrent] = useState('');
    const [macroSectorsCurrent, setMacroSectorsCurrent] = useState('');
    const [stockStyles, setStockStyles] = useState({});
    const [screenerMetric, setscreenerMetric] = useState([])
    const [metricDescription, setMetricDescription] = useState([])
    const [styleBoxes, setStyleBoxes] = useState([])
    const [screenerFinalData, setscreenerFinalData] = useState([])
    const [stockSectorCLF, setStockSectorCLF] = useState([])
    const [allFilteredFundsData, setallFilteredFundsData] = useState([])
    const [allFundsData, setallFundsData] = useState([])
    const [isOpen, setisOpen] = useState(0)
    const [isV1Open, setisV1Open] = useState(-1)
    const [isV2Open, setisV2Open] = useState(-1)
    const [screener_final_query, set_screener_final_query] = useState([])
    const [newFiltersList, setnewFiltersList] = useState([])
    const [mfCategories, setmfCategories] = useState([])
    const [mfAMC, setmfAMC] = useState([])
    const [screenerMetricDescData, setScreenerMetricDescData] = useState([])

    const dataTableColumns = [
        {
            "title": "Name",
            "dataIndex": "proper_name",
            render: (text, record, index) => <div style={{ display: 'flex', gap: '12px', cursor: "pointer" }} onClick={() => navigate({ pathname: type.basePath + `${type.value === 4 ? '/stock-detail/' + record.symbol : '/fund-detail/' + record?.basic_name?.replace(" - Direct Plan", "")?.replaceAll(' ', '-') + '?code=' + record.plan_id}` })}>
                <AMCIcon amcName={record.symbol} fundType={type.value} height='1.2rem' width="1.2rem" />
                <div>
                    <div className="textSM black">{record?.proper_name}</div>
                    <div className="text-left textXS ">{stockSectorCLF?.find(s => s.industry_code === record.industry_code)?.industry_desc}</div>
                </div>
            </div>,
            width: 230,
            fixed: 'left',
            show: true
        },
        {
            "title": "Weights",
            "dataIndex": "weights",
            render: (text, record, index) => DecimalValueFormat({ num: text, suffix: '%', decimals: 2, placeholder: '-' }),
            width: 100,
            align: 'right',
            show: true
        }
    ]

    React.useEffect(() => {
        const dem = async () => {
            setLoading(true)
            // let a = await fetchFundData(3, false)
            // const result = a.filter(x => x.UID === MINTBOX_ID);
            // setFundData(result[0])
            // console.log('fundData', result[0])

            //unsaved custom mb
            // var dataObj = {
            //     "mintbox_type": 3,
            //     "instrument_type": 3,
            //     "instrument_method": 1,
            //     "tickers": ["RELIANCE", "TCS", "INFY", "ITC", "HDFCBANK", "ADANIPORTS"], "weights": [0.2, 0.2, 0.15, 0.15, 0.15, 0.15],
            //     "rebal_freq": "3M", "weight_method": 6, "rebal_method": 1, "mintbox_bm_id": 1, "mintbox_bm_name": "Nifty 50 TRI"
            // }
            var dataObj = {
                "mintbox_type": 3,
                "isins": [],
                "tickers": [
                    "TCS",
                    "3MINDIA",
                    "4THDIM",
                    "5PAISA",
                    "AAREYDRUGS"
                ],
                "instrument_type": 3,
                "instrument_method": 1,
                "weights": [
                    0.2,
                    0.2,
                    0.2,
                    0.2,
                    0.2
                ],
                "rebal_method": 1,
                "weight_method": 1,
                "screen_query": [],
                "rebal_freq": "Q",
                "mintbox_name": "hjvvjjvh",
                "mintbox_bm_id": 1,
                "mintbox_bm_name": "Nifty IT Index TRI"
            }
            // saved custom
            // var dataObj = {
            //     "mintbox_id": 52,
            //     "mintbox_type": 2,
            //     "instrument_type": 3,
            // }

            if (state?.portfolio_params !== undefined) {
                dataObj = state?.portfolio_params
            }

            let res2 = await apiHelperPY({ apiName: 'getCuratedPortfolioDetails', data: dataObj, saveResponse: '' })
            if (res2) {
                console.log('getCuratedPortfolioDetails fundDetails', JSON.parse(res2.replace(/NaN/g, null)))
                setfundDetails(JSON.parse(res2.replace(/NaN/g, null)))
            }
            else {
                // setApiError(true)
                setfundDetails({})
            }

            var screener_datas = await screenerMetricData(type)
            console.log(screener_datas)
            setisOpen(screener_datas?.levelVals?.level1)
            setisV1Open(screener_datas?.levelVals?.level2)
            setisV2Open(screener_datas?.levelVals?.level3)
            setStockSectorCLF(screener_datas?.fabricatedData?.stockSectorCLF)
            setStyleBoxes(screener_datas?.fabricatedData?.stockStyles)
            setMacroSectors(screener_datas?.fabricatedData?.macroSectors)
            setSectors(screener_datas?.fabricatedData?.sectors)
            setIndustries(screener_datas?.fabricatedData?.industries)
            setBasicIndustries(screener_datas?.fabricatedData?.basicIndustries)
            setmfCategories(screener_datas?.fabricatedData?.mf_categories)
            setmfAMC(screener_datas?.fabricatedData?.mf_amcs)
            setscreenerMetric(screener_datas?.screenerMetricData)
            setscreenerFinalData(screener_datas?.screenerFinalData)
            setallFundsData(screener_datas?.allFunds)
            setallFilteredFundsData(screener_datas?.allFunds)
            setScreenerMetricDescData(screener_datas?.screenerMetricDescData)
            setLoading(false)
        }
        dem();
    }, [MINTBOX_ID])

    const updateWatchlist = async () => {
        if (user) {
            const data = {
                "clientId": user.id,
                "fundType": type.value,
                "fundCode": type.value === 1 ? fundData.nse_symbol : fundData.isin_code,
                "fundId": fundData.isin_code + "",
                "isActive": isWatchlisted ? 0 : 1
            }
            await apiHelper({ apiName: 'updateWatchList', data: data })
            await apiHelper({ apiName: 'getWatchList', data: { id: user.id }, saveResponse: 'sessionStorage', getFresh: true })
            setIsWatchlisted(!isWatchlisted)
        } else {
            navigate('/auth-login')
        }
    }

    const PortfolioBenchmarkTable = ({ data }) => {
        const dataSource = Object.keys(data || {}).map(f => {
            return {
                'name': f,
                ...data[f]
            }
        });
        const cols = Object.keys(data.Strategy || {}).map(t => {
            return {
                title: t,
                width: 100,
                dataIndex: t,
                align: "right",
            }
        })
        const columns = [
            {
                title: '',
                dataIndex: 'name',
                key: 'name',
                width: 150,
                fixed: 'left'
            },
            ...cols
        ]
        return <Table dataSource={dataSource} columns={columns} size={'small'} bordered className='textXS black' pagination={false} />
    }

    const TableConstructor = ({ tableName = "", tableData = {}, showNameColumn = false, isTranspose = false }) => {
        if (isTranspose) {
            var dataSource = Object.values(tableData || {})
            var dataSourceKeys = Object.keys(tableData || {})

            var smDataSource = Object.keys(dataSource[0] || {})
            smDataSource = smDataSource.map((key, index) => {
                var i = {
                    "key": key,
                    "name": key
                }
                dataSourceKeys.map((finalkey, index) => {
                    i = {
                        ...i,
                        [finalkey]: new Date(dataSource[index][key]) !== "Invalid Date" && !dataSource[index][key].includes("%") && !dataSource[index][key].includes(".") ? formatDate(dataSource[index][key]) : dataSource[index][key]
                    }
                })
                return i
            })
            dataSourceKeys = ["name"].concat(dataSourceKeys)
            var columns = dataSourceKeys.map((key, index) => {
                return {
                    title: key === "name" ? "" : key,
                    dataIndex: key,
                    key: key,
                    width: 100,
                    align: key === "name" ? "left" : "right",
                }
            })
            return <div className=''>
                <Table scroll={{ x: isTranspose ? 300 : 800 }} dataSource={smDataSource} columns={columns} size={'small'} bordered className='textXS black' pagination={false} /></div>
        } else {
            var dataSource = Object.values(tableData || {})
            var dataSourceKeys = Object.keys(tableData || {})

            if (showNameColumn) {
                dataSource = dataSource.map((item, index) => {
                    return {
                        ...item,
                        "col1_name_custom": dataSourceKeys[index]
                    }
                })
            }

            var columns = Object.entries(dataSource?.[0] || {}).map(([key, val], index) => {
                return {
                    title: key,
                    dataIndex: key,
                    key: key,
                    width: 100,
                    align: () => {
                        if (new Date(val) !== "Invalid Date" || val.includes("%") || val.includes(".") || isNaN(Number(val))) {
                            return "right"
                        } else {
                            return "left"
                        }
                    },
                    fixed: key === "col1_name_custom" ? "left" : false,
                    render: (text) => {
                        if (new Date(text) !== "Invalid Date" && !text.includes("%") && !text.includes(".")) {
                            return formatDate(text)
                        } else {
                            return text
                        }
                    }
                }
            })


            if (showNameColumn) {
                columns.splice(columns.indexOf((i) => i.key === "col1_name_custom"), 1)
                columns = [{
                    title: "",
                    width: 150,
                    dataIndex: "col1_name_custom",
                    key: "col1_name_custom",
                    fixed: "left",
                }].concat(columns)
            }
            return <div className='my-3'>
                <div className='fontH2 w-700 mb-2'>{tableName}</div><Table scroll={{ x: 800 }} dataSource={dataSource} columns={columns} size={'small'} bordered className='textXS black' pagination={false} /></div>
        }
    }

    const PortfolioExpense = ({ expenseRatio }) => {
        return <div style={{ maxWidth: '25rem' }}>
            <div style={{ display: "flex", alignItems: 'center', padding: '0.4rem 0 1rem' }}>
                <div className="textSM" style={{ flex: 1 }}>Avg expense ratio of underlying ETFs</div>
                <div className="fontH2" style={{ textAlign: "right" }}>{DecimalValueFormat({
                    num: expenseRatio,
                    suffix: "%", placeholder: "-"
                })}</div>
            </div>
            <div style={{ borderTop: '1px solid var(--border)', display: "flex", alignItems: 'center', padding: '1rem 0' }}>
                <div className="textSM" style={{ flex: 1 }}>mintbox management fee</div>
                <div className="fontH2" style={{ textAlign: "right", color: 'var(--primary)' }}>0.00%</div>
            </div>
            <div style={{ borderTop: '1px solid var(--border)', display: "flex", alignItems: 'center', padding: '1rem 0' }}>
                <div className="fontH2" style={{ flex: 1 }}>Total fee to invest in this mintbox</div>
                <div className="fontH2" style={{ textAlign: "right" }}>{
                    DecimalValueFormat({
                        num: expenseRatio,
                        suffix: "%", placeholder: "-"
                    })
                }</div>
            </div>
            <div style={{ borderTop: '1px solid var(--border)', paddingTop: '1rem' }}>
                <div className="textSM">It can’t get any cheaper than this. For comparison, the annual fee of a direct mutual fund is <b>1.00%</b>. That of regular mutual funds and PMS are <b>2.00%</b> and <b>2.50%</b> respectively.</div>
            </div>
        </div>
    }

    const assetAlloConfig = {
        chart: {
            type: 'pie',
            backgroundColor: 'transparent',
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            height: 220
        },
        title: {
            text: ''
        },
        scrollbar: {
            enabled: false
        },
        exporting: {
            enabled: false
        },

        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: false,
                },
                showInLegend: true,
                size: 180,
                innerSize: '60%',
            }
        },
        credits: {
            enabled: false
        },

        tooltip: {
            headerFormat: '',
            pointFormat: '{point.name}'
        },
        legend: {
            enabled: true,
            align: 'right',
            verticalAlign: 'middle',
            itemMarginBottom: 10,
            itemStyle: {
                color: '#333333',
                fontSize: '13px',
                fontWeight: "normal"
            }

        },
        series: [{
            data: Object.entries(fundDetails?.overview_dict?.asset_allocation || {})?.sort(([, a], [, b]) => Number(a) - Number(b)).map((a, index) => {
                // const finalbg = {
                //     'equity': ,
                //     'debt': t.colors.assetColors.debt,
                //     'others': t.colors.assetColors.USequity,
                //     'commodities': t.colors.assetColors.gold,
                //     'hybrid': t.colors.assetColors.cash,
                // };
                return {
                    name: a?.[0].charAt(0).toUpperCase() + a[0].slice(1),
                    y: a?.[1],
                    // color: finalbg[a[0]],
                    z: index + 1
                }
            })
        }]
    }

    const subscription_details = [{
        key: 'a',
        heading: 'Min. lumpsum',
        value: CurrencyFormat(fundDetails?.portfolio_params?.min_lumpsum)
    }, {
        key: 'b',
        heading: 'Min. SIP investment',
        value: CurrencyFormat(fundDetails?.portfolio_params?.min_sip),
    }, {
        key: 'c',
        heading: 'Min. subsequent investment',
        value: CurrencyFormat(fundDetails?.portfolio_params?.min_subsequent_lumpsum)
    },
        // {
        //     key: 'd',
        //     heading: 'Portfolio expense',
        //     show: fundDetails?.portfolio_params?.IsCustom === 1,
        //     value: DecimalValueFormat({
        //         num: fundDetails?.overview_dict?.portfolio_params?.expense,
        //         suffix: "%", placeholder: "-"
        //     })
        // },
        // {
        //     key: 'e',
        //     heading: 'Lock in',
        //     value: <Text onPress={() => {
        //         setModalType(4)
        //         setIsModelOpen(true)
        //     }} style={{ ...s.font12DarkNormal, color: t.colors.secondary }}>View constituents Lock in</Text>
        // }, {
        //     key: 'f',
        //     heading: 'Exit load',
        //     value: <Text onPress={() => {
        //         setModalType(3)
        //         setIsModelOpen(true)
        //     }} style={{ ...s.font12DarkNormal, color: t.colors.secondary }}>View constituents Exit load</Text>
        // }
    ]

    const TableCustomTwoCol = ({ data, firstColName = '', secondColName = '' }) => {
        const dataSource = Object.keys(data || {}).map(f => {
            return {
                'first': f,
                'second': data[f]
            }
        });
        const columns = [
            {
                title: firstColName,
                dataIndex: 'first',
                width: 250,
                fixed: 'left'
            },
            {
                title: secondColName,
                dataIndex: 'second',
                width: 50,
                align: 'right',
                render: (text) => DecimalValueFormat({
                    num: text, suffix: ' %', decimals: 1, placeholder: "-"
                })
            },
        ]
        if (data) {
            return <Table style={{ width: '25rem' }} dataSource={dataSource} columns={columns} size={'small'} bordered className='textXS black' pagination={{ hideOnSinglePage: true, pageSize: 10 }} />
        } else return <></>
    }

    const SectorAllocationTable = ({ data, header }) => {
        const options1 = [
            {
                key: 'sector',
                name: 'Sector'
            },
            {
                key: 'industry_group',
                name: 'Industry group'
            },
            {
                key: 'industry',
                name: 'Industry'
            },
        ]
        const [selected, setSelected] = React.useState(options1[0])

        const dataSource = fundDetails?.[selected.key]
        const columns = [{
            title: selected.name,
            dataIndex: 'descs',
            fixed: 'left',
            render: (text, record) => text
        },
        {
            title: 'Alocation',
            dataIndex: 'weights',
            align: 'right',
            render: (text, record) => DecimalValueFormat({ num: Number(text), suffix: "%", placeholder: "-" })
        }
        ]

        return <>
            <Segmented style={{ border: '1px solid var(--border)', marginBottom: '0.5rem' }} size='small' value={selected.name} options={options1.map(m => m.name)} onChange={(e) => setSelected(options1.find(o => o.name === e))} />
            <Table dataSource={dataSource} columns={columns} size={'small'} bordered className='textXS black' pagination={false} />
        </>

    }

    const ExitLoadLockinTable = ({ data }) => {
        const dataSource = data;
        const columns = [
            {
                title: 'Instrument',
                dataIndex: 'basic_name',
                width: 250,
                fixed: 'left',
                render: (text, record) => {
                    return <div style={{ display: 'flex', gap: '0.25rem' }}>
                        <AMCIcon amcName={record?.amc_full_name} fundType={fundDetails?.portfolio_params?.FundType} height='1rem' width='1rem' />
                        <div className="textSM">{record?.basic_name}</div>
                    </div>
                },
                show: true
            },
            {
                title: 'Expense ratio',
                dataIndex: 'expense_ratio',
                align: 'right',
                width: 150,
                show: true
            },
            {
                title: 'Exit load',
                dataIndex: 'exit_load',
                align: 'right',
                width: 250,
                render: (text) => {
                    return text && text.length ? '-' : text
                },
                show: fundDetails?.portfolio_params?.FundType === 2 ? true : false
            },
        ]
        if (data) {
            return <Table dataSource={dataSource} columns={columns.filter(c => c.show)} size={'small'} bordered className='textXS black' pagination={{ hideOnSinglePage: true, pageSize: 10 }} />
        } else return <></>
    }

    const portfolioFundamentals = [{
        key: 'a',
        heading: 'P/E ratio',
        value: DecimalValueFormat({
            num: fundDetails?.perf_factsheet?.factsheet?.fundamentals?.pe,
            suffix: "%", placeholder: "-"
        })
    }, {
        key: 'b',
        heading: 'P/B ratio',
        value: DecimalValueFormat({
            num: fundDetails?.perf_factsheet?.factsheet?.fundamentals?.pb,
            suffix: "%", placeholder: "-"
        }),
    }, {
        key: 'c',
        heading: 'Average YTM',
        value: DecimalValueFormat({
            num: fundDetails?.perf_factsheet?.factsheet?.fundamentals?.maturity,
            suffix: "%", placeholder: "-"
        })
    }, {
        key: 'd',
        heading: 'Average maturity',
        value: DecimalValueFormat({
            num: fundDetails?.perf_factsheet?.factsheet?.fundamentals?.ytm,
            suffix: "%", placeholder: "-"
        })
    }, {
        key: 'e',
        heading: 'Average duration',
        value: DecimalValueFormat({
            num: fundDetails?.perf_factsheet?.factsheet?.fundamentals?.duration,
            suffix: " years", placeholder: "-"
        })
    }]

    const TopHoldings = ({ data }) => {
        const chipset2 = Object.keys(data || {})?.map((d, index) => {
            if (data[d]?.length > 0) {
                return {
                    key: index,
                    value: d,
                    label: d.replace("international", "intl'").charAt(0).toUpperCase() + d.replace("international", "intl'").replace("_", " ").slice(1),
                }
            }
        })
        const [holdingCat, setholdingCat] = React.useState(chipset2?.[0]?.value)

        const [dataSource, setDataSource] = React.useState()

        React.useEffect(() => {
            setDataSource(data?.[holdingCat])
        }, [holdingCat])
        const columns = [
            {
                title: 'Instrument',
                dataIndex: 'instrument',
                fixed: 'left',
                width: 300
            },
            {
                title: 'Sector',
                dataIndex: 'sector',
                align: 'right',
                width: 150
            },
            {
                title: 'Weight',
                dataIndex: 'weight',
                align: "right",
                width: 150,
                render: text => DecimalValueFormat({ num: Number(text), suffix: "%", placeholder: "-" })
            }
        ]
        return (
            <div>
                <div style={{ display: 'flex', justifyContent: 'end' }} className="mb-2">
                    <Segmented options={chipset2} value={holdingCat} onChange={setholdingCat} size="small" />
                </div>
                <Table dataSource={dataSource} columns={columns} size={'small'} bordered className='textXS black' pagination={{ hideOnSinglePage: true }} />
            </div>
        );
    }

    const WorstDrawdowns = ({ data }) => {
        const dataSource = data
        const columns = [
            {
                title: 'Started on',
                dataIndex: 'Started',
                width: 150,
                fixed: 'left'
            },
            {
                title: 'Drawdown',
                dataIndex: 'Drawdown',
                align: "right",
                render: (text) => DecimalValueFormat({ num: Number(text), suffix: "%", placeholder: "-" })
            },
            {
                title: 'Lasted for (days)',
                dataIndex: 'Days',
                align: "right",
            },
            {
                title: 'Recovered on',
                dataIndex: 'Recovered',
                align: "right",
            }
        ]
        return <Table dataSource={dataSource} columns={columns} size={'small'} bordered className='textXS black' pagination={false} />
    }

    const HeatMap = ({ data }) => {


        var heatmapdata = data
        var years = data?.map((data) => { return data?.year })
        var months = Object.keys(data?.[0] || {}).slice(1)


        var dataFinal = months.map((month, index) => {
            return years.map((year, yindex) => {
                return {
                    name: month.charAt(0).toUpperCase() + month.toLowerCase().slice(1) + " " + year,
                    x: index,
                    y: yindex,
                    value: Number((heatmapdata[yindex][month] * 100)?.toFixed(1))
                }
            })
        })


        var chart = {

            chart: {
                type: 'heatmap',
                plotBorderWidth: 0,
                borderWidth: 0,

            },


            title: {
                text: '',
                style: {
                    color: "var(--black)"
                }
            },
            scrollbar: {
                enabled: false
            },
            exporting: {
                enabled: false
            },
            credits: {
                enabled: false
            },

            xAxis: {
                categories: months
            },

            yAxis: {
                visible: false,
                tickAmount: 5,
                gridLineDashStyle: 'longdash',
                categories: years,
                title: null,
                reversed: true
            },

            colorAxis: {
                minColor: 'var(--red)',
                maxColor: 'var(--green)',
                min: 0,
            },

            legend: {
                enabled: false
            },

            tooltip: {
                headerFormat: '',
                pointFormat: '{point.name} returns: {point.value}%',
            },
            series: [{
                name: 'Monthly returns',
                borderWidth: 0.2,
                borderColor: 'black',
                data: dataFinal?.flat(1),
                dataLabels: {
                    enabled: true,
                    color: '#000000',
                    style: {
                        fontWeight: 'normal',
                        fontSize: 8,
                        textOutline: false
                    },
                    pointFormat: '{point.value}%',
                }
            }],

            responsive: {
                rules: [{
                    condition: {
                        maxWidth: 300
                    },
                    chartOptions: {
                        yAxis: {
                            labels: {
                                formatter: function () {
                                    return this.value.charAt(0);
                                }
                            }
                        }
                    }
                }]
            }

        }

        return <HighchartsReact highcharts={Highcharts} options={chart} />
    }

    const CorrelationHeatMap = ({ data }) => {


        var heatmapdata = data
        var stocks = data?.map((data) => { return data?.symbol })
        var months = Object.keys(data?.[0] || {}).slice(1)


        var dataFinal = stocks?.map((s, index) => {
            return stocks?.map((f, yindex) => {
                return {
                    name: f,
                    x: index,
                    y: yindex,
                    value: Number((heatmapdata[yindex][s]).toFixed(1))
                }
            })
        })


        var chart = {

            chart: {
                type: 'heatmap',
                plotBorderWidth: 0,
                borderWidth: 0,

            },


            title: {
                text: '',
                style: {
                    color: "var(--black)"
                }
            },
            scrollbar: {
                enabled: false
            },
            exporting: {
                enabled: false
            },
            credits: {
                enabled: false
            },

            xAxis: {
                categories: stocks
            },

            yAxis: {
                tickAmount: 5,
                gridLineDashStyle: 'longdash',
                categories: stocks,
                title: null,
                reversed: true
            },

            colorAxis: {
                minColor: 'var(--red)',
                maxColor: 'var(--green)',
            },

            legend: {
                enabled: false
            },

            tooltip: {
                headerFormat: '',
                pointFormat: '{point.name} correlation: {point.value}',
            },
            series: [{
                name: 'Monthly returns',
                borderWidth: 0.2,
                borderColor: 'black',
                data: dataFinal?.flat(1),
                dataLabels: {
                    enabled: true,
                    color: '#000000',
                    style: {
                        fontWeight: 'normal',
                        fontSize: 8,
                        textOutline: false
                    },
                    pointFormat: '{point.value}',
                }
            }],

            responsive: {
                rules: [{
                    condition: {
                        maxWidth: 300
                    },
                    chartOptions: {
                        yAxis: {
                            labels: {
                                formatter: function () {
                                    return this.value.charAt(0);
                                }
                            }
                        }
                    }
                }]
            }

        }

        return <HighchartsReact highcharts={Highcharts} options={chart} />
    }

    const ReturnsHistogram = ({ histData }) => {
        var heatmapdata = histData?.map((res) => {
            return Number((res * 100).toFixed(2))
        }) || []

        const xiData = [];
        const animationDuration = 1;//4000;
        const range = Math.max(...heatmapdata) - Math.min(...heatmapdata) * 2,
            startPoint = Math.min(...heatmapdata);
        let i = 0,
            j = 0;
        for (i = 0; i < range; i++) {
            xiData[i] = startPoint + i;
        }
        const data = [];

        function GaussKDE(xi, x) {
            return (1 / Math.sqrt(2 * Math.PI)) * Math.exp(Math.pow(xi - x, 2) / -2);
        }

        const N = heatmapdata.length;

        for (i = 0; i < xiData.length; i++) {
            let temp = 0;
            for (j = 0; j < heatmapdata.length; j++) {
                temp = temp + GaussKDE(xiData[i], heatmapdata[j]);
            }
            data.push([xiData[i], (1 / N) * temp * 100]);
        }

        const chart = {
            chart: {
                type: "areaspline",
                animation: true
            },
            title: {
                text: '',
                style: {
                    color: 'var(--black)'
                }
            },
            scrollbar: {
                enabled: false
            },
            exporting: {
                enabled: false
            },
            credits: {
                enabled: false
            },

            title: {
                text: ""
            },
            xAxis: {
                title: { text: "" }
            },
            yAxis: {
                tickAmount: 5,
                gridLineDashStyle: 'longdash',
                title: { text: 'Density' }
            },
            tooltip: {
                enabled: false
            },
            legend: {
                enabled: false
            },

            plotOptions: {
                areaspline: {
                    threshold: null
                },
                series: {
                    marker: {
                        enabled: false
                    },
                    dashStyle: "shortdot",
                    color: "#ff8d1e",
                    pointStart: xiData[0],
                    animation: {
                        duration: animationDuration
                    }
                }
            },
            series: [
                {
                    name: "Return density",
                    dashStyle: "solid",
                    lineWidth: 2,
                    color: 'var(--primary)',
                    data: data
                }
            ]
        }

        const options = {
            global: {
                useUTC: false
            },
            lang: {
                decimalPoint: '.',
                thousandsSep: ','
            }
        };

        return <HighchartsReact highcharts={Highcharts} options={chart} />
    }
    const UnderWater = ({ data }) => {
        var heatmapdata = data

        try {
            var conf = {
                chart: {
                    backgroundColor: "transparent"
                },
                title: {
                    text: ''
                },
                exporting: {
                    enabled: false
                },
                yAxis: {
                    tickAmount: 5,
                    gridLineDashStyle: 'longdash',
                    labels: {
                        format: '{value}%'
                    },
                    title: {
                        text: ''
                    },
                    gridLineColor: "var(--border)",
                },
                xAxis: [{
                    type: 'datetime',
                    dateTimeLabelFormats: {
                        millisecond: '%H:%M:%S.%L',
                        second: '%H:%M:%S',
                        minute: '%H:%M',
                        hour: '%H:%M',
                        day: '%eth %b',
                        week: '%eth %b',
                        month: '%b \'%y',
                        year: '%Y'
                    },
                    lineColor: "var(--border)",
                    tickColor: "var(--border)",
                }
                ],
                legend: {
                    enabled: false
                },
                plotOptions: {
                    series: {
                        lineWidth: 1.5,
                        label: {
                            connectorAllowed: false
                        },
                        marker: {
                            enabled: false
                        }
                    }
                },
                tooltip: {
                    shared: true,
                    valueDecimals: 2,
                    valuePrefix: '',
                    valueSuffix: '%'
                },
                credits: {
                    enabled: false
                },
                boost: {
                    useGPUTranslations: true,
                },
                series: [
                    {
                        name: "Portfolio", turboThreshold: 9e9,
                        color: "var(--red)",
                        data: changeObjKey(heatmapdata, ["Portfolio", "date"], ["y", "x"], "rollingTearchart", 1).map((i) => {
                            return {
                                ...i,
                                y: i.y * 100
                            }
                        })
                    }
                ],
                scrollbar: {
                    enabled: false
                },
                responsive: {
                    rules: [{
                        condition: {
                            maxWidth: 400
                        },
                    }]
                },

            };
        } catch (error) {
            // console.log("SDF", error)
        }

        const options = {
            global: {
                useUTC: false
            },
            lang: {
                decimalPoint: '.',
                thousandsSep: ','
            }
        };

        return <HighchartsReact highcharts={Highcharts} options={conf} />
    }

    const RollingVol = ({ data }) => {

        var heatmapdata = data

        try {
            var conf = {
                chart: {
                    backgroundColor: "transparent"
                },
                title: {
                    text: ''
                },
                exporting: {
                    enabled: false
                },
                yAxis: {
                    tickAmount: 5,
                    gridLineDashStyle: 'longdash',
                    title: {
                        text: ''
                    },
                    gridLineColor: "var(--border)",
                    plotLines: [{
                        color: '#000', // Red
                        value: heatmapdata[0]["Average_6M_vol"], // Position, you'll have to translate this to the values on your x axis
                        dashStyle: 'dash',
                        width: 1,
                        label: {
                            text: "Average 6M vol", rotation: 0, x: 10,
                            style: {
                                color: "var(--black)",
                                fontWeight: 'bold'
                            }
                        }
                    }]
                },
                xAxis: [{
                    type: 'datetime',
                    dateTimeLabelFormats: {
                        millisecond: '%H:%M:%S.%L',
                        second: '%H:%M:%S',
                        minute: '%H:%M',
                        hour: '%H:%M',
                        day: '%eth %b',
                        week: '%eth %b',
                        month: '%b \'%y',
                        year: '%Y'
                    },
                    lineColor: "var(--border)",
                    tickColor: "var(--border)",
                }
                ],
                legend: {
                    enabled: false
                },
                plotOptions: {
                    series: {
                        lineWidth: 1.5,
                        label: {
                            connectorAllowed: false
                        },
                        marker: {
                            enabled: false
                        }
                    }
                },
                tooltip: {
                    shared: true,
                    valueDecimals: 2,
                    valuePrefix: '',
                    valueSuffix: '%'
                },
                credits: {
                    enabled: false
                },
                boost: {
                    useGPUTranslations: true,
                },
                series: [
                    {
                        name: "Portfolio", turboThreshold: 9e9,
                        color: '#4284f3',
                        data: changeObjKey(heatmapdata, ["Portfolio", "date"], ["y", "x"], "rollingTearchart", 1)
                    }, {
                        name: "Benchmark", turboThreshold: 9e9,
                        color: "var(--primary)",
                        data: changeObjKey(heatmapdata, ["Benchmark", "date"], ["y", "x"], "rollingTearchart", 1)
                    }
                ].reverse(),
                scrollbar: {
                    enabled: false
                },
                responsive: {
                    rules: [{
                        condition: {
                            maxWidth: 400
                        },
                    }]
                },

            };
        } catch (error) {
            // console.log("SDF", error)
        }

        const options = {
            global: {
                useUTC: false
            },
            lang: {
                decimalPoint: '.',
                thousandsSep: ','
            }
        };

        return <HighchartsReact highcharts={Highcharts} options={conf} />
    }

    const SharpeChart = ({ data }) => {
        var heatmapdata = data

        try {
            var conf = {
                chart: {
                    backgroundColor: "transparent"
                },
                title: {
                    text: ''
                },
                exporting: {
                    enabled: false
                },
                yAxis: {
                    tickAmount: 5,
                    gridLineDashStyle: 'longdash',
                    title: {
                        text: ''
                    },
                    gridLineColor: "var(--border)",
                    plotLines: [{
                        color: '#000', // Red
                        value: heatmapdata[0]["Average_Sharpe"], // Position, you'll have to translate this to the values on your x axis
                        dashStyle: 'dash',
                        width: 1,
                        label: {
                            text: "Average Sharpe", rotation: 0, x: 10,
                            style: {
                                color: "var(--black)",
                                fontWeight: 'bold',
                                zIndex: 1
                            }
                        }
                    }]
                },
                xAxis: [{
                    type: 'datetime',
                    dateTimeLabelFormats: {
                        millisecond: '%H:%M:%S.%L',
                        second: '%H:%M:%S',
                        minute: '%H:%M',
                        hour: '%H:%M',
                        day: '%eth %b',
                        week: '%eth %b',
                        month: '%b \'%y',
                        year: '%Y'
                    },
                    lineColor: "var(--border)",
                    tickColor: "var(--border)",
                }
                ],
                legend: {
                    enabled: false
                },
                plotOptions: {
                    series: {
                        lineWidth: 1.5,
                        label: {
                            connectorAllowed: false
                        },
                        marker: {
                            enabled: false
                        }
                    }
                },
                tooltip: {
                    shared: true,
                    valueDecimals: 0,
                    valuePrefix: '₹',
                },
                credits: {
                    enabled: false
                },
                boost: {
                    useGPUTranslations: true,
                },
                series: [
                    {
                        name: "Portfolio", turboThreshold: 9e9,
                        color: "#24c1e0",
                        tooltip: {
                            valueSuffix: ' ({point.Portfolio}%)',
                        },
                        data: changeObjKey(heatmapdata, ["Portfolio", "date"], ["y", "x"], "rollingTearchart", 1)
                    }
                ],
                scrollbar: {
                    enabled: false
                },
                responsive: {
                    rules: [{
                        condition: {
                            maxWidth: 400
                        },
                    }]
                },

            };
        } catch (error) {
            // console.log("SDF", error)
        }

        const options = {
            global: {
                useUTC: false
            },
            lang: {
                decimalPoint: '.',
                thousandsSep: ','
            }
        };

        return <HighchartsReact highcharts={Highcharts} options={conf} />
    }
    const RebalanceData = ({ rebalData }) => {
        const dataSource = rebalData

        const columns = [
            {
                title: 'Symbol',
                dataIndex: 'symbol',
                key: 'symbol',
                width: 150,
                fixed: 'left'
            },
            {
                title: 'Market cap',
                dataIndex: 'mcap',
                key: 'mcap',
                align: "right",
                render: (text) => DecimalValueFormat({ num: Number(text?.toFixed(2)), suffix: " Cr", placeholder: "-" })
            },
            {
                title: 'Weights',
                dataIndex: 'weights',
                key: 'weights',
                align: "right",
                render: (text) => DecimalValueFormat({ num: Number(text?.toFixed(2)), suffix: "%", placeholder: "-" })
            },
            {
                title: 'Next day avg. price',
                dataIndex: 'next_day_price_avg',
                key: 'next_day_price_avg',
                align: "right",
                render: (text) => CurrencyFormat(Number(text))
            },
            {
                title: 'No. of shares',
                dataIndex: 'num_shares',
                key: 'num_shares',
                align: "right",
            }
        ]
        return <Table dataSource={dataSource} columns={columns} size={'small'} bordered className='textXS black my-2' pagination={false} />
    }
    const CustomAccordionItem = ({ x }) => {

        const [isOpen, setIsOpen] = React.useState(false)
        return <>

            <Timeline.Item>
                <div className="compare-collapse" style={{ backgroundColor: "transparent", borderBottom: "0px", padding: "0px" }} id={`id${x.key}`} onClick={() => {
                    setIsOpen(!isOpen)
                }}>
                    <div className="fontH2 black" >{x.header}</div>
                    <div className="black">{isOpen ? <FiChevronUp size={'18px'} /> : <FiChevronDown size={'18px'} />}</div>
                </div>
                <CollapseReact
                    style={{ border: "0px" }}
                    isOpen={isOpen}
                    toggler={`#id${x.key}`} id={`#body${x.key}`}>

                    <RebalanceData rebalData={x.rebalData} />

                </CollapseReact>
            </Timeline.Item>
        </>
    }
    const SmallCharts = ({ data }) => {
        return <div style={{ display: 'flex', gap: '1rem', flexWrap: 'wrap' }}>
            {
                data.map(chart => {
                    var x = Object.keys(chart?.data?.[0])?.[0]
                    return <Card bordered={false} style={{ flex: 1, minWidth: '20rem', maxWidth: '20rem', marginBottom: '0.25rem' }} bodyStyle={{ padding: 0 }}>
                        <div className="fontH2 m-3">{chart.name}</div>
                        <PerformanceChart3 fundName={chart.name} chartData={JSON.stringify(chart.data)} xAxisKey={x} />
                    </Card>
                })
            }
        </div>
    }
    const Methodology = ({ data }) => {
        // const options1 = Object.keys(data || {}).map(k => {
        //     return {
        //         key: k,
        //         name: data[k].section_name
        //     }
        // })
        // const [selected, setSelected] = React.useState(options1?.[0])
        return <>
            {/* <Segmented style={{ border: '1px solid var(--border)', marginBottom: '0.5rem' }} size='small' value={selected.name} options={options1?.map(m => m.name)} onChange={(e) => setSelected(options1?.find(o => o.name === e))} /> */}
            <div className='mt-2'>
                <div className='fontH2 w-500 mb-3'>{data?.stock_selection?.section_name}</div>
                <div className='textSM'>
                    {
                        Object.values(data?.stock_selection?.data || {}).map(p => {
                            return <div className='mb-1'>{p}</div>
                        })
                    }
                </div>
            </div>
            <div className='mt-4'>
                <div className='fontH2 w-500 mb-3'>{data?.stock_weights?.section_name}</div>
                <div className='textSM'>
                    {
                        Object.values(data?.stock_weights?.data || {}).map(p => {
                            return <div className='mb-1'>{p}</div>
                        })
                    }
                </div>
            </div>
            <div className='mt-4'>
                <div className='fontH2 w-500 mb-3'>{data?.rebalance?.section_name}</div>
                <div className='textSM'>
                    <div className='w-500 mb-1'>{data?.rebalance?.sub_sec_1?.name}</div>
                    <div className='mb-2'>{data?.rebalance?.sub_sec_1?.para_1}</div>
                    <div className='w-500 mb-1'>{data?.rebalance?.sub_sec_2?.name}</div>
                    <div>{data?.rebalance?.sub_sec_2?.para_1}</div>
                </div>

            </div>
        </>
    }
    const tabData = loading ? [] :
        [
            {
                key: "1",
                title: "Overview",
                children: [
                    {
                        key: 'Investment details',
                        header: 'Investment details',
                        html: <div>
                            <CustomLabelValueCards x={subscription_details} />
                        </div>,
                        show: true,
                    },
                    {
                        key: 'Methodology',
                        header: 'Methodology',
                        html: <Methodology data={fundDetails?.methodology} />,
                        show: true,
                    },
                    {
                        key: 'Metric charts',
                        header: 'Key metric charts',
                        html: <SmallCharts data={Object.values(fundDetails?.chart_dict || [])} />,
                        show: true,
                    }
                ]
            },
            {
                key: "2",
                title: "Holdings analysis",
                children: [
                    {
                        key: 'Constituents',
                        header: 'Constituents',
                        html: <CustomListTable
                            finalList={fundDetails?.holdings}
                            tableType={0}
                            defFilters={[
                                // ["nse_basic_ind_code", fundData?.nse_basic_ind_code]
                            ]}
                            initialColumns={[
                                ...dataTableColumns,
                                ...screenerMetric.filter((item) => ['sh_val_comp', 'sh_mom_comp', 'sh_qual_comp', 'sh_3fac_comp', 'pe_ttm', 'pb'].includes(item.metric_col_code)),

                            ]}
                            is_Open={isOpen}
                            is_V1Open={isV1Open}
                            is_V2Open={isV2Open}
                            tableHeader={<span className='fontH2'></span>}
                            screenerFinalData={screenerFinalData}
                            hideCheckBox={true}
                        />,
                        show: true,
                    },
                    {
                        key: 'Sector allocation',
                        header: 'Sector allocation',
                        html: <SectorAllocationTable />,
                        hideHeader: true,
                        show: true,
                    },
                    {
                        key: 'Portfolio history',
                        header: 'Portfolio history',
                        html: <Timeline>
                            {Object.entries(fundDetails?.portfolio_history_dict || {})?.map(([Date, rebalData], index1) => {
                                return <CustomAccordionItem x={{
                                    "Date": Date,
                                    "header": "Portfolio as on " + formatDate(Date),
                                    "rebalData": rebalData,
                                    "key": Date + "" + index1
                                }} />
                            })}
                        </Timeline>,
                        show: true,
                    },
                    {
                        key: 'Correlation',
                        header: 'Correlation',
                        html: <CorrelationHeatMap data={fundDetails?.correlation} />,
                        show: true,
                    },
                ]
            },
            {
                key: "4",
                title: "Historical performance",
                children: [
                    {
                        key: 'Portfolio Summary',
                        header: 'Portfolio Summary',
                        html: <TableConstructor tableData={fundDetails?.perfromance_tables?.perf_summary} isTranspose={true} />,
                        show: true,
                    },
                    {
                        key: 'Key Performance Metrics',
                        header: 'Key Performance Metrics',
                        html: <TableConstructor tableData={fundDetails?.perfromance_tables?.perf_metrics} isTranspose={true} />,
                        show: true,
                    },
                    {
                        key: 'Trailing returns',
                        header: 'Trailing returns',
                        html: <TableConstructor tableData={fundDetails?.perfromance_tables?.trailing_returns} isTranspose={true} />,
                        show: true,
                    },
                    {
                        key: 'Calendar year returns',
                        header: 'Calendar year returns',
                        html: <TableConstructor tableData={fundDetails?.perfromance_tables?.cal_year_returns} isTranspose={true} />,
                        show: true,
                    },
                    {
                        key: 'Worst 5 drawdowns',
                        header: 'Worst 5 drawdowns',
                        html: <WorstDrawdowns data={Object.values(fundDetails?.perfromance_tables?.worst_5_drawdowns || {})} />,
                        show: true,
                    },
                    {
                        key: 'Monthly returns heatmap',
                        header: 'Monthly returns heatmap',
                        html: <HeatMap data={fundDetails?.perfromance_charts?.heatmap} />,
                        show: true,
                    },
                    {
                        key: 'Distribution of monthly returns',
                        header: 'Distribution of monthly returns',
                        html: <ReturnsHistogram histData={fundDetails?.perfromance_charts?.histogram_monthly_rets} />,
                        show: true,
                    },
                    {
                        key: '6-month rolling volatility',
                        header: '6-month rolling volatility',
                        html: <RollingVol data={fundDetails?.perfromance_charts?.rolling_vol} />,
                        show: true,
                    },
                    {
                        key: '6-month rolling Sharpe ratio',
                        header: '6-month rolling Sharpe ratio',
                        html: <SharpeChart data={fundDetails?.perfromance_charts?.rolling_sharpe} />,
                        show: true,
                    },
                    {
                        key: 'Underwater plot',
                        header: 'Underwater plot',
                        html: <UnderWater data={fundDetails?.perfromance_charts?.underwater} />,
                        show: true,
                    },
                ]
            },
        ];


    if (apiError) {
        return <ErrorComponent />
    }
    if (loading) {
        return <ActivityLoaderMintbox margin="20%" />
    }
    return (
        <React.Fragment>
            <Head title="Stock portfolio" />
            <Row style={{ marginBottom: '1rem' }}>
                <Col sm="8">
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                        <div style={{ display: 'flex', gap: '1rem' }}>
                            <MintboxIcon amcName={fundDetails?.portfolio_params?.mintbox_id} height='60px' width='60px' />
                            <div className="project-info" style={{ flex: 1 }}>
                                <div className="mb-1" style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                                    <div className="fontH1">{state?.portfolio_params?.mintbox_name || fundDetails?.portfolio_params?.name}</div>
                                    {/* {
                                        isWatchlisted ?
                                            <BsFillBookmarkFill style={{ marginLeft: '1rem', fontSize: '1.2rem' }} onClick={() => updateWatchlist()} />
                                            :
                                            <BsBookmark style={{ marginLeft: '1rem', fontSize: '1.2rem' }} onClick={() => updateWatchlist()} />
                                    } */}
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                                    <Tag color="var(--primary)">
                                        <span className="ms-2">Min investment {CurrencyFormat(fundDetails?.portfolio_params?.min_lumpsum, 'long', 0)}</span>
                                    </Tag>
                                    <Tag icon={<AiOutlineLineChart />} color={RiskTextColor(fundDetails?.portfolio_params?.portfolio_risk)}>
                                        <span id="riskTooltip" className="ms-2">{RiskFormat(fundDetails?.portfolio_params?.portfolio_risk)} risk</span>
                                    </Tag>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col sm="4">
                    <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'center', gap: '1rem' }}>
                        <Tooltip title={isWatchlisted ? "remove from watchlist" : "add to watchlist"} placement="bottom">
                            <button className="btnSecondary">
                                {
                                    isWatchlisted ?
                                        <BsFillBookmarkFill style={{ fontSize: '1.2rem' }} onClick={() => updateWatchlist()} />
                                        :
                                        <BsBookmark style={{ fontSize: '1.2rem' }} onClick={() => updateWatchlist()} />
                                }
                            </button>
                        </Tooltip>
                        <InsightsModal insights={fundDetails?.insights} holdings={fundDetails?.holdings} type={type} detailType='stockPortfolio' />
                    </div>
                </Col>
            </Row>
            <Row style={{ marginBottom: '1rem' }}>
                <Col sm="8">
                    <Card bordered={false} style={{ height: '100%' }} bodyStyle={{ height: '100%' }}>
                        <PerformanceChart2 xAxisKey="date" goLiveDate={new Date(moment(fundDetails?.portfolio_params?.go_live_date, "DD/MM/YY").toISOString()).getTime()} portfolioType={fundDetails?.portfolio_params?.mintbox_type === 1 ? 1 : 2} chartData={fundDetails?.perfromance_against_bm?.prices} benchName={fundDetails?.portfolio_params?.mintbox_bm_name} />
                        <div className='mt-2' style={{ display: 'flex', justifyContent: 'space-around', gap: '1rem' }}>
                            <div>
                                <div className="textSM">Value Score</div>
                                <CustomSharpelyScoreProgressBar val={fundDetails?.top_box_summary?.sh_val_comp} />
                            </div>
                            <div>
                                <div className="textSM">Momentum Score</div>
                                <CustomSharpelyScoreProgressBar val={fundDetails?.top_box_summary?.sh_mom_comp} />
                            </div>
                            <div>
                                <div className="textSM">Quality Score</div>
                                <CustomSharpelyScoreProgressBar val={fundDetails?.top_box_summary?.sh_qual_comp} />
                            </div>
                            <div>
                                <div className="textSM">QVM Score</div>
                                <CustomSharpelyScoreProgressBar val={fundDetails?.top_box_summary?.sh_3fac_comp} />
                            </div>
                        </div>
                    </Card>
                </Col>
                <Col sm="4">
                    <Card bordered={false} style={{ height: '100%' }} bodyStyle={{ height: '100%' }}>
                        <div className="fund-detail-card-left" style={{ border: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                            <div className="textSM" style={{ lineHeight: '24x', whiteSpace: 'pre-line' }}>
                                <ReadMoreText text={fundDetails?.portfolio_params?.About?.replaceAll(`\{\"\\n\"\}`, '\n\n')} showCharacters={300} />
                            </div>
                            <div className="custom-details-table text-center">
                                <div className="custom-detail" >
                                    <div className="custom-label textSM">CAGR <span className="textXS" style={{ whiteSpace: 'nowrap' }}>(since {moment(fundDetails?.portfolio_params?.cagr_since).format("DD MMM YYYY")})</span></div>
                                    <div className="custom-value fontH2">{fundDetails?.portfolio_params?.cagr === null ? "-" : Number(fundDetails?.portfolio_params?.cagr)?.toFixed(2)}%</div>
                                </div>
                                <div className="custom-detail" >
                                    <div className="custom-label textSM">Avg. market cap</div>
                                    <div className="custom-value fontH2">{CurrencyFormat(fundDetails?.top_box_summary?.mcap, 'long', 0)} Cr</div>
                                </div>
                                <div className="custom-detail" >
                                    <div className="custom-label textSM">PE ratio</div>
                                    <div className="custom-value fontH2">{fundDetails?.top_box_summary?.pe === null ? "-" : Number(fundDetails?.top_box_summary?.pe)?.toFixed(2)}</div>
                                </div>
                                <div className="custom-detail" >
                                    <div className="custom-label textSM">PB ratio</div>
                                    <div className="custom-value fontH2">{fundDetails?.top_box_summary?.pb === null ? "-" : Number(fundDetails?.top_box_summary?.pb)?.toFixed(2)}</div>
                                </div>
                            </div>
                            <div>
                                <InvestmentCard />
                            </div>
                        </div>
                    </Card>
                </Col>
            </Row>
            <CustomCollapseTabs tabData={tabData} />
        </React.Fragment >
    );
};

export default StockPortfolioDetail;
