import apiHelper from "Services/api/ApiHelper"

export const getKnowledgeBaseData = async () => {
    let categories = await apiHelper({ apiName: "getBlogCategories", saveResponse: "sessionStorage" });
    let subCategories = await apiHelper({ apiName: "getBlogSubCategories", saveResponse: "sessionStorage" });
    let blogs = await apiHelper({ apiName: "getBlogs", saveResponse: "sessionStorage" });
    let formattedData = formatKnowledgeBaseData(categories, subCategories, blogs)
    return { categories, subCategories, blogs, formattedData, type: true };
}

const formatKnowledgeBaseData = (allCategories, allSubCategories, allBlogs) => {
    let temp = [...allCategories];
    let base = '/knowledge-base';
    temp.forEach((item, i) => {
        let subs = allSubCategories.filter((ele) => ele.Category === item.Category);
        item.SubCategories = [...subs];
        item.Slug = base + '/' + item?.Category?.split(' ')?.join('_')?.toLowerCase();
        item.SubCategories.forEach((sub, j) => {
            let resources = allBlogs.filter((ele) => ele.SubCategory === sub.SubCategory && ele.Category === item.Category);
            sub.Resources = [...resources];
            if (resources.length) {
                sub.FirstBlog = ('/knowledge-base/' + resources[0]?.Category.split(' ').join('_') + '/' + resources[0]?.SubCategory?.split(' ')
                    .join('_') + '/' + resources[0]?.URL.split('/')?.[2] || '').toLowerCase();
            }
            else {
                sub.FirstBlog = ('/knowledge-base/' + allBlogs[0]?.Category.split(' ').join('_') + '/' + (allBlogs[0]?.SubCategory?.split(' ')
                    .join('_') || '-') + '/' + allBlogs[0]?.URL.split('/')?.[2] || '').toLowerCase();
            }
            sub.Resources.forEach((res, i) => {
                let slug = base + '/' + res?.Category?.split(' ')?.join('_')?.toLowerCase() + '/' +
                    res?.SubCategory?.split(' ')?.join('_')?.toLowerCase() + '/' + res?.URL?.split('/')?.[2]?.toLowerCase();
                res.Slug = slug;
            })
        })
        let blogs = allBlogs.filter((ele) => ele.Category === item.Category && ele.SubCategory === "");
        item.Resources = [...blogs];
        item.Resources.forEach((res, i) => {
            let slug = base + '/' + res?.Category?.split(' ')?.join('_')?.toLowerCase() + '/' +
                (res?.SubCategory?.split(' ')?.join('_')?.toLowerCase() || '-') + '/' + res?.URL?.split('/')?.[2]?.toLowerCase();
            res.Slug = slug;
        })
    })
    return temp;
}

export const getNextPrevBlog = async (blogURL) => {
    let res = await getKnowledgeBaseData();
    let data = [...res?.formattedData];
    let blogs = [];
    for (let i = 0; i < data.length; i++) {
        let temp = data[i]?.SubCategories?.reduce((a, c) => [...a, ...c?.Resources], []);
        blogs = [...blogs, ...temp, ...data[i]?.Resources]
    };
    let id = blogs.findIndex((item) => item.URL.split('/')?.[2] === blogURL);
    return { prev: blogs[id - 1], next: blogs[id + 1] }
}