import { CurrencyFormat } from "Components/CurrencyFormat";
import { addWeek, formatDateyyyymmdd } from "Components/DateFormatter";
import { DecimalValueFormat } from "Components/DecimalValueFormat";
import RedGreenText from "Components/RedGreenText";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { useSelector } from "react-redux";

const StockLivePrice = ({ symbol, format = 1 }) => {
    // format
    // 1 => change changeper ltp
    // 2 => LTP (changeper)
    // 3 => LTP change(changeper)
    // 4 => LTP 
    //      change(changeper)
    // 5 => LTP
    var stockprices = useSelector((state) => state?.stockprices?.value)
    var prevstockprices = useSelector((state) => state?.prevStockPrices?.value)
    // console.log(prevstockprices)
    // console.log(stockprices)

    const getChange = (current, prev) => {
        // console.log(current, prev)
        return {
            changePer: (((current - prev) / prev) * 100) || '-',
            changeVal: (current - prev) || '-'
        }
    }

    // console.log(prevstockprices.filter((i) => i.Symbol === symbol))
    var currentLTP = (stockprices.filter((i) => i.Symbol === symbol)?.[0]?.LTP || stockprices.filter((i) => i.Symbol === symbol)?.[0]?.ClosePrice) / 100
    var prevVal = (prevstockprices.filter((i) => i.Symbol === symbol && i.Date.replace('T00:00:00', '') === formatDateyyyymmdd(addWeek(new Date, -(1 / 7))))?.[0]?.ClosePrice) / 100 || (prevstockprices.filter((i) => i.Symbol === symbol && i.Date.replace('T00:00:00', '') === formatDateyyyymmdd(addWeek(new Date, -(1 / 7))))?.[1]?.ClosePrice) / 100
    var { changePer, changeVal } = getChange(currentLTP, prevVal)
    if (isNaN(changePer) && isNaN(changeVal)) {
        var { changePer, changeVal } = getChange(currentLTP, (prevstockprices.filter((i) => (i.Symbol === symbol))?.[0]?.ClosePrice) / 100)
    }
    if (stockprices.length > 0) {

        if (format === 1) {
            return <span className="textXS w-500 black" style={{ display: "flex", gap: "0.2rem", alignItems: "center", flex: 1, minWidth: "9vw", textAlign: "right" }}>
                <span className="dark3" style={{ flex: 1, textAlign: "right" }}><span className="dark3 me-1">{DecimalValueFormat({ num: changeVal, suffix: '', decimals: 1 })}</span>
                    <span className="dark3" style={{ alignItems: "center" }}>(<RedGreenText text={DecimalValueFormat({ num: changePer, suffix: "%", decimals: 2 })} value={changePer} />{changePer > 0 ? <IoIosArrowUp color="var(--green)" /> : <IoIosArrowDown color="var(--red)" />})</span></span>
                <span style={{ textAlign: "right", minWidth: "3.6vw" }}>{CurrencyFormat(currentLTP, 'long', 2)}</span>
            </span>
        } else if (format === 2) {
            return <span className="textXS w-500 black" style={{ display: "flex", gap: "0.2rem", alignItems: "center", flex: 1, minWidth: "11.5vw", textAlign: "right" }}>
                <span >{CurrencyFormat(currentLTP, 'long', 2)}</span>
                <span className="dark3" style={{ display: "flex", alignItems: "center" }} >(<RedGreenText text={DecimalValueFormat({ num: changePer, suffix: "%", decimals: 2 })} value={changePer} />{changePer > 0 ? <IoIosArrowUp color="var(--green)" /> : <IoIosArrowDown color="var(--red)" />})</span>
            </span>
        } else if (format === 3) {
            return <span className="textXS w-500 black" style={{ display: "flex", flexDirection: "column", gap: "0.1rem", alignItems: "flex-end", flex: 1, minWidth: "9vw", textAlign: "right" }}>
                <span style={{ textAlign: "right", minWidth: "3vw" }}>{CurrencyFormat(currentLTP, 'long', 2)}</span>
                <span className="dark3" style={{ flex: 1, textAlign: "right" }}>
                    <RedGreenText text={`${DecimalValueFormat({ num: changeVal, suffix: '', decimals: 1 }).replace("NaN", "0.00")} (${DecimalValueFormat({ num: changePer, suffix: "%", decimals: 2 }).replace("NaN", "0.00")})`} value={changePer} />
                </span>
            </span>
        } else if (format === 4) {
            return <span className="textXS w-500 black" style={{ display: "flex", gap: "0.2rem", alignItems: "center", flex: 1, minWidth: "11.5vw", textAlign: "right" }}>
                <span className="dark3" style={{ flex: 1, textAlign: "right" }}>{DecimalValueFormat({ num: changeVal, suffix: '', decimals: 1 })}</span>
                <span style={{ flex: 1, textAlign: "right" }}><RedGreenText text={DecimalValueFormat({ num: changePer, suffix: "%", decimals: 2 })} value={changePer} />{changePer > 0 ? <IoIosArrowUp color="var(--green)" /> : <IoIosArrowDown color="var(--red)" />}</span>
                <span style={{ flex: 1, textAlign: "right" }}>{CurrencyFormat(currentLTP, 'long', 2)}</span>
            </span>
        } else if (format === 5) {
            //chart special
            return <span className="textMD w-500 black" style={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
                <span className="w-700 dark3">{CurrencyFormat(currentLTP, 'long', 2)}</span>
                <RedGreenText isBg={true}
                    text={<span>
                        {DecimalValueFormat({ num: changeVal, suffix: '', decimals: 1 }).replace("NaN", "0.00")} ({DecimalValueFormat({ num: changePer, suffix: "%", decimals: 2 }).replace("NaN", "0.00")})
                    </span>} value={changePer} />


            </span >
        }


    } else {
        return <span className="w-500">
            {"-"}
        </span>
    }

}

const GetLTP = ({ symbol }) => {

    var stockprices = useSelector((state) => state?.stockprices?.value)

    return (stockprices.filter((i) => i.Symbol === symbol)?.[0]?.LTP || stockprices.filter((i) => i.Symbol === symbol)?.[0]?.ClosePrice) / 100


}

export { StockLivePrice, GetLTP };
