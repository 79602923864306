import ActivityLoaderMintbox from 'Components/ActivityLoaderMintbox';
import Head from 'Layout/Head';
import db from 'Libs/db';
import React, { useState } from 'react';
import { Dashboard_Portfolio } from 'Services/api/FabricatedAPIS';
import jwt_decode from "jwt-decode";
import AdminServices from 'Services/api/AdminServices';
import { toast } from 'react-toastify';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts'
import { CurrencyFormat } from 'Components/CurrencyFormat';
import { Card, Col, Row, Skeleton, Tabs } from 'antd';
import RedGreenText from 'Components/RedGreenText';
import HomePortfolioCard from 'Components/HomePortfolioCard';
import { changeObjKey } from 'Components/DataCustomizer';
import TransactionCard from 'Components/TransactionCard';
import { formatDate } from 'Components/DateFormatter';
import FundBox from 'Components/FundBox';
import { FaArrowLeft } from 'react-icons/fa';
import FundConstituents from 'Components/FundConstituents';
import SIPCard from 'Components/SIPCard';
import ListViewWithIcon from 'Components/ListViewWithIcon';
import PortfolioGoalCard from 'Components/PortfolioGoalCard';

export default function Portfolio(props) {

    const [xirr, setXIRR] = React.useState([]);
    const [portfolioSnap, setportfolioSnap] = React.useState({});
    const [portfolioHoldings, setPortfolioHoldings] = React.useState({});
    const [portfolioPerformance, setPortfolioPerformance] = React.useState({});
    const [portfolioAssetChart, setPortfolioAssetChart] = React.useState([]);
    const [portfolioInstrumentChart, setPortfolioInstrumentChart] = React.useState([]);
    const [chartLoad, setChartLoad] = React.useState(true)
    const [refreshing, setRefreshing] = React.useState(false);
    const [portfolioSnapLoading, setportfolioSnapLoading] = React.useState(true);
    const [portfolioloading, setportfolioloading] = React.useState(true);
    const [portfolioXIRRloading, setportfolioXIRRloading] = React.useState(true);
    const [isFabOpen, setIsFabOpen] = React.useState(false);
    const [clientGoals, setclientGoals] = React.useState([]);
    const [transactions, setTransactions] = React.useState({});
    const [scheduledOrders, setScheduledOrders] = React.useState([]);


    const finalInstbg = [
        {
            id: 1,
            name: 'ETFs',
            color: 'var(--indianEquity)'
        },
        {
            id: 2,
            name: 'Mutual funds',
            color: 'var(--gold)'
        },
        {
            id: 3,
            name: 'Stocks',
            color: 'var(--internationalEquity)'
        }
    ];

    const finalbg = [
        {
            id: 1,
            name: 'Indian equity',
            color: 'var(--indianEquity)'
        },
        {
            id: 2,
            name: 'Gold',
            color: 'var(--gold)'
        },
        {
            id: 3,
            name: 'Foreign equity',
            color: 'var(--internationalEquity)'
        },
        {
            id: 4,
            name: 'Debt',
            color: 'var(--debt)'
        },
        {
            id: 5,
            name: 'Cash',
            color: 'var(--cash)'
        },
        {
            id: 6,
            name: 'Silver',
            color: 'var(--silver)'
        }
    ];



    const getAssetData = async (data, portfolioSnap) => {
        var aa = data
        var ak = []

        var holder = {};

        if (data === null) {
            setPortfolioAssetChart([])
        } else {
            aa.forEach(function (d) {
                if (holder.hasOwnProperty(d.AssetId)) {
                    holder[d.AssetId] = holder[d.AssetId] + d.AssetBalance;
                } else {
                    holder[d.AssetId] = d.AssetBalance;
                }
            });

            var obj2 = [];

            for (var prop in holder) {
                obj2.push({ name: prop, value: holder[prop] });
            }
            obj2.map((a, index) => {
                var per = (a.value) / portfolioSnap?.Portfolio?.PortfolioBalance
                per = per * 100
                var clr = finalbg[Number(a.name) - 1].color
                var dd = {
                    y: Number(Number(per).toFixed(2)) > 100 ? 100 : Number(Number(per).toFixed(2)),
                    name: finalbg[Number(a.name) - 1].name + ` (${Number(Number(per).toFixed(2)) > 100 ? 100 : Number(Number(per).toFixed(2))}%)`,
                    labelColor: '#000',
                    color: clr
                }
                if (dd.y > 0) {
                    ak.push(dd)
                }
            })
            await setPortfolioAssetChart(ak)
        }
    }

    const getInstrumentData = async (data, portfolioSnap) => {
        var aa = data
        var ak = []

        var holder = {};

        if (data === null) {
            setPortfolioInstrumentChart([])
        } else {
            aa.forEach(function (d) {
                if (holder.hasOwnProperty(d.FundType)) {
                    holder[d.FundType] = holder[d.FundType] + d.AssetBalance;
                } else {
                    holder[d.FundType] = d.AssetBalance;
                }
            });

            var obj2 = [];

            for (var prop in holder) {
                obj2.push({ name: prop, value: holder[prop] });
            }
            obj2.map((a, index) => {
                var per = (a.value) / portfolioSnap?.Portfolio?.PortfolioBalance
                per = per * 100
                var clr = finalInstbg[Number(a.name) - 1].color
                var dd = {
                    y: Number(Number(per).toFixed(2)) > 100 ? 100 : Number(Number(per).toFixed(2)),
                    name: finalInstbg[Number(a.name) - 1].name + ` (${Number(Number(per).toFixed(2)) > 100 ? 100 : Number(Number(per).toFixed(2))}%)`,
                    labelColor: '#000',
                    color: clr
                }
                if (dd.y > 0) {
                    ak.push(dd)
                }
            })
            await setPortfolioInstrumentChart(ak)
        }
    }

    const loadData = async (fresh = false) => {
        await db.get(db.model.user).then(async (p) => {
            //p = JSON.parse(p)
            //getPortfolioSnapData
            var portfolioSnapshot = await Dashboard_Portfolio.getPortfolioSnapData(p.userData, fresh).then((res) => {
                setportfolioSnap(res)
                console.log(res)
                setportfolioSnapLoading(false)
                setRefreshing(false)
                return res
            })

            //getPortfolioXIRR
            await Dashboard_Portfolio.getPortfolioXIRR(p.userData, fresh).then((res) => {
                setXIRR(res)
                console.log(res)
                setportfolioXIRRloading(false)
            })

            //getClientGoals
            Dashboard_Portfolio.getPortfolioGoals(p.userData, fresh).then((res) => {
                setclientGoals(res)
                console.log(res)
            })

            //getPortfolio
            Dashboard_Portfolio.getPortfolioHoldingsData(p.userData, fresh).then((res) => {
                setPortfolioHoldings(res)
                console.log(res)
                setportfolioloading(false)
            })

            //getPortfolioPerformace
            Dashboard_Portfolio.getPortfolioPerformance(p.userData, fresh).then((res) => {
                setPortfolioPerformance(res)
                console.log(res)
                setChartLoad(false)
            })

            //getPortfolioAssets
            Dashboard_Portfolio.getPortfolioAsset(p.userData, fresh).then((res) => {
                console.log(res)
                getAssetData(res, portfolioSnapshot)
                getInstrumentData(res, portfolioSnapshot)
            })


            //getPortfolioAssets
            Dashboard_Portfolio.getPortfolioTransaction(p.userData, fresh).then((res) => {
                console.log(res)
                setTransactions(res)
            })

            //getPortfolioAssets
            Dashboard_Portfolio.getPortfolioScheduledOrders(p.userData, fresh).then((res) => {
                console.log(res)
                setScheduledOrders(res)
            })

            //getPortfolioXIRR
            // await Dashboard_Portfolio.getPortfolioHoldingsData(p.userData, fresh).then((res) => {
            //     setPortfolioHoldings(res)
            //     setportfolioloading(false)
            // })
        });
    };

    const refreshData = async () => {
        setRefreshing(true);
        loadData(true);
    };

    React.useEffect(() => {
        loadData()
    }, []);


    const executeSmallCaseTransaction = async () => {
        setIsFabOpen(true)
        var order = {
            "intent": "HOLDINGS_IMPORT",
            "assetConfig": {
                "mfHoldings": false
            }
        }
        var id = await localStorage.getItem("smallcaseSDKToken")
        if (jwt_decode(id).smallcaseAuthId !== undefined) {
            id = jwt_decode(id).smallcaseAuthId
            var res = await AdminServices.getSmallcaseTransactionId(order, id).then((rs) => { return rs })
            console.log("New id =>>", res)

            // try {
            //     await SmallcaseGateway.triggerTransaction(res.data.data.transactionId)
            //         .then((a) => {
            //             // if (a.success) {
            //             try {
            //                 db.get(db.model.clientDetails).then(async (p) => {
            //                     p = JSON.parse(p);
            //                     var res = await AdminServices.getHoldings(p.userData.id, jwt_decode(a.data.smallcaseAuthToken).smallcaseAuthId).then((rs) => { return rs })


            //                     var res = await AdminServices.updateHoldings(p.userData.id).then((rs) => { return rs })



            //                     refreshData()

            //                     toast.success('Your portfolio holdings are in sync with your demat account.');
            //                     setIsFabOpen(false)

            //                     // }, 3000);


            //                 });
            //             } catch (error) {
            //                 toast.error('Something went wrong! Try again.');
            //             }
            //             //}
            //         })
            //     // .catch((p) => {
            //     //     console.log("chandak", p.userInfo)
            //     //     if (p.userInfo.errorMessage !== "user_cancelled") {
            //     //         toast.error('Something went wrong! Try again.');
            //     //     }
            //     //     setIsFabOpen(false)

            //     //     // if (p.userInfo.errorCode !== 1010 && p.userInfo.errorCode !== 1011) {
            //     //     //     getsmallCaseLoginTransactionId()
            //     //     // }
            //     // });
            // } catch (e) {

            //     db.get(db.model.clientDetails).then(async (p) => {
            //         p = JSON.parse(p);
            //         var res = await AdminServices.getHoldings(p.userData.id, id).then((rs) => { return rs })


            //         var res1 = await AdminServices.updateHoldings(p.userData.id).then((rs) => { return rs })
            //         console.log(res1)


            //         refreshData()

            //         toast.success('Your portfolio holdings are in sync with your demat account.');
            //         setIsFabOpen(false)

            //         // }, 3000);


            //     });
            // }
        } else {
            toast.error('Something went wrong! Try again.');
        }

    }

    const fetchHoldings = () => {
        executeSmallCaseTransaction()
    }

    const ScreenLoader = () => {
        return <div style={{ backgroundColor: '#00000011', zIndex: 1, position: 'absolute', height: '100vh', width: '100%', justifyContent: 'center', alignContent: 'center' }}>
            <ActivityLoaderMintbox />
        </div>
    }

    const CuratedPortfolios = () => {
        var isCustom = 0
        console.log(portfolioHoldings)
        var filteredList = isCustom === 1 ? portfolioHoldings?.CustomMintbox.filter((i) => i.GoalId === 0 && i.WealthCreator === 0) : portfolioHoldings?.Mintboxes.filter((i) => i.GoalId === 0 && i.WealthCreator === 0)

        var allMintboxes = [...new Set(filteredList.map(item => {
            return {
                "MintboxId": item.MintboxId,
                "MintboxName": item.MintboxName,
            }
        }))]

        allMintboxes = Array.from(new Set(allMintboxes.map(JSON.stringify))).map(JSON.parse)
        const [level2Index, setLevel2Index] = useState(-1)
        const [showLevel2, setShowLevel2] = React.useState(false);
        const [FundData, setFundData] = React.useState([]);

        const Content = (rowData) => {
            var isWealth = 0

            const Funds = () => {

                var filteredList = isCustom === 1 ? portfolioHoldings.CustomMintbox.filter((i) => i.GoalId === 0 && i.WealthCreator === 0 && i.MintboxId === rowData.data.MintboxId) : isWealth === 1 ? portfolioHoldings.WealthCreators.filter((i) => i.GoalId === 0 && i.MintboxId === rowData.data.MintboxId) : portfolioHoldings.Mintboxes.filter((i) => i.GoalId === 0 && i.WealthCreator === 0 && i.MintboxId === rowData.data.MintboxId)

                var finalXIRR = isCustom === 1 ? xirr.CustomMintboxFundsXIRR : isWealth === 1 ? xirr.WealthCreatorFundsXIRR : xirr.MintboxFundsXIRR

                const Transactions2 = () => {
                    return <div>
                        {transactions.filter((obj) => obj.FundCode === FundData.FundCode && obj.MintboxId === FundData.MintboxId && obj.GoalId === FundData.GoalId && obj.WealthCreator === FundData.WealthCreator).reverse().map((data, index) => {
                            // console.log("check transcatiobn data ", data)
                            return <div key={index + "SAd"} style={{ borderBottom: "0.1px solid var(--border)" }}>
                                <TransactionCard data={{
                                    date: formatDate(data.TradeDate.toString().replace(" 00:00:00", "")),
                                    type: data.TransactionType,
                                    total: data.Amount,
                                    key: index + "ASD",
                                    name: data.ETFName,
                                    id: data.MintboxId, ...data
                                }} />
                            </div>
                        })}
                    </div>
                }

                const Info = () => {
                    return <div className='row' style={{ borderBottom: "0.1px solid var(--border)" }}>
                        <div className='col-md-12' style={{ margin: "16px 0" }}>
                            {/* <div className="card">
                                <div className="card-body" style={{ padding: "16px" }}> */}
                            <div className='row'>
                                <div className='col-6'>
                                    <div className='fontNormalDark13'>Symbol</div>
                                    <div className='fontH2 w-500' style={{ marginTop: "8px" }}>{FundData.FundCode}</div>
                                </div>
                                <div className='col-6'>
                                    <div className='fontNormalDark13'>Units owned</div>
                                    <div className='fontH2 w-500' style={{ marginTop: "8px" }}>{FundData.Units}</div>
                                </div>
                            </div>
                            {/* </div>
                            </div> */}
                        </div>
                    </div>
                }


                return (
                    <div>
                        {!showLevel2 ?
                            filteredList.map((data) => {
                                var prevBalance = filteredList.length > 0 ? filteredList.filter((item) => item.FundCode === data.FundCode).map(item => item.PrevBalance).reduce((prev, next) => prev + next) || 0 : 0
                                var currentBalance = filteredList.length > 0 ? filteredList.filter((item) => item.FundCode === data.FundCode).map(item => item.CurrentBalance).reduce((prev, next) => prev + next) || 0 : 0

                                var changeBalance
                                var percentageChange

                                if (prevBalance === 0) {
                                    changeBalance = 0
                                    percentageChange = 0.00
                                } else {
                                    changeBalance = prevBalance === 0 ? 0 : currentBalance - prevBalance
                                    percentageChange = ((currentBalance - prevBalance) / prevBalance) * 100 === Infinity ? 0 : ((currentBalance - prevBalance) / prevBalance) * 100
                                }

                                var portXIRR = finalXIRR[data.MintboxId + ":" + data.FundCode + ":"].XIRR

                                return currentBalance !== 0 ? <div key={data.MintboxId} style={{ borderBottom: "0.1px solid var(--border)" }}>
                                    <FundBox
                                        onPress={() => {
                                            setShowLevel2(!showLevel2)
                                            setFundData(data)
                                        }}
                                        level={3}
                                        fundType={data.FundType}
                                        fundData={data}
                                        fundName={data.ETFName}
                                        fundPerChange={percentageChange}
                                        fundChangeBalance={changeBalance}
                                        fundBalance={currentBalance}
                                        fundXirr={portXIRR}
                                        fundGoalID={data.GoalId || 0}
                                        fundGoalName={data.GoalName || ""}
                                    />
                                </div>
                                    : null
                            }) : <div>
                                <FaArrowLeft size={24} style={{ color: "var(--secondaryGreen)", cursor: "pointer" }} onClick={() => setShowLevel2(!showLevel2)} />
                                <div>
                                    <Tabs
                                        defaultActiveKey="1"

                                        items={[
                                            {
                                                label: `Transactions`,
                                                key: '1',
                                                children: <Transactions2 />,
                                            },
                                            {
                                                label: `More info.`,
                                                key: '2',
                                                children: <Info />,
                                            },
                                        ]}
                                    />
                                </div>
                            </div>}
                    </div>
                )
            }

            const Allocation = () => {

                var filteredList = isCustom === 1 ? portfolioHoldings.CustomMintbox.filter((i) => i.GoalId === 0 && i.WealthCreator === 0 && i.MintboxId === rowData.data.MintboxId) : isWealth === 1 ? portfolioHoldings.WealthCreators.filter((i) => i.GoalId === 0 && i.MintboxId === rowData.data.MintboxId) : portfolioHoldings.Mintboxes.filter((i) => i.GoalId === 0 && i.WealthCreator === 0 && i.MintboxId === rowData.data.MintboxId)

                var currentBalance = filteredList.length > 0 ? filteredList.map(item => item.CurrentBalance).reduce((prev, next) => prev + next) || 0 : 0

                var fundConstituentsDataFinal = filteredList.filter((item) => item.CurrentBalance !== 0).map((item) => {

                    return {
                        "CurrentWeightage": (item.CurrentBalance * 100 / currentBalance),
                        "Symbol": item.FundCode,
                        ...item
                    }
                })
                console.log("fundConstituents", fundConstituentsDataFinal)
                return <div>
                    <FundConstituents data={fundConstituentsDataFinal} isPortfolioView={true} type={'portfolio'} />
                </div>
            }

            const SIP = () => {
                var orderList = scheduledOrders.filter((obj) => obj.MintboxId === rowData.data.MintboxId && obj.GoalId === 0 && obj.WealthCreator === 0)
                console.log("list sip", orderList)
                if (orderList.length === 0) {
                    return (
                        <div style={{ flex: 1, alignItems: "center" }}>
                            <img src="" />
                            <div className='fontBoldDark15' style={{ textAlign: "center", margin: "16px 0" }}>You don't have any active SIPs</div>
                        </div>
                    )
                } else {
                    return <div>
                        {orderList.map((data, index) => {
                            return <div key={index + "SAd"}>
                                <SIPCard
                                    orderType={"sip"}
                                    fundData={data}
                                    fundType={3}
                                    status={data.IsDeleted === 1 ? 0 : data.IsActive === 1 ? 1 : 2}
                                // onModifyPress={() => {
                                //     setIsModelOpen(true)
                                //     setModalType(1)
                                //     setselectedScheduleItem(data)
                                // }}
                                />
                            </div>
                        })}
                    </div>
                }
            }

            const Transactions = () => {
                try {
                    return (
                        <div>
                            {transactions.filter((obj) => obj.MintboxId === rowData.data.MintboxId && obj.GoalId === 0 && obj.WealthCreator === isWealth && obj.IsCustom === isCustom).reverse().map((data, index) => {
                                return <div key={index + "SAd"} style={{ borderBottom: "0.1px solid var(--border)" }}>
                                    <TransactionCard data={{
                                        date: formatDate(data.TradeDate.toString().replace(" 00:00:00", "")),
                                        type: data.TransactionType,
                                        total: data.Amount,
                                        key: index + "ASD",
                                        name: data.ETFName,
                                        id: data.MintboxId, ...data
                                    }} />
                                </div>
                            })}
                        </div>
                    )
                } catch (error) {
                    return <div className='fontNormalLight13'>No Data Found</div>
                }
            }

            return <>
                <Tabs
                    defaultActiveKey="1"
                    items={[
                        {
                            label: `Funds`,
                            key: '1',
                            children: <Funds />,
                        },
                        {
                            label: `Allocation`,
                            key: '2',
                            children: <Allocation />,
                        },
                        {
                            label: `SIPs`,
                            key: '3',
                            children: <SIP />,
                        },
                        {
                            label: `Transactions`,
                            key: '4',
                            children: <Transactions />,
                        },
                    ]}
                />
            </>
        }

        if (filteredList.length === 0) {
            return (
                <div style={{ flex: 1, alignItems: "center" }}>
                    {/* <img src="" /> */}
                    <div className='fontBoldDark15' style={{ textAlign: "center", margin: "16px 0" }}>You don't have any active portfolio</div>
                </div>
            )
        } else {
            return (
                <Row style={{ gap: "10px", maxHeight: "60vh", minHeight: "60vh" }}>
                    <Col sm={7} className="p-0" style={{ position: "relative", backgroundColor: "#fff", borderRadius: "5px", maxHeight: "59vh", overflowY: "auto" }}>

                        {allMintboxes.map((rowData, index) => {
                            // console.log("testsgdf", rowData)
                            var prevBalance = (filteredList.filter(item =>
                                item.MintboxId === rowData.MintboxId
                            )).map(item => item.PrevBalance).reduce((prev, next) => prev + next)
                            var currentBalance = (filteredList.filter(item =>
                                item.MintboxId === rowData.MintboxId
                            )).map(item => item.CurrentBalance).reduce((prev, next) => prev + next)

                            var changeBalance
                            var percentageChange

                            if (prevBalance === 0) {
                                changeBalance = 0
                                percentageChange = 0.00
                            } else {
                                changeBalance = prevBalance === 0 ? 0 : currentBalance - prevBalance
                                percentageChange = ((currentBalance - prevBalance) / prevBalance) * 100 === Infinity ? 0 : ((currentBalance - prevBalance) / prevBalance) * 100
                            }

                            var portXIRR = xirr[isCustom === 1 ? "CustomMintboxXIRR" : "MintboxXIRR"][String(rowData.MintboxId)].XIRR

                            return currentBalance !== 0 ? <div key={index} style={{ borderBottom: "0.1px solid var(--border)", backgroundColor: level2Index !== index ? "#fff" : "#fafafa" }}>
                                <FundBox
                                    onClick={() => {
                                        setLevel2Index(index)
                                    }
                                    }
                                    isSelected={level2Index === index}
                                    fundType={3}
                                    fundData={rowData}
                                    fundName={rowData.MintboxName}
                                    fundPerChange={percentageChange}
                                    fundChangeBalance={changeBalance}
                                    fundBalance={currentBalance}
                                    fundXirr={portXIRR}
                                    fundGoalID={rowData.GoalId || 0}
                                    fundGoalName={rowData.GoalName || ""}
                                />
                            </div>
                                : null
                        })}
                    </Col>
                    <Col style={{ backgroundColor: "#fafafa", maxHeight: "59vh", overflowY: "auto", flex: 1 }}>
                        {level2Index !== -1 ? <Content data={allMintboxes[level2Index]} /> : <>
                            <div style={{ display: "flex", flexDirection: "column", flex: 1, height: "100%", alignItems: "center", justifyContent: "center" }}>
                                <img
                                    src='https://cdn-icons-png.flaticon.com/512/3344/3344553.png'
                                    height={100}
                                    width={100}
                                />
                                <br></br>
                                <div>Select any fund to view details</div>
                            </div>
                        </>}
                    </Col>
                </Row>
            )
        }
    }

    const CustomPortfolios = () => {
        var isCustom = 1
        var filteredList = isCustom === 1 ? portfolioHoldings.CustomMintbox.filter((i) => i.GoalId === 0 && i.WealthCreator === 0) : portfolioHoldings.Mintboxes.filter((i) => i.GoalId === 0 && i.WealthCreator === 0)

        var allMintboxes = [...new Set(filteredList.map(item => {
            return {
                "MintboxId": item.MintboxId,
                "MintboxName": item.MintboxName,
            }
        }))]

        allMintboxes = Array.from(new Set(allMintboxes.map(JSON.stringify))).map(JSON.parse)

        const [level2Index, setLevel2Index] = useState(-1)
        const [showLevel2, setShowLevel2] = React.useState(false);
        const [FundData, setFundData] = React.useState([]);

        const Content = (rowData) => {
            var isWealth = 0

            const Funds = () => {

                var filteredList = isCustom === 1 ? portfolioHoldings.CustomMintbox.filter((i) => i.GoalId === 0 && i.WealthCreator === 0 && i.MintboxId === rowData.data.MintboxId) : isWealth === 1 ? portfolioHoldings.WealthCreators.filter((i) => i.GoalId === 0 && i.MintboxId === rowData.data.MintboxId) : portfolioHoldings.Mintboxes.filter((i) => i.GoalId === 0 && i.WealthCreator === 0 && i.MintboxId === rowData.data.MintboxId)

                var finalXIRR = isCustom === 1 ? xirr.CustomMintboxFundsXIRR : isWealth === 1 ? xirr.WealthCreatorFundsXIRR : xirr.MintboxFundsXIRR

                const Transactions2 = () => {
                    return <div>
                        {transactions.filter((obj) => obj.FundCode === FundData.FundCode && obj.MintboxId === FundData.MintboxId && obj.GoalId === FundData.GoalId && obj.WealthCreator === FundData.WealthCreator).reverse().map((data, index) => {
                            // console.log("check transcatiobn data ", data)
                            return <div key={index + "SAd"} style={{ borderBottom: "0.1px solid var(--border)" }}>
                                <TransactionCard data={{
                                    date: formatDate(data.TradeDate.toString().replace(" 00:00:00", "")),
                                    type: data.TransactionType,
                                    total: data.Amount,
                                    key: index + "ASD",
                                    name: data.ETFName,
                                    id: data.MintboxId, ...data
                                }} />
                            </div>
                        })}
                    </div>
                }

                const Info = () => {
                    return <div className='row'>
                        <div className='col-md-12' style={{ marginTop: "16px" }}>
                            <div className="card">
                                <div className="card-body" style={{ padding: "16px" }}>
                                    <div className='row'>
                                        <div className='col-6'>
                                            <div className='fontNormalLight13'>Symbol</div>
                                            <div className='fontBoldLight13' style={{ marginTop: "8px" }}>{FundData.FundCode}</div>
                                        </div>
                                        <div className='col-6'>
                                            <div className='fontNormalLight13'>Units owned</div>
                                            <div className='fontBoldLight13' style={{ marginTop: "8px" }}>{FundData.Units}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }


                return (
                    <div>
                        {!showLevel2 ?
                            filteredList.map((data) => {
                                var prevBalance = filteredList.length > 0 ? filteredList.filter((item) => item.FundCode === data.FundCode).map(item => item.PrevBalance).reduce((prev, next) => prev + next) || 0 : 0
                                var currentBalance = filteredList.length > 0 ? filteredList.filter((item) => item.FundCode === data.FundCode).map(item => item.CurrentBalance).reduce((prev, next) => prev + next) || 0 : 0

                                var changeBalance
                                var percentageChange

                                if (prevBalance === 0) {
                                    changeBalance = 0
                                    percentageChange = 0.00
                                } else {
                                    changeBalance = prevBalance === 0 ? 0 : currentBalance - prevBalance
                                    percentageChange = ((currentBalance - prevBalance) / prevBalance) * 100 === Infinity ? 0 : ((currentBalance - prevBalance) / prevBalance) * 100
                                }

                                var portXIRR = finalXIRR[data.MintboxId + ":" + data.FundCode + ":"].XIRR

                                return currentBalance !== 0 ? <div key={data.MintboxId} style={{ borderBottom: "0.1px solid var(--border)" }}>
                                    <FundBox
                                        onPress={() => {
                                            setShowLevel2(!showLevel2)
                                            setFundData(data)
                                        }}
                                        level={3}
                                        fundType={data.FundType}
                                        fundData={data}
                                        fundName={data.ETFName}
                                        fundPerChange={percentageChange}
                                        fundChangeBalance={changeBalance}
                                        fundBalance={currentBalance}
                                        fundXirr={portXIRR}
                                        fundGoalID={data.GoalId || 0}
                                        fundGoalName={data.GoalName || ""}
                                    />
                                </div>
                                    : null
                            }) : <div>
                                <FaArrowLeft size={24} style={{ color: "var(--secondaryGreen)", cursor: "pointer" }} onClick={() => setShowLevel2(!showLevel2)} />
                                <div>
                                    <Tabs
                                        defaultActiveKey="1"

                                        items={[
                                            {
                                                label: `Transactions`,
                                                key: '1',
                                                children: <Transactions2 />,
                                            },
                                            {
                                                label: `More info.`,
                                                key: '2',
                                                children: <Info />,
                                            },
                                        ]}
                                    />
                                </div>
                            </div>}
                    </div>
                )
            }

            const Allocation = () => {

                var filteredList = isCustom === 1 ? portfolioHoldings.CustomMintbox.filter((i) => i.GoalId === 0 && i.WealthCreator === 0 && i.MintboxId === rowData.data.MintboxId) : isWealth === 1 ? portfolioHoldings.WealthCreators.filter((i) => i.GoalId === 0 && i.MintboxId === rowData.data.MintboxId) : portfolioHoldings.Mintboxes.filter((i) => i.GoalId === 0 && i.WealthCreator === 0 && i.MintboxId === rowData.data.MintboxId)

                var currentBalance = filteredList.length > 0 ? filteredList.map(item => item.CurrentBalance).reduce((prev, next) => prev + next) || 0 : 0

                var fundConstituentsDataFinal = filteredList.filter((item) => item.CurrentBalance !== 0).map((item) => {

                    return {
                        "CurrentWeightage": (item.CurrentBalance * 100 / currentBalance),
                        "Symbol": item.FundCode,
                        ...item
                    }
                })
                console.log("fundConstituents", fundConstituentsDataFinal)
                return <div>
                    <FundConstituents data={fundConstituentsDataFinal} type={'portfolio'} />
                </div>
            }

            const SIP = () => {
                var orderList = scheduledOrders.filter((obj) => obj.MintboxId === rowData.data.MintboxId && obj.GoalId === 0 && obj.WealthCreator === 0)
                console.log("list sip", orderList)
                if (orderList.length === 0) {
                    return (
                        <div style={{ flex: 1, alignItems: "center" }}>
                            <img src="" />
                            <div className='fontBoldDark15' style={{ textAlign: "center", margin: "16px 0" }}>You don't have any active SIPs</div>
                        </div>
                    )
                } else {
                    return <div>
                        {orderList.map((data, index) => {
                            return <div key={index + "SAd"} style={{ borderBottom: "0.1px solid var(--border)" }}>
                                <SIPCard
                                    orderType={"sip"}
                                    fundData={data}
                                    fundType={3}
                                    status={data.IsDeleted === 1 ? 0 : data.IsActive === 1 ? 1 : 2}
                                // onModifyPress={() => {
                                //     setIsModelOpen(true)
                                //     setModalType(1)
                                //     setselectedScheduleItem(data)
                                // }}
                                />
                            </div>
                        })}
                    </div>
                }
            }

            const Transactions = () => {
                try {
                    return (
                        <div>
                            {transactions.filter((obj) => obj.MintboxId === rowData.data.MintboxId && obj.GoalId === 0 && obj.WealthCreator === isWealth && obj.IsCustom === isCustom).reverse().map((data, index) => {
                                return <div key={index + "SAd"} style={{ borderBottom: "0.1px solid var(--border)" }}>
                                    <TransactionCard data={{
                                        date: formatDate(data.TradeDate.toString().replace(" 00:00:00", "")),
                                        type: data.TransactionType,
                                        total: data.Amount,
                                        key: index + "ASD",
                                        name: data.ETFName,
                                        id: data.MintboxId, ...data
                                    }} />
                                </div>
                            })}
                        </div>
                    )
                } catch (error) {
                    return <div className='fontNormalLight13'>No Data Found</div>
                }
            }

            return <>
                <Tabs
                    defaultActiveKey="1"

                    items={[
                        {
                            label: `Funds`,
                            key: '1',
                            children: <Funds />,
                        },
                        {
                            label: `Allocation`,
                            key: '2',
                            children: <Allocation />,
                        },
                        {
                            label: `SIPs`,
                            key: '3',
                            children: <SIP />,
                        },
                        {
                            label: `Transactions`,
                            key: '4',
                            children: <Transactions />,
                        },
                    ]}
                />
            </>
        }

        if (filteredList.length === 0) {
            return (
                <div style={{ flex: 1, alignItems: "center" }}>
                    <img src="" />
                    <div className='fontBoldDark15' style={{ textAlign: "center", margin: "16px 0" }}>You don't have any active portfolio</div>
                </div>
            )
        } else {
            return (
                <Row style={{ gap: "10px", maxHeight: "60vh", minHeight: "60vh" }}>
                    <Col sm={7} className="p-0" style={{ position: "relative", backgroundColor: "#fff", borderRadius: "5px", maxHeight: "59vh", overflowY: "auto" }}>
                        {allMintboxes.map((rowData, index) => {
                            // console.log("testsgdf", rowData)
                            var prevBalance = (filteredList.filter(item =>
                                item.MintboxId === rowData.MintboxId
                            )).map(item => item.PrevBalance).reduce((prev, next) => prev + next)
                            var currentBalance = (filteredList.filter(item =>
                                item.MintboxId === rowData.MintboxId
                            )).map(item => item.CurrentBalance).reduce((prev, next) => prev + next)

                            var changeBalance
                            var percentageChange

                            if (prevBalance === 0) {
                                changeBalance = 0
                                percentageChange = 0.00
                            } else {
                                changeBalance = prevBalance === 0 ? 0 : currentBalance - prevBalance
                                percentageChange = ((currentBalance - prevBalance) / prevBalance) * 100 === Infinity ? 0 : ((currentBalance - prevBalance) / prevBalance) * 100
                            }

                            var portXIRR = xirr[isCustom === 1 ? "CustomMintboxXIRR" : "MintboxXIRR"][String(rowData.MintboxId)].XIRR

                            return currentBalance !== 0 ? <div key={index} style={{ borderBottom: "0.1px solid var(--border)", backgroundColor: level2Index !== index ? "#fff" : "#fafafa" }}>
                                <FundBox
                                    onClick={() => {
                                        setLevel2Index(index)
                                    }
                                    }
                                    isSelected={level2Index === index}
                                    fundType={3}
                                    fundData={rowData}
                                    fundName={rowData.MintboxName}
                                    fundPerChange={percentageChange}
                                    fundChangeBalance={changeBalance}
                                    fundBalance={currentBalance}
                                    fundXirr={portXIRR}
                                    fundGoalID={rowData.GoalId || 0}
                                    fundGoalName={rowData.GoalName || ""}
                                />
                            </div>
                                : null
                        })}
                    </Col>
                    <Col style={{ backgroundColor: "#fafafa", maxHeight: "59vh", overflowY: "auto", flex: 1 }}>
                        {level2Index !== -1 ? <Content data={allMintboxes[level2Index]} /> : <>
                            <div style={{ display: "flex", flexDirection: "column", flex: 1, height: "100%", alignItems: "center", justifyContent: "center" }}>
                                <img
                                    src='https://cdn-icons-png.flaticon.com/512/3344/3344553.png'
                                    height={100}
                                    width={100}
                                />
                                <br></br>
                                <div>Select any fund to view details</div>
                            </div>
                        </>}
                    </Col>
                </Row>

            )
        }
    }

    const LowCostEtfs = () => {
        var fundType = 1
        var isCustom = 0
        var isWealth = 0

        let diyETFCurVal = portfolioloading ? 0 : portfolioHoldings.CustomAll.filter((i => i.FundType === 1)).length > 0 ? portfolioHoldings.CustomAll.filter((i => i.FundType === 1)).map(item => item.CurrentBalance).reduce((prev, next) => prev + next) || 0 : 0
        let diyETFPrevVal = portfolioloading ? 0 : portfolioHoldings.CustomAll.filter((i => i.FundType === 1)).length > 0 ? portfolioHoldings.CustomAll.filter((i => i.FundType === 1)).map(item => item.PrevBalance).reduce((prev, next) => prev + next) || 0 : 0

        var filteredList = portfolioHoldings.CustomAll.filter((i) => i.MintboxId === 0 && i.GoalId === 0 && i.FundType === fundType)

        const [level2Index, setLevel2Index] = useState(-1)

        const Content = (rowData) => {

            const SIPs = () => {
                var orderList = scheduledOrders.filter((obj) => obj.FolioNumber === rowData.data.FolioNumber && obj.TransactionType === 1 && obj.FromFundCode.length === 0 && String(obj.ToFundCode) === String(rowData.data.FundCode) && obj.IsCustom === isCustom && obj.WealthCreator === isWealth)

                if (orderList.length === 0) {
                    return (
                        <div style={{ flex: 1, alignItems: "center" }}>
                            <img src="" />
                            <div className='fontBoldDark15' style={{ textAlign: "center", margin: "16px 0" }}>You don't have any active SIPs</div>
                        </div>
                    )
                } else {
                    return <div>
                        {orderList.map((data, index) => {
                            return <div key={index + "SAd"} style={{ borderBottom: "0.1px solid var(--border)" }}>
                                <SIPCard
                                    orderType={"sip"}
                                    fundData={data}
                                    fundType={fundType}
                                    status={data.IsDeleted === 1 ? 0 : data.IsActive === 1 ? 1 : 2}
                                // onModifyPress={() => {
                                //     setIsModelOpen(true)
                                //     setModalType(1)
                                //     setselectedScheduleItem(data)
                                // }}
                                />
                            </div>
                        })}
                    </div>
                }
            }

            const Transactions2 = () => {
                return <div>
                    {transactions.filter((obj) => obj.FundCode === rowData.data.FundCode && obj.MintboxId === rowData.data.MintboxId && obj.GoalId === rowData.data.GoalId && obj.WealthCreator === rowData.data.WealthCreator).reverse().map((data, index) => {
                        console.log("check transcatiobn data ", data)
                        return <div key={index + "SAd"} style={{ borderBottom: "0.1px solid var(--border)" }}>
                            <TransactionCard data={{
                                date: formatDate(data.TradeDate.toString().replace(" 00:00:00", "")),
                                type: data.TransactionType,
                                total: data.Amount,
                                key: index + "ASD",
                                name: data.ETFName,
                                id: data.MintboxId, ...data
                            }} />
                        </div>
                    })}
                </div>
            }

            const Info = () => {
                return <div className='row' style={{ borderBottom: "0.1px solid var(--border)" }}>
                    <div className='col-md-12' style={{ margin: "16px 0" }}>
                        {/* <div className="card">
                            <div className="card-body" style={{ padding: "16px" }}> */}
                        <div className='row'>
                            <div className='col-6'>
                                <div className='fontNormalDark13'>Symbol</div>
                                <div className='fontH2 w-500' style={{ marginTop: "8px" }}>{rowData.data.FundCode}</div>
                            </div>
                            <div className='col-6'>
                                <div className='fontNormalDark3'>Units owned</div>
                                <div className='fontH2 w-500' style={{ marginTop: "8px" }}>{rowData.data.Units}</div>
                            </div>
                        </div>
                        {/* </div>
                        </div> */}
                    </div>
                </div>
            }

            return <>
                <Tabs
                    defaultActiveKey="1"

                    items={[
                        {
                            label: `SIPs`,
                            key: '1',
                            children: <SIPs />,
                        },
                        {
                            label: `Transactions`,
                            key: '2',
                            children: <Transactions2 />,
                        },
                        {
                            label: `More info.`,
                            key: '3',
                            children: <Info />,
                        },
                    ]}
                />
            </>
        }

        if (filteredList.length === 0) {
            return (
                <div style={{ flex: 1, alignItems: "center" }}>
                    <img src="" />
                    <div className='fontBoldDark15' style={{ textAlign: "center", margin: "16px 0" }}>You don't have any active ETFs</div>
                </div>
            )
        } else {
            return (
                <Row style={{ gap: "10px", maxHeight: "60vh", minHeight: "60vh" }}>
                    <Col sm={7} className="p-0" style={{ position: "relative", backgroundColor: "#fff", borderRadius: "5px", maxHeight: "59vh", overflowY: "auto" }}>
                        {filteredList.map((rowData, index) => {
                            // console.log("testsgdf", rowData)
                            var prevBalance = (filteredList.filter(item =>
                                item.plan_id === rowData.plan_id
                            )).map(item => item.PrevBalance).reduce((prev, next) => prev + next)
                            var currentBalance = (filteredList.filter(item =>
                                item.plan_id === rowData.plan_id
                            )).map(item => item.CurrentBalance).reduce((prev, next) => prev + next)

                            var changeBalance
                            var percentageChange

                            if (prevBalance === 0) {
                                changeBalance = 0
                                percentageChange = 0.00
                            } else {
                                changeBalance = prevBalance === 0 ? 0 : currentBalance - prevBalance
                                percentageChange = ((currentBalance - prevBalance) / prevBalance) * 100 === Infinity ? 0 : ((currentBalance - prevBalance) / prevBalance) * 100
                            }

                            var portXIRR = xirr[fundType === 1 ? "ETFXIRR" : "MFXIRR"][fundType === 1 ? rowData.FundCode : String(rowData.FundCode + ":" + rowData.FolioNumber)].XIRR

                            return currentBalance !== 0 ? <div key={index} style={{ borderBottom: "0.1px solid var(--border)", backgroundColor: level2Index !== index ? "#fff" : "#fafafa" }}>
                                <FundBox
                                    onClick={() => {
                                        setLevel2Index(index)
                                    }
                                    }
                                    isSelected={level2Index === index}
                                    fundType={fundType}
                                    fundData={rowData}
                                    fundName={rowData.ETFName}
                                    fundPerChange={percentageChange}
                                    fundChangeBalance={changeBalance}
                                    fundBalance={currentBalance}
                                    fundXirr={portXIRR}
                                    fundGoalID={rowData.GoalId || 0}
                                    fundGoalName={rowData.GoalName || ""}
                                />
                            </div>
                                : null
                        })}
                    </Col>
                    <Col style={{ backgroundColor: "#fafafa", maxHeight: "59vh", overflowY: "auto", flex: 1 }}>
                        {level2Index !== -1 ? <Content data={filteredList[level2Index]} /> : <>
                            <div style={{ display: "flex", flexDirection: "column", flex: 1, height: "100%", alignItems: "center", justifyContent: "center" }}>
                                <img
                                    src='https://cdn-icons-png.flaticon.com/512/3344/3344553.png'
                                    height={100}
                                    width={100}
                                />
                                <br></br>
                                <div>Select any fund to view details</div>
                            </div>
                        </>}
                    </Col>
                </Row>
            )
        }
    }

    const WealthCreator = () => {
        var isCustom = 0
        var isWealth = 1


        const Content = (rowData) => {
            var isWealth = 0

            const Funds = () => {
                return (
                    <></>
                )
            }

            return <>
                <Tabs
                    defaultActiveKey="1"

                    items={[
                        {
                            label: `Funds`,
                            key: '1',
                            children: <Funds />,
                        },
                        {
                            label: `Allocation`,
                            key: '2',
                            children: "",
                        },
                        {
                            label: `SIPs`,
                            key: '3',
                            children: "",
                        },
                        {
                            label: `Transactions`,
                            key: '4',
                            children: '',
                        },
                    ]}
                />
            </>
        }


        return (
            <Row style={{ gap: "10px", maxHeight: "60vh", minHeight: "60vh" }}>
                <Col style={{ backgroundColor: "#fafafa", maxHeight: "59vh", overflowY: "auto" }}>

                </Col>
            </Row>
        )
    }

    const Goals = () => {

        const [level2Index, setLevel2Index] = useState(-1)

        // console.log(portfolioHoldings.Goals)
        var allGoals = [...new Set(portfolioHoldings.Goals.map(item => {
            return {
                "GoalId": item.GoalId,
                "GoalName": item.GoalName,
                "GoalTargetCorpus": item.GoalTargetCorpus,
                "GoalDueDate": item.GoalDueDate,
                "MinLumpsumAmount": Number(item.MinLumpsumAmount),
                "MinSIPAmount": Number(item.MinSIPAmount),
                "MinSubsequentLumpsum": Number(item.MinSubsequentLumpsum),
                "MintboxId": Number(item.MintboxId),
            }
        }))]
        allGoals = Array.from(new Set(allGoals.map(JSON.stringify))).map(JSON.parse)


        const Content = (rowData) => {

            let goalsData = clientGoals.filter((pk) => pk.GoalId === rowData.data.GoalId)
            console.log("goal data olaksdjak", goalsData, rowData)

            let goalData = goalsData[0]

            const Constituents = () => {
                var filteredList = portfolioHoldings.Goals.filter((i) => i.GoalId === rowData.data.GoalId)

                var currentBalance = filteredList.length > 0 ? filteredList.map(item => item.CurrentBalance).reduce((prev, next) => prev + next) || 0 : 0
                var fundConstituentsDataFinal = filteredList.filter((item) => item.CurrentBalance !== 0).map((item) => {
                    return {
                        "CurrentWeightage": (item.CurrentBalance * 100 / currentBalance),
                        "Symbol": item.FundCode,
                        ...item
                    }
                })

                return <div>
                    <FundConstituents data={fundConstituentsDataFinal} type={'portfolio'} />
                </div>

            }

            const FinancialPlan = () => {
                return <div>
                    <div className='row' style={{ borderBottom: "0.1px solid var(--border)" }}>
                        <div className='col-md-12' style={{ margin: "0 0 16px 0" }}>
                            <div className='fontH2 w-normal black mb-2'>Your inputs</div>
                            <ListViewWithIcon
                                children={[
                                    `Present cost of goal: ${CurrencyFormat(goalData.GoalPresentValue, "long")}`
                                    , `Years until goal: ${goalData.Tenure} years`
                                    , `Upfront investment: ${CurrencyFormat(goalData.LumpsumInvestment, "long")}`
                                    , `Annual increase in SIP: ${goalData.YearlyIncrementalInvestment}%`
                                ]}
                            />
                        </div>
                    </div>
                    <div className='row' style={{}}>
                        <div className='col-md-12' style={{ margin: "16px 0" }}>
                            <div className='fontH2 w-normal black mb-2'>Plan's assumptions</div>
                            <ListViewWithIcon
                                children={[
                                    `Target annual returns: ${goalData.TargetXIRR}%`
                                    , `Inflation: ${goalData.Inflation}%`
                                    , `Capital gains tax: 10%`
                                ]}
                            />
                        </div>
                    </div>
                </div>
            }

            const SIPs = () => {
                var orderList = scheduledOrders.filter((obj) => obj.GoalId === rowData.data.GoalId)

                if (orderList.length === 0) {
                    return (
                        <div style={{ flex: 1, alignItems: "center" }}>
                            <img src="" />
                            <div className='fontBoldDark15' style={{ textAlign: "center", margin: "16px 0" }}>You don't have any active SIPs</div>
                        </div>
                    )
                } else {
                    return <div>
                        {orderList.map((data, index) => {
                            return <div key={index + "SAd"} style={{ borderBottom: "0.1px solid var(--border)" }}>
                                <SIPCard
                                    orderType={"sip"}
                                    fundData={data}
                                    fundType={3}
                                    status={data.IsDeleted === 1 ? 0 : data.IsActive === 1 ? 1 : 2}
                                // onModifyPress={() => {
                                //     setIsModelOpen(true)
                                //     setModalType(1)
                                //     setselectedScheduleItem(data)
                                // }}
                                />
                            </div>
                        })}
                    </div>
                }
            }

            const Transactions2 = () => {
                var transactionsAll = transactions.filter((obj) => obj.GoalId === rowData.data.GoalId)
                var allconsotran = [...new Set(transactionsAll.map(item => {
                    return {
                        "TradeDate": item.TradeDate,
                        "TransactionType": item.TransactionType
                    }
                }))]
                allconsotran = Array.from(new Set(allconsotran.map(JSON.stringify))).map(JSON.parse)

                console.log(allconsotran)
                return <div>
                    {allconsotran.reverse().map((data, index) => {
                        console.log("check transcatiobn data ", data)
                        return <div key={index + "SAd"} style={{ borderBottom: "0.1px solid var(--border)" }}>
                            <TransactionCard data={{
                                date: formatDate(data.TradeDate.toString().replace(" 00:00:00", "")),
                                type: data.TransactionType,
                                total: (transactionsAll.filter(item =>
                                    item.TradeDate === data.TradeDate && item.TransactionType === data.TransactionType
                                )).map(item => item.Amount).reduce((prev, next) => prev + next),
                                Units: (transactionsAll.filter(item =>
                                    item.TradeDate === data.TradeDate && item.TransactionType === data.TransactionType
                                )).map(item => Math.abs(item.Units)).reduce((prev, next) => prev + next),
                                key: index + "ASD",
                                name: data.ETFName,
                                GoalId: rowData.data.GoalId,
                                id: data.MintboxId, ...data
                            }} />
                        </div>
                    })}
                </div>
            }



            return <>
                <Tabs
                    defaultActiveKey="1"

                    items={[
                        {
                            label: `Constituents`,
                            key: '1',
                            children: <Constituents />,
                        },
                        {
                            label: `Financial Plan`,
                            key: '2',
                            children: <FinancialPlan />,
                        },
                        {
                            label: `SIPs`,
                            key: '3',
                            children: <SIPs />,
                        },
                        {
                            label: `Transactions`,
                            key: '4',
                            children: <Transactions2 />,
                        },
                    ]}
                />
            </>
        }

        if (portfolioHoldings.Goals.length === 0) {
            return (
                <div style={{ flex: 1, alignItems: "center" }}>
                    <img src="" />
                    <div className='fontBoldDark15' style={{ textAlign: "center", margin: "16px 0" }}>No goals created</div>
                </div>
            )
        } else {
            return (
                <Row style={{ gap: "10px", maxHeight: "60vh", minHeight: "60vh" }}>
                    <Col sm={7} className="p-0" style={{ position: "relative", backgroundColor: "#fff", borderRadius: "5px", maxHeight: "59vh", overflowY: "auto" }}>
                        {allGoals.map((rowData, index) => {
                            console.log("goals data", rowData)
                            return <div key={index} style={{ borderBottom: "0.1px solid var(--border)", backgroundColor: level2Index !== index ? "#fff" : "#fafafa" }}>
                                <PortfolioGoalCard
                                    onClick={() => {
                                        setLevel2Index(index)
                                    }}
                                    isSelected={level2Index === index}
                                    fundData={{
                                        ...rowData,
                                        "MintboxBalance": (portfolioHoldings.Goals.filter(item =>
                                            item.GoalId === rowData.GoalId
                                        )).map(item => item.CurrentBalance).reduce((prev, next) => prev + next)
                                    }}
                                />
                            </div>
                        })}
                    </Col>
                    <Col style={{ backgroundColor: "#fafafa", maxHeight: "59vh", overflowY: "auto", flex: 1 }}>
                        {level2Index !== -1 ? <Content data={allGoals[level2Index]} /> : <>
                            <div style={{ display: "flex", flexDirection: "column", flex: 1, height: "100%", alignItems: "center", justifyContent: "center" }}>
                                <img
                                    src='https://cdn-icons-png.flaticon.com/512/3344/3344553.png'
                                    height={100}
                                    width={100}
                                />
                                <br></br>
                                <div>Select any goal to view details</div>
                            </div>
                        </>}
                    </Col>
                </Row>
            )
        }
    }

    const portfolioView = () => {

        const ProfileDetails = ({ title, right }) => {
            var prevBalance
            var currentBalance
            var changeBalance
            var percentageChange

            if (portfolioSnap?.Portfolio?.PrevPortfolioBalance === 0) {
                changeBalance = 0
                percentageChange = 0.00
                currentBalance = portfolioSnap?.Portfolio?.PortfolioBalance
            } else {
                var prevBalance = portfolioSnap?.Portfolio?.PrevPortfolioBalance
                var currentBalance = portfolioSnap?.Portfolio?.PortfolioBalance
                changeBalance = prevBalance === 0 ? 0 : currentBalance - prevBalance
                percentageChange = ((currentBalance - prevBalance) / prevBalance) * 100 === Infinity ? 0 : ((currentBalance - prevBalance) / prevBalance) * 100
            }

            var portXIRR = portfolioXIRRloading ? undefined : xirr.PortfolioXIRR.XIRR

            const SnapShot = () => (
                <HomePortfolioCard
                    onRefresh={() => {
                        refreshData()
                    }}
                    show="value"
                    data={[
                        portfolioSnapLoading ? undefined : currentBalance,
                        portfolioSnapLoading ? undefined : percentageChange,
                        portfolioSnapLoading ? undefined : changeBalance,
                        portXIRR
                    ]}
                />
            )

            const growth = () => (
                <HomePortfolioCard
                    onRefresh={() => {
                        refreshData()
                    }}
                    show="chart"
                    chartLoad={chartLoad}
                    data={[
                        portfolioSnapLoading ? undefined : currentBalance, portfolioSnapLoading ? undefined : percentageChange,
                        portfolioSnapLoading ? undefined : changeBalance,
                        true ? undefined : portXIRR
                    ]}
                    chartData={chartLoad ? [] : [
                        {
                            name: 'Portfolio',
                            data: portfolioPerformance.length === 0 ? [] : changeObjKey(portfolioPerformance.Portfolio, ["Balance", "NavDateStr"], ["y", "x"], "type", "ty")
                        }
                    ]}
                    chartMin={chartLoad ? 0 : portfolioPerformance.length === 0 ? 0 : Math.min(...(portfolioPerformance.Portfolio).map(item => item.Balance))}
                />

            )

            const asset = () => {
                var conf = {
                    chart: {
                        type: 'pie',

                        plotBackgroundColor: null,
                        plotBorderWidth: null,
                        plotShadow: false,
                        height: 220
                    },
                    title: {
                        text: ''
                    },
                    scrollbar: {
                        enabled: false
                    },
                    exporting: {
                        enabled: false
                    },

                    plotOptions: {
                        pie: {
                            allowPointSelect: true,
                            cursor: 'pointer',
                            dataLabels: {
                                enabled: false,
                            },
                            showInLegend: true,
                            size: 150,
                            innerSize: '60%',
                        }
                    },
                    credits: {
                        enabled: false
                    },
                    tooltip: {
                        pointFormat: ''
                    },
                    legend: {
                        enabled: true,
                        align: 'right',
                        verticalAlign: 'middle',
                        itemMarginBottom: 10,
                        itemStyle: {
                            color: "var(--black)",
                            fontSize: 12,
                            fontWeight: "normal"
                        }

                    },
                    series: [
                        {
                            data: portfolioAssetChart,
                        }
                    ]
                };

                const options = {
                    global: {
                        useUTC: false
                    },
                    lang: {
                        decimalPoint: '.',
                        thousandsSep: ','
                    }
                };

                return <div style={{
                    backgroundColor: "var(--card)",
                    marginVertical: 20
                }}>
                    {portfolioAssetChart.length === 0 ? <ActivityLoaderMintbox size={"small"} style={{ margin: 20 }} /> : <HighchartsReact highcharts={Highcharts} options={conf} />}
                </div>
            }

            const instrument = () => {
                var conf = {
                    chart: {
                        type: 'pie',

                        backgroundColor: "transparent",
                        plotBackgroundColor: null,
                        plotBorderWidth: null,
                        plotShadow: false,
                        height: 220
                    },
                    title: {
                        text: ''
                    },
                    scrollbar: {
                        enabled: false
                    },
                    exporting: {
                        enabled: false
                    },

                    plotOptions: {
                        pie: {
                            allowPointSelect: true,
                            cursor: 'pointer',
                            dataLabels: {
                                enabled: false,
                            },
                            showInLegend: true,
                            size: 150,
                            innerSize: '60%',
                        }
                    },
                    credits: {
                        enabled: false
                    },
                    tooltip: {
                        pointFormat: ''
                    },
                    legend: {
                        enabled: true,
                        align: 'right',
                        verticalAlign: 'middle',
                        itemMarginBottom: 10,
                        itemStyle: {
                            color: "var(--black)",
                            fontSize: 12,
                            fontWeight: "normal"
                        }

                    },
                    series: [
                        {
                            data: portfolioInstrumentChart,
                        }
                    ]
                };

                const options = {
                    global: {
                        useUTC: false
                    },
                    lang: {
                        decimalPoint: '.',
                        thousandsSep: ','
                    }
                };

                return <div style={{
                    backgroundColor: "var(--card)",
                    marginVertical: 20
                }}>
                    {portfolioInstrumentChart.length === 0 ? <ActivityLoaderMintbox size={"small"} style={{ margin: 20 }} /> : <HighchartsReact highcharts={Highcharts} options={conf} />}

                </div>
            }

            const keyTitles = [
                { key: "growth", label: "Growth", children: growth(), show: !chartLoad },
                { key: "asset", label: "Asset allocation", children: asset(), show: portfolioAssetChart.length !== 0 },
                { key: "Instasset", label: "Instruments", children: instrument(), show: portfolioInstrumentChart.length !== 0 },
            ];
            return <div style={{
                margin: 16,
                marginBottom: 0,
                marginTop: 20,
            }}>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    {title ? <div style={{ marginBottom: 16, }}>{title}</div> : null}
                    <div
                        style={{
                            borderRadius: 60,
                            marginBottom: 16,
                            borderWidth: 1,
                            borderColor: "var(--black)",
                        }}
                        onClick={() => {
                            fetchHoldings()
                        }}
                    >
                        <div style={{ paddingHorizontal: 15, paddingVertical: 2, }}>{right}</div>
                    </div>
                </div>

                <Row style={{ gap: "2rem" }}>
                    <SnapShot />
                    <Card bordered={false}  style={{ flex: 1 }}>
                        <Tabs defaultActiveKey="growth" items={keyTitles.filter((item) => item.show !== false)} onChange={(e) => console.log(e)} />
                    </Card>
                </Row>
            </div >
        };

        return (
            <div style={{ flex: 1 }}>

                <div>
                    <ProfileDetails title={"Summary"} right={"Sync with demat"} />
                </div>
                <div style={{ marginTop: 32, marginBottom: 5 }}>Holdings</div>


                {portfolioloading ? <Skeleton active /> :
                    <Tabs
                        defaultActiveKey="1"
                        items={[
                            {
                                label: `Curated portfolios`,
                                key: '1',
                                children: <CuratedPortfolios />,
                            },
                            {
                                label: `Custom portfolios`,
                                key: '2',
                                children: <CustomPortfolios />,
                            },
                            {
                                label: `Low cost ETFs`,
                                key: '3',
                                children: <LowCostEtfs />,
                            },
                            {
                                label: `Wealth Creator`,
                                key: '4',
                                children: <WealthCreator />,
                            },
                            {
                                label: `Financial Goals`,
                                key: '5',
                                children: <Goals />,
                            }
                        ]}
                    />}

            </div>
        );
    };

    const notInvest = (name, id) => (
        <div style={{ flex: 1, paddingHorizontal: 32, alignItems: "center", marginTop: 30 }}>
            <img
                style={{ height: 100, width: 100 }}
                src={require('../../Assets/icons/no-invest.webp')}
            />
            <div style={{ marginTop: 24 }}>No investments found</div>
            <div style={{ textAlign: "center", marginTop: 4, marginBottom: 32 }}>Just link your demat account to start investing in smart investment portfolios for free!</div>
            {/* <LinkDematAccount
                colorType={1}
                navigation={navigation}
            /> */}
        </div>
    );

    if (portfolioSnapLoading) {
        return (
            <div style={{ flex: 1 }}>
                <div style={{ flex: 1 }}>
                    <ActivityLoaderMintbox />
                </div>
            </div>
        );
    } else {

        var lenf = portfolioSnap?.Portfolio?.PortfolioBalance === 0 || portfolioSnap?.Portfolio?.PortfolioBalance === undefined
        return (
            <div style={{ flex: 1 }}>
                <Head title={"Portfolio"} />

                {lenf
                    ? notInvest("Link demat account >", 2)
                    : portfolioView()
                }

                {isFabOpen ? <ScreenLoader /> : null}

            </div >
        );

    }
}