import { Card, Checkbox, Divider, Drawer, Dropdown as AntDropdown, Empty, message, Segmented, Skeleton, Space, Switch, Table } from "antd";
import Highcharts from 'highcharts';
import HighchartsReact from "highcharts-react-official";
import { getUser, magicBoardCols, screenerMetricData, sortFunds, unique, useGetAssetType } from "Libs/utils";
import React, { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import * as ReactDOM from "react-dom";
import { BiCustomize, BiFilter } from "react-icons/bi";
import { CgAdd } from "react-icons/cg";
import { HiLockClosed } from "react-icons/hi";
import { IoIosArrowForward, IoIosClose } from "react-icons/io";
import { MdArrowDropDown, MdBubbleChart, MdCancel, MdDragIndicator, MdOutlineLock, MdSettings } from "react-icons/md";
import { useNavigate } from "react-router";
import ShowMoreText from "react-show-more-text";
import { Col } from "reactstrap";
import AMCIcon from "./AMCIcon";
import { CardHeader } from "./atoms/SmallComponents";
import { Dropdown } from "./CustomComponents/DropDown";
import CustomSegmentedTab from "./CustomSegmentedTab";
import { changeObjKey } from "./DataCustomizer";
import LoadingTableSkeleton from "./LoadingTableSkeleton";
import StyleBoxIcons from "./StyleBoxIcons";

require('highcharts/highcharts-more')(Highcharts)

const InstrumentListTable = ({
    finalList,
    tableType,
    tableHeader,
    hideHeaderBlueLine = false,
    initialColumns,
    magicBoardtype = "etf",
    otherCol = [],
    paginationSize = 50,
    isCard = true,
    investInScreener = () => { },
    onChangeExcludeStocksSwitch = () => { },
    defExcludeStocks = true,
    defFilters,
    hideCheckBox = false,
    disableInvestBtn = false,
    showCategoryDropDown = false,
    istableLoading = true,
}) => {

    // tableType
    //  1 => screener , 
    //  0 => normal without filters icon but filters applied if passed , 
    //  2 => with filters, 
    //  3=>signal,
    //   5 => compare
    //   6 => alpha
    // 7 => stylebox
    const navigate = useNavigate()
    const type = useGetAssetType();
    const [customFinalList, setCustomFinalList] = useState(finalList);
    const [displayData, setDisplayData] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [appliedFilters, setAppliedFilters] = useState({})
    const [modal, setmodal] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    const [itemPerPage, setItemPerPage] = useState(paginationSize);
    const [pageData, setPageData] = useState({ page: 1, pageSize: paginationSize })
    const [magic_categoryType, setmagic_categoryType] = useState('Equity')
    const [magic_benchType, setmagic_benchType] = useState('NIFTY 50 Total Return Index')
    const [selectedRows, setSelectedRows] = React.useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = React.useState([]);
    const [defFil, setDefFil] = React.useState(defFilters || []);
    const [showBubbleChart, setShowBubbleChart] = useState(false);
    const [bubbleChartType, setbubbleChartType] = useState(1);
    const [userBasedCols, setuserBasedCols] = useState([]);
    const [screenerMetric, setscreenerMetric] = useState([])
    const [screenerFinalData, setscreenerFinalData] = useState([])
    const [allFundsData, setallFundsData] = useState([])
    const [selectedCategory, setselectedCategory] = useState("")
    const [selectedMetric, setselectedMetric] = useState("")
    const [loading, setloading] = useState(true)
    const [excludeSurveillance, setexcludeSurveillance] = useState(defExcludeStocks)
    const [tableLoading, setTableLoading] = useState(true)
    const [user, setUser] = useState(undefined)
    const [styleBoxes, setStyleBoxes] = React.useState([]);
    const [selectedStyleBox, setselectedStyleBox] = React.useState(1);

    React.useEffect(() => {
        setTableLoading(istableLoading)
    }, [istableLoading])

    const sortOptions = [
        {
            value: 'category',
            queryStr: 'category',
            label: 'Category',
        },
        {
            value: 'benchmark',
            queryStr: 'benchmark',
            label: 'Benchmark',
        }
    ]

    const [magicboardFilterType, setmagicboardFilterType] = useState(sortOptions[0].value)

    const [dataTableColumns, setDataTableColumns] = useState(type.value === 4 ? tableType === 3 ? [{
        "title": "Name",
        "dataIndex": "proper_name",
        render: (text, record, index) => <div style={{ display: 'flex', gap: '0.5rem', cursor: "pointer", alignItems: "center" }} onClick={() => navigate(type.basePath + `${type.value === 4 ? '/stock-detail/' + record.symbol : '/fund-detail?code=' + record.plan_id}`)}>
            <AMCIcon amcName={record.symbol} fundType={type.value} height='1.15rem' width="1.15rem" />
            <div>
                <div className="w-500 black" style={{ width: 220, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>{record?.proper_name}</div>
                {/* <div className="text-left textXS ">{stockSectorCLF?.find(s => s.industry_code === record.industry_code)?.industry_desc}</div> */}
                {/* <StockLivePrice symbol={record?.symbol} format={2} /> */}
            </div>
        </div>,
        width: 200,
        fixed: 'left',
        show: true
    }] : [
        {
            "title": "Name",
            "dataIndex": "proper_name",
            render: (text, record, index) => <div style={{ display: 'flex', gap: '0.5rem', cursor: "pointer", alignItems: "center" }} onClick={() => navigate(type.basePath + `${type.value === 4 ? '/stock-detail/' + record.symbol : '/fund-detail/' + record?.basic_name?.replace(" - Direct Plan", "")?.replaceAll(' ', '-') + '?code=' + record.plan_id}`)}>
                <AMCIcon amcName={record.symbol} fundType={type.value} height='1.15rem' width="1.15rem" />
                <div>
                    <div className="w-500 black" style={{ width: 220, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>{record?.proper_name}</div>
                    {/* <div className="text-left textXS ">{stockSectorCLF?.find(s => s.industry_code === record.industry_code)?.industry_desc}</div> */}
                    {/* <StockLivePrice symbol={record?.symbol} format={2} /> */}
                </div>
            </div>,
            width: 200,
            fixed: 'left',
            show: true
        }, {
            "title": "Market capitalization",
            "dataIndex": "mcap",
            width: 150,

        }
    ]
        :
        [
            {
                "title": "Name",
                "dataIndex": "basic_name",
                render: (text, record, index) => <div style={{ display: 'flex', gap: '0.5rem', cursor: "pointer", alignItems: "center", }} onClick={() => navigate(type.basePath + `${type.value === 4 ? '/stock-detail/' + record.symbol : '/fund-detail/' + record?.basic_name?.replace(" - Direct Plan", "")?.replaceAll(' ', '-') + '?code=' + record.plan_id}`)}>
                    <AMCIcon amcName={record.amc_full_name} height='1.15rem' width="1.15rem" />
                    <div>
                        <div className="w-500 black" style={{ width: 220, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>{record?.basic_name?.replace(" - Direct Plan", "")}</div>
                        {/* <div className="text-left textXS ">{record?.category_name}</div> */}
                    </div>
                </div>,
                width: 200,
                fixed: 'left',
                show: true
            }, {
                "title": "Asset under management",
                "dataIndex": "aum",
                width: 150,

            }
        ]);


    const loadData = async () => {
        let user = await getUser(false)
        setUser(user)
        var screener_datas = await screenerMetricData(type)
        setselectedCategory(screener_datas?.levelVals?.m_category_id)
        setStyleBoxes(screener_datas?.fabricatedData?.stockStyles)
        setselectedMetric(screener_datas?.levelVals?.m_metric_col_code)
        setscreenerMetric(screener_datas?.screenerMetricData)
        setscreenerFinalData(screener_datas?.screenerFinalData)
        setallFundsData(screener_datas?.allFunds)
       
        setloading(false)
    }

    React.useEffect(() => {
        loadData()
    }, [])

    React.useEffect(() => {
        if (finalList !== undefined) {
            setCustomFinalList(finalList)
        }
    }, [finalList])

    React.useEffect(() => {
        setDefFil(defFilters || [])
    }, [defFilters])

    React.useEffect(() => {
        setTableLoading(true)
        var fundList = []
        if (tableType === 1) {
            fundList = customFinalList
        } else {
            if (customFinalList?.length > 0) {
                fundList = customFinalList
            } else {
                fundList = allFundsData
            }
        }

        if (type)
            if (searchText !== "") {
                fundList = fundList.filter((item) => {
                    return item[type.value === 4 ? 'proper_name' : 'basic_name'].toLowerCase().includes(searchText.toLowerCase());
                });
            }

        var listf = defFilters || []
        if (showCategoryDropDown) {
            if (magicboardFilterType === "category") {
                listf = listf.concat([["primary_category_name", magic_categoryType]])
                if (user !== undefined && user !== null) {
                    if (user?.Client[0]?.SharpelyAccessLevel === 0) {
                        setuserBasedCols(magicBoardCols[magicBoardtype][magic_categoryType]["paid"])
                    } else {
                        setuserBasedCols(magicBoardCols[magicBoardtype][magic_categoryType]["free"])
                    }
                } else {
                    setuserBasedCols(magicBoardCols[magicBoardtype][magic_categoryType]["free"])
                }
            } else {
                listf = listf.concat([["benchmark", magic_benchType]])
            }

        }
        if (listf?.length > 0) {
            listf?.forEach(item => {
                fundList = fundList.filter((fund) => {
                    return String(fund[String(item[0])]) === String(item[1])
                })
            });
        }

        if (tableType === 7) {
            //stylebox
            fundList = fundList.filter((i) => Number(i.style_box_code) === Number(selectedStyleBox))
        }
        setDisplayData(fundList);

        setDataTableColumns(unique(tableType === 3 ? [...dataTableColumns, ...otherCol,] : [
            ...dataTableColumns,
            ...screenerMetric.filter((item) => initialColumns?.includes(item?.metric_col_code)),
            ...otherCol,
            ...(userBasedCols.length > 0 ? screenerMetric?.filter((item) => userBasedCols?.includes(item.metric_col_code)) : screenerMetric?.filter((i) => i?.isInitial === true)),
        ]?.map((i) => { return i?.dataIndex === "proper_name" || i?.dataIndex === "basic_name" || i?.dataIndex === "mcap" || i?.dataIndex === "aum" ? { ...i, disableDeleteInCustomize: true } : { ...i } }), 'dataIndex'))
       
        setTableLoading(false)
    }, [loading, customFinalList, defFil, magic_categoryType, magic_benchType, magicboardFilterType,selectedStyleBox])


    // React.useEffect(() => {
    //     if (tableType === 7) {
    //         //stylebox
    //         setDisplayData(allFundsData.filter((i) => Number(i.style_box_code) === Number(selectedStyleBox)))
    //     }
    // }, [selectedStyleBox])

    const portal = document.createElement("div");
    document.body.appendChild(portal);

    var chartKeys = bubbleChartType === 1 ? ['sh_qual_comp', 'sh_val_comp', "proper_name"] : bubbleChartType === 2 ? ['sh_mom_comp', 'sh_val_comp', "proper_name"] : ['sh_mom_comp', 'sh_qual_comp', "proper_name"]
    var chartAxisNames = bubbleChartType === 1 ? ['sharpely Value Score', 'sharpely Quality Score'] : bubbleChartType === 2 ? ['sharpely Value Score', 'sharpely Momentum Score'] : ['sharpely Quality Score', 'sharpely Momentum Score']

    const rowSelection = {
        selectedRowKeys: selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            if (selectedRowKeys.length > (tableType === 1 || tableType === 3 ? type.value === 4 ? 50 : 10 : 4)) {
                return
            }
            setSelectedRowKeys(selectedRowKeys);
            setSelectedRows(selectedRows);
            //// console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        },
        getCheckboxProps: (record) => ({
            disabled: record.name === 'Disabled User', // Column configuration not to be checked
        }),
        hideSelectAll: true,
    };

    const getChartFunds = (pageNum, pageSize) => {
        return [...customFinalList].slice(pageSize * (pageNum - 1), pageSize * pageNum)
    }

    const onOrderChange = (items) => {
        setDataTableColumns(items);
    };

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    function PortalAwareItem({ provided, snapshot, item, index }) {
        const usePortal = snapshot.isDragging;
        const child = (
            <div
                className="mb-1"
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
            >
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <MdDragIndicator size={25} color="var(--dark3)" />

                    <span className='textXS dark3 mx-3' style={{ flex: 1 }}>{item.title}</span>
                    {item.disableDeleteInCustomize ? <HiLockClosed className='textXS dark3' size={15} onClick={() => { }} /> :
                        <MdCancel className='textXS dark3' size={15} onClick={() => {
                            var a = [...dataTableColumns]
                            a.splice(index, 1)
                            setDataTableColumns(a)
                        }} />}
                </div>
            </div>
        );

        if (!usePortal) {
            return child;
        }

        // if dragging - put the item in a portal
        return ReactDOM.createPortal(child, portal);
    }

    function VerticalList({ items, onChange }) {
        const onDragEnd = (result) => {
            if (!result.destination) return;
            onChange(reorder(items, result.source.index, result.destination.index));
        };
        return (
            <div style={{ display: "flex", flexDirection: "column", gap: "1.25rem" }}>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="vertical-list">
                        {(droppableProvided, droppableSnapshot) => (
                            <div
                                ref={droppableProvided.innerRef}
                                {...droppableProvided.droppableProps}
                            >
                                {items.map((item, index) => {
                                    if (!item.disableDeleteInCustomize) {
                                        return <Draggable key={item.dataIndex} draggableId={item.dataIndex} index={index}>
                                            {(draggableProvided, draggableSnapshot) => (
                                                <PortalAwareItem
                                                    item={item}
                                                    index={index} //removing name and aum/mcap
                                                    provided={draggableProvided}
                                                    snapshot={draggableSnapshot}
                                                />
                                            )}
                                        </Draggable>
                                    } else {
                                        return null
                                    }
                                })}
                                {droppableProvided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div >
        );
    }

    const DrawerMetricDescComp = () => {
        return <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                <span className='textSM black w-700'>{selectedm?.metric_name}</span>
            </div>
            <ShowMoreText
                lines={10}
                more="Know more"
                less="Know less"
                className="textSM"
                anchorClass="show-more-less-clickable textSM"
                onClick={(e) => {
                    console.log(e)
                }}
                expanded={false}
                truncatedEndingComponent={"... "}
            >
                {selectedm?.metric_desc}
            </ShowMoreText>
        </div>
    }

    const AppliedFilterView = () => {
        var nonEditable = [...dataTableColumns].filter((i) => i.disableDeleteInCustomize)
        return <div style={{
            display: "flex", flex: 1, flexDirection: "column", gap: "1.2rem", marginBottom: "2rem"
        }}>
            <span>
                {nonEditable.length > 2 ?
                    <div className="textSM" style={{ color: 'var(--primary)', backgroundColor: 'var(--blueShade)', padding: "0.875rem 0.75rem", borderRadius: "4px", alignItems: "center", gap: "0.5rem" }}>
                        <MdOutlineLock className='textXS primary' size={15} onClick={() => { }} />{`${nonEditable[0]?.title}, ${nonEditable[1]?.title} and ${nonEditable.length - 2} others`}
                    </div> :
                    <div className="textSM" style={{ color: 'var(--primary)', backgroundColor: 'var(--blueShade)', padding: "0.875rem 0.75rem", borderRadius: "4px", alignItems: "center", gap: "0.5rem" }}>
                        <MdOutlineLock className='textXS primary' size={15} onClick={() => { }} />{`${nonEditable[0]?.title} and ${nonEditable[1]?.title}`}
                    </div>
                }
            </span>
            <div style={{ overflow: 'auto', maxHeight: '600px' }}>
                <VerticalList
                    items={dataTableColumns}
                    onChange={onOrderChange}
                />
            </div>
        </div >
    }

    const renderRow = ({ item, index }) => {
        return <span key={item.category_id} style={{
            padding: "0.75rem 1.25rem",
            borderRadius: "4px 0px 0px 4px",
            display: "flex",
            alignItems: "center",
            backgroundColor: item.category_id === selectedCategory ? "var(--blueShade)" : "transparent", cursor: "pointer"
        }}
            onClick={() => {
                setselectedCategory(item.category_id)
                setselectedMetric("")
            }}
        >
            <span style={{ flex: 1, color: item.category_id === selectedCategory ? "var(--primary)" : "var(--dark3)" }} className="textSM w-500">{item.category_name}</span>
            <IoIosArrowForward color={item.category_id === selectedCategory ? "var(--primary)" : "var(--dark3)"} />
        </span>
    };

    const RenderIntialFlatlist = ({ item, index }) => {
        return item?.sub_category.filter((ele) => ele.isVisibleNormally !== false).map((subCategory, subCategoryIndex) => {
            if (subCategory.isVisibleForCustomise) {
                return <div key={"asd" + subCategoryIndex} style={{ borderRadius: 2, marginBottom: "2rem" }}>
                    <span className="textSM black w-500"> {subCategory.sub_category_name}</span>
                    <span style={{ display: "flex", flexDirection: "column", gap: "0.5rem", marginTop: "0.625rem" }}>
                        {subCategory.metric.map((metric, metricIndex) => {
                            var upDatedFilters = [...dataTableColumns]
                            var presentIndex = upDatedFilters.findIndex((met) => met.dataIndex === metric.metric_col_code)
                            var isPresentIndex = presentIndex !== -1
                            var isDisabled = metric?.dataIndex === "proper_name" || metric?.dataIndex === "basic_name" || metric?.dataIndex === "mcap" || metric?.dataIndex === "aum"
                            if (metric.hidden !== true && metric.isVisibleNormally !== false) {
                                return <div key={metric.metric_col_code} style={{ cursor: isPresentIndex ? 'not-allowed' : "pointer", color: isDisabled ? "var(--dark2)" : "var(--dark3)", display: "flex", alignItems: "center", gap: "0.5rem" }} className='textXS'>
                                    <Checkbox
                                        disabled={isDisabled}
                                        checked={isPresentIndex === true}
                                        onChange={(e) => {
                                            e.preventDefault()
                                            if (!isPresentIndex) {
                                                upDatedFilters.push({ ...metric })
                                                setDataTableColumns([...upDatedFilters])
                                            } else {
                                                upDatedFilters.splice(presentIndex, 1)
                                                setDataTableColumns([...upDatedFilters])
                                            }

                                            setselectedMetric(metric.metric_col_code)
                                        }}
                                    />
                                    <span onClick={() => {
                                        setselectedMetric(metric.metric_col_code)
                                    }}>{metric.metric_name}</span>
                                </div>
                            } else {
                                return null
                            }
                        })}
                    </span>
                </div>
            } else {
                return null
            }
        })
    }

    const BubbleChart = () => {

        var options = {
            chart: {
                type: 'bubble',
                height: "100%",
                plotBorderWidth: 0,
                plotBorderBottomWidth: 0,
                plotBorderColor: "#ccd6eb"
            },

            legend: {
                enabled: false
            },

            title: {
                text: ''
            },

            exporting: {
                enabled: false
            },
            credits: {
                enabled: false
            },
            xAxis: {
                tickLength: 0,
                lineWidth: 0,
                labels: { enabled: false },
                gridLineWidth: 0.5,
                title: {
                    text: chartAxisNames[0]
                },
                accessibility: {
                    rangeDescription: 'Range: 0 to 100.'
                }
            },

            yAxis: {
                visible: false,
                tickLength: 0,
                labels: { enabled: false },
                gridLineWidth: 0.5,
                startOnTick: false,
                endOnTick: false,
                title: {
                    text: chartAxisNames[1]
                },
                maxPadding: 0.2,

                accessibility: {
                    rangeDescription: 'Range: 0 to 100.'
                }
            },

            tooltip: {
                useHTML: true,
                headerFormat: '<table>',
                pointFormat: '<tr><th colspan="2"><h6>{point.name}</h6></th></tr>' +
                    '<tr><th>sharpely Value Score:</th><td>{point.x}</td></tr>' +
                    '<tr><th>sharpely Quality Score:</th><td>{point.y}</td></tr>',
                footerFormat: '</table>',
                followPointer: true
            },

            plotOptions: {
                bubble: {
                    minSize: 50,
                    maxSize: 55
                }
            },

            series: [{
                data: changeObjKey(getChartFunds(pageData.page, pageData.pageSize), chartKeys, ["y", "x", "name"], "normal").map((item) => {
                    return {
                        ...item,
                        color: item.y < 30 && item.x < 30 ? "#ef4221" : item.y > 70 && item.x > 70 ? "#58975e" : "#FFB70077"
                    }
                }),
            }]
        };
        return <HighchartsReact highcharts={Highcharts} options={options} />
    }

    var selectedm = screenerMetric.filter((i) => i.metric_col_code === selectedMetric)[0]

    const MetricSelectView = ({ currentMetricData }) => {
        // console.log(currentMetricData)
        var checkAllData = Array.from(new Set(allFundsData.map(item => item[currentMetricData.metric_col_code])));
        checkAllData = checkAllData.map((i, index) => {
            var x = {}
            x["value"] = i
            x["label"] = i
            return x
        })
        checkAllData = checkAllData.filter((x) => x?.label !== null && x?.label !== "" && x?.label !== undefined)
        return <Dropdown
            className='textSM black'
            allowClear={false}
            suffixIcon={<MdArrowDropDown size={17} />}
            value={currentMetricData.value}
            style={{
                width: "150px",
                marginTop: 0
            }}
            placeholder={"Please select a " + currentMetricData.metric_name}
            onChange={(value) => {
                // onValueChange(value)
                if (currentMetricData?.metric_col_code === "benchmark") {
                    setmagic_benchType(value)
                } else {
                    setmagic_categoryType(value)
                }

                // setDefFil(defFilters?.push(["primary_category_name", magic_categoryType]))
            }}
            options={checkAllData}
        />
    }


    const [settingsMenu, setSettingsMenu] = React.useState(false)

    if (tableLoading || loading) {
        return <LoadingTableSkeleton />
    } else {
        return (
            <Card bodyStyle={{ position: "relative", padding: isCard ? '1.25rem' : '1.25rem 0' }}>
                <div style={{ display: "flex", flexDirection: "column", gap: "1.25rem" }}>
                    <div style={{ display: "flex", flexDirection: "row", gap: "1rem", alignItems: "center" }}>
                        {tableHeader && <CardHeader hideHeaderBlueLine={hideHeaderBlueLine} heading={tableHeader} />}
                        {tableType === 7 && <CardHeader heading={`${displayData.length} stocks under '${styleBoxes?.filter((box) => box.style_box_code === selectedStyleBox)[0]?.style_box_name}' StyleBox`} />}
                        <span>
                            {type.value !== 4 && showCategoryDropDown ? <Segmented
                                className='performanceChartYrSegmented'
                                style={{ backgroundColor: "transparent", border: "1px solid var(--grey3)" }}
                                options={sortOptions}
                                onChange={(value) => {
                                    setmagicboardFilterType(value)
                                }}
                                value={magicboardFilterType}
                            /> : <></>}
                        </span>
                        <span>
                            {type.value !== 4 && showCategoryDropDown ?
                                MetricSelectView({
                                    currentMetricData: {
                                        metric_name: magicboardFilterType,
                                        metric_col_code: magicboardFilterType === "benchmark" ? "benchmark" : "primary_category_name",
                                        value: magicboardFilterType === "benchmark" ? magic_benchType : magic_categoryType
                                    }
                                })
                                : <></>}
                        </span>


                        {tableType === 7 && <AntDropdown
                            placement="bottomLeft"
                            // overlayStyle={{ width: "15vw" }}
                            menu={{
                                items: styleBoxes?.map((box) => {
                                    return {
                                        key: String(box.style_box_code),
                                        onClick: () => {
                                            setselectedStyleBox(box.style_box_code)
                                        },
                                        icon: <StyleBoxIcons style_box_code={box.style_box_code - 1} />,
                                        label: <span key={box.style_box_code}>
                                            <span className='ms-2 textSM w-500 dark4'>{box.style_box_name}
                                                <span className='ms-2 textXS w-400 dark2'>{box.style_box_desc}</span></span>
                                        </span>
                                    }
                                }),
                                selectable: true,
                                defaultSelectedKeys: [String(selectedStyleBox)],
                            }}
                        >
                            <button className={"btnWhite"} onClick={(e) => { e.preventDefault() }}>
                                <CgAdd size={18} /> StyleBox
                            </button>
                        </AntDropdown>}

                        {!(tableType === 1 || tableType === 3 || hideCheckBox) && <button className="btnBlack" disabled={selectedRows.length < 2} onClick={() => navigate(type.basePath + '/compare', { state: { "stocks": selectedRows } })}>Compare</button>}

                        {(tableType === 1 || tableType === 3 || !hideCheckBox) && <button className="btnBlack" disabled={disableInvestBtn} onClick={tableType === 1 || tableType === 3 ? () => { investInScreener(selectedRows) } : () => setmodal(true)}>{tableType === 1 ? "Invest in screen" : tableType === 3 ? "Invest in signal" : "Create strategy"}</button>}

                        {(tableType === 1 || tableType === 3) && type.value === 4 && <button className={showBubbleChart ? "btnBlack" : " btnWhite"} onClick={() => setShowBubbleChart(!showBubbleChart)}>
                            <MdBubbleChart size={20} />
                        </button>
                        }

                        <Divider style={{ height: "1.5rem", margin: 0 }} type="vertical" />



                        <AntDropdown
                            overlayStyle={{ width: "15vw" }}
                            open={settingsMenu}
                            onOpenChange={(e) =>
                                setSettingsMenu(e)
                            }
                            menu={{
                                items: [
                                    {
                                        key: '11',
                                        label: <span className="textSM w-500 mt-2">
                                            Advance settings
                                        </span>,
                                    },
                                    {
                                        type: "divider"
                                    },
                                    {
                                        key: '1',
                                        icon: <BiCustomize />,
                                        onClick: () => {
                                            setSettingsMenu(false)
                                            setmodal(true)
                                        },
                                        label: <span className="textSM">
                                            Customise table columns
                                        </span>,
                                    },
                                    type.value === 4 && (tableType === 1 || tableType === 3) && {
                                        key: '2',
                                        icon: <BiFilter />,
                                        onClick: (e) => { setSettingsMenu(true) },
                                        label: <span className="textSM" style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
                                            Exclude flaged stocks
                                            <Switch checked={excludeSurveillance} onChange={(e) => {
                                                setexcludeSurveillance(e)
                                                onChangeExcludeStocksSwitch(e)
                                            }} />
                                        </span>,
                                    },
                                ],
                            }}
                        >
                            <button className={"btnWhite"} onClick={(e) => { e.preventDefault() }}>
                                <MdSettings size={18} />
                            </button>
                        </AntDropdown>
                    </div>

                    <Table
                        loading={tableLoading}
                        rowSelection={showBubbleChart || hideCheckBox ? false : rowSelection}
                        pagination={{
                            defaultPageSize: paginationSize,
                            position: ['bottomCenter'],
                            showSizeChanger: false,
                            showPrevNextJumpers: true,
                            className: "textSM",
                            hideOnSinglePage: true,
                            itemRender: (current, type, originalElement) => {
                                if (type === 'prev') {
                                    return <a {...originalElement.props} className="textSM mx-2">Previous</a>;
                                }
                                if (type === 'next') {
                                    return <a {...originalElement.props} className="textSM mx-2">Next</a>;
                                }
                                return originalElement;
                            }
                        }}
                        showSorterTooltip={false}
                        locale={{
                            emptyText: tableLoading ? <Skeleton active={true} /> : <Empty />
                        }}
                        dataSource={tableLoading ? [] : sortFunds(displayData, type.value === 4 ? "mcap" : "aum")}
                        columns={dataTableColumns.filter((i) => i.hidden !== true)}
                        rowKey={(record) => record.plan_id || record.symbol}
                        scroll={{ x: 'max-content' }}
                    />
                </div>

                <Drawer
                    width={"75vw"}
                    closable={false}
                    title={false}
                    onClose={() => {
                        setmodal(!modal)
                    }}
                    open={modal}
                    footerStyle={{}}
                    footer={false}>
                    <Card
                        bodyStyle={{ display: "flex", flex: 1, height: "100%", padding: 0, overflowY: "scroll" }} style={{ display: "flex", flexDirection: "column", flex: 1, height: "100%" }}
                        title={<span style={{ display: "flex", alignItems: "center" }}>
                            <span className='textLG w-500' style={{ flex: 1 }}>Select Metric</span>
                            <IoIosClose onClick={() => {
                                setmodal(!modal)
                            }}
                                size={"1.75rem"} style={{ cursor: "pointer" }} />
                        </span>}
                        bordered>
                        <Card bordered bodyStyle={{ display: "flex", flexDirection: "column", flex: 1, height: "100%", paddingRight: 0, overflowY: "scroll" }} style={{ display: "flex", flexDirection: "column", flex: 1.2, height: "100%", borderRadius: 0, borderWidth: 0.5 }}>
                            {screenerFinalData.map((item, index) => {
                                if (item.isVisibleNormally === true) {
                                    return renderRow({
                                        item, index
                                    })
                                } else {
                                    return <></>
                                }
                            }
                            )}
                        </Card>
                        <Card bordered bodyStyle={{ display: "flex", flexDirection: "column", flex: 1, height: "100%", overflowY: "scroll" }} style={{ display: "flex", flexDirection: "column", flex: 1.1, height: "100%", borderRadius: 0, borderWidth: 0.5 }}>
                            <RenderIntialFlatlist item={screenerFinalData.filter((i) => i.category_id === selectedCategory)[0]} />
                        </Card>
                        <Card bordered bodyStyle={{ display: "flex", flexDirection: "column", flex: 1, height: "100%", backgroundColor: "var(--grey4)", overflowY: "scroll" }} style={{ display: "flex", flexDirection: "column", flex: 2.7, height: "100%", borderRadius: 0, borderWidth: 0.5 }}>
                            <AppliedFilterView />
                            <DrawerMetricDescComp />
                        </Card>
                    </Card>
                </Drawer>

                <Drawer
                    width={"80vw"}
                    title={<span className='fontH1 black m-1 mb-5' style={{ fontWeight: "bold" }}>Bubble charts</span>} placement="right"
                    onClose={() => {
                        setShowBubbleChart(!showBubbleChart)
                    }}
                    open={showBubbleChart}
                    footerStyle={{}}
                    footer={false}>
                    <div style={{ display: "flex", flexDirection: "row", height: "calc(89vh)", gap: "0.2rem" }}>
                        <Col style={{ position: "relative", height: "100%", flex: 1 }}>
                            <Card bordered={false} style={{ padding: 0 }} bodyStyle={{ padding: 0 }}>
                                <CustomSegmentedTab
                                    options={[
                                        { "key": 1, label: "Quality v/s Value", value: 1 },
                                        { "key": 2, label: "Momentum v/s Value", value: 2 },
                                        { "key": 3, label: "Quality v/s Momentum", value: 3 },
                                    ]}
                                    isBlock={false}
                                    value={bubbleChartType}
                                    onChange={(e) => setbubbleChartType(e)}
                                />

                                <BubbleChart />
                            </Card>
                        </Col>
                        <Col sm={5} style={{ position: "relative", height: "100%", overflowY: "scroll" }}>
                            <Table
                                rowSelection={showBubbleChart ? false : rowSelection}
                                pagination={{
                                    defaultPageSize: paginationSize,
                                    position: ['bottomCenter'],
                                    showSizeChanger: false,
                                    showPrevNextJumpers: true,
                                    className: "textSM",
                                    hideOnSinglePage: true,
                                    itemRender: (current, type, originalElement) => {
                                        if (type === 'prev') {
                                            return <a {...originalElement.props} className="textSM mx-2">Previous</a>;
                                        }
                                        if (type === 'next') {
                                            return <a {...originalElement.props} className="textSM mx-2">Next</a>;
                                        }
                                        return originalElement;
                                    },
                                    onChange: (page, pageSize) => {
                                        setPageData({
                                            page: page,
                                            pageSize: pageSize
                                        })
                                    }
                                }}
                                defaultSortOrder='descend'
                                bordered={true}
                                size='small'
                                className='textXS black'
                                dataSource={customFinalList}
                                columns={unique([
                                    ...dataTableColumns,
                                    ...screenerMetric.filter((item) => chartKeys.includes(item.metric_col_code)),
                                ], 'dataIndex').filter((i) => i.hidden !== true && chartKeys.includes(i.dataIndex)).map((i) => {
                                    return {
                                        ...i,
                                        sorter: false
                                    }
                                })}
                                rowKey={(record) => record.plan_id || record.symbol}
                                scroll={{ x: "max-content", y: "70vh" }}
                            />
                        </Col>
                    </div>
                </Drawer>
            </Card>
        );
    }
};

export default InstrumentListTable;
