import AMCIcon from "./AMCIcon"
import { CurrencyFormat } from "./CurrencyFormat"

const TransactionCard = ({ data, key, type, total, name }) => {
    return (
        <div className='row'>
            <div className='col-md-12' style={{ margin: "16px 0px" }}>
                {/* <div className="card">
                    <div className="card-body" style={{ padding: "16px" }}> */}
                <div className='row'>
                    <div className='col-4'>
                        <div className='fontBoldDark13' style={{ textAlign: "left", alignItems: "flex-start", margin: "0 0 8px 0" }}>{data.date}</div>
                        {data.id > 0 ? <div style={{ display: "flex", alignItems: "center" }}>
                            <div style={{ alignItems: "center" }}>
                                <AMCIcon height="30px" width="30px" amcName={data.amc_full_name || data.AMCName} />
                            </div>
                            <div className='fontNormalDark13' style={{ marginLeft: "16px" }}>
                                {data.ETFName}
                            </div>
                        </div>
                            : null}
                    </div>
                    <div className='col-4' style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <div>
                            <div className='fontBoldDark13'>
                                {CurrencyFormat(Math.abs(data.total), "long")}
                            </div>
                            <div>
                                {data.GoalId > 0 ? null : <div className='fontNormalDark13'>{Math.abs(data.Units)} units</div>}
                            </div>
                        </div>
                    </div>
                    <div className='col-4' style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <div className='fontBoldDark13' style={{ textAlign: "left", alignItems: "flex-start", margin: "4px 0" }}>{data.type}</div>
                    </div>
                </div>
                {/* </div>
                </div> */}
            </div>
        </div>
    )
}

export default TransactionCard