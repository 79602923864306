import AlphaAnalysis from "Pages/AlphaAnalysis/AlphaAnalysis";
import BuildCustomPortfolio from "Pages/CustomPortfolio";
import Dashboard from "Pages/Dashboard";
import Compare from "Pages/DetailPage/Compare";
import FundDetail from "Pages/DetailPage/FundDetail";
import PortfolioDetail from "Pages/DetailPage/PortfolioDetails";
import StockDetail from "Pages/DetailPage/StockDetail";
import StockPortfolioDetail from "Pages/DetailPage/StockPortfolioDetails";
import FundList from "Pages/FundList";
import Home from "Pages/Home";
import CategoryPage from "Pages/KnowledgeBase/CategoryPage";
import KnowledgeBase from "Pages/KnowledgeBase/KnowledgeBase";
import Resource from "Pages/KnowledgeBase/Resource";
import SubCategoryPage from "Pages/KnowledgeBase/SubCategoryPage";
import Portfolio from "Pages/Portfolio/Portfolio";
import Screener from "Pages/Screener/Screener";
import StockSectors from "Pages/Sectors/StockSectors";
import StockSectorsDetail from "Pages/Sectors/StockSectorsDetails";
import Signal from "Pages/Signal/Signal";
import StockStyleBoxes from "Pages/StyleBox";
import Login from "Pages/UserPages/Login";
import Profile from "Pages/UserPages/Profile";
import Register from "Pages/UserPages/Register";
import { useLayoutEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { ProtectedRoutes } from "./ProtectedRoutes";


const Pages = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  const paths = ['/etfs', '/mutual-funds', '/stocks']

  const renderMultiRoutes = ({ element, path, ...rest }) =>
    paths.map((p) => <Route key={p + path} path={p + path} {...rest} element={element} />);


  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<Register />} />

      {renderMultiRoutes({
        path: '/dashboard',
        element: <Dashboard />,
      })}
      {renderMultiRoutes({
        path: '/list',
        element: <FundList />,
      })}
      {renderMultiRoutes({
        path: '/screener',
        element: <Screener />,
      })}
      {renderMultiRoutes({
        path: '/portfolio/:code',
        element: <PortfolioDetail />,
      })}
      {renderMultiRoutes({
        path: '/alpha-analysis',
        element: <AlphaAnalysis />,
      })}


      <Route path={'/stocks/stock-portfolio'} element={<StockPortfolioDetail />} />
      <Route path={'/etfs/fund-detail/*'} element={<FundDetail />} />
      <Route path={'/mutual-funds/fund-detail/*'} element={<FundDetail />} />
      <Route path={'/stocks/stock-detail/:code'} element={<StockDetail />} />
      <Route path={'/stocks/styleBox'} element={<StockStyleBoxes />} />
      <Route path={'/stocks/sectors'} element={<StockSectors />} />
      <Route path={'/stocks/sector-details'} element={<StockSectorsDetail />} />
      <Route path={'/stocks/signal'} element={<Signal />} />
      {renderMultiRoutes({
        path: '/compare',
        element: <Compare />,
      })}
      {renderMultiRoutes({
        path: '/build-custom-portfolio',
        element: <BuildCustomPortfolio />,
      })}


      {/* all protected routes where userdata is must */}
      <Route path={'/portfolio'} element={<ProtectedRoutes redirectRoute="/login"><Portfolio /></ProtectedRoutes>} />
      <Route path={'/user-profile'} element={<ProtectedRoutes redirectRoute="/login"><Profile /></ProtectedRoutes>} />

      {/* All routes related to knowledge base */}
      <Route path='/knowledge-base' element={<KnowledgeBase />} />
      <Route path='/knowledge-base/:category' element={<CategoryPage />} />
      <Route path='/knowledge-base/:category/:subCategory' element={<SubCategoryPage />} />
      <Route path='/knowledge-base/:category/:subCategory/:blog' element={<Resource />} />
      <Route path='/knowledge-base/:category/-/:blog' element={<Resource />} />
    </Routes>
  );
};
export default Pages;
