import { Divider } from 'antd';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useGetAssetType } from 'Libs/utils';
import React from 'react';
import { BsFillSquareFill } from 'react-icons/bs';
import { StockLivePrice } from 'Services/api/LivePricesWebSocket';
import { CurrencyFormat } from './CurrencyFormat';
import CustomSegmentedTab from './CustomSegmentedTab';
import { changeObjKey } from './DataCustomizer';
import RedGreenText from './RedGreenText';

const InstrumentPerfBenchChart = ({
    chartData = [],
    fundData = [],
    benchName = "",
    fundName = "",
    portfolioType = 0,
    goLiveDate = "",
    defYrSelect = "",
    defAmtSelect = "",
    showOnlySelectedKey = "",
    xAxisKey = "Date",
    showLiveTickerVal = false,
    fundColors = ['#83BF6E', '#007AFF', '#fabc05', '#24c1e0', '#fa7b16', '#b74447', '#333', 'purple', 'violet', 'red', 'darkgreen'],
    isStyleBox = false
}) => {
    // portfolioType 1 = curated , 2 = custom, 0 = for individual funds
    // portfolioType = -1 => for showing only one amount value 
    // console.log(fundData)
    fundName = fundName || fundData?.symbol || fundData?.nse_symbol || "Fund"
    benchName = benchName || fundData?.benchmark || fundData?.bench_name || "Benchmark"
    const type = useGetAssetType()
    // console.log(chartData)
    const yrtabs = chartData !== undefined ? portfolioType === 1 ? [
        { key: "A", label: "1Y", value: "365D", show: chartData["365D"] !== undefined ? JSON.parse(chartData?.["365D"]?.data).length > 0 : false },
        { key: "b", label: "3Y", value: "1095D", show: chartData["1095D"] !== undefined ? JSON.parse(chartData?.["1095D"]?.data).length !== JSON.parse(chartData?.["365D"]?.data).length : false },
        { key: "c", label: "5Y", value: "1825D", show: chartData["1825D"] !== undefined ? JSON.parse(chartData?.["1825D"]?.data).length !== JSON.parse(chartData?.["1095D"]?.data).length : false },
        { key: "d", label: "10Y", value: "3650D", show: chartData["3650D"] !== undefined ? JSON.parse(chartData?.["3650D"]?.data).length !== JSON.parse(chartData?.["1825D"]?.data).length : false },
        { key: "dm", label: "Live", value: "live", show: true },
        { key: "dmk", label: "Max", value: "max", show: chartData["max"] !== undefined ? JSON.parse(chartData?.["max"]?.data).length !== JSON.parse(chartData?.["3650D"]?.data).length : false },
    ] : [
        { key: "A1", label: "1m", value: "30D", show: chartData["30D"] !== undefined ? JSON.parse(chartData?.["30D"]?.data).length > 0 : false },
        { key: "A2", label: "6m", value: "182D", show: chartData["182D"] !== undefined ? JSON.parse(chartData?.["182D"]?.data).length > 0 : false },
        { key: "A3", label: "YTD", value: "ytd", show: chartData["ytd"] !== undefined ? JSON.parse(chartData?.["ytd"]?.data).length > 0 : false },
        { key: "A", label: "1Y", value: "365D", show: chartData["365D"] !== undefined ? JSON.parse(chartData?.["365D"]?.data).length > 0 : false },
        { key: "b", label: "3Y", value: "1095D", show: chartData["1095D"] !== undefined ? JSON.parse(chartData?.["1095D"]?.data).length !== JSON.parse(chartData?.["365D"]?.data).length : false },
        { key: "c", label: "5Y", value: "1825D", show: chartData["1825D"] !== undefined ? JSON.parse(chartData?.["1825D"]?.data).length !== JSON.parse(chartData?.["1095D"]?.data).length : false },
        { key: "d", label: "10Y", value: "3650D", show: chartData["3650D"] !== undefined ? JSON.parse(chartData?.["3650D"]?.data).length !== JSON.parse(chartData?.["1825D"]?.data).length : false },
        { key: "dm", label: "Max", value: "max", show: chartData["max"] !== undefined ? JSON.parse(chartData?.["max"]?.data).length !== JSON.parse(chartData?.["3650D"]?.data).length : false },
    ] : []

    const chipset = [
        { "key": "a", label: "₹ 5,000", value: 5000 },
        { "key": "b", label: "₹ 10,000", value: 10000 },
        { "key": "c", label: "₹ 50,000", value: 50000 },
    ]
    const [noChart, setnoChart] = React.useState(false);
    const [chartType, setChartType] = React.useState(portfolioType === 1 ? defYrSelect || yrtabs[4]?.value : defYrSelect || yrtabs[0]?.value)
    const [chartAmount, setChartAmount] = React.useState(defAmtSelect || chipset[1].value)

    var a = JSON.parse(chartData?.[chartType]?.data || null) !== null && JSON.parse(chartData?.[chartType]?.data || null) !== undefined ? showOnlySelectedKey !== "" ? Object.keys(JSON.parse(chartData?.[chartType]?.data || null)[0] || {})?.filter((i) => !i.includes("ret_") && i.toLowerCase() !== xAxisKey.toLowerCase()).filter((i) => String(i) === String(showOnlySelectedKey)) : Object.keys(JSON.parse(chartData?.[chartType]?.data || null)[0] || {})?.filter((i) => !i.includes("ret_") && i.toLowerCase() !== xAxisKey.toLowerCase()) : ["Fund", "Benchmark"]

    // console.log(a)

    try {
        var options = {
            chart: {
                backgroundColor: "transparent",
            },
            title: null,
            exporting: {
                enabled: false
            },
            yAxis: {
                visible: false,
                tickAmount: 5,
                gridLineDashStyle: 'longdash',
                title: null,
                tickColor: 'transparent',
                gridLineColor: 'var(--border)',
            },
            xAxis: [{
                tickPixelInterval: 110,
                title: null,
                type: 'datetime',
                dateTimeLabelFormats: {
                    millisecond: '%H:%M:%S.%L',
                    second: '%H:%M:%S',
                    minute: '%H:%M',
                    hour: '%H:%M',
                    day: '%eth %b',
                    week: '%eth %b',
                    month: '%b \'%y',
                    year: '%Y'
                },
                lineColor: 'var(--border)',
                tickColor: 'transparent',
                plotLines: portfolioType === 1 && chartType.name !== "Live" ? [{
                    color: '#000', // Red
                    value: goLiveDate, // Position, you'll have to translate this to the values on your x axis
                    dashStyle: 'dash',
                    width: 1,
                    label: {
                        text: "Inception", rotation: 0, x: 5,
                        style: {
                            color: 'var(--secondary)',
                            fontWeight: 'bold'
                        }
                    }
                }] : []
            }
            ],
            legend: {
                enabled: false
            },
            plotOptions: {
                areaspline: {
                    threshold: null
                },
                series: {
                    label: {
                        connectorAllowed: false
                    },
                    marker: {
                        enabled: false
                    }
                }
            },
            tooltip: {
                shared: true,
                valueDecimals: 0,
                valuePrefix: '₹',
            },
            credits: {
                enabled: false
            },
            boost: {
                useGPUTranslations: true,
            },
            series: a.map((item, index) => {
                return {
                    name: index === 0 ? (fundName || item) : (benchName || item),
                    turboThreshold: 9e9,
                    color: fundColors[index],
                    tooltip: {
                        valueSuffix: ' ({point.ret_' + item + '}%)',
                    },
                    type: isStyleBox ? "spline" : "areaspline",
                    fillColor: {
                        linearGradient: {
                            x1: 0,
                            x2: 0,
                            y1: 0,
                            y2: 1
                        },
                        stops: [
                            [0, fundColors[index] + "77"],
                            [0.1, fundColors[index] + "11"]
                        ]
                    },
                    data: changeObjKey(chartData?.[chartType], [String(item), xAxisKey], ["y", "x"], "mfetfChart", (chartAmount / 10000))
                }
            }),
            scrollbar: {
                enabled: false
            },
        };
    } catch (error) {
        setnoChart(true)
    }


    const ContentBeforeChart = () => {
        try {
            if (chartType) {
                var mintData = JSON.parse(chartData?.[chartType]?.data)
            }
            return <div style={{ gap: '1rem', flexWrap: 'wrap', alignItems: "center" }}>

                <span style={{ flex: 1, display: "flex", flexDirection: "row", marginBottom: "1rem" }}>
                    <div style={{ flex: 1, display: "flex", flexDirection: "column", gap: "1.25rem" }}>
                        {showLiveTickerVal && <span className='textMD w-700' style={{ flex: 1, color: 'var(--black)', display: "flex", flexDirection: "column", gap: "0.5rem" }}>
                            <span>{fundData?.proper_name || fundData?.basic_name} <i style={{ marginLeft: "0.5rem" }} className="fi fi-rr-arrow-up-right-from-square"></i></span>
                            {type.value !== 2 ? <StockLivePrice format={5} symbol={fundData?.symbol || fundData?.nse_symbol} /> : <span className='textMD dark3'>NAV: {CurrencyFormat(fundData?.latest_nav)}</span>}
                        </span>}
                        <div style={{ display: 'flex', flexWrap: 'wrap', gap: "0.5rem" }}>
                            {
                                a.map((item, index) => {
                                    var val = (mintData[mintData?.length - 1][item] * (chartAmount / 10000)).toFixed(2)
                                    var valXIRR = (mintData[mintData?.length - 1]["ret_" + item])?.toFixed(2)
                                    return val && val !== 'NaN' ?
                                        <div key={index} style={{
                                            display: 'flex', gap: '0.375rem', flexDirection: "column",
                                        }}>
                                            {/* {isStyleBox ? <span><span className='textXS' style={{ color: 'var(--black)', display: "flex", alignItems: "center", gap: "0.5rem" }}>
                                        <StyleBoxIcons style_box_code={index} />{item === "Benchmark" ? benchName || item : fundName || item}
                                    </span>
                                        <div className='textXS' style={{ flex: 1, marginLeft: "1.25rem" }}>
                                            {CurrencyFormat(val, 'long', 0)}<span className='textXS'>{RedGreenText({ text: ` (${valXIRR}%)`, value: valXIRR })}</span>
                                        </div>
                                    </span> : */}
                                            <span>
                                                <span className='textXS w-700' style={{ color: 'var(--black)', display: "flex", alignItems: "center", gap: "0.5rem" }}>
                                                    <BsFillSquareFill color={fundColors[index]} size={"0.5rem"} />
                                                    {index === 0 ? (fundName || item) : (benchName || item)}
                                                </span>
                                                <span className='textXS' style={{ flex: 1, padding: "0rem 1rem" }}>
                                                    {CurrencyFormat(val, 'long', 0)}<span className='textXS'>{RedGreenText({ text: ` (${valXIRR}%)`, value: valXIRR })}</span>
                                                </span>
                                            </span>
                                            {/* } */}


                                        </div > : <></>
                                })
                            }
                        </div>
                    </div>
                    <span>
                        <Divider style={{ margin: 0, marginBottom: "0.5rem" }} />
                        <CustomSegmentedTab
                            isBlock={false}
                            className='performanceChartYrSegmented'
                            style={{ backgroundColor: "transparent", padding: "0rem 0.5rem" }}
                            options={yrtabs}
                            value={chartType}
                            onChange={(e) => setChartType(e)}
                        />
                        <Divider style={{ margin: 0, marginTop: "0.5rem" }} />
                    </span>
                </span>





                {/* {
                    portfolioType !== -1 ?
                        <div style={{ flex: 1 }}>
                            <div style={{ display: 'flex', justifyContent: 'right' }} className="mt-2 mt-lg-0">
                                <Segmented size='small' options={chipset} value={chartAmount} onChange={setChartAmount} />
                            </div>
                            {yrtabs.filter((i) => i.show !== false).length > 0 ?
                                <div style={{ display: 'flex', justifyContent: 'right' }} className="mt-2">
                                    <Segmented size='small' options={yrtabs.filter((i) => i.show !== false)} value={chartType} onChange={setChartType} />
                                </div> : <></>}
                        </div> : <></>
                } */}
            </div>
        } catch (error) {
            // console.log(error)
            return <></>
        }
    };



    const ContentAfterChart = () => {
        return <div>
            {
                portfolioType === 1 || portfolioType === 2 ?
                    <>
                        <div className='textXS' style={{ paddingTop: "1rem" }}>
                            <b>Notes and Disclaimer:</b>
                            <ol style={{ marginBottom: 0, paddingLeft: '16px' }}>
                                <li>Past performance is not an assurance of future performance.</li>
                                <li>Back-tested calculations do take into account an estimate of potential transaction cost incurred. Calculations do not take into account taxation.</li>
                                <li>Live performance is based on actual portfolio performance since it went live.</li>
                            </ol>
                        </div>
                    </>
                    :
                    <>
                        <div className='textXS' style={{ paddingTop: "1rem", whiteSpace: 'pre-wrap' }}>
                            <b>Note: </b>Past performance is not an assurance of future performance. The chart has been shown based on EOD {type.value === 4 ? "prices" : "NAVs"} of the {type.dname}.
                        </div>
                    </>
            }
        </div>
    }


    try {
        return (
            noChart ? <div className='textXS' style={{ margin: "4rem", textAlign: "center" }}>
                No chart data available!
            </div>
                : <span style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem', }}>
                    <ContentBeforeChart />
                    <HighchartsReact containerProps={{ style: { height: "20.8rem" } }} highcharts={Highcharts} options={options} />
                    {/* <ContentAfterChart /> */}
                </span>
        );
    } catch (error) {
        console.log(error)
        return <div className='textXS' style={{ margin: "4%", textAlign: "center" }}>
            No chart data available!
        </div>
    }
};
export default InstrumentPerfBenchChart;