import { formatDate } from "./DateFormatter";
import moment from 'moment';
import { DecimalValueFormat } from "./DecimalValueFormat";

function dropdownCustom(data, keys) {
  for (const [key, value] of Object.entries(data)) {
    value.id = value[keys[0]] - 1; // Assign new key
    value.name = value[keys[1]]; // Assign new key
  }

  return (data);
}

var aa = 0

function addMonths(date, months) {
  date.setMonth(date.getMonth() + months);
  return date;
}

const arr = x => Array.from(x);
const num = x => Number(x) || 0;
const str = x => String(x);
const isEmpty = xs => xs.length === 0;
const take = n => xs => xs.slice(0, n);
const drop = n => xs => xs.slice(n);
const reverse = xs => xs.slice(0).reverse();
const comp = f => g => x => f(g(x));
const not = x => !x;
const chunk = n => xs =>
  isEmpty(xs) ? [] : [take(n)(xs), ...chunk(n)(drop(n)(xs))];

// numToWords :: (Number a, String a) => a -> String
let numToWords = n => {

  let a = [
    '', 'one', 'two', 'three', 'four',
    'five', 'six', 'seven', 'eight', 'nine',
    'ten', 'eleven', 'twelve', 'thirteen', 'fourteen',
    'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'
  ];

  let b = [
    '', '', 'twenty', 'thirty', 'forty',
    'fifty', 'sixty', 'seventy', 'eighty', 'ninety'
  ];

  let g = [
    '', 'thousand', 'million', 'billion', 'trillion', 'quadrillion',
    'quintillion', 'sextillion', 'septillion', 'octillion', 'nonillion'
  ];

  // this part is really nasty still
  // it might edit this again later to show how Monoids could fix this up
  let makeGroup = ([ones, tens, huns]) => {
    return [
      num(huns) === 0 ? '' : a[huns] + ' hundred ',
      num(ones) === 0 ? b[tens] : b[tens] && b[tens] + '-' || '',
      a[tens + ones] || a[ones]
    ].join('');
  };

  let thousand = (group, i) => group === '' ? group : `${group} ${g[i]}`;

  if (typeof n === 'number')
    return numToWords(String(n));
  else if (n === '0')
    return 'zero';
  else
    return comp(chunk(3))(reverse)(arr(n))
      .map(makeGroup)
      .map(thousand)
      .filter(comp(not)(isEmpty))
      .reverse()
      .join(' ');
};


function changeObjKey(dataf, keys, newKeys, type, ty) {
  try {
    if (dataf) {
      if (type === "type") {
        var data = [...dataf]

        for (const [key, value] of Object.entries(data)) {
          value[newKeys[0]] = value[keys[0]]; // Assign new key
          value[newKeys[1]] = new Date(value[keys[1]].toString().replace("T00:00:00Z", "")).getTime(); // Assign new key
          value["name"] = "Date: " + formatDate(value[keys[1]].toString().replace("T00:00:00Z", ""))
        }
        //console.log(data[0])
        return data;

      } if (type === "normal") {
        var data = typeof (dataf) === "string" ? JSON.parse(dataf) : JSON.parse(JSON.stringify(dataf))
        ////console.log(data)
        for (const [key, value] of Object.entries(data)) {
          for (var i = 0; i < keys.length; i++) {
            value[newKeys[i]] = typeof (value[keys[i]]) === "number" ? Number(DecimalValueFormat({ num: value[keys[i]], decimals: 2 })) : value[keys[i]];
          }
          // value[newKeys[0]] = value[keys[0]]; // Assign new key
          // value[newKeys[1]] = value[keys[1]]; // Assign new key
        }
        return data;

      } else if (type === "normalanddelold") {
        var data = typeof (dataf) === "string" ? JSON.parse(dataf) : JSON.parse(JSON.stringify(dataf))
        ////console.log(data)
        for (const [key, value] of Object.entries(data)) {
          for (var i = 0; i < keys.length; i++) {
            value[newKeys[i]] = typeof (value[keys[i]]) === "number" ? Number(DecimalValueFormat({ num: value[keys[i]], decimals: 2 })) : value[keys[i]];
          }
          // value[newKeys[0]] = value[keys[0]]; // Assign new key
          // value[newKeys[1]] = value[keys[1]]; // Assign new key
        }
        return removeUnwantedKeyValAdmin(data, newKeys);

      } else if (type === "mfetfChart") {


        var newArr = JSON.parse(dataf.data)
// console.log(newArr)

        for (const [key, value] of Object.entries(newArr)) {
          var a = String(value[keys[1]]).replace("T00:00:00.000Z", "").split('-').join('/')
          var dn = moment(a, 'YYYY/MM/DD')

          value[newKeys[0]] = Number(value[keys[0]]).toFixed(2) * ty; // Assign new key
          value[newKeys[1]] = new Date(dn).getTime(); // Assign new key
          value["name"] = "Date: " + formatDate(value[keys[1]].toString().replace(" 00:00:00", ""))
        }

        return newArr;
      } else if (type === "stockLiveDaily") {



        var newArr = dataf

        for (const [key, value] of Object.entries(newArr)) {
          // var a = String(value[keys[1]]).replace("T00:00:00.000Z", "").split('-').join('/')
          // var dn = moment(value[keys[1]])

          value[newKeys[0]] = Number(value[keys[0]]).toFixed(2) / ty; // Assign new key
          value[newKeys[1]] = value[keys[1]] * 1000; // Assign new key
          value["name"] = "Date: " + formatDate(value[keys[1]] * 1000)
        }

        return newArr;
      } else if (type === "stockhistchart") {


        var newArr = JSON.parse(dataf)


        for (const [key, value] of Object.entries(newArr)) {
          var a = String(value[keys[1]]).replace("T00:00:00.000Z", "").split('-').join('/')
          var dn = moment(a, 'YYYY/MM/DD')

          value[newKeys[0]] = Number(value[keys[0]]).toFixed(2) * ty; // Assign new key
          value[newKeys[1]] = new Date(dn).getTime(); // Assign new key
          value["name"] = "Date: " + formatDate(value[keys[1]].toString().replace(" 00:00:00", ""))
        }

        return newArr;
      } else if (type === "rollingTearchart") {
        var newArr = dataf


        for (const [key, value] of Object.entries(newArr)) {
          var a = String(value[keys[1]]).split('-').join('/')
          var dn = moment(a, 'YYYY/MM/DD')

          value[newKeys[0]] = Number(value[keys[0]]).toFixed(2) * 1
          value[newKeys[1]] = new Date(dn).getTime(); // Assign new key
          value["name"] = "Date: " + formatDate(value[keys[1]].toString().replace(" 00:00:00", ""))
        }
        return newArr;
      } else {
        var newArr = JSON.parse(dataf.data)

        for (const [key, value] of Object.entries(newArr)) {
          var a = String(value[keys[1]]).replace(" 00:00:00", "").split('-').join('/')
          var dn = moment(a, 'DD/MM/YYYY')

          value[newKeys[0]] = Number(value[keys[0]]).toFixed(2) * ty; // Assign new key
          value[newKeys[1]] = new Date(dn).getTime(); // Assign new key
          value["name"] = "Date: " + formatDate(value[keys[1]].toString().replace(" 00:00:00", ""))
        }
        return newArr;
      }
    }
    else return []
  } catch (error) {
    console.log(error)
    return []
  }


}

function changeObjKeyAdmin(dataf, keys, newKeys) {
  var data = JSON.parse(JSON.stringify(dataf))
  for (const [key, value] of Object.entries(data)) {
    keys.map((a, i) => {
      if (value[keys[i]] !== undefined) {
        value[newKeys[i]] = value[keys[i]];
      }
      // Assign new key
    })
  }
  return data;
}

function removeUnwantedKeyValAdmin(dataf, keys) {
  var data = JSON.parse(JSON.stringify(dataf))
  data.forEach(function (v) {
    var keysF = Object.keys(v)
    keysF.map((key) => {
      if (!keys.includes(key)) {
        delete v[key]
      }
    })
  });
  return data;
}
export { dropdownCustom, changeObjKey, addMonths, numToWords, changeObjKeyAdmin, removeUnwantedKeyValAdmin };

