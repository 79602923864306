import { CaretRightOutlined } from '@ant-design/icons';
import { Button, Card, Collapse, Drawer, Popover, Progress, Segmented, Table, Tabs, Tag, Tooltip } from "antd";
import ActivityLoaderMintbox from "Components/ActivityLoaderMintbox.js";
import addToRecentlyViewed from "Components/addToRecentlyViewed.js";
import AMCIcon from "Components/AMCIcon";
import { CurrencyFormat } from "Components/CurrencyFormat.js";
import CustomListTable from "Components/CustomListTable";
import CustomSharpelyScoreProgressBar from 'Components/CustomSharpelyScoreProgressBar';
import { formatDate } from 'Components/DateFormatter';
import { DecimalValueFormat } from 'Components/DecimalValueFormat';
import ErrorComponent from "Components/ErrorComponent";
import { InsightsModal } from 'Components/Insights';
import InvestmentCard from 'Components/InvestmentCard';
import ordinal_suffix_of from "Components/Ordinal-suffix";
import PerformanceChart2 from "Components/PerformanceChart2";
import PerformanceChart3 from "Components/PerformanceChart3";
import PriceVolumeChart from 'Components/PriceVolumeChart';
import RedGreenText from 'Components/RedGreenText';
import { RiskFormat, RiskTextColor } from "Components/RiskFunctions";
import StyleBoxIcons from 'Components/StyleBoxIcons';
import Head from "Layout/Head";
import { displayValue, getUser, screenerMetricData, useGetAssetType } from "Libs/utils";
import moment from 'moment';
import React, { useRef, useState } from "react";
import { BsBookmark, BsFillBookmarkFill } from "react-icons/bs";
import { FaQuestionCircle } from "react-icons/fa";
import { useNavigate, useParams } from "react-router";
import { Col, Row } from "reactstrap";
import apiHelper from 'Services/api/ApiHelper';
import apiHelperPY from 'Services/api/ApiHelperPY';

const StockDetail = (props) => {
    const SYMBOL = useParams().code
    const type = useGetAssetType() // 1 = etf, 2 = mf
    const navigate = useNavigate()
    const [user, setUser] = useState()

    const [loading, setLoading] = useState(true);

    const [allFunds, setAllFunds] = useState([])
    const [fundData, setFundData] = useState({});
    const [stockPerformanceDetail, setStockPerformanceDetail] = useState({});
    const [historicalCharts, setHistoricalCharts] = useState({});
    const [financialStatements, setFinancialStatements] = useState({})
    const [allCheckLists, setAllCheckLists] = useState({});
    const [industries, setIndustries] = useState({});
    const [basicIndustries, setBasicIndustries] = useState({});
    const [sectors, setSectors] = useState({});
    const [macroSectors, setMacroSectors] = useState({});
    const [industriesCurrent, setIndustriesCurrent] = useState('');
    const [basicIndustriesCurrent, setBasicIndustriesCurrent] = useState('');
    const [sectorsCurrent, setSectorsCurrent] = useState('');
    const [macroSectorsCurrent, setMacroSectorsCurrent] = useState('');
    const [stockStyles, setStockStyles] = useState({});
    const [screenerMetric, setscreenerMetric] = useState([])
    const [styleBoxes, setStyleBoxes] = useState([])
    const [isWatchlisted, setIsWatchlisted] = useState(false);
    const [stockLiveData, setstockLiveData] = useState([])
    const [screenerFinalData, setscreenerFinalData] = useState([])
    const [stockSectorCLF, setStockSectorCLF] = useState([])
    const [allFilteredFundsData, setallFilteredFundsData] = useState([])
    const [allFundsData, setallFundsData] = useState([])
    const [isOpen, setisOpen] = useState(0)
    const [isV1Open, setisV1Open] = useState(-1)
    const [isV2Open, setisV2Open] = useState(-1)
    const [screener_final_query, set_screener_final_query] = useState([])
    const [newFiltersList, setnewFiltersList] = useState([])
    const [mfCategories, setmfCategories] = useState([])
    const [mfAMC, setmfAMC] = useState([])
    const [screenerMetricDescData, setScreenerMetricDescData] = useState([])
    const ref = useRef(null);
    const dataTableColumns = type.value === 4 ? [
        {
            "title": "Name",
            "dataIndex": "proper_name",
            render: (text, record, index) => <div style={{ display: 'flex', gap: '12px', cursor: "pointer" }} onClick={() => navigate({ pathname: type.basePath + `${type.value === 4 ? '/stock-detail/' + record.symbol : '/fund-detail/' + record?.basic_name?.replace(" - Direct Plan", "")?.replaceAll(' ', '-') + '?code=' + record.plan_id}` })}>
                <AMCIcon amcName={record.symbol} fundType={type.value} height='1.2rem' width="1.2rem" />
                <div>
                    <div className="textSM black">{record?.proper_name}</div>
                    <div className="text-left textXS ">{stockSectorCLF?.find(s => s.industry_code === record.industry_code)?.industry_desc}</div>
                </div>
            </div>,
            width: 230,
            fixed: 'left',
            show: true
        }
    ]
        :
        [
            {
                "title": "Name",
                "dataIndex": "basic_name",
                render: (text, record, index) => <div style={{ display: 'flex', gap: '12px', cursor: "pointer" }} onClick={() => navigate({ pathname: type.basePath + `${type.value === 4 ? '/stock-detail/' + record.symbol : '/fund-detail/' + record?.basic_name?.replace(" - Direct Plan", "")?.replaceAll(' ', '-') + '?code=' + record.plan_id}` })}>
                    <AMCIcon amcName={record.amc_full_name} height='1.2rem' width="1.2rem" />
                    <div>
                        <div className="textSM black">{record?.basic_name?.replace(" - Direct Plan", "")}</div>
                        <div className="text-left textXS ">{record?.category_name}</div>
                    </div>
                </div>,
                width: 230,
                fixed: 'left',
                show: true
            }
        ]

    const [apiError, setApiError] = useState(false)
    React.useEffect(() => {
        const dem = async () => {
            setLoading(true)
            var screener_datas = await screenerMetricData(type)

            console.log(screener_datas)
            let a = screener_datas?.allFunds
            setAllFunds(a)
            const result = a.filter(x => x.symbol === SYMBOL);
            setFundData(result[0])
            console.log('funddata', result[0])

            setisOpen(screener_datas?.levelVals?.level1)
            setisV1Open(screener_datas?.levelVals?.level2)
            setisV2Open(screener_datas?.levelVals?.level3)
            setStockSectorCLF(screener_datas?.fabricatedData?.stockSectorCLF)
            setStyleBoxes(screener_datas?.fabricatedData?.stockStyles)
            setMacroSectors(screener_datas?.fabricatedData?.macroSectors)
            setMacroSectorsCurrent(screener_datas?.fabricatedData?.macroSectors.find(i => i.value === result[0].macro_sec_code)?.label)
            setSectors(screener_datas?.fabricatedData?.sectors)
            setSectorsCurrent(screener_datas?.fabricatedData?.sectors.find(i => i.value === result[0].sector_code)?.label)
            setIndustries(screener_datas?.fabricatedData?.industries)
            setIndustriesCurrent(screener_datas?.fabricatedData?.industries.find(i => i.value === result[0].industry_code)?.label)
            setBasicIndustries(screener_datas?.fabricatedData?.basicIndustries)
            setBasicIndustriesCurrent(screener_datas?.fabricatedData?.basicIndustries.find(i => i.value === result[0].nse_basic_ind_code)?.label)
            setmfCategories(screener_datas?.fabricatedData?.mf_categories)
            setmfAMC(screener_datas?.fabricatedData?.mf_amcs)
            setscreenerMetric(screener_datas?.screenerMetricData)
            setscreenerFinalData(screener_datas?.screenerFinalData)
            setallFundsData(screener_datas?.allFunds)
            setallFilteredFundsData(screener_datas?.allFunds)
            setScreenerMetricDescData(screener_datas?.screenerMetricDescData)

            await getUser().then(async (u) => {
                if (u) {
                    setUser(u)
                    const watchlist = await apiHelper({ apiName: 'getWatchList', data: { id: u.id }, saveResponse: 'sessionStorage' })
                    if (watchlist) {
                        setIsWatchlisted(watchlist.some(f => f.FundType === type.value && f.FundId === result[0].isin_code))
                    }
                    console.log(watchlist)
                }
            })
            if (Object.keys(result[0])?.length) {
                addToRecentlyViewed({
                    type: type.value,
                    ID: result[0].plan_id,
                    Name: result[0].basic_name,
                    CAGR: result[0].cagr_5y,
                    Category: result[0].category_name,
                    AMCName: result[0].amc_full_name,
                    MinInv: result[0].min_initial_investment
                })
            }

            let res0 = await apiHelperPY({ apiName: 'getStockPerformanceDetail', data: { symbol: SYMBOL }, saveResponse: '' })
            if (res0) {
                res0 = await JSON.parse(res0.replace(/NaN/g, null))
                console.log("getStockPerformanceDetail", JSON.parse(res0.stock_insight))
                setStockPerformanceDetail(res0)
            }
            else {
                // setApiError(true)
                setStockPerformanceDetail({})
            }
            let resa = await apiHelper({ apiName: 'getFundOneDayPrices', data: { id: SYMBOL }, saveResponse: '' })
            if (resa) {
                resa = await JSON.parse(resa.replace(/NaN/g, null))
                console.log('getFundOneDayPrices', resa)
                setstockLiveData(resa)
            }
            else {
                // setApiError(true)
                setstockLiveData({})
            }
            let res1 = await apiHelperPY({ apiName: 'getHistoricalCharts', data: { symbol: SYMBOL }, saveResponse: '' })
            if (res1) {
                res1 = await JSON.parse(res1.replace(/NaN/g, null))
                console.log("getHistoricalCharts", res1)
                setHistoricalCharts(res1)
            }
            else {
                // setApiError(true)
                setHistoricalCharts({})
            }
            let res2 = await apiHelperPY({ apiName: 'getAllCheckLists', data: { symbol: SYMBOL }, saveResponse: '' })
            if (res2) {
                res2 = await JSON.parse(res2.replace(/NaN/g, null))
                console.log("getAllCheckLists", res2)
                setAllCheckLists(res2)
            }
            else {
                // setApiError(true)
                setAllCheckLists({})
            }
            let res3 = await apiHelperPY({ apiName: 'getFinancialStatements', data: { symbol: SYMBOL, fsym_id: result?.[0]?.fs_regional_id, industry_code: result[0]?.industry_code }, saveResponse: '' })
            if (res3) {
                res3 = await JSON.parse(res3.replace(/NaN/g, null))
                console.log("getFinancialStatements", res3)
                setFinancialStatements(res3)
            }
            else {
                // setApiError(true)
                setFinancialStatements({})
            }

            setLoading(false)
        }
        dem();
    }, [SYMBOL])

    // const scroll = () => {
    //     // ref.current.scrollLeft -= 100;
    //     const ele = document.querySelector('#table-you-want .ant-table-container thead tr');
    //     console.log('left', ele)
    //     // ele.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' })
    //     ele.scrollLeft += 20
    // };

    const updateWatchlist = async () => {
        if (user) {
            const data = {
                "clientId": user.id,
                "fundType": type.value,
                "fundCode": type.value === 4 ? fundData.nse_symbol : fundData.isin_code,
                "fundId": fundData.isin_code + "",
                "isActive": isWatchlisted ? 0 : 1
            }
            await apiHelper({ apiName: 'updateWatchList', data: data })
            await apiHelper({ apiName: 'getWatchList', data: { id: user.id }, saveResponse: 'sessionStorage', getFresh: true })
            setIsWatchlisted(!isWatchlisted)
        } else {
            navigate('/auth-login')
        }
    }

    const CustomSmallProgressBar = ({ val, allStocks, metricObj, tooltipContent }) => {
        console.log(metricObj)
        var max, min;
        if (metricObj.direction_flag === 'bot') {
            min = allStocks[0][metricObj.metric_col_code]
            max = allStocks[allStocks.length - 1][metricObj.metric_col_code]
        } else {
            min = allStocks[allStocks.length - 1][metricObj.metric_col_code]
            max = allStocks[0][metricObj.metric_col_code]
        }

        let percentage = (val - min) * 100 / (max - min)
        // if (metricObj.direction_flag === 'bot') {
        //     percentage = 100 - percentage
        // }
        let color = 'var(--primary)' //yellow
        if (percentage < 33.33) {
            color = metricObj.direction === 'bot' ? 'var(--green)' : 'var(--red)'
        } else if (percentage > 66.66) {
            color = metricObj.direction === 'bot' ? 'var(--red)' : 'var(--green)'
        }
        return <Popover content={tooltipContent} title="" placement="top">
            <div className="custom-small-progressbar">
                <Progress percent={percentage} size="small" showInfo={false}
                    strokeColor={color}
                />
            </div>
        </Popover>
    }

    const CustomSmallProgressBar2 = ({ rank, total, metricObj, tooltipContent }) => {
        let percentage = 100 - (rank * 100 / total)
        if (total < 4) {
            percentage = 0
        }
        let color = 'var(--primary)' //yellow
        if (percentage < 33.33) {
            color = 'var(--red)'
        } else if (percentage > 66.66) {
            color = 'var(--green)'
        }
        return <Popover content={tooltipContent} title="" placement="top">
            <div className="custom-small-progressbar">
                <Progress percent={percentage} showInfo={false}
                    strokeColor={color} strokeWidth={10} strokeLinecap="butt" type="line"
                />
            </div>
        </Popover>
    }

    const ScoreBoardTablesView = ({ data }) => {
        var metricObj = {};
        const dataSource = data.map(k => {
            metricObj = screenerMetric?.find(m => m.metric_col_code === k)
            console.log(metricObj)
            const sortFunc = (a, b) => {
                if (metricObj.direction_flag === 'top') {
                    return b[k] - a[k]  // descending sort
                } else if (metricObj.direction_flag === 'bot') {
                    return a[k] - b[k]  // ascending sort
                } else return 0 //no sort
            }
            const allArray = allFunds.filter(f => f[k]).sort((a, b) => sortFunc(a, b))
            const sectorArray = allArray.filter(f => f.sector_code === fundData.sector_code)
            const industryGrpArray = allArray.filter(f => f.industry_code === fundData.industry_code)
            const industryArray = allArray.filter(f => f.nse_basic_ind_code === fundData.nse_basic_ind_code)
            console.log(k, industryArray, fundData[k], metricObj)
            // console.log(metricObj?.metric_col_code, allFundsNotNull.map(f => { return { name: f.proper_name, score: f[metricObj?.metric_col_code] } }))
            return {
                'key': k,
                'name': metricObj?.metric_name || '',
                'metricObj': metricObj,
                'value': fundData[k],
                'allArray': allArray,
                'sectorArray': sectorArray,
                'industryArray': industryArray,
                'industryGrpArray': industryGrpArray,
                'allRank': allArray.findIndex(s => s.proper_name === fundData.proper_name) + 1,
                'sectorRank': sectorArray.findIndex(s => s.proper_name === fundData.proper_name) + 1,
                'industry_grpRank': industryGrpArray.findIndex(s => s.proper_name === fundData.proper_name) + 1,
                'industryRank': industryArray.findIndex(s => s.proper_name === fundData.proper_name) + 1,
            }
        })
        const columns = [
            {
                title: 'Name',
                dataIndex: 'name',
                fixed: 'left',
                width: '10rem',
            },
            {
                title: 'Value',
                dataIndex: 'value',
                align: "right",
                width: '6.5rem',
                render: (text, record) => displayValue({ text, metricObj: record.metricObj })
            },
            {
                title: 'Market',
                dataIndex: 'all',
                align: "right",
                width: '7rem',
                // render: (text, record) => record.value ? <CustomSmallProgressBar
                //     val={record.value}
                //     allStocks={record.all}
                //     metricObj={record.metricObj}
                //     tooltipContent={<span className="textXS">{fundData.proper_name}'s<b> {record.metricObj.metric_name}</b> is ranked <b>{ordinal_suffix_of(record.all.findIndex(s => s.proper_name === fundData.proper_name) + 1)}</b> out of <b>{record.all.length}</b> stocks in the <b>Indian</b> market</span>}
                // /> : '-'
                render: (text, record) => record.value ? <CustomSmallProgressBar2
                    rank={record.allRank}
                    total={record.allArray.length}
                    metricObj={record.metricObj}
                    tooltipContent={<span className="textXS">{fundData.proper_name}'s<b> {record.metricObj.metric_name}</b> is ranked <b>{ordinal_suffix_of(record.allRank)}</b> out of <b>{record.allArray.length}</b> stocks in the <b>Indian</b> market</span>}
                /> : '-'
            },
            {
                title: 'Sector',
                dataIndex: 'in_sector',
                align: "right",
                width: '7rem',
                // render: (text, record) => record.value ? <CustomSmallProgressBar
                //     val={record.value}
                //     allStocks={record.sector}
                //     metricObj={record.metricObj}
                //     tooltipContent={<span className="textXS">{fundData.proper_name}'s<b> {record.metricObj.metric_name}</b> is ranked <b>{ordinal_suffix_of(record.sector.findIndex(s => s.proper_name === fundData.proper_name) + 1)}</b> out of <b>{record.sector.length}</b> stocks in the <b>{sectorsCurrent}</b> sector</span>}
                // /> : '-'
                render: (text, record) => record.value ? <CustomSmallProgressBar2
                    rank={record.sectorRank}
                    total={record.sectorArray.length}
                    metricObj={record.metricObj}
                    tooltipContent={<span className="textXS">{fundData.proper_name}'s<b> {record.metricObj.metric_name}</b> is ranked <b>{ordinal_suffix_of(record.sectorRank)}</b> out of <b>{record.sectorArray.length}</b> stocks in the <b>{sectorsCurrent}</b> sector</span>}
                /> : '-'
            },
            {
                title: 'Industry Group',
                dataIndex: 'in_ind_group',
                align: "right",
                width: '7rem',
                // render: (text, record) => record.value ? <CustomSmallProgressBar
                //     val={record.value}
                //     allStocks={record.industry_grp}
                //     metricObj={record.metricObj}
                //     tooltipContent={<span className="textXS">{fundData.proper_name}'s<b> {record.metricObj.metric_name}</b> is ranked <b>{ordinal_suffix_of(record.industry_grp.findIndex(s => s.proper_name === fundData.proper_name) + 1)}</b> out of <b>{record.industry_grp.length}</b> stocks in the <b>{basicIndustriesCurrent}</b> industry group</span>}
                // /> : '-'
                render: (text, record) => record.value ? <CustomSmallProgressBar2
                    rank={record.industry_grpRank}
                    total={record.industryGrpArray.length}
                    metricObj={record.metricObj}
                    tooltipContent={<span className="textXS">{fundData.proper_name}'s<b> {record.metricObj.metric_name}</b> is ranked <b>{ordinal_suffix_of(record.industry_grpRank)}</b> out of <b>{record.industryGrpArray.length}</b> stocks in the <b>{industriesCurrent}</b> industry group</span>}
                /> : '-'
            },
            {
                title: 'Industry',
                dataIndex: 'in_industry',
                align: "right",
                width: '7rem',
                // render: (text, record) => record.value ? <CustomSmallProgressBar
                //     val={record.value}
                //     allStocks={record.industry}
                //     metricObj={record.metricObj}
                //     tooltipContent={<span className="textXS">{fundData.proper_name}'s<b> {record.metricObj.metric_name}</b> is ranked <b>{ordinal_suffix_of(record.industry.findIndex(s => s.proper_name === fundData.proper_name) + 1)}</b> out of <b>{record.industry.length}</b> stocks in the <b>{industriesCurrent}</b> industry</span>}
                // /> : '-'
                render: (text, record) => record.value ? <CustomSmallProgressBar2
                    rank={record.industryRank}
                    total={record.industryArray.length}
                    metricObj={record.metricObj}
                    tooltipContent={<span className="textXS">{fundData.proper_name}'s<b> {record.metricObj.metric_name}</b> is ranked <b>{ordinal_suffix_of(record.industryRank)}</b> out of <b>{record.industryArray.length}</b> stocks in the <b>{basicIndustriesCurrent}</b> industry</span>}
                /> : '-'
            },
        ]
        return <Table dataSource={dataSource} columns={columns} size={'small'} bordered className='textXS black mt-3' pagination={false} />
    }

    const ChecklistView = ({ data }) => {
        const [open, setOpen] = React.useState(false)
        const passed = data?.checklist_val_data?.filter(f => f.pass)?.length;
        const total = data?.checklist_val_data?.length
        const dummyDescription = "Description Description Description Description Description Description Description Description Description Description Description Description Description Description Description Description Description Description Description Description Description Description Description Description Description Description Description Description Description Description Description Description Description Description Description Description Description Description Description Description Description Description Description Description Description Description Description Description Description Description Description Description Description Description Description Description Description"
        return <>
            <h2 className="fontH1 dark3">
                <b className="black">{data.vertical_col} <FaQuestionCircle size={'1.5rem'} onClick={() => setOpen(true)} style={{ marginLeft: '1rem', color: 'var(--primary)', verticalAlign: 'text-top' }} /></b>
            </h2>
            <Drawer title="" placement="right" onClose={() => setOpen(false)} open={open} width="30rem">
                <h2 className="fontH1 dark3" style={{ marginBottom: '2rem' }}>What is <b className="black">{data.vertical_col}</b>?</h2>
                <p className="textSM">{data.description || dummyDescription}</p>
                <p>Some contents...</p>
                <p>Some contents...</p>
                <p>Some contents...</p>
            </Drawer>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '2rem' }}>
                <div style={{ width: '16rem' }}>
                    <Progress percent={passed * 100 / total} strokeWidth={12} trailColor='var(--red)' strokeColor='var(--green)' format={(percent) => percent.toFixed(1) + '%'} />
                </div>
                <div className="fontH2"><b>{fundData.proper_name}</b> passes <b className="fontH1">{passed}</b> out of <b className="fontH1">{total}</b> of this checklist's rules</div>
            </div>
            <Collapse accordion
                bordered={false}
                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                className="checklist-custom-collapse"
            >
                {
                    data?.checklist_val_data.map(q => {
                        const metricObj = screenerMetric?.find(m => m.desc_id === q?.primary_col_details?.desc_id)

                        const val = q?.primary_col_details?.val;
                        return <Collapse.Panel header={
                            <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }} >
                                {
                                    q.pass ? <img style={{ height: '2rem', width: '2rem' }} src="https://d3etfstcuyoos5.cloudfront.net/app-images/imgs/like_icon.png" /> : <img style={{ height: '2rem', width: '2rem' }} src="https://d3etfstcuyoos5.cloudfront.net/app-images/imgs/dislike_icon.png" />
                                }
                                <div style={{ flex: 1 }}>{q.name}</div>
                            </div>} key={q.query_id} className="checklist-custom-panel">
                            <div>
                                <div className="fontH2"><b>Rule: </b>{q.name}</div>
                                <div className="fontH2 w-500"><b>{metricObj?.metric_name || 'Value'}: </b><span style={{ color: q.pass ? 'var(--green)' : 'var(--red)' }}>{displayValue({ text: val, metricObj: metricObj })}</span></div>
                                <div>
                                    {metricObj?.metric_desc}
                                </div>
                            </div>
                        </Collapse.Panel>
                    })
                }
            </Collapse>
        </>
    }

    const SmallCharts = ({ data, type }) => {
        console.log(data, type)
        return <div style={{ display: 'flex', gap: '1rem', flexWrap: 'wrap' }}>
            {
                data.map(chart => {
                    var x = Object.keys(JSON.parse(chart?.chart_data)[0])[0]

                    return <Card bordered={false} style={{ flex: 1, minWidth: '25rem', maxWidth: '25rem', marginBottom: '0.25rem' }} bodyStyle={{ padding: 0 }}>
                        <div className="fontH2 m-3">{chart.chart_name}</div>
                        <PerformanceChart3 fundName={chart.chart_name} chartData={chart.chart_data} xAxisKey={x} chartType={type} />
                    </Card>
                })
            }
        </div>

    }

    const InternalTabs = ({ items }) => {
        return <Card bordered={false} style={{ borderTop: 'none', borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
            <Tabs
                defaultActiveKey="a"
                size="small"
                tabBarGutter={0}
                tabPosition="left"
                className="internal-tabs"
                style={{ minHeight: '400px' }}
                items={items}
            />
        </Card>
    }
    const ScorecardModal = ({ opinionData, type }) => {
        const socreboardObj = [
            {
                label: `Stock scores`,
                keys: ['sh_val_comp', 'sh_mom_comp', 'sh_qual_comp', 'sh_gr_comp', 'sh_v_qual_comp', 'sh_qual_mom_comp', 'sh_gr_mom_comp', 'sh_3fac_comp',]
            },
            {
                label: `Value`,
                keys: ["pb", "pe_ttm", "ps_ttm", "ev_ebitda_ttm", "cape_5", "cape_3", "b_mcap", "earn_yield_ttm", "3y_roll_ey", "5y_roll_ey", "ebitda_yield_ttm", "ebit_yield_ttm", "ocf_yield", "fcfe_yield"]
            },
            {
                label: `Momentum`,
                keys: ["below_52_high", "above_52_low", "diff_200sma", "diff_50sma", "diff_200ema", "diff_50ema", "diff_200wma", "diff_50wma", "ret_1y_ex_1m", "one_mth_pct", "three_mth_pct", "six_mth_pct", "one_yr_pct"]
            },
            {
                label: `Quality`,
                keys: ["npm_margin_quat", "ebitda_margin_quat", "gross_mgn_quat", "roe_ttm", "roce_ttm", "roa_ttm", "npm_margin_ttm", "ebitda_margin_ttm", "gross_mgn_ttm", "asset_turn_latest", "avg_3_roe", "avg_3_roce", "avg_3_roa", "avg_3_npm", "avg_3_ebitda_margin", "avg_3_gross_margin", "avg_3_ocf_margin", "avg_3_fcf_margin", "cf_accrual_ratio", "ben_m_score", "div_yield", "div_1y_cagr", "div_3y_cagr", "div_5y_cagr"]
            },
            {
                label: `Growth`,
                keys: ["last_q_eps_growth", "earnings_1y_cagr", "earnings_3y_cagr", "earnings_5y_cagr", "last_q_ebitda_growth", "ebitda_1y_cagr", "ebitda_3y_cagr", "ebitda_5y_cagr", "sales_1y_cagr", "sales_3y_cagr", "sales_5y_cagr", "cfo_1y_cagr", "cfo_3y_cagr", "cfo_5y_cagr", "fcf_1y_cagr", "fcf_3y_cagr", "fcf_5y_cagr"]
            },
            {
                label: `Performance`,
                keys: ["three_yr_pct", "five_yr_pct", "ten_yr_pct", "alpha_1y", "alpha_3y", "alpha_5y", "sharpe_1y", "sharpe_3y", "sharpe_5y"]
            },
            {
                label: `Risk`,
                keys: ["beta_1y", "beta_3y", "beta_5y", "corr_1y", "corr_3y", "corr_5y", "volatility_1y", "volatility_3y", "volatility_5y"]
            },
            {
                label: `Financial health`,
                keys: ["current_ratio_latest", "quick_ratio_latest", "cash_ratio_latest", "ocf_ratio_latest", "int_cov_ratio_latest", "debt_ebitda_latest", "net_debt_ebitda_latest", "debt_equity_latest", "lt_debt_equity_latest", "debt_assets_latest"]
            },
        ]
        const [isOpen, setIsOpen] = useState(false);
        const [openedParamId, setOpenedParamId] = useState();

        const toggle = () => { setIsOpen(!isOpen) };

        return (
            <>
                <button block className="btnPrimary" style={{ color: "var(--primary)" }} onClick={toggle}>sharpely Scorecard</button>
                <Drawer title="Scorecard" placement="right" onClose={toggle} open={isOpen} width={'55rem'}
                    footerStyle={{ textAlign: 'right' }}
                    footer={<button className="btnPrimary ms-auto" onClick={toggle}>Close</button>}>
                    <Tabs className="textSM scoreboardTabs" tabBarStyle={{ fontSize: '10px' }} size="small" items={socreboardObj.map((s, i) => {
                        return {
                            label: s.label,
                            key: i,
                            children: <ScoreBoardTablesView data={s.keys} />,
                        }
                    })} />
                </Drawer>
            </>
        )
    }
    const StatementTable = ({ data, type = "an" }) => {
        const displayValue = ({ text, descObj }) => {
            if (String(descObj?.metric_data_type).toLowerCase() === "float") {
                if (String(descObj?.metric_unit).toLowerCase() === "rupee") {
                    return CurrencyFormat(Number(text), 'long', 2)
                } else if (String(descObj?.metric_unit).toLowerCase() === "rupee_cr") {
                    return DecimalValueFormat({ num: Number(text), suffix: "", placeholder: "-", decimals: 1 })
                } else if (String(descObj?.metric_unit).toLowerCase() === "percentage") {
                    console.log('kmdkkm')
                    return <RedGreenText text={DecimalValueFormat({ num: Number(text), suffix: "%", placeholder: "-", decimals: 1 })} value={Number(text)} />
                } else {
                    return DecimalValueFormat({ num: Number(text), suffix: "", placeholder: "-", decimals: 2 })
                }
            } else if (String(descObj?.metric_data_type).toLowerCase() === 'date') {
                return formatDate(Number(text))
            } else {
                return text
            }
        }
        if (!data) {
            return <Card bordered={false} bodyStyle={{ minHeight: '20rem', paddingTop: '5rem', textAlign: 'center' }}>
                <span className='fontH2'>Data not available</span></Card>
        }
        const years = Object.keys(JSON.parse(data?.data)?.[0])
        console.log(data, screenerMetricDescData)
        const dataSource = data?.desc_id?.map((k, i) => {
            const descObj = screenerMetricDescData?.find(m => m.desc_id === k)
            const x = JSON.parse(data?.data)?.[i];
            let displayX = {}
            Object.keys(x).forEach(y => {
                displayX[y] = displayValue({ text: x[y], descObj: descObj })
            })

            return {
                'key': k,
                'name': data?.cols?.[i] || '',
                'descObj': descObj,
                ...displayX
            }
        })
        const x = years?.map(y => {
            const d = new Date(y)
            const title = y === "TTM" ? "TTM" : type === "an" ? "FY" + moment(d).format("YY") : moment(d).format("MMM") + "'" + moment(d).format("YY")
            return {
                title: title,
                dataIndex: y,
                align: "right",
                width: '6rem',
                render: (text) => <span style={{ whiteSpace: 'nowrap' }}>{text}</span>
            }

        })
        const columns = [
            {
                title: 'Name',
                dataIndex: 'name',
                fixed: 'left',
                width: '10rem',
            },
            ...x
        ]
        const getRowClassName = (descObj) => {
            return descObj.color_code === 2 ? 'px-2 rowHighlightLevel2' : descObj.color_code === 1 ? 'px-2 rowHighlightLevel1' : 'px-2';
        }
        return <Table id='table-you-want' rowClassName={(record, index) => getRowClassName(record.descObj)} dataSource={dataSource} columns={columns} size={'small'} bordered className='textXS black mt-3' pagination={false} scroll={{ x: '100%', y: '25rem' }} />
    }

    const Statements = () => {
        const options1 = [
            {
                key: 'pnl',
                name: 'Profit and Loss'
            },
            {
                key: 'bs',
                name: 'Balance sheet'
            },
            {
                key: 'cf',
                name: 'Cash flow'
            },
        ]
        const options2 = [
            {
                key: 'an',
                name: 'Annual'
            },
            {
                key: 'qt',
                name: 'Quaterly'
            }
        ]

        const [statementType, setStatementType] = React.useState(options1[0])
        const [tenure, setTenure] = React.useState(options2[0])
        const scrollLeft = () => {
        };
        React.useEffect(() => {
            const x = document.getElementById('table-you-want')?.getElementsByClassName('ant-table-body')?.[0];
            if (x) {
                x.scrollLeft += 200000
            }
        }, [statementType, tenure])
        return <Card bordered={false} style={{ borderTop: 'none', borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '1rem', marginBottom: '0.5rem' }}>
                <div><Segmented style={{ border: '1px solid var(--border)' }} size='medium' value={statementType.name} options={options1.map(m => m.name)} onChange={(e) => setStatementType(options1.find(o => o.name === e))} /></div>

                <div className='fontH2'>Statement period:
                    {
                        statementType.key === 'pnl' ?
                            <Segmented style={{ border: '1px solid var(--border)', marginLeft: '1rem' }} size="small" value={tenure.name} options={options2.map(m => m.name)} onChange={(e) => setTenure(options2.find(o => o.name === e))} />
                            :
                            ' Annual'
                    }
                </div>
            </div>
            <span className='textXS'>(All values are in Rs. Crores unless specified otherwise)</span>
            <StatementTable data={financialStatements?.[`${statementType.key}_${statementType.key === 'pnl' ? tenure.key : 'an'}`]} type={statementType.key === 'pnl' ? tenure.key : 'an'} />
        </Card>
    }

    const DisplayCorporateActions = ({ x }) => {
        console.log('awdsd', x)
        const dataTableColumns = Object.keys(x?.[0] || {}).map((k, i) => {
            return {
                title: k,
                dataIndex: k,
                // align: i === 0 ? 'left' : 'right',
                // fixed: i === 0 ? 'left' : '',
                render: (text) => !text ? '-' : k.includes('Date') ? formatDate(text) : text
            }
        })
        return <Table bordered={true} size='small' className='textXS black insideTable' pagination={false}
            dataSource={x} columns={dataTableColumns} />
    }

    const tabData = loading ? [] : [
        {
            label: `Checklists`,
            key: '1',
            children: <InternalTabs items={Object.values(allCheckLists).map((c, i) => {
                return {
                    label: c.vertical_col.replace(' Checklist', ""),
                    key: i,
                    children: <ChecklistView data={c} />,
                }
            })
            } />,
            show: Object.values(allCheckLists).length
        },
        {
            label: `Statements`,
            key: '2',
            children: <Statements />,
            show: true
        },
        {
            label: `Peers`,
            key: '3',
            children: <InternalTabs items={[
                {
                    label: `Industry`,
                    key: 'a',
                    children: <>
                        <CustomListTable
                            finalList={allFilteredFundsData}
                            tableType={0}
                            defFilters={[
                                ["nse_basic_ind_code", fundData?.nse_basic_ind_code]
                            ]}
                            initialColumns={[
                                ...dataTableColumns,
                                ...screenerMetric.filter((item) => item.isInitial === true),
                            ]}
                            is_Open={isOpen}
                            is_V1Open={isV1Open}
                            is_V2Open={isV2Open}
                            tableHeader={<span className='fontH2'>{basicIndustriesCurrent} {type.dname}</span>}
                            screenerFinalData={screenerFinalData}
                        />
                    </>,
                },
                {
                    label: `Industry group`,
                    key: 'b',
                    children: <><CustomListTable
                        finalList={allFilteredFundsData}
                        tableType={0}
                        defFilters={[
                            ["industry_code", fundData?.industry_code]
                        ]}
                        initialColumns={[
                            ...dataTableColumns,
                            ...screenerMetric.filter((item) => item.isInitial === true),
                        ]}
                        is_Open={isOpen}
                        is_V1Open={isV1Open}
                        is_V2Open={isV2Open}
                        tableHeader={<span className='fontH2'>{industriesCurrent} {type.dname}</span>}
                        screenerFinalData={screenerFinalData}
                    /></>,
                },
                {
                    label: `Sector`,
                    key: 'c',
                    children: <><CustomListTable
                        finalList={allFilteredFundsData}
                        tableType={0}
                        defFilters={[
                            ["sector_code", fundData?.sector_code]
                        ]}
                        initialColumns={[
                            ...dataTableColumns,
                            ...screenerMetric.filter((item) => item.isInitial === true),
                        ]}
                        is_Open={isOpen}
                        is_V1Open={isV1Open}
                        is_V2Open={isV2Open}
                        tableHeader={<span className='fontH2'>{sectorsCurrent} {type.dname}</span>}
                        screenerFinalData={screenerFinalData}
                    /></>,
                },
                {
                    label: `Macro economic sector`,
                    key: 'd',
                    children: <><CustomListTable
                        finalList={allFilteredFundsData}
                        tableType={0}
                        defFilters={[
                            ["macro_sec_code", fundData?.macro_sec_code]
                        ]}
                        initialColumns={[
                            ...dataTableColumns,
                            ...screenerMetric.filter((item) => item.isInitial === true),
                        ]}
                        is_Open={isOpen}
                        is_V1Open={isV1Open}
                        is_V2Open={isV2Open}
                        tableHeader={<span className='fontH2'>{macroSectorsCurrent} {type.dname}</span>}
                        screenerFinalData={screenerFinalData}
                    /></>,
                }
            ]} />,
            show: true
        },
        {
            label: `Historical Graphs`,
            key: '4',
            // children: <InternalTabs items={Object.values(historicalCharts).map((c, i) => {
            //     const { vertical_col, ...newObj } = c;
            //     var insideItems = []
            //     const kids = Object.values(newObj)
            //     if (kids.length) {
            //         kids.forEach(t => {
            //             insideItems.push({
            //                 label: t.name,
            //                 key: "tab" + t.name,
            //                 disabled: true,
            //             })
            //             insideItems = insideItems.concat(t.charts.map(c => {
            //                 const x = Object.keys(JSON.parse(c?.chart_data)[0])[0]
            //                 return {
            //                     label: c.chart_name,
            //                     key: t.name + c.chart_name,
            //                     children: <PerformanceChart3 fundName={c.chart_name} chartData={c.chart_data} xAxisKey={x} />
            //                 }
            //             }))
            //         })
            //     }

            //     console.log(insideItems)
            //     return {
            //         label: vertical_col,
            //         key: i,
            //         children: <Tabs
            //             defaultActiveKey={insideItems.find(i => !i.disabled).key}
            //             size="small"
            //             tabBarGutter={0}
            //             tabPosition="left"
            //             className="special-internal-tabs"
            //             style={{ minHeight: '400px', marginTop: '-0.5rem', marginLeft: '-0.5rem' }}
            //             items={insideItems}
            //         />,
            //     }
            // })
            // } />,
            children: <InternalTabs items={Object.values(historicalCharts).map((c, i) => {
                const { vertical_col, ...newObj } = c;
                return {
                    label: vertical_col,
                    key: i,
                    children: <Tabs
                        size="small"
                        tabBarGutter={'15px'}
                        className="grand-child-tabs"
                        items={Object.values(newObj).map((t) => {
                            console.log(i)
                            if (t.charts.length > 1) {
                                return {
                                    label: t.name,
                                    key: t.name,
                                    children: <SmallCharts data={t.charts} type={i > 1 ? 'column' : 'spline'} />,
                                }
                            }
                        })}
                    />,
                }
            })
            } />,
            show: true
        },
        {
            label: `Shareholding`,
            key: '5',
            children: <div style={{ height: '500px' }}></div>,
            show: true
        },
        {
            label: `Corporate actions`,
            key: '6',
            children: <InternalTabs items={Object.keys(financialStatements?.corp_action || {}).map(a => {
                return {
                    label: a?.[0].toUpperCase() + a.slice(1),
                    key: a,
                    children: <DisplayCorporateActions x={JSON.parse(financialStatements?.corp_action?.[a] || {})?.reverse()
                    } />,
                    show: JSON.parse(financialStatements?.corp_action?.[a]).length
                }
            })} />,
            show: true
        },
        {
            label: `News & Sentiment`,
            key: '7',
            children: <InternalTabs items={[
                {
                    label: `Recent news`,
                    key: 'a',
                    children: <></>,
                },
                {
                    label: `Sentiment`,
                    key: 'b',
                    children: <></>,
                }
            ]} />,
            show: true
        },
    ]

    if (apiError) {
        return <ErrorComponent />
    }
    if (loading) {
        return <ActivityLoaderMintbox margin="20%" />
    }
    return (
        <React.Fragment>
            <Head title="Stock detail" />
            <Row style={{ marginBottom: '1rem' }}>
                <Col sm="8">
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                        <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                            <AMCIcon amcName={fundData?.symbol} fundType={type.value} height='60px' width='60px' />
                            <div className="project-info" style={{ flex: 1 }}>
                                <div className="mb-1" style={{ display: 'flex', alignItems: 'center' }}>
                                    <div className="fontH1" style={{ display: 'flex', gap: '0.25rem' }}>
                                        {fundData?.proper_name}
                                    </div>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                                    <div>
                                        <Tag className="textSM" color="var(--primary)">{sectors?.find(i => i.value === fundData.sector_code)?.label}</Tag>
                                        <Tag className="textSM" color="var(--secondary)">{industries?.find(i => i.value === fundData.industry_code)?.label}</Tag>
                                        {/* <Tag className="textSM" color="blue">{basicIndustries.find(i=>i.value===fundData.nse_basic_ind_code).label}</Tag> */}
                                        <Tag className="textSM" onClick={() => window.open(`https://www1.nseindia.com/live_market/dynaContent/live_watch/get_quote/GetQuote.jsp?symbol=${fundData.symbol}`, '_blank')} style={{ cursor: 'pointer' }} color="red">NSE</Tag>
                                        <Tag className="textSM" style={{ cursor: 'pointer' }} color="geekblue">BSE</Tag>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col sm="4">
                    <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'center', gap: '1rem' }}>
                        <Tooltip title={isWatchlisted ? "remove from watchlist" : "add to watchlist"} placement="bottom">
                            <button className="btnSecondary">
                                {
                                    isWatchlisted ?
                                        <BsFillBookmarkFill style={{ fontSize: '1.2rem' }} onClick={() => updateWatchlist()} />
                                        :
                                        <BsBookmark style={{ fontSize: '1.2rem' }} onClick={() => updateWatchlist()} />
                                }
                            </button>
                        </Tooltip>
                        <InsightsModal insights={JSON.parse(stockPerformanceDetail?.stock_insight || {})} type={type} detailType='stock' />
                        <ScorecardModal />
                    </div>

                </Col>
            </Row>
            <Row style={{ marginBottom: '1rem' }}>
                <Col sm="8">
                    <Card bordered={false} style={{ height: '100%' }} bodyStyle={{ height: '100%' }}>
                        <Tabs
                            size='small'
                            items={[
                                {
                                    label: `Performance vs Benchmark`,
                                    key: '1',
                                    children: <div className="py-3">
                                        <PerformanceChart2 chartData={stockPerformanceDetail?.total_returns?.prices} />
                                    </div>,
                                },
                                {
                                    label: `Price volume chart`,
                                    key: '2',
                                    children: <div className="py-3">
                                        <PriceVolumeChart isOneDay={stockLiveData} chartData={stockPerformanceDetail.price_vol_chart} />
                                    </div>,
                                },
                            ]}
                        />
                        <div className='mt-2' style={{ display: 'flex', justifyContent: 'space-around', gap: '1rem' }}>
                            <div>
                                <div className="custom-label textSM">Value Score</div>
                                {/* <div className="custom-value fontH2">{fundData?.sh_val_comp?.toFixed(2) || '-'}</div> */}
                                <div className="custom-value fontH2">
                                    <CustomSharpelyScoreProgressBar val={fundData?.sh_val_comp} />
                                </div>
                            </div>
                            <div>
                                <div className="custom-label textSM">Momentum Score</div>
                                {/* <div className="custom-value fontH2">{fundData?.sh_mom_comp?.toFixed(2) || '-'}</div> */}
                                <CustomSharpelyScoreProgressBar val={fundData?.sh_mom_comp} />
                            </div>
                            <div>
                                <div className="custom-label textSM">Quality Score</div>
                                {/* <div className="custom-value fontH2">{fundData?.sh_qual_comp?.toFixed(2) || '-'}</div> */}
                                <CustomSharpelyScoreProgressBar val={fundData?.sh_qual_comp} />
                            </div>
                            <div>
                                <div className="custom-label textSM">QVM Score</div>
                                {/* <div className="custom-value fontH2">{fundData?.sh_qual_mom_comp?.toFixed(2) || '-'}</div> */}
                                <CustomSharpelyScoreProgressBar val={fundData?.sh_qual_mom_comp} />
                            </div>
                        </div>
                        {/* <PerformanceChart2 chartData={stockPerformanceDetail?.performance_snapshot?.snapshot?.prices} benchName={stockPerformanceDetail?.overview?.mintbox_bm_name} fundName={fundData?.nse_symbol} /> */}
                    </Card>
                </Col>
                <Col sm="4">
                    <Card bordered={false} style={{ height: '100%' }} bodyStyle={{ height: '100%' }}>
                        <div className="fund-detail-card-left" style={{ border: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                            {/* <div className="textSM" style={{ lineHeight: '24x' }}>
                                <ReadMoreText text={stockPerformanceDetail?.overview?.objective_text} />
                            </div> */}
                            <div className="custom-details-table text-center">
                                <div className="custom-detail" >
                                    <div className="custom-label textSM">Market cap</div>
                                    <div className="custom-value fontH2">{CurrencyFormat(fundData?.mcap, "long", 0)} Cr</div>
                                </div>
                                <div className="custom-detail" >
                                    <div className="custom-label textSM">Enterprise value</div>
                                    <div className="custom-value fontH2">{CurrencyFormat(fundData?.mcap, "long", 0)} Cr</div>
                                </div>
                                <div className="custom-detail" >
                                    <div className="custom-label textSM">sharpely style box</div>
                                    <div className="custom-value fontH2">
                                        <StyleBoxIcons style_box_code={fundData?.style_box_code - 1} />
                                        <span style={{ whiteSpace: 'nowrap', paddingLeft: '0.2rem' }}>{styleBoxes.find(s => s.style_box_code === fundData?.style_box_code)?.style_box_name || '-'}</span>
                                    </div>
                                </div>
                                <div className="custom-detail" >
                                    <div className="custom-label textSM">sharpely risk box</div>
                                    <div className="custom-value fontH2" style={{ color: RiskTextColor(fundData?.risk_box_code) }}>{RiskFormat(fundData?.risk_box_code) || '-'}</div>
                                </div>
                            </div>
                            <InvestmentCard SCREENTYPE={1} FUNDDATA={{ ...fundData, FundCode: fundData.symbol }} />
                        </div>
                    </Card>
                </Col>
            </Row>
            <Tabs
                defaultActiveKey="1"
                size="small"
                type="card"
                items={tabData.filter(t => t.show)}
            />
        </React.Fragment >
    );
};

export default StockDetail;
