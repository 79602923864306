
import { baseImgURL } from 'Assets/Constant'
import React from 'react'

function MintboxIcon({ id, height, width }) {
  return (
    // <div style={{ display:'flex', border: "1px solid " + cssVar.getPropertyValue("--lowestRisk"),padding:`calc(${height}*10/100) 0`, borderRadius: '4px', height, width, backgroundColor: RiskColor(risk)}}>
    //     <img src={baseImgURL+'wealth-creators.webp'} style={{height: `calc(${height}*80/100)`,margin:'auto'}}/>
    // </div>
    Number(id) > 20 || id === undefined ?
      <img src={baseImgURL + `mintbox_boxLogo.webp`} style={{ height, width }} />
      :
      <img src={baseImgURL + `MintboxIcon${id}.webp`} style={{ height, width }} />
  )
}

export default React.memo(MintboxIcon)