import React from 'react'
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';

export default function ReadMoreText({ text, onViewChange = () => { }, showCharacters = 130 }) {

    const [isReadMore, setIsReadMore] = React.useState(true);
    React.useEffect(() => {
        onViewChange(!isReadMore)
    }, [isReadMore])

    if (!text) {
        return ''
    }
    if (text.length < showCharacters + 20) {
        return text
    }
    else return <>
        {isReadMore ? text?.slice(0, showCharacters) + "..." : text}
        <span onClick={(e) => { e.stopPropagation(); setIsReadMore(!isReadMore) }} style={{ color: 'var(--primary)', fontWeight: "500", whiteSpace: "nowrap", cursor: 'pointer' }}>
            {isReadMore ? " Read More " : " Read Less "}{isReadMore ? <BsChevronDown size={12} /> : <BsChevronUp size={12} />}
        </span>
    </>
} 