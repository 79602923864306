// class SHA256Hasher {
//   constructor() {
//     this.enc = new TextEncoder();
//     this.dec = new TextDecoder();
//     this.iv = window.crypto.getRandomValues(new Uint8Array(12));
//   }

//   async exportCryptoKey(key) {
//     const exported = await window.crypto.subtle.exportKey("raw", key);
//     const exportedKeyBuffer = new Uint8Array(exported);
//     return exportedKeyBuffer;
//   }

//   async importSecretKey() {
//     let rawKey = await localStorage.getItem("yek")
//     rawKey = new Uint8Array(rawKey.split(",").map(Number));
//     const key = await window.crypto.subtle.importKey(
//       "raw",
//       rawKey,
//       "AES-GCM",
//       true,
//       ["encrypt", "decrypt"]
//     );
//     console.log("Imported: ", key);
//     return key
//   }


//   async secretKey() {
//     const key = await window.crypto.subtle.generateKey({
//       name: "AES-GCM",
//       length: 256,
//     }, true, ["encrypt", "decrypt"])
//     localStorage.setItem("yek", await this.exportCryptoKey(key))
//     return key
//   }

//   async encrypt(text) {
//     const encodedMessage = this.enc.encode(text);
//     const key = await this.secretKey()
//     const encryptedText = await window.crypto.subtle.encrypt({
//       name: "AES-GCM",
//       iv: this.iv
//     }, key, encodedMessage)
//     return new Uint8Array(encryptedText);
//   }

//   async decrypt(encryptedText) {
//     const key = await this.importSecretKey()
//     try{
//       const decryptedText = await window.crypto.subtle.decrypt({
//         name: "AES-GCM",
//         iv: this.iv
//       }, key, encryptedText)
//       return this.dec.decode(new Uint8Array(decryptedText));
//     }
//     catch(e) {
//       console.log("E: ", e)
//       return false
//     }

//   }
// 
// }



var salt = "l1czA/hCG2HOfC6dV6ewfQfhK4NQKTyqYfBTPLcgIok=";


function encrypt(o) {
  o = JSON.stringify(o).split('');
  for (var i = 0, l = o.length; i < l; i++)
    if (o[i] === '{')
      o[i] = '}';
    else if (o[i] === '}')
      o[i] = '{';
  return encodeURI(salt + o.join(''));
}

function decrypt(o) {
  if (o === null) {
    console.error('DB Error: object is not present in DB.')
    return false
  }
  o = decodeURI(o);
  if (salt && o.indexOf(salt) !== 0) {
    console.error('object cannot be decrypted');
    return false
  }
  o = o.substring(salt.length).split('');
  for (var i = 0, l = o.length; i < l; i++)
    if (o[i] === '{')
      o[i] = '}';
    else if (o[i] === '}')
      o[i] = '{';
  return JSON.parse(o.join(''));
}


export { encrypt, decrypt }
// export default SHA256Hasher