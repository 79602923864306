import { ConfigProvider, Layout as AntDLayout, theme } from 'antd';
import BreadCrumbs from 'Components/BreadCrumbs';
import LoginSignupModal from 'Components/LoginSignupModal';
import React from "react";
import { useSelector } from 'react-redux';
import Pages from "Routes/Index";
import Head from "./Head";
import Header from "./Header";
import Footer from './Footer';

const Layout = (props) => {
  const [isDarkMode, setisDarkMode] = React.useState()
  const cssVar = getComputedStyle(document.documentElement);
  const toggleDarkMode = (m) => {
    setisDarkMode(m)
  }
  var loginModal = useSelector((state) => state?.toggleloginModal?.value)
  console.log(loginModal)
  return (
    <React.Fragment>
      <Head title="Loading" />
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: cssVar.getPropertyValue('--primary'),
            borderRadius: "4px",
            borderRadiusLG: "4px",
            colorTextPlaceholder: "var(--dark2)",
            fontSizeIcon: 10,
            paddingXXS: 0,
            lineHeight: 1.25,
            colorTextBase: "var(--black)",
            colorBorder: "var(--grey3)",
            fontFamily: "DM Sans"
          },
          algorithm: isDarkMode ? theme.darkAlgorithm : theme.defaultAlgorithm
        }}
      >
        <AntDLayout style={{ backgroundColor: "var(--grey3)", minHeight: "100vh" }}>
          <AntDLayout.Header style={{ backgroundColor: "var(--white)", padding: 0, position: "sticky", top: "0px", zIndex: 1000, borderBottom: "1px solid var(--grey2)" }}>
            <Header isDarkMode={isDarkMode} toggleDarkMode={toggleDarkMode} />
          </AntDLayout.Header>
          <AntDLayout.Content style={{ flex: 1, minHeight: "100vh" }}>
            <BreadCrumbs />
            <main className='topLevelContainer'>
              <Pages />
            </main>
          </AntDLayout.Content>
          <AntDLayout.Footer style={{ padding: 0, backgroundColor: "var(--white)" }}>
            <Footer />
          </AntDLayout.Footer>
        </AntDLayout>
        <LoginSignupModal modelOpen={loginModal} />
      </ConfigProvider>
    </React.Fragment>
  );
};
export default Layout;
