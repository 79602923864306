import React from 'react';
import { BsCircleFill } from 'react-icons/bs';



const ListViewWithIcon = ({ children, type = "icon" }) => {

    return <div>
        {children.map((i, index) => {
            return <div key={index + ""} style={{ display: "flex", flexDirection: "row", alignItems: i.length > 60 ? "flex-start" : "center", marginLeft: 5 }}>
                {type === 'icon' ? <BsCircleFill size={5} style={{
                    color: "var(--black)", marginTop: type === 'icon' ? i.length > 60 ? 7 : 0 : 0
                }} /> : <div className='fontH2 w-normal black' style={{}}>{index + 1}.</div>}
                <div className='fontH2 w-normal dark3' style={{ marginLeft: "10px" }}>{i}</div>
            </div>
        })}
    </div >
};

export default ListViewWithIcon;
