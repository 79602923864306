import { Card, Divider } from "antd";
import StaticSlider from "./StaticSlider";

const CustomRangeSliders = ({ sliders }) => {
    return <Card>
        {
            sliders.map((x, i) => {
                if (x.show) {
                    return <div style={{ display: 'flex', gap: '2rem', alignItems: 'center', paddingTop: i === 0 ? '0' : '1rem', paddingBottom: '1rem', borderBottom: i === sliders.length - 1 ? 'none' : '1px solid var(--grey3)' }}>
                        <div style={{ flex: 2 }}>
                            <div className="textSM w-500 black">{x.title}</div>
                            <div className="textXS dark2">{x.description}</div>
                        </div>
                        <div style={{ flex: 3 }}>
                            <StaticSlider
                                currentVal={x.currentVal}
                                currentValStr={x.currentValStr}
                                min={x.min}
                                max={x.max}
                                minStr={x.minStr}
                                maxStr={x.maxStr}
                                minStrLabel={x.minStrLabel}
                                maxStrLabel={x.maxStrLabel}
                            />
                        </div>
                    </div>
                }
                else return <></>
            })
        }
    </Card>
}

export default CustomRangeSliders;