import { Button, Card, Drawer, Segmented, Table, Tabs } from "antd"
import React, { useState } from "react"
import { AiFillCaretRight, AiOutlineArrowLeft } from "react-icons/ai"
import { MdFlag } from "react-icons/md"
import apiHelperPY from "Services/api/ApiHelperPY"
import ActivityLoaderMintbox from "./ActivityLoaderMintbox"
import AMCIcon from "./AMCIcon"
import { CardHeader } from "./atoms/SmallComponents"
import CustomSegmentedTab from "./CustomSegmentedTab"
import CustomSharpelyScoreProgressBar from "./CustomSharpelyScoreProgressBar"
import { MatrixCardView } from "./MatrixCardView"
import PerformanceChart3 from "./PerformanceChart3"
import StyleBoxIcons from "./StyleBoxIcons"

const CustomDrawer = ({ title, isOpen, toggle, children }) => {
    return <Drawer title={title} placement="right" onClose={toggle} open={isOpen} push={false}
        footerStyle={{ textAlign: 'right', borderTop: 'none', padding: '1.25rem' }} width="52.6rem" bodyStyle={{ padding: '0 1.25rem' }}
        footer={<button className="btnPrimary ms-auto" onClick={toggle}>Close</button>}
        // closeIcon={<AiOutlineArrowLeft style={{ fontSize: '1.5rem', color: 'black' }} />}
        closable={false}
        headerStyle={{ padding: '1.25rem', borderBottom: 'none' }}>
        {children}
    </Drawer>
}

const DisplayOverview = ({ x }) => {
    return <div style={{ paddingTop: '1rem' }}>
        <div style={{ marginBottom: '1rem', display: 'flex', alignItems: 'center', gap: '1rem' }}>
            <div className="textSM dark3">sharpely styleBox:</div>
            <StyleBoxIcons style_box_code={x?.data?.style_box_code - 1} size={'3rem'} />
            <div className="fontH2 w-500">{x?.data?.style_box_name}</div>
        </div>
        {
            Object.values(x?.data?.stance_text || {})?.map((s, i) => {
                return <p className={i === 0 ? 'textSM dark3 w-500' : 'textSM dark3'}>{s}</p>
            })
        }
    </div>
}

const DisplayStockStyleAnalysis = ({ x }) => {
    const children = x?.children
    const options2 = [
        {
            key: 'pe_ttm',
            name: 'Price to earnings'
        },
        {
            key: 'pb',
            name: 'Price to book'
        }
    ]
    const [selected, setSelected] = React.useState(children?.[0])
    const [selected2, setSelected2] = React.useState(options2?.[0])
    return <div>
        <Segmented size="small" value={selected?.section_name} options={children?.map(s => s.section_name)} onChange={(c) => setSelected(children?.find(x => x.section_name === c))} style={{ margin: '1rem 0' }} />
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '2%' }}>
            {selected?.data?.map((data, i) => {
                if (data?.type === 1) {
                    if (data?.chart?.chart_data) {
                        return <div style={{ padding: '1rem 0' }}>
                            <div className='fontH2 w-500'>{data.name}</div>
                            <div style={{ flex: 1, minWidth: '25rem', maxWidth: '25rem', margin: '0.5rem 0' }}>
                                <Segmented size="small" value={selected2?.name} options={options2?.map(s => s.name)} onChange={(c) => setSelected2(options2?.find(x => x.name === c))} style={{ marginBottom: '0.5rem' }} />
                                <PerformanceChart3 yAxisKeys={[selected2.key]} fundName={''} chartData={JSON.stringify(data?.chart?.chart_data)} xAxisKey={'price_date'} />
                                <div className="textSM dark3 m-2" style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                    {
                                        selected2.key === 'pe_ttm' ?
                                            <>
                                                <div>Average PE: <b>{data?.chart?.pe_avg}</b></div>
                                                <div>Median PE: <b>{data?.chart?.pe_median}</b></div>
                                            </>
                                            : selected2.key === 'pb' ?
                                                <>
                                                    <div>Average PB: <b>{data?.chart?.pb_avg}</b></div>
                                                    <div>Median PB: <b>{data?.chart?.pb_median}</b></div>
                                                </>
                                                :
                                                <></>
                                    }
                                </div>
                            </div>
                            {data?.children?.map((data, i) => {
                                if ((selected2.key === 'pe_ttm' && (i === 0 || i === 1)) || (selected2.key === 'pb' && (i === 2 || i === 3))) {
                                    return <div key={i} style={{ display: 'flex', marginBottom: '1rem', alignItems: 'center' }}>
                                        {data.stance === "Positive" ?
                                            <img style={{ height: '3rem', width: '3rem', marginRight: '1rem' }} src="https://d3etfstcuyoos5.cloudfront.net/app-images/imgs/like_icon.png" />
                                            : data.stance === "Negative" ?
                                                <img style={{ height: '3rem', width: '3rem', marginRight: '1rem' }} src="https://d3etfstcuyoos5.cloudfront.net/app-images/imgs/dislike_icon.png" />
                                                : data.stance === "Neutral" ?
                                                    <img style={{ height: '3rem', width: '3rem', marginRight: '1rem' }} src="https://d3etfstcuyoos5.cloudfront.net/app-images/imgs/neutral_icon.png" />
                                                    :
                                                    <img style={{ height: '3rem', width: '3rem', marginRight: '1rem' }} src="https://d3etfstcuyoos5.cloudfront.net/app-images/imgs/no_data_icon.png" />
                                        }
                                        <div style={{ flex: 1 }}>
                                            <div className="fontH2 w-500">{data.name}</div>
                                            <div className="textSM dark3">{data.stance_text}</div>
                                        </div>
                                    </div>
                                }
                            })}
                        </div>
                    } else return <></>
                }
                else return <div key={i} style={{ width: '49%', marginBottom: '1rem' }}>
                    <div className="fontH2 w-500 text-center">{data?.name?.charAt(0)?.toUpperCase() + data?.name?.slice(1)}</div>
                    <div style={{ padding: '0.5rem', textAlign: 'center' }}>
                        <CustomSharpelyScoreProgressBar val={data?.val} />
                    </div>
                    {/* <div className="textSM dark3">{data?.stance_text}</div> */}
                    <div key={i} style={{ display: 'flex', marginBottom: '1rem', alignItems: 'center' }}>
                        {data.stance === "Positive" ?
                            <img style={{ height: '3rem', width: '3rem', marginRight: '1rem' }} src="https://d3etfstcuyoos5.cloudfront.net/app-images/imgs/like_icon.png" />
                            : data.stance === "Negative" ?
                                <img style={{ height: '3rem', width: '3rem', marginRight: '1rem' }} src="https://d3etfstcuyoos5.cloudfront.net/app-images/imgs/dislike_icon.png" />
                                : data.stance === "Neutral" ?
                                    <img style={{ height: '3rem', width: '3rem', marginRight: '1rem' }} src="https://d3etfstcuyoos5.cloudfront.net/app-images/imgs/neutral_icon.png" />
                                    :
                                    <img style={{ height: '3rem', width: '3rem', marginRight: '1rem' }} src="https://d3etfstcuyoos5.cloudfront.net/app-images/imgs/no_data_icon.png" />
                        }
                        <div style={{ flex: 1 }}>
                            {/* <div className="fontH2 w-500">{data.name}</div> */}
                            <div className="textSM dark3">{data.stance_text}</div>
                        </div>
                    </div>
                </div>
            })}
        </div>
    </div>
}

const DisplayStanceText = ({ x }) => {
    return x?.data?.map((data, i) => {
        return <Card key={data.param_id} style={{ borderRadius: '12px' }} bodyStyle={{ padding: 0 }}>
            <div style={{ display: 'flex', gap: '1rem', padding: '0.625rem 1.25rem' }}>
                {data.stance === "Positive" ?
                    <img style={{ height: '3rem', width: '3rem' }} src="https://d3etfstcuyoos5.cloudfront.net/app-images/imgs/like_icon.png" />
                    : data.stance === "Negative" ?
                        <img style={{ height: '3rem', width: '3rem' }} src="https://d3etfstcuyoos5.cloudfront.net/app-images/imgs/dislike_icon.png" />
                        : data.stance === "Neutral" ?
                            <img style={{ height: '3rem', width: '3rem' }} src="https://d3etfstcuyoos5.cloudfront.net/app-images/imgs/neutral_icon.png" />
                            :
                            <img style={{ height: '3rem', width: '3rem' }} src="https://d3etfstcuyoos5.cloudfront.net/app-images/imgs/no_data_icon.png" />
                }
                <div style={{ flex: 1 }}>
                    <div className="fontSM dark3 w-500 mb-2">{data.name}</div>
                    <div className="textSM dark3">{data.stance_text}</div>
                </div>
            </div>
        </Card>
    })
}

const DisplayRedFlags = ({ x }) => {
    return x?.data?.map((data, i) => {
        const dataTableColumns = Object.keys(data?.stance_table?.[0] || {}).map(k => {
            if (k === 'symbol') {
                return undefined
            } else if (k === "proper_name") {
                return {
                    "title": "Name",
                    "dataIndex": "proper_name",
                    render: (text, record, index) => <div style={{ display: 'flex', gap: '12px', cursor: "pointer" }}>
                        <AMCIcon amcName={record.symbol} fundType={4} height='1.2rem' width="1.2rem" />
                        <div>
                            <div className="textSM dark3 black">{record?.proper_name}</div>
                        </div>
                    </div>,
                    fixed: 'left',
                }
            }
            else return {
                "title": "Value",
                "dataIndex": k,
                "align": "right",
            }
        }).filter(x => x)
        return <Card key={data.param_id} style={{ borderRadius: '12px' }} bodyStyle={{ padding: 0 }}>
            <div style={{ display: 'flex', gap: '1rem', padding: '0.625rem 1.25rem' }}>

                <MdFlag style={{ height: '3rem', width: '3rem', color: 'var(--red)' }} />
                <div style={{ flex: 1 }}>
                    <div className="fontSM w-500 dark3 mb-2">{data.name}</div>
                    <div className="textSM dark3">{data.stance_text}</div>
                    <div className="mt-2 me-4">
                        {
                            data.stance_table ?
                                <Table className='insideTable' pagination={false}
                                    dataSource={data.stance_table} columns={dataTableColumns} />
                                :
                                <></>
                        }
                    </div>
                </div>
            </div>
        </Card>
    })
}

const DisplayInstruments = ({ x }) => {
    const dataTableColumns = Object.keys(x?.data?.[0] || {}).map((k, i) => {
        const title = k
        return {
            title: title[0].toUpperCase() + title.slice(1),
            dataIndex: k,
            align: i === 0 ? 'left' : 'right',
            fixed: i === 0 ? 'left' : '',
        }
    })
    return <div><Table className='insideTable' pagination={false}
        dataSource={x?.data} columns={dataTableColumns} /></div>
}

const DisplaySebiPrcMatrix = ({ x }) => {
    return <MatrixCardView v1Head='Credit risk' v2Head='Interest risk' v1={x?.credit_risk} v2={x?.interest_rate_risk} />
}

const DisplayEquityInsights = ({ x }) => {
    console.log(x)
    switch (x.type) {
        case 1: return DisplayOverview({ x })
        case 2: return DisplayStockStyleAnalysis({ x })
        case 3: return DisplayStanceText({ x })
        case 4: return DisplayRedFlags({ x })
    }
}

const DisplayDebtInsights = ({ x }) => {
    switch (x.type) {
        case 1: return DisplayInstruments({ x })
        case 2: return DisplaySebiPrcMatrix({ x })
        case 3: return DisplayStanceText({ x })
    }
}

const DisplayTickerList = ({ x }) => {
    const [childrenDrawer, setChildrenDrawer] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedStock, setSelectedStock] = useState({})
    const [stockInsight, setStockInsights] = useState([])
    const dataTableColumns = [
        {
            "title": "Name",
            "dataIndex": "full_name",
            render: (text, record, index) => <div style={{ display: 'flex', gap: '12px', cursor: "pointer" }}>
                <AMCIcon amcName={record.symbol} fundType={4} height='1.2rem' width="1.2rem" />
                <div>
                    <div className="textSM dark3 black">{text}</div>
                </div>
            </div>,
            fixed: 'left',
        },
        {
            "title": "Weight",
            "dataIndex": 'weight',
            "align": "right",
        },
        {
            "align": "right",
            render: t => <AiFillCaretRight />
        },
    ]

    React.useEffect(() => {
        const dum = async () => {
            setLoading(true)
            let res = await apiHelperPY({ apiName: 'getStockInsights', data: { ticker: selectedStock?.symbol }, saveResponse: '' })
            if (res) {
                setStockInsights(JSON.parse(res))
                setLoading(false)
            }
            else {
                setStockInsights([])
                setLoading(false)
            }
        }
        dum();
    }, [selectedStock])
    return <><Table bordered={false} size='small' className='textXS dark2 black tickerTable' pagination={false}
        dataSource={x} columns={dataTableColumns} onRow={(record) => {
            return {
                onClick: () => { setSelectedStock(record); setChildrenDrawer(true) }, // click row
            };
        }} />
        <CustomDrawer title={<div style={{ display: 'flex', gap: '12px', alignItems: 'center' }}>
            <AMCIcon amcName={selectedStock?.symbol} fundType={4} height='1.5rem' width="1.5rem" />
            <div className="fontXS w-700 black">{selectedStock.full_name}</div>
        </div>} toggle={() => setChildrenDrawer(!childrenDrawer)} isOpen={childrenDrawer}>
            {
                loading ?
                    <ActivityLoaderMintbox margin="5rem" />
                    :
                    stockInsight && stockInsight?.length ?
                        <StockInsightsModal insights={stockInsight} />
                        :
                        <div className="fontH2 mt-4">Not enough data</div>
            }
        </CustomDrawer>
    </>
}

const DisplayMFList = ({ x, type }) => {
    const [childrenDrawer, setChildrenDrawer] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selected, setSelected] = useState({})
    const [insight, setInsights] = useState({})
    const dataTableColumns = [
        {
            "title": "Name",
            "dataIndex": "basic_name",
            render: (text, record, index) => <div style={{ display: 'flex', gap: '12px', cursor: "pointer" }}>
                <AMCIcon amcName={record.amc_full_name} fundType={2} height='1.2rem' width="1.2rem" />
                <div>
                    <div className="textSM dark3 black">{text}</div>
                </div>
            </div>,
            fixed: 'left',
        },
        {
            "title": "Weight",
            "dataIndex": 'weights',
            "align": "right",
        },
        {
            "align": "right",
            render: t => <AiFillCaretRight />
        },
    ]

    React.useEffect(() => {
        const dum = async () => {
            setLoading(true)
            let res = await apiHelperPY({ apiName: 'getMFInsights', data: { planId: selected?.plan_id, assetType: selected?.primary_category_name?.toLowerCase() }, saveResponse: '' })
            if (res) {
                setInsights(res)
                setLoading(false)
            }
            else {
                setLoading(false)
                setInsights([])
            }
        }
        dum();
    }, [selected])
    return <><Table bordered={false} size='small' className='textXS dark2 black tickerTable' pagination={false}
        dataSource={x} columns={dataTableColumns} onRow={(record) => {
            return {
                onClick: () => { setSelected(record); setChildrenDrawer(true) }, // click row
            };
        }} />
        <CustomDrawer
            title={<div style={{ display: 'flex', gap: '12px', alignItems: 'center' }}>
                <AMCIcon amcName={selected.amc_full_name} fundType={2} height='1.5rem' width="1.5rem" />
                <div className="fontXS w-700 black">{selected.basic_name}</div>
            </div>}
            toggle={() => setChildrenDrawer(!childrenDrawer)}
            isOpen={childrenDrawer}
        >
            {
                loading ?
                    <ActivityLoaderMintbox margin="5rem" />
                    :
                    Object.keys(insight || {}).length ?
                        <FundInsightsModal insights={insight} type={type} />
                        :
                        <div className="fontH2 mt-4">Not enough data</div>
            }
        </CustomDrawer>
    </>
}

const FundInsightsModal = ({ insights, type }) => {
    const [childrenDrawer, setChildrenDrawer] = useState(false);

    const Overview = () => {
        const [openedParamId, setOpenedParamId] = React.useState();
        return <div style={{ height: 'calc(100% - 4rem)', backgroundColor: 'var(--grey5)', overflowY: 'scroll', display: 'flex', flexDirection: 'column', gap: '0.75rem', padding: '1.25rem' }}>
            {/* <CardHeader heading={'Overview'} style={{ marginBottom: '0.5rem' }} /> */}
            {insights?.overview?.map((data) => {
                return <Card key={data.param_id} style={{ borderRadius: '12px' }} bodyStyle={{ padding: 0 }}>
                    <div style={{ display: 'flex', gap: '1rem', padding: '0.625rem 1.25rem' }}>
                        {data.mintbox_stance === "Positive" ?
                            <img style={{ height: '3rem', width: '3rem' }} src="https://d3etfstcuyoos5.cloudfront.net/app-images/imgs/like_icon.png" />
                            : data.mintbox_stance === "Negative" ?
                                <img style={{ height: '3rem', width: '3rem' }} src="https://d3etfstcuyoos5.cloudfront.net/app-images/imgs/dislike_icon.png" />
                                : data.mintbox_stance === "Neutral" ?
                                    <img style={{ height: '3rem', width: '3rem' }} src="https://d3etfstcuyoos5.cloudfront.net/app-images/imgs/neutral_icon.png" />
                                    :
                                    <img style={{ height: '3rem', width: '3rem' }} src="https://d3etfstcuyoos5.cloudfront.net/app-images/imgs/no_data_icon.png" />
                        }
                        <div style={{ flex: 1 }}>
                            <div className="textSM dark3 w-500">{data.param_text}</div>
                            <div className="textSM dark3 mt-2">{data.stance_text}
                                <span style={{ color: 'var(--red)', textDecoration: 'underline', whiteSpace: 'nowrap', cursor: 'pointer', marginLeft: '4px' }}
                                    onClick={() => { setOpenedParamId(data.param_id === openedParamId ? null : data.param_id) }}>
                                    {
                                        data.param_id === openedParamId ?
                                            "View less" : "Know more"
                                    }
                                </span>
                            </div>
                            {
                                data.param_id === openedParamId ?
                                    <div className="textXS dark2 mt-2">{data.help_text}</div>
                                    : <></>
                            }
                        </div>
                    </div>
                </Card>
            })}</div>
    }
    const EquityInsights = () => {
        const options = Object.values(JSON.parse(insights?.mf_paid || '')?.equity_insights?.overview || {})
        const [selected, setSelected] = React.useState(options?.[0])
        if (JSON.parse(insights?.mf_paid || '')?.equity_insights) {
            return <>
                <div><Segmented className="insightsTabs" value={selected?.section_name} options={options?.map(s => s.section_name)} onChange={(c) => setSelected(options?.find(x => x.section_name === c))} style={{ marginBottom: '1rem', background: 'var(--white)', padding: 0 }} /></div>
                <div style={{ height: 'calc(100% - 7rem)', backgroundColor: 'var(--grey5)', overflowY: 'scroll', display: 'flex', flexDirection: 'column', gap: '0.75rem', padding: '1.25rem' }}>
                    <DisplayEquityInsights x={selected} />
                </div>
                {/* <button style={{ width: '100%' }} className="btnBlack mt-3" onClick={() => setChildrenDrawer(true)}>
                    Detailed equity insights
                </button> */}
            </>
        } else {
            return <div style={{ height: 'calc(100% - 5rem)', backgroundColor: 'var(--grey5)', overflowY: 'scroll', display: 'flex', flexDirection: 'column', gap: '0.75rem', padding: '1.25rem' }}>
                <Card>No significant equity holdings</Card>
            </div>
        }
    }
    const DebtInsights = () => {
        const options = Object.values(JSON.parse(insights?.mf_paid || '')?.debt_insights || {})
        const [selected, setSelected] = React.useState(options?.[0])
        if (JSON.parse(insights?.mf_paid || '')?.debt_insights) {
            return <>
                <div><Segmented className="insightsTabs" value={selected?.section_name} options={options?.map(s => s.section_name)} onChange={(c) => setSelected(options?.find(x => x.section_name === c))} style={{ marginBottom: '1rem', background: 'var(--white)', padding: 0 }} /></div>
                <div style={{ height: 'calc(100% - 7rem)', backgroundColor: 'var(--grey5)', overflowY: 'scroll', display: 'flex', flexDirection: 'column', gap: '0.75rem', padding: '1.25rem' }}>
                    <DisplayDebtInsights x={selected} />
                </div>
            </>
        } else {
            return <div style={{ height: 'calc(100% - 5rem)', backgroundColor: 'var(--grey5)', overflowY: 'scroll', display: 'flex', flexDirection: 'column', gap: '0.75rem', padding: '1.25rem' }}>
                <Card>No significant debt holdings</Card>
            </div>
        }

    }
    const tabs = [
        {
            key: 0,
            value: 0,
            label: <div className='fontH2'>Overview</div>,
            children: <Overview />
        },
        {
            key: 1,
            value: 1,
            label: <div className='fontH2'>Equity insights</div>,
            children: <EquityInsights />
        },
        {
            key: 2,
            value: 2,
            label: <div className='fontH2'>Debt insights</div>,
            children: <DebtInsights />
        }
    ]
    const [selectedTabItem, setSelectedTabItem] = React.useState(0);
    return (
        <>
            <CustomSegmentedTab isBlock={false}
                value={selectedTabItem}
                onChange={(e) => {
                    setSelectedTabItem(e)
                }}
                options={tabs} style={{ marginBottom: '1.25rem' }} />
            {tabs.filter((i) => i.value === selectedTabItem)[0]?.children}
            <CustomDrawer
                title={<div className="fontXS w-700 black">Stocks in this {type.omkar}</div>}
                toggle={() => setChildrenDrawer(!childrenDrawer)}
                isOpen={childrenDrawer}
            >
                {
                    <DisplayTickerList x={JSON.parse(insights?.mf_paid || '')?.equity_insights?.ticker_list} />
                }
            </CustomDrawer>
        </>
    )
}

const StockInsightsModal = ({ insights }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => { setIsOpen(!isOpen) };

    const tabs = insights?.map((tab, i) => {
        return {
            key: i,
            label: <div className='fontH2'>{tab.section_name}</div>,
            children: <DisplayEquityInsights x={tab} />
        }
    })
    return (
        <Tabs
            defaultActiveKey="0"
            size="small"
            items={tabs}
        />
    )
}

const FundPortfolioInsightsModal = ({ insights, holdings, type }) => {
    const [childrenDrawer, setChildrenDrawer] = useState(false);

    const EquityInsights = () => {
        const options = Object.values(insights?.equity_insights?.overview || {})
        const [selected, setSelected] = React.useState(options?.[0])
        return <>
            <Segmented value={selected?.section_name} options={options?.map(s => s.section_name)} onChange={(c) => setSelected(options?.find(x => x.section_name === c))} style={{ margin: '1rem 0' }} />
            <DisplayEquityInsights x={selected} />
            <button block className="btnPrimary mt-3" style={{ color: 'var(--primary)' }} onClick={() => setChildrenDrawer(true)}>
                Detailed equity insights
            </button>
        </>
    }

    const DebtInsights = () => {
        const options = Object.values(insights?.debt_insights || {})
        const [selected, setSelected] = React.useState(options?.[0])
        return <>
            <Segmented value={selected?.section_name} options={options?.map(s => s.section_name)} onChange={(c) => setSelected(options?.find(x => x.section_name === c))} style={{ margin: '1rem 0' }} />
            <DisplayDebtInsights x={selected} />
        </>
    }
    const tabs = [
        {
            key: 0,
            label: <div className='fontH2'>Equity insights</div>,
            children: <EquityInsights />
        },
        {
            key: 1,
            label: <div className='fontH2'>Debt insights</div>,
            children: <DebtInsights />
        },
        {
            key: 2,
            label: <div className='fontH2'>Holdings</div>,
            children: <DisplayMFList x={holdings} type={type} />
        }
    ]
    return (
        <>
            <Tabs
                defaultActiveKey="0"
                size="small"
                items={tabs}
            />
            <Drawer
                title={`Stocks in this strategy`}
                width={"30rem"}
                push={false}
                onClose={() => setChildrenDrawer(false)}
                open={childrenDrawer}
                bodyStyle={{ padding: 0 }}
                closeIcon={<AiOutlineArrowLeft style={{ fontSize: '1.5rem', color: 'black' }} />} headerStyle={{ paddingLeft: '0.2rem' }}
            >
                {
                    <DisplayTickerList x={insights?.equity_insights?.ticker_list} />
                }
            </Drawer>
        </>
    )
}

const StockPortfolioInsightsModal = ({ insights, holdings, type }) => {
    const [childrenDrawer, setChildrenDrawer] = useState(false);

    const tabs = Object.values(insights || {})?.map((tab, i) => {
        return {
            key: i,
            label: <div className='fontH2'>{tab.section_name}</div>,
            children: <>
                <DisplayEquityInsights x={tab} />
                <button block className="btnPrimary mt-3" style={{ color: 'var(--primary)' }} onClick={() => setChildrenDrawer(true)}>
                    Detailed equity insights
                </button>
            </>
        }
    })
    const tickerList = holdings?.map(h => {
        return {
            symbol: h.symbol,
            full_name: h.proper_name,
            weight: h.weights
        }
    })
    return (
        <>
            <Tabs
                defaultActiveKey="0"
                size="small"
                items={tabs}
            />
            <Drawer
                title={`Stocks in this strategy`}
                width={"30rem"}
                push={false}
                onClose={() => setChildrenDrawer(false)}
                open={childrenDrawer}
                bodyStyle={{ padding: 0 }}
                closeIcon={<AiOutlineArrowLeft style={{ fontSize: '1.5rem', color: 'black' }} />} headerStyle={{ paddingLeft: '0.2rem' }}
            >
                {
                    <DisplayTickerList x={tickerList} />
                }
            </Drawer>
        </>
    )
}

const InsightsModal = ({ insights, holdings, type, detailType }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => { setIsOpen(!isOpen) };
    return (
        <>
            <button style={{ width: '100%' }} className="btnBlack" onClick={toggle}>
                Insights
            </button>
            <CustomDrawer title={<div className="fontXS w-700 black">{type?.omkar} Insights</div>} toggle={toggle} isOpen={isOpen}>
                {
                    detailType === 'fund' ?
                        <FundInsightsModal insights={insights} type={type} />
                        :
                        detailType === 'stock' ?
                            <StockInsightsModal insights={insights} type={type} />
                            :
                            detailType === 'fundPortfolio' ?
                                <FundPortfolioInsightsModal insights={insights} holdings={holdings} type={type} />
                                :
                                detailType === 'stockPortfolio' ?
                                    <StockPortfolioInsightsModal insights={insights} holdings={holdings} type={type} />
                                    :
                                    <></>
                }
            </CustomDrawer>
        </>
    )
}

export { InsightsModal }
