import React from "react";
import { Link } from "react-router-dom";
import sharpely_logo_light from "Assets/images/sharpely-icons/svg/sharpely-nameicon-white.svg"
import sharpely_logo_dark from "Assets/images/sharpely-icons/svg/sharpely-nameicon-dark.svg"
const Logo = ({ type = "light", isClickable = true }) => {
  return (
    type === "light" ?
      <Link to={isClickable ? "/" : ""} style={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}>
        <img src={sharpely_logo_light} alt="logo" style={{ height: '2rem' }} />
      </Link> :
      <Link to={isClickable ? "/" : ""} style={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}>
        <img src={sharpely_logo_dark} alt="logo" style={{ height: '2rem' }} />
      </Link>
  );
};

export default Logo;
