import React from 'react';

import InfiniteScroll from "react-infinite-scroll-component";

import { BsBookmark, BsPlusCircle, BsSearch } from 'react-icons/bs';
// import MintboxIcon from '../atoms/MintboxIcon';
// import { fetchFundData } from 'components/atoms/fetchFundData';
// import { OutlinedInput } from 'components/Component';
import AMCIcon from './AMCIcon';
import { ArrayScrollLoader } from './ArrayScrollLoader';
import { Card, Input } from 'antd';
import { fetchFundData } from 'Libs/fetchFundData';
import MintboxIcon from './MintboxIcon';

const RECORDS_PER_FETCH = 20;

export default function SearchScreen({
    navigation,
    searchText,
    rightIcon = "", type = 1,
    queryForFilter = "",
    stpFundCode = [],
    onCardClick = () => { },
    showViewDetails = false,
    viewDetailsAction = () => { },
    showPlusIcon = false,
    isDropOpen = false,
    plusIconAction = () => { }
}) {


    var textInp = React.createRef('')

    const loadData = async (type) => {
        let a = await fetchFundData(type, false)
        if (type === 3) {
            a = a.filter((i) => i.IsDisplay !== 0 && i.IsCustom === 0)
        }
        setmainData(a)
        fetchData(a).then(() => setloading(false));
    }

    React.useEffect(() => {
        loadData(type)
    }, [])



    // const [finalDatalist, setdatalist] = React.useState([]);
    // const [showMore, setShowMore] =React.useState(true);

    // const [loadingMore, setLoadingMore] = React.useState(false);

    // const [showSortByModal, setShowSortByModal] = React.useState(false);
    // const [animateModal, setanimateModal] = React.useState(true);


    const [mainData, setmainData] = React.useState([]);
    const [data, setData] = React.useState([]);
    const [text, setText] = React.useState('');
    const [searchdata, setsearchData] = React.useState([]);
    const [loading, setloading] = React.useState(true);
    const [dropOpen, setDropOpen] = React.useState(isDropOpen);


    const fetchData = async (arr, text = '') => {
        const response = await ArrayScrollLoader(arr, 25, true);

        if (type) {
            if (response !== 'done') {

                setData(response);

            } else {
                setData([])
            }

        } else {
            if (text !== '') {
                if (response !== 'done') {

                    setData(response);

                } else {
                    setData([])
                }
            } else {
                setData([])

            }
        }


    };

    const loadMoreFunds = async () => {
        if (text === '') {
            const response = await ArrayScrollLoader(mainData, 10);
            if (response !== 'done') {
                setData([...data, ...response]);
            }
        } else {
            const response = await ArrayScrollLoader(searchdata, 10);
            if (response !== 'done') {
                setData([...data, ...response]);
            }
        }

    };

    const filterSearch = (text) => {
        setDropOpen(true)
        setText(text)
        if (text.length > 0) {
            if (type === 3) {
                text = text.split(' ');
                var newData = mainData.filter(function (listItem) {
                    return text.every(function (el) {
                        return listItem.MintboxName.toLowerCase().indexOf(el.toLowerCase()) > -1;
                    });
                });
                setsearchData(newData);
                fetchData(newData, text)
            } else if (type === 1) {
                text = text.split(' ');
                var newData = mainData.filter(function (listItem) {
                    return text.every(function (el) {
                        return listItem.basic_name.toLowerCase().indexOf(el.toLowerCase()) > -1
                    });
                });
                setsearchData(newData);
                fetchData(newData, text)
            } else if (type === 11) {
                text = text.split(' ');
                var newData = mainData.filter(function (listItem) {
                    return text.every(function (el) {
                        return listItem.benchmark_name.toLowerCase().indexOf(el.toLowerCase()) > -1
                    });
                });
                setsearchData(newData);
                fetchData(newData, text)
            } else if (type === 4) {
                text = text.split(' ');
                var newData = mainData.filter(function (listItem) {
                    return text.every(function (el) {
                        return listItem.proper_name.toLowerCase().indexOf(el.toLowerCase()) > -1 || listItem.symbol.toLowerCase().indexOf(el.toLowerCase()) > -1;
                    });
                });
                setsearchData(newData);
                fetchData(newData, text)
            } else {
                text = text.split(' ');
                var newData = mainData.filter(function (listItem) {
                    return text.every(function (el) {
                        return listItem.basic_name.toLowerCase().indexOf(el.toLowerCase()) > -1;
                    });
                });
                setsearchData(newData);
                fetchData(newData, text)
            }
        } else {
            setsearchData([]);
            setData([])
        }
    };



    return (
        <div style={{
            width: "100%",
            zIndex: 998,
        }}>
            <div style={{ marginBottom: '0', }}>
                <Input ref={textInp} className='textSM' style={{ borderRadius: 8 }} size="large" placeholder={searchText} prefix={<BsSearch />} onFocus={() => {
                    setDropOpen(true)
                }} onBlur={() => {
                    setText('')
                    setTimeout(() => {
                        setDropOpen(false)
                    }, 200);
                }} onChange={(e) => { filterSearch(e.target.value) }} />
            </div>
            {data.length === 0 ? <div>
                {text.length === 0 ? null :
                    <div style={{ textAlign: "center", margin: "4%" }}> NO RESULT FOUND!!</div>}
            </div> : null}
            <div style={{ position: "relative" }}>
                {dropOpen ?
                    text.length === 0 && !dropOpen ?
                        <></>
                        :
                        <Card bordered={false} style={{ position: "absolute", zIndex: 998, flex: 1, width: "100%" }} bodyStyle={{ padding: '0rem' }}>
                            <InfiniteScroll
                                dataLength={data.length}
                                next={() => {
                                    loadMoreFunds()
                                }}
                                height={300}
                                hasMore={true}
                                loader={<h4></h4>}
                            >
                                {data.filter((i) => !stpFundCode.includes(i.isin_code)).map((item, index) => (
                                    <div
                                        key={index + "ak"}
                                        style={{ borderBottom: "0.5px solid #e5e5e5", borderTop: "none", display: "flex", gap: "1rem", padding: "0.5rem 1rem", alignItems: "center", justifyContent: "space-around" }}>
                                        <span onClick={() => onCardClick(item)} style={{ cursor: "pointer" }}>
                                            {
                                                type === 2 || type === 1 ? <AMCIcon height='24px' width='24px' amcName={item.amc_full_name} /> : type === 4 ? <AMCIcon className="mt-1" amcName={item.symbol} fundType={type} height='20px' width='20px' /> : type === 11 ? <></> :
                                                    <MintboxIcon height='24px' width='24px' id={item.MintboxId || Number(item.FundCode) || item.UID || item.mintbox_id || item.inv_MintboxId} />
                                            }
                                        </span>
                                        <div className='textSM' onClick={() => onCardClick(item)} style={{ flex: 1, whiteSpace: 'initial', cursor: "pointer" }}>
                                            {type === 3 ? item.MintboxName : type === 4 ? item.proper_name : type === 11 ? item.benchmark_name : item.basic_name}
                                        </div>
                                        {showPlusIcon && rightIcon === "" ? <BsPlusCircle onClick={() => {
                                            plusIconAction(item);
                                            setDropOpen(false)
                                            textInp.current.blur()
                                            setText('')
                                            setData([])
                                        }} size={15} className='secondaryGreen' style={{ background: "var(conservativeRisk)", borderRadius: "50%", margin: "auto", cursor: "pointer" }} /> : showPlusIcon && rightIcon === "watchlist" ? <BsBookmark onClick={() => {
                                            plusIconAction(item);
                                            setDropOpen(false)
                                            textInp.current.blur()
                                            setText('')
                                            setData([])
                                        }} size={15} className='secondaryGreen' style={{ margin: "auto", cursor: "pointer" }} /> : <></>}
                                    </div>
                                ))}
                            </InfiniteScroll>
                        </Card>
                    : <></>
                }
            </div>
        </div>
    );
}

