import { Card, Select } from 'antd';
import ActivityLoaderMintbox from 'Components/ActivityLoaderMintbox';
import AMCIcon from 'Components/AMCIcon';
import CustomListTable from 'Components/CustomListTable';
import { DecimalValueFormat } from 'Components/DecimalValueFormat';
import InstrumentListTable from 'Components/InstrumentListTable';
import Highcharts from 'highcharts';
import HighchartsHeatmap from "highcharts/modules/heatmap";
import Head from 'Layout/Head';
import db from 'Libs/db';
import { screenerMetricData, useGetAssetType } from 'Libs/utils';
import React, { useState } from 'react';
import { BsArrowRightShort } from 'react-icons/bs';
import { MdArrowDropDown } from 'react-icons/md';
import { TiDelete } from 'react-icons/ti';
import { useLocation, useNavigate } from 'react-router';
HighchartsHeatmap(Highcharts)
const ss = require('simple-statistics')
const d3 = require('d3')

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

const grid = 5;

const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    margin: `${grid}px`,
    marginBottom: 10,
    backgroundColor: '#252B3405',
    borderRadius: 5,
    ...draggableStyle
});

const getListStyle = (isDraggingOver) => ({
    background: "#fff",
    borderRadius: 5,
    // flex: 1,
});

function Quartile(data, q) {
    var pos = ((data.length) - 1) * q;
    var base = Math.floor(pos);
    var rest = pos - base;
    if ((data[base + 1] !== undefined)) {
        return data[base] + rest * (data[base + 1] - data[base]);
    } else {
        return data[base];
    }
}


const StockSectorsDetail = (props) => {

    const navigate = useNavigate()
    const { state } = useLocation()

    const [screenerMetric, setscreenerMetric] = useState([])
    const [screenerFinalData, setscreenerFinalData] = useState([])
    const [stockSectorCLF, setStockSectorCLF] = useState([])
    const [styleBoxes, setStyleBoxes] = React.useState([]);
    const [allFilteredFundsData, setallFilteredFundsData] = useState([])
    const [allFundsData, setallFundsData] = useState([])
    const [isOpen, setisOpen] = useState(0)
    const [isV1Open, setisV1Open] = useState(-1)
    const [isV2Open, setisV2Open] = useState(-1)
    const [screener_final_query, set_screener_final_query] = useState([])
    const [newFiltersList, setnewFiltersList] = useState([])
    const [mfCategories, setmfCategories] = useState([])
    const [mfAMC, setmfAMC] = useState([])
    const [stock_macro_sectors, setMacroSectors] = useState([])
    const [stock_sectors, setSectors] = useState([])
    const [stock_industries, setIndustries] = useState([])
    const [stock_basic_industries, setBasicIndustries] = useState([])

    const [def_stock_sectors, setDefSectors] = useState(state?.selectionData?.sector || '')
    const [def_stock_industries, setDefIndustries] = useState(state?.selectionData?.indgrp || '')
    const [def_stock_basic_industries, setDefBasicIndustries] = useState(state?.selectionData?.ind || '')

    const [clasificationData, setclasificationData] = useState(state?.clasificationData)

    console.log(state?.clasificationData)
    const [loading, setloading] = useState(true)

    const [investInScreenerModal, setinvestInScreenerModal] = useState(false)
    const [tableColumns, setTableColumns] = useState([])

    const type = useGetAssetType()


    const dataTableColumns = type.value === 4 ? [
        {
            "title": "Name",
            "dataIndex": "proper_name",
            render: (text, record, index) => <div style={{ display: 'flex', gap: '12px', cursor: "pointer" }} onClick={() => navigate({ pathname: type.basePath + `${type.value === 4 ? '/stock-detail/' + record.symbol : '/fund-detail/' + record?.basic_name?.replace(" - Direct Plan", "")?.replaceAll(' ', '-') + '?code=' + record.plan_id}` })}>
                <AMCIcon amcName={record.symbol} fundType={type.value} height='1.2rem' width="1.2rem" />
                <div>
                    <div className="textSM black">{record?.proper_name}</div>
                    <div className="text-left textXS ">{stockSectorCLF?.find(s => s.industry_code === record.industry_code)?.industry_desc}</div>
                </div>
            </div>,
            width: 230,
            fixed: 'left',
            show: true
        }
    ]
        :
        [
            {
                "title": "Name",
                "dataIndex": "basic_name",
                render: (text, record, index) => <div style={{ display: 'flex', gap: '12px', cursor: "pointer" }} onClick={() => navigate({ pathname: type.basePath + `${type.value === 4 ? '/stock-detail/' + record.symbol : '/fund-detail/' + record?.basic_name?.replace(" - Direct Plan", "")?.replaceAll(' ', '-') + '?code=' + record.plan_id}` })}>
                    <AMCIcon amcName={record.amc_full_name} height='1.2rem' width="1.2rem" />
                    <div>
                        <div className="textSM black">{record?.basic_name?.replace(" - Direct Plan", "")}</div>
                        <div className="text-left textXS ">{record?.category_name}</div>
                    </div>
                </div>,
                width: 230,
                fixed: 'left',
                show: true
            }
        ]

    const loadData = async () => {

        var screener_datas = await screenerMetricData(type)

        db.get(db.model.user).then(async (p) => {
            //const aa = JSON.parse(p);
            setisOpen(screener_datas?.levelVals?.level1)
            setisV1Open(screener_datas?.levelVals?.level2)
            setisV2Open(screener_datas?.levelVals?.level3)
            setStockSectorCLF(screener_datas?.fabricatedData?.stockSectorCLF)
            setStyleBoxes(screener_datas?.fabricatedData?.stockStyles)
            setMacroSectors(screener_datas?.fabricatedData?.macroSectors)
            setSectors([{ "key": "all", "label": "All sectors", "value": "" }]?.concat(screener_datas?.fabricatedData?.sectors))
            setIndustries([{ "key": "all", "label": "All Industry groups", "value": "" }]?.concat(screener_datas?.fabricatedData?.industries))
            setBasicIndustries([{ "key": "all", "label": "All Industries", "value": "" }]?.concat(screener_datas?.fabricatedData?.basicIndustries))
            setmfCategories(screener_datas?.fabricatedData?.mf_categories)
            setmfAMC(screener_datas?.fabricatedData?.mf_amcs)
            setscreenerMetric(screener_datas?.screenerMetricData)
            setscreenerFinalData(screener_datas?.screenerFinalData)
            setallFundsData(screener_datas?.allFunds)
            setallFilteredFundsData(screener_datas?.allFunds)
            setloading(false)
        })


    }

    React.useEffect(() => {
        loadData()
    }, [])

    const AppliedFilterView = () => (<Card bodyStyle={{ display: "flex", flexDirection: "column", gap: "1.5rem", paddingTop: 0, paddingBottom: 0 }}>
        <div className='my-2' style={{ alignItems: "center" }}>
            <div className='mt-3 pb-3' style={{ borderBottom: "0.5px solid #e5e5e5" }}>
                <Select removeIcon={<TiDelete size={12} />}
                    className='textSM black'
                    // style={{
                    //     width: "250px"
                    // }}
                    bordered={false}
                    defaultValue={def_stock_sectors}
                    suffixIcon={<MdArrowDropDown size={17} />}
                    placeholder="Please select weighing method for stocks"
                    onChange={(value) => {
                        setDefSectors(value)
                        setDefIndustries('')
                        setDefBasicIndustries('')
                    }}
                    options={stock_sectors}
                />
                <BsArrowRightShort className='mx-3' />
                <Select removeIcon={<TiDelete size={12} />}
                    className='textSM black'
                    // style={{
                    //     width: "250px"
                    // }}
                    bordered={false}
                    suffixIcon={<MdArrowDropDown size={17} />}
                    defaultValue={def_stock_industries}
                    placeholder="Please select weighing method for stocks"
                    onChange={(value) => {
                        setDefIndustries(value)
                    }}
                    options={stock_industries.filter((i) => String(i.value).includes(def_stock_sectors) || i.value === "")}
                />
                <BsArrowRightShort className='mx-3' />
                <Select removeIcon={<TiDelete size={12} />}
                    className='textSM black'
                    // style={{
                    //     width: "250px"
                    // }}
                    bordered={false}
                    suffixIcon={<MdArrowDropDown size={17} />}
                    defaultValue={def_stock_basic_industries}
                    placeholder="Please select weighing method for stocks"
                    onChange={(value) => {
                        setDefBasicIndustries(value)
                    }}
                    options={stock_basic_industries.filter((i) => (String(i.value).includes(def_stock_sectors) && String(i.value).includes(def_stock_industries)) || i.value === "")}
                />
            </div>
            {def_stock_sectors.length > 0 &&
                <div className='my-3 mb-2 mx-2' style={{}}>
                    <div className='textSM w-500'>Median statistics</div>
                    <div className='mt-1' style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <Card bordered={false} className='me-2' bodyStyle={{ paddingTop: 0, paddingBottom: 0 }} style={{ paddingTop: 5, paddingBottom: 5 }}>
                            <span className='textSM'>Median PE ratio: <span className='textSM w-500 ms-2'>{
                                DecimalValueFormat({
                                    num: def_stock_basic_industries.length > 0 ? clasificationData['ind']?.filter((i) => i.basic_industry_code === def_stock_basic_industries)[0]?.med_pe :
                                        def_stock_industries.length > 0 ? clasificationData['indgrp']?.filter((i) => i.industry_code === def_stock_industries)[0]?.med_pe : clasificationData['sector']?.filter((i) => i.sector_code === def_stock_sectors)[0]?.med_pe,
                                    placeholder: "-",
                                    decimals: 2
                                })
                            }</span></span>
                        </Card>
                        <Card bordered={false} className='me-2' bodyStyle={{ paddingTop: 0, paddingBottom: 0 }} style={{ paddingTop: 5, paddingBottom: 5 }}>
                            <span className='textSM'>Median PB ratio: <span className='textSM w-500 ms-2'>{
                                DecimalValueFormat({
                                    num: def_stock_basic_industries.length > 0 ? clasificationData['ind']?.filter((i) => i.basic_industry_code === def_stock_basic_industries)[0]?.med_pb :
                                        def_stock_industries.length > 0 ? clasificationData['indgrp']?.filter((i) => i.industry_code === def_stock_industries)[0]?.med_pb : clasificationData['sector']?.filter((i) => i.sector_code === def_stock_sectors)[0]?.med_pb,
                                    placeholder: "-",
                                    decimals: 2
                                })
                            }</span></span>
                        </Card>
                        <Card bordered={false} className='me-2' bodyStyle={{ paddingTop: 0, paddingBottom: 0 }} style={{ paddingTop: 5, paddingBottom: 5 }}>
                            <span className='textSM'>Median dividend yield: <span className='textSM w-500 ms-2'>{
                                DecimalValueFormat({
                                    num: def_stock_basic_industries.length > 0 ? clasificationData['ind']?.filter((i) => i.basic_industry_code === def_stock_basic_industries)[0]?.med_div_yield :
                                        def_stock_industries.length > 0 ? clasificationData['indgrp']?.filter((i) => i.industry_code === def_stock_industries)[0]?.med_div_yield : clasificationData['sector']?.filter((i) => i.sector_code === def_stock_sectors)[0]?.med_div_yield,
                                    placeholder: "-",
                                    decimals: 2
                                })
                            }</span></span>
                        </Card>
                    </div>
                </div>}
        </div>
    </Card >
    )


    if (loading) {
        return <React.Fragment>
            <div className='mainContainer' style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
                <ActivityLoaderMintbox />
            </div>
        </React.Fragment>
    } else {
        return (
            <React.Fragment>
                <Head title="StockSectorsDetail" />
                <div className='normalContainer'>
                    <AppliedFilterView />
                    <InstrumentListTable
                        tableType={0}
                        // initialColumns={[
                        //     ...dataTableColumns,
                        //     ...screenerMetric.filter((item) => item.isInitial === true),
                        //     ...tableColumns
                        // ]}
                        defFilters={[
                            ["sector_code", def_stock_sectors],
                            def_stock_industries.length > 0 && ["industry_code", def_stock_industries],
                            def_stock_basic_industries.length > 0 && ["nse_basic_ind_code", def_stock_basic_industries]
                        ]?.filter((i) => i !== false)}
                        // is_Open={isOpen}
                        // is_V1Open={isV1Open}
                        // is_V2Open={isV2Open}
                        tableHeader={<span>Filtered {type.dname}</span>}
                        // screenerFinalData={screenerFinalData}
                        investInScreener={() => {
                            setinvestInScreenerModal(!investInScreenerModal)
                        }}
                    />
                </div>
            </React.Fragment >
        );
    }

}

export default StockSectorsDetail