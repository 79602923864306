import React from 'react';
import { toast } from 'react-toastify';
import db from 'Libs/db';
import { Link, useNavigate } from 'react-router-dom';
import AdminServices from 'Services/api/AdminServices';
import Logo from 'Layout/Logo';
import { Button, Card, Form, Input } from 'antd';
import { useDispatch } from 'react-redux';
import { toggleloginModal, updateUserData } from 'Libs/redux/UserAuth/reduxSlice';

export default function Login(props) {
  const [email, setEmail] = React.useState('');
  const [userData, setuserData] = React.useState({});
  const [otp, setOTP] = React.useState('');
  const [mainOTP, setmainOTP] = React.useState('');
  const [nextBtnPressed, setNextBtnPressed] = React.useState(false)

  const navigate = useNavigate()
  const dispatch = useDispatch();

  const cyrb53 = (str, seed = 0) => {
    let h1 = 0xdeadbeef ^ seed, h2 = 0x41c6ce57 ^ seed;
    for (let i = 0, ch; i < str.length; i++) {
      ch = str.charCodeAt(i);
      h1 = Math.imul(h1 ^ ch, 2654435761);
      h2 = Math.imul(h2 ^ ch, 1597334677);
    }
    h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^ Math.imul(h2 ^ (h2 >>> 13), 3266489909);
    h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^ Math.imul(h1 ^ (h1 >>> 13), 3266489909);
    return 4294967296 * (2097151 & h2) + (h1 >>> 0);
  };

  const checkOTP = () => {
    if (Number(otp) === Number(mainOTP)) {
      console.log("heyey")
      try {
        db.set(db.model.user, JSON.stringify({ userData }));
        dispatch(updateUserData(userData))
        dispatch(toggleloginModal(false))
        navigate("/etfs/dashboard")
        // localStorage.setItem("isAdmin", cyrb53("yesitisamintboxadmin"))
        // props.history.push("/dashboard")
      } catch (error) {
        console.log(error)
      }
    } else {
      toast.error("Incorrect OTP");
    }
  }

  const sendOTP = async (id) => {
    console.log("ASDFASF", id)
    try {
      setmainOTP('')
      const res = await AdminServices.login({ id: id }).then(res => res)
      console.log(res)
      if (res.type) {
        setNextBtnPressed(true);
        console.log("hey")
        setmainOTP(res.data.otpcode);
      } else {
        toast.error("Try again ...");
      }
    } catch (error) {
      console.log(error)
      toast.error("Try again ...");
    }
  };


  const onNextBtnPressed = async () => {
    setNextBtnPressed(true);
    try {
      const res = await AdminServices.ClientExists({ type: "email", value: email }).then((res) => res);
      if (res.type) {
        console.log("client exists API ", res.data)
        if (res.data.IFAId !== '') {
          setuserData({
            name: res.data.ClientName,
            email: res.data.Email,
            mobile: res.data.MobileNumber,
            id: res.data.ClientId,
            isLogged: true,
          });
          // console.log(userData)
          sendOTP(res.data.ClientId)
        } else {
          setNextBtnPressed(false);
          toast.error("Not a mintbox registered IFA");
        }
      } else {
        toast.warn("Try again ...");
        setNextBtnPressed(false);
      }
    } catch (error) {
      toast.warn("Try again ...");
      console.log("DSFDSFsda", error)
      setNextBtnPressed(false);
    }
  };


  return (
    <div style={{ display: "flex", flex: 1, justifyContent: "center" }}>
      <Card bordered={false} style={{ minWidth: "30vw", padding: "1.5rem" }}>
        <Logo type='dark' isClickable={false} />
        <div className='fontH1 w-500 mt-4 my-2'>Hello! let's get started</div>
        <div className="fontH2 my-2">Sign in to continue.</div>
        <Form
          className='mt-4 mb-2'
          name="basic"
          initialValues={{ remember: true, }}
          size="large"
          autoComplete="off">
          {!nextBtnPressed ?
            <Form.Item name="email" rules={[{ type: 'email', },]}>
              <Input placeholder='Enter your email address' onChange={(e) => {
                setEmail(e.target.value)
              }} />
            </Form.Item>
            :
            <Form.Item name="otp">
              <Input.Password placeholder='Enter OTP' style={{ letterSpacing: 4 }} maxLength={6} onChange={(e) => {
                setOTP(e.target.value)
              }} />
            </Form.Item>
          }
        </Form>
        <button style={{ width: "100%" }} onClick={nextBtnPressed ? () => checkOTP() : () => onNextBtnPressed()} type='submit' className={"mx-1 btnPrimary"}>
          {nextBtnPressed ? "Sign in" : "Send OTP"}
        </button>
        <div className="text-center mt-4 fontH2">
          New to sharpely? <Link to="/signup" className='mx-2'>Register</Link>
        </div>
      </Card>
    </div>
  )
}

