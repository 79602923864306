function formatDate(d, skip = "", seperator = " ") {
    var date = "";
    // console.log(d)
    try {
        date = new Date(d.replace(" ", "T"));
    } catch (error) {
        date = new Date(d);
    }

    if (isNaN(date.getTime())) {
        return d;
    }
    else {

        var month = new Array();
        month[0] = "Jan";
        month[1] = "Feb";
        month[2] = "Mar";
        month[3] = "Apr";
        month[4] = "May";
        month[5] = "Jun";
        month[6] = "Jul";
        month[7] = "Aug";
        month[8] = "Sep";
        month[9] = "Oct";
        month[10] = "Nov";
        month[11] = "Dec";

        let day = date.getDate();

        if (day < 10) {
            day = "0" + day;
        }

        var finalStr = skip === "date" ? month[date.getMonth()] + seperator + date.getFullYear() : day + seperator + month[date.getMonth()] + seperator + date.getFullYear()
        return finalStr;
    }

}

function convertAnyDatetoDDMMYYYY(d, type = "") {
    var month = new Array();
    month[0] = "Jan";
    month[1] = "Feb";
    month[2] = "Mar";
    month[3] = "Apr";
    month[4] = "May";
    month[5] = "Jun";
    month[6] = "Jul";
    month[7] = "Aug";
    month[8] = "Sep";
    month[9] = "Oct";
    month[10] = "Nov";
    month[11] = "Dec";



    if (type === "monStr") {
        var mon = ""
        for (let i = 0; i < d.length; i++) {
            if (d[i].match(/[a-zA-Z]/)) {
                mon += d[i];
            }
        }
        var monN = month.indexOf(mon) + 1
        d = d.replace(mon, monN.toString().length === 1 ? 0 + monN.toString() : monN.toString()).replaceAll(" ", "-")
        return d
    } else if (type === "yyyymmdd") {
        var today = new Date(d)
        const yyyy = today.getFullYear();
        let mm = today.getMonth() + 1; // Months start at 0!
        let dd = today.getDate();

        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;

        return yyyy + '-' + mm + '-' + dd;
    } else {
        var today = new Date(d)
        const yyyy = today.getFullYear();
        let mm = today.getMonth() + 1; // Months start at 0!
        let dd = today.getDate();

        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;

        return dd + '-' + mm + '-' + yyyy;

    }


}


function formatDateddmmyyyy(today) {
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();

    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;

    return dd + '/' + mm + '/' + yyyy;
}

function formatDateyyyymmdd(today) {
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();

    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;

    return yyyy + '-' + mm + '-' + dd;
}

function addMonths(date, months) {
    var a = date
    a.setMonth(a.getMonth() + months);
    return a;
}

function addWeek(date, week) {
    var a = date
    a.setDate(a.getDate() + (week * 7));
    return a;
}

function getNextSIPDate(sipDate, freq = 1) {
    if (freq !== 1) {
        return formatDate(new Date(addMonths(new Date(new Date().getFullYear(), new Date().getMonth(), sipDate), freq)).toISOString())
    }
    const date1 = new Date();
    const date2 = new Date(addMonths(new Date(new Date().getFullYear(), new Date().getMonth(), sipDate), 1));
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    if (diffDays < 27) {
        return formatDate(new Date(addMonths(new Date(new Date().getFullYear(), new Date().getMonth(), sipDate), 2)).toISOString())
    } else {
        return formatDate(new Date(addMonths(new Date(new Date().getFullYear(), new Date().getMonth(), sipDate), 1)).toISOString())
    }
}

function monthDiff(d1, d2) {
    var months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
}

function dayDiff(d1, d2) {
    // console.log(d1, d2)
    var diff = d2 - d1;
    var daydiff = diff / (1000 * 60 * 60 * 24);
    return daydiff;
}



export { formatDate, formatDateddmmyyyy, formatDateyyyymmdd, getNextSIPDate, convertAnyDatetoDDMMYYYY, monthDiff, addMonths, addWeek, dayDiff }