import { DataService } from "./DataService";
import { DataServiceOLDAPI } from "./DataServiceOLDAPI";

const MINTBOX_CLIENT_ID = "Li2L9VO1eawEbsgLrHdpZjhmUdW6N8Cm";

const FamilyMemberAPIs = {
  addMember: async (id, email) =>
    DataService.post(`/dashboard/addMember?clientId=${id}&email=${email}`),
  removeMember: async (id, email) =>
    DataService.post(`/dashboard/removeMember?clientId=${id}&email=${email}`),
};

export default {
  FamilyMemberAPIs,
  addBankAccount: async (data) =>
    DataService.post("/SignUp/addBankAccount", data),
  updateBankAccount: async (data) =>
    DataService.post("/SignUp/updateBankAccount", data),

  getAccessToken: async () => {
    return DataService.get("/Auth/getToken?clientId=" + MINTBOX_CLIENT_ID);
  },

  getClient: async ({ id }) => {
    return DataService.get("/Dashboard/getClient?clientid=" + id);
  },

  emailExist: async (id, data, optionalHeader) => {
    return DataService.get(
      "/SignUp/EmailExists?email=" + id,
      data,
      optionalHeader
    );
  },

  ClientExists: async ({ type, value }) => {
    return DataService.get(
      "/SignUp/ClientExists?type=" + type + "&value=" + value
    );
  },

  loginwithPass: async (data) => {
    return DataService.post("/SignUp/login", data);
  },
  validatePin: async (data) => {
    return DataService.post("/SignUp/validatePIN", data);
  },

  sendOTP: async (type, value) => {
    return DataService.post(
      "/SignUp/generateOTP?type=" + type + "&value=" + value
    );
  },

  login: async ({ id }) => {
    return DataService.post(
      "/SignUp/login?clientId=" + id
    );
  },

  forgotPass: async (data) => {
    return DataService.post("/SignUp/generatePasswordLink", data);
  },

  getRiskScore: async (data) => {
    return DataService.post("/Dashboard/getRiskScore", data);
  },

  updateGoal: async (data) => {
    return DataService.post("/Dashboard/addGoal", data);
  },

  getDashboardData: async () => {
    return DataService.get(
      "/Dashboard/getDashboardContent"
    );
  },

  getRecentlyViewed: async (id) => {
    return DataService.get(
      "/Dashboard/getRecentlyViewed?clientId=" + id
    );
  },


  getUserScreeners: async ({ id, type }) => {
    return DataService.get(
      `/Dashboard/getScreeners?clientId=${id}&instrumentType=${type}`
    );
  },

  getUserStrategies: async ({ id }) => {
    return DataService.get(
      "/Dashboard/getWatchListInfo?clientId=" + id
    );
  },

  getUserSignal: async ({ id }) => {
    return DataService.get(
      "/Dashboard/getSignals?clientId=" + id
    );
  },

  getWatchList: async ({ id }) => {
    return DataService.get(
      "/Dashboard/getWatchListInfo?clientId=" + id
    );
  },

  updateWatchList: async (data) => {
    if (data.isActive === 1)
      return DataService.post("/Dashboard/addToWatchlist", data);
    else
      return DataService.post("/Dashboard/removeFromWatchlist", data);
  },


  updateRecentlyViewed: async (data) => {
    return DataService.post("/Dashboard/updateRecentlyViewed", data);
  },

  getSampleData: async () => {
    return DataService.post("/bse/getNiftyIndices?ticker=NIFTY 50")
  },

  getPortfolioHoldingsData: async (id, ifaid, isFresh) => {
    return DataService.get(
      `/portfolio/getPortfolioHoldings?clientId=${id}&ifaId=${ifaid}&isFresh=${isFresh}`
    );
  },

  getPortfolioScheduledOrders: async (id, ifaid, isFresh) => {
    return DataService.get(
      `/dashboard/getScheduledOrders?clientId=${id}`
    );
  },

  getPortfolioSnapshot: async (id, ifaid, isFresh) => {
    return DataService.get(
      `/portfolio/getPortfolioSnapshot?clientId=${id}&ifaId=${ifaid}&isFresh=${isFresh}`
    );
  },

  getPortfolioXIRR: async (id, isFresh) => {
    return DataService.get(
      `/portfolio/getportfolioXIRRv1?clientId=${id}&isFresh=${isFresh}`
    );
  },

  getPortfolioPerformance: async (id, isFresh) => {
    return DataService.get(
      `/portfolio/getPortfolioPerformancev1?clientId=${id}&isFresh=${isFresh}`

    );
  },

  getPortfolioAsset: async (id, isFresh) => {
    return DataService.get(
      `/portfolio/getPortfolioAssets?clientId=${id}&isFresh=${isFresh}`

    );
  },

  getPortfolioInstruments: async (id, isFresh) => {
    return DataService.get(
      `/portfolio/getPortfolioInstruments?clientId=${id}&isFresh=${isFresh}`

    );
  },

  getPortfolioTransactions: async (id, isFresh) => {
    return DataService.get(
      `/portfolio/getTransactions?clientId=${id}&isFresh=${isFresh}`

    );
  },

  getMintboxFunds: async (userid, mintboxid) => {
    return DataService.get(`/portfolio/getMintboxPortfolio?clientId=${userid}&mintboxId=${mintboxid}`)
  },

  getFundsData: async () => {
    return DataService.get(
      "/dashboard/getFundsMaster?appId=" + MINTBOX_CLIENT_ID
    );
  },

  getFundsDetails: async (id) => {
    return DataService.get(
      "/dashboard/getFundByFundCode?fundCode=" + id
    );
  },

  getFundOneDayPrices: async ({ id, isIndex = 0 }) => {
    return DataService.get(
      `/BSE/getLivePricesBySymbol?ticker=${id}&isIndex=${isIndex}`
    );
  },

  getEtfDetails: async (id) => {
    return DataService.get(
      "/dashboard/getETFInfoBySymbol?symbol=" + id
    );
  },

  getPeerReturns: async (id) => {
    return DataService.get(
      "/dashboard/getPeerReturnsByFundCode?fundCode=" + id
    );
  },

  getTopHoldings: async (id) => {
    return DataService.get(
      "/dashboard/getCompaniesByFundCode?fundCode=" + id
    );
  },

  getSectorHoldings: async (id) => {
    return DataService.get(
      "/dashboard/getFundSectorsByFundCode?fundCode=" + id
    );
  },

  getEtfPeer: async (id) => {
    return DataService.get(
      "/dashboard/getETFPeers?symbol=" + id
    );
  },

  getRollingReturns: async (id) => {
    return DataService.get(
      "/dashboard/getRollingReturnsByFundCode?fundCode=" + id
    );
  },

  getETFs: async () => {
    return DataService.get(
      "/Dashboard/getETFs"
    );
  },

  getStocks: async () => {
    return DataService.get(
      "/Dashboard/getStocks"
    );
  },

  getETFsById: async (id) => {
    return DataService.get(
      "/Dashboard/getETFs?symbols=" + id
    );
  },


  getXirr: async (data) => {
    return DataService.post(
      "/Dashboard/getXIRR", data
    );
  },

  getMintboxes: async ({ clientId }) => {
    return DataService.get(
      "/Mintbox/getAllMintboxesv1?clientId=" + clientId
    );
  },

  getMintboxData: async ({ id }) => {
    return DataService.get(
      `/Mintbox/getMintboxById?mintboxId=${id}`
    );
  },

  getMintboxConstituentsById: async (id) => {
    return DataService.get(
      "/Mintbox/getMintboxConstituentsById?mintboxId=" + id
    );
  },

  getMintboxExecParams: async (id) => {
    return DataService.get(
      "/Mintbox/getMintboxExecParams?mintboxId=" + id
    );
  },

  getPortfolioExecParams: async (id) => {
    return DataService.get(
      "/Order/getPortfolioExecParams" + id
    );
  },


  getETFQuantities: async (data) => {
    return DataService.post(
      "/StrategyAutomation/getFundQuantity", data
    );
  },

  createMintbox: async (data) => {
    return DataService.post("/Mintbox/insertMintbox", data)
  },

  savePass: async (data) => {
    return DataService.post("/SignUp/savePassword", data);
  },

  createUser: async (data) => {
    return DataService.post("/SignUp/openAccount", data);
  },

  uploadDoc: async (id, data) => {
    console.log(data)
    return DataService.postImg("/SignUp/saveDocument?id=" + id, data);
  },

  enableMf: async (data) => {
    return DataService.post("/Dashboard/activateMutualFunds?email=" + data);
  },

  completeprofile: async (data) => {
    return DataService.post("/SignUp/completeAccount?callBSE=True", data);
  },


  saveMFPIN: async (id, pin) => {
    return DataService.post(`/SignUp/savePin?clientId=${id}&pin=${pin}`);
  },



  checkkyc: async (data) => {
    return DataServiceOLDAPI.post("/Signzy/checkKYC", data);
  },

  verifyZip: async (id) => {
    return DataService.get("/SignUp/getInfoByZip?zip=" + id);
  },

  verifyifsc: async (id) => {
    return DataService.get("/SignUp/getBankByIFSC?ifsc=" + id);
  },

  getTaxStatus: async () => {
    return DataService.get("/bse/getTaxStaus");
  },

  getAnnualIncome: async () => {
    return DataService.get("/bse/getAnnualIncome");
  },

  getCountries: async () => {
    return DataService.get("/bse/getCountries");
  },

  getIdentificationType: async () => {
    return DataService.get("/bse/getIdentificationType");
  },

  getOccupation: async () => {
    return DataService.get("/bse/getOccupation");
  },

  getSourceofWealth: async () => {
    return DataService.get("/bse/getSourceofWealth");
  },

  getStates: async () => {
    return DataService.get("/bse/getStates");
  },

  getBanks: async () => {
    return DataService.get("/bse/getbanks");
  },

  createCustomGoal: async (data) => {
    return DataService.post("/Dashboard/addGoal", data);
  },
  addRisk: async (data) => {
    return DataService.post("/Dashboard/addRisk", data);
  },
  addEducationGoal: async (data) => {
    return DataService.post("/Dashboard/addEducationGoal", data);
  },
  addRetirmentGoal: async (data) => {
    return DataService.post("/Dashboard/addRetirementGoal", data);
  },


  // smallcase 

  getSmallcasetoken: async (authid) => {
    return DataService.get("/SmallCase/getToken?authId=" + authid);
  },


  saveSmallCaseAuthId: async (data) => {
    return DataService.post("/SmallCase/saveAuthId", data);
  },

  saveSmallCaseOrder: async (id, data) => {
    return DataService.post("/SmallCase/saveOrderResponse?clientId=" + id, data);
  },


  saveTransactionLogs: async (data) => {
    return DataService.post("/Feeds/logtransaction", data);
  },


  getSmallcaseTransactionId: async (data, authId = "", mintboxId = 0, goalId = 0) => {
    return DataService.post(`/SmallCase/getTransactionId?authId=${authId}&mintboxId=${mintboxId}&goalId=${goalId}`, data);
  },


  getHoldings: async (id, authId = "") => {
    return DataService.get(`/SmallCase/fetchHoldings?clientId=${id}&authId=${authId}`);
  },

  getGoalByID: async (goalID) => {
    return DataService.get(`/Dashboard/getGoal?goalId=${goalID}`);
  },

  getClientGoals: async (ClientId) => {
    return DataService.get(`/Dashboard/getClientGoals?ClientId=${ClientId}`);
  },

  updateHoldings: async (id, authId = "") => {
    return DataService.post(`/portfolio/updateHoldings?clientId=${id}`);
  },


  portfolioDecrypt: async (encryptedString) => {
    return DataService.get(`/Portfolio/decrypt?encryptedString=${encryptedString}`);
  },

  rebalanceMintbox: async (clientId, mintboxId, goalID) => {
    return DataService.get(`/mintbox/getMintboxRebalanceData?clientId=${clientId}&MintboxId=${mintboxId}&GoalId=${goalID}`);
  },

  getScheduledSIPData: async (clientId, mintboxId, goalID) => {
    return DataService.get(`/mintbox/getScheduledSIPData?clientId=${clientId}&MintboxId=${mintboxId}&GoalId=${goalID}`);
  },

  getScheduledETFSIPData: async (clientId, mintboxId, fundcode) => {
    return DataService.get(`/mintbox/getScheduledSIPData?clientId=${clientId}&MintboxId=${mintboxId}&fundCode=${fundcode}`);
  },


  updateScheduledOrders: async (data) => {
    return DataService.post(`/Order/updateScheduledOrder`, data);
  },

  updateGoalName: async (goalID, goalName) => {
    return DataService.post(`/Dashboard/updateGoalName?goalId=${goalID}&goalName=${goalName}`);
  },


  sendPushNotification: async (data) => {
    return DataService.post(`/Notification/sendPushNotification`, data);
  },

  getPushNotifications: async (id) => {
    return DataService.get(`/Notification/getNotificationsByClient?clientId=` + id);
  },


  getPortfolioReport: async (id) => {
    return DataService.get(`/Reports/getPortfolioReport?clientId=` + id);
  },

  getTransactionReport: async (id) => {
    return DataService.get(`/Reports/getTransactionReport?clientId=` + id);
  },

  deactivateAccount: async (id) => {
    return DataService.post(`/Signup/deleteAccount?clientId=` + id);
  },

  getRewardsStatus: async (id) => {
    return DataService.get(`/Dashboard/getRewardsStatus?clientId=` + id);
  },


  getMFCategories: async () => {
    return DataService.get(
      "/Dashboard/getFundCategories"
    );
  },

  getStockStyles: async () => {
    return DataService.get(
      "/Dashboard/getStockStyles"
    );
  },

  getStockSectorCLF: async () => {
    return DataService.get(
      "/Dashboard/getStockSectorCLF"
    );
  },

  getScreenerCategory: async (type) => {
    return DataService.get(
      "/Dashboard/getScreenerCategory?type=" + type
    );
  },
  getScreenerSubCategory: async (type) => {
    return DataService.get(
      "/Dashboard/getScreenerSubCategory?type=" + type
    );
  },
  getScreenerMetric: async (type) => {
    return DataService.get(
      "/Dashboard/getScreenerMetric?type=" + type
    );
  },

  getAMCs: async () => {
    return DataService.get(
      "/Dashboard/getAMCs"
    );
  },

  getMFScreenerFunds: async () => {
    return DataService.get(
      "/Dashboard/getScreenerFunds"
    );
  },


  callVrApi: async (data) => {
    return DataService.post(
      "/ValueResearch/updateTables", data
    );
  },


  createMFOrder: async (data) => {
    return DataService.post(
      "/BSE/order", data
    );
  },


  subscribeToBlack: async (data) => {
    return DataService.post(
      "/Razorpay/createSubscription", data
    );
  },

  getBenchmarks: async () => {
    return DataService.get(
      "/dashboard/getBenchmarks"
    );
  },


  saveScreener: async (data) => {
    return DataService.post(
      "/Dashboard/addScreener", data
    );
  },


  saveSignal: async (data) => {
    return DataService.post(
      "/Dashboard/addSignal", data
    );
  },

  saveScreenerRule: async (data) => {
    return DataService.post(
      "/Dashboard/addScreenerRule", data
    );
  },

  getStockPrevPrices: async (isFresh) => {
    return DataService.get(
      `/BSE/getRecentPrices?isFresh=${isFresh}`
    );
  },


  //   updateComment: async (data) => {
  //     return DataService.post('/comment/update', data);
  //   },

  //   getComment: async (data) => {
  //     return DataService.post('/comment/listing', data);
  //   },

  //   getHomeBlockDiscover: async (data) => {
  //     return DataService.post('/block/home', data);
  //   }

  //knowledgebase
  getBlogCategories: async () => {
    return DataService.get(`/Content/getBlogCategories`);
  },
  getBlogSubCategories: async () => {
    return DataService.get(`/Content/getBlogSubCategories`);
  },
  getBlogs: async () => {
    return DataService.get(`/Content/getBlogs`)
  },
  readBlogFile: async (fileName) => {
    return DataService.get(`/AWS/readFile?bucketName=mintbox-assets&folderName=blogs&fileName=` + fileName)
  }
};
