import { Card, Cascader, DatePicker, Divider, Drawer, Input, InputNumber, Modal, Popover, Segmented, Select, Switch, Table, Tabs, Tag, Timeline } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import ActivityLoaderMintbox from 'Components/ActivityLoaderMintbox';
import { CardHeader } from 'Components/atoms/SmallComponents';
import { CurrencyFormat } from 'Components/CurrencyFormat';
import { changeObjKey } from 'Components/DataCustomizer';
import { convertAnyDatetoDDMMYYYY, formatDate } from 'Components/DateFormatter';
import { DecimalValueFormat } from 'Components/DecimalValueFormat';
import InstrumentListTable from 'Components/InstrumentListTable';
import PerformanceChart2 from 'Components/PerformanceChart2';
import RedGreenText from 'Components/RedGreenText';
import dayjs from 'dayjs';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsHeatmap from "highcharts/modules/heatmap";
import Head from 'Layout/Head';
import { toggleloginModal } from 'Libs/redux/UserAuth/reduxSlice';
import { displayValue, investingStylelist, perFilterValues, queries, risk_box_codes, screenerMetricData, stock_universe, useGetAssetType } from 'Libs/utils';
import BuildCustomPortfolio from 'Pages/CustomPortfolio';
import React, { useState } from 'react';
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { FaFilter } from 'react-icons/fa';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { HiLockClosed, HiLockOpen } from 'react-icons/hi';
import { IoIosArrowForward, IoIosClose, IoIosCloseCircleOutline } from 'react-icons/io';
import { MdArrowDropDown, MdDragIndicator } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { Col, Collapse, Row } from 'reactstrap';
import apiHelper from 'Services/api/ApiHelper';
import apiHelperPY from 'Services/api/ApiHelperPY';
HighchartsHeatmap(Highcharts)
const ss = require('simple-statistics')
const d3 = require('d3')

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

const grid = 5;

const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    // margin: `${grid}px`,
    margin: "0.5rem 0rem",
    // backgroundColor: '#252B3405',
    borderRadius: 5,
    ...draggableStyle
});

const getListStyle = (isDraggingOver) => ({
    // background: "#fff",
    borderRadius: 5,
    display: "flex",
    flexDirection: "column",
    // gap: "0.5rem"
    // flex: 1,
});

function Quartile(data, q) {
    var pos = ((data.length) - 1) * q;
    var base = Math.floor(pos);
    var rest = pos - base;
    if ((data[base + 1] !== undefined)) {
        return data[base] + rest * (data[base + 1] - data[base]);
    } else {
        return data[base];
    }
}


const Signal = (props) => {

    const navigate = useNavigate()
    const dispatch = useDispatch();

    const [saveAsChecklist, setsaveAsChecklist] = useState(true)
    const [alertsEnabled, setalertsEnabled] = useState(true)

    const [screenerMetric, setscreenerMetric] = useState([])
    const [screenerFinalData, setscreenerFinalData] = useState([])
    const [stockSectorCLF, setStockSectorCLF] = useState([])
    const [styleBoxes, setStyleBoxes] = React.useState([]);
    const [allFilteredFundsData, setallFilteredFundsData] = useState([])
    const [allFundsData, setallFundsData] = useState([])

    const [selectedCategory, setselectedCategory] = useState("")
    const [selectedSubCategory, setselectedSubCategory] = useState("")
    const [selectedMetric, setselectedMetric] = useState("")

    const sortOptions = [
        {
            value: 'top',
            queryStr: 'top',
            label: 'Higher the better',
        },
        {
            value: 'bot',
            queryStr: 'bot',
            label: 'Lower the better',
        }
    ]

    const { state } = useLocation()

    console.log(state?.signalData)
    var currentCheckListId = state?.signalData?.[0]?.checklist_id || 0
    var currentCheckListName = state?.signalData?.[0]?.signal_name || ""
    var currentCheckListDesc = state?.signalData?.[0]?.signal_desc || ""

    const [signalData, set_screener_final_query] = useState(state?.signalData || [])
    const [screener_name, setscreener_name] = useState(currentCheckListName)
    const [screener_desc, setscreener_desc] = useState(currentCheckListDesc)
    const [newFiltersScoreList, setnewFiltersScoreList] = useState([])
    const [tableLoading, setTableLoading] = useState(true)

    const [newFiltersList, setnewFiltersList] = useState([])
    const [checkFiltersList, setcheckFiltersList] = useState([])
    const [mfCategories, setmfCategories] = useState([])
    const [mfAMC, setmfAMC] = useState([])
    const [stock_macro_sectors, setMacroSectors] = useState([])
    const [stock_sectors, setSectors] = useState([])
    const [stock_industries, setIndustries] = useState([])
    const [stock_basic_industries, setBasicIndustries] = useState([])
    const [loading, setloading] = useState(true)

    const [modal, setmodal] = useState(false)
    const [modalSave, setmodalSave] = useState(false)
    const [backTestModal, setbackTestModal] = useState(false)
    const [investInScreenerModal, setinvestInScreenerModal] = useState(false)
    const [backTestModalLoading, setbackTestModalLoading] = useState(true)
    const [backTestData, setBackTestData] = useState({})
    const [metricbackTestRes, setMetricbackTestRes] = useState({})
    const [metricbackTestResName, setMetricbackTestResName] = useState('')
    const [toggleMetricBackTest, settoggleMetricBackTest] = useState(false)
    const [tableColumns, setTableColumns] = useState([])
    const [appliedMetrics, setappliedMetrics] = useState([])
    const [instrumentList, setinstrumentList] = React.useState([]);

    const [selectedStockUniverse, setselectedStockUniverse] = useState([])
    const [selectedStockUniverseMetricBack, setselectedStockUniverseMetricBack] = useState([])
    const [selectedPortfolioType, setselectedPortfolioType] = useState(0)
    const [excludeSurveillance, setexcludeSurveillance] = useState(true)

    const [popopen, setpopOpen] = useState();

    var user = useSelector((state) => state?.updateUserData?.value)

    const type = useGetAssetType()

    const metric_data = (key, value) => {
        return screenerMetric.filter((met) => met[key] === value)[0]
    }

    const sortFunds = (arr, key, order = "top") => {
        var arrr = [...arr]?.filter((i) => (i[key] !== null))
        if (order === "bot") {
            arrr = arrr.sort((a, b) => a[key] - b[key])
        } else {
            arrr = arrr.sort((a, b) => b[key] - a[key])
        }

        return arrr
    }

    const quantile = (sorted, q) => {
        // const sorted = asc(arr);

        let pos = (sorted.length - 1) * q;
        if (pos % 1 === 0) {
            return sorted[pos];
        }

        pos = Math.floor(pos);
        if (sorted[pos + 1] !== undefined) {
            return (sorted[pos] + sorted[pos + 1]) / 2;
        }

        return sorted[pos];
    };

    const median = (arr, key, order = "top") => {
        var nums = arr.filter((i) => i[key] !== null && i[key] !== NaN && i[key] !== undefined)

        if (nums.length > 0) {
            if (order === "bot") {
                nums = nums.sort((a, b) => a[key] - b[key])
            } else {
                nums = nums.sort((a, b) => b[key] - a[key])
            }
            nums = nums.sort((a, b) => a[key] - b[key])
            const mid = Math.floor(nums.length / 2)
            var a = nums.length % 2 !== 0 ? Number(nums[mid][key]) : (Number(nums[mid - 1][key]) + Number(nums[mid][key])) / 2;
            return Number(a.toFixed(2))
        }
        return null


    };


    // React.useEffect(() => {
    //     hide()
    // }, [allFilteredFundsData])


    var selectedm = screenerMetric.filter((i) => i.metric_col_code === selectedMetric)[0]

    const convertScreenerQueryToNormal = () => {
        var categories = mfCategories
        var current_q = [...signalData]
        var final_q = []

        final_q = sortFunds(current_q, 'pos', 'bot').map((query) => {
            query = { ...metric_data('metric_col_code', query.primary_col), ...query }
            var fquery = {
                metric_col_code: query.primary_col
            }
            if (query.oper === "abs_comp") {
                //abs
                console.log(query)
                if (query.metric_data_type === "boolean") {
                    if (stock_universe.map((i) => i.value).includes(query.primary_col) && type.value === 4) {
                        setselectedStockUniverse([query.primary_col])
                    } else if (query.primary_col === 'is_exclusion_list') {
                        setexcludeSurveillance(Number(query.abs_val_1) === 0 ? true : false)
                    } else {
                        fquery = {
                            ...fquery,
                            queryValue: Number(query.abs_val_1) === 1 ? "yes" : "no"
                        }
                    }
                } else {
                    fquery = {
                        ...fquery,
                        value: Number(query.abs_val_1),
                        queryValue: query.comp_operator
                    }
                }
            } else if (query.oper === "rank_comp") {
                //rank per or abs
                var a = query.comp_operator.split('_')
                //a[0] => top or bot (queryValue)
                //a[2] => per or abs
                //a[1] => 25,50,75,100 => a[1]/100 => if per => pervalue
                //a[1] => 25,50,75,100 => a[1] => if abs => value => top 25 stocks
                if (a[2] === 'per') {
                    fquery = {
                        ...fquery,
                        pervalue: String(Number(a[1]) / 100),
                        queryValue: 'in',//default
                        filter: a[0]
                    }
                } else {
                    // fquery = {
                    //     ...fquery,
                    //     value: query.abs_val_1,
                    //     queryValue: query.comp_operator
                    // }
                }
            } else if (query.oper === "col_comp") {
                //compare other column
            } else if (query.oper === "rel_comp") {
                //rel with other table
            } else if (query.oper === "isin_comp") {
                //in arr
                if (query.primary_col === "category_id") {
                    var sub_category_id = query.abs_val_1.split(",")
                    var fi = []
                    categories.map((i, index) => {
                        var ak = []
                        if (i["subCategories"] !== undefined) {
                            i["subCategories"]?.map((ii, indexi) => {
                                if (sub_category_id.includes(String(ii.categoryId))) {
                                    ak = [i.categoryName, String(ii.categoryId)]
                                    fi.push(ak)
                                }
                            })
                        }
                    })
                    fquery = {
                        ...fquery,
                        queryValue: 'isOne',//default
                        value: fi
                    }
                } else {
                    fquery = {
                        ...fquery,
                        queryValue: 'isOne',//default
                        value: query.abs_val_1.split(",")
                    }
                }
            } else if (query.oper === "isnotin_comp") {
                //not arr
                if (query.primary_col === "category_id") {
                    var sub_category_id = query.abs_val_1.split(",")
                    var fi = []
                    categories.map((i, index) => {
                        var ak = []
                        if (i["subCategories"] !== undefined) {
                            i["subCategories"]?.map((ii, indexi) => {
                                if (sub_category_id.includes(String(ii.categoryId))) {
                                    ak = [i.categoryName, String(ii.categoryId)]
                                    fi.push(ak)
                                }
                            })
                        }
                    })
                    fquery = {
                        ...fquery,
                        queryValue: 'notOne',//default
                        value: fi
                    }
                } else {
                    fquery = {
                        ...fquery,
                        queryValue: 'notOne',//default
                        value: query.abs_val_1.split(",")
                    }
                }
            } else {
                // issue
            }


            return fquery
        })

        setnewFiltersList(final_q.filter((query) => !(stock_universe.map((i) => i.value).includes(query.metric_col_code)) && "is_exclusion_list" !== query.metric_col_code))

    }

    const saveScreener = async () => {

        console.log(user)
        if (user !== undefined && user !== null) {
            if (screener_name.length > 0) {


                var q_param = {
                    "ClientId": user.id,
                    'Universe': selectedStockUniverse[0],
                    'MetricCols': newFiltersList.map((i) => i.metric_col_code),
                    'IsExclusionList': excludeSurveillance ? 0 : 1,
                    'DirectionFlags': newFiltersList.map((i) => (i.filter || i.direction_flag) === "top" ? 1 : -1),
                    'Weights': newFiltersList.map((i) => i.allocation / 100),
                    'Top': -1,
                    "SignalId": currentCheckListId,
                    "SignalName": screener_name,
                    // "Description": screener_desc,
                }

                var savedScreener = await apiHelper({ apiName: 'saveSignal', data: q_param })
                console.log(savedScreener)
                if (savedScreener[0]?.signal_id !== undefined) {
                    toast.success("Signal saved")
                    let screeners = await apiHelper({ apiName: "getUserSignal", saveResponse: "sessionStorage", data: { id: user.id, type: type.omkar }, getFresh: true });
                    setmodalSave(false)
                } else {
                    toast.error("Something went wrong. Try again later!!")
                }

            } else {
                toast.error("Please enter signal name")
            }
        } else {
            toast.error("Please login first")
            dispatch(toggleloginModal(true))
        }


    }

    const loadData = async () => {

        var screener_datas = await screenerMetricData(type, 1)

        // var user = await getUser()
        // setUser(user)
        console.log("adsjbkjasbd")
        setselectedCategory(screener_datas?.levelVals?.m_category_id)
        setselectedSubCategory(screener_datas?.levelVals?.m_sub_category_id)
        // setselectedMetric(screener_datas?.levelVals?.m_metric_col_code)

        setStockSectorCLF(screener_datas?.fabricatedData?.stockSectorCLF)
        setStyleBoxes(screener_datas?.fabricatedData?.stockStyles)
        setMacroSectors(screener_datas?.fabricatedData?.macroSectors)
        setSectors(screener_datas?.fabricatedData?.sectors)
        setIndustries(screener_datas?.fabricatedData?.industries)
        setBasicIndustries(screener_datas?.fabricatedData?.basicIndustries)
        setmfCategories(screener_datas?.fabricatedData?.mf_categories)
        setmfAMC(screener_datas?.fabricatedData?.mf_amcs)
        setscreenerMetric(screener_datas?.screenerMetricData)
        setscreenerFinalData(screener_datas?.screenerFinalData)
        setallFundsData(screener_datas?.allFunds)
        setallFilteredFundsData(screener_datas?.allFunds)
        setselectedStockUniverse([stock_universe.filter((i) => i?.key !== 'all')[0].value])
        setselectedStockUniverseMetricBack([stock_universe.filter((i) => i?.key !== 'all')[0].value])
        setloading(false)
        setpopOpen(false)


    }

    React.useEffect(() => {
        console.log("adsjbkjasbd")
        loadData()
    }, [])


    React.useEffect(() => {
        if (signalData.length > 0 && loading === false) {
            // convertScreenerQueryToNormal()
        }
    }, [loading])


    const getSignalFunds = async () => {
        if (newFiltersList.length !== 0) {
            setTableLoading(true)
            var q_param = {
                'universe': selectedStockUniverse[0],
                'metric_cols': newFiltersList.map((i) => i.metric_col_code),
                'include_exclusion_list': excludeSurveillance ? 0 : 1,
                'direction_flag': newFiltersList.map((i) => (i.filter || i.direction_flag) === "top" ? 1 : -1),
                'weights': newFiltersList.map((i) => i.allocation / 100),
                'top': -1
            }
            console.log(newFiltersList)
            console.log(q_param)
            var stocks = await apiHelperPY({ apiName: 'getSignalList', data: q_param })
            console.log(stocks)
            setallFilteredFundsData(JSON.parse(stocks).map((i) => {
                return {
                    ...i,
                    ...[...allFundsData].filter((ii) => ii.symbol === i.symbol)[0]
                }
            }))
            var stocksBackTest = await apiHelperPY({ apiName: 'getBacktestSignal', data: q_param })
            console.log(stocksBackTest)
            stocksBackTest = await JSON.parse(stocksBackTest.replace(/NaN/g, null))
            setBackTestData(stocksBackTest)
            setbackTestModalLoading(false)

            setTableLoading(false)
        } else {
            if (selectedStockUniverse.length !== 0) {
                setallFilteredFundsData([...allFundsData].filter((i) => i[selectedStockUniverse[0]] === 1))
                setTableLoading(false)
            }
        }
    }
    React.useEffect(() => {
        getSignalFunds()
    }, [newFiltersList, selectedStockUniverse])



    const allocate = (newFiltersList) => {
        console.log("sdfdfsdfs")
        var a = newFiltersList

        if (100 % a.length === 0) {
            a = a.map((obj, index) => ({
                ...obj,
                "title": obj.metric_name + " (value)", allocation: (100 / a.length).toFixed(0), locked: false, index
            }))
            setnewFiltersList(a)
        } else {
            a = a.map((obj, index) => ({
                ...obj,
                "title": obj.metric_name + " (value)", allocation: ((100 - (100 % a.length)) / a.length).toFixed(0), locked: false, index
            }))
            a[0].allocation = Number(a[0].allocation) + (100 % a.length)
            setnewFiltersList(a)
        }


        var newItems = newFiltersList.map((item) => {
            console.log(item)
            return {
                ...item,
                "dataIndex": item.dataIndex + "_score",
                "key": item.dataIndex + "_score",
                "metric_col_code": item.metric_col_code + "_score",
                "metric_unit": "",
                "metric_data_type": "float",
                // "metric_name": item.metric_name + " (score)",
                "title": item.metric_name + " (score)",
                "render": (text, record, index) => <div style={{ display: 'flex', gap: '5px', cursor: "pointer", alignItems: "center", justifyContent: "flex-end" }}>
                    <RedGreenText text={
                        displayValue({
                            text, metricObj: {
                                ...item, "dataIndex": item.dataIndex + "_score",
                                "key": item.dataIndex + "_score",
                                "metric_col_code": item.metric_col_code + "_score",
                                "metric_unit": "",
                                "metric_data_type": "float",
                                // "metric_name": item.metric_name + " (score)",
                                "title": item.metric_name + " (score)",
                            }
                        })
                    } value={Number(text)} />
                </div>
            }
        })

        setnewFiltersScoreList(newItems)
    }

    const del = (index) => {
        var a = newFiltersList;
        a.splice(index, 1)
        if (a.length === 0) {
            setnewFiltersList([])
        } else {
            allocate([...a])
        }
    }

    const isValidAllo = () => {
        var sum = 0;
        newFiltersList.map((data, index) => {
            sum += parseInt(data.allocation);
        })
        // if (sum !== 100) {
        //     setnextDisabled(true)
        // } else {
        //     setnextDisabled(false)
        // }
    }

    const changeData = (index, text) => {
        try {

            var upDatedFilters = []


            upDatedFilters = [...newFiltersList]


            const setFinalData = (data) => {
                setnewFiltersList(data)

            }

            var a = JSON.parse(JSON.stringify(upDatedFilters))
            var b = a.filter(data => data.locked === true);
            a[index].locked = true
            b = a.filter(data => data.locked === true);

            if (Number(text) !== 0) {

                if (a.length - b.length >= 1) {

                    a[index].allocation = text
                    var sum = 0;
                    b.map((data, index) => {
                        sum += parseInt(data.allocation);
                    })
                    var c = a.filter((data) => {
                        return !b.includes(data);
                    });
                    var finalamt = 100 - sum
                    if (finalamt % c.length === 0) {

                        var alllo = (finalamt / c.length).toFixed(0)
                        if (alllo > 0) {
                            c = c.map(obj => (
                                {
                                    ...obj,
                                    allocation: alllo
                                }
                            ))
                            b = (b.concat(c)).sort((a, b) => a.index - b.index)

                            setFinalData(b)
                        } else {
                            if (b.length === 1) {
                                toast.warn("Each signal must have weightage greater then 1%. Sum of weights allocated to each signal must be 100%", {
                                    className: "textSM"
                                })
                            } else {
                                toast.warn("Unlock some more signals.\nEach signal must have weightage greater then 1% and sum of weights allocated to each signal must be 100%", {
                                    className: "textSM"
                                })
                            }
                            toast.warn("Unlock some more signals.\nEach signal must have weightage greater then 1% and sum of weights allocated to each signal must be 100%", {
                                className: "textSM"
                            })
                            setFinalData(newFiltersList);
                        }

                    } else {

                        var alllo = ((finalamt - (finalamt % c.length)) / c.length).toFixed(0)

                        if (alllo > 0) {
                            c = c.map(obj => ({ ...obj, allocation: alllo }))
                            var allo2 = Number(c[0].allocation) + (finalamt % c.length)

                            if (allo2 > 0) {

                                c[0].allocation = allo2
                                b = (b.concat(c)).sort((a, b) => a.index - b.index)

                                setFinalData(b);
                            } else {
                                if (b.length === 1) {
                                    toast.warn("Each signal must have weightage greater then 1%. Sum of weights allocated to each signal must be 100%", {
                                        className: "textSM"
                                    })
                                } else {
                                    toast.warn("Unlock some more signals.\nEach signal must have weightage greater then 1% and sum of weights allocated to each signal must be 100%", {
                                        className: "textSM"
                                    })
                                }
                                toast.warn("Unlock some more signals.\nEach signal must have weightage greater then 1% and sum of weights allocated to each signal must be 100%", {
                                    className: "textSM"
                                })
                                setFinalData(newFiltersList);
                            }
                        } else {
                            if (b.length === 1) {
                                toast.warn("Each signal must have weightage greater then 1%. Sum of weights allocated to each signal must be 100%", {
                                    className: "textSM"
                                })
                            } else {
                                toast.warn("Unlock some more signals.\nEach signal must have weightage greater then 1% and sum of weights allocated to each signal must be 100%", {
                                    className: "textSM"
                                })
                            }
                            toast.warn("Unlock some more signals.\nEach signal must have weightage greater then 1% and sum of weights allocated to each signal must be 100%", {
                                className: "textSM"
                            })
                            setFinalData(newFiltersList);
                        }
                    }
                } else {
                    toast.warn("Unlock ateast 2 signals to readjust weights.\nEach signal must have weightage greater then 1% and sum of weights allocated to each signal must be 100%", {
                        className: "textSM"
                    })
                }
            } else {
                toast.error("Weights can't be zero.\nEither allocate some weight or remove the signal you don't want", {
                    className: "textSM"
                })
                setFinalData(newFiltersList);
            }
            isValidAllo()
        } catch (error) {
            //console.log("error", error)
        }
    }


    const performBackTest = async () => {
        setbackTestModal(!backTestModal)

        setbackTestModalLoading(false)
        // //console.log(backTestData)


    }


    const getScreenerMetricBackTest = async (metric_col_code) => {
        // var backTestRes = screener_metric_backTest

        if (metric_col_code === "style_box_code") {
            let backTestRes = await apiHelperPY({ apiName: 'getSharpelyStyleBoxBacktest' })
            backTestRes = await JSON.parse(backTestRes?.replace(/NaN/g, null))
            setMetricbackTestRes(backTestRes?.prices)
            setMetricbackTestResName(metric_col_code)
        } else {
            if (metric_col_code !== undefined) {
                var backTestRes = await apiHelperPY({ apiName: 'getScreenerMetricBacktestData', data: { metric_code: metric_col_code, universe_code: selectedStockUniverseMetricBack[0] } })
                backTestRes = await JSON.parse(backTestRes?.replace(/NaN/g, null))
                setMetricbackTestRes(backTestRes?.prices)
                setMetricbackTestResName(metric_col_code)
            }
        }
    }


    React.useEffect(() => {
        setMetricbackTestRes({})
        getScreenerMetricBackTest(selectedm?.metric_col_code)
    }, [selectedStockUniverseMetricBack])

    const lockData = (index, text) => {
        var upDatedFilters = []

        upDatedFilters = [...newFiltersList]

        var a = upDatedFilters
        a[index].locked = text
        setnewFiltersList([...a])


        isValidAllo()
        // setetfloading(true)
    }

    const MetricFilterView = ({ data, index }) => {

        const [weight, setWeight] = React.useState(Number(data?.allocation))
        var upDatedFilters = []

        upDatedFilters = [...newFiltersList]


        var currentMetricData = { ...metric_data('metric_col_code', data.metric_col_code), ...data }

        var floatType = currentMetricData.metric_data_type === "float" && currentMetricData.show_percentile === 1 && currentMetricData.show_absolute === 1 ? "top" :
            currentMetricData.metric_data_type === "float" && currentMetricData.show_percentile === 1 ? "bot" : null

        var queriesFinal = currentMetricData.metric_data_type === "string" ? queries[0] : currentMetricData.metric_data_type === "boolean" ? queries[3] : (currentMetricData.metric_data_type === "Date" || currentMetricData.metric_data_type === "date") ? queries[4] :
            floatType === "top" ? queries[1]?.concat(queries[2]) :
                floatType === "bot" ? queries[1] :
                    floatType === null ? queries[2] : queries[0]

        const setFinalData = (data) => {

            setnewFiltersList(data)

        }

        const onQueryValueChange = (queryValue) => {
            upDatedFilters[index] = {
                ...data,
                queryValue: queryValue
            }

            setFinalData(upDatedFilters)
        }


        const FilterViewLevel1 = () => {
            return <Segmented
                className='textSM black mx-3'
                options={sortOptions}
                onChange={(value) => {
                    //console.log(value)
                    upDatedFilters[index] = {
                        ...data,
                        filter: value
                    }
                    setFinalData(upDatedFilters)
                }}

                defaultValue={currentMetricData.filter || (currentMetricData.direction_flag === "top" ? sortOptions[0].value : sortOptions[1].value)}

            />
        }

        return <Card key={index} bodyStyle={{ padding: "0rem", display: "flex", gap: "0.5rem", alignItems: "center" }}>
            <span style={{ flex: 1, alignItems: "center", display: "flex", gap: "0.25rem" }}>
                <MdDragIndicator size={25} color="var(--primary)" />
                <span className='textSM black'>{currentMetricData.metric_name}</span>
            </span>

            {/* <Select removeIcon={<IoIosCloseCircleOutline size={12} />}
                    className='textSM black selectWhite'
                    suffixIcon={<MdArrowDropDown size={17} />}
                    defaultValue={currentMetricData?.queryValue || queriesFinal[0]?.value}
                    value={currentMetricData?.queryValue || queriesFinal[0]?.value}
                    style={{ width: 120, borderRadius: 5, backgroundColor: "var(--white)" }}
                    onChange={(value) => {
                        onQueryValueChange(value)
                    }}
                    options={queriesFinal}
                /> */}
            <FilterViewLevel1 />
            <span className='textSM dark3'>Weight:</span>
            <InputNumber addonBefore={
                data.locked ?
                    <HiLockClosed size={14} style={{ color: 'var(--primary)' }} onClick={() => lockData(index, false)} />
                    :
                    <HiLockOpen size={14} style={{ color: 'var(--grey)' }} onClick={() => lockData(index, true)} />
            } formatter={(value) => `${value} %`}
                parser={(value) => value.replace(' %', '')} controls={true} className='textSM black' size="middle" style={{ textAlign: "center", justifyContent: "center", alignItems: "center", width: 120 }} min={1} max={newFiltersList.length > 1 ? 99 : 100} defaultValue={weight} value={weight} onChange={e => changeData(index, parseInt(e))} />
            <IoIosCloseCircleOutline className='textSM black' color='var(--dark3)' size={18} onClick={() => {
                var a = [...newFiltersList]
                a.splice(index, 1)
                setFinalData(a)
            }} />

        </Card>



    }


    const MetricFlatList = () => {
        return
    }

    const RenderIntialFlatlist = ({ item }) => {

        const [checked, setChecked] = React.useState()

        return item.sub_category.filter((ele) => ele.isVisibleNormally !== false).map((subCategory, subCategoryIndex) => {
            return <div key={"asd" + subCategoryIndex} style={{ borderRadius: 2, marginBottom: "2rem" }}>
                <span className="textSM black w-500"> {subCategory.sub_category_name}</span>

                <span style={{ display: "flex", flexDirection: "column", gap: "0.5rem", marginTop: "0.625rem" }}>
                    {subCategory.metric.map((metric, metricIndex) => {

                        // var upDatedFilters = [...checkFiltersList]
                        var upDatedFilters = []
                        var itemIndex = [...newFiltersList].findIndex((met) => met.metric_col_code === metric.metric_col_code)
                        var isPresentIndex = itemIndex !== -1

                        if (metric.isVisibleNormally !== false) {
                            return <div onClick={() => {
                                // console.log(e.target.checked)
                                if (!isPresentIndex) {
                                    setselectedSubCategory(subCategory.sub_category_id)
                                    setselectedMetric(metric.metric_col_code)
                                    isPresentIndex = true
                                    var selectedm = screenerMetric.filter((i) => i.metric_col_code === metric.metric_col_code)[0]
                                    upDatedFilters.push({
                                        metric_col_code: metric.metric_col_code,
                                        value: selectedm?.metricDefVal,
                                        pervalue: selectedm?.pervalue,
                                        queryValue: selectedm?.queryValue,
                                    })
                                    setcheckFiltersList(upDatedFilters)
                                } else {
                                    toast.info("Metric already added")
                                }
                            }} key={metric.metric_col_code} style={{ cursor: isPresentIndex ? 'not-allowed' : "pointer", color: metric.metric_col_code === selectedMetric ? "var(--primary)" : isPresentIndex ? "var(--dark1)" : "var(--dark3)", display: "flex", alignItems: "center", gap: "0.5rem" }} className='textXS'>
                                {metric.metric_name}
                            </div>
                        } else {
                            return <div key={"DSF" + metricIndex
                            } ></div >
                        }
                    })}
                </span >
            </div >
        })
    }



    const onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const reorderedItems = reorder(
            newFiltersList,
            result.source.index,
            result.destination.index
        );

        setnewFiltersList(reorderedItems)

    };

    const isBackTestAvailable = () => {
        var con1 = allFilteredFundsData.length >= 10 && allFilteredFundsData.length <= 100
        var con2 = appliedMetrics.length === appliedMetrics.filter((item) => {
            //console.log(item)
            return item.back_test_available === 1
        }).length
        //console.log(con1, con2)
        return con1 && con2 && newFiltersList.length > 0
    }


    const hide = () => {
        setpopOpen(false);
    };
    const handleOpenChange = (newOpen) => {
        console.log("hey")
        setpopOpen(newOpen);
    };

    React.useEffect(() => {
        console.log("sdf", popopen)
    }, [popopen])

    const CheckedFilterView = () => {
        return <span style={{ padding: '0rem 1.25rem' }}>
            <span className='textSM black w-700'>Customise rule</span>

            {checkFiltersList.map((item, idx) => {
                return <MetricFilterView key={idx} data={item} index={idx} />
            })}
        </span>
    }

    const DrawerMetricDescComp = () => {
        return <div style={{ padding: "1.25rem" }}>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                <span className='textSM black w-700'>{selectedm?.metric_name}</span>
                {(selectedm?.back_test_available === 1 && selectedm?.metric_data_type === 'float') || selectedm?.metric_col_code === "style_box_code" ? <button className='btnSecondary btnSM mb-1' onClick={() => {
                    getScreenerMetricBackTest(selectedm?.metric_col_code)
                    settoggleMetricBackTest(true)
                }}>
                    Metric performance
                </button> : <></>}
            </div>
            <span className='textSM'>{selectedm?.metric_desc}</span>
        </div>
    }


    const AppliedFilterView = () => (
        <Card title={<div style={{ display: "flex", gap: "0.5rem" }}>
            <CardHeader heading={signalData?.[0]?.checklist_name || "Create signal"} />
            {type.value === 4 && <button className='btnSecondary' title='Backtest signal' disabled={!isBackTestAvailable()} onClick={() => {
                performBackTest()
            }}>Backtest signal</button>}
            <button className='btnPrimary' title='Save' disabled={newFiltersList.length === 0} onClick={() => {
                setmodalSave(true)
            }}>{signalData?.length > 0 ? "Update" : "Save"}</button>
        </div>} bodyStyle={{ display: "flex", flexDirection: "column", gap: "1.5rem", paddingTop: 0, paddingBottom: 0 }}>
            {/* {type.value === 4 && <div className='' style={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
                <span className='textSM w-500' >Select stock universe</span>

                <Select removeIcon={<IoIosCloseCircleOutline size={12} />}
                    className='textSM black '
                    style={{
                        width: "250px"
                    }}
                    suffixIcon={<MdArrowDropDown size={17} />}
                    defaultValue={selectedStockUniverse}
                    placeholder="Please select stock universe"
                    onChange={(value) => {
                        setselectedStockUniverse([value])
                    }}
                    options={stock_universe}
                />

                <span className='textSM w-500 ms-5' >Exclude stocks under surveillance</span>
                <Switch className='' size="small" checked={excludeSurveillance} onChange={(e) => {
                    setexcludeSurveillance(e)
                }} />
            </div>} */}
            <div style={{ display: "flex", gap: "0.75rem", alignItems: "center", paddingLeft: 0, paddingRight: 0 }}>
                <Popover

                    content={
                        <div >
                            <div style={{
                                position: "relative", minHeight: "40vh",
                                maxHeight: "40vh", overflowY: "scroll"
                            }}>
                                <DragDropContext onDragEnd={(e) => onDragEnd(e, 0)}>
                                    <Droppable droppableId={"droppable"}>
                                        {(provided, snapshot) => (
                                            <div
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                                style={getListStyle(snapshot.isDraggingOver)}
                                            >
                                                {newFiltersList.map((item, idx) => {
                                                    return <Draggable key={item.metric_col_code + "metric"} draggableId={item.metric_col_code + "metric"} index={idx}>
                                                        {(provided, snapshot) => (
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                style={getItemStyle(
                                                                    snapshot.isDragging,
                                                                    provided.draggableProps.style
                                                                )}
                                                            >
                                                                <MetricFilterView data={item} index={idx} />
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                })}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            </div>
                            <div style={{ padding: '1.25rem' }} className='textSX dark2'>{`showing ${allFilteredFundsData.length} out of ${allFundsData.length} ${type.dname}`}</div>
                        </div>
                    }
                    // title="Title"
                    trigger="click"
                    open={popopen}
                    overlayStyle={{
                        position: "relative",
                        width: "45%",
                        height: "50%",
                        padding: 0
                    }}
                    style={{ position: "relative", width: "60vw" }}
                    placement='bottomRight'
                    onOpenChange={(e) => handleOpenChange(e)}
                >
                    <button style={{ width: "10vw" }} className="btnSecondary"><span style={{ display: "flex", alignItems: "center" }}><FaFilter className='mx-2' /> Filters <Tag className='mx-2' color="var(--blueShade)" style={{ color: "var(--black)" }}>{newFiltersList.length}</Tag></span></button>
                </Popover>

                {type.value === 4 ? <div className='' style={{ display: "flex", gap: "0.5rem", alignItems: "center", flex: 1, justifyContent: "flex-end" }}>
                    {/* <span className='textSM w-500' >Select stock universe</span> */}
                    <span className='textSM w-500 ms-5' >Exclude stocks under surveillance</span>
                    <Switch className='' size="small" checked={excludeSurveillance} onChange={(e) => {
                        setexcludeSurveillance(e)
                    }} />
                    <Select removeIcon={<IoIosCloseCircleOutline size={12} />}
                        className='textSM black '
                        style={{
                            width: "150px"
                        }}
                        suffixIcon={<MdArrowDropDown size={17} />}
                        defaultValue={selectedStockUniverse}
                        placeholder="Please select stock universe"
                        onChange={(value) => {
                            setselectedStockUniverse([value])
                        }}
                        options={stock_universe.filter((i) => i?.key !== 'all')}
                    />

                </div> :
                    <span className='textSM' color='var(--dark3)' style={{ flex: 1, textAlign: "right" }}>Add rule to find the best {type.dname}</span>}

                <button className='btnPrimary' title='Max 5 rules can be added' disabled={newFiltersList.length >= 5} onClick={() => {
                    setmodal(!modal)
                }}>Add rule</button>
            </div>
        </Card>
    )

    const renderRow = ({ item, index }) => {
        return <span key={item.category_id} style={{
            padding: "0.75rem 1.25rem",
            borderRadius: "4px 0px 0px 4px",
            display: "flex",
            alignItems: "center",
            backgroundColor: item.category_id === selectedCategory ? "var(--blueShade)" : "transparent", cursor: "pointer"
        }}
            onClick={() => {
                setselectedCategory(item.category_id)
                setselectedSubCategory("")
                setselectedMetric("")
            }}
        >
            <span style={{ flex: 1, color: item.category_id === selectedCategory ? "var(--primary)" : "var(--dark3)" }} className="textSM w-500">{item.category_name}</span>
            <IoIosArrowForward color={item.category_id === selectedCategory ? "var(--primary)" : "var(--dark3)"} />
        </span>
    };

    const WorstDrawdowns = () => {
        const dataSource = Object.values(backTestData?.perfromance_tables?.worst_5_drawdowns)

        const columns = [
            {
                title: 'Started on',
                dataIndex: 'Started',
                key: 'Started',
                width: 150,
                fixed: 'left'
            },
            {
                title: 'Drawdown',
                dataIndex: 'Drawdown',
                key: 'Drawdown',
                align: "right",
                render: (text) => DecimalValueFormat({ num: Number(text), suffix: "%", placeholder: "-" })
            },
            {
                title: 'Lasted for (days)',
                dataIndex: 'Days',
                key: 'Days',
                align: "right",
            },
            {
                title: 'Recovered on',
                dataIndex: 'Recovered',
                key: 'Recovered',
                align: "right",
            }
        ]
        return <Table dataSource={dataSource} columns={columns} size={'small'} bordered className='textSM black' pagination={false} />
    }

    const TableConstructor = ({ tableName = "", tableData = {}, showNameColumn = false, isTranspose = false }) => {
        if (isTranspose) {
            var dataSource = Object.values(tableData)
            var dataSourceKeys = Object.keys(tableData)

            var smDataSource = Object.keys(dataSource[0])
            smDataSource = smDataSource.map((key, index) => {
                var i = {
                    "key": key,
                    "name": key
                }
                dataSourceKeys.map((finalkey, index) => {
                    i = {
                        ...i,
                        [finalkey]: new Date(dataSource[index][key]) !== "Invalid Date" && !dataSource[index][key]?.includes("%") && !dataSource[index][key]?.includes(".") ? formatDate(dataSource[index][key]) : dataSource[index][key]
                    }
                })
                return i
            })
            dataSourceKeys = ["name"]?.concat(dataSourceKeys)
            var columns = dataSourceKeys.map((key, index) => {
                return {
                    title: key === "name" ? "" : key,
                    dataIndex: key,
                    key: key,
                    width: 100,
                    align: key === "name" ? "left" : "right",
                }
            })
            return <div className='my-4'>
                <div className='fontH2 w-700 mb-2'>{tableName}</div><Table scroll={{ x: isTranspose ? 300 : 800 }} dataSource={smDataSource} columns={columns} size={'small'} bordered className='textSM black' pagination={false} /></div>
        } else {
            var dataSource = Object.values(tableData)
            var dataSourceKeys = Object.keys(tableData)

            if (showNameColumn) {
                dataSource = dataSource.map((item, index) => {
                    return {
                        ...item,
                        "col1_name_custom": dataSourceKeys[index]
                    }
                })
            }

            var columns = Object.entries(dataSource[0]).map(([key, val], index) => {
                return {
                    title: key,
                    dataIndex: key,
                    key: key,
                    width: 100,
                    align: () => {
                        if (new Date(val) !== "Invalid Date" || val.includes("%") || val.includes(".") || isNaN(Number(val))) {
                            return "right"
                        } else {
                            return "left"
                        }
                    },
                    fixed: key === "col1_name_custom" ? "left" : false,
                    render: (text) => {
                        if (new Date(text) !== "Invalid Date" && !text.includes("%") && !text.includes(".")) {
                            return formatDate(text)
                        } else {
                            return text
                        }
                    }
                }
            })


            if (showNameColumn) {
                columns.splice(columns.indexOf((i) => i.key === "col1_name_custom"), 1)
                columns = [{
                    title: "",
                    width: 150,
                    dataIndex: "col1_name_custom",
                    key: "col1_name_custom",
                    fixed: "left",
                }]?.concat(columns)
            }
            return <div className='my-3'>
                <div className='fontH2 w-700 mb-2'>{tableName}</div><Table scroll={{ x: 800 }} dataSource={dataSource} columns={columns} size={'small'} bordered className='textSM black' pagination={false} /></div>
        }
    }

    const ReturnsHistogram = () => {
        var heatmapdata = backTestData?.perfromance_charts?.histogram_monthly_rets.map((res) => {
            return Number((res * 100).toFixed(2))
        })

        const xiData = [];
        const animationDuration = 1;//4000;
        const range = Math.max(...heatmapdata) - Math.min(...heatmapdata) * 2,
            startPoint = Math.min(...heatmapdata);
        let i = 0,
            j = 0;
        for (i = 0; i < range; i++) {
            xiData[i] = startPoint + i;
        }
        const data = [];

        function GaussKDE(xi, x) {
            return (1 / Math.sqrt(2 * Math.PI)) * Math.exp(Math.pow(xi - x, 2) / -2);
        }

        const N = heatmapdata.length;

        for (i = 0; i < xiData.length; i++) {
            let temp = 0;
            for (j = 0; j < heatmapdata.length; j++) {
                temp = temp + GaussKDE(xiData[i], heatmapdata[j]);
            }
            data.push([xiData[i], (1 / N) * temp * 100]);
        }

        const chart = {
            chart: {
                type: "areaspline",
                animation: true,
                height: 300,
                marginTop: 10
            },
            title: {
                text: '',
                style: {
                    color: 'var(--black)'
                }
            },
            scrollbar: {
                enabled: false
            },
            exporting: {
                enabled: false
            },
            credits: {
                enabled: false
            },

            title: {
                text: ""
            },
            xAxis: {
                title: { text: "" }
            },
            yAxis: {
                visible: false,
                tickAmount: 5,
                gridLineDashStyle: 'longdash',
                title: { text: 'Density' }
            },
            tooltip: {
                enabled: false
            },
            legend: {
                enabled: false
            },

            plotOptions: {
                areaspline: {
                    threshold: null
                },
                series: {
                    marker: {
                        enabled: false
                    },
                    dashStyle: "shortdot",
                    color: "#ff8d1e",
                    pointStart: xiData[0],
                    animation: {
                        duration: animationDuration
                    }
                }
            },
            series: [
                {
                    name: "Return density",
                    dashStyle: "solid",
                    lineWidth: 2,
                    color: 'var(--primary)',
                    data: data
                }
            ]
        }

        const options = {
            global: {
                useUTC: false
            },
            lang: {
                decimalPoint: '.',
                thousandsSep: ','
            }
        };

        return <HighchartsReact highcharts={Highcharts} options={chart} />
    }

    const HeatMap = () => {


        var heatmapdata = backTestData?.perfromance_charts?.heatmap
        var years = backTestData?.perfromance_charts?.heatmap.map((data) => { return data.year })
        var months = Object.keys(backTestData?.perfromance_charts?.heatmap[0]).slice(1)


        var dataFinal = months.map((month, index) => {
            return years.map((year, yindex) => {
                return {
                    name: month.charAt(0).toUpperCase() + month.toLowerCase().slice(1) + " " + year,
                    x: index,
                    y: yindex,
                    value: Number((heatmapdata[yindex][month] * 100).toFixed(1))
                }
            })
        })


        var chart = {

            chart: {
                type: 'heatmap',
                plotBorderWidth: 0,
                borderWidth: 0,
                height: 300,

            },


            title: {
                text: '',
                style: {
                    color: "var(--black)"
                }
            },
            scrollbar: {
                enabled: false
            },
            exporting: {
                enabled: false
            },
            credits: {
                enabled: false
            },

            xAxis: {
                categories: months
            },

            yAxis: {
                tickAmount: 5,
                gridLineDashStyle: 'longdash',
                categories: years,
                title: null,
                reversed: true
            },

            colorAxis: {
                min: 0,
                max: 100,
                minColor: "var(--red)",
                maxColor: "var(--green)"
            },

            legend: {
                enabled: false
            },

            tooltip: {
                headerFormat: '',
                pointFormat: '{point.name} returns: {point.value}%',
            },
            series: [{
                name: 'Monthly returns',
                borderWidth: 0.2,
                borderColor: 'black',
                data: dataFinal.flat(1),
                dataLabels: {
                    enabled: true,
                    color: '#000000',
                    style: {
                        fontWeight: 'normal',
                        fontSize: 8,
                        textOutline: false
                    },
                    pointFormat: '{point.value}%',
                }
            }],

            responsive: {
                rules: [{
                    condition: {
                        maxWidth: 300
                    },
                    chartOptions: {
                        yAxis: {
                            labels: {
                                formatter: function () {
                                    return this.value.charAt(0);
                                }
                            }
                        }
                    }
                }]
            }

        }

        const options = {
            global: {
                useUTC: false
            },
            lang: {
                decimalPoint: '.',
                thousandsSep: ','
            }
        };

        return <HighchartsReact highcharts={Highcharts} options={chart} />
    }

    const RollingVol = () => {

        var heatmapdata = backTestData?.perfromance_charts?.rolling_vol

        try {
            var conf = {
                chart: {
                    backgroundColor: "transparent",
                    height: 250,
                },
                title: {
                    text: ''
                },
                exporting: {
                    enabled: false
                },
                yAxis: {
                    tickAmount: 5,
                    gridLineDashStyle: 'longdash',
                    title: {
                        text: ''
                    },
                    gridLineColor: "var(--border)",
                    plotLines: [{
                        color: '#000', // Red
                        value: heatmapdata[0]["Average_6M_vol"], // Position, you'll have to translate this to the values on your x axis
                        dashStyle: 'dash',
                        width: 1,
                        label: {
                            text: "Average 6M vol", rotation: 0, x: 10,
                            style: {
                                color: "var(--black)",
                                fontWeight: 'bold'
                            }
                        }
                    }]
                },
                xAxis: [{
                    type: 'datetime',
                    dateTimeLabelFormats: {
                        millisecond: '%H:%M:%S.%L',
                        second: '%H:%M:%S',
                        minute: '%H:%M',
                        hour: '%H:%M',
                        day: '%eth %b',
                        week: '%eth %b',
                        month: '%b \'%y',
                        year: '%Y'
                    },
                    lineColor: "var(--border)",
                    tickColor: "var(--border)",
                }
                ],
                legend: {
                    enabled: false
                },
                plotOptions: {
                    series: {
                        lineWidth: 1.5,
                        label: {
                            connectorAllowed: false
                        },
                        marker: {
                            enabled: false
                        }
                    }
                },
                tooltip: {
                    shared: true,
                    valueDecimals: 2,
                    valuePrefix: '',
                    valueSuffix: '%'
                },
                credits: {
                    enabled: false
                },
                boost: {
                    useGPUTranslations: true,
                },
                series: [
                    {
                        name: "Portfolio", turboThreshold: 9e9,
                        color: '#4284f3',
                        data: changeObjKey(heatmapdata, ["Portfolio", "Date"], ["y", "x"], "rollingTearchart", 1)
                    }, {
                        name: "Benchmark", turboThreshold: 9e9,
                        color: "var(--primary)",
                        data: changeObjKey(heatmapdata, ["Benchmark", "Date"], ["y", "x"], "rollingTearchart", 1)
                    }
                ]?.reverse(),
                scrollbar: {
                    enabled: false
                },
                responsive: {
                    rules: [{
                        condition: {
                            maxWidth: 400
                        },
                    }]
                },

            };
        } catch (error) {
            //console.log("SDF", error)
        }

        const options = {
            global: {
                useUTC: false
            },
            lang: {
                decimalPoint: '.',
                thousandsSep: ','
            }
        };

        return <HighchartsReact highcharts={Highcharts} options={conf} />
    }

    const SharpeChart = () => {
        var heatmapdata = backTestData?.perfromance_charts?.rolling_sharpe

        try {
            var conf = {
                chart: {
                    backgroundColor: "transparent",
                    height: 250,
                },
                title: {
                    text: ''
                },
                exporting: {
                    enabled: false
                },
                yAxis: {
                    tickAmount: 5,
                    gridLineDashStyle: 'longdash',
                    title: {
                        text: ''
                    },
                    gridLineColor: "var(--border)",
                    plotLines: [{
                        color: '#000', // Red
                        value: heatmapdata[0]["Average_Sharpe"], // Position, you'll have to translate this to the values on your x axis
                        dashStyle: 'dash',
                        width: 1,
                        label: {
                            text: "Average Sharpe", rotation: 0, x: 10,
                            style: {
                                color: "var(--black)",
                                fontWeight: 'bold',
                                zIndex: 1
                            }
                        }
                    }]
                },
                xAxis: [{
                    type: 'datetime',
                    dateTimeLabelFormats: {
                        millisecond: '%H:%M:%S.%L',
                        second: '%H:%M:%S',
                        minute: '%H:%M',
                        hour: '%H:%M',
                        day: '%eth %b',
                        week: '%eth %b',
                        month: '%b \'%y',
                        year: '%Y'
                    },
                    lineColor: "var(--border)",
                    tickColor: "var(--border)",
                }
                ],
                legend: {
                    enabled: false
                },
                plotOptions: {
                    series: {
                        lineWidth: 1.5,
                        label: {
                            connectorAllowed: false
                        },
                        marker: {
                            enabled: false
                        }
                    }
                },
                tooltip: {
                    shared: true,
                    valueDecimals: 0,
                    valuePrefix: '₹',
                },
                credits: {
                    enabled: false
                },
                boost: {
                    useGPUTranslations: true,
                },
                series: [
                    {
                        name: "Portfolio", turboThreshold: 9e9,
                        color: "#24c1e0",
                        tooltip: {
                            valueSuffix: ' ({point.Portfolio}%)',
                        },
                        data: changeObjKey(heatmapdata, ["Portfolio", "Date"], ["y", "x"], "rollingTearchart", 1)
                    }
                ],
                scrollbar: {
                    enabled: false
                },
                responsive: {
                    rules: [{
                        condition: {
                            maxWidth: 400
                        },
                    }]
                },

            };
        } catch (error) {
            //console.log("SDF", error)
        }

        const options = {
            global: {
                useUTC: false
            },
            lang: {
                decimalPoint: '.',
                thousandsSep: ','
            }
        };

        return <HighchartsReact highcharts={Highcharts} options={conf} />
    }

    const UnderWater = () => {
        var heatmapdata = backTestData?.perfromance_charts?.underwater

        try {
            var conf = {
                chart: {
                    backgroundColor: "transparent",
                    height: 250,
                },
                title: {
                    text: ''
                },
                exporting: {
                    enabled: false
                },
                yAxis: {
                    tickAmount: 5,
                    gridLineDashStyle: 'longdash',
                    labels: {
                        format: '{value}%'
                    },
                    title: {
                        text: ''
                    },
                    gridLineColor: "var(--border)",
                },
                xAxis: [{
                    type: 'datetime',
                    dateTimeLabelFormats: {
                        millisecond: '%H:%M:%S.%L',
                        second: '%H:%M:%S',
                        minute: '%H:%M',
                        hour: '%H:%M',
                        day: '%eth %b',
                        week: '%eth %b',
                        month: '%b \'%y',
                        year: '%Y'
                    },
                    lineColor: "var(--border)",
                    tickColor: "var(--border)",
                }
                ],
                legend: {
                    enabled: false
                },
                plotOptions: {
                    series: {
                        lineWidth: 1.5,
                        label: {
                            connectorAllowed: false
                        },
                        marker: {
                            enabled: false
                        }
                    }
                },
                tooltip: {
                    shared: true,
                    valueDecimals: 2,
                    valuePrefix: '',
                    valueSuffix: '%'
                },
                credits: {
                    enabled: false
                },
                boost: {
                    useGPUTranslations: true,
                },
                series: [
                    {
                        name: "Portfolio", turboThreshold: 9e9,
                        color: "var(--red)",
                        data: changeObjKey(heatmapdata, ["Portfolio", "Date"], ["y", "x"], "rollingTearchart", 1).map((i) => {
                            return {
                                ...i,
                                y: i.y * 100
                            }
                        })
                    }
                ],
                scrollbar: {
                    enabled: false
                },
                responsive: {
                    rules: [{
                        condition: {
                            maxWidth: 400
                        },
                    }]
                },

            };
        } catch (error) {
            //console.log("SDF", error)
        }

        const options = {
            global: {
                useUTC: false
            },
            lang: {
                decimalPoint: '.',
                thousandsSep: ','
            }
        };

        return <HighchartsReact highcharts={Highcharts} options={conf} />
    }

    const RebalanceData = ({ rebalData }) => {
        const dataSource = rebalData

        const columns = [
            {
                title: 'Symbol',
                dataIndex: 'symbol',
                key: 'symbol',
                width: 150,
                fixed: 'left'
            },
            {
                title: 'Market cap',
                dataIndex: 'mcap',
                key: 'mcap',
                align: "right",
                render: (text) => DecimalValueFormat({ num: Number(text.toFixed(2)), suffix: " Cr", placeholder: "-" })
            },
            {
                title: 'Weights',
                dataIndex: 'weights',
                key: 'weights',
                align: "right",
                render: (text) => DecimalValueFormat({ num: Number(text.toFixed(2)), suffix: "%", placeholder: "-" })
            },
            {
                title: 'Next day avg. price',
                dataIndex: 'next_day_price_avg',
                key: 'next_day_price_avg',
                align: "right",
                render: (text) => CurrencyFormat(Number(text))
            },
            {
                title: 'No. of shares',
                dataIndex: 'num_shares',
                key: 'num_shares',
                align: "right",
            }
        ]
        return <Table dataSource={dataSource} columns={columns} size={'small'} bordered className='textSM black my-2' pagination={false} />
    }

    const CustomAccordionItem = ({ x }) => {

        const [isOpen, setIsOpen] = React.useState(false)
        return <Timeline.Item key={x.index}>
            <div className="compare-collapse" style={{ backgroundColor: "transparent", borderBottom: "0px", padding: "0px" }} id={`id${x.index}`} key={`id${x.index}`} onClick={() => {
                setIsOpen(!isOpen)
            }}>
                <div className="fontH2 black" >{x.header}</div>
                <div className="black">{isOpen ? <FiChevronUp size={'18px'} /> : <FiChevronDown size={'18px'} />}</div>
            </div>
            <Collapse
                style={{ border: "0px" }}
                isOpen={isOpen}
                toggler={`#id${x.index}`} id={`#body${x.index1}`}>

                <RebalanceData rebalData={x.rebalData} />

            </Collapse>
        </Timeline.Item>

    }

    const CashPositions = () => {
        var heatmapdata = backTestData?.cash_positions

        try {
            var conf = {
                chart: {
                    backgroundColor: "transparent"
                },
                title: {
                    text: ''
                },
                exporting: {
                    enabled: false
                },
                yAxis: {
                    tickAmount: 5,
                    gridLineDashStyle: 'longdash',
                    labels: {
                        format: '{value}'
                    },
                    title: {
                        text: ''
                    },
                    gridLineColor: "var(--border)",
                },
                xAxis: [{
                    type: 'datetime',
                    dateTimeLabelFormats: {
                        millisecond: '%H:%M:%S.%L',
                        second: '%H:%M:%S',
                        minute: '%H:%M',
                        hour: '%H:%M',
                        day: '%eth %b',
                        week: '%eth %b',
                        month: '%b \'%y',
                        year: '%Y'
                    },
                    lineColor: "var(--border)",
                    tickColor: "var(--border)",
                }
                ],
                legend: {
                    enabled: false
                },
                plotOptions: {
                    series: {
                        lineWidth: 1.5,
                        label: {
                            connectorAllowed: false
                        },
                        marker: {
                            enabled: false
                        }
                    }
                },
                tooltip: {
                    shared: true,
                    valueDecimals: 2,
                    valuePrefix: '',
                    valueSuffix: ''
                },
                credits: {
                    enabled: false
                },
                boost: {
                    useGPUTranslations: true,
                },
                series: [
                    {
                        name: "Cash position", turboThreshold: 9e9,
                        color: "var(--red)",
                        data: changeObjKey(heatmapdata, ["cash", "date"], ["y", "x"], "rollingTearchart", 1).map((i) => {
                            return {
                                ...i,
                                y: i.y
                            }
                        })
                    }
                ],
                scrollbar: {
                    enabled: false
                },
                responsive: {
                    rules: [{
                        condition: {
                            maxWidth: 400
                        },
                    }]
                },

            };
        } catch (error) {
            //console.log("SDF", error)
        }

        const options = {
            global: {
                useUTC: false
            },
            lang: {
                decimalPoint: '.',
                thousandsSep: ','
            }
        };

        return <HighchartsReact highcharts={Highcharts} options={conf} />
    }

    const tabItems = Object.keys(backTestData).length !== 0 ? [
        {
            label: <span className='textSM w-500'>{`Performance`}</span>,
            key: "id1",
            children: (
                <div className='my-3'>
                    <Row>
                        <Col sm={6}>
                            <div className='fontH2 w-700 mb-3'>Portfolio vs Benchmark performance</div>
                            <PerformanceChart2 chartData={backTestData?.perfromance_against_bm?.prices} xAxisKey='date' />
                            <TableConstructor tableName='Performance metrics' tableData={backTestData?.perfromance_tables?.perf_metrics} showNameColumn={true} isTranspose={true} />
                        </Col>
                        <Col sm={6}>
                            <TableConstructor tableName='Performance summary' tableData={backTestData?.perfromance_tables?.perf_summary} showNameColumn={true} isTranspose={true} />
                            <div className='my-4'>
                                <div className='fontH2 w-700 mb-2'>Worst 5 Drawdowns</div>
                                <WorstDrawdowns />
                            </div>
                            <TableConstructor tableName='Trailing Returns' tableData={backTestData?.perfromance_tables?.trailing_returns} showNameColumn={true} isTranspose={true} />
                            <TableConstructor tableName='Calender year returns' tableData={backTestData?.perfromance_tables?.cal_year_returns} showNameColumn={true} isTranspose={true} />
                        </Col>
                    </Row>
                </div>
            ),
        }, {
            label: <span className='textSM w-500'>{`Performance charts`}</span>,
            key: "id11",
            children: (
                <div className='my-3'>
                    <Row>
                        <Col className='mb-3' sm={6}>
                            <div className='fontH2 w-700 mb-3'>Distribution of monthly returns</div>
                            <ReturnsHistogram />
                        </Col>
                        <Col className='mb-3' sm={6}>
                            <div className='fontH2 w-700 mb-3'>Monthly returns heatmap</div>
                            <HeatMap />
                        </Col>
                        <Col className='my-3' sm={4}>
                            <div className='fontH2 w-700 mb-3'>6-month rolling volatility</div>
                            <RollingVol />
                        </Col>
                        <Col className='my-3' sm={4}>
                            <div className='fontH2 w-700 mb-3'>6-month rolling Sharpe ratio</div>
                            <SharpeChart />
                        </Col>
                        <Col className='my-3' sm={4}>
                            <div className='fontH2 w-700 mb-3'>Underwater plot</div>
                            <UnderWater />
                        </Col>
                    </Row>
                </div>
            ),
        }, {
            label: <span className='textSM w-500'>{`Rebalance history`}</span>,
            key: "id2",
            children: (
                <div className='my-4'>
                    <Timeline>
                        {Object.entries(backTestData?.rebal_hsitory)?.map(([Date, rebalData], index1) => {
                            return <CustomAccordionItem x={{
                                "Date": Date,
                                "header": "Rebalance data as on " + formatDate(Date),
                                "rebalData": rebalData,
                                "key": Date + "" + index1,
                                "index": index1
                            }} />
                        })}
                    </Timeline>
                </div>
            ),
        }] : [];

    const investInScreenerSteps = [
        {
            title: 'First',
            content: 'First-content',
        },
        {
            title: 'Second',
            content: 'Second-content',
        },
        {
            title: 'Last',
            content: 'Last-content',
        },
    ];

    const [invest_current, setinvest_Current] = useState(0);
    const next = () => {
        setinvest_Current(invest_current + 1);
    };
    const prev = () => {
        setinvest_Current(invest_current - 1);
    };
    const items = investInScreenerSteps.map((item) => ({
        key: item.title,
        title: item.title,
    }));

    if (loading) {
        return <React.Fragment>
            <div className='mainContainer' style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
                <ActivityLoaderMintbox />
            </div>
        </React.Fragment>
    } else {
        return (
            <React.Fragment>
                <Head title="Signal" />

                <div className='normalContainer'>
                    {AppliedFilterView()}

                    {newFiltersScoreList?.length > 0 &&
                        <InstrumentListTable
                            finalList={JSON.parse(JSON.stringify(allFilteredFundsData))}
                            tableType={3}
                            disableInvestBtn={allFilteredFundsData.length < 5}
                            otherCol={(newFiltersScoreList.length > 0 ? [{
                                "dataIndex": "agg_z_score",
                                "key": "agg_z_score",
                                "metric_col_code": "agg_z_score",
                                "metric_unit": "",
                                "metric_data_type": "float",
                                "metric_name": "Aggregated score",
                                "title": "Aggregated score",
                                "align": "right",
                                "width": 100,
                                "render": (text, record, index) => <div style={{ display: 'flex', gap: '5px', cursor: "pointer", alignItems: "center", justifyContent: "flex-end" }}>
                                    <RedGreenText text={
                                        displayValue({
                                            text, metricObj: {
                                                "dataIndex": "agg_z_score",
                                                "key": "agg_z_score",
                                                "metric_col_code": "agg_z_score",
                                                "metric_unit": "",
                                                "metric_data_type": "float",
                                                "metric_name": "Aggregated score",
                                                "title": "Aggregated score",
                                            }
                                        })
                                    } value={Number(text)} />
                                </div>

                            }] : []).concat([
                                ...newFiltersScoreList,
                                // ...newFiltersList
                            ])}
                            initialColumns={tableColumns.map((i) => i.metric_col_code)}
                            tableHeader={`Showing ${allFilteredFundsData.length} out of ${allFundsData.length} ${type.dname}`}
                            investInScreener={(rowSelection) => {
                                if (rowSelection.length < (type.value === 4 ? 5 : 2)) {
                                    toast.info(<span className='textSM dark3'>To invest in signal <br></br>Select <span className='black w-500'>Min. {type.value === 4 ? "5" : "2"}</span> or <span className='black w-500'>Max. {type.value === 4 ? "50" : "10"}</span> {type.dname}</span>)
                                } else {
                                    setinstrumentList(rowSelection)
                                    setinvestInScreenerModal(!investInScreenerModal)
                                }
                            }}
                        />
                    }
                </div>

                <Drawer
                    width={"75vw"}
                    closable={false}
                    title={false} placement="right"
                    onClose={() => {
                        setmodal(!modal)
                        settoggleMetricBackTest(false)
                    }}
                    open={modal}
                    footer={false}>
                    <Card
                        actions={[<div style={{ display: "flex", gap: "0.5rem", alignItems: "center", justifyContent: "flex-end", paddingRight: "1rem" }}><button className="btnSecondary" onClick={() => {
                            setmodal(!modal)
                            settoggleMetricBackTest(false)
                        }}>Cancel</button>,
                            <button className="btnPrimary" onClick={() => {
                                var upDatedFilters = [...newFiltersList]
                                var isPresentIndex = upDatedFilters.findIndex((met) => met.metric_col_code === selectedm?.metric_col_code)
                                if (isPresentIndex === -1) {
                                    upDatedFilters.push({
                                        ...selectedm,
                                        disableDeleteInCustomize: true,
                                        metric_col_code: selectedm?.metric_col_code,
                                        value: selectedm?.metricDefVal,
                                        pervalue: selectedm?.pervalue,
                                        queryValue: selectedm?.queryValue,
                                    })
                                    setmodal(!modal)
                                    settoggleMetricBackTest(false)
                                    // setnewFiltersList(upDatedFilters)
                                    allocate(upDatedFilters)
                                } else {
                                    alert("Metric already added")
                                }
                            }}>Add rule</button></div>]}

                        bodyStyle={{ display: "flex", flex: 1, height: "100%", padding: 0, overflowY: "scroll" }} style={{ display: "flex", flexDirection: "column", flex: 1, height: "100%" }}
                        title={<span style={{ display: "flex", alignItems: "center" }}>
                            <span className='textLG w-500' style={{ flex: 1 }}>Select Metric</span>
                            <IoIosClose onClick={() => {
                                setmodal(!modal)
                                settoggleMetricBackTest(false)
                            }}
                                size={"1.75rem"} style={{ cursor: "pointer" }} />
                        </span>}
                        bordered>
                        <Card bordered bodyStyle={{ display: "flex", flexDirection: "column", flex: 1, height: "100%", paddingRight: 0, overflowY: "scroll" }} style={{ display: "flex", flexDirection: "column", flex: 1.2, height: "100%", borderRadius: 0, borderWidth: 0.5 }}>
                            {screenerFinalData.map((item, index) => {
                                if (item.isVisibleNormally === true) {
                                    return renderRow({
                                        item, index
                                    })
                                } else {
                                    return <></>
                                }
                            }
                            )}
                        </Card>
                        <Card bordered bodyStyle={{ display: "flex", flexDirection: "column", flex: 1, height: "100%", overflowY: "scroll" }} style={{ display: "flex", flexDirection: "column", flex: 1.1, height: "100%", borderRadius: 0, borderWidth: 0.5 }}>
                            <RenderIntialFlatlist item={screenerFinalData.filter((i) => i.category_id === selectedCategory)[0]} />
                        </Card>
                        <Card bordered bodyStyle={{ display: "flex", flexDirection: "column", flex: 1, height: "100%", overflowY: "scroll", padding: 0 }} style={{ display: "flex", flexDirection: "column", flex: 2.7, height: "100%", borderRadius: 0, borderWidth: 0.5 }}>
                            {selectedm?.metric_name !== undefined ? <>
                                <DrawerMetricDescComp />
                                {/* <Divider style={{ marginBottom: "0.5rem" }} />
                                {checkFiltersList.length > 0 && <CheckedFilterView />} */}
                            </> : <div className="mt-5" style={{ display: "flex", flexDirection: "column", flex: 1, height: "100%", alignItems: "center" }}>
                                <img
                                    src='https://cdn-icons-png.flaticon.com/512/3344/3344553.png'
                                    height={80}
                                    width={80}
                                />
                                <br></br>
                                <div>Please select a metric</div>
                            </div>
                            }
                        </Card>
                    </Card>

                    <Drawer
                        width={800}
                        height={"40vh"}
                        title={<span className='fontH1 black m-1 mb-5' >{toggleMetricBackTest ? <>
                            <span>'{selectedm?.metric_name}' metric backtest</span>
                        </> : "Select metric"}</span>} placement="right"
                        onClose={() => {
                            //setmodal(!modal)
                            settoggleMetricBackTest(false)
                        }}
                        open={toggleMetricBackTest}
                        footerStyle={{ textAlign: 'right' }}
                        footer={<button className="btnPrimary ms-auto" onClick={() => {
                            settoggleMetricBackTest(false)
                        }}>Close</button>}>
                        <div className="">
                            <div className='p-2' style={{ display: "flex", flexDirection: "column", gap: "20px" }}>

                                <div className='' style={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
                                    <span className='textSM w-500' >Select stock universe</span>

                                    <Select removeIcon={<IoIosCloseCircleOutline size={12} />}
                                        className='textSM black '
                                        style={{
                                            width: "250px"
                                        }}
                                        suffixIcon={<MdArrowDropDown size={17} />}
                                        defaultValue={selectedStockUniverseMetricBack}
                                        placeholder="Please select stock universe"
                                        onChange={(value) => {
                                            setselectedStockUniverseMetricBack([value])
                                        }}
                                        options={stock_universe.filter((i) => i.value !== 'all')}
                                    />
                                </div>

                                {Object.keys(metricbackTestRes).length !== 0 && selectedm?.metric_col_code === metricbackTestResName ?
                                    <PerformanceChart2 portfolioType={-1} defYrSelect="max" xAxisKey={metricbackTestResName === "style_box_code" ? "Date" : "date"} chartData={metricbackTestRes} defAmtSelect={100000} />
                                    : <ActivityLoaderMintbox />}

                            </div>
                        </div>
                    </Drawer>
                </Drawer>

                <Modal
                    title={<span className='textLG w-700' >Save signal</span>}
                    centered
                    open={modalSave}
                    okButtonProps={{
                        className: "btnPrimary"
                    }}
                    cancelButtonProps={{
                        className: "btnSecondary"
                    }}
                    onOk={() => {
                        saveScreener()
                    }}
                    closable={false}
                    onCancel={() => setmodalSave(!modalSave)}
                    width={"30vw"}
                    zIndex={1000}
                    style={{ top: -70 }}
                    okText={"Save"}
                    cancelText={"Cancel"}
                >

                    <div style={{ display: "flex", flexDirection: "column", gap: "1.25rem", marginTop: "1rem" }}>
                        <div style={{ display: "flex", gap: "1.25rem" }}>
                            <span className='d-flex gap-3 align-items-center'><span className='textXS w-500 dark3'>Alert</span><Switch checked={alertsEnabled} onChange={(e) => {
                                setalertsEnabled(!alertsEnabled)
                            }} /></span>
                            <span className='d-flex gap-3 align-items-center'><span className='textXS w-500 dark3'>Also use as checklist</span><Switch checked={saveAsChecklist} onChange={(e) => {
                                setsaveAsChecklist(!saveAsChecklist)
                            }} /></span>
                        </div>

                        <span className='d-flex flex-column gap-1'>
                            <span className='textXS w-500 dark3'>Signal Name</span>
                            <Input size='large' value={screener_name} onChange={(e) => {
                                setscreener_name(e.target.value)
                            }} placeholder='Enter your signal name' />
                        </span>

                        <span className='d-flex flex-column gap-1'>
                            <span className='textXS w-500 dark3'>Description <span className='dark1'>(Optional)</span></span>
                            <TextArea size='large' rows={4} value={screener_desc} onChange={(e) => {
                                setscreener_desc(e.target.value)
                            }} placeholder='Enter your signal description' />
                        </span>

                    </div>
                </Modal>

                <Drawer
                    width={"80vw"}
                    title={<span className='fontH1 black m-1 mb-5' >Backtest results</span>} placement="right"
                    onClose={() => {
                        setbackTestModal(!backTestModal)
                    }}
                    open={backTestModal}
                    footerStyle={{}}
                    footer={false}>
                    <Row className='px-2' style={{ gap: "20px", minHeight: "90vh" }}>
                        {backTestModalLoading ? <ActivityLoaderMintbox /> :
                            Object.keys(backTestData).length !== 0 ?
                                <Tabs tabBarGutter={20} className='textSM' size='small' items={tabItems} /> : null}
                    </Row>
                </Drawer>

                <Drawer
                    width={"85vw"}
                    closable={false}
                    placement="right"
                    onClose={() => {
                        setinvestInScreenerModal(!investInScreenerModal)
                    }}

                    open={investInScreenerModal}
                    footerStyle={{}}
                    footer={false}>
                    <BuildCustomPortfolio
                        isModalView={true}
                        instrumentList={[...instrumentList]}
                        allFilteredFundsData={[...allFilteredFundsData]}
                        instrument_method={2}
                        screenerQuery={signalData.length > 0 ? signalData : []}
                        newFiltersList={newFiltersList}
                        tableColumns={tableColumns}
                        screenerMetric={screenerMetric}
                    />
                </Drawer>

            </React.Fragment >
        );
    }

}

export default Signal