import { Avatar, Button, Card, Divider, Popover, Progress, Segmented, Table, Tabs, Tag, Tooltip } from "antd";
import ActivityLoaderMintbox from "Components/ActivityLoaderMintbox.js";
import addToRecentlyViewed from "Components/addToRecentlyViewed.js";
import AMCIcon from "Components/AMCIcon";
import { CardHeader } from "Components/atoms/SmallComponents";
import { CurrencyFormat } from "Components/CurrencyFormat.js";
import CustomCollapseTabs from "Components/CustomCollapseTabs";
import CustomLabelValueCards from "Components/CustomLabelValue";
import CustomListTable from "Components/CustomListTable";
import CustomRangeSliders from "Components/CustomRangeSliders";
import CustomSegmentedTab from "Components/CustomSegmentedTab";
import CustomSideTabs from "Components/CustomSideTabs";
import CustomTag from "Components/CustomTag";
import { formatDate } from "Components/DateFormatter";
import { DecimalValueFormat } from "Components/DecimalValueFormat";
import ErrorComponent from "Components/ErrorComponent";
import { InsightsModal } from "Components/Insights";
import InstrumentListTable from "Components/InstrumentListTable";
import InstrumentPerfBenchChart from "Components/InstrumentPerfBenchChart";
import InvestmentCard from "Components/InvestmentCard";
import ordinal_suffix_of from "Components/Ordinal-suffix";
import PerformanceChart2 from "Components/PerformanceChart2";
import PriceVsNAVChart from "Components/PriceVsNAVChart";
import ReadMoreText from "Components/ReadMoreText";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Head from "Layout/Head";
import { getUser, screenerMetricData, useGetAssetType } from "Libs/utils";
import React, { useState } from "react";
import { AiFillDownCircle, AiFillMinusCircle, AiFillUpCircle, AiOutlineDown, AiOutlineLineChart, AiOutlineRight } from "react-icons/ai";
import { BsBookmark, BsExclamationCircle, BsFillBarChartLineFill, BsFillBookmarkFill, BsTable } from "react-icons/bs";
import { FiUser } from "react-icons/fi";
import { useNavigate, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { Col, Row, UncontrolledTooltip } from "reactstrap";
import apiHelper from 'Services/api/ApiHelper';
import apiHelperPY from 'Services/api/ApiHelperPY';
import { StockLivePrice } from "Services/api/LivePricesWebSocket";
import { UserOutlined } from '@ant-design/icons';
import { BiChevronDown, BiChevronRight } from "react-icons/bi";
import NewFundCard from "Components/NewFundCard";
import Slider52Week from "Components/Slider52Week";

const FundDetail = (props) => {
    const [searchParams, setSearchParams] = useSearchParams()
    const PLAN_ID = Number(searchParams.get('code'))
    const type = useGetAssetType() // 1 = etf, 2 = mf
    const navigate = useNavigate()
    const [user, setUser] = useState()

    const [loading, setLoading] = useState(true);

    const [fundData, setFundData] = useState({});
    const [fundDetail, setFundDetail] = useState({});
    const [fundHolding, setFundHolding] = useState({});
    const [fundRet, setFundRet] = useState({});
    const [mfInsights, setMFInsights] = useState({});
    const [isWatchlisted, setIsWatchlisted] = useState(false);

    const [apiError, setApiError] = useState(false)


    const [screenerMetric, setscreenerMetric] = useState([])
    const [screenerFinalData, setscreenerFinalData] = useState([])
    const [stockSectorCLF, setStockSectorCLF] = useState([])
    const [styleBoxes, setStyleBoxes] = React.useState([]);
    const [allFilteredFundsData, setallFilteredFundsData] = useState([])
    const [allFundsData, setallFundsData] = useState([])
    const [isOpen, setisOpen] = useState(0)
    const [isV1Open, setisV1Open] = useState(-1)
    const [isV2Open, setisV2Open] = useState(-1)
    const [screener_final_query, set_screener_final_query] = useState([])
    const [newFiltersList, setnewFiltersList] = useState([])
    const [mfCategories, setmfCategories] = useState([])
    const [mfAMC, setmfAMC] = useState([])
    const [stock_macro_sectors, setMacroSectors] = useState([])
    const [stock_sectors, setSectors] = useState([])
    const [stock_industries, setIndustries] = useState([])
    const [stock_basic_industries, setBasicIndustries] = useState([])

    const items = type.value === 1 ? [
        {
            label: `Performance vs Benchmark`,
            key: '1',
            value: '1',
            children: <span>
                <InstrumentPerfBenchChart
                    fundData={fundData}
                    chartData={fundDetail?.performance_snapshot?.snapshot?.prices}
                />
            </span>,
        },
        {
            label: `Price vs NAV`,
            key: '2',
            value: '2',
            children: <span>
                <PriceVsNAVChart
                    chartData={{
                        data: JSON.stringify(fundDetail?.performance_snapshot?.etf_nav_price)
                    }} />
            </span>,
        },
    ] : [
        {
            label: `Performance vs Benchmark`,
            key: '1',
            value: '1',
            children: <span>
                <InstrumentPerfBenchChart
                    fundData={fundData}
                    chartData={fundDetail?.performance_snapshot?.snapshot?.prices}
                />
            </span>,
        }
    ]
    const [selectedTab, setselectedTab] = React.useState(items.filter((i) => i !== false)[0]?.value || '');
    const [selectedTabItem, setSelectedTabItem] = React.useState('1');

    const dataTableColumns = type.value === 4 ? [
        {
            "title": "Name",
            "dataIndex": "proper_name",
            render: (text, record, index) => <div style={{ display: 'flex', gap: '12px', cursor: "pointer" }} onClick={() => navigate({ pathname: type.basePath + `${type.value === 4 ? '/stock-detail/' + record.symbol : '/fund-detail/' + record?.basic_name?.replace(" - Direct Plan", "")?.replaceAll(' ', '-') + '?code=' + record.plan_id}` })}>
                <AMCIcon amcName={record.symbol} fundType={type.value} height='1.2rem' width="1.2rem" />
                <div>
                    <div className="textSM black">{record?.proper_name}</div>
                    <div className="text-left textXS ">{stockSectorCLF?.find(s => s.industry_code === record.industry_code)?.industry_desc}</div>
                </div>
            </div>,
            width: 230,
            fixed: 'left',
            show: true
        }
    ]
        :
        [
            {
                "title": "Name",
                "dataIndex": "basic_name",
                render: (text, record, index) => <div style={{ display: 'flex', gap: '12px', cursor: "pointer" }} onClick={() => navigate({ pathname: type.basePath + `${type.value === 4 ? '/stock-detail/' + record.symbol : '/fund-detail/' + record?.basic_name?.replace(" - Direct Plan", "")?.replaceAll(' ', '-') + '?code=' + record.plan_id}` })}>
                    <AMCIcon amcName={record.amc_full_name} height='1.2rem' width="1.2rem" />
                    <div>
                        <div className="textSM black">{record?.basic_name?.replace(" - Direct Plan", "")}</div>
                        <div className="text-left textXS ">{record?.category_name}</div>
                    </div>
                </div>,
                width: 230,
                fixed: 'left',
                show: true
            }
        ]

    React.useEffect(() => {
        const dem = async () => {
            setLoading(true)
            var screener_datas = await screenerMetricData(type)

            setisOpen(screener_datas?.levelVals?.level1)
            setisV1Open(screener_datas?.levelVals?.level2)
            setisV2Open(screener_datas?.levelVals?.level3)
            setStockSectorCLF(screener_datas?.fabricatedData?.stockSectorCLF)
            setStyleBoxes(screener_datas?.fabricatedData?.stockStyles)
            setMacroSectors(screener_datas?.fabricatedData?.macroSectors)
            setSectors(screener_datas?.fabricatedData?.sectors)
            setIndustries(screener_datas?.fabricatedData?.industries)
            setBasicIndustries(screener_datas?.fabricatedData?.basicIndustries)
            setmfCategories(screener_datas?.fabricatedData?.mf_categories)
            setmfAMC(screener_datas?.fabricatedData?.mf_amcs)
            setscreenerMetric(screener_datas?.screenerMetricData)
            setscreenerFinalData(screener_datas?.screenerFinalData)
            setallFundsData(screener_datas?.allFunds)
            setallFilteredFundsData(screener_datas?.allFunds)

            let a = screener_datas?.allFunds
            const result = a.filter(x => x.plan_id === PLAN_ID);
            setFundData(result[0])
            console.log(type.value, PLAN_ID, result[0])
            await getUser().then(async (u) => {
                if (u) {
                    setUser(u)
                    const watchlist = await apiHelper({ apiName: 'getWatchList', data: { id: u.id }, saveResponse: 'sessionStorage' })
                    if (watchlist) {
                        setIsWatchlisted(watchlist.some(f => f.FundType === type.value && f.FundId === result?.[0]?.isin_code))
                    }
                    console.log(watchlist)
                }
            })
            // if (Object.keys(result?.[0] || {})?.length) {
            //     addToRecentlyViewed({
            //         type: type.value,
            //         ID: result[0].plan_id,
            //         Name: result[0].basic_name,
            //         CAGR: result[0].cagr_5y,
            //         Category: result[0].category_name,
            //         AMCName: result[0].amc_full_name,
            //         MinInv: result[0].min_initial_investment
            //     })
            // }
            let res = await apiHelperPY({ apiName: 'getFundsDetails', data: { id: PLAN_ID }, saveResponse: '' })
            if (res) {
                res = await JSON.parse(res.replace(/NaN/g, null))
                console.log('getFundsDetails', res)
                setFundDetail(res)
            }
            else {
                setApiError(true)
                setFundDetail({})
            }
            let res1 = await apiHelperPY({ apiName: 'getFundsHolding', data: { id: PLAN_ID }, saveResponse: '' })
            if (res1) {
                res1 = await JSON.parse(res1.replace(/NaN/g, null))
                console.log('getFundsHolding', res1)
                setFundHolding(res1)
            }
            else {
                setApiError(true)
                setFundHolding({})
            }
            let res2 = await apiHelperPY({ apiName: 'getFundsRet', data: { id: PLAN_ID }, saveResponse: '' })
            if (res2) {
                res2 = await JSON.parse(res2.replace(/NaN/g, null))
                // console.log('getFundsRet', res2)
                setFundRet(res2)
            }
            else {
                setApiError(true)
                setFundRet({})
            }
            let res4 = await apiHelperPY({ apiName: 'getMFInsights', data: { planId: PLAN_ID, assetType: result[0]?.primary_category_name?.toLowerCase() }, saveResponse: '' })
            if (res4) {
                console.log("mf_insights", res4)
                setMFInsights(res4)
            }
            else {
                // setApiError(true)
                setMFInsights({})
            }
            setLoading(false)
        }
        dem();
    }, [PLAN_ID])

    const updateWatchlist = async () => {
        console.log(user)
        if (user) {
            const data = {
                "clientId": user.id,
                "fundType": type.value,
                "fundCode": type.value === 4 ? fundData.nse_symbol : fundData.isin_code,
                "fundId": fundData.isin_code + "",
                "isActive": isWatchlisted ? 0 : 1
            }
            await apiHelper({ apiName: 'updateWatchList', data: data })
            await apiHelper({ apiName: 'getWatchList', data: { id: user.id }, saveResponse: 'sessionStorage', getFresh: true })
            setIsWatchlisted(!isWatchlisted)
        } else {
            navigate('/auth-login')
        }
    }

    const performance_summary = Object.keys(fundRet || {}).length > 1 ? [
        {
            key: '1',
            heading: 'AUM',
            value: CurrencyFormat(fundDetail?.overview?.aum_data_scheme?.aum, "long", 0) + "Cr",
            tooltip: '',
            show: fundDetail?.overview?.aum_data_scheme?.aum || false,
        },
        {
            key: '2',
            heading: 'CAGR',
            value: DecimalValueFormat({
                num: fundDetail?.overview?.cagr_since_inception,
                suffix: "%"
            }),
            show: fundDetail?.overview?.cagr_since_inception || false
        },
        {
            key: '3',
            heading: 'NAV',
            value: CurrencyFormat(fundDetail?.overview?.latest_nav?.nav, "long", 2),
            tooltip: 'content content',
            show: fundDetail?.overview?.latest_nav?.nav || false
        },
        {
            key: '4',
            heading: 'Expense ratio',
            value: DecimalValueFormat({
                num: fundDetail?.overview?.expense_data_scheme?.expense_ratio,
                suffix: "%"
            }),
            tooltip: 'content content',
            show: fundDetail?.overview?.expense_data_scheme?.expense_ratio || false
        },
        {
            key: '5',
            heading: 'Inception',
            value: formatDate(fundDetail?.overview?.inception),
            tooltip: 'content content',
            show: fundDetail?.overview?.inception || false
        },
        {
            key: '6',
            heading: 'Max drawdown',
            value: DecimalValueFormat({
                num: fundRet?.summary?.max_drawdown,
                suffix: "%"
            }),
            tooltip: 'content content',
            show: fundRet?.summary?.max_drawdown || false
        },
        {
            key: 'a',
            heading: 'Alpha',
            value: DecimalValueFormat({
                num: fundRet?.summary.ratios.alpha,
            }),
            tooltip: 'content content',
            show: fundRet?.summary?.ratios?.alpha || false
        },
        {
            key: 'b',
            heading: 'Beta',
            value: DecimalValueFormat({
                num: fundRet?.summary.ratios.beta,
            }),
            tooltip: 'content content',
            show: fundRet?.summary?.ratios?.beta || false
        },
        {
            key: 'c',
            heading: 'Sharpe ratio',
            value: DecimalValueFormat({
                num: fundRet?.summary.ratios.sharpe,
            }),
            tooltip: 'content content',
            show: fundRet?.summary?.ratios?.sharpe || false
        },
        {
            key: 'd',
            heading: 'Annualized Volatility',
            value: DecimalValueFormat({
                num: fundRet?.summary?.annualized_vol,
                suffix: "%"
            }),
            tooltip: 'content content',
            show: fundRet?.summary?.annualized_vol || false
        },
    ] : []

    const TrackingDifference = () => {
        const dataSource = [
            {
                'key': 'Fund',
                'name': 'Fund',
                '1y': fundRet?.tracking_diff?.scheme["td_1y"],
                '3y': fundRet?.tracking_diff?.scheme["td_3y"],
                '5y': fundRet?.tracking_diff?.scheme["td_5y"],
                '10y': fundRet?.tracking_diff?.scheme["td_10y"],
            },
            {
                'key': 'Avg. of fund with same benchmark',
                'name': 'Avg. of fund with same benchmark',
                '1y': fundRet?.tracking_diff?.same_bm_avg["td_1y"],
                '3y': fundRet?.tracking_diff?.same_bm_avg["td_3y"],
                '5y': fundRet?.tracking_diff?.same_bm_avg["td_5y"],
                '10y': fundRet?.tracking_diff?.same_bm_avg["td_10y"],
            }
        ];
        const columns = [
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
                width: 150,
                fixed: 'left'
            },
            {
                title: '1 Y',
                dataIndex: '1y',
                key: '1y',
                align: "right",
                render: (text) => DecimalValueFormat({ num: Number(text), suffix: "%", placeholder: "-" })
            },
            {
                title: '3 M',
                dataIndex: '3y',
                key: '3y',
                align: "right",
                render: (text) => DecimalValueFormat({ num: Number(text), suffix: "%", placeholder: "-" })
            },
            {
                title: '5 Y',
                dataIndex: '5y',
                key: '5y',
                align: "right",
                render: (text) => DecimalValueFormat({ num: Number(text), suffix: "%", placeholder: "-" })
            },
            {
                title: '10 Y',
                dataIndex: '10y',
                key: '10y',
                align: "right",
                render: (text) => DecimalValueFormat({ num: Number(text), suffix: "%", placeholder: "-" })
            },
        ]
        return <Table dataSource={dataSource} columns={columns} size={'small'} pagination={false} />
    }

    const TrailingReturns = () => {
        const TrailingReturnsTable = () => {
            const showRank = ({ period }) => {
                const rank = fundRet?.trailing_returns?.scheme_rank?.[`rank_${period}`]
                const count = fundRet?.trailing_returns?.category_count?.[`count_${period}`]
                console.log(period, rank, count)
                if (rank && count) {
                    return rank + ' / ' + count
                } else {
                    return '-'
                }
            }
            const dataSource = [
                {
                    'key': 'Fund',
                    'name': 'Fund',
                    'ytd': DecimalValueFormat({ num: Number(fundRet?.trailing_returns?.scheme?.["ret_ytd"]), suffix: "%", placeholder: "-" }),
                    '1m': DecimalValueFormat({ num: Number(fundRet?.trailing_returns?.scheme?.["ret_1month"]), suffix: "%", placeholder: "-" }),
                    '6m': DecimalValueFormat({ num: Number(fundRet?.trailing_returns?.scheme?.["ret_6month"]), suffix: "%", placeholder: "-" }),
                    '1y': DecimalValueFormat({ num: Number(fundRet?.trailing_returns?.scheme?.["ret_1year"]), suffix: "%", placeholder: "-" }),
                    '3y': DecimalValueFormat({ num: Number(fundRet?.trailing_returns?.scheme?.["ret_3year"]), suffix: "%", placeholder: "-" }),
                    '5y': DecimalValueFormat({ num: Number(fundRet?.trailing_returns?.scheme?.["ret_5year"]), suffix: "%", placeholder: "-" }),
                    '10y': DecimalValueFormat({ num: Number(fundRet?.trailing_returns?.scheme?.["ret_10year"]), suffix: "%", placeholder: "-" }),
                },
                {
                    'key': fundDetail?.overview?.category_name,
                    'name': fundDetail?.overview?.category_name,
                    'ytd': DecimalValueFormat({ num: Number(fundRet?.trailing_returns?.category?.["ret_ytd"]), suffix: "%", placeholder: "-" }),
                    '1m': DecimalValueFormat({ num: Number(fundRet?.trailing_returns?.category?.["ret_1month"]), suffix: "%", placeholder: "-" }),
                    '6m': DecimalValueFormat({ num: Number(fundRet?.trailing_returns?.category?.["ret_6month"]), suffix: "%", placeholder: "-" }),
                    '1y': DecimalValueFormat({ num: Number(fundRet?.trailing_returns?.category?.["ret_1year"]), suffix: "%", placeholder: "-" }),
                    '3y': DecimalValueFormat({ num: Number(fundRet?.trailing_returns?.category?.["ret_3year"]), suffix: "%", placeholder: "-" }),
                    '5y': DecimalValueFormat({ num: Number(fundRet?.trailing_returns?.category?.["ret_5year"]), suffix: "%", placeholder: "-" }),
                    '10y': DecimalValueFormat({ num: Number(fundRet?.trailing_returns?.category?.["ret_10year"]), suffix: "%", placeholder: "-" }),
                }, {
                    'key': 'rank',
                    'name': 'Category rank',
                    'ytd': showRank({ period: 'ytd' }),
                    '1m': showRank({ period: '1month' }),
                    '6m': showRank({ period: '6month' }),
                    '1y': showRank({ period: '1year' }),
                    '3y': showRank({ period: '3year' }),
                    '5y': showRank({ period: '5year' }),
                    '10y': showRank({ period: '10year' }),
                },
                // {
                //     'key': fundDetail?.overview?.benchmark_name,
                //     'name': fundDetail?.overview?.benchmark_name,
                //     'ytd': fundRet?.trailing_returns?.benchmark?.["benchmark_ytd"],
                //     '1m': fundRet?.trailing_returns?.benchmark?.["benchmark_1month"],
                //     '6m': fundRet?.trailing_returns?.benchmark?.["benchmark_6month"],
                //     '1y': fundRet?.trailing_returns?.benchmark?.["benchmark_1year"],
                //     '3y': fundRet?.trailing_returns?.benchmark?.["benchmark_3year"],
                //     '5y': fundRet?.trailing_returns?.benchmark?.["benchmark_5year"],
                //     '10y': fundRet?.trailing_returns?.benchmark?.["benchmark_10year"],
                // },
            ];
            const columns = [
                {
                    title: 'Name',
                    dataIndex: 'name',
                    key: 'name',
                    width: 150,
                    fixed: 'left'
                },
                {
                    title: 'YTD',
                    dataIndex: 'ytd',
                    key: 'ytd',
                    align: "right",
                },
                {
                    title: '1 M',
                    dataIndex: '1m',
                    key: '1m',
                    align: "right",
                },
                {
                    title: '6 M',
                    dataIndex: '6m',
                    key: '6m',
                    align: "right",
                },
                {
                    title: '1 Y',
                    dataIndex: '1y',
                    key: '1y',
                    align: "right",
                },
                {
                    title: '3 M',
                    dataIndex: '3y',
                    key: '3y',
                    align: "right",
                },
                {
                    title: '5 Y',
                    dataIndex: '5y',
                    key: '5y',
                    align: "right",
                },
                {
                    title: '10 Y',
                    dataIndex: '10y',
                    key: '10y',
                    align: "right",
                },
            ]
            return <Table dataSource={dataSource} columns={columns} size={'small'} pagination={false} />
        }

        const TrailingReturnsChart = () => {
            var data = fundRet?.trailing_returns
            console.log(data)
            const xAxisKeys = [
                { key: 'ret_ytd', value: 'YTD' },
                { key: 'ret_1month', value: '1 M' },
                { key: 'ret_6month', value: '6 M' },
                { key: 'ret_1year', value: '1 Y' },
                { key: 'ret_3year', value: '3 Y' },
                { key: 'ret_5year', value: '5 Y' },
                { key: 'ret_10year', value: '10 Y' },
            ];
            console.log("cat", xAxisKeys.map(x => data?.category?.[x.key]))


            try {
                var conf = {
                    chart: {
                        backgroundColor: "transparent",
                        type: 'column',
                        // height: 300,
                    },
                    title: {
                        text: ''
                    },
                    exporting: {
                        enabled: false
                    },
                    yAxis: {
                        tickAmount: 10,
                        // tickInterval: 5,
                        gridLineDashStyle: 'longdash',
                        title: {
                            text: ''
                        },
                        gridLineColor: "var(--border)",
                    },
                    xAxis: {
                        categories: xAxisKeys.map(x => x.value),
                        lineColor: "var(--border)",
                        tickColor: "var(--border)",
                    }
                    ,
                    legend: {
                        enabled: true
                    },
                    plotOptions: {
                        series: {
                            lineWidth: 1.5,
                            label: {
                                connectorAllowed: false
                            },
                            marker: {
                                enabled: false
                            }
                        },
                        column: {
                            pointPadding: 0,
                            borderWidth: 0,
                            // groupPadding: 0.05,
                            pointWidth: 20
                        }
                    },
                    tooltip: {
                        // shared: true,
                        valueDecimals: 2,
                        // valuePrefix: '₹',
                    },
                    credits: {
                        enabled: false
                    },
                    boost: {
                        useGPUTranslations: true,
                    },
                    series: [
                        {
                            name: "Scheme", turboThreshold: 9e9,
                            color: '#83BF6E',
                            tooltip: {
                                valueSuffix: ' %',
                            },
                            data: xAxisKeys.map(x => data?.scheme?.[x.key])
                        },
                        {
                            name: `Category (${fundDetail?.overview?.category_name})`, turboThreshold: 9e9,
                            color: '#007AFF',
                            tooltip: {
                                valueSuffix: ' %',
                            },
                            data: xAxisKeys.map(x => data?.category?.[x.key])
                        }
                    ],
                    scrollbar: {
                        enabled: false
                    },
                    responsive: {
                        rules: [{
                            condition: {
                                maxWidth: 400
                            },
                        }]
                    },

                };
            } catch (error) {
                console.log("SDF", error)
            }

            return <Card bodyStyle={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>

                <div style={{ width: '40rem' }}><HighchartsReact highcharts={Highcharts} options={conf} /></div>
            </Card>
        }
        const items = [
            {
                key: '1',
                value: '1',
                label: <BsTable size={'1rem'} />,
                children: <TrailingReturnsTable />
            },
            {
                key: '2',
                value: '2',
                label: <BsFillBarChartLineFill size={'1rem'} />,
                children: <TrailingReturnsChart />
            },
        ]
        const [selectedTab, setselectedTab] = React.useState('1')

        return <>
            <div style={{ position: 'absolute', right: 0, top: 0, padding: '1.25rem' }}>
                <Segmented
                    value={selectedTab}
                    onChange={(e) => {
                        setselectedTab(e)
                    }}
                    options={items}
                    className='performanceChartYrSegmented'
                    style={{ backgroundColor: "var(--white)", border: "1px solid var(--grey3)" }}

                />
            </div>
            <div style={{ marginTop: '12px' }}>
                {items.filter((i) => i.value === selectedTab)[0]?.children}
            </div>
        </>
    }

    const alphaBetaSharpe = [{
        title: 'Alpha',
        description: 'Alpha is the excess returns relative to market benchmark for a given amount of risk taken by the scheme',
        currentVal: fundRet?.category_ratios?.alpha_average,
        currentValStr: fundRet?.category_ratios?.alpha_average?.toFixed(2) + "%",
        min: Math.min(...fundRet?.category_ratios?.alpha_list || []),
        max: Math.max(...fundRet?.category_ratios?.alpha_list || []),
        minStr: Math.min(...fundRet?.category_ratios?.alpha_list || [])?.toFixed(2) + "%",
        maxStr: Math.max(...fundRet?.category_ratios?.alpha_list || [])?.toFixed(2) + "%",
        minStrLabel: 'Category lowest',
        maxStrLabel: 'Category highest',
        show: fundRet?.category_ratios?.alpha_list?.length > 1
    },
    {
        title: 'Beta',
        description: 'Beta measures the expected move in a stock relative to movements in the overall market',
        currentVal: fundRet?.category_ratios?.beta_average,
        currentValStr: fundRet?.category_ratios?.beta_average?.toFixed(2) + "%",
        min: Math.min(...fundRet?.category_ratios?.beta_list || []),
        max: Math.max(...fundRet?.category_ratios?.beta_list || []),
        minStr: Math.min(...fundRet?.category_ratios?.beta_list || [])?.toFixed(2) + "%",
        maxStr: Math.max(...fundRet?.category_ratios?.beta_list || [])?.toFixed(2) + "%",
        minStrLabel: "Category lowest",
        maxStrLabel: "Category highest",
        show: fundRet?.category_ratios?.beta_list?.length > 1
    },
    {
        title: 'Sharpe',
        description: 'The Sharpe ratio compares the return of an investment with its risk',
        currentVal: fundRet?.category_ratios?.sharpe_average,
        currentValStr: fundRet?.category_ratios?.sharpe_average?.toFixed(2) + "%",
        min: Math.min(...fundRet?.category_ratios?.sharpe_list || []),
        max: Math.max(...fundRet?.category_ratios?.sharpe_list || []),
        minStr: Math.min(...fundRet?.category_ratios?.sharpe_list || [])?.toFixed(2) + "%",
        maxStr: Math.max(...fundRet?.category_ratios?.sharpe_list || [])?.toFixed(2) + "%",
        minStrLabel: "Category lowest",
        maxStrLabel: "Category highest",
        show: fundRet?.category_ratios?.sharpe_list?.length > 1
    }
    ]

    const RollingReturns = () => {
        const dataSource = [
            {
                'key': 'Average',
                'name': 'Average',
                '1y': fundRet?.rolling_returns?.["1y"] !== undefined ? fundRet?.rolling_returns?.["1y"]["avg"] : "",
                '3y': fundRet?.rolling_returns?.["3y"] !== undefined ? fundRet?.rolling_returns?.["3y"]["avg"] : "",
                '5y': fundRet?.rolling_returns?.["5y"] !== undefined ? fundRet?.rolling_returns?.["5y"]["avg"] : ""
            },
            {
                'key': 'Standard deviation',
                'name': 'Standard deviation',
                '1y': fundRet?.rolling_returns?.["1y"] !== undefined ? fundRet?.rolling_returns?.["1y"]["std"] : "",
                '3y': fundRet?.rolling_returns?.["3y"] !== undefined ? fundRet?.rolling_returns?.["3y"]["std"] : "",
                '5y': fundRet?.rolling_returns?.["5y"] !== undefined ? fundRet?.rolling_returns?.["5y"]["std"] : ""
            },
            {
                'key': 'Best',
                'name': 'Best',
                '1y': fundRet?.rolling_returns?.["1y"] !== undefined ? fundRet?.rolling_returns?.["1y"]["best"] : "",
                '3y': fundRet?.rolling_returns?.["3y"] !== undefined ? fundRet?.rolling_returns?.["3y"]["best"] : "",
                '5y': fundRet?.rolling_returns?.["5y"] !== undefined ? fundRet?.rolling_returns?.["5y"]["best"] : ""
            },
            {
                'key': 'Worst',
                'name': 'Worst',
                '1y': fundRet?.rolling_returns?.["1y"] !== undefined ? fundRet?.rolling_returns?.["1y"]["worst"] : "",
                '3y': fundRet?.rolling_returns?.["3y"] !== undefined ? fundRet?.rolling_returns?.["3y"]["worst"] : "",
                '5y': fundRet?.rolling_returns?.["5y"] !== undefined ? fundRet?.rolling_returns?.["5y"]["worst"] : ""
            },
        ];
        const columns = [
            {
                title: '',
                dataIndex: 'name',
                key: 'name',
                width: 150,
                fixed: 'left'
            },
            {
                title: '1 Y',
                dataIndex: '1y',
                key: '1y',
                align: "right",
                render: (text) => DecimalValueFormat({ num: Number(text), suffix: "%", placeholder: "-" })
            },
            {
                title: '3 M',
                dataIndex: '3y',
                key: '3y',
                align: "right",
                render: (text) => DecimalValueFormat({ num: Number(text), suffix: "%", placeholder: "-" })
            },
            {
                title: '5 Y',
                dataIndex: '5y',
                key: '5y',
                align: "right",
                render: (text) => DecimalValueFormat({ num: Number(text), suffix: "%", placeholder: "-" })
            }
        ]
        return <Table dataSource={dataSource} columns={columns} size={'small'} pagination={false} />
    }
    const WorstDrawdowns = () => {
        const dataSource = fundRet?.worst_5_drawdowns
        const columns = [
            {
                title: 'Started on',
                dataIndex: 'Started',
                key: 'Started',
                width: 150,
                fixed: 'left'
            },
            {
                title: 'Drawdown',
                dataIndex: 'Drawdown',
                key: 'Drawdown',
                align: "right",
                render: (text) => DecimalValueFormat({ num: Number(text), suffix: "%", placeholder: "-" })
            },
            {
                title: 'Lasted for (days)',
                dataIndex: 'Days',
                key: 'Days',
                align: "right",
            },
            {
                title: 'Recovered on',
                dataIndex: 'Recovered',
                key: 'Recovered',
                align: "right",
            }
        ]
        return <Table dataSource={dataSource} columns={columns} size={'small'} pagination={false} />
    }

    const basic_details = [{
        key: 'bb',
        heading: 'Fund house',
        tooltip: 'content content',
        value: fundDetail?.overview?.amc_full_name,
    }, {
        key: 'a',
        heading: 'Inception',
        value: formatDate(fundDetail?.overview?.inception),
        tooltip: 'content content',
        show: fundDetail?.overview?.inception || false
    }, {
        key: 'b',
        heading: 'Benchmark',
        tooltip: 'content content',
        value: fundDetail?.overview?.benchmark_name || "-",
    }, {
        key: 'cc',
        heading: 'CAGR (since inception)',
        value: DecimalValueFormat({
            num: fundDetail?.overview?.cagr_since_inception || "-",
            suffix: "%"
        }),
        tooltip: 'content content',
    }, {
        key: 'c',
        heading: 'Expense ratio',
        value: DecimalValueFormat({
            num: fundDetail?.overview?.expense_data_scheme?.expense_ratio || "-",
            suffix: "%"
        }),
        tooltip: 'content content',
    }, {
        key: 'd',
        heading: 'Max Drawdown',
        value: DecimalValueFormat({
            num: fundRet?.summary?.max_drawdown,
            suffix: "%"
        }),
        tooltip: 'content content',
        show: fundRet?.summary?.max_drawdown || false
    }, {
        key: 'e',
        heading: `AUM (as on ${formatDate(new Date(new Date().setDate(0)).toISOString())})`,
        value: CurrencyFormat(fundDetail?.overview?.aum_data_scheme?.aum) + " Cr",
        tooltip: 'content content',
    }, {
        key: 'f',
        heading: 'Tracking Error',
        value: DecimalValueFormat({
            num: fundDetail?.overview?.scheme_te,
            suffix: "%"
        }),
        tooltip: 'content content',
        show: fundDetail?.overview?.is_index_fund === 1 || fundDetail?.overview?.is_etf === 1
    }, {
        key: 'g',
        heading: 'Avg. daily traded value',
        value: CurrencyFormat(fundDetail?.overview?.scheme_volume),
        tooltip: 'content content',
        show: fundDetail?.overview?.is_etf === 1
    }]

    const exit_lock_tax_sub = [{
        key: '1',
        heading: 'Lock in',
        value: fundDetail?.overview?.is_lock_in === 1 ? fundDetail?.overview?.lock_in_period : "No lock in",
        tooltip: 'content content',
    }, {
        key: '2',
        heading: 'Taxation',
        value: fundDetail?.overview?.taxation?.allocation || "-",
        tooltip: 'content content',
        /* <Tooltip width={240} overlayColor='#00000011' backgroundColor={t.colors.background} popover={<div style={{ ...s.font12DarkNormal }}>{fundDetail?.overview?.risk_desc}</div>}>
            <Icon type='ionicon' color={t.colors.primary} name='information-circle-outline' size={14} />
        </Tooltip> */
    }, {
        key: '3',
        heading: 'Exit load',
        value: fundDetail?.overview?.exit_load.note || "-",
        tooltip: 'content content',
    }, {
        key: 'a',
        heading: 'Min. Investment',
        value: CurrencyFormat(fundDetail?.overview?.min_initial_investment),
        tooltip: 'content content',
    }, {
        key: 'b',
        heading: 'Min. Addl Investment',
        value: CurrencyFormat(fundDetail?.overview?.min_subsequent_investment),
        tooltip: 'content content',
    }, {
        key: 'bb',
        heading: 'Min. SIP Investment',
        value: CurrencyFormat(fundDetail?.overview?.min_subsequent_sip_investment),
        tooltip: 'content content',
    }, {
        key: 'bbx',
        heading: 'Min. Withdrawal',
        value: CurrencyFormat(fundDetail?.overview?.min_withdrawl_amount),
        tooltip: 'content content',
    }, {
        key: 'bbs',
        heading: 'Min. Balance',
        value: CurrencyFormat(fundDetail?.overview?.min_balance),
        tooltip: 'content content',
    }]

    const CategoryRankView = () => {
        const CustomSmallProgressBar = ({ rank, total, tooltipContent }) => {
            let percentage = 100 - ((rank - 1) * 100) / total
            let color = 'var(--yellow)' //yellow
            if (percentage < 33.33) {
                color = 'var(--red)'
            } else if (percentage > 66.66) {
                color = 'var(--green)'
            }
            return <Popover content={tooltipContent} title="" placement="top">
                <div>
                    <Progress percent={percentage} size="large" format={(per) => <span className="textSM w-500 dark3">{per.toFixed(0) + '%'}</span>}
                        strokeColor={color} strokeWidth={'0.5rem'}
                    />
                </div>
            </Popover>
        }
        const dataSource = [{
            duration: '1 month',
            rank: fundRet?.trailing_returns?.scheme_rank?.["rank_1month"],
            total: fundRet?.trailing_returns?.category_count?.["count_1month"],
            show: fundRet?.trailing_returns?.scheme_rank?.["rank_1month"] !== null
        }, {
            duration: '6 month',
            rank: fundRet?.trailing_returns?.scheme_rank?.["rank_6month"],
            total: fundRet?.trailing_returns?.category_count?.["count_6month"],
            show: fundRet?.trailing_returns?.scheme_rank?.["rank_6month"] !== null
        }, {
            duration: 'YTD',
            rank: fundRet?.trailing_returns?.scheme_rank?.["rank_ytd"],
            total: fundRet?.trailing_returns?.category_count?.["count_ytd"],
            show: fundRet?.trailing_returns?.scheme_rank?.["rank_ytd"] !== null
        }, {
            duration: '1 year',
            rank: fundRet?.trailing_returns?.scheme_rank?.["rank_1year"],
            total: fundRet?.trailing_returns?.category_count?.["count_1year"],
            show: fundRet?.trailing_returns?.scheme_rank?.["rank_1year"] !== null
        }, {
            duration: '3 year',
            rank: fundRet?.trailing_returns?.scheme_rank?.["rank_3year"],
            total: fundRet?.trailing_returns?.category_count?.["count_3year"],
            show: fundRet?.trailing_returns?.scheme_rank?.["rank_3year"] !== null
        }, {
            duration: '5 year',
            rank: fundRet?.trailing_returns?.scheme_rank?.["rank_5year"],
            total: fundRet?.trailing_returns?.category_count?.["count_5year"],
            show: fundRet?.trailing_returns?.scheme_rank?.["rank_5year"] !== null
        }, {
            duration: '10 year',
            rank: fundRet?.trailing_returns?.scheme_rank?.["rank_10year"],
            total: fundRet?.trailing_returns?.category_count?.["count_10year"],
            show: fundRet?.trailing_returns?.scheme_rank?.["rank_10year"] !== null
        }]
        const columns = [
            {
                title: '',
                dataIndex: 'duration',
                fixed: 'left',
                render: (text) => <div className="textSM w-500 black">{text}</div>
            },
            {
                title: '',
                dataIndex: 'rank',
                align: "center",
                render: (text, record) => record.rank ? <div style={{ padding: '0 1rem' }}><CustomSmallProgressBar
                    rank={record.rank}
                    total={record.total}
                    tooltipContent={<span className="textXS">{fundData.basic_name} is ranked <b>{ordinal_suffix_of(record.rank)}</b> out of <b>{record.total}</b> {type.dname} in the <b>{fundData.category_name}</b> category</span>}
                /></div> : '-'
            }
        ]
        return <Table showHeader={false} style={{ width: '35rem' }} dataSource={dataSource} columns={columns} size={'small'} pagination={false} />
    }

    const Managers = () => {
        const ManagerDetails = ({ d }) => {
            const [show, setShow] = React.useState(false)
            return <React.Fragment key={d.index}>
                <Card bordered={false} hoverable key={d.index} style={{ marginBottom: '1rem' }} bodyStyle={{ padding: '0.875rem 1.25rem' }}
                    onClick={() => setShow(!show)}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '1rem' }}>
                        <div style={{ flex: 1, display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                            <Avatar size={'2rem'} style={{ backgroundColor: 'var(--blueShade)' }} icon={<UserOutlined style={{ color: 'var(--primary)' }} />} />
                            <div className="textSM w-500 black">{d.name}</div>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                            <div className="textXS black">{formatDate(d.since.replace("T00:00:00.000Z", ""))} - Present</div>
                            <div className="textXL black mb-1">{show ? <BiChevronDown /> : <BiChevronRight />}</div>
                        </div>
                    </div>
                    {
                        show ?
                            <>
                                <div style={{ margin: '1.25rem 0', display: 'flex', flexDirection: 'column', gap: '1rem' }} className="textSM black">
                                    <div>
                                        <div className="w-700 dark3" style={{ marginBottom: '0.5rem' }}>Education</div>
                                        <div>{d.education || "-"}</div>
                                    </div>
                                    <div>
                                        <div className="w-700 dark3" style={{ marginBottom: '0.5rem' }}>Past Experience</div>
                                        <div>{d.experience || "-"}</div>
                                    </div>
                                    {JSON.parse(d.other_funds).length > 0 ?
                                        <>
                                            <div className="w-700">Funds managed ({JSON.parse(d.other_funds).length})</div>
                                            {JSON.parse(d.other_funds).map((fund, i) => {
                                                return <Card style={{ border: '1px solid var(--grey3)', }} bodyStyle={{ padding: '0.875rem 1.25rem', display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '1rem' }}
                                                    onClick={() => navigate(type.basePath + '/fund-detail/' + fund?.basic_name?.replace(" - Direct Plan", "")?.replaceAll(' ', '-') + '?code=' + fund.plan_id)}>
                                                    <div style={{ flex: 1, display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                                                        <div className="textSM w-500 black">{fund.basic_name}</div>
                                                    </div>
                                                    <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                                                        <div className="textXS black">{formatDate(fund.date_from.replace("T00:00:00.000Z", ""))} - Present</div>
                                                        <div className="textXL black mb-1"><BiChevronRight /></div>
                                                    </div>
                                                </Card>
                                            })}
                                        </>
                                        :
                                        <></>
                                    }
                                </div>
                            </>
                            :
                            <></>
                    }
                </Card>
            </React.Fragment >
        }

        return fundDetail?.overview?.fm_details.map(d => {
            return <ManagerDetails d={d} />
        })
    }
    const categoryComparision = [
        {
            title: "AUM comparision",
            description: '',
            currentVal: fundDetail?.overview?.aum_data_scheme?.aum,
            currentValStr: CurrencyFormat(fundDetail?.overview?.aum_data_scheme?.aum, 'long', 0) + "Cr",
            min: Math.min(...fundDetail?.overview?.category_aum_list || []),
            max: Math.max(...fundDetail?.overview?.category_aum_list || []),
            minStr: CurrencyFormat(Math.min(...fundDetail?.overview?.category_aum_list || []), 'long', 0) + "Cr",
            maxStr: CurrencyFormat(Math.max(...fundDetail?.overview?.category_aum_list || []), 'long', 0) + "Cr",
            minStrLabel: "Category lowest",
            maxStrLabel: "Category highest",
            show: fundDetail?.overview?.category_aum_list?.length > 1
        },
        {
            title: 'Expense ratio comparision',
            description: '',
            currentVal: fundDetail?.overview?.expense_data_scheme?.expense_ratio,
            currentValStr: fundDetail?.overview?.expense_data_scheme?.expense_ratio?.toFixed(2) + "%",
            min: Math.min(...fundDetail?.overview?.category_expense_list || []),
            max: Math.max(...fundDetail?.overview?.category_expense_list || []),
            minStr: Math.min(...fundDetail?.overview?.category_expense_list || [])?.toFixed(2) + "%",
            maxStr: Math.min(...fundDetail?.overview?.category_expense_list || [])?.toFixed(2) + "%",
            minStrLabel: "Category lowest",
            maxStrLabel: "Category highest",
            show: fundDetail?.overview?.category_expense_list?.length > 1
        },
        {
            title: `21-day average daily traded value`,
            description: '',
            currentVal: fundDetail?.overview?.scheme_volume,
            currentValStr: CurrencyFormat(fundDetail?.overview?.scheme_volume, 'short', 0),
            min: Math.min(...fundDetail?.overview?.category_volume_list || []),
            max: Math.max(...fundDetail?.overview?.category_volume_list || []),
            minStr: CurrencyFormat(Math.min(...fundDetail?.overview?.category_volume_list || []), 'short', 0),
            maxStr: CurrencyFormat(Math.max(...fundDetail?.overview?.category_volume_list || []), 'short', 0),
            minStrLabel: "Category lowest",
            maxStrLabel: "Category highest",
            show: fundDetail?.overview?.is_etf === 1 && fundDetail?.overview?.category_volume_list?.length > 1
        },
    ]
    const benchmarkComparision = [
        {
            title: 'AUM comparision',
            description: '',
            currentVal: fundDetail?.overview?.aum_data_scheme?.aum,
            currentValStr: CurrencyFormat(fundDetail?.overview?.aum_data_scheme?.aum, 'long', 0) + "Cr",
            min: Math.min(...fundDetail?.overview?.bm_aum_list || []),
            max: Math.max(...fundDetail?.overview?.bm_aum_list || []),
            minStr: CurrencyFormat(Math.min(...fundDetail?.overview?.bm_aum_list || []), 'long', 0) + "Cr",
            maxStr: CurrencyFormat(Math.max(...fundDetail?.overview?.bm_aum_list || []), 'long', 0) + "Cr",
            minStrLabel: "Benchmark lowest",
            maxStrLabel: "Benchmark highest",
            show: fundDetail?.overview?.bm_aum_list?.length > 1
        },
        {
            title: 'Expense ratio comparision',
            description: '',
            currentVal: fundDetail?.overview?.expense_data_scheme?.expense_ratio,
            currentValStr: fundDetail?.overview?.expense_data_scheme?.expense_ratio?.toFixed(2) + "%",
            min: Math.min(...fundDetail?.overview?.bm_expense_list || []),
            max: Math.max(...fundDetail?.overview?.bm_expense_list || []),
            minStr: Math.min(...fundDetail?.overview?.bm_expense_list || [])?.toFixed(2) + "%",
            maxStr: Math.min(...fundDetail?.overview?.bm_expense_list || [])?.toFixed(2) + "%",
            minStrLabel: "Benchmark lowest",
            maxStrLabel: "Benchmark highest",
            show: fundDetail?.overview?.bm_expense_list?.length > 1
        },
        {
            title: 'Tracking error comparision',
            description: '',
            currentVal: fundDetail?.overview?.scheme_te,
            currentValStr: fundDetail?.overview?.scheme_te?.toFixed(3) + "%",
            min: Math.min(...fundDetail?.overview?.bm_te_list || []),
            max: Math.max(...fundDetail?.overview?.bm_te_list || []),
            minStr: Math.min(...fundDetail?.overview?.bm_te_list || [])?.toFixed(3) + "%",
            maxStr: Math.min(...fundDetail?.overview?.bm_te_list || [])?.toFixed(3) + "%",
            minStrLabel: "Benchmark lowest",
            maxStrLabel: "Benchmark highest",
            show: fundDetail?.overview?.bm_te_list?.length > 1
        },
        {
            title: `21-day average daily traded value`,
            description: '',
            currentVal: fundDetail?.overview?.scheme_volume,
            currentValStr: CurrencyFormat(fundDetail?.overview?.scheme_volume, 'short', 0),
            min: Math.min(...fundDetail?.overview?.bm_volume_list || []),
            max: Math.max(...fundDetail?.overview?.bm_volume_list || []),
            minStr: CurrencyFormat(Math.min(...fundDetail?.overview?.bm_volume_list || []), 'short', 0),
            maxStr: CurrencyFormat(Math.max(...fundDetail?.overview?.bm_volume_list || []), 'short', 0),
            minStrLabel: "Benchmark lowest",
            maxStrLabel: "Benchmark highest",
            show: fundDetail?.overview?.is_etf === 1 && fundDetail?.overview?.bm_volume_list?.length > 1
        },
    ]

    // const holdingSnap = fundDetail?.overview?.primary_category_name === "Equity" ? [
    //     {
    //         key: 'a',
    //         heading: 'Number of stocks',
    //         value: fundHolding?.snapshot?.num_securities,
    //         tooltip: 'content content',
    //         show: fundDetail?.overview?.is_fof === 0 && fundHolding?.snapshot?.num_securities
    //     }, {
    //         key: 'b',
    //         heading: 'PE score',
    //         value: DecimalValueFormat({
    //             num: fundHolding?.snapshot?.valuation?.pescore,
    //             suffix: ""
    //         }),
    //         tooltip: 'content content',
    //         show: fundHolding?.snapshot?.valuation?.pescore || false
    //     }, {
    //         key: 'c',
    //         heading: 'PB score',
    //         value: DecimalValueFormat({
    //             num: fundHolding?.snapshot?.valuation?.pbscore,
    //             suffix: ""
    //         }),
    //         tooltip: 'content content',
    //         show: fundHolding?.snapshot?.valuation?.pbscore || false
    //     }, {
    //         key: 'd',
    //         heading: 'Top 5 holding percentage',
    //         value: DecimalValueFormat({
    //             num: fundHolding?.snapshot?.top_5_holding_per,
    //             suffix: "%"
    //         }),
    //         tooltip: 'content content',
    //         show: fundHolding?.snapshot?.top_5_holding_per || false
    //     }, {
    //         key: 'e',
    //         heading: 'Top 10 holding percentage',
    //         value: DecimalValueFormat({
    //             num: fundHolding?.snapshot?.top_10_holding_per,
    //             suffix: "%"
    //         }),
    //         tooltip: 'content content',
    //         show: fundHolding?.snapshot?.top_10_holding_per || false
    //     }
    // ] : fundDetail?.overview?.primary_category_name === "Debt" ? [
    //     {
    //         key: 'a',
    //         heading: 'Number of securities',
    //         value: fundHolding?.snapshot?.num_securities,
    //         tooltip: 'content content',
    //         show: fundDetail?.overview?.is_fof === 0 && fundHolding?.snapshot?.num_securities
    //     }, {
    //         key: 'b',
    //         heading: 'Modified Duration',
    //         value: DecimalValueFormat({
    //             num: fundHolding?.snapshot?.debt_data?.duration,
    //             suffix: " years"
    //         }),
    //         tooltip: 'content content',
    //         show: fundHolding?.snapshot?.debt_data?.duration || false
    //     }, {
    //         key: 'c',
    //         heading: 'Average Maturity',
    //         value: DecimalValueFormat({
    //             num: fundHolding?.snapshot?.debt_data?.average_maturity_period,
    //             suffix: " years"
    //         }),
    //         tooltip: 'content content',
    //         show: fundHolding?.snapshot?.debt_data?.average_maturity_period || false
    //     }, {
    //         key: 'd',
    //         heading: 'Yield to maturity',
    //         value: DecimalValueFormat({
    //             num: fundHolding?.snapshot?.debt_data?.avg_ytm,
    //             suffix: " years"
    //         }),
    //         tooltip: 'content content',
    //         show: fundHolding?.snapshot?.debt_data?.avg_ytm || false
    //     }
    // ] : fundDetail?.overview?.is_fof === 1 ? [
    //     {
    //         key: 'a',
    //         heading: 'PE score',
    //         value: DecimalValueFormat({
    //             num: fundHolding?.snapshot?.valuation?.pescore,
    //             suffix: ""
    //         }),
    //         tooltip: 'content content',
    //         show: fundHolding?.snapshot?.valuation?.pescore || false
    //     }, {
    //         key: 'b',
    //         heading: 'PB score',
    //         value: DecimalValueFormat({
    //             num: fundHolding?.snapshot?.valuation?.pbscore,
    //             suffix: ""
    //         }),
    //         tooltip: 'content content',
    //         show: fundHolding?.snapshot?.valuation?.pbscore || false
    //     }, {
    //         key: 'c',
    //         heading: 'Modified Duration',
    //         value: DecimalValueFormat({
    //             num: fundHolding?.snapshot?.debt_data?.duration,
    //             suffix: " years"
    //         }),
    //         tooltip: 'content content',
    //         show: fundHolding?.snapshot?.debt_data?.duration || false
    //     }, {
    //         key: 'd',
    //         heading: 'Average Maturity',
    //         value: DecimalValueFormat({
    //             num: fundHolding?.snapshot?.debt_data?.average_maturity_period,
    //             suffix: " years"
    //         }),
    //         tooltip: 'content content',
    //         show: fundHolding?.snapshot?.debt_data?.average_maturity_period || false
    //     }, {
    //         key: 'e',
    //         heading: 'Yield to maturity',
    //         value: DecimalValueFormat({
    //             num: fundHolding?.snapshot?.debt_data?.avg_ytm,
    //             suffix: " years"
    //         }),
    //         tooltip: 'content content',
    //         show: fundHolding?.snapshot?.debt_data?.avg_ytm || false
    //     }
    // ] : []

    const holdingSnapNew = [
        {
            key: 'a',
            heading: 'Number of securities',
            value: fundHolding?.snapshot?.num_securities,
            tooltip: 'content content',
            show: fundDetail?.overview?.is_fof === 0 && (Number(fundHolding?.snapshot?.num_securities) || false)
        }, {
            key: 'b',
            heading: 'PE score',
            value: DecimalValueFormat({
                num: fundHolding?.snapshot?.valuation?.pescore,
                suffix: ""
            }),
            tooltip: 'content content',
            show: Number(fundHolding?.snapshot?.valuation?.pescore) || false
        }, {
            key: 'c',
            heading: 'PB score',
            value: DecimalValueFormat({
                num: fundHolding?.snapshot?.valuation?.pbscore,
                suffix: ""
            }),
            tooltip: 'content content',
            show: Number(fundHolding?.snapshot?.valuation?.pbscore) || false
        }, {
            key: 'd',
            heading: 'Top 5 holding percentage',
            value: DecimalValueFormat({
                num: fundHolding?.snapshot?.top_5_holding_per,
                suffix: "%"
            }),
            tooltip: 'content content',
            show: Number(fundHolding?.snapshot?.top_5_holding_per) || false
        }, {
            key: 'e',
            heading: 'Top 10 holding percentage',
            value: DecimalValueFormat({
                num: fundHolding?.snapshot?.top_10_holding_per,
                suffix: "%"
            }),
            tooltip: 'content content',
            show: Number(fundHolding?.snapshot?.top_10_holding_per) || false
        }, {
            key: 'f',
            heading: 'Modified Duration',
            value: DecimalValueFormat({
                num: fundHolding?.snapshot?.debt_data?.duration,
                suffix: " years"
            }),
            tooltip: 'content content',
            show: Number(fundHolding?.snapshot?.debt_data?.duration) || false
        }, {
            key: 'g',
            heading: 'Average Maturity',
            value: DecimalValueFormat({
                num: fundHolding?.snapshot?.debt_data?.average_maturity_period,
                suffix: " years"
            }),
            tooltip: 'content content',
            show: Number(fundHolding?.snapshot?.debt_data?.average_maturity_period) || false
        }, {
            key: 'h',
            heading: 'Yield to maturity',
            value: DecimalValueFormat({
                num: fundHolding?.snapshot?.debt_data?.avg_ytm,
                suffix: " years"
            }),
            tooltip: 'content content',
            show: Number(fundHolding?.snapshot?.debt_data?.avg_ytm) || false
        }
    ]
    const actualAllo = Object.entries(fundHolding?.actual_asset_alloc || {}).sort(([, a], [, b]) => Number(a) - Number(b)).map((a, index) => {
        return {
            name: a[0] + ` (${a[1]?.toFixed(2)}%)`,
            y: a[1],
            z: index + 1
        }
    })

    const conf1 = {
        chart: {
            type: 'pie',
            backgroundColor: 'transparent',
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            height: 220
        },
        title: {
            text: ''
        },
        scrollbar: {
            enabled: false
        },
        exporting: {
            enabled: false
        },

        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: false,
                },
                showInLegend: true,
                size: 180,
                // innerSize: '60%',
            }
        },
        credits: {
            enabled: false
        },

        tooltip: {
            headerFormat: '',
            pointFormat: '{point.name}'
        },
        legend: {
            enabled: true,
            align: 'right',
            verticalAlign: 'middle',
            itemMarginBottom: 10,
            itemStyle: {
                color: '#333333',
                fontSize: '13px',
                fontWeight: "normal"
            }

        },
        series: [{
            data: actualAllo?.filter((item) => item.y !== 0)
        }]
    }

    const targetassetData = [
        {
            key: 'equity',
            heading: "Equity",
            value: fundHolding?.target_asset_alloc?.equity_min + "% - " + fundHolding?.target_asset_alloc?.equity_max + "%",
            tooltip: 'content content',
            show: fundHolding?.target_asset_alloc?.equity_min || fundHolding?.target_asset_alloc?.equity_max
        },
        {
            key: 'debt',
            heading: "Debt",
            value: fundHolding?.target_asset_alloc?.debt_min + "% - " + fundHolding?.target_asset_alloc?.debt_max + "%",
            tooltip: 'content content',
            show: fundHolding?.target_asset_alloc?.debt_min || fundHolding?.target_asset_alloc?.debt_max
        },
        {
            key: 'cash',
            heading: "Cash & equivalents",
            value: fundHolding?.target_asset_alloc?.cash_min + "% - " + fundHolding?.target_asset_alloc?.cash_max + "%",
            tooltip: 'content content',
            show: fundHolding?.target_asset_alloc?.cash_min || fundHolding?.target_asset_alloc?.cash_max
        },
    ]

    const AssetAllocation = () => {
        return <Card bordered={false} bodyStyle={{ display: 'flex' }}>
            <div style={{ flex: 1, paddingRight: '2.5rem' }}>
                <HighchartsReact highcharts={Highcharts} options={conf1} />
            </div>
            <div style={{ flex: 1, paddingLeft: '2.5rem', borderLeft: '1px solid var(--grey3)' }}>
                <div className="textXL w-700 dark3" style={{ paddingBottom: '1.25rem' }}>Target asset allocation</div>
                <CustomLabelValueCards x={targetassetData} />
            </div>
        </Card>
    }
    const CreditRating = () => {
        const dataSource = Object.keys(fundHolding?.scheme_rating_profile || {}).map(x => {
            return {
                key: x,
                ratings: x,
                fund: fundHolding?.scheme_rating_profile?.[x],
                category: fundHolding?.category_rating_profile?.[x]
            }
        })

        const columns = [
            {
                title: 'Ratings',
                dataIndex: 'ratings',
                key: 'ratings',
                width: 150,
                fixed: 'left',
                render: (text) => <span className="w-500">{text}</span>
            },
            {
                title: 'Fund',
                dataIndex: 'fund',
                key: 'fund',
                align: "right",
                render: (text) => DecimalValueFormat({ num: text, suffix: "%" })
            },
            {
                title: 'Category',
                dataIndex: 'category',
                key: 'category',
                align: "right",
                render: (text) => DecimalValueFormat({ num: text, suffix: "%" })
            }
        ]
        return <Table dataSource={dataSource} columns={columns} size={'small'} pagination={false} />
    }

    const SectorAllocation = () => {

        const dataMain = typeof (fundHolding?.sector) === "string" ? JSON.parse(fundHolding?.sector) : fundHolding?.sector
        const dates = dataMain?.map(x => x.index)
        const dataSource = Object.keys(dataMain?.[0] || {})?.map((k) => {
            if (k !== 'index') {
                return {
                    sector: k,
                    [dates[0]]: dataMain?.[0]?.[k],
                    [dates[1]]: dataMain?.[1]?.[k],
                    [dates[2]]: dataMain?.[2]?.[k],
                }
            }
        }).filter(f => f)
        const columns = [{
            title: 'Sector',
            dataIndex: 'sector',
            key: 'sector',
            fixed: 'left',
            render: (text, record) => text
        }, ...dates.map((d, index) => {
            return {
                title: 'as on ' + formatDate(d, "date"),
                dataIndex: d,
                key: d,
                fixed: 'right',
                align: 'right',
                render: (text, record) => DecimalValueFormat({ num: Number(text), suffix: "%", placeholder: "-" })
            }
        })
        ]

        return <Table dataSource={dataSource} columns={columns} size={'small'} pagination={false} />

    }

    const TopHoldings = () => {
        const chipset = fundHolding?.holdings?.map((holding_by_date, index) => {
            return {
                key: index,
                value: index,
                label: formatDate(holding_by_date?.index, "date")
            }
        })
        const [holdingDate, setholdingDate] = React.useState(chipset?.[0].key)

        const chipset2 = Object.keys(fundHolding?.holdings?.[holdingDate] || {})?.splice(1)?.map((d, index) => {
            return {
                key: index,
                value: d,
                label: d.replace("international", "intl'").charAt(0).toUpperCase() + d.replace("international", "intl'").replace("_", " ").slice(1),
                show: fundHolding?.holdings?.[holdingDate][d]?.length ? true : false
            }
        })

        const [holdingCat, setholdingCat] = React.useState(chipset2.filter((d) => d.show !== false)[0].value)

        const [dataSource, setDataSource] = React.useState()

        React.useEffect(() => {
            setDataSource(fundHolding?.holdings?.[holdingDate]?.[holdingCat])
        }, [holdingDate, holdingCat])
        const columns = [
            {
                title: '',
                dataIndex: 'instrument',
                key: 'instrument',
                fixed: 'left'
            },
            {
                title: 'Weight',
                dataIndex: 'weight',
                key: 'weight',
                align: "right",
                render: (text) => {
                    return DecimalValueFormat({ num: Number(text), suffix: "%", placeholder: "-" })
                }
            },
            {
                title: 'Change',
                dataIndex: 'change',
                key: 'change',
                align: "right",
                width: '8rem',
                render: (text, record) => {
                    return <div>
                        {text === "N" ?
                            ""
                            :
                            <div style={{ display: 'flex', gap: '0.5rem', justifyContent: 'end' }}>
                                <div>{DecimalValueFormat({ num: text, suffix: "%", })}</div>
                                <div>
                                    {
                                        record.change > 0 ?
                                            <AiFillUpCircle style={{ color: "var(--green)", fontSize: '0.875rem' }} />
                                            : record.change < 0 ?
                                                <AiFillDownCircle style={{ color: "var(--red)", fontSize: '0.875rem' }} />
                                                : <AiFillMinusCircle style={{ color: 'var(--yellow)', fontSize: '0.875rem' }} />
                                    }
                                </div>
                            </div>
                        }
                    </div>
                }
            }
        ]
        return (
            <div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }} className="mb-2">
                    <div>
                        <CustomSegmentedTab isBlock={false}
                            value={holdingCat}
                            onChange={(e) => {
                                setholdingCat(e)
                            }}
                            options={chipset2.filter(c => c.show)} />
                    </div>
                    <div>
                        <CustomSegmentedTab isBlock={false}
                            value={holdingDate}
                            onChange={(e) => {
                                setholdingDate(e)
                            }}
                            options={chipset} />
                    </div>
                </div>
                <Table dataSource={dataSource} columns={columns} size={'small'} pagination={true} />
            </div>
        );
    }

    const tabItems = [
        {
            label: `Overview`,
            key: '1',
            value: '1',
            children: CustomSideTabs({
                tabData: [
                    {
                        key: 'basic_Details',
                        label: 'Basic details',
                        children: <CustomLabelValueCards x={basic_details} />,
                        show: true,
                    },
                    {
                        key: 'exit_lock_tax',
                        label: 'Exit load, lock in and taxation',
                        children: <CustomLabelValueCards x={exit_lock_tax_sub} />,
                        show: true,
                    },
                    {
                        key: 'managers',
                        label: 'Managers',
                        children: <Managers />,
                        show: true,
                    },
                ]
            })
        },
        {
            label: `Performance`,
            key: '2',
            value: '2',
            children: CustomSideTabs({
                tabData: [
                    {
                        key: 'summary',
                        label: 'Performance summary',
                        children: <CustomLabelValueCards x={performance_summary} />,
                        show: true,
                    },
                    {
                        key: 'tracking_difference',
                        label: 'Tracking difference',
                        children: <TrackingDifference />,
                        show: fundDetail?.overview?.is_etf || fundDetail?.overview?.is_index_fund,
                    },
                    {
                        key: 'trailing_returns',
                        label: 'Trailing returns',
                        children: <TrailingReturns />,
                        show: true,
                    },
                    // {
                    //     key: 'category_rank',
                    //     label: 'Category rank',
                    //     children: <CategoryRankView />,
                    //     show: true,
                    // },
                    {
                        key: 'alpha_beta_sharpe',
                        label: 'Alpha, beta and sharpe ratios',
                        children: <CustomRangeSliders sliders={alphaBetaSharpe} />,
                        show: true,
                    },
                    {
                        key: 'rolling_returns',
                        label: 'Rolling returns',
                        children: <RollingReturns />,
                        show: true,
                    },
                    {
                        key: 'worst_drawdowns',
                        label: 'Worst drawdowns',
                        children: <WorstDrawdowns />,
                        show: true,
                    },
                ]
            }),
        },
        {
            label: `Peers`,
            key: '3',
            value: '3',
            children: CustomSideTabs({
                tabData: [
                    {
                        key: 'benchmark_peers',
                        label: 'Benchmark peers',
                        noHeader: true,
                        children: <InstrumentListTable
                            paginationSize={10}
                            // finalList={allFilteredFundsData}
                            tableType={0}
                            defFilters={[
                                ["benchmark_id", fundData?.benchmark_id]
                            ]}
                            initialColumns={[
                                ...dataTableColumns,
                                ...screenerMetric.filter((item) => item.isInitial === true),
                            ]}
                            tableHeader={<div className="textXL w-700 dark3">Benchmark peers</div>}

                        />,
                        show: (fundDetail?.overview?.is_index_fund === 1 || fundDetail?.overview?.is_etf === 1) && JSON.parse(fundDetail?.overview?.["other_funds_in_benchmark"])?.length,
                    },
                    {
                        key: 'category_peers',
                        label: 'Category peers',
                        noHeader: true,
                        children: <InstrumentListTable
                            // finalList={allFilteredFundsData}
                            tableType={0}
                            paginationSize={10}
                            defFilters={
                                [
                                    ["category_id", fundData?.category_id]
                                ]}
                            initialColumns={
                                [
                                    ...dataTableColumns,
                                    ...screenerMetric.filter((item) => item.isInitial === true),
                                ]
                            }
                            tableHeader={< div className="textXL w-700 dark3" > Category peers</div >}

                        />,
                        show: JSON.parse(fundDetail?.overview?.["other_funds_in_category"] || null)?.length,
                    },
                    {
                        key: 'benchmark_comparision',
                        label: `Other ${type.dname} tracking ${fundData?.benchmark}`,
                        children: <CustomRangeSliders sliders={benchmarkComparision} />,
                        show: (fundDetail?.overview?.is_index_fund === 1 || fundDetail?.overview?.is_etf === 1)
                            && ((fundDetail?.overview?.bm_aum_list !== undefined && fundDetail?.overview?.bm_aum_list?.length > 1)
                                || (fundDetail?.overview?.bm_expense_list !== undefined && fundDetail?.overview?.bm_expense_list?.length > 1)
                                || (fundDetail?.overview?.bm_volume_list !== undefined && fundDetail?.overview?.bm_volume_list?.length > 1)
                                || (fundDetail?.overview?.bm_te_list !== undefined && fundDetail?.overview?.bm_te_list?.length > 1)),
                    },
                    {
                        key: 'category_comparision',
                        label: `Other ${type.dname} in ${fundData?.category_name}`,
                        children: <CustomRangeSliders sliders={categoryComparision} />,
                        show: (fundDetail?.overview?.category_aum_list !== undefined && fundDetail?.overview?.category_aum_list?.length > 1)
                            || (fundDetail?.overview?.category_expense_list !== undefined && fundDetail?.overview?.category_expense_list?.length > 1)
                            || (fundDetail?.overview?.category_volume_list !== undefined && fundDetail?.overview?.category_volume_list?.length > 1),
                    },
                ]
            }),
        },
        {
            label: `Holdings`,
            key: '4',
            value: '4',
            children: CustomSideTabs({
                tabData: [
                    {
                        key: 'holding_snapshot',
                        label: 'Holding snapshot',
                        children: <CustomLabelValueCards x={holdingSnapNew} />,
                        show: holdingSnapNew?.filter(h => h.show)?.length,
                    },
                    {
                        key: 'actual_alloc',
                        label: 'Asset allocation',
                        children: <AssetAllocation />,
                        show: actualAllo?.length,
                    },
                    {
                        key: 'credit_rating',
                        label: 'Credit rating',
                        children: <CreditRating />,
                        show: (fundDetail?.overview?.primary_category_name === "Hybrid" || fundDetail?.overview?.primary_category_name === "Debt") && Object.keys(fundHolding?.category_rating_profile || {})?.length !== 0 && Object.keys(fundHolding?.scheme_rating_profile || {})?.length !== 0,
                    },
                    // {
                    //     key: 'matrix',
                    //     label: 'SEBI PRC matrix',
                    //     children: <MatrixCardView v1Head='Credit risk' v2Head='Interest risk' v1={fundHolding?.sebi_prc?.credit_risk} v2={fundHolding?.sebi_prc?.interest_rate_risk} />,
                    //     show: fundHolding?.sebi_prc?.credit_risk !== null && fundHolding?.sebi_prc?.interest_rate_risk !== null,
                    //     marginTop: '2rem'
                    // },
                    {
                        key: 'top_holdings',
                        label: 'Top holdings',
                        children: <TopHoldings />,
                        show: fundHolding?.holdings?.length,
                    },
                    {
                        key: 'sector_allox',
                        label: 'Sector allocation',
                        children: <SectorAllocation />,
                        show: fundHolding?.sector?.length,
                    }
                ]
            }),
        },
    ]

    if (apiError) {
        return <ErrorComponent />
    }
    if (loading) {
        return <div className='mainContainer' style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
            <ActivityLoaderMintbox margin="20%" />
        </div>
    }
    return (
        <React.Fragment>
            <Head title="Fund detail" />

            <div className="normalContainer">
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                    <div style={{ display: 'flex', gap: '1rem' }}>
                        <AMCIcon amcName={fundDetail?.overview?.amc_full_name} height='4rem' width='4rem' type={'square'} />
                        <div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                            <div className="mb-1" style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                                <div className="textXL w-700 dark3">{fundDetail?.overview?.scheme_name}</div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                                {
                                    type.value === 1 ?
                                        <StockLivePrice format={5} symbol={fundData?.symbol || fundData?.nse_symbol} />
                                        :
                                        type.value === 2 ?
                                            <div className="textSM w-500 black">{CurrencyFormat(fundDetail?.overview?.latest_nav?.nav, "long", 2)}</div>
                                            :
                                            <></>
                                }

                                <CustomTag text={fundDetail?.overview?.is_index_fund === 1 ? "Tracking: " + fundDetail?.overview?.mintbox_bm_name : fundDetail?.overview?.category_name}
                                    color='var(--primary)' bgColor='var(--blueShade)' onClick={() => { }} />
                                {/* <Tag icon={<AiOutlineLineChart />} color="">
                                    <span id="riskTooltip" className="ms-2">{fundDetail?.overview?.risk} Risk</span>
                                    <UncontrolledTooltip placement="bottom" target="riskTooltip">
                                        {fundDetail?.overview?.risk_desc}
                                    </UncontrolledTooltip>
                                </Tag> */}
                                <CustomTag text={`${fundDetail?.overview?.risk} Risk`} icon={<AiOutlineLineChart />}
                                    color='var(--red)' bgColor='var(--redShade)' onClick={() => { }} />
                            </div>
                        </div>
                    </div>
                    <button className="btnWhite" onClick={() => updateWatchlist()}>
                        {/* <Tooltip title={isWatchlisted ? "remove from watchlist" : "add to watchlist"} placement="bottom"> */}
                        {
                            isWatchlisted ?
                                <BsFillBookmarkFill style={{ fontSize: '1.5rem', color: 'var(--primary)' }} />
                                :
                                <BsBookmark className="dark3" style={{ fontSize: '1.5rem' }} />
                        }
                        {/* </Tooltip> */}
                    </button>
                </div>
                <div className='mainContainer'>
                    <div className='leftContainer'>
                        <Card bordered={false} style={{ height: '100%' }} bodyStyle={{ height: '100%' }}>
                            <div style={{ display: "flex", flexDirection: "row" }}>
                                <CardHeader heading={items.filter((i) => i.value === selectedTab)[0]?.label} />
                                <CustomSegmentedTab
                                    isBlock={false}
                                    flexVal="unset"
                                    value={selectedTab}
                                    onChange={(e) => {
                                        setselectedTab(e)
                                    }}
                                    options={items}
                                /></div>
                            <Divider style={{ margin: "0.5rem 0rem" }} />
                            {items.filter((i) => i.value === selectedTab)[0]?.children}
                        </Card>
                    </div>
                    <div className="rightContainer">
                        <Card bordered={false}>
                            <div className="textSM w-500 dark3 mb-2" style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                                <span>Sharpely ‘s excellent analysis</span>
                                <BsExclamationCircle size={"1rem"} />
                            </div>
                            <InsightsModal insights={mfInsights} type={type} detailType='fund' />
                        </Card>
                        <Card bordered={false} style={{ height: '100%' }} bodyStyle={{ height: '100%' }}>
                            <div className="fund-detail-card-left" style={{ border: 'none', height: '100%', display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
                                <div className="textXS dark3" style={{ lineHeight: '24x' }}>
                                    <ReadMoreText text={fundDetail?.overview?.objective_text} />
                                </div>
                                <Slider52Week high={fundData?.['52w_high'] || 250} low={fundData?.['52w_low'] || 120} value={100} />
                                <div style={{ display: 'flex', flexDirection: 'column', gap: '1.25rem', }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', gap: '1.25rem' }}>
                                        <div className="custom-details-table text-center">
                                            <div className="custom-detail">
                                                <div className="custom-label">AUM <span className="textXS" style={{ whiteSpace: 'nowrap' }}>(as on {formatDate(new Date(new Date().setDate(0)).toISOString())})</span></div>
                                                <div className="custom-value">{CurrencyFormat(fundDetail?.overview?.aum_data_scheme?.aum, "long", 0)} Cr</div>
                                            </div>
                                            <div className="custom-detail" >
                                                <div className="custom-label">CAGR <span className="textXS" style={{ whiteSpace: 'nowrap' }}>(since inception)</span></div>
                                                <div className="custom-value">{fundDetail?.overview?.cagr_since_inception === null ? "-" : Number(fundDetail?.overview?.cagr_since_inception)?.toFixed(2)}%</div>
                                            </div>
                                        </div>
                                        <div className="custom-details-table text-center">
                                            <div className="custom-detail" >
                                                <div className="custom-label">Inception</div>
                                                <div className="custom-value">{formatDate(fundDetail?.overview?.inception)}</div>
                                            </div>
                                            <div className="custom-detail" >
                                                <div className="custom-label">Expense ratio</div>
                                                <div className="custom-value">{fundDetail?.overview?.expense_data_scheme?.expense_ratio === null ? "-" : Number(fundDetail?.overview?.expense_data_scheme?.expense_ratio)?.toFixed(2)}%</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <button className="btnPrimary" style={{ width: '100%' }}>Invest</button>
                                    {/* <InvestmentCard SCREENTYPE={1} FUNDDATA={{ ...fundData, FundCode: fundData.nse_symbol }} /> */}
                                </div>
                            </div>
                        </Card>
                    </div>
                </div>
                <Card bordered={false}>
                    <CustomSegmentedTab isBlock={false}
                        value={selectedTabItem}
                        onChange={(e) => {
                            setSelectedTabItem(e)
                        }}
                        options={tabItems} />
                    {tabItems.filter((i) => i.value === selectedTabItem)[0]?.children}
                </Card>
                <Card bordered={false}>
                    <CardHeader heading={`Similar ${type.dname}`}></CardHeader>
                    <div style={{ display: 'flex', gap: '1.25rem', overflowX: 'scroll', marginTop: '1rem', alignItems: 'stretch' }}>
                        {
                            allFundsData.filter(f => f.category_id === fundData?.category_id).map(f => {
                                return <NewFundCard f={f} type={type} onClick={() => navigate(type.basePath + '/fund-detail/' + f?.basic_name?.replace(" - Direct Plan", "")?.replaceAll(' ', '-') + '?code=' + f.plan_id)} />
                            })
                        }
                    </div>
                </Card>
            </div >
        </React.Fragment >
    );
};

export default FundDetail;
