const allowedError = 2;
const minInvestment = (arr, type = 0) => {
  if (arr.length === 0) {
    return 0
  } else if (arr.length === 1) {
    return (arr[0].latest_nav )
  } else {
    // initialize equal weights
    if (type) {
      if (100 % arr.length === 0) {
        arr = arr.map((obj) => ({ ...obj, allocation: (100 / arr.length).toFixed(0) }))
      } else {
        arr = arr.map((obj) => ({ ...obj, allocation: ((100 - (100 % arr.length)) / arr.length).toFixed(0) }))
        arr[0].allocation = Number(arr[0].allocation) + (100 % arr.length)
      }
    }

    const requiredAllocation = arr.map(e => e.allocation)
    let quantities = arr.map(e => 1)
    let t = 0
    while (t < 10000) {
      t++
      const totalAmount = arr.reduce((sum, e, i) => sum + ((e.latest_nav ) * quantities[i]), 0)
      const actualAllocation = arr.map((e, i) => (e.latest_nav) * quantities[i] * 100 / totalAmount)
      const diffArr = actualAllocation.map((a, i) => requiredAllocation[i] - a)
      const maxDiff = Math.max.apply(Math, diffArr)
      const minDiff = Math.min.apply(Math, diffArr)
      // console.log(quantities, diffArr, actualAllocation)
      if (minDiff > -1 * allowedError && maxDiff < allowedError) {
        return totalAmount
      } else {
        const indexOfMaxDiff = diffArr.indexOf(maxDiff);
        quantities[indexOfMaxDiff]++
      }
    }
  }
}
export { minInvestment }