import apiHelper from "Services/api/ApiHelper"
import apiHelperPY from "Services/api/ApiHelperPY"



export async function fetchFundData(type, isFresh = false) {

    //console.log(type, isFresh)
    // type values
    // 1 = ETF
    // 2 = MF
    // 3 = Mintbox
    // 4 = Stocks
    // 5 = US stock
    // 11 = benchmarks

    if (type === 1) {
        let res = await apiHelperPY({ apiName: 'getEtfs', saveResponse: 'sessionStorage', getFresh: isFresh })
        if (res) {
            return JSON.parse(convertToJson(res).replace(/NaN/g, null)).filter((i) => i.isin_code !== null)
        } else {
            return []
        }
    }
    else if (type === 2) {
        let res = await apiHelperPY({ apiName: 'getMFs', saveResponse: 'sessionStorage', getFresh: isFresh })
        //console.log(typeof (res))
        if (res) {
            //console.log(res)
            return JSON.parse(convertToJson(res).replace(/NaN/g, null)).filter((i) => i.isin_code !== null)
        } else {
            return []
        }
    } else if (type === 3) {
        let res = await apiHelper({ apiName: 'getMintboxes', data: { clientId: -1 }, saveResponse: 'sessionStorage', getFresh: isFresh })
        console.log("get mintboxes", res)
        if (res) {
            return res.filter(m => m.IsDisplay === 1 && m.IsCustom === 0)
        } else {
            return []
        }
    } if (type === 4) {
        let res = await apiHelperPY({ apiName: 'getStocks', saveResponse: 'sessionStorage', getFresh: isFresh })
        //console.log(res)
        if (res) {
            return JSON.parse(convertToJson(res).replace(/NaN/g, null)).filter((i) => i.nse_active === 1 && i.symbol.length > 0)
        } else {
            return []
        }
    } else if (type === 11) {
        let res = await apiHelper({ apiName: 'getBenchmarks', saveResponse: 'sessionStorage', getFresh: isFresh })
        //console.log(res)
        if (res) {
            return res
        } else {
            return []
        }
    } else {
        return []
    }
};

const convertToJson = (data) => {
    const keys = data.keys
    const values = JSON.parse(data.values)

    const z = values.map(val => {
        var obj = {}
        keys.map((k, i) => {
            Object.assign(obj, { [k]: val[i] })
        })
        return obj
    })
    return JSON.stringify(z)
}