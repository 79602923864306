import { Alert, Avatar, Button, Card, Collapse, Input, InputNumber, Radio, Segmented, Select, Space, Steps, Tag, Tooltip } from "antd";
import AMCIcon from "Components/AMCIcon";
import { CurrencyFormat } from "Components/CurrencyFormat";
import { changeObjKey } from "Components/DataCustomizer";
import { minInvestment } from "Components/MinInvestment";
import SearchScreen from "Components/SearchScreen";
import Head from "Layout/Head";
import { sortOptions, useGetAssetType } from "Libs/utils";
import React from "react";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { FiMinusCircle } from "react-icons/fi";
import { HiLockClosed, HiLockOpen } from "react-icons/hi";
import { MdArrowDropDown } from "react-icons/md";
import { TiDelete } from "react-icons/ti";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { Col, Row } from "reactstrap";
import AdminServicesPY from "Services/api/AdminServicesPY";

const BuildCustomPortfolio = (props) => {
    const type = useGetAssetType()
    const navigate = useNavigate()
    const { Panel } = Collapse;
    var stockprices = useSelector((state) => state?.stockprices?.value)
    const instrument_method = props?.instrument_method || 1
    const isModalView = props?.isModalView === true ? true : false

    var weight_methods = [
        {
            "value": 1,
            "key": "FX",
            "label": "Custom",
            "show": !isModalView && instrument_method === 1
        },
        {
            "value": 2,
            "key": "EQ",
            "label": "Equal Weight",
        },
        {
            "value": 3,
            "key": "INVV",
            "label": "Inverese volatility based on last 1-year vol",
            // "show": !isModalView && instrument_method === 1
        },
        {
            "value": 4,
            "key": "MINV",
            "label": "Minimum Variance Portfolio based on last 1-year vol",
            "show": false
        },
        {
            "value": 5,
            "key": "ER",
            "label": "Equal Risk contribution based on last 1-year vol",
            // "show": !isModalView && instrument_method === 1
        },
        {
            "value": 6,
            "key": "MC",
            "label": "Weight stocks by market cap",
            "show": type.value === 4
        }
    ]

    const [weight_method, setweight_method] = React.useState(weight_methods.filter((i) => i.show !== false)[0].value);
    const [mintboxName, setMintboxName] = React.useState('')
    const [instrumentList, setinstrumentList] = React.useState(props?.instrumentList || []);

    /* MODAL */
    const [selectedBenchmark, setselectedBenchmark] = React.useState([]);
    const [etfloading, setetfloading] = React.useState(false);
    const [showNeverRebalance, setShowNeverRebalance] = React.useState(false)
    const [calculatedWeights, setcalculatedWeights] = React.useState([])

    // screener or signal specific
    const [allFilteredFundsData, setallFilteredFundsData] = React.useState([])
    const [screenerQuery, setscreenerQuery] = React.useState(props?.screenerQuery || [])
    const [metricType, setMetricType] = React.useState(type.value === 4 ? "mcap" : "expense_ratio")
    const [sortType, setSortType] = React.useState("top")
    const [numOfFunds, setNumOfFunds] = React.useState(props?.allFilteredFundsData?.length < 10 ? props?.allFilteredFundsData?.length : 10)

    const loadWeightsData = async (instrumentList) => {
        if (calculatedWeights.length === 0) {
            var instruments = type.value === 4 ? instrumentList.map((instrument) => { return instrument.symbol }) : instrumentList.map((instrument) => { return instrument.isin_code })

            var data = {
                instruments: instruments,
                instrument_type: type.value === 4 ? 3 : type.value,
                mCap: 0
            }

            const res = await AdminServicesPY.getPortfolioWeights(data).then(res => res)
            console.log(res?.data)

            setcalculatedWeights(res?.data || [])

            return res.data || []
        } else {
            return calculatedWeights
        }
    }

    React.useEffect(() => {
        setallFilteredFundsData(props?.allFilteredFundsData || [])
    }, [props?.allFilteredFundsData])

    React.useEffect(() => {
        setcalculatedWeights([])
    }, [allFilteredFundsData])

    const options = [
        { "key": 1, "text": "Every month", value: 'M', disabled: type.value === 2 },
        { "key": 2, "text": "Every 3 months", value: '3M', disabled: type.value === 2 },
        { "key": 3, "text": "Every 6 months", value: 'Q' },
        { "key": 4, "text": "Every 12 months", value: 'Y' },
        {
            "key": 5, "text": <span style={{ alignItems: "center" }}>Never rebalance <Tooltip placement="bottom" title={<span className="textSM">By selecting this option, you will not receive periodic rebalancing updates for this strategy. However, you will be able to rebalance this strategy manually whenever you wish to from your strategy.</span>}>
                <AiOutlineQuestionCircle size={15} className='secondaryGreen' onClick={() => setShowNeverRebalance(true)} />
            </Tooltip></span>, value: 0
        }
    ]
    const [rebalanceFrequency, setRebalanceFrequency] = React.useState(3)
    const [rebalanceFreq, setRebalanceFreq] = React.useState(options[2])
    const [current, setCurrent] = React.useState(0);
    const next = () => {
        setCurrent(current + 1);
    };
    const prev = () => {
        setCurrent(current - 1);
    };

    React.useEffect(() => {
        setCurrent(0)
    }, [])

    const metric_data = (key, value) => {
        return props?.screenerMetric.filter((met) => met[key] === value)[0]
    }

    const sortFunds = (arr, key, order = "top") => {
        var arrr = [...arr]?.filter((i) => (i[key] !== null))
        if (order === "bot") {
            arrr = arrr.sort((a, b) => a[key] - b[key])
        } else {
            arrr = arrr.sort((a, b) => b[key] - a[key])
        }

        return arrr
    }

    const applyFilters = () => {

        //float
        //console.log("dsjhkvchadsvhsvdjh")
        var filterVal = sortType
        var allFunds = JSON.parse(JSON.stringify(props?.allFilteredFundsData))
        var currentMetricData = { ...metric_data('metric_col_code', metricType) }
        console.log(currentMetricData)

        if (currentMetricData.direction_flag !== null || currentMetricData.direction_flag !== undefined) {
            if (sortType !== undefined) {
                // //////console.log(order.value)
                allFunds = sortFunds(allFunds, currentMetricData.metric_col_code, sortType)
            } else {
                // //////console.log("ASDF", currentMetricData.direction_flag)
                filterVal = currentMetricData.direction_flag
                allFunds = sortFunds(allFunds, currentMetricData.metric_col_code, currentMetricData.direction_flag)
            }
        }

        allFunds = allFunds.splice(0, numOfFunds)

        var addonQuery = {
            "screenerType": type.strVal,
            "pos": Object.entries(props?.screenerQuery).length + 1,
            "oper": "rank_comp",
            "primary_col": metricType || null,
            "sec_col": null,
            "adj_operator": null,
            "abs_val_1": null,
            "abs_val_2": null,
            "comp_operator": sortType + "_" + numOfFunds + "_abs",
            "rel_comp_stat": null,
            "rel_comp_name": null,
            "rule_name": "FINAL SORT"
        }
        setallFilteredFundsData([...allFunds])
        allocate([...allFunds])

        console.log(props?.screenerQuery)
        console.log(props?.screenerQuery.concat([addonQuery]))
        console.log(props?.screenerQuery.concat(addonQuery))
        setscreenerQuery(props?.screenerQuery.concat([addonQuery]))

        // console.log(addonQuery)
        // setappliedMetrics(finalTableColumns)
        // setTableColumns(finalTableColumns)
    }



    // React.useEffect(() => {
    //     if (isModalView)
    //         applyFilters()
    // }, [metricType, sortType, numOfFunds])


    React.useEffect(() => {
        if (instrumentList.length > 0) {
            allocate(instrumentList)
        }
    }, [weight_method])

    // React.useEffect(() => {
    //     if (selectedPortfolioType === 1) {
    //         if (isModalView) {
    //             console.log(props?.allFilteredFundsData)
    //             setallFilteredFundsData(props?.allFilteredFundsData)
    //             allocate(props?.allFilteredFundsData)
    //         }
    //     } else {
    //         if (isModalView)
    //             applyFilters()
    //     }
    // }, [selectedPortfolioType])


    const createMintbox = async () => {

        // //console.log({
        //     "InstrumentType": type.value,
        //     "InstrumentMethod": 1,
        //     "ISINS": instrumentList.map((instrument) => { return instrument.isin_code || instrument.symbol }).join(","),
        //     // "ScreenId": null,
        //     // "ScreenEval": null,
        //     "WeightMethod": 1,
        //     "Weights": instrumentList.map((instrument) => { return instrument.allocation }).join(","),
        //     "RebalanceMethod": rebalanceFreq.value === 0 ? 0 : 1,
        //     "RebalanceFrequency": rebalanceFreq.value,
        //     // "RebalanceTT": null
        // })
        console.log({
            'mintbox_type': 3,
            'isins': type.value !== 4 ? instrumentList.map((instrument) => { return instrument.isin_code }) : [],
            'tickers': type.value === 4 ? instrumentList.map((instrument) => { return instrument.symbol }) : [],
            'instrument_type': type.value === 4 ? 3 : type.value,
            'instrument_method': instrument_method,
            'weights': instrumentList.map((instrument) => { return instrument.allocation / 100 }),
            'rebal_method': rebalanceFreq.value === 0 ? 0 : 1,
            'weight_method': weight_method,
            'screen_query': screenerQuery,
            'rebal_freq': rebalanceFreq.value,
            'mintbox_name': mintboxName,
            'mintbox_bm_id': selectedBenchmark[0].benchmark_id,
            'mintbox_bm_name': selectedBenchmark[0].benchmark_name,
        })

        navigate(type.basePath + '/stock-portfolio', {
            state: {
                "portfolio_params": {
                    'mintbox_type': 3,
                    'isins': type.value !== 4 ? instrumentList.map((instrument) => { return instrument.isin_code }) : [],
                    'tickers': type.value === 4 ? instrumentList.map((instrument) => { return instrument.symbol }) : [],
                    'instrument_type': type.value === 4 ? 3 : type.value,
                    'instrument_method': instrument_method,
                    'weights': instrumentList.map((instrument) => { return instrument.allocation / 100 }),
                    'rebal_method': rebalanceFreq.value === 0 ? 0 : 1,
                    'weight_method': weight_method,
                    'screen_query': screenerQuery,
                    'rebal_freq': rebalanceFreq.value,
                    'mintbox_name': mintboxName,
                    'mintbox_bm_id': selectedBenchmark[0].benchmark_id,
                    'mintbox_bm_name': selectedBenchmark[0].benchmark_name,
                }
            }
        })
        // //console.log({
        //     IsCustom: 1,
        //     UID: "XYZ",
        //     FundType: type.value,
        //     MintboxName: mintboxName,
        //     MinLumpsumAmount: minInvestment(instrumentList),
        //     MinSIPAmount: minInvestment(instrumentList)
        // })


        // mainProps.history.push({
        //     pathname: '/custom-portfolio-detail', state: {
        //         mintExecParams: {
        //             "InstrumentType": type.value,
        //             "InstrumentMethod": 1,
        //             "ISINS": instrumentList.map((instrument) => { return instrument.isin_code }).join(","),
        //             // "ScreenId": null,
        //             // "ScreenEval": null,
        //             "WeightMethod": 1,
        //             "Weights": instrumentList.map((instrument) => { return instrument.allocation }).join(","),
        //             "RebalanceMethod": rebalanceFreq.value === 0 ? 0 : 1,
        //             "RebalanceFrequency": rebalanceFreq.value,
        //             // "RebalanceTT": null
        //         },
        //         routeData: {
        //             IsCustom: 1,
        //             UID: "XYZ",
        //             FundType: type.value,
        //             MintboxName: mintboxName,
        //             MinLumpsumAmount: minInvestment(instrumentList),
        //             MinSIPAmount: minInvestment(instrumentList)
        //         },
        //         mintboxDetailsQuery: {
        //             'mintbox_type': 3,
        //             'isins': instrumentList.map((instrument) => { return instrument.isin_code }),
        //             'instrument_type': type.value,
        //             'weights': instrumentList.map((instrument) => { return instrument.allocation / 100 }),
        //             'rebal_method': rebalanceFreq.value === 0 ? 0 : 1,
        //             'rebal_freq': rebalanceFreq.value,
        //             'mintbox_bm_id': selectedBenchmark[0]?.benchmark_id,
        //             'mintbox_bm_name': selectedBenchmark[0]?.benchmark_name,
        //         }
        //     }
        // })
    }


    const ItemBox = ({ item, index }) => {
        return <Tag key={index} style={{ position: "relative", border: "0.5px solid #e5e5e5" }} className='my-1'>
            <div style={{ display: "flex", alignItems: "flex-start", gap: "0.3rem", padding: "0.3rem" }}>
                <AMCIcon amcName={item.symbol || item.amc_full_name} fundType={type.value} height='1rem' width="1rem" />
                <span>{item.proper_name || item.basic_name} <br></br> {type.value === 4 ? "Market Cap: " : "AUM: "}{CurrencyFormat(item.mcap || item.aum)} Cr.</span>
            </div>
        </Tag>
    }

    const RefineSelection = () => {
        return <div className="m-2">
            {/* <div className='fontH2 mx-2 w-500'>Select a way to start investing in your screen</div> */}
            {/* <Row className='m-2' style={{ gap: "1rem", position: "relative" }}>
                <Card bordered={false}  onClick={props?.allFilteredFundsData.length <= 50 ? () => {
                    setselectedPortfolioType(1)
                } : () => { }} bodyStyle={{ padding: "1rem", opacity: props?.allFilteredFundsData.length <= 50 ? 1 : 0.4 }} style={{ flex: 1, cursor: props?.allFilteredFundsData.length <= 50 ? "pointer" : "not-allowed", border: selectedPortfolioType === 1 ? "2px solid var(--primary)" : "1px solid #e5e5e5" }} hoverable={props?.allFilteredFundsData.length <= 50}>
                    <div className='fontH2 w-500'>Invest in entire screen</div>
                    <div className='textSM'>All stocks in the screen will be selected in your strategy</div>
                </Card>
                <Card bordered={false}  onClick={() => {
                    setselectedPortfolioType(2)
                }} bodyStyle={{ padding: "1rem" }} style={{ flex: 1, border: selectedPortfolioType === 2 ? "2px solid var(--primary)" : "1px solid #e5e5e5" }} hoverable>
                    <div className='fontH2 w-500'>Refine the screen</div>
                    <div className='textSM'>You can further refine your strategy by selecting top stocks based on your screen metrics</div>
                </Card>
            </Row>

            {selectedPortfolioType === 2 ? <div className='m-2 mt-4'>
                <div className='fontH2 my-1 w-500'>Refine your selections on the basis of market cap or your screener rules</div>
                <div style={{ gap: "1rem", display: "flex" }}>
                    <div>
                        <div className='textSM mt-1'>Rule name</div>
                        <Select removeIcon={<TiDelete size={12} />}
                            className='textSM black'
                            suffixIcon={<MdArrowDropDown size={17} />}
                            value={metricType}
                            style={{
                                width: "200px",
                                marginTop: 0
                            }}
                            placeholder="Please select"
                            onChange={(value) => {
                                // onValueChange(value)
                                setMetricType(value)
                            }}
                            options={(type.value === 4 ? [{
                                value: "mcap",
                                label: "Market Cap",
                                key: "mcap"
                            }]?.concat(props?.tableColumns.filter((i) => i.dataIndex !== "mcap" && i.metric_data_type === "float").map((i) => {
                                return {
                                    value: i.dataIndex,
                                    key: i.dataIndex,
                                    label: i.metric_name
                                }
                            })) : type.value === 1 ? [{
                                value: "expense_ratio",
                                label: "Expense ratio",
                                key: "expense_ratio"
                            }, {
                                value: "21_day_vol",
                                label: "21 day volume",
                                key: "21_day_vol"
                            }]?.concat(props?.tableColumns.filter((i) => (i.dataIndex !== "expense_ratio" || i.dataIndex !== "21_day_vol") && i.metric_data_type === "float").map((i) => {
                                return {
                                    value: i.dataIndex,
                                    key: i.dataIndex,
                                    label: i.metric_name
                                }
                            })) : [{
                                value: "expense_ratio",
                                label: "Expense ratio",
                                key: "expense_ratio"
                            }]?.concat(props?.tableColumns.filter((i) => i.dataIndex !== "expense_ratio" && i.metric_data_type === "float").map((i) => {
                                return {
                                    value: i.dataIndex,
                                    key: i.dataIndex,
                                    label: i.metric_name
                                }
                            })))}
                        />
                    </div>

                    <div>
                        <div className='textSM mt-1'>Order by</div>
                        <Segmented
                            className='textXS black'
                            options={sortOptions}
                            onChange={(value) => {
                                setSortType(value)
                            }}
                            value={sortType}
                            defaultValue={({ ...metric_data('metric_col_code', metricType) }.direction_flag === "top" ? sortOptions[0]?.value : sortOptions[1]?.value)}

                        />
                    </div>

                    <div>
                        <div className='textSM mt-1'>Number of {type.dname}</div>
                        <InputNumber min={type.value === 4 ? 5 : 2} max={type.value === 4 ? 50 : 10} value={numOfFunds} onBlur={(val) => {
                            //console.log(val)
                            setNumOfFunds(Number(val.target.value) < (type.value === 4 ? 5 : 2) ? (type.value === 4 ? 5 : 2) : Number(val.target.value) > (type.value === 4 ? 50 : 10) ? (type.value === 4 ? 50 : 10) : Number(val.target.value))
                        }} />
                    </div>
                </div>
            </div> : <></>} */}
            <div className='fontH2 mb-4 w-500'>{type.dname} you will be investing in</div>
            {/* <div style={{ flex: 1 }}>
                <div className="fontH2 my-3 w-500">{instrumentList.length} {type.dname} selected</div>
                <div style={{
                    marginBottom: "20px"
                }} className="textSM" >Minimum {type.value === 4 ? 5 : 2} | Max {type.value === 4 ? 50 : 10}</div>
            </div> */}
            <div className='' style={{ position: "relative", maxHeight: "40vh", overflowY: "scroll" }}>
                {instrumentList.map((item, index) => {
                    return <ItemBox item={item} index={index} />
                })}
            </div>
        </div>
    }



    const AddInstrument = () => {
        return <div>
            {!isModalView && <div className="row gx-2" style={{}}>
                <div className='fontH2 w-500 col-7' >Add {type.dname} to start <br></br>building your strategy</div>
                <div className="col-5" >
                    <SearchScreen
                        searchText={`Search by ${type.dname} name`}
                        navigation={props.history}
                        type={type.value}
                        // isDropOpen={true}
                        showPlusIcon={true}
                        plusIconAction={(item) => onSearchItemClick(item)}
                        showViewDetails={true}
                    />
                </div>
            </div>}
            <div>
                {instrumentList.length === 0 ?
                    <div className='bodyTextDark' style={{ fontSize: "16px" }}></div>
                    :
                    <>
                        <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
                            <div style={{ flex: 1 }}>
                                <div className="fontH2 my-3 w-500">{instrumentList.length} {type.dname} selected</div>
                                <div style={{
                                    marginBottom: "20px"
                                }} className="textSM" >Minimum {type.value === 4 ? 5 : 2} | Max {type.value === 4 ? 50 : 10}</div>
                            </div>
                            <div style={{ justifyContent: "flex-end" }} >
                                <Select removeIcon={<TiDelete size={12} />}
                                    className='textSM black'
                                    suffixIcon={<MdArrowDropDown size={17} />}
                                    value={weight_method}
                                    style={{
                                        width: "370px",
                                        marginTop: 0
                                    }}
                                    placeholder="Please select"
                                    onChange={(value) => {
                                        // onValueChange(value)
                                        setweight_method(value)
                                    }}
                                    options={weight_methods.filter((i) => i.show !== false)}
                                />
                            </div>
                        </div>
                        {instrumentList.length > 0 ? instrumentList.map((data, index) => {
                            return <ListEtfView
                                id={data?.isin_code || data?.symbol}
                                key={data?.isin_code || data?.symbol}
                                data={data}
                                index={index}
                            />
                        }) : null}
                    </>

                }
            </div>
        </div>
    }

    const SelectBenchmark = () => {
        return <div>
            <div className='fontH2 w-500' >Select benchmark for your strategy</div>

            <div className="mt-3" style={{ width: "500px" }} >
                <SearchScreen
                    searchText={`Search by benchmark name`}
                    type={11}
                    isDropOpen={selectedBenchmark.length === 0 ? true : false}
                    showPlusIcon={true}
                    plusIconAction={(item) => {
                        //console.log(item)
                        setselectedBenchmark([item])
                    }}
                    showViewDetails={true}
                />
            </div>

            <div style={{
                marginTop: "30px"
            }}>
                {selectedBenchmark.map((data, index) => {
                    return <div style={{ borderBottom: "1px solid " + 'var(--light)' }} key={data.ticker}>
                        <div style={{ display: "flex", gap: "20px", alignItems: "center", padding: 10 }}>
                            <div style={{ flex: 1 }}>
                                <div className='fontH2 w-500'>{data.benchmark_name}</div>
                            </div>
                            <FiMinusCircle size={20} style={{ backgroundColor: "#FFE7E5", color: '#FF8080', borderRadius: "50%", margin: "auto 0", cursor: "pointer" }} onClick={() => setselectedBenchmark([])} />
                        </div>
                    </div>
                })}
            </div>
        </div>

    }

    const SelectRebalanceing = () => {
        return <div>
            <div className='fontH2 w-500'>How often would you like to rebalance your strategy?</div>
            <Tooltip placement="topLeft" title={<span className="textSM">Over time, the weights of the constituents in your strategy can change. This happens because different constituents grow at different rates. By rebalancing your strategy you can realign the weights to the values selected by you during your portfolio’s setup</span>}>
                <div className='fontH2 w-500 my-2' style={{ color: "var(--primary)" }}>
                    What is rebalancing?
                </div>
            </Tooltip>
            <Radio.Group className="my-2" onChange={(e) => {
                setRebalanceFrequency(parseInt(e.target.value))
                setRebalanceFreq(options.filter((i) => i.key === e.target.value)[0])
            }} name="radiogroup" defaultValue={rebalanceFrequency}>
                <Space direction="vertical" style={{ gap: "0.5rem" }}>
                    {
                        options.map((option) => {
                            return (<Radio disabled={option.disabled} value={option.key}>{option.text}</Radio>)
                        })
                    }
                </Space>
            </Radio.Group>

        </div>

    }

    const PortfolioName = () => {
        return <div>
            <div className='fontH2 w-500 mb-2' style={{ fontSize: 20 }}>Woohoo! You just built a strategy 🥁</div>
            <div className='fontH2 w-500'>What would you like to call it?</div>
            <div style={{ marginTop: "4%" }}>
                <Input size="large" maxLength={120} style={{ maxWidth: "20vw" }} placeholder="My wealth box" defaultValue={mintboxName} onBlur={e => setMintboxName(e.target.value)} />
            </div>
        </div >

    }

    React.useEffect(() => {
        setweight_method(weight_methods.filter((i) => i.show !== false)[0].value)
    }, [])

    const steps = [
        // isModalView && {
        //     id: "aa",
        //     title: <div className="fontH2 w-500">Refine your screen</div>,
        //     description: <div className="textSM w-normal mt-1">Select a way to start investing in your screen</div>,
        //     content: <RefineSelection />,
        //     nextbtnname: "Define weights",
        //     nextbtnclick: () => next()
        // },
        {
            id: "a",
            title: <div className="fontH2 w-500">{isModalView ? "Define weights for screened " + type.dname : "Add your favourite " + type.dname + " and define weights"}</div>,
            description: <div className="textSM w-normal mt-1">Tell us how much weight do you want to assign each {type.omkar} in your strategy?</div>,
            content: <AddInstrument />,
            prevbtnname: isModalView ? "Previous" : undefined,
            nextbtnname: "Confirm weights",
            nextbtndisabled: !(instrumentList?.length >= (type.value === 4 ? 5 : 2) && instrumentList?.length <= (type.value === 4 ? 50 : 10)),
            nextbtnclick: () => next()
        },
        {
            id: "aaa",
            title: <div className="fontH2 w-500">Choose a performance benchmark for your strategy</div>,
            description: <div className="textSM w-normal mt-1">We will show the back tested performance of your strategy against this benchmark</div>,
            content: <SelectBenchmark />,
            prevbtnname: "Previous",
            nextbtnname: "Confirm",
            nextbtndisabled: selectedBenchmark.length === 0,
            nextbtnclick: () => next()
        },
        {
            id: "c",
            title: <div className="fontH2 w-500">Choose rebalancing frequency</div>,
            description: <div className="textSM w-normal mt-1">{isModalView ? "How often would you like the strategy to be rebalanced to new set of " + type.dname : "Tell us how often (if at all!) you want to rebalance your strategy to maintain weightages"}</div>,
            content: <SelectRebalanceing />,
            prevbtnname: "Previous",
            nextbtnname: "Confirm",
            nextbtnclick: () => next()
        },
        {
            id: "d",
            title: <div className="fontH2 w-500">Congrats! You just built a strategy!</div>,
            description: <div className="textSM w-normal mt-1">That’s it - start investing in your own strategy. We will monitor and help you manage it.</div>,
            content: <PortfolioName />,
            prevbtnname: "Previous",
            nextbtnname: "Save and view strategy",
            nextbtndisabled: mintboxName.length < 2,
            nextbtnclick: () => createMintbox()
        },
    ].filter((i) => i !== false)




    React.useEffect(() => {
        setetfloading(false)
    }, [etfloading])

    React.useEffect(() => {
        isValidAllo()
    }, [instrumentList])

    const onSearchItemClick = (item) => {
        if (type.value === 4) {
            if (instrumentList?.length === 50) {
                toast.error(`Maximum 50 ${type.dname} allowed`);
                return;
            }
        } else {
            if (instrumentList?.length === 25) {
                toast.error(`Maximum 25 ${type.dname} allowed`);
                return;
            }
        }
        if (instrumentList?.length === 25) {
            toast.error(`Maximum 25 ${type.dname} allowed`);
            return;
        }
        if (instrumentList.findIndex(type.value === 4 ? f => f.symbol === item.symbol : f => f.isin_code === item.isin_code) === -1) {
            allocate([...instrumentList, item])
        }
    }


    const allocate = async (instrumentList) => {
        var a = instrumentList

        // loadWeightsData()

        if (weight_method === 1) {
            if (100 % a?.length === 0) {
                a = a.map((obj, index) => ({
                    ...obj,
                    allocation: (100 / a?.length).toFixed(0), locked: false, index
                }))
                setinstrumentList(a)
            } else {
                a = a.map((obj, index) => ({
                    ...obj,
                    allocation: ((100 - (100 % a?.length)) / a?.length).toFixed(0), locked: false, index
                }))
                a[0].allocation = Number(a[0]?.allocation) + (100 % a?.length)
                setinstrumentList(a)
            }
        } else {
            var weights = await loadWeightsData(a).then((r) => r)
            console.log(weights)
            var finalWeights = weights.filter((i) => i.wt_method === weight_method)[0]?.weights

            a = a.map((obj, index) => ({
                ...obj,
                allocation: finalWeights[index].toFixed(1),
                locked: true,
                index
            }))
            setinstrumentList(a)
            console.log(finalWeights)
        }


    }

    const del = (index) => {
        var a = instrumentList;
        a.splice(index, 1)
        if (a?.length === 0) {
            setinstrumentList([])
        } else {
            allocate([...a])
        }
    }

    const isValidAllo = () => {
        var sum = 0;
        instrumentList.map((data, index) => {
            sum += parseInt(data.allocation);
        })
        // if (sum !== 100) {
        //     setnextDisabled(true)
        // } else {
        //     setnextDisabled(false)
        // }
    }

    const changeData = (index, text) => {
        try {
            var a = JSON.parse(JSON.stringify(instrumentList))
            var b = a.filter(data => data.locked === true);
            a[index].locked = true
            b = a.filter(data => data.locked === true);

            if (Number(text) !== 0) {

                if (a?.length - b?.length >= 1) {

                    a[index].allocation = text
                    var sum = 0;
                    b.map((data, index) => {
                        sum += parseInt(data.allocation);
                    })
                    var c = a.filter((data) => {
                        return !b.includes(data);
                    });
                    var finalamt = 100 - sum
                    if (finalamt % c?.length === 0) {

                        var alllo = (finalamt / c?.length).toFixed(0)
                        if (alllo > 0) {
                            c = c.map(obj => (
                                {
                                    ...obj,
                                    allocation: alllo
                                }
                            ))
                            b = (b.concat(c)).sort((a, b) => a.index - b.index)

                            setinstrumentList(b)
                        } else {
                            if (b?.length === 1) {
                                toast.warn("Each signal must have weightage greater then 1%. Sum of weights allocated to each signal must be 100%", {
                                    className: "textSM"
                                })
                            } else {
                                toast.warn("Unlock some more signals.\nEach signal must have weightage greater then 1% and sum of weights allocated to each signal must be 100%", {
                                    className: "textSM"
                                })
                            }
                            toast.warn("Unlock some more signals.\nEach signal must have weightage greater then 1% and sum of weights allocated to each signal must be 100%", {
                                className: "textSM"
                            })
                            setinstrumentList(instrumentList);
                        }

                    } else {

                        var alllo = ((finalamt - (finalamt % c.length)) / c.length).toFixed(0)

                        if (alllo > 0) {
                            c = c.map(obj => ({ ...obj, allocation: alllo }))
                            var allo2 = Number(c[0].allocation) + (finalamt % c.length)

                            if (allo2 > 0) {

                                c[0].allocation = allo2
                                b = (b.concat(c)).sort((a, b) => a.index - b.index)

                                setinstrumentList(b);
                            } else {
                                if (b.length === 1) {
                                    toast.warn("Each signal must have weightage greater then 1%. Sum of weights allocated to each signal must be 100%", {
                                        className: "textSM"
                                    })
                                } else {
                                    toast.warn("Unlock some more signals.\nEach signal must have weightage greater then 1% and sum of weights allocated to each signal must be 100%", {
                                        className: "textSM"
                                    })
                                }
                                toast.warn("Unlock some more signals.\nEach signal must have weightage greater then 1% and sum of weights allocated to each signal must be 100%", {
                                    className: "textSM"
                                })
                                setinstrumentList(instrumentList);
                            }
                        } else {
                            if (b.length === 1) {
                                toast.warn("Each signal must have weightage greater then 1%. Sum of weights allocated to each signal must be 100%", {
                                    className: "textSM"
                                })
                            } else {
                                toast.warn("Unlock some more signals.\nEach signal must have weightage greater then 1% and sum of weights allocated to each signal must be 100%", {
                                    className: "textSM"
                                })
                            }
                            toast.warn("Unlock some more signals.\nEach signal must have weightage greater then 1% and sum of weights allocated to each signal must be 100%", {
                                className: "textSM"
                            })
                            setinstrumentList(instrumentList);
                        }
                    }
                } else {
                    toast.warn("Unlock ateast 2 signals to readjust weights.\nEach signal must have weightage greater then 1% and sum of weights allocated to each signal must be 100%", {
                        className: "textSM"
                    })
                }
            } else {
                toast.error("Weights can't be zero.\nEither allocate some weight or remove the signal you don't want", {
                    className: "textSM"
                })
                setinstrumentList(instrumentList);
            }
            isValidAllo()
        } catch (error) {
            ////console.log("error", error)
        }
    }

    const lockData = (index, text) => {
        var a = instrumentList
        a[index].locked = text
        setinstrumentList([...a])
        isValidAllo()
        // setetfloading(true)
    }

    const ListEtfView = ({ id, data, index }) => {
        const [weight, setWeight] = React.useState(Number(data.allocation))
        // var textinp = React.useRef()
        try {
            return <div style={{ borderBottom: "1px solid " + 'var(--light)' }} key={id}>

                <div style={{ display: "flex", gap: "20px", alignItems: "center", padding: 10 }}>
                    {type.value === 4 ? <AMCIcon amcName={data.symbol} fundType={type.value} height='1.2rem' width="1.2rem" /> : <AMCIcon amcName={data.amc_full_name} height='1.2rem' width="1.2rem" />}
                    <div style={{ flex: 1 }}>
                        <div className='fontH2 w-500'>{type.value === 4 ? data?.proper_name : data?.basic_name}</div>
                        <div className='textSM'>{type.value === 2 ? "NAV: " : "LTP: "} <span className='textSM w-500'>{CurrencyFormat(type.value !== 4 ? data?.latest_nav : stockprices.length > 0 ? (stockprices.filter((i) => i.Symbol === data?.symbol)?.[0]?.LTP || stockprices.filter((i) => i.Symbol === data?.symbol)?.[0]?.ClosePrice) / 100 || 0 : 0)}</span></div>
                    </div>
                    <InputNumber disabled={isModalView} addonBefore={
                        data.locked || isModalView ?
                            <HiLockClosed size={14} style={{ color: 'var(--primary)' }} onClick={() => lockData(index, false)} />
                            :
                            <HiLockOpen size={14} style={{ color: 'var(--grey)' }} onClick={() => lockData(index, true)} />
                    } formatter={(value) => `${value} %`}
                        parser={(value) => value.replace(' %', '')} controls={true} className='textSM black' size="middle" style={{ textAlign: "center", justifyContent: "center", alignItems: "center", width: 120 }} min={1} max={instrumentList?.length > 1 ? 99 : 100} defaultValue={weight} value={weight} onChange={e => changeData(index, parseInt(e))} />
                    {isModalView !== true ?
                        <FiMinusCircle size={20} style={{ backgroundColor: "#FFE7E5", color: '#FF8080', borderRadius: "50%", margin: "auto 10", cursor: "pointer" }} onClick={() => del(index)} /> : <></>}
                </div>
            </div>
        } catch (error) {
            //console.log("error", error)
            return <div><span>err</span></div>
        }
    }


    const StepsView = () => {

        return <Steps size="small" direction="vertical" current={current} items={[...steps].map((i) => {
            return {
                id: i.id,
                title: i.title,
                description: i.description,
                content: i.content
            }
        })} />

    }

    // console.log(steps[current]?.nextbtndisabled)
    return (
        <React.Fragment>
            <Head title="Custom strategy" />
            {isModalView !== true &&
                <div className='fontH1' >Build your own strategy</div>}

            {/* <Collapse
                bordered={false}
                style={{ backgroundColor: "transparent" }}
                activeKey={[String(current)]}
            >
                {steps.map((step, index) => {
                    return <Panel showArrow={false} collapsible={current !== index ? 'disabled' : 'header'} className="my-3" header={<div style={{ display: "flex", alignItems: "center", gap: "1rem" }}><Avatar>{index + 1}</Avatar> <div>{step.title}{step.description}</div></div>} style={{ maxHeight: "63vh", backgroundColor: "transparent", border: "0.5px solid #e5e5e5", borderRadius: "4px", overflow: "hidden" }} key={String(index)}>
                        <div style={{ minHeight: "30vh", display: "flex", flexDirection: "column" }}>
                            <div style={{ flex: 1 }}>{step.content}</div>
                            {type.value !== 4 && <Alert className="my-2"
                                message={<div className="textSM w-500">Info:<span className="w-normal">{` In ${type.omkar} based screeners we offer fixed portfolios and rebalancing within initial instruments`}</span></div>}
                                type="info"
                                closable
                                showIcon
                            />}
                            <div className="mt-3">
                                {instrumentList?.length > 0 && steps[current].id !== "aa" ? <Card bordered={false} style={{ width: "100%" }} bodyStyle={{ display: "flex", flex: 1 }}>
                                    <div className="col-6" style={{ fontSize: "16px", display: "flex", alignItems: "center" }}>
                                        Minimum investment : ₹ {Number(minInvestment(instrumentList.map((data) => {
                                            return {
                                                ...data,
                                                latest_nav: type.value !== 4 ? data.latest_nav : stockprices.length > 0 ? (stockprices.filter((i) => i.Symbol === data?.symbol)?.[0]?.LTP || stockprices.filter((i) => i.Symbol === data?.symbol)?.[0]?.ClosePrice) / 100 || 0 : 0
                                            }
                                        }))).toFixed(2)}
                                    </div>
                                    <div className="col-6" style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", gap: "1rem" }}>
                                        {steps[current]?.prevbtnname !== undefined &&
                                            <button className="btnPrimary"
                                                onClick={() => prev()}>
                                                {steps[current]?.prevbtnname}
                                            </button>}
                                        {steps[current]?.nextbtnname !== undefined && <button disabled={steps[current]?.nextbtndisabled} className={steps[current]?.nextbtndisabled ? "btnFilledDisabled" : "btnSecondary"}
                                            onClick={() => steps[current]?.nextbtnclick()}>
                                            {steps[current]?.nextbtnname}
                                        </button>}
                                    </div>
                                </Card> :
                                    <Card bordered={false} bodyStyle={{ gap: "1rem ", display: "flex", justifyContent: "flex-end", }}>
                                        {steps[current]?.prevbtnname !== undefined &&
                                            <button className="btnPrimary"
                                                onClick={() => prev()}>
                                                {steps[current]?.prevbtnname}
                                            </button>}
                                        {steps[current]?.nextbtnname !== undefined && <button className={steps[current]?.nextbtndisabled ? "btnFilledDisabled" : "btnSecondary"}
                                            disabled={steps[current]?.nextbtndisabled}
                                            onClick={() => steps[current]?.nextbtnclick()}>
                                            {steps[current]?.nextbtnname}
                                        </button>}
                                    </Card>}
                            </div>
                        </div>

                    </Panel>
                })}

            </Collapse> */}
            <Card bordered={false}  className={isModalView === true ? "px-4 my-1" : "px-3 my-3"} bodyStyle={{ padding: isModalView === true ? 0 : "1rem" }} >
                <div className="nk-wizard nk-wizard-simple is-alter wizard clearfix">
                    <Row className="gx-5" style={{ minHeight: "calc(100vh - 64px - 4rem)" }}>
                        <Col md="3" className="py-3" style={{ backgroundColor: "#f5f5f5", borderRadius: 8 }}>
                            <StepsView />
                        </Col>
                        <Col style={{ display: "flex", flexDirection: "column", position: "relative" }}>
                            <div style={{ flex: 1, maxHeight: "85vh", overflowY: "scroll" }}>
                                {steps[current]?.content}
                            </div>
                            
                            {type.value !== 4 && <Alert className="my-2"
                                message={<div className="textSM w-500">Info:<span className="w-normal">{` In ${type.omkar} based screeners we offer fixed portfolios and rebalancing within initial instruments`}</span></div>}
                                type="info"
                                closable
                                showIcon
                            />}
                            <div style={{ position: "relative" }}>
                                {instrumentList?.length > 0 && steps[current].id !== "aa" ? <Card bordered={false}  style={{ position: "absolute", bottom: 0, width: "100%" }} bodyStyle={{ display: "flex", flex: 1 }}>
                                    <div className="col-6" style={{ fontSize: "16px", display: "flex", alignItems: "center" }}>
                                        Minimum investment : ₹ {Number(minInvestment(instrumentList.map((data) => {
                                            return {
                                                ...data,
                                                latest_nav: type.value !== 4 ? data.latest_nav : stockprices.length > 0 ? (stockprices.filter((i) => i.Symbol === data?.symbol)?.[0]?.LTP || stockprices.filter((i) => i.Symbol === data?.symbol)?.[0]?.ClosePrice) / 100 || 0 : 0
                                            }
                                        }))).toFixed(2)}
                                    </div>
                                    <div className="col-6" style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", gap: "1rem" }}>
                                        {steps[current]?.prevbtnname !== undefined &&
                                            <button className="btnPrimary"
                                                onClick={() => prev()}>
                                                {steps[current]?.prevbtnname}
                                            </button>}
                                        {steps[current]?.nextbtnname !== undefined && <button disabled={steps[current]?.nextbtndisabled} className={steps[current]?.nextbtndisabled ? "btnFilledDisabled" : "btnSecondary"}
                                            onClick={() => steps[current]?.nextbtnclick()}>
                                            {steps[current]?.nextbtnname}
                                        </button>}
                                    </div>
                                </Card> :
                                    <Card bordered={false}  bodyStyle={{ gap: "1rem ", display: "flex", justifyContent: "flex-end", }}>
                                        {steps[current]?.prevbtnname !== undefined &&
                                            <button className="btnPrimary"
                                                onClick={() => prev()}>
                                                {steps[current]?.prevbtnname}
                                            </button>}
                                        {steps[current]?.nextbtnname !== undefined && <button className={steps[current]?.nextbtndisabled ? "btnFilledDisabled" : "btnSecondary"}
                                            disabled={steps[current]?.nextbtndisabled}
                                            onClick={() => steps[current]?.nextbtnclick()}>
                                            {steps[current]?.nextbtnname}
                                        </button>}
                                    </Card>}
                            </div>
                        </Col>
                    </Row>
                </div>
            </Card>
        </React.Fragment >
    );
};

export default BuildCustomPortfolio;
