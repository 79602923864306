import { encrypt, decrypt } from "./SHA256Hasher";
const GATEWAY_NAME = "mintbox"

const MODEL = {
  user: 'user',
  theme: 'theme',
  profileComplete: 'ProfileComp',
  userData: 'userData',
  appData: 'appData',
  clientData: "clientData",
  smallcaseSDKToken: "smallcaseSDKToken",
  createMintbox: 'createMintbox',
  // all funds
  allAssetmentPortfolios: 'allAssetmentPortfolios',
  allEtfs: 'allEtfs',
  allMutualFunds: "allMutualFunds",
  assetBenchmark: "assetBenchmark",
  //portfolio
  recentlyViewed: 'recentlyViewed',
  riskProfile: 'riskProfile',
  createGoal: 'createGoal',
  dismissNotes: 'dismissNotes',
  dematBalance: 'dematBalance',
  objective: 'objective',
  clientDetails: 'clientDetails',
  darkMode: 'darkMode',
  notification: 'notification',
  openNotification: 'openNotification',
  portfolio: 'portfolio',
  portfolioHoldings: 'portfolioHoldings',
  xirr: 'xirr',
  assetment: 'assetment',
  fcmToken: 'fcmToken',
  dashboard: 'dashboard',
  etf: 'etf',
  stock: 'stock',
  mfData: 'mfData',
  watchlist: 'watchlist',
  portfolioSnap: 'portfolioSnap',
  portfolioPerformance: 'portfolioPerformance',
  portfolioAsset: 'portfolioAsset',
  portfolioInstrument: 'portfolioInstrument',
  portfolioTransaction: 'portfolioTransaction',
  goal: 'goal',
  recentlyViewed: "recentlyViewed",
  mfCategories: "mfCategories",
  portfolioScheduledOrders: "portfolioScheduledOrders",
  clientGoals: "clientGoals",
  isFreshInstall: "isFreshInstall",
  assetBenchmark: "assetBenchmark"
};

const db = {
  // hash : new SHA256Hasher(),
  model: MODEL,
  get: async (key, dec = true) => {
    key = MODEL[key] ? MODEL[key] : key;
    let data = localStorage.getItem(key)
    if (data === null) {
      return null
    }
    if (dec) {
      data = decrypt(data);
    }
    // if (!data) return {}
    // const decryptedData = await db.hash.decrypt(new Uint8Array(data.split(",").map(Number)));
    // console.log(decryptedData);
    try {
      return JSON.parse(data)
    } catch (error) {
      console.log(error)
      return data
    }
  },
  set: async (key, val, enc = true) => {
    key = MODEL[key] ? MODEL[key] : key;
    if (enc) {
      val = encrypt(val)
    }
    // const enc = await db.hash.encrypt(val);
    const data = await localStorage.setItem(key, val);
    return data;
  },
  remove: async (key) => await localStorage.removeItem(key),
  clear: async () => {
    var aa = localStorage.getItem("lastEmail")
    var bb = localStorage.getItem("lastMobile")
    await localStorage.clear();
    sessionStorage.clear();
    localStorage.setItem("lastEmail", aa)
    localStorage.setItem("lastMobile", bb)
  },
};

export default db;