import { Card, Tabs } from "antd";
import React from "react";

const CustomSideTabs = ({ tabData }) => {
    const newTabData = tabData.filter(t => t.show).map(tab => {
        return {
            ...tab,
            children: tab.noHeader ?
                tab.children
                :
                <Card bordered={false} bodyStyle={{ backgroundColor: 'var(--grey4)', minHeight: '60vh', border: '1px solid var(--grey3)', borderRadius: '0.5rem' }}>
                    <div className="textXL w-700 dark3" style={{ marginBottom: '0.875rem' }}>{tab.label}</div>
                    {tab.children}
                </Card>
        }
    })
    const [activeKey, setActiveKey] = React.useState(newTabData?.[0]?.key)
    return <Tabs items={newTabData} tabPosition="left" style={{ marginTop: '1.25rem' }} activeKey={activeKey} defaultActiveKey={newTabData?.[0]?.key}
        renderTabBar={(props) => {
            return <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'white', border: 'none', width: '11rem', borderTop: '1px solid var(--grey4)' }}>
                {newTabData.map(tab => {
                    return <div
                        className="textSM"
                        style={{
                            width: '11rem',
                            borderLeft: '1px solid var(--grey4)',
                            borderRight: '1px solid var(--grey4)',
                            borderBottom: '1px solid var(--grey3)',
                            padding: '1rem 1.25rem',
                            backgroundColor: props.activeKey === tab.key ? 'var(--grey4)' : 'var(--white)',
                            color: props.activeKey === tab.key ? 'var(--black)' : 'var(--dark2)',
                            fontWeight: '500',
                            cursor: 'pointer'
                        }}
                        onClick={() => setActiveKey(tab.key)}
                    >
                        {tab.label}
                    </div>
                })}
            </div>
        }} />
}

export default CustomSideTabs;