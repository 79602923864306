import store, { persistor } from 'Libs/redux';
import React, { Suspense } from 'react';
import * as ReactDOM from "react-dom";
import { createStoreHook, Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'quill/dist/quill.snow.css';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { PersistGate } from 'redux-persist/integration/react';
import ActivityLoaderMintbox from 'Components/ActivityLoaderMintbox';
const root = document.getElementById('assetment')
console.log(root.attributes.getNamedItem('data-theme').value)
// root.render(
//   <React.StrictMode>
//     <Router>
//       <App />
//     </Router>
//   </React.StrictMode>
// );

// const store = createStoreHook(rootReducer);

ReactDOM.render(
  <React.Fragment>
    <Provider store={store}>
      <PersistGate loading={<ActivityLoaderMintbox />} persistor={persistor}>
        <Suspense fallback={<div />}>
          <ToastContainer />
          <Router basename={`/`}>
            <App />
          </Router>
        </Suspense>
      </PersistGate>
    </Provider >
  </React.Fragment>,

  document.getElementById("root")
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
