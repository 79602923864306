import { Card, Divider } from 'antd';
import CustomSegmentedTab from 'Components/CustomSegmentedTab';
import { fetchFundData } from 'Libs/fetchFundData';
import React from 'react';
import { useNavigate } from 'react-router';
import AdminServicesPY from 'Services/api/AdminServicesPY';
import { StockLivePrice } from 'Services/api/LivePricesWebSocket';
import ActivityLoaderMintbox from './ActivityLoaderMintbox';
import { CardHeader } from './atoms/SmallComponents';
import { CurrencyFormat } from './CurrencyFormat';
import { InstrumentListSearch } from './CustomComponents/InstrumentListSearch';
import InstrumentPerfBenchChart from './InstrumentPerfBenchChart';
import PriceVolumeChart from './PriceVolumeChart';
import PriceVsNAVChart from './PriceVsNAVChart';
const DashboardChartComponent = ({ type, header = '' }) => {

    const defChart = type.value === 4 ? "RELIANCE" : type.value === 1 ? 1171 : 1171
    const navigate = useNavigate()
    const [chartData, setChartData] = React.useState({})
    const [selectedInstrument, setselectedInstrument] = React.useState({})
    const [mainData, setmainData] = React.useState([]);
    const [chartLoad, setChartLoad] = React.useState(true);

    const items = type.value === 4 && Object.keys(chartData).length > 0 ? [
        {
            label: `Performance vs Benchmark`,
            value: '1',
            key: '1',
            children: <span>
                <InstrumentPerfBenchChart
                    // showLiveTickerVal={true}
                    fundData={selectedInstrument}
                    chartData={JSON.parse(chartData?.perf?.replace(/NaN/g, null))?.total_returns?.prices}
                />
            </span>,
        },
        {
            label: `Price volume chart`,
            value: '3',
            key: '3',
            children: <div className="py-3">
                <PriceVolumeChart chartData={JSON.stringify(chartData?.priceVol)?.replace(/NaN/g, null)} />
            </div>,
        },
    ] : type.value === 1 && Object.keys(chartData).length > 0 ? [
        {
            label: `Performance vs Benchmark`,
            key: '1',
            value: '1',
            children: <span>
                <InstrumentPerfBenchChart
                    // showLiveTickerVal={true}
                    fundData={selectedInstrument}
                    chartData={JSON.parse(chartData?.perf?.replace(/NaN/g, null))?.prices}
                />
            </span>,
        },
        {
            label: `Price vs NAV`,
            key: '2',
            value: '2',
            children: <span>
                <PriceVsNAVChart
                    chartData={{
                        data: JSON.stringify(chartData?.priceNAV)?.replace(/NaN/g, null)
                    }} />
            </span>,
        },
        {
            label: `Price volume chart`,
            value: '3',
            key: '3',
            children: <div className="py-3">
                <PriceVolumeChart chartData={JSON.stringify(chartData?.priceVol)?.replace(/NaN/g, null)} />
            </div>,
        },
    ] : type.value === 2 && Object.keys(chartData).length > 0 ? [
        {
            label: `Performance vs Benchmark`,
            key: '1',
            value: '1',
            children: <span>
                <InstrumentPerfBenchChart
                    // showLiveTickerVal={true}
                    fundData={selectedInstrument}
                    chartData={JSON.parse(chartData?.perf?.replace(/NaN/g, null))?.prices}
                />
            </span>,
        }
    ] : []

    const [selectedTab, setselectedTab] = React.useState('1');

    const getChartData = async () => {
        if (type.value === 4) {
            //stocks
            var data = {
                "symbol": selectedInstrument?.symbol,
                "mintbox_bm_id": 7,
                "instrument_type": type.value
            }

            var res = await AdminServicesPY.getInstrumentCharts(data).then((r) => r)
            setChartData(res?.data)
        } else if (type.value === 1) {
            //etf
            var data = {
                "symbol": selectedInstrument?.nse_symbol,
                "plan_id": selectedInstrument?.plan_id,
                "mintbox_bm_id": 7,
                "instrument_type": type.value
            }
            // console.log(data)

            var res = await AdminServicesPY.getInstrumentCharts(data).then((r) => r)
            setChartData(res?.data)
        } else {
            //mf
            var data = {
                "plan_id": selectedInstrument?.plan_id,
                "mintbox_bm_id": 7,
                "instrument_type": type.value
            }

            var res = await AdminServicesPY.getInstrumentCharts(data).then((r) => r)
            setChartData(res?.data)
        }

        setChartLoad(false)
    }

    React.useEffect(() => {
        // console.log(selectedInstrument)
        if (Object.keys(selectedInstrument).length > 0) {
            setChartLoad(true)
            getChartData()
        }
    }, [selectedInstrument])


    const loadData = async (type) => {
        let a = await fetchFundData(type.value, false)
        setmainData(a)
        if (type.value === 4) {
            setselectedInstrument(a.filter((i) => i.symbol === "IEX")[0])
        } else if (type.value === 1) {
            setselectedInstrument(a.filter((i) => i.nse_symbol === "NIFTYBEES")[0])
        } else {
            setselectedInstrument(a.filter((i) => i.plan_id === 17381)[0])
        }
    }

    React.useEffect(() => {
        setselectedTab('1')
        setChartLoad(true)
        loadData(type)
    }, [type.value])




    return <Card bordered={false} bodyStyle={{ paddingBottom: 0 }}>
        <div className='d-flex'>
            {header.length > 0 && <CardHeader heading={header} />}
            {/* <InstrumentSearchComponent
                type={type}
                onChange={(e) => {
                    setselectedInstrument(e)
                }}
                searchText={`Search ${type.dname} charts`}
            /> */}
            <span style={{ width: "15rem" }}>
                <InstrumentListSearch placeholder={`Search and add ${type.dname}`} type={type.value} onChange={(e) => setselectedInstrument(e[0])} />
            </span>
        </div>
        <Divider style={{ margin: "0.75rem 0rem" }} />
        <div style={{ display: "flex", alignItems: "center" }}>
            <span className='textMD w-700' style={{ flex: 1, color: 'var(--black)', display: "flex", flexDirection: "column", gap: "0.5rem" }}>
                <span>
                    <span style={{ cursor: "pointer" }} onClick={() => navigate(type.basePath + `${type.value === 4 ? '/stock-detail/' + selectedInstrument?.symbol : '/fund-detail/' + selectedInstrument?.basic_name?.replace(" - Direct Plan", "")?.replaceAll(' ', '-') + '?code=' + selectedInstrument?.plan_id}`)}>{selectedInstrument?.proper_name || selectedInstrument?.basic_name} <i style={{ marginLeft: "0.5rem" }} className="fi fi-rr-arrow-up-right-from-square"></i></span>
                </span>
                {type.value !== 2 ? <StockLivePrice format={5} symbol={selectedInstrument?.symbol || selectedInstrument?.nse_symbol} /> : <span className='dark3'>NAV: {CurrencyFormat(selectedInstrument?.latest_nav)}</span>}
            </span>
            <CustomSegmentedTab
                flexVal="unset"
                isBlock={false}
                value={selectedTab}
                onChange={(e) => {
                    setselectedTab(e)
                }}
                options={items}
            />
        </div>
        <Divider style={{ margin: "0.75rem 0rem" }} />
        {Object.keys(chartData).length > 0 ? items.filter((i) => i.value === selectedTab)[0]?.children : <div style={{ height: "22.8rem" }}><ActivityLoaderMintbox /></div>}
    </Card>
}

export default DashboardChartComponent;