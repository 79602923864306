const RiskFormat = (riskID) => {
    switch (riskID) {
        case 1: return 'Super conservative'
        case 2: return 'Conservative'
        case 3: return 'Moderate'
        case 4: return 'Aggressive'
        case 5: return 'Super aggressive'
        case 6: return 'Very aggressive'
        default: return ''
    }
}

const RiskColor = (riskID) => {
    switch (riskID) {
        case 1: return 'var(--lowestRisk)'
        case 2: return 'var(--conservativeRisk)'
        case 3: return 'var(--moderateRisk)'
        case 4: return 'var(--secondHeighestRisk)'
        case 5: return 'var(--highestRisk)'
        case 6: return 'var(--highestRisk)'
        default: return ''
    }
}

const RiskTextColor = (riskID) => {
    switch (riskID) {
        case 1: return '#3395FF'
        case 2: return '#285E4D'
        case 3: return '#FAAD3B'
        case 4: return '#F97B30'
        case 5: return '#FF5A5F'
        case 6: return 'red'
        default: return ''
    }
}
export { RiskFormat, RiskColor, RiskTextColor }