import { Card } from "antd";
import React from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { CategoryIcon } from "./CategoryIcon";

const KnowledgeBaseCategories = ({ allCategories }) => {
    const navigate = useNavigate();
    return (
        <React.Fragment>
            <Row sm={3} xs={2} className='gx-3 gy-3 margin20Bottom'>
                {allCategories.map((item) => (
                    <Col key={item.UID}>
                        <Card style={{ height: "100%", border: "1px solid var(--grey1)", borderRadius: "0.5rem" }} hoverable={true}
                            onClick={() => navigate(`${item?.Slug}`)} bordered={true}>
                            <div className='d-flex align-items-center' style={{ gap: "2.5rem" }}>
                                <CategoryIcon title={item.Category} />
                                <div style={{ textTransform: "capitalize" }}>
                                    <Link to={`${item?.Slug}`}
                                        className='cardHeader displayXS' style={{ fontWeight: 700 }}>
                                        {item.Category}
                                    </Link>
                                </div>
                            </div>
                        </Card>
                    </Col>
                ))}
            </Row>
        </React.Fragment>
    )
}

export default KnowledgeBaseCategories;