import { Segmented } from 'antd';
import React from 'react';

export default function CustomSegmentedTab({ options = [], isBlock = true, onChange = () => { }, value = '', style = {}, className = '', flexVal=1 }) {

    // both formats are allowed in response you will get value
    // [{
    //     label: 'Watchlist',
    //     key: 'Watchlist',
    //     value: 'Watchlist'
    //   }]
    //   ['Watchlist', 'Screeners', 'Strategies']

    const onChangeVal = (i) => {
        onChange(i)
    }

    return <Segmented block={isBlock}
        value={value}
        className={'textSM w-500 ' + className}
        style={{ padding: '0.5rem', flex: flexVal, ...style }}
        onChange={(e) => onChangeVal(e)}
        options={
            options
        }
    />
}