import db from "Libs/db";

const maxLength = 5;

export default function addToRecentlyViewed(fund) {
    const keyName = fund.type === 1 ? 'ETFs' : fund.type === 2 ? 'MutualFunds' : fund.type === 3 ? 'Mintboxes' : ''
    if (keyName === '') { return }
    db.get(db.model.recentlyViewed).then(async (p) => {
        if (p === null) {
            p = {
                'ETFs': [],
                'MutualFunds': [],
                'Mintboxes': []
            }
        }
        else if (p[keyName].some(f => f.ID === fund.ID)) {
            p[keyName] = p[keyName].filter(f => f.ID !== fund.ID) // remove existing fund
        } else if (p[keyName].length >= maxLength) {
            p[keyName].pop() // removes last fund
        }
        p[keyName].unshift(fund) // adds fund to front of array
        await db.set(db.model.recentlyViewed, JSON.stringify(p))
    })
}