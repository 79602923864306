import { Button, Card, Cascader, DatePicker, Select, Space } from 'antd';
import ActivityLoaderMintbox from 'Components/ActivityLoaderMintbox';
import { CardHeader } from 'Components/atoms/SmallComponents';
import { formatDateyyyymmdd } from 'Components/DateFormatter';
import InstrumentListTable from 'Components/InstrumentListTable';
import RedGreenText from 'Components/RedGreenText';
import Head from 'Layout/Head';
import { fetchFundData } from 'Libs/fetchFundData';
import { displayValue, getUser, screenerMetricData, useGetAssetType } from 'Libs/utils';
import moment from 'moment';
import React, { useCallback, useState } from 'react';
import { MdArrowDropDown, MdCancel } from 'react-icons/md';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import AdminServicesPY from 'Services/api/AdminServicesPY';

const AlphaAnalysis = (props) => {

    const navigate = useNavigate()
    const [allFundsData, setallFundsData] = useState([])
    const [allFilteredFundsData, setallFilteredFundsData] = useState([])
    const [allbenchmarks, setallbenchmarks] = useState([])
    const [mfCategories, setmfCategories] = useState([])
    const [loading, setloading] = useState(true)
    const [user, setUser] = useState(undefined)
    const type = useGetAssetType()

    const [category_id, setcategory_id] = useState(['Equity', '103'])
    const [benchmark_id, setbenchmark_id] = useState([1])
    const [start_date, setstart_date] = useState(null)
    const [end_date, setend_date] = useState(null)
    const [dates, setDates] = useState(null);
    const [value1, setValue1] = useState(null);
    const [value2, setValue2] = useState(null);
    const [tableLoading, setTableLoading] = useState(true)
    const minDate = moment('2013-01-01');
    const maxDate = moment(new Date());

    const getRange = (start, end) => [
        ...Array((end - start) + 1).keys(),
    ].map((
        (i) => i + start
    ));

    const disabledDate1 = useCallback((d) => {
        if (d == null) {
            return null;
        }
        return (
            (minDate != null && d.isBefore(minDate) && !d.isSame(minDate, 'day')) ||
            (maxDate != null && d.isAfter(maxDate) && !d.isSame(maxDate, 'day'))
        );
    }, [minDate, maxDate]);

    const disabledDate2 = useCallback((d) => {
        if (d == null) {
            return null;
        }
        return (
            (minDate != null && d.isBefore(minDate) && !d.isSame(minDate, 'day')) ||
            (maxDate != null && d.isAfter(maxDate) && !d.isSame(maxDate, 'day')) ||
            (value1 != null && d.isBefore(value1) && !d.isSame(value1, 'day')) || (d.diff(value1, 'days') < 91)
        );
    }, [minDate, maxDate, value1]);

    const onOpenChange = (open) => {
        if (open) {
            setDates([null, null]);
        } else {
            setDates(null);
        }
    };

    const loadData = async () => {

        var screener_datas = await screenerMetricData(type)
        var benchmarks = await fetchFundData(11, true)
        setallbenchmarks(benchmarks)
        var user = await getUser()
        setUser(user)
        setmfCategories(screener_datas?.fabricatedData?.mf_categories)
        setallFundsData(screener_datas?.allFunds)
        setallFilteredFundsData(screener_datas?.allFunds)
        setloading(false)

    }

    React.useEffect(() => {
        loadData()
    }, [])


    const getAlphaData = async () => {
        if (start_date !== null || end_date !== null) {
            setTableLoading(true)
            var d = await AdminServicesPY.getMFAlphaAnalysis({ category_id: category_id[1], benchmark_id, start_date, end_date, instrument_type: type.value === 1 ? 1 : 0 }).then((r) => r)
            // console.log(d)
            if (d?.type) {
                var filteredfunds = JSON.parse(d?.data?.replace(/NaN/g, null))
                filteredfunds = filteredfunds.map((i) => {
                    return {
                        ...allFundsData.filter((a) => a.plan_id === i.plan_id)[0],
                        ...i,
                        "start": moment(String(i?.start).replace("T00:00:00", "").split('-').join('/'), 'YYYY/MM/DD'),
                        "end": moment(String(i?.end).replace("T00:00:00", "").split('-').join('/'), 'YYYY/MM/DD')
                    }
                })

                setallFilteredFundsData(filteredfunds)
                setTableLoading(false)
            }
            setTableLoading(false)
        } else {
            toast.error("Please select start and end date")
        }
    }


    const AppliedFilterView = () => (<Card title={<CardHeader heading={'Alpha analysis'} />}>

        <Space direction='horizontal' style={{ alignItems: "flex-end" }}>
            <Space direction='vertical' className='mx-2 mt-2' style={{}}>
                <div className='textSM' style={{ flex: 1, fontWeight: "bold" }}>Select {type.omkar} category</div>

                <Cascader
                    options={JSON.parse(JSON.stringify(mfCategories)).map((i, index) => {
                        i["value"] = i.categoryName
                        i["label"] = i.categoryName
                        i["title"] = i.categoryName
                        if (i["subCategories"] !== undefined) {
                            i["subCategories"]?.map((ii, indexi) => {
                                ii["value"] = String(ii.categoryId)
                                ii["label"] = ii.subCategoryName
                                return ii
                            })
                            i["children"] = i["subCategories"]
                        }
                        return i
                    })}
                    style={{
                        width: "15rem",
                    }}
                    expandTrigger="hover"
                    placeholder={"Please select " + type.omkar + " category"}
                    defaultValue={category_id}
                    onChange={(value) => {
                        // console.log(value)
                        setcategory_id(value)
                    }}
                />
            </Space>

            <Space direction='vertical' className='mx-2 mt-2' style={{}}>
                <div className='textSM' style={{ flex: 1, fontWeight: "bold" }}>Select benchmark</div>

                <Select removeIcon={<MdCancel size={12} />}
                    className='textXS black'
                    style={{
                        width: "250px"
                    }}
                    suffixIcon={<MdArrowDropDown size={17} />}
                    defaultValue={benchmark_id}
                    placeholder={"Please select benchmark"}
                    onChange={(value) => {
                        // console.log(value)
                        setbenchmark_id([value])
                    }}
                    options={allbenchmarks.map((i) => {
                        // console.log(i)
                        return {
                            'value': i.benchmark_id,
                            'key': i.benchmark_id,
                            'label': i.benchmark_name
                        }
                    })}
                />
            </Space>

            <Space direction='vertical' className='mx-2 mt-2' style={{}}>
                <div className='textSM' style={{ flex: 1, fontWeight: "bold" }}>Select date range <span className='textXS'>(min. 3 months range)</span></div>

                <Space direction='horizontal'>
                    <DatePicker disabledDate={disabledDate1} placeholder='Start date' value={value1} format={'DD-MM-YYYY'} onChange={(val) => {
                        setstart_date(formatDateyyyymmdd(new Date(val)))
                        setValue1(val)
                    }} />
                    <DatePicker disabledDate={disabledDate2} disabled={value1 === null || value1 === undefined} placeholder='End date' value={value2} format={'DD-MM-YYYY'} onChange={(val) => {
                        setend_date(formatDateyyyymmdd(new Date(val)))
                        setValue2(val)
                    }} />
                </Space>
            </Space>



            <button className="btnPrimary ms-auto mx-2" onClick={() => {
                getAlphaData()
            }}>
                Check
            </button>
        </Space>

    </Card >
    )


    const alphaCOls = [{
        "dataIndex": "start",
        "key": "start",
        "metric_col_code": "start",
        "metric_unit": "",
        "metric_data_type": "date",
        "metric_name": "Start date",
        "title": "Start date",
        "align": "right",
        "width": 100,
    }, {
        "dataIndex": "end",
        "key": "end",
        "metric_col_code": "end",
        "metric_unit": "",
        "metric_data_type": "date",
        "metric_name": "End date",
        "title": "End date",
        "align": "right",
        "width": 100,
    }, {
        "dataIndex": "rets",
        "key": "rets",
        "metric_col_code": "rets",
        "metric_unit": "percentage",
        "metric_data_type": "float",
        "metric_name": "Fund returns",
        "title": "Fund returns",
        "align": "right",
        "width": 100,
    }, {
        "dataIndex": "bm_rets",
        "key": "bm_rets",
        "metric_col_code": "bm_rets",
        "metric_unit": "percentage",
        "metric_data_type": "float",
        "metric_name": "Benchmark returns",
        "title": "Benchmark returns",
        "align": "right",
        "width": 100,
    }, {
        "dataIndex": "alpha",
        "key": "alpha",
        "metric_col_code": "alpha",
        "metric_unit": "",
        "metric_data_type": "float",
        "metric_name": "Alpha",
        "title": "Alpha",
        "align": "right",
        "width": 100,
    }, {
        "dataIndex": "beta",
        "key": "beta",
        "metric_col_code": "beta",
        "metric_unit": "",
        "metric_data_type": "float",
        "metric_name": "Beta",
        "title": "Beta",
        "align": "right",
        "width": 100,
    }, {
        "dataIndex": "r2",
        "key": "r2",
        "metric_col_code": "r2",
        "metric_unit": "",
        "metric_data_type": "float",
        "metric_name": "R2",
        "title": "R2",
        "align": "right",
        "width": 100,
    }]

    if (loading) {
        return <React.Fragment>
            <div className='mainContainer' style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
                <ActivityLoaderMintbox />
            </div>
        </React.Fragment>
    } else {
        return (
            <React.Fragment>
                <Head title="Alpha analysis" />
                <div className='normalContainer'>
                    <AppliedFilterView />

                    <InstrumentListTable
                        tableType={0}
                        hideCheckBox={true}
                        istableLoading={tableLoading}
                        otherCol={allFilteredFundsData.length !== allFundsData.length ? alphaCOls.map((col) => {
                            return {
                                ...col,
                                "render": (text, record, index) => <div style={{ display: 'flex', gap: '5px', cursor: "pointer", alignItems: "center", justifyContent: "flex-end" }}>
                                    {displayValue({
                                        text, metricObj: col
                                    })}
                                </div>
                            }
                        }) : []}
                        finalList={JSON.parse(JSON.stringify(allFilteredFundsData))}
                        tableHeader={<span>Showing {allFilteredFundsData.length} out of {allFundsData.length} {type.dname}</span>}
                    />
                </div>
            </React.Fragment >
        );
    }

}

export default AlphaAnalysis