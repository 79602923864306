import React from 'react'
import Lottie from 'react-lottie';
import animationData from 'Assets/lottie/MintboxLoader.json'
import { Button, Card, Empty, Spin } from 'antd';
import { useNavigate } from 'react-router';

const ErrorComponent = ({ type = '', margin = '0%' }) => {

const navigate = useNavigate()
  return (
    <Empty
    image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
    imageStyle={{
      height: 60,
    }}
    description={
        <>
      <div>
        Error loading data
      </div>
      <div className='mt-4'>
        Please refresh or try again after some time
      </div>
      </>
    }
  >
    <button className='btnPrimary' onClick={()=>navigate('/')}>Go to dashboard</button>
  </Empty>
  )
}

export default React.memo(ErrorComponent)