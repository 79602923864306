import { useState } from "react";

import { Avatar, Dropdown } from "antd";
import db from "Libs/db";
import { updateUserData } from "Libs/redux/UserAuth/reduxSlice";
import { CgDarkMode } from 'react-icons/cg';
import { FiBookmark, FiBriefcase, FiLogOut } from 'react-icons/fi';
import { IoIosArrowDown } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

const User = ({ user, isDarkMode, toggleDarkMode }) => {
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen(!open);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  var themeNM = useSelector((state) => state?.changeTheme?.value)
  const items = [
    {
      key: '1',
      icon: <Avatar>
        {user?.name[0]}
      </Avatar>,
      label: (
        <Link className="" to="/user-profile">
          <div className="fontH2">{user?.name}</div>
          <div className="textXS">{user?.email}</div>
        </Link>
      ),
    },
    {
      type: 'divider',
    },
    {
      key: '2',
      icon: <FiBriefcase />,
      label: (
        <Link to="/portfolio" className="fontH2">
          Portfolio
        </Link>
      ),
    },
    {
      key: '22',
      icon: <FiBookmark />,
      label: (
        <Link to="/watchlist" className="fontH2">
          Watchlist
        </Link>
      ),
    },
    {
      key: '221',
      icon: <CgDarkMode />,
      label: (
        <Link to="/" className="fontH2" onClick={(e) => {
          e.preventDefault()
          dispatch(changeTheme(themeNM === "light" ? "dark" : "light"))
        }}>
          Dark mode
        </Link>
      ),
    },
    {
      type: 'divider',
    },
    {
      key: '4',
      danger: true,
      icon: <FiLogOut />,
      label: <Link to="/" onClick={() => {
        db.clear()
        dispatch(updateUserData(undefined))
      }} className="fontH2">
        Sign out
      </Link>,
    },
  ];


  const changeTheme = () => {
    if (isDarkMode === true) {
      document.documentElement.setAttribute('data-theme', 'light')
      localStorage.setItem('theme', 'light')
      toggleDarkMode(false)
    } else {
      document.documentElement.setAttribute('data-theme', 'dark')
      localStorage.setItem('theme', 'dark')
      toggleDarkMode(true)
    }
  }


  return (
    user !== undefined && user !== null && user?.length !== 0 ?
      <Dropdown
        overlayStyle={{ width: "15vw" }}
        menu={{
          items,
        }}
      >
        <div style={{ display: "flex", alignItems: "center", flexDirection: "row", gap: "0.2rem", cursor: "pointer" }}>
          <Avatar src="https://joesch.moe/api/v1/random?key=1" />
          <span className="textSM w-500 dark3 me-1">{user?.name}</span>
          <IoIosArrowDown />
        </div>
      </Dropdown> :
      <div style={{ display: "flex", gap: "0.875rem", alignItems: "center" }}>
        <button className={"btnPrimary"} onClick={() => {
          navigate(`/login`)
        }}>
          Login
        </button>
        <button className={"btnSecondary"} onClick={() => {
          navigate(`/signup`)
        }}>
          Signup
        </button>
      </div>
  );
};

export default User;
