import dontTouch from 'Assets/images/style-box-icons/dontTouch.svg';
import fallenAngel from 'Assets/images/style-box-icons/fallenAngel.svg';
import fallingAngel from 'Assets/images/style-box-icons/fallingAngel.svg';
import marketAngel from 'Assets/images/style-box-icons/marketAngel.svg';
import momentum from 'Assets/images/style-box-icons/momentum.svg';
import neutral from 'Assets/images/style-box-icons/neutral.svg';
import superStock from 'Assets/images/style-box-icons/superStock.svg';
import turnAround from 'Assets/images/style-box-icons/turnAround.svg';
import underRadar from 'Assets/images/style-box-icons/underRadar.svg';
import valueTrap from 'Assets/images/style-box-icons/valueTrap.svg';

const StyleBoxIcons = ({ style_box_code, size = "1.5rem" }) => {
    const icons = [neutral, superStock, underRadar, marketAngel, turnAround, valueTrap, momentum, fallingAngel, fallenAngel, dontTouch]
    return <img src={icons[style_box_code]} style={{ width: size, height: size }} />
}

export default StyleBoxIcons