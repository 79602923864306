import DashboardCards1 from "Components/DashboardCards1";
import Head from "Layout/Head";
import { getUser } from "Libs/utils";
import React from "react";
import { useNavigate } from "react-router";
import { Row } from "reactstrap";

const Home = (props) => {
    const [loading, setLoading] = React.useState(true);
    const user = getUser()
    var navigate = useNavigate()

    const [data, setData] = React.useState([])
    // Stocks
    const instrumentList = {
        heading: "Welcome to sharpely",
        content: "",
        slides: [
            {
                UID: 'c',
                cardClass: 'card-gray',
                heading: <>
                    <div className="textSM">ETFs</div>
                    <div className="fontNormalDark14"></div>
                </>,
                btnColor: "#285E4D",
                bgColor: "#FAFAFA",
                onClick: () => {
                    navigate({
                        pathname: "/etfs/dashboard",
                        // state: { type: 3 }
                    })
                },
                show: true,
                backgroundColor: '#222a23',
            },
            {
                UID: 'a',
                cardClass: 'card-gray-light-1',
                heading: <>
                    <div className="textSM">Mutual funds</div>
                    <div className="fontNormalDark14"></div>
                </>,
                btnColor: "#000B16",
                bgColor: "#FAFAF4",
                onClick: () => {
                    navigate({
                        pathname: "/mutual-funds/dashboard",
                    })
                },
                show: true,
                backgroundColor: '#222a23',
            },
            {
                UID: 'b',
                cardClass: 'card-gray-light-2',
                heading: <>
                    <div className="textSM">Stocks</div>
                    <div className="fontNormalDark14"></div>
                </>,
                btnColor: "#294C88",
                bgColor: "#FAFAF4",
                onClick: () => {
                    navigate({
                        pathname: "/stocks/dashboard",
                        // state: { type: 3 }
                    })
                },
                show: true,
                backgroundColor: '#222a23',
            }
        ]
    }

    return (
        <React.Fragment>
            <Head title="Home" />
            <Row style={{ gap: "20px" }}>
                <DashboardCards1 data={instrumentList} />
            </Row>
        </React.Fragment>
    );
};

export default Home;
