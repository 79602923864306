import React from 'react';
import { IoIosArrowForward } from 'react-icons/io';
import { Link, useLocation } from 'react-router-dom';

const disabledPaths = ['/etfs/dashboard', '/mutual-funds/dashboard', '/stocks/dashboard', '/etfs', '/mutual-funds', '/stocks', '/', '/login'];

const BreadCrumbs = () => {
    const location = useLocation();
    let navItems = formatNavItems(location);
    let checkPath = disabledPaths.filter((ele) => location?.pathname?.startsWith(ele) && location.pathname.endsWith(ele));
    if (checkPath.length) {
        return (
            <React.Fragment></React.Fragment>
        )
    }
    return (
        <div style={{
            padding: "1.25rem 0px", background: "var(--white)", borderTop: "1px solid var(--grey2)",
            borderBottom: "1px solid var(--grey2)"
        }}>
            <div className='d-flex align-items-center flex-wrap headerContainer textSM'
                style={{ gap: "0.4rem", fontWeight: "500" }}>
                <Link to='/' className={!navItems.length ? "breadcrumbsCurr" : 'breadcrumbPrev'}>
                    <div>Home</div>
                </Link>
                {navItems?.map((item, i) => {
                    return (
                        <React.Fragment key={item?.key}>
                            <IoIosArrowForward />
                            <Link to={i + 1 === navItems.length ? (location.pathname + location.search) : item?.to} className={navItems.length === i + 1 ? "breadcrumbCurr" : "breadcrumbPrev"}>
                                <div>
                                    {item?.label}
                                </div>
                            </Link>
                        </React.Fragment>
                    )
                })}
            </div>
        </div>
    )
}

export default BreadCrumbs;

let modifiedPaths = ['etfs', 'mutual-funds', 'stocks'];
let omittedPaths = ['', '-', 'fund-detail']
function formatNavItems(location) {
    let regexPattern = /[^A-Za-z0-9]/g;
    let to = '';
    let loc = decodeURIComponent(location.pathname);
    let links = []
    loc?.split('/')?.forEach((ele, i, arr) => {
        if (omittedPaths.includes(ele)) {
            if (ele) to += '/' + ele;
        }
        else {
            let temp = ele.replace(regexPattern, ' ');
            // For modifying path of resources
            if (temp.endsWith('html')) {
                temp = temp.split(' ');
                temp.pop();
                temp = temp.join(' ');
            }
            // For mpodifying labels
            if (temp === 'etfs') temp = 'ETF';
            if (temp === 'screener') {
                if (arr[i - 1] === 'etfs') temp = 'ETF Screener';
                else temp = arr[i - 1].replace(regexPattern, ' ') + ' ' + temp;
            }
            to += '/' + ele;
            if (modifiedPaths.includes(ele) && i === 1) {
                return links.push({ to: to + '/dashboard', label: temp, key: ele })
            }
            links.push({ to, label: temp, key: ele })
        }
    })
    return links;
}