import React from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import AdminServices from 'Services/api/AdminServices';
import db from 'Libs/db';
import Logo from 'Layout/Logo';

export default function Register(props) {
  const [email, setEmail] = React.useState('');
  const [name, setName] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [otp, setOTP] = React.useState('');
  const [mainOTP, setmainOTP] = React.useState('');
  const [nextBtnPressed, setNextBtnPressed] = React.useState(false)

  const cyrb53 = (str, seed = 0) => {
    let h1 = 0xdeadbeef ^ seed, h2 = 0x41c6ce57 ^ seed;
    for (let i = 0, ch; i < str.length; i++) {
      ch = str.charCodeAt(i);
      h1 = Math.imul(h1 ^ ch, 2654435761);
      h2 = Math.imul(h2 ^ ch, 1597334677);
    }
    h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^ Math.imul(h2 ^ (h2 >>> 13), 3266489909);
    h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^ Math.imul(h1 ^ (h1 >>> 13), 3266489909);
    return 4294967296 * (2097151 & h2) + (h1 >>> 0);
  };

  const checkOTP = async () => {
    if (Number(otp) === Number(mainOTP)) {
      const data = {
        mobileNumber: phone, // will be blank if skip is pressed
        email: email,
        clientName: name,
        source: "web"
      }
      console.log(data)
      try {
        const res = await AdminServices.createIFA(data).then((res) => res)
        console.log(res)
        if (res.type) {
          if (res.data.Result === 'success') {
            const userData = {
              name: name,
              email: email,
              mobile: phone,
              id: res.data.ClientId,
              isLogged: true,
            };
            db.set(db.model.ifa, JSON.stringify({ userData }));
            localStorage.setItem("isAdmin", cyrb53("yesitisamintboxadmin"))
            props.history.push("/dashboard")
            setNextBtnPressed(false);
          } else {
            toast.error("Try again ...");
            setNextBtnPressed(false);
          }
        } else {
          toast.error("Try again ...");
          setNextBtnPressed(false);
        }
      } catch (error) {
        toast.error("Try again ...");
        setNextBtnPressed(false);
      }

    } else {
      toast.error("Incorrect OTP. Try again ...");
      setNextBtnPressed(false);
    }
  }

  const sendOTP = async (id) => {
    try {
      setmainOTP('')
      const res = await AdminServices.generateOTP(email, "EMAIL").then((res) => res)
      if (res.type) {
        if (res.data.Result === 'success') {
          setmainOTP(res.data.otpcode);
          toast.success("OTP sent on mail");
        } else {
          toast.error("Try again ...");
        }
      } else {
        toast.error("Try again ...");
      }
    } catch (error) {
      toast.error("Try again ...");
    }
  };

  const onNextBtnPressed = async () => {
    setNextBtnPressed(true);
    console.log("DSFDSF")
    try {
      const res = await AdminServices.ifaExists(email, "EMAIL").then((res) => res);
      console.log("DSFDSF", res)
      if (res.type) {
        console.log("client exists API ", res.data)
        if (res.data.IFAId !== '') {
          setNextBtnPressed(false);
          toast.info("Already a mintbox registered IFA!! Please Login.");
        } else {
          sendOTP(res.data.IFAId)
        }
      } else {
        toast.warn("Try again ...");
        setNextBtnPressed(false);
      }
    } catch (error) {
      toast.warn("Try again ...");
      console.log("DSFDSFsda", error)
      setNextBtnPressed(false);
    }
  };

  return (
    <div>
      under construction
      {/* <div className="d-flex align-items-center auth px-0 h-100">
        <div className="row w-100 mx-0">
          <div className="col-lg-4 mx-auto">
            <div className="card text-left py-5 px-4 px-sm-5">
              <Logo type='dark' isClickable={false} />
              <h4>New here?</h4>
              <h6 className="font-weight-light">Signing up is easy. It only takes a few steps</h6>
              <form className="pt-3">
                {nextBtnPressed ? <div className="form-group">
                  <input type="pin" className="form-control form-control-lg" id="exampleInputOTP" placeholder="OTP" onChange={(e) => {
                    setOTP(e.target.value)
                  }} />
                </div> : <>
                  <div className="form-group">
                    <input type="text" className="form-control form-control-lg" id="exampleInputUsername1" placeholder="Name" onChange={(e) => {
                      setName(e.target.value)
                    }} />
                  </div>
                  <div className="form-group">
                    <input type="email" className="form-control form-control-lg" id="exampleInputEmail1" placeholder="Email" onChange={(e) => {
                      setEmail(e.target.value)
                    }} />
                  </div>
                  <div className="form-group">
                    <input type="pin" className="form-control form-control-lg" id="exampleInputPhone1" placeholder="Phone number" onChange={(e) => {
                      setPhone(e.target.value)
                    }} />
                  </div>
                </>}
   
                <div className="mt-3">
                  <div type="submit" className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn" onClick={nextBtnPressed ? () => checkOTP() : () => onNextBtnPressed()}>{nextBtnPressed ? "Become a assetment IFA" : "Send OTP"}</div>
                </div>
                <div className="text-center mt-4 font-weight-light">
                  Already have an account? <Link to="/login" className="text-primary">Login</Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  )
}

