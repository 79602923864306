import { HiOutlineArrowSmDown, HiOutlineArrowSmUp } from "react-icons/hi";

const RedGreenText = ({ text, value, isBg = false }) => {
    if (Number(value) > 0) {
        return <span style={{ color: "var(--green)", backgroundColor: isBg ? "var(--greenShade)" : 'unset', padding: isBg ? "0.25rem" : 'unset', borderRadius: isBg ? 4 : 'unset' }}>{isBg && <HiOutlineArrowSmUp size={15} color="var(--green)" />}{text}
        </span>
    } else if (Number(value) < 0) {
        return <span style={{ color: "var(--red)", backgroundColor: isBg ? "var(--redShade)" : 'unset', padding: isBg ? "0.25rem" : 'unset', borderRadius: isBg ? 4 : 'unset' }}>
            {isBg && <HiOutlineArrowSmDown size={15} color="var(--red)" />}{text}
        </span>
    } else return <span>{text}</span>
}

export default RedGreenText;