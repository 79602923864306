import { Segmented } from 'antd';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useGetAssetType } from 'Libs/utils';
import React from 'react';
import { BsFillCircleFill } from 'react-icons/bs';
import { CurrencyFormat } from './CurrencyFormat';
import CustomSegmentedTab from './CustomSegmentedTab';
import { changeObjKey } from './DataCustomizer';
import { formatDate } from './DateFormatter';
import RedGreenText from './RedGreenText';
import StyleBoxIcons from './StyleBoxIcons';

const PerformanceChart2 = ({ chartData = [], benchName = "", fundName = "", portfolioType = 0, goLiveDate = "", defYrSelect = "", defAmtSelect = "", showOnlySelectedKey = "", xAxisKey = "Date", fundColors = ['#4284f3', '#fabc05', '#24c1e0', '#fa7b16', '#b74447', '#333', 'purple', 'violet', 'red', 'darkgreen'], isStyleBox = false }) => {
    // portfolioType 1 = curated , 2 = custom, 0 = for individual funds
    // portfolioType = -1 => for showing only one amount value 

    const type = useGetAssetType()
    const yrtabs = chartData !== undefined ? portfolioType === 1 ? [
        { key: "A", label: "1Y", value: "365D", show: chartData["365D"] !== undefined ? JSON.parse(chartData?.["365D"]?.data).length > 0 : false },
        { key: "b", label: "3Y", value: "1095D", show: chartData["1095D"] !== undefined ? JSON.parse(chartData?.["1095D"]?.data).length !== JSON.parse(chartData?.["365D"]?.data).length : false },
        { key: "c", label: "5Y", value: "1825D", show: chartData["1825D"] !== undefined ? JSON.parse(chartData?.["1825D"]?.data).length !== JSON.parse(chartData?.["1095D"]?.data).length : false },
        { key: "d", label: "10Y", value: "3650D", show: chartData["3650D"] !== undefined ? JSON.parse(chartData?.["3650D"]?.data).length !== JSON.parse(chartData?.["1825D"]?.data).length : false },
        { key: "dm", label: "Live", value: "live", show: true },
        { key: "dmk", label: "Max", value: "max", show: chartData["max"] !== undefined ? JSON.parse(chartData?.["max"]?.data).length !== JSON.parse(chartData?.["3650D"]?.data).length : false },
    ] : [
        { key: "A", label: "1Y", value: "365D", show: chartData["365D"] !== undefined ? JSON.parse(chartData?.["365D"]?.data).length > 0 : false },
        { key: "b", label: "3Y", value: "1095D", show: chartData["1095D"] !== undefined ? JSON.parse(chartData?.["1095D"]?.data).length !== JSON.parse(chartData?.["365D"]?.data).length : false },
        { key: "c", label: "5Y", value: "1825D", show: chartData["1825D"] !== undefined ? JSON.parse(chartData?.["1825D"]?.data).length !== JSON.parse(chartData?.["1095D"]?.data).length : false },
        { key: "d", label: "10Y", value: "3650D", show: chartData["3650D"] !== undefined ? JSON.parse(chartData?.["3650D"]?.data).length !== JSON.parse(chartData?.["1825D"]?.data).length : false },
        { key: "dm", label: "Max", value: "max", show: chartData["max"] !== undefined ? JSON.parse(chartData?.["max"]?.data).length !== JSON.parse(chartData?.["3650D"]?.data).length : false },
    ] : []

    const chipset = [
        { "key": "a", label: "₹ 5,000", value: 5000 },
        { "key": "b", label: "₹ 10,000", value: 10000 },
        { "key": "c", label: "₹ 50,000", value: 50000 },
    ]
    const [noChart, setnoChart] = React.useState(false);
    const [chartType, setChartType] = React.useState(portfolioType === 1 ? defYrSelect || yrtabs[4]?.value : defYrSelect || yrtabs[0]?.value)
    const [chartAmount, setChartAmount] = React.useState(defAmtSelect || chipset[1].value)

    var a = JSON.parse(chartData?.[chartType]?.data || null) !== null && JSON.parse(chartData?.[chartType]?.data || null) !== undefined ? showOnlySelectedKey !== "" ? Object.keys(JSON.parse(chartData?.[chartType]?.data || null)[0] || {})?.filter((i) => !i.includes("ret_") && i.toLowerCase() !== xAxisKey.toLowerCase()).filter((i) => String(i) === String(showOnlySelectedKey)) : Object.keys(JSON.parse(chartData?.[chartType]?.data || null)[0] || {})?.filter((i) => !i.includes("ret_") && i.toLowerCase() !== xAxisKey.toLowerCase()) : ["Fund", "Benchmark"]

    try {
        var options = {
            chart: {
                backgroundColor: "transparent",
                height: 250,
            },
            title: {
                text: ''
            },
            exporting: {
                enabled: false
            },
            yAxis: {
                visible: false,
                tickAmount: 5,
                gridLineDashStyle: 'longdash',
                title: {
                    text: ''
                },
                gridLineColor: 'var(--border)',
            },
            xAxis: [{
                type: 'datetime',
                dateTimeLabelFormats: {
                    millisecond: '%H:%M:%S.%L',
                    second: '%H:%M:%S',
                    minute: '%H:%M',
                    hour: '%H:%M',
                    day: '%eth %b',
                    week: '%eth %b',
                    month: '%b \'%y',
                    year: '%Y'
                },
                lineColor: 'var(--border)',
                tickColor: 'var(--border)',
                plotLines: portfolioType === 1 && chartType.name !== "Live" ? [{
                    color: '#000', // Red
                    value: goLiveDate, // Position, you'll have to translate this to the values on your x axis
                    dashStyle: 'dash',
                    width: 1,
                    label: {
                        text: "Inception", rotation: 0, x: 5,
                        style: {
                            color: 'var(--secondary)',
                            fontWeight: 'bold'
                        }
                    }
                }] : []
            }
            ],
            legend: {
                enabled: false
            },
            plotOptions: {
                areaspline: {
                    threshold: null
                },
                series: {
                    label: {
                        connectorAllowed: false
                    },
                    marker: {
                        enabled: false
                    }
                }
            },
            tooltip: {
                shared: true,
                valueDecimals: 0,
                valuePrefix: '₹',
            },
            credits: {
                enabled: false
            },
            boost: {
                useGPUTranslations: true,
            },
            series: a.map((item, index) => {
                return {
                    name: item === "Benchmark" ? benchName || item : fundName || item,
                    turboThreshold: 9e9,
                    color: fundColors[index],
                    tooltip: {
                        valueSuffix: ' ({point.ret_' + item + '}%)',
                    },
                    type: isStyleBox ? "spline" : "areaspline",
                    fillColor: {
                        linearGradient: {
                            x1: 0,
                            x2: 0,
                            y1: 0,
                            y2: 1
                        },
                        stops: [
                            [0, fundColors[index] + "77"],
                            [0.7, fundColors[index] + "01"]
                        ]
                    },
                    data: changeObjKey(chartData?.[chartType], [String(item), xAxisKey], ["y", "x"], "mfetfChart", (chartAmount / 10000))
                }
            }),
            scrollbar: {
                enabled: false
            },
        };
    } catch (error) {
        setnoChart(true)
    }


    const ContentBeforeChart = () => {
        try {
            if (chartType) {
                var mintData = JSON.parse(chartData?.[chartType]?.data)
            }
            return <div style={{ gap: '1rem', flexWrap: 'wrap', alignItems: "center" }}>

                {/* {portfolioType === -1 ? */}
                <div style={{ flex: 1, display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <div className='fontH2' style={{ flex: 1, color: 'var(--black)', display: "flex", alignItems: "center", gap: "0.3rem" }}>
                        <span>Value of <span className='fontH2 w-500' >{CurrencyFormat(chartAmount, 'long', 0)}</span> if invested on <span className='fontH2 w-500' >{formatDate(mintData[0][xAxisKey])}</span></span>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'right', borderTop: "1px solid var(--grey3)", borderBottom: "1px solid var(--grey3)", padding: "0.4rem" }} >
                        <CustomSegmentedTab
                            isBlock={false}
                            className='performanceChartYrSegmented'
                            style={{ backgroundColor: "transparent", padding: 0 }}
                            options={yrtabs}
                            value={chartType}
                            onChange={(e) => setChartType(e)}
                        />
                    </div>
                </div>
                {/* : <></>} */}

                <div className='my-3' style={{ display: 'flex', flexWrap: 'wrap', gap: "0.5rem" }}>
                    {
                        a.map((item, index) => {
                            var val = (mintData[mintData?.length - 1][item] * (chartAmount / 10000)).toFixed(2)
                            var valXIRR = (mintData[mintData?.length - 1]["ret_" + item])?.toFixed(2)
                            return val && val !== 'NaN' ?
                                <div className='my-2 mx-3' key={index} style={{
                                    display: 'flex', gap: '0.5rem', flexDirection: "column", minWidth: '120px'
                                }}>


                                    {isStyleBox ? <span><span className='textSM' style={{ color: 'var(--black)', display: "flex", alignItems: "center", gap: "0.5rem" }}>
                                        <StyleBoxIcons style_box_code={index} />{item === "Benchmark" ? benchName || item : fundName || item}
                                    </span>
                                        <div className='textSM' style={{ flex: 1, marginLeft: "1.8rem" }}>
                                            {CurrencyFormat(val, 'long', 0)}<span className='textSM'>{RedGreenText({ text: ` (${valXIRR}%)`, value: valXIRR })}</span>
                                        </div>
                                    </span> :
                                        <span>
                                            <span className='textSM' style={{ color: 'var(--black)', display: "flex", alignItems: "center", gap: "0.38rem" }}>
                                                <BsFillCircleFill color={fundColors[index]} size={10} /> {item === "Benchmark" ? benchName || item : fundName || item}
                                            </span> <div className='textSM  ms-3' style={{ flex: 1 }}>
                                                {CurrencyFormat(val, 'long', 0)}<span className='textSM'>{RedGreenText({ text: ` (${valXIRR}%)`, value: valXIRR })}</span>
                                            </div>
                                        </span>}


                                </div > : <></>
                        })
                    }
                </div>



                {/* {
                    portfolioType !== -1 ?
                        <div style={{ flex: 1 }}>
                            <div style={{ display: 'flex', justifyContent: 'right' }} className="mt-2 mt-lg-0">
                                <Segmented size='small' options={chipset} value={chartAmount} onChange={setChartAmount} />
                            </div>
                            {yrtabs.filter((i) => i.show !== false).length > 0 ?
                                <div style={{ display: 'flex', justifyContent: 'right' }} className="mt-2">
                                    <Segmented size='small' options={yrtabs.filter((i) => i.show !== false)} value={chartType} onChange={setChartType} />
                                </div> : <></>}
                        </div> : <></>
                } */}
            </div>
        } catch (error) {
            console.log(error)
            return <></>
        }
    };



    const ContentAfterChart = () => {
        return <div>
            {
                portfolioType === 1 || portfolioType === 2 ?
                    <>
                        <div className='textXS' style={{ paddingTop: "1rem" }}>
                            <b>Notes and Disclaimer:</b>
                            <ol style={{ marginBottom: 0, paddingLeft: '16px' }}>
                                <li>Past performance is not an assurance of future performance.</li>
                                <li>Back-tested calculations do take into account an estimate of potential transaction cost incurred. Calculations do not take into account taxation.</li>
                                <li>Live performance is based on actual portfolio performance since it went live.</li>
                            </ol>
                        </div>
                    </>
                    :
                    <>
                        <div className='textXS' style={{ paddingTop: "1rem", whiteSpace: 'pre-wrap' }}>
                            <b>Note: </b>Past performance is not an assurance of future performance. The chart has been shown based on EOD {type.value === 4 ? "prices" : "NAVs"} of the {type.dname}.
                        </div>
                    </>
            }
        </div>
    }


    try {
        return (
            noChart ? <div className='textXS' style={{ margin: "4rem", textAlign: "center" }}>
                No chart data available!
            </div>
                : <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', gap: '1rem', }}>
                    <ContentBeforeChart />
                    <HighchartsReact highcharts={Highcharts} options={options} />
                    <ContentAfterChart />
                </div>
        );
    } catch (error) {
        console.log(error)
        return <div className='textSM' style={{ margin: "4%", textAlign: "center" }}>
            No chart data available!
        </div>
    }
};
export default PerformanceChart2;