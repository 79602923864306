import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import { combineReducers, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';

import { stockpricesSlice } from './StockLivePrice/reduxSlice';
import { prevstockpricesSlice } from './StocksPrevPrices/reduxSlice';
import { loginModal, userDataSlice } from './UserAuth/reduxSlice';
import storage from 'redux-persist/lib/storage';
import { darkModeSlice } from './DarkMode/reduxSlice';

const rootReducer = combineReducers({
  stockprices: stockpricesSlice.reducer,
  prevStockPrices: prevstockpricesSlice.reducer,
  updateUserData: userDataSlice.reducer,
  toggleloginModal: loginModal.reducer,
  changeTheme: darkModeSlice.reducer
})

const persistConfig = {
  key: 'root',
  version: 1,
  storage: storage,
  blacklist: [], //blacklisting a store attribute name, will not persist that store attribute.
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// const store = configureStore({
//   reducer: persistedReducer,
// });
const store = configureStore({
  reducer: persistedReducer,
  // middleware option needs to be provided for avoiding the error. ref: https://redux-toolkit.js.org/usage/usage-guide#use-with-redux-persist
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
});

export const persistor = persistStore(store);

export default store