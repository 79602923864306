let lastItem = {};
export const ArrayScrollLoader = (arr, qty, bool = false) => new Promise((resolve, reject) => {
  let newArr;
  if (bool) {
    lastItem = {}
  }
  const lastItemIndex = arr.indexOf(lastItem);
  if (lastItemIndex === arr.length - 1) {

    return resolve('done')

  };


  const newIndex = arr.indexOf(lastItem);

  newArr = arr.slice(newIndex + 1, qty + newIndex);

  lastItem = newArr[newArr.length - 1];


  setTimeout(() => {
    resolve(newArr);
  }, 200);
});
