import React from 'react';
import { BsArrowRight } from 'react-icons/bs';
import AMCIcon from './AMCIcon';
import { CurrencyFormat } from './CurrencyFormat';
import MintboxIcon from './MintboxIcon';
import RedGreenText from './RedGreenText';
import { Progress, Space } from 'antd';
import { formatDate } from './DateFormatter';


const PortfolioGoalCard = ({ fundData, onClick = () => { }, isSelected = false }) => {

    return <div onClick={onClick} style={{ padding: "10px", backgroundColor: !isSelected ? "#fff" : "#fafafa", border: isSelected ? "0.5px solid #e5e5e5" : "0px", cursor: "pointer" }}>
        <div style={{ display: "flex", justifyContent: "space-between", flex: 1, gap: "5px" }}>
            <div style={{ flex: 1 }} >
                <div style={{ display: "flex", }}>
                    <div className='mt-1'>
                        <div style={{ alignItems: "center" }}>
                            {/* <GoalIcon fundData={fundData} /> */}
                        </div>
                    </div>
                    <div className='fontNormalDark14' style={{ marginLeft: "16px" }}>
                        {fundData.GoalName}

                    </div>
                </div>
            </div>
            <div style={{ textAlign: "right" }} >
                <h5 style={{ display: "flex", justifyContent: "space-between", color: "#333", fontWeight: 500, marginBottom: "8px" }}>
                    {CurrencyFormat(fundData.MintboxBalance)}
                    {" "} <BsArrowRight />
                </h5>
            </div>
        </div>
        <div style={{ margin: "0px 16px" }}>
            <Progress
                percent={((fundData.MintboxBalance / fundData.GoalTargetCorpus) * 100).toFixed(2)}
                style={{ height: 30, }}
                strokeColor={{
                    '0%': '#108ee9',
                    '100%': '#87d068',
                }}
            />
        </div>
        <div style={{ display: "flex", justifyContent: "space-between", flex: 1, gap: "5px", margin:"0px 0px 0px 16px" }}>
            <div className='fontH2 w-normal dark3' style={{ flex: 1 }} >
                Due {formatDate(fundData.GoalDueDate, "date")}
            </div>
            <div style={{ textAlign: "right" }} >
                <h5 className='fontH2 w-normal dark3' style={{ display: "flex", justifyContent: "space-between"}}>
                    Target {CurrencyFormat(fundData.GoalTargetCorpus, "short")}
                </h5>
            </div>
        </div>
    </div >
};

export default PortfolioGoalCard;
