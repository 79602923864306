import { Avatar, Card, Select, Tag } from 'antd';
import ActivityLoaderMintbox from 'Components/ActivityLoaderMintbox';
import { fetchFundData } from 'Libs/fetchFundData';
import React from "react";
import { IoIosAddCircleOutline, IoIosSearch } from 'react-icons/io';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import apiHelper from 'Services/api/ApiHelper';
import { StockLivePrice } from 'Services/api/LivePricesWebSocket';
import AMCIcon from './AMCIcon';
import { CardHeader } from './atoms/SmallComponents';
import { CurrencyFormat } from './CurrencyFormat';
import CustomSegmentedTab from './CustomSegmentedTab';
import RedGreenText from './RedGreenText';

const RightTopContainerDashboard = ({ props, type }) => {
  const [loading, setLoading] = React.useState(true);
  const [allFunds, setAllFunds] = React.useState([]);
  const [watchData, setwatchData] = React.useState([])
  const [screeners, setscreeners] = React.useState([])
  const [signals, setsignals] = React.useState([])
  const [strategies, setstrategies] = React.useState([])
  var user = useSelector((state) => state?.updateUserData?.value)
  // console.log(user)
  const navigate = useNavigate()

  const items = [{
    label: 'Watchlist',
    value: 'Watchlist',
    key: "id1",
    children: (
      <div style={{}}>
        {watchData?.map((f, i) => {
          return <div key={i} className="borderAll" style={{ backgroundColor: i % 2 !== 0 ? 'var(--white)' : 'var(--grey4)' }}>
            <div className="pad20Side6VerContainer" style={{ display: "flex", gap: "0.5rem", alignItems: "center" }} onClick={() => navigate(type.basePath + `${type.value === 4 ? '/stock-detail/' + f.symbol : '/fund-detail/' + f?.basic_name?.replace(" - Direct Plan", "")?.replaceAll(' ', '-') + '?code=' + f.plan_id}`)}>
              {/* <AMCIcon amcName={type.value === 4 ? f.symbol : f.amc_full_name} fundType={type.value} height='1.5rem' width="1.5rem" /> */}
              <div className="textXS black hoverBold" style={{ flex: 2, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }} >
                {type.value === 4 ? f?.proper_name : f?.basic_name?.replace(" - Direct Plan", "")}
              </div>
              <span className="textXS w-500" >{type.value !== 2 ?
                <StockLivePrice format={3} symbol={f?.symbol || f?.ticker || f?.nse_symbol} /> :
                <RedGreenText text={CurrencyFormat(f?.latest_nav)} value={f?.latest_nav} />}</span>
            </div>
          </div>
        })}
      </div>
    ),
  }, {
    label: 'Screens',
    value: 'Screens',
    key: "id2",
    children: (
      <span style={{}}>
        {screeners?.map((item, i) => {
          return <div key={i} className="borderAll" style={{ backgroundColor: i % 2 !== 0 ? 'var(--white)' : 'var(--grey4)' }}>
            <div className="pad20Side6VerContainer" style={{ display: "flex", gap: "0.5rem", alignItems: "center" }} onClick={() => {
              navigate(type.basePath + "/screener", { state: { screener_final_query: item?.rules } })
            }}>
              <Avatar src="https://joesch.moe/api/v1/random?key=1" />
              <div className="textXS black hoverBold" style={{ flex: 2, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }} >
                {item?.checklist_name}
              </div>
              {/* <span className="textXS w-500" >{type.value !== 2 && dropDown === 0 ?
                <StockLivePrice format={3} symbol={f?.symbol || f?.ticker} /> :
                <RedGreenText text={DecimalValueFormat({ num: f[year.key], suffix: ' %' })} value={f[year.key]} />}</span> */}
            </div>
          </div>
        })}
      </span>
    ),
  }, {
    label: 'Strategies',
    value: 'Strategies',
    key: "id3",
    children: (
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexDirection: "column" }}>

      </div>
    ),
  }, type.value === 4 && {
    label: 'Signals',
    value: 'Signals',
    key: "id34",
    children: (
      <span style={{}}>
        {signals?.map((item, i) => {
          return <div key={i} className="borderAll" style={{ backgroundColor: i % 2 !== 0 ? 'var(--white)' : 'var(--grey4)' }}>
            <div className="pad20Side6VerContainer" style={{ display: "flex", gap: "0.5rem", alignItems: "center" }} onClick={() => {
              navigate(type.basePath + "/signal", { state: { signalData: item } })
            }}>
              <Avatar src="https://joesch.moe/api/v1/random?key=1" />
              <div className="textXS black hoverBold" style={{ flex: 2, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }} >
                {item?.signal_name}
              </div>
              {/* <span className="textXS w-500" >{type.value !== 2 && dropDown === 0 ?
                <StockLivePrice format={3} symbol={f?.symbol || f?.ticker} /> :
                <RedGreenText text={DecimalValueFormat({ num: f[year.key], suffix: ' %' })} value={f[year.key]} />}</span> */}
            </div>
          </div>
        })}
      </span>
    ),
  }];

  const [selectedTab, setselectedTab] = React.useState(items.filter((i) => i !== false)[0]?.value || '');

  const getData = async (id) => {
    let watchlist = await apiHelper({ apiName: "getWatchList", saveResponse: "sessionStorage", data: { id: id } });
    let screeners = await apiHelper({ apiName: "getUserScreeners", saveResponse: "sessionStorage", data: { id: id, type: type.omkar } });
    // let strategies = await apiHelper({ apiName: "getUserStrategies", saveResponse: "sessionStorage", data: { id :id } });
    let signals = await apiHelper({ apiName: "getUserSignal", saveResponse: "sessionStorage", data: { id: id } });


    if (watchlist?.length > 0) {
      let a = await fetchFundData(type.value, false)

      watchlist = watchlist.map((fund) => {
        return {
          ...fund,
          ...a.filter((asset) => String(asset[type.value === 3 ? "UID" : "isin_code"]) === String(fund["FundId"]))[0]
        }
      })
      setwatchData(watchlist?.filter((i) => i?.FundType === type?.value))
    }

    if (screeners?.length > 0) {
      var n = [...new Set([...screeners].map((item) => {
        return {
          "checklist_id": item.checklist_id,
          "client_id": item.client_id,
          "show_as_screen": item.show_as_screen,
          "show_as_checklist": item.show_as_checklist,
          "checklist_name": item.checklist_name,
          "checklist_desc": item.checklist_desc,
          "is_deleted": item.is_deleted,
          "created_on": item.created_on,
        }
      }))]
      n = Array.from(new Set(n.map(JSON.stringify))).map(JSON.parse)

      n = n.map((screen) => {
        return {
          ...screen,
          rules: screeners?.filter((i) => i?.checklist_id === screen?.checklist_id)
        }
      })
      // console.log(n)
      setscreeners(n)
    }



    // setscreeners(screeners)
    // setstrategies(strategies)
    setsignals(signals)

    // console.log(watchlist)
    // console.log(screeners)
    // console.log(strategies)
    // console.log(signals)
    setLoading(false)
  }


  React.useEffect(() => {
    const dum = async () => {
      setLoading(true)

      if (user !== undefined && user !== null) {
        console.log(user.id)
        getData(user.id)
      }

      setLoading(false)
    }
    dum();
  }, [type])


  return <Card bordered={false} style={{ padding: 0 }} bodyStyle={{ padding: 0, paddingBottom: '0.5rem' }}>
    <CustomSegmentedTab style={{ margin: "0.5rem" }} value={selectedTab} onChange={(e) => {
      setselectedTab(e)
    }} options={items.filter((i) => i !== false)} />
    <div className='pad20Container'>
      <span style={{ display: "flex", alignItems: "center" }}>
        <span><CardHeader heading={selectedTab} /></span>
        <span>
          <Tag className='mx-3 pt-1 textSM w-500' style={{ color: "var(--black)" }} color="var(--blueShade)">
            {selectedTab === "Watchlist" ? (watchData?.length || 0) : selectedTab === "Screens" ? (screeners?.length || 0) : selectedTab === "Strategies" ? (strategies?.length || 0) : (signals?.length || 0)}</Tag>
        </span>
      </span>
    </div>

    <div style={{ minHeight: "18.75rem", maxHeight: "18.75rem", overflowY: 'scroll' }}>
      {loading ? <ActivityLoaderMintbox /> : items.filter((i) => i.value === selectedTab)[0].children}
    </div>
    {/* {selectedTab !== 'Watchlist' ? <div className='pad20Container'>
      <button style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center", gap: "0.5rem" }} className='textSM btnSecondary'>
        <IoIosAddCircleOutline size={18} />Create New {String(selectedTab).toLocaleLowerCase()}
      </button>
    </div> : <></>} */}
    {/* <TopGainersAndLosers type={4} format='top' /> */}
  </Card >

};

export default RightTopContainerDashboard;
