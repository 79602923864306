import { DataServicePY } from "./DataServicePY";

const MINTBOX_CLIENT_ID = "Li2L9VO1eawEbsgLrHdpZjhmUdW6N8Cm";

export default {

	get_goal_scenarios: async (data) => {
		return DataServicePY.post('/getGoalScenariosv1', data);
	},

	getGoalCorpus: async (data) => {
		return DataServicePY.post('/getGoalTargetCorpusv1', data);
	},

	getGoalSIP: async (data) => {
		return DataServicePY.post('/getGoalSIPv1', data);
	},

	getGoalDetails: async (data) => {
		return DataServicePY.post('/getGoalDetailsv1', data);
	},

	getGoalReturn: async (data) => {
		return DataServicePY.post('/getGoalReturnsv1', data);
	},

	getETFTrailingReturns: async (id) => {
		return DataServicePY.get(
			`/core/getETFTrailingReturns/${id}`
		);
	},

	getETFTrackingError: async (id) => {
		return DataServicePY.get(
			`/core/getETFTrackingError/${id}`
		);
	},

	getFundsDetails: async ({ id }) => {
		return DataServicePY.get(
			"/core/getFundOverViewDetails/plan_id=" + id
		);
	},

	getCuratedPortfolioDetails: async (data) => {
		return DataServicePY.post(
			"/core/getOrCreateMintbox", data
		);
	},

	getMintboxOverview: async (data) => {
		return DataServicePY.post(
			"/core/getMintboxOverview", data
		);
	},


	getFundsHolding: async ({ id }) => {
		return DataServicePY.get(
			"/core/getHoldingDetails/plan_id=" + id
		);
	},

	getFundsRet: async ({ id }) => {
		return DataServicePY.get(
			"/core/getperfromancedetail/plan_id=" + id
		);
	},

	getEtfs: async () => {
		return DataServicePY.get(
			"/core/getAllFundsV1/instrumentType=1"
		);
	},

	getMFs: async () => {
		return DataServicePY.get(
			"/core/getAllFundsV1/instrumentType=0"
		);
	},
	getStocks: async () => {
		return DataServicePY.get(
			"/core/getAllFundsV1/instrumentType=2"
		);
	},
	getCompData: async (data) => {
		return DataServicePY.post(
			`/core/getFundsComparisonDataByISIN`, data
		);
	},
	getFundsComparisionData: async ({ type, data }) => {
		return DataServicePY.post(
			`/core/getFundsComparisonData/type=${type}`, data
		);
	},
	getStockPerformanceDetail: async ({ symbol }) => {
		return DataServicePY.get(
			`/core/getstockperfromancedetail/symbol=` + symbol
		);
	},
	getHistoricalCharts: async ({ symbol }) => {
		return DataServicePY.get(
			`/core/get_hisotorical_charts/symbol=` + symbol
		);
	},
	getFinancialStatements: async ({ symbol, fsym_id, industry_code }) => {
		return DataServicePY.get(
			`/core/get_financial_statements/symbol=` + symbol + "/fsym_id=" + fsym_id + "/industry_code=" + industry_code
		);
	},
	getAllCheckLists: async ({ symbol }) => {
		return DataServicePY.get(
			`/core/get_all_check_lists/symbol=` + symbol
		);
	},

	getMetricDescription: async (type) => {
		return DataServicePY.get(
			`/core/getMetricDescription/instrument_type=` + type
		);
	},

	getScreenerBacktestData: async (data) => {
		return DataServicePY.post(
			`/core/backtestScreener`, data
		);
	},

	getBacktestSignal: async (data) => {
		return DataServicePY.post(
			`/core/backtestSignal`, data
		);
	},

	getScreenerMetricBacktestData: async ({ metric_code, universe_code }) => {
		return DataServicePY.post(
			`/core/backtestScreenerMetric/metric_code=${metric_code}/universe_code=${universe_code}`
		);
	},

	getStockStyles: async () => {
		return DataServicePY.get(
			`/core/getStockStyles`
		);
	},

	getSharpelyStyleBoxBacktest: async () => {
		return DataServicePY.get(
			`/core/getSharpelyStyleBoxBacktest`
		);
	},

	getMFInsights: async ({ planId, assetType }) => {
		return DataServicePY.get(
			`/core/mf_insights/plan_id=${planId}/asset_type=${assetType}`
		);
	},

	getStockInsights: async ({ ticker }) => {
		return DataServicePY.get(
			`/core/stock_insights/ticker=${ticker}`
		);
	},

	getSignalList: async (data) => {
		return DataServicePY.post(
			`/core/getSignalList`, data
		);
	},


	getSectorsClassificationData: async (data) => {
		return DataServicePY.get(
			`/core/getSectorsClassification/weight_method=` + data
		);
	},


	getPortfolioWeights: async (data) => {
		return DataServicePY.post(
			`/core/getPortfolioWeights`, data
		);
	},


	getInstrumentCharts: async (data) => {
		return DataServicePY.post(
			`/core/getInstrumentAllCharts`, data
		);
	},


	getMFAlphaAnalysis: async ({ category_id, benchmark_id, start_date, end_date, instrument_type }) => {
		return DataServicePY.get(
			`/core/getMFAlphaAnalysis/category_id=${category_id}/benchmark_id=${benchmark_id}/start_date=${start_date}/end_date=${end_date}/instrument_type=${instrument_type}`
		);
	},
};
