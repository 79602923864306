import { Card, Divider, Row } from 'antd';
import ActivityLoaderMintbox from 'Components/ActivityLoaderMintbox';
import { CardHeader } from 'Components/atoms/SmallComponents';
import InstrumentListTable from 'Components/InstrumentListTable';
import PerformanceChart2 from 'Components/PerformanceChart2';
import StyleBoxIcons from 'Components/StyleBoxIcons';
import Head from 'Layout/Head';
import { screenerMetricData, useGetAssetType } from 'Libs/utils';
import React from "react";
import { useNavigate } from 'react-router';
import apiHelperPY from 'Services/api/ApiHelperPY';

const StockStyleBoxes = (props) => {
    const navigate = useNavigate()
    const type = useGetAssetType()
    const fundColors = ['#fabc05', '#41BF4E', '#58985F', '#fa7b16', '#00CFFD', '#333', '#5A2C42', '#B82447', '#FF003D', '#533E37']
    const [loading, setLoading] = React.useState(true);
    const [styleBoxes, setStyleBoxes] = React.useState([]);
    const [styleBoxesBackTest, setstyleBoxesBackTest] = React.useState([]);
    const [selectedStyleBox, setselectedStyleBox] = React.useState(1);
    const [allFilteredFundsData, setallFilteredFundsData] = React.useState([])
    const [allFundsData, setallFundsData] = React.useState([])

    const loadData = async () => {
        var screener_datas = await screenerMetricData(type)
        setStyleBoxes(screener_datas?.fabricatedData?.stockStyles)
        setallFundsData(screener_datas?.allFunds)
        setallFilteredFundsData(screener_datas?.allFunds.filter((i) => Number(i.style_box_code) === Number(selectedStyleBox)))
    }

    React.useEffect(() => {
        setallFilteredFundsData(allFundsData.filter((i) => Number(i.style_box_code) === Number(selectedStyleBox)))
    }, [selectedStyleBox])

    React.useEffect(() => {
        const dum = async () => {
            setLoading(true)
            loadData()
            let res2 = await apiHelperPY({ apiName: 'getSharpelyStyleBoxBacktest' })
            res2 = await JSON.parse(res2.replace(/NaN/g, null))
            console.log(res2)
            setstyleBoxesBackTest(res2)
            setLoading(false)
        }
        dum();
    }, [])

    if (loading) {
        return <React.Fragment>
            <div className='mainContainer' style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
                <ActivityLoaderMintbox />
            </div>
        </React.Fragment>
    } else {
        return (
            <React.Fragment>
                <Head title="Styleboxes" />

                <div className='normalContainer'>
                    <Card bordered={false} className='mb-4' style={{ gap: "1rem", width: "100%", flex: 1 }}>
                        <CardHeader heading={'Sharpely StyleBoxes backtest results'} />
                        <Divider />
                        <PerformanceChart2 isStyleBox={true} fundColors={fundColors} portfolioType={-1} defAmtSelect={100000} chartData={styleBoxesBackTest?.prices} />
                    </Card>

                    <Row style={{ gap: "1rem", height: 'calc(100vh - 64px - 4rem)' }}>
                        {/* <Card bordered={false} style={{ gap: "1rem", height: 'calc(100vh - 64px - 4rem)', overflowY: "auto", maxWidth: "25%" }}>
                            <div className='fontH1 w-500 mb-3'>Sharpely StyleBoxes</div>
                            {styleBoxes?.map((box) => {
                                return <Card bordered={false} onClick={() => {
                                    setselectedStyleBox(box.style_box_code)
                                }} hoverable className='mb-2 p-0 py-1' style={{ border: 0, borderBottom: "0.5px solid #e5e5e5", cursor: "pointer" }} bodyStyle={{ display: "flex", justifyContent: "space-between", gap: "0.6rem", padding: 0 }} key={box.style_box_code}>
                                    <StyleBoxIcons style_box_code={box.style_box_code - 1} />
                                    <div style={{ flex: 1 }}>
                                        <div className='fontH2 w-500'>{box.style_box_name}</div>
                                        <div className='textSM w-400'>{box.style_box_desc}</div>
                                    </div>
                                </Card>
                            })}
                        </Card> */}


                        <div style={{ gap: "1rem", height: 'calc(100vh - 64px - 4rem)', overflowY: "auto", width: "100%", flex: 1 }}>
                            <InstrumentListTable
                                tableType={7}
                            />
                        </div>


                    </Row>

                </div>

            </React.Fragment >
        );
    };
}

export default StockStyleBoxes;
