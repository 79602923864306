import { Progress } from "antd";

const CustomSharpelyScoreProgressBar = ({ val }) => {
    const color = val < 33.33 ? 'var(--red)' : val < 66.66 ? 'var(--primary)' : val <= 100 ? 'var(--green)' : 'transparent'
    if (val) {
        return <Progress
            percent={val}
            type="dashboard"
            width={60}
            strokeWidth={8}
            gapDegree={110}
            format={(f) => <span className="fontH2" style={{ color: color, fontWeight: '500' }}>{Number(f.toFixed(1))}</span>}
            strokeColor={color}
            style={{ marginBottom: '-0.5rem' }} />
    } else {
        return <>-</>
    }

}

export default CustomSharpelyScoreProgressBar;