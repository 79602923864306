import { Card, Select, Table } from 'antd';
import ActivityLoaderMintbox from 'Components/ActivityLoaderMintbox';
import { CardHeader } from 'Components/atoms/SmallComponents';
import CustomSegmentedTab from 'Components/CustomSegmentedTab';
import { DecimalValueFormat } from 'Components/DecimalValueFormat';
import Highcharts from 'highcharts';
import HighchartsHeatmap from "highcharts/modules/heatmap";
import Head from 'Layout/Head';
import { useGetAssetType } from 'Libs/utils';
import React, { useState } from 'react';
import { MdArrowDropDown } from 'react-icons/md';
import { TiDelete } from 'react-icons/ti';
import { useNavigate } from 'react-router';
import apiHelperPY from 'Services/api/ApiHelperPY';
HighchartsHeatmap(Highcharts)

const StockSectors = ({ paginationSize = 0 }) => {

    const navigate = useNavigate()
    const [loading, setloading] = useState(true)
    const type = useGetAssetType()

    // const [stock_sectors, setSectors] = useState([])
    // const [stock_industries, setIndustries] = useState([])
    // const [stock_basic_industries, setBasicIndustries] = useState([])
    // const [stockSectorCLF, setStockSectorCLF] = useState([])
    const tabOptions = [
        {
            "value": "sector",
            "queryStr": "sector",
            "label": "Sectors"
        },
        {
            "value": "indgrp",
            "queryStr": "indgrp",
            "label": "Industry group"
        },
        {
            "value": "ind",
            "queryStr": "ind",
            "label": "Industry"
        },
    ]

    const weightMethodOptions = [{
        "value": 6,
        "label": "Weight stocks by market cap",
        "key": 6,
    }, {
        "value": 2,
        "label": "Equal weight",
        "key": 2,
    }]

    const hidden_col = ["med_pe", "med_pb", "med_div_yield", "num_stocks", "sector_code", "industry_code", "basic_industry_code"]

    const [selectedTabOption, setselectedTabOption] = useState(tabOptions[0].value)
    const [selectedWeightMethod, setselectedWeightMethod] = useState(weightMethodOptions[0].value)
    const [clasificationData, setclasificationData] = useState({})
    const [dataTableColumns, setdataTableColumns] = useState({})

    const loadData = async () => {
        setloading(true)

        // var screener_datas = await screenerMetricData(type, 1)
        // setStockSectorCLF(screener_datas?.fabricatedData?.stockSectorCLF)
        // console.log(screener_datas?.fabricatedData?.stockSectorCLF)

        var sectorsData = await apiHelperPY({ apiName: 'getSectorsClassificationData', saveResponse: "sessionStorage", data: selectedWeightMethod })
        sectorsData = JSON.parse(sectorsData.replace(/NaN/g, null))
        setclasificationData(sectorsData)
        var finalSectorCols = Object.entries(sectorsData.sector[0]).map(([key, val], index) => {
            return {
                "dataIndex": key,
                "title": key === "sector_desc" ? "Sector name" : key,
                'width': key === "sector_desc" ? 220 : 120,
                'fixed': key === "sector_desc" && 'left',
                "align": typeof (val) === "object" || typeof (val) === "number" ? "right" : "left",
                "render": (text, record) => typeof (text) === "object" || typeof (text) === "number" ? DecimalValueFormat({ num: Number(text), suffix: "", placeholder: "-", decimals: 2 }) : key === "sector_desc" ? <div onClick={() => {
                    var data = {
                        "sector": record.sector_code,
                        "indgrp": "",
                        "ind": ""
                    }
                    console.log(sectorsData)
                    navigate(type.basePath + "/sector-details", { state: { selectionData: data, clasificationData: sectorsData } })
                }} style={{ color: "var(--primary)", cursor: "pointer", }}>
                    {text}
                </div> : text
            }
        })
        var finalIndustryGrpCols = Object.entries(sectorsData.indgrp[0]).map(([key, val], index) => {
            return {
                "dataIndex": key,
                "title": key === "industry_desc" ? "Industry group name" : key,
                'width': key === "industry_desc" ? 220 : 120,
                'fixed': key === "industry_desc" && 'left',
                "align": typeof (val) === "object" || typeof (val) === "number" ? "right" : "left",
                "render": (text, record) => typeof (text) === "object" || typeof (text) === "number" ? DecimalValueFormat({ num: Number(text), suffix: "", placeholder: "-", decimals: 2 }) : key === "industry_desc" ? <div onClick={() => {
                    var data = {
                        "sector": String(record.industry_code).slice(0, 6),
                        "indgrp": record.industry_code,
                        "ind": ""
                    }
                    console.log(data)
                    navigate(type.basePath + "/sector-details", { state: { selectionData: data, clasificationData: sectorsData } })
                }} style={{ color: "var(--primary)", cursor: "pointer", }}>
                    {text}
                </div> : text
            }
        })
        var finalIndustryCols = Object.entries(sectorsData.ind[0]).map(([key, val], index) => {
            return {
                "dataIndex": key,
                "title": key === "basic_industry_desc" ? "Industry name" : key,
                'width': key === "basic_industry_desc" ? 220 : 120,
                'fixed': key === "basic_industry_desc" && 'left',
                "align": typeof (val) === "object" || typeof (val) === "number" ? "right" : "left",
                "render": (text, record) => typeof (text) === "object" || typeof (text) === "number" ? DecimalValueFormat({ num: Number(text), suffix: "", placeholder: "-", decimals: 2 }) : key === "basic_industry_desc" ? <div onClick={() => {
                    var data = {
                        "sector": String(record.basic_industry_code).slice(0, 6),
                        "indgrp": String(record.basic_industry_code).slice(0, 8),
                        "ind": record.basic_industry_code
                    }
                    console.log(data)
                    navigate(type.basePath + "/sector-details", { state: { selectionData: data, clasificationData: sectorsData } })
                }} style={{ color: "var(--primary)", cursor: "pointer", }}>
                    {text}
                </div> : text
            }
        })
        setdataTableColumns({
            "sector": finalSectorCols.filter((col) => !hidden_col.includes(col.dataIndex)),
            "indgrp": finalIndustryGrpCols.filter((col) => !hidden_col.includes(col.dataIndex)),
            "ind": finalIndustryCols.filter((col) => !hidden_col.includes(col.dataIndex))
        })
        setloading(false)
    }

    React.useEffect(() => {
        loadData()
    }, [selectedWeightMethod])


    if (loading) {
        return <React.Fragment>
            <div className='mainContainer' style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
                <ActivityLoaderMintbox />
            </div>
        </React.Fragment>
    } else {
        return (
            <React.Fragment>
                <Head title="StockSectors" />
                <Card bordered={false} style={{ flex: 1 }} title={<div style={{ display: "flex", gap: "0.5rem" }}>
                    <CardHeader heading={"Sectors"} />
                    <Select removeIcon={<TiDelete size={12} />}
                        className='textSM black mx-3'
                        suffixIcon={<MdArrowDropDown size={17} />}
                        style={{
                            width: "250px"
                        }}
                        defaultValue={selectedWeightMethod}
                        placeholder="Please select weighing method for stocks"
                        onChange={(value) => {
                            setselectedWeightMethod(value)
                        }}
                        options={weightMethodOptions}
                    />
                </div>}>

                    <CustomSegmentedTab
                        flexVal={'unset'}
                        isBlock={false}
                        className='textXS black m-2'
                        options={tabOptions}
                        onChange={(value) => {
                            setselectedTabOption(value)
                        }}
                        value={selectedTabOption}
                    />

                    <Table

                        pagination={{
                            defaultPageSize: paginationSize,
                            position: ['bottomCenter'],
                            showSizeChanger: false,
                            showPrevNextJumpers: true,
                            className: "textSM",
                            hideOnSinglePage: true,
                            itemRender: (current, type, originalElement) => {
                                if (type === 'prev') {
                                    return <a {...originalElement.props} className="textSM mx-2">Previous</a>;
                                }
                                if (type === 'next') {
                                    return <a {...originalElement.props} className="textSM mx-2">Next</a>;
                                }
                                return originalElement;
                            }

                        }}

                        showSorterTooltip={false}
                        defaultSortOrder='descend'
                        dataSource={clasificationData[selectedTabOption]?.sort((a, b) => a?.[selectedTabOption === 'sector' ? 'sector_desc' : selectedTabOption === 'indgrp' ? 'industry_desc' : 'basic_industry_desc']?.localeCompare(b?.[selectedTabOption === 'sector' ? 'sector_desc' : selectedTabOption === 'indgrp' ? 'industry_desc' : 'basic_industry_desc']))}
                        columns={dataTableColumns[selectedTabOption]}
                        rowKey={(record) => record[selectedTabOption === 'sector' ? 'sector_desc' : selectedTabOption === 'indgrp' ? 'industry_desc' : 'basic_industry_desc']}
                        scroll={{ x: "max-content" }}
                    />
                </Card>
            </React.Fragment >
        );
    }
}

export default StockSectors