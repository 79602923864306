import { Card, Skeleton } from 'antd';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import ActivityLoaderMintbox from './ActivityLoaderMintbox';
import { CurrencyFormat } from './CurrencyFormat';
import RedGreenText from './RedGreenText';

const HomePortfolioCard = ({ data, type = 0, onRefresh, chartLoad = true, chartData = [], chartMin = 0, show }) => {

    console.log(chartData)
    if (show !== 'chart') {
        return (
            <Card bordered={false}  className='col-4' bodyStyle={{ display: "flex", gap: "1rem", padding: "0.5rem 0px" }}>
                {data[0] !== undefined ? <div>
                    <div className="fontH2" >Current value</div>
                    <div className="fontH1 w-700">{CurrencyFormat(data[0])}</div>
                    <div className="textSM mt-2">
                        <span className='mr-2'>1 Day</span>
                        <RedGreenText text={CurrencyFormat(data[2], 'long', 2)} value={data[2]} />
                        <RedGreenText text={"(" + data[1]?.toFixed(2) + "%)"} value={data[1]} />
                    </div>
                </div> : <Skeleton active />}
                <div>
                    <div className="fontH2" >Ann. return</div>
                    <div className="fontH2 w-500">{data[3] !== undefined ? <RedGreenText text={data[3].toFixed(2) + '%'} value={data[3]} /> : <Skeleton active />}</div>
                </div>
            </Card>
        );
    } else {

        const timezone = new Date().getTimezoneOffset()

        const conf = {
            chart: {
                height: 330
            },
            boost: {
                useGPUTranslations: true,
                seriesThreshold: 5
            },

            title: {
                text: ''
            },
            exporting: {
                enabled: false
            },
            responsive: {
                rules: [{
                    condition: {
                        maxHeight: 100
                    }
                }]
            },
            yAxis: {
                visible: false,
                title: {
                    text: ''
                },
                labels: {
                    prefix: '₹'
                },
                min: chartLoad ? 0 : chartMin,
                startOnTick: true,
                gridLineColor: "var(--divider)",

            },

            xAxis: [{
                type: 'datetime',
                dateTimeLabelFormats: {
                    millisecond: '%H:%M:%S.%L',
                    second: '%H:%M:%S',
                    minute: '%H:%M',
                    hour: '%H:%M',
                    day: '%eth %b',
                    week: '%eth %b',
                    month: '%b \'%y',
                    year: '%Y'
                },
                lineColor: "var(--divider)",
                tickColor: "var(--divider)",
                labels: {
                    step: 2
                },
                crosshair: {
                    width: 1,
                    color: 'grey'
                },
            }
            ],
            legend: {
                enabled: false
            },

            tooltip: {
                shared: true,
                valuePrefix: '₹',
            },
            credits: {
                enabled: false
            },
            scrollbar: {
                enabled: false
            },
            series: chartLoad ? [] : chartData,
            plotOptions: {
                series: {
                    label: {
                        connectorAllowed: false
                    },
                    turboThreshold: 51000,
                    marker: {
                        enabled: false
                    },

                    // color: chartData[0].data[0].Balance > chartData[0].data[chartData[0].data.length - 1].Balance ? "#ff000088" : t.colors.primaryDark,
                    fillColor: {
                        linearGradient: [0, 0, 0, 300],
                        // stops: [
                        //     [0, chartData[0].data[0].Balance > chartData[0].data[chartData[0].data.length - 1].Balance ? t.colors.red + "22" : t.colors.primaryDark + "22"],
                        //     [1, '#fff']
                        // ]
                        stops: [
                            [0, "red" + "22"],
                            [1, 'transparent']
                        ]
                    }
                }
            },
            responsive: {
                rules: [{
                    condition: {
                        maxWidth: 400
                    },
                }]
            }
        };

        return (
            <div style={{ paddingHorizontal: 2, flex: 1 }}>
                {chartLoad ? <ActivityLoaderMintbox /> :
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={conf}
                    />
                }
            </div>
        );
    }


};

export default HomePortfolioCard;
