import { Card, Row } from "antd";

const DashboardResearchTools = ({ data }) => {
  if (!data) {
    return <></>
  }
  return <div style={{}}>
    {
      data.sectionHeader ?
        <div className='fontH1 mb-4'>
          {data.sectionHeader}
        </div>
        :
        <></>
    }

    <Row className="wrapper">
      {
        data.children.map((component, i) => {
          return <Card bordered={false} 
            key={component.UID}
            // className="col-4"
            bodyStyle={{
              cursor: "pointer",
              flex: 1,
              paddingTop: '1.2rem',
              paddingBottom: '1.2rem',
            }}
            style={{
              // backgroundColor: 'var(--primaryBg)',
              border: 'none',
              flex: 1,
              height: "100%"
              // backgroundColor: component.bgColor,

            }}
            hoverable
            onClick={() => component.onClick()}
          >
            <div style={{ display: "flex", justifyContent: 'space-between', gap: '1rem' }}>
              <div style={{ display: "flex", flexDirection: "column", gap: '0.375rem' }}>
                <div className='textMD black w-700'>
                  {component.heading}
                </div>
                <div className='textSM dark3'>
                  {component.content}
                </div>
              </div>
              <div style={{ display: "flex", gap: '1rem' }}>
                <img src={component.icon} style={{ width: '5rem', height: '5rem' }} />
              </div>
            </div>
          </Card>
        })
      }
    </Row>
  </div >
}

export default DashboardResearchTools;