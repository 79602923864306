import { Divider, Input } from "antd";
import React from "react";
import { BsInstagram, BsLinkedin, BsTwitter } from "react-icons/bs";
import { Link } from "react-router-dom";
import Logo from "./Logo";
import factset from '../Assets/images/Footer/FactSet_Logo_CMYK_Cyan-1024x199 1.svg';
import nse from '../Assets/images/Footer/image 2.svg'
import valueresearch from '../Assets/images/Footer/valueresearchonline 1.svg';
import { FaFacebookF } from "react-icons/fa";

const Footer = () => {

  const products = [
    {
      label: "ETFs",
      link: "/etfs"
    },
    {
      label: "Mutual Funds",
      link: "/mutual-funds"
    },
    {
      label: "Stocks",
      link: "/stocks"
    }
  ];

  const sharpely = [
    {
      label: "About Us",
      link: "#",
    },
    {
      label: "Pricing",
      link: "#"
    },
    {
      label: "Careers",
      link: "#",
    },
    {
      label: "Help & Support",
      link: "#"
    }
  ];

  const quicklinks = [
    {
      label: "ETF",
      link: "/etfs/dashboard"
    },
    {
      label: "Mutual Funds",
      link: "/mutual-funds/dashboard"
    },
    {
      label: "Stocks",
      link: "/stocks/dashboard"
    },
    {
      label: "My Portfolio",
      link: "/portfolio"
    },
    {
      label: "Blog",
      link: "/blogs"
    },
    {
      label: "Knowledge Base",
      link: "/knowledge-base"
    }
  ]

  const socialmedia = [
    {
      label: "Facebook",
      link: "#",
      Icon: FaFacebookF
    },
    {
      label: "Instagram",
      link: "#",
      Icon: BsInstagram
    },
    {
      label: "Twitter",
      link: "#",
      Icon: BsTwitter
    },
    {
      label: "Linkedin",
      link: "#",
      Icon: BsLinkedin
    },
  ]

  const footerlinks = [
    {
      label: "Privacy Policy",
      link: "#"
    },
    {
      label: "Terms of Service",
      link: "#"
    },
    {
      label: "Cookies Settings",
      link: "#"
    }
  ]
  return (
    <React.Fragment>
      <div className='footerContent'>
        <div className='d-flex align-items-start flex-column' style={{ gap: "1.5rem", flex: 1 }}>
          <Logo type='dark' />
          <div className='textMD black'>
            Join our newsletter to stay up to date on features and releases.
          </div>
          <div className='d-flex align-items-start flex-column' style={{ gap: "1rem" }}>
            <div className='d-flex align-items-center' style={{ gap: "0.75rem", width: "100%", height: "2.5rem" }}>
              <Input placeholder='Enter your email' style={{ height: "100%", background: "var(--grey4)", border: "1px solid var(--grey3)" }}
                className='textSM black' />
              <button className='btnSecondary' style={{ whiteSpace: "nowrap", height: "100%" }}>
                Subscribe Now
              </button>
            </div>
            <div className='textXS black'>
              By subscribing you agree to with our Privacy Policy and provide consent to receive updates from our company.
            </div>
            <div className='d-flex align-items-center' style={{ gap: "0.75rem" }}>
              <img src={factset} style={{ width: "4.5rem", height: "0.75rem" }} />
              <img src={nse} style={{ width: "3rem", height: "1.1rem" }} />
              <img src={valueresearch} style={{ width: "8.4rem", height: "0.75rem" }} />
            </div>
          </div>
        </div>
        <div className='d-flex align-items-start justify-content-between' style={{ gap: "2.5rem", flex: 1.1 }}>
          <div className='d-flex align-items-start' style={{ gap: "2rem" }}>
            <div>
              <div className='textMD w-700'>PRODUCTS</div>
              {products?.map((item, i) => (
                <Link to={item.link} className='dark3 textSM w-500 d-flex' style={{ margin: "0.75rem 0px" }} key={i + 1} >
                  {item.label}
                </Link>
              ))}
            </div>
            <div>
              <div className='textMD w-700'>SHARPELY</div>
              {sharpely?.map((item, i) => (
                <Link to={item.link} className='dark3 textSM w-500 d-flex' style={{ margin: "0.75rem 0px" }} key={i + 1} >
                  {item.label}
                </Link>
              ))}
            </div>
            <div>
              <div className='textMD w-700'>QUICK LINKS</div>
              {quicklinks?.map((item, i) => (
                <Link to={item.link} className='dark3 textSM w-500 d-flex' style={{ margin: "0.75rem 0px" }} key={i + 1} >
                  {item.label}
                </Link>
              ))}
            </div>
          </div>
          <div>
            <div className='textMD black w-600'>Follow Us</div>
            {socialmedia?.map((item, i) => (
              <Link key={i + 1} style={{ margin: "0.8rem 0px", gap: "0.5rem" }} className='d-flex align-items-center dark3 textSM w-400'
                to={item.link}>
                <item.Icon color='var(--black)' />
                <div>{item.label}</div>
              </Link>
            ))}
          </div>
        </div>
      </div>
      <Divider style={{ margin: "0px" }} />
      <div className='footerBottom'>
        <div className='textSM black'>
          Copyright © 2023 Sharpely. All rights reserved.
        </div>
        <div className='d-flex align-items-center' style={{ gap: "1.5rem" }}>
          {footerlinks?.map((item, i) => (
            <Link key={i + 1} to={item.link} className='textSM black'>
              {item.label}
            </Link>
          ))}
        </div>
      </div>
    </React.Fragment>
  );
};
export default Footer;
