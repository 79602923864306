import { Menu } from "antd";
import React, { useEffect, useState } from "react";
import { FcFile, FcFolder } from 'react-icons/fc';
import { useNavigate, useParams } from "react-router-dom";
import { getKnowledgeBaseData } from "./getKnowledgeBaseData";

const ResourcesSidebar = ({ }) => {
    const params = useParams();
    const navigate = useNavigate();
    const [sidebarItems, setSidebarItems] = useState([])

    const loadData = async () => {
        let res = await getKnowledgeBaseData();
        if (res.type) {
            setSidebarItems(res.formattedData || []);
        }
    }

    useEffect(() => {
        loadData();
    }, [params])

    return (
        <React.Fragment>
            <div className='rightContainer'>
                <Menu mode='inline' style={{
                    height: "100%", overflow: "auto",
                    width: "100%", flexShrink: 0, borderRadius: "var(--borderRadius)"
                }}
                    defaultSelectedKeys={[params.blog]} defaultOpenKeys={[params.category, params.subCategory]}
                    className='d-none d-md-block kbResSide textSM'>
                    {sidebarItems?.map(category => (
                        <Menu.SubMenu key={category?.Category?.toLowerCase().split(' ').join('_')} title={category?.Category}>
                            {category?.SubCategories?.map((subCategory) => (
                                <Menu.SubMenu key={subCategory?.SubCategory?.toLowerCase().split(' ').join('_')}
                                    title={<div><FcFolder /> {subCategory?.SubCategory}</div>} style={{ overflow: "wrap" }}>
                                    {subCategory?.Resources?.map(resource => (
                                        <Menu.Item key={resource?.URL.split('/')?.[2]} onClick={() => navigate(resource.Slug)}
                                            style={{ overflow: "wrap" }}>
                                            <FcFile /> {resource?.Title}
                                        </Menu.Item>
                                    ))}
                                </Menu.SubMenu>
                            ))}
                            {
                                category?.Resources?.map((resource) => (
                                    <Menu.Item key={resource?.URL.split('/')?.[2]} onClick={() => navigate(resource.Slug)}
                                        style={{ overflow: "wrap" }}>
                                        <FcFile /> {resource?.Title}
                                    </Menu.Item>
                                ))
                            }
                        </Menu.SubMenu>
                    ))}
                </Menu>
                {/* <div style={{ paddingLeft: "300px" }} className='d-none d-md-block resideaux'>
            </div> */}
            </div>
        </React.Fragment >
    )
}

export default ResourcesSidebar;
