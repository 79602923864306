import { Slider } from "antd"
import React from 'react';

const Slider52Week = ({ high, low, value }) => {

    const difference = Math.abs(high - low);
    let current = Math.abs(value - low);
    let percentage = (current / difference) * 100;

    return (
        <div style={{ width: '100%', marginBottom: '0.5rem' }}>
            <div className="textXS w-500 black text-center" style={{ marginBottom: '0.25rem' }}>52 week</div>
            <div className='week52slider' style={{ '--left': percentage + '%' }}>
                <div className='week52slidertrack'>
                </div>
                <div className='week52sliderpoint'>
                </div>
            </div>
        </div>
    )
}

export default Slider52Week