import { Navigate, useLocation } from "react-router-dom";

export function ProtectedRoutes({ redirectRoute = "/", children }) {
  const isAuthenticated = localStorage.getItem("user");
  let location = useLocation();

  if (!isAuthenticated) {
    return <Navigate to={redirectRoute} state={{ from: location }} replace />;
  } else {
    return children;
  }
}