import { Card } from "antd";
import ActivityLoaderMintbox from "Components/ActivityLoaderMintbox";
import BreadCrumbs from "Components/BreadCrumbs";
import { getKnowledgeBaseData } from "Components/getKnowledgeBaseData";
import Head from "Layout/Head";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";

const SubCategoryPage = () => {
    const params = useParams();
    const [loading, setLoading] = useState(true);
    const [category, setCategory] = useState({});
    const [subCategory, setSubCategory] = useState({});
    const [blogs, setBlogs] = useState([]);
    const navigate = useNavigate();

    const loadData = async () => {
        let res = await getKnowledgeBaseData();
        if (res.type) {
            setCategory(res.categories?.filter(item => item?.Category?.toLowerCase().split(' ').join('_') === params.category)?.[0] || {});
            setSubCategory(res.subCategories?.filter(item => item?.Category?.toLowerCase().split(' ').join('_') === params.category &&
                item?.SubCategory.toLowerCase().split(' ').join('_') === params.subCategory)?.[0] || {});
            setBlogs(res.blogs?.filter(item => item?.Category?.toLowerCase().split(' ').join('_') === params.category &&
                item?.SubCategory.toLowerCase().split(' ').join('_') === params.subCategory) || [])
        }
        setLoading(false);
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        loadData();
    }, [params]);

    if (loading) {
        return (
            <div className='flex-grow-1 mt-5'>
                <ActivityLoaderMintbox />
            </div>
        )
    }
    return (
        <React.Fragment>
            <Head title={subCategory.SubCategory || "Loading"} />
            <div className='kbMidContainer'>
                <h2>
                    {subCategory.SubCategory}
                </h2>
                <div className='textMD margin20Bottom'>
                    {subCategory.Description}
                </div>
                <Row lg={3} sm={2} xs={1} >
                    {blogs.map((item) => (
                        <Col key={item.UID} className='margin20Bottom'>
                            <Card style={{ height: "100%" }} hoverable={true}
                                onClick={() => navigate(`${item.Slug}`)} bordered={false}>
                                <div className='d-flex align-items-center flex-column' style={{ gap: "10px", padding: "1.6rem 0px" }}>
                                    <div style={{ background: "var(--dark1)", padding: "2rem", borderRadius: "var(--borderRadius)" }}>
                                    </div>
                                    <div className="fontH1" style={{ textTransform: "capitalize" }}>
                                        <Link to={`${item.Slug}`} className='cardHeader textLG'>
                                            {item.Title}
                                        </Link>
                                    </div>
                                </div>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </div>
        </React.Fragment>
    )
}

export default SubCategoryPage;