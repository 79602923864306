import { Badge, Divider, Select } from "antd";
import { getUser, useGetAssetType } from "Libs/utils";
import React from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Logo from "./Logo";
import User from "./User";
const Header = ({ isDarkMode, toggleDarkMode }) => {
  const type = useGetAssetType()
  const loc = useLocation()
  // const [user, setUser] = React.useState(undefined)
  const navigate = useNavigate();
  var user = useSelector((state) => state?.updateUserData?.value)
  console.log(user)
  // const loadUserData = async () => {
  //   const user = await getUser(false)
  //   setUser(user)
  //   // console.log(user)
  // }
  // React.useEffect(() => {
  //   loadUserData()
  // }, [loc])

  const items = [
    {
      key: '1',
      label: <Link key={1} style={{ borderBottom: type.value === 1 ? "1px solid" : "0px" }} to="/etfs/dashboard"><span className={type.value === 1 ? "menu-item textSM w-500 menu-item-selected" : "menu-item textSM w-500"}>ETFs</span></Link>,
    },
    {
      key: '2',
      label: <Link key={2} style={{ borderBottom: type.value === 2 ? "1px solid" : "0px" }} to="/mutual-funds/dashboard"><span className={type.value === 2 ? "menu-item textSM w-500 menu-item-selected" : "menu-item textSM w-500"}>Mutual funds</span></Link>,
    },
    {
      key: '4',
      label: <Link key={4} style={{ borderBottom: type.value === 4 ? "1px solid" : "0px" }} to="/stocks/dashboard"><span className={type.value === 4 ? "menu-item textSM w-500 menu-item-selected" : "menu-item textSM w-500"}>Stocks</span></Link>,
    },
    {
      key: '5',
      label: <Link key={5} style={{ borderBottom: loc.pathname === '/portfolio' ? "1px solid" : "0px" }} to="/portfolio"><span className={loc.pathname === '/portfolio' ? "menu-item textSM w-500 menu-item-selected" : "menu-item textSM w-500"}>My Portfolio</span></Link>,
    },
    {
      key: '6',
      label: <Link key={6} style={{ borderBottom: loc.pathname === '/blogs' ? "1px solid" : "0px" }} to="/blogs"><span className={loc.pathname === '/blogs' ? "menu-item textSM w-500 menu-item-selected" : "menu-item textSM w-500"}>Blog</span></Link>,
    },
    {
      key: '7',
      label: <Link key={7} style={{ borderBottom: loc.pathname.startsWith('/knowledge-base') ? "1px solid" : "0px" }} to="/knowledge-base"><span className={loc.pathname.startsWith('/knowledge-base') ? "menu-item textSM w-500 menu-item-selected" : "menu-item textSM w-500"}>Knowledge Base</span></Link>,
    }
  ];

  return (
    <div className='headerContainer' style={{ display: "flex", gap: "1.25rem", alignItems: "center" }}>
      <div style={{ display: "flex", flex: 2, gap: "2.5rem", alignItems: "center" }}>
        <Logo type='dark' />
        <div style={{ display: "flex", gap: "1.5rem", alignItems: "center" }}>
          {
            items.map(m => {
              return m.label
            })
          }
        </div>
      </div>
      <div style={{ display: "flex", flex: 1.5, gap: "1.5rem", alignItems: "center", justifyContent: "flex-end" }}>
        <Select
          showSearch
          style={{ width: "18.75rem" }}
          placeholder={<span className="textSM w-500"><i className="fi fi-rr-search"></i><span className="ms-2">Search stocks, ETFs & mutual funds</span></span>}
          // defaultactivefirstoption={false}
          showArrow={false}
          filterOption={false}
          notFoundContent={null}
        />
        <Badge dot><i className="fi fi-rr-bell"></i></Badge>
        <div style={{ display: "flex", gap: "0.5rem", alignItems: "center", justifyContent: "flex-end" }}>
          <Divider style={{ height: "2rem" }} type="vertical" />
          <User user={user} isDarkMode={isDarkMode} toggleDarkMode={toggleDarkMode} />
        </div>
      </div>
    </div>
  );
};
export default Header;
