import { Card, Input, Modal } from 'antd';
import Logo from 'Layout/Logo';
import db from 'Libs/db';
import { toggleloginModal, updateUserData } from 'Libs/redux/UserAuth/reduxSlice';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import AdminServices from 'Services/api/AdminServices';

export default function LoginSignupModal({ modelOpen = false }) {
    const [email, setEmail] = React.useState('');
    const [userData, setuserData] = React.useState({});
    const [otp, setOTP] = React.useState('');
    const [mainOTP, setmainOTP] = React.useState('');
    const [nextBtnPressed, setNextBtnPressed] = React.useState(false)
    const dispatch = useDispatch();
    const checkOTP = () => {
        if (Number(otp) === Number(mainOTP)) {
            console.log("heyey")
            try {
                db.set(db.model.user, JSON.stringify({ userData }));
                dispatch(updateUserData(userData))
                dispatch(toggleloginModal(false))
            } catch (error) {
                console.log(error)
            }
        } else {
            toast.error("Incorrect OTP");
        }
    }

    const sendOTP = async (id) => {
        console.log("ASDFASF", id)
        try {
            setmainOTP('')
            const res = await AdminServices.login({ id: id }).then(res => res)
            console.log(res)
            if (res.type) {
                setNextBtnPressed(true);
                console.log("hey")
                setmainOTP(res.data.otpcode);
            } else {
                toast.error("Try again ...");
            }
        } catch (error) {
            console.log(error)
            toast.error("Try again ...");
        }
    };


    const onNextBtnPressed = async () => {
        setNextBtnPressed(true);
        try {
            const res = await AdminServices.ClientExists({ type: "email", value: email }).then((res) => res);
            if (res.type) {
                console.log("client exists API ", res.data)
                if (res.data.IFAId !== '') {
                    setuserData({
                        name: res.data.ClientName,
                        email: res.data.Email,
                        mobile: res.data.MobileNumber,
                        id: res.data.ClientId,
                        isLogged: true,
                    });
                    // console.log(userData)
                    sendOTP(res.data.ClientId)
                } else {
                    setNextBtnPressed(false);
                    toast.error("Not a sharpely registered user");
                }
            } else {
                toast.warn("Try again ...");
                setNextBtnPressed(false);
            }
        } catch (error) {
            toast.warn("Try again ...");
            console.log("DSFDSFsda", error)
            setNextBtnPressed(false);
        }
    };


    return (
        <Modal
            animation={false}
            centered
            mask
            transitionName=""
            maskTransitionName=""
            maskStyle={{ border: "1px solid" }}
            maskClosable={true}
            open={modelOpen}
            width={"30vw"}
            zIndex={1000}
            style={{ top: -70, padding: 0 }}
            bodyStyle={{ padding: 0 }}
            footer={null}
            title={null}
            closable={false}
            onCancel={() => {
                dispatch(toggleloginModal(false))
            }}
        >

            <Card bordered={false} style={{ padding: "0rem" }} bodyStyle={{ padding: 0, display: "flex", flexDirection: "column", gap: "1rem" }}>
                <Logo type='dark' isClickable={false} />
                <div className='textLG w-700'>Hello! let's get started</div>
                <span>

                    <span className='d-flex flex-column gap-1'>
                        <span className='textSM w-500 dark3'>Sign in to continue.</span>
                        {!nextBtnPressed ? <Input size='large' placeholder='Enter your email address' onChange={(e) => {
                            setEmail(e.target.value)
                        }} /> : <Input.Password size='large' placeholder='Enter OTP' style={{ letterSpacing: 4 }} maxLength={6} onChange={(e) => {
                            setOTP(e.target.value)
                        }} />}
                    </span>

                </span>
                <button style={{ width: "100%" }} onClick={nextBtnPressed ? () => checkOTP() : () => onNextBtnPressed()} type='submit' className={"btnPrimary"}>
                    {nextBtnPressed ? "Sign in" : "Send OTP"}
                </button>
                <div className="text-center mt-4 fontH2">
                    New to sharpely? <Link to="/signup" className='mx-2'>Register</Link>
                </div>
            </Card>
        </Modal>
    )
}

