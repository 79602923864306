import { Card, Dropdown } from "antd"
import { fetchFundData } from "Libs/fetchFundData"
import { screenerMetricData } from "Libs/utils"
import React from "react"
import { FiChevronDown } from "react-icons/fi"
import { useNavigate } from "react-router"
import { StockLivePrice } from "Services/api/LivePricesWebSocket"
import AMCIcon from "./AMCIcon"
import { DecimalValueFormat } from "./DecimalValueFormat"
import RedGreenText from "./RedGreenText"

const DashboardTopPerformers = ({ header, type, fundsToShow = 5, dropDown = 1, filter = [] }) => {
    const navigate = useNavigate();
    const options = type.value === 4 ?
        [
            {
                key: 'cagr_1y',
                label: <div className="textXS" onClick={() => setYear(options[0])}>1 Year</div>,
                text: '1 Year'
            },
            {
                key: 'cagr_3y',
                label: <div className="textXS" onClick={() => setYear(options[1])}>3 Years</div>,
                text: '3 Years'
            },
            {
                key: 'cagr_5y',
                label: <div className="textXS" onClick={() => setYear(options[2])}>5 Years</div>,
                text: '5 Years'
            },
            {
                key: 'cagr_10y',
                label: <div className="textXS" onClick={() => setYear(options[3])}>10 Years</div>,
                text: '10 Years'
            },
        ]
        :
        [
            {
                key: 'cagr_1y',
                label: <div className="textXS" onClick={() => setYear(options[0])}>1 Year</div>,
                text: '1 Year'
            },
            {
                key: 'cagr_3y',
                label: <div className="textXS" onClick={() => setYear(options[1])}>3 Years</div>,
                text: '3 Years'
            },
            {
                key: 'cagr_5y',
                label: <div className="textXS" onClick={() => setYear(options[2])}>5 Years</div>,
                text: '5 Years'
            },
            {
                key: 'cagr_10y',
                label: <div className="textXS" onClick={() => setYear(options[3])}>10 Years</div>,
                text: '10 Years'
            },
        ]
    const [year, setYear] = React.useState({})
    const [funds, setFunds] = React.useState([]);
    const [allFunds, setallFunds] = React.useState([]);

    const getData = async () => {
        var a = await fetchFundData(type.value)
        

        if (filter.length > 0) {
            filter.map((fil) => {
                a = a.filter((i) => i[fil[0]] === fil[1])
            })
        }
        setallFunds(a)
        setYear(options[1])
        // setFunds(a)
    }

    React.useEffect(() => {
        getData()
    }, [type])

    React.useEffect(() => {
        let a = [...allFunds]?.sort((a, b) => { return a[year.key] ? b[year.key] - a[year.key] : 1 })
        setFunds(a)
    }, [year])


    return <Card bordered={false} style={{ padding: 0 }} bodyStyle={{ padding: 0 }}>
        <div className='pad20Container' style={{ display: "flex", alignItems: "center" }}>
            {header}
            {dropDown === 0 ? <></>
                : <Dropdown
                    menu={{
                        items: options,
                        selectable: true,
                        defaultSelectedKeys: [year.key],
                    }}
                    placement="bottomRight">
                    <span className="textXS" style={{ cursor: 'pointer' }}>{year.text} <FiChevronDown size={12} /></span>
                </Dropdown>
            }
        </div>
        <div>
            {
                funds.slice(0, fundsToShow).map((f, i) => {
                    return <div key={i} className="borderAll" style={{ backgroundColor: i % 2 !== 0 ? 'var(--white)' : 'var(--grey4)' }}>
                        <div className="pad20Side6VerContainer" style={{ display: "flex", gap: "0.5rem", alignItems: "center" }} onClick={() => navigate(type.basePath + `${type.value === 4 ? '/stock-detail/' + f.symbol : '/fund-detail/' + f?.basic_name?.replace(" - Direct Plan", "")?.replaceAll(' ', '-') + '?code=' + f.plan_id}`)}>
                            <AMCIcon amcName={type.value === 4 ? f.symbol : f.amc_full_name} fundType={type.value} height='1.5rem' width="1.5rem" />
                            <div className="textXS black hoverBold" style={{ flex: 2, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }} >
                                {type.value === 4 ? f?.proper_name : f?.basic_name?.replace(" - Direct Plan", "")}
                            </div>
                            <span className="textXS w-500" >{type.value !== 2 && dropDown === 0 ?
                                <StockLivePrice format={3} symbol={f?.symbol || f?.ticker} /> :
                                <RedGreenText text={DecimalValueFormat({ num: f[year.key], suffix: ' %' })} value={f[year.key]} />}</span>
                        </div>
                    </div>
                })
            }
        </div>
    </Card>
}

export default DashboardTopPerformers