import React from 'react';
import { BsArrowRight } from 'react-icons/bs';
import AMCIcon from './AMCIcon';
import { CurrencyFormat } from './CurrencyFormat';
import MintboxIcon from './MintboxIcon';
import RedGreenText from './RedGreenText';


const FundBox = ({ fundType, fundData, fundName, fundPerChange, fundChangeBalance, fundBalance,
    fundXirr, onClick = () => { }, isSelected = false }) => {

    return <div onClick={onClick} style={{ padding: "10px", backgroundColor: !isSelected ? "#fff" : "#fafafa", border: isSelected ? "0.5px solid #e5e5e5" : "0px", cursor: "pointer" }}>
        <div style={{ display: "flex", justifyContent: "space-between", flex: 1, gap: "5px" }}>
            <div style={{ flex: 1 }} >
                <div style={{ display: "flex", }}>
                    <div className='mt-1'>
                        {fundType === 2 || fundType === 1 ? <div style={{ alignItems: "center" }}>
                            <AMCIcon height="40px" width="40px" amcName={fundData.amc_full_name || fundData.AMCName} />
                        </div> : <div style={{ alignItems: "center" }}>
                            <MintboxIcon id={fundData.MintboxId} fundData={fundData} height="40px" width="40px" />
                        </div>}
                    </div>
                    <div className='fontNormalDark14' style={{ marginLeft: "16px" }}>
                        {fundName}
                        <div>
                            <span className='fontNormalDark13'>1 Day</span> {" "}
                            <RedGreenText text={CurrencyFormat(fundChangeBalance, 'long', 2)} value={fundChangeBalance} />
                            <RedGreenText text={"(" + fundPerChange.toFixed(2) + "%)"} value={fundPerChange} />
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ textAlign: "right" }} >
                <h5 style={{ display: "flex", justifyContent: "space-between", color: "#333", fontWeight: 500, marginBottom: "8px" }}>
                    {CurrencyFormat(fundBalance)}
                    <BsArrowRight />
                </h5>
                <h6 className="fontNormalDark13" style={{ margin: "0px" }}>
                    Ann. return: <RedGreenText text={fundXirr.toFixed(2) + '%'} value={fundXirr} />
                </h6>
            </div>
        </div>
    </div >
};

export default FundBox;
