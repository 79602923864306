import { CurrencyFormat } from "Components/CurrencyFormat";
import { dayDiff, formatDate } from "Components/DateFormatter";
import { DecimalValueFormat } from "Components/DecimalValueFormat";
import { encryptedBoxType } from "Components/EncryptedBox";
import { useLocation } from "react-router-dom";
import apiHelper from "Services/api/ApiHelper";
import apiHelperPY from "Services/api/ApiHelperPY";
import { Dashboard_Portfolio } from "Services/api/FabricatedAPIS";
import db from "./db";
import { fetchFundData } from "./fetchFundData";


// Logs out user
export const handleSignout = async (props) => {
    await db.clear();
    props.history.push("/auth-login")
};

// for asset dropdown in header
export const useGetAssetType = () => {
    var a = useLocation().pathname
    var propData = [
        { "key": "1", "name": "Exchange traded funds", "dname": "ETFs", basePath: "/etfs", value: 1, strVal: 'etf', omkar: 'ETF' },
        { "key": "2", "name": "Mutual funds", "dname": "Mutual funds", basePath: "/mutual-funds", value: 2, strVal: 'mf', omkar: 'Mutual fund' },
        { "key": "4", "name": "Stocks", "dname": "Stocks", basePath: "/stocks", value: 4, strVal: 'stock', omkar: 'Stock' }
    ]
    const type = a.startsWith('/etfs') ? propData[0] : a.startsWith('/mutual-funds') ? propData[1] : a.startsWith('/stocks') ? propData[2] : [];

    return type
}

export const getUser = async (isFresh = false) => {
    return await db.get(db.model.user).then(async (user) => {
        if (user) {
            var a = await Dashboard_Portfolio.getClientData(user?.userData, isFresh).then((res) => {
                return res
            })
            return {
                ...user?.userData,
                ...a
            }
        } else {
            return null
        }
    })
}

export const getStockPrevPrices = async () => {
    let res = await apiHelper({ apiName: 'getStockPrevPrices', saveResponse: 'localStorage', data: true })
    if (res.length > 0) {
        var akkk = res?.filter((i) => i.Symbol === 'INFY').map((item) => new Date(item.Date.replace('T00:00:00', '')).getTime())
        console.log(parseInt(dayDiff(Math.max(...akkk), new Date().getTime())))
        if (parseInt(dayDiff(Math.max(...akkk), new Date().getTime())) >= 1) {
            console.log("fresh data")
            let res = await apiHelper({ apiName: 'getStockPrevPrices', saveResponse: 'localStorage', data: true, getFresh: true })
            return res
        }
    } else {
        var akkk = res?.filter((i) => i.Symbol === 'INFY').map((item) => new Date(item.Date.replace('T00:00:00', '')).getTime())
        if (parseInt(dayDiff(Math.max(...akkk), new Date().getTime())) >= 1) {
            console.log("fresh data")
            let res = await apiHelper({ apiName: 'getStockPrevPrices', saveResponse: 'localStorage', data: true, getFresh: true })
            return res
        }
    }
    console.log("saved data")
    return res
}


export const sortFunds = (arr, key, order = "top") => {
    var arrr = [...arr]?.filter((i) => (i[key] !== null))
    if (order === "bot") {
        arrr = arrr.sort((a, b) => a[key] - b[key])
    } else {
        arrr = arrr.sort((a, b) => b[key] - a[key])
    }
    return arrr
}

export const quantile = (sorted, q) => {
    let pos = (sorted.length - 1) * q;
    if (pos % 1 === 0) {
        return sorted[pos];
    }
    pos = Math.floor(pos);
    if (sorted[pos + 1] !== undefined) {
        return (sorted[pos] + sorted[pos + 1]) / 2;
    }
    return sorted[pos];
};

export const inputBoxFormatter = ({ text, metricObj }) => {
    if (String(metricObj?.metric_data_type).toLowerCase() === "float") {
        if (String(metricObj?.metric_unit).toLowerCase() === "rupee") {
            return `₹ ${text}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        } else if (String(metricObj?.metric_unit).toLowerCase() === "rupee_cr") {
            return `₹ ${text} Cr`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        } else if (String(metricObj?.metric_unit).toLowerCase() === "percentage") {
            return `${text} %`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        } else {
            return text
        }
    } else {
        return text
    }
}

export const inputBoxParser = ({ text, metricObj }) => {
    if (String(metricObj?.metric_data_type).toLowerCase() === "float") {
        if (String(metricObj?.metric_unit).toLowerCase() === "rupee") {
            return text.replace(/\₹\s?|(,*)/g, '')
        } else if (String(metricObj?.metric_unit).toLowerCase() === "rupee_cr") {
            return text.replace(/\₹\s?|(,*)/g, '').replace("Cr", '')
        } else if (String(metricObj?.metric_unit).toLowerCase() === "percentage") {
            return text.replace(/\₹\s?|(,*)/g, '').replace("%", '')
        } else {
            return text
        }
    } else {
        return text
    }
}

export const displayValue = ({ text, metricObj }) => {
    if (String(metricObj?.metric_data_type).toLowerCase() === "float") {
        if (String(metricObj?.metric_unit).toLowerCase() === "rupee") {
            return CurrencyFormat(text, 'long', 2)
        } else if (String(metricObj?.metric_unit).toLowerCase() === "rupee_cr") {
            return CurrencyFormat(text, 'long', 2, ' Cr')
        } else if (String(metricObj?.metric_unit).toLowerCase() === "percentage") {
            return DecimalValueFormat({ num: text, suffix: "%", placeholder: "-", decimals: 2 })
        } else {
            return DecimalValueFormat({ num: text, suffix: "", placeholder: "-", decimals: 2 })
        }
    } else if (String(metricObj?.metric_data_type).toLowerCase() === 'date') {
        return formatDate(text)
    } else {
        return text || "-"
    }
    // return (
    //     String(metricObj?.metric_data_type).toLowerCase() === "float"
    //         ?
    //         String(metricObj?.metric_unit).toLowerCase() === "rupee"
    //             ?
    //             CurrencyFormat(Number(text), 'long', 2)
    //             :
    //             String(metricObj?.metric_unit).toLowerCase() === "rupee_cr"
    //                 ?
    //                 CurrencyFormat(Number(text), 'long', 2) + ' Cr'
    //                 :
    //                 String(metricObj?.metric_unit).toLowerCase() === "percentage"
    //                     ?
    //                     <RedGreenText text={DecimalValueFormat({ num: Number(text), suffix: "%", placeholder: "-", decimals: 1 })} value={Number(text)} />
    //                     : "ADSFSDF" + DecimalValueFormat({ num: Number(text), suffix: "", placeholder: "-", decimals: 2 })
    //         : String(metricObj?.metric_data_type).toLowerCase() === 'date'
    //             ?
    //             formatDate(Number(text))
    //             : text
    // )
}

export const risk_box_codes = [{
    "value": 1,
    "label": "Super conservative",
    "key": 1,
}, {
    "value": 2,
    "label": "Conservative",
    "key": 2,
}, {
    "value": 3,
    "label": "Moderate",
    "key": 3,
}, {
    "value": 4,
    "label": "Aggressive",
    "key": 4,
}, {
    "value": 5,
    "label": "Super aggressive",
    "key": 5,
}, {
    "value": 6,
    "label": "Very aggressive",
    "key": 6,
}]


export const stock_universe = [{
    "value": "all",
    "label": "All stocks",
    "key": "all",
}, {
    "value": "is_nifty_50",
    "label": "Nifty 50",
    "key": "is_nifty_50",
}, {
    "value": "is_nifty_100",
    "label": "Nifty 100",
    "key": "is_nifty_100",
}, {
    "value": "is_nifty_500",
    "label": "Nifty 500",
    "key": "is_nifty_500",
}, {
    "value": "is_nifty_midcap100",
    "label": "Nifty Midcap 100",
    "key": "is_nifty_midcap100",
}, {
    "value": "is_nifty_smallcap100",
    "label": "Nifty Smallcap 100",
    "key": "is_nifty_smallcap100",
}]


export const mf_etf_categories = [{
    "value": "Equity",
    "label": "Equity",
    "key": "Equity",
}, {
    "value": "Debt",
    "label": "Debt",
    "key": "Debt",
}, {
    "value": "Hybrid",
    "label": "Hybrid",
    "key": "Hybrid",
}, {
    "value": "Commodities",
    "label": "Commodities",
    "key": "Commodities",
}]


export const unique = (array, key) => [...new Map(array.map(item =>
    [item[key], item])).values()];

export const queries = [[
    {
        value: 'isOne',
        queryStr: 'isOne',
        label: 'includes',
    },
    {
        value: 'notOne',
        queryStr: 'notOne',
        label: 'excludes',
    }
], [
    {
        value: 'in',
        queryStr: 'in',
        label: 'is in',
    },
], [
    {
        value: 'gt',
        queryStr: 'gt',
        label: 'greater than',
    },
    {
        value: 'gteq',
        queryStr: 'gteq',
        label: 'greater than equal to',
    },
    {
        value: 'lt',
        queryStr: 'lt',
        label: 'less than',
    },
    {
        value: 'lteq',
        queryStr: 'lteq',
        label: 'less than equal to',
    },
    // {
    //     value: 'query7',
    //     label: 'is between',
    // }
], [
    {
        value: 'yes',
        queryStr: 'yes',
        label: 'yes',
    },
    {
        value: 'no',
        queryStr: 'no',
        label: 'no',
    }
], [
    {
        value: 'gt',
        queryStr: 'gt',
        label: 'after',
    },
    {
        value: 'lt',
        queryStr: 'lt',
        label: 'before',
    }
]],

    perFilterValues = Array.from({ length: 20 }, (v, k) => (k + 1) * 5).map((i) => {
        return {
            value: String(i / 100),
            queryStr: String(i),
            label: String(i) + "%",
        }
    }),

    investingStylelist = [
        { "UniqueIDCheck": "Check_1", "label": "FoF", "CheckName": "Fund of funds", value: "is_fof", fundListKey: "is_fof", fundListValue: 1 },
        { "UniqueIDCheck": "Check_2", "label": "Index Funds", "CheckName": "Index funds", value: "is_index_fund", fundListKey: "is_index_fund", fundListValue: 1 },
        { "UniqueIDCheck": "Check_3", "label": "ELSS funds", "CheckName": "ELSS funds", value: "is_elss_fund", fundListKey: "is_elss_fund", fundListValue: "Equity: ELSS" },
        { "UniqueIDCheck": "Check_4", "label": "All", "CheckName": "All funds", value: "all", isAll: true }
    ],

    sortOptions = [
        {
            value: 'top',
            queryStr: 'top',
            label: 'Top',
        },
        {
            value: 'bot',
            queryStr: 'bot',
            label: 'Bottom',
        }
    ],

    // mf def cols
    mf_equityFreeCols = ["basic_name", "aum", 'expense_ratio', 'pe', 'pb', 'cagr_1y', 'cagr_3y', 'cagr_5y'],
    mf_equityPaidCols = ["basic_name", "aum", 'expense_ratio', 'sh_val_comp', 'sh_mom_comp', 'sh_qual_comp', 'sh_3fac_comp', 'cagr_3y'],

    mf_debtFreeCols = ["basic_name", "aum", 'expense_ratio', 'ytm', 'duration', 'maturity', 'credit_risk', 'ir_risk'],
    mf_debtPaidCols = ["basic_name", "aum", 'expense_ratio', 'ytm', 'duration', 'maturity', 'credit_risk', 'ir_risk'],

    mf_hybridFreeCols = ["basic_name", "aum", 'expense_ratio', 'pe', 'pb', 'ytm', 'duration', 'maturity'],
    mf_hybridPaidCols = ["basic_name", "aum", 'expense_ratio', 'pe', 'pb', 'ytm', 'duration', 'maturity'],

    mf_commodityFreeCols = ["basic_name", "aum", 'expense_ratio', 'benchmark', 'cagr_1y', 'cagr_3y', 'cagr_5y'],
    mf_commodityPaidCols = ["basic_name", "aum", 'expense_ratio', 'benchmark', 'cagr_1y', 'cagr_3y', 'cagr_5y'],


    // index def cols
    index_equityFreeCols = ["basic_name", "aum", 'expense_ratio', 'tracking_error', 'td_1y', 'cagr_1y', 'cagr_3y', 'pe', 'pb'],
    index_equityPaidCols = ["basic_name", "aum", 'expense_ratio', 'tracking_error', 'td_1y', 'sh_val_comp', 'sh_mom_comp', 'sh_qual_comp', 'sh_3fac_comp'],

    index_debtFreeCols = ["basic_name", "aum", 'expense_ratio', 'tracking_error', 'td_1y', 'ytm', 'duration', 'maturity', 'credit_risk', 'ir_risk'],
    index_debtPaidCols = ["basic_name", "aum", 'expense_ratio', 'tracking_error', 'td_1y', 'ytm', 'duration', 'maturity', 'credit_risk', 'ir_risk'],

    index_hybridFreeCols = ["basic_name", "aum", 'expense_ratio', 'tracking_error', 'td_1y', 'pe', 'pb', 'ytm', 'duration', 'maturity'],
    index_hybridPaidCols = ["basic_name", "aum", 'expense_ratio', 'tracking_error', 'td_1y', 'pe', 'pb', 'ytm', 'duration', 'maturity'],

    index_commodityFreeCols = ["basic_name", "aum", 'expense_ratio', 'tracking_error', 'td_1y', 'benchmark', 'cagr_1y', 'cagr_3y', 'cagr_5y'],
    index_commodityPaidCols = ["basic_name", "aum", 'expense_ratio', 'tracking_error', 'td_1y', 'benchmark', 'cagr_1y', 'cagr_3y', 'cagr_5y'],


    //etf def cols
    etf_equityFreeCols = ["basic_name", "aum", 'expense_ratio', 'tracking_error', 'td_1y', '21d_vol', 'cagr_1y', 'cagr_3y', 'pe', 'pb'],
    etf_equityPaidCols = ["basic_name", "aum", 'expense_ratio', 'tracking_error', 'td_1y', '21d_vol', 'sh_val_comp', 'sh_mom_comp', 'sh_qual_comp', 'sh_3fac_comp'],

    etf_debtFreeCols = ["basic_name", "aum", 'expense_ratio', 'tracking_error', 'td_1y', '21d_vol', 'ytm', 'duration', 'maturity', 'credit_risk', 'ir_risk'],
    etf_debtPaidCols = ["basic_name", "aum", 'expense_ratio', 'tracking_error', 'td_1y', '21d_vol', 'ytm', 'duration', 'maturity', 'credit_risk', 'ir_risk'],

    etf_hybridFreeCols = ["basic_name", "aum", 'expense_ratio', 'tracking_error', 'td_1y', '21d_vol', 'pe', 'pb', 'ytm', 'duration', 'maturity'],
    etf_hybridPaidCols = ["basic_name", "aum", 'expense_ratio', 'tracking_error', 'td_1y', '21d_vol', 'pe', 'pb', 'ytm', 'duration', 'maturity'],

    etf_commodityFreeCols = ["basic_name", "aum", 'expense_ratio', 'tracking_error', 'td_1y', '21d_vol', 'benchmark', 'cagr_1y', 'cagr_3y', 'cagr_5y'],
    etf_commodityPaidCols = ["basic_name", "aum", 'expense_ratio', 'tracking_error', 'td_1y', '21d_vol', 'benchmark', 'cagr_1y', 'cagr_3y', 'cagr_5y'],


    magicBoardCols = {
        "mf": {
            "Equity": {
                "free": mf_equityFreeCols,
                "paid": mf_equityPaidCols
            },
            "Debt": {
                "free": mf_debtFreeCols,
                "paid": mf_debtPaidCols
            },
            "Hybrid": {
                "free": mf_hybridFreeCols,
                "paid": mf_hybridPaidCols
            },
            "Commodities": {
                "free": mf_commodityFreeCols,
                "paid": mf_commodityPaidCols
            }
        },
        "index": {
            "Equity": {
                "free": index_equityFreeCols,
                "paid": index_equityPaidCols
            },
            "Debt": {
                "free": index_debtFreeCols,
                "paid": index_debtPaidCols
            },
            "Hybrid": {
                "free": index_hybridFreeCols,
                "paid": index_hybridPaidCols
            },
            "Commodities": {
                "free": index_commodityFreeCols,
                "paid": index_commodityPaidCols
            }
        },
        "etf": {
            "Equity": {
                "free": etf_equityFreeCols,
                "paid": etf_equityPaidCols
            },
            "Debt": {
                "free": etf_debtFreeCols,
                "paid": etf_debtPaidCols
            },
            "Hybrid": {
                "free": etf_hybridFreeCols,
                "paid": etf_hybridPaidCols
            },
            "Commodities": {
                "free": etf_commodityFreeCols,
                "paid": etf_commodityPaidCols
            }
        }
    },

    //stocks def cols
    stock_FreeCols = ["proper_name", "mcap", "pe_ttm", "pb", "roe_ttm", 'roce_ttm', 'one_yr_pct', 'three_yr_pct', 'five_yr_pct'],
    stock_PaidCols = ["proper_name", "mcap", "style_box_code", "sh_val_comp", 'sh_mom_comp', 'sh_qual_comp', 'one_yr_pct', 'three_yr_pct'],



    hiddenCols = ["industry_code", "is_nifty_50", "is_nifty_100", "is_nifty_500", "is_nifty_midcap100", "is_nifty_smallcap100", "investing_style", "category_id", 'exit_load'],

    initialColumnsStocks = ["proper_name", "mcap", "style_box_code", "sh_3fac_comp", "sh_val_comp", 'sh_mom_comp', 'sh_qual_comp',],

    initialColumnsMFandETF = ["basic_name", "aum", 'expense_ratio', "cagr_1y", "cagr_3y", "cagr_5y"],

    stockCodes = ['sh_val_comp', 'sh_mom_comp', 'sh_qual_comp', 'sh_gr_comp', 'sh_v_qual_comp', 'sh_qual_mom_comp', 'sh_gr_mom_comp', 'sh_3fac_comp']

export const median = (arr, key, order = "top") => {
    var nums = arr.filter((i) => i[key] !== null && i[key] !== NaN && i[key] !== undefined)

    if (nums.length > 0) {
        if (order === "bot") {
            nums = nums.sort((a, b) => a[key] - b[key])
        } else {
            nums = nums.sort((a, b) => b[key] - a[key])
        }
        nums = nums.sort((a, b) => a[key] - b[key])
        const mid = Math.floor(nums.length / 2)
        var a = nums.length % 2 !== 0 ? Number(nums[mid][key]) : (Number(nums[mid - 1][key]) + Number(nums[mid][key])) / 2;
        return Number(a.toFixed(2))
    }
    return null


};


export const setIsInitialCol = (user, item, freeCols, paidCols) => {
    if (user !== undefined && user !== null) {
        if (user?.Client[0]?.SharpelyAccessLevel > 0) {
            return paidCols.includes(item.metric_col_code)
        } else {
            return freeCols.includes(item.metric_col_code)
        }
    } else {
        return freeCols.includes(item.metric_col_code)
    }

}


export const screenerMetricData = async (type, isSignal = 0) => {
    var categories = []
    var amcs = []
    var macroSectors = []
    var sectors = []
    var industries = []
    var basicIndustries = []
    var stockStyles = []

    let a = await fetchFundData(type.value)
    let user = await getUser(false)

    if (type.value === 1 || type.value === 2) {
        categories = await apiHelper({ apiName: "getMFCategories" })
        amcs = [...new Set(a.map((item, index) => {
            return {
                "amc_full_name": item.amc_full_name,
                "key": item.amc_full_name.split(" ").join("_").toString().toLowerCase()
            }
        }))]
        amcs = Array.from(new Set(amcs.map(JSON.stringify))).map(JSON.parse)
        a = a.map(i => {
            if (i.category_id === 106) {
                return {
                    ...i,
                    is_elss_fund: 1
                }
            }
            return i
        })
    } else {
        a = a.filter((i) => i.nse_active === 1)

        //sectorAndIndustries
        // basic_industry_code
        // basic_industry_desc
        // detailed_desc
        // industry_code
        // industry_desc
        // maco_sec_desc
        // macro_sec_code
        // sector_code
        // sector_desc
        var stockSectorCLF = await apiHelper({ apiName: 'getStockSectorCLF' })

        stockStyles = await apiHelperPY({ apiName: 'getStockStyles', saveResponse: 'sessionStorage' })
        stockStyles = JSON.parse(stockStyles).map((item) => {
            return {
                ...item,
                "value": item.style_box_code,
                "label": item.style_box_name,
                "key": item.style_box_code,
            }
        })


        industries = [...new Set(stockSectorCLF.map(item => {
            return {
                "value": item.industry_code,
                "label": item.industry_desc,
                "key": item.industry_code,
            }
        }))];
        industries = Array.from(new Set(industries.map(JSON.stringify))).map(JSON.parse)

        basicIndustries = [...new Set(stockSectorCLF.map(item => {
            return {
                "value": item.basic_industry_code,
                "label": item.basic_industry_desc,
                "key": item.basic_industry_code,
            }
        }))];
        basicIndustries = Array.from(new Set(basicIndustries.map(JSON.stringify))).map(JSON.parse)

        sectors = [...new Set(stockSectorCLF.map(item => {
            return {
                "value": item.sector_code,
                "label": item.sector_desc,
                "key": item.sector_code,
            }
        }))];
        sectors = Array.from(new Set(sectors.map(JSON.stringify))).map(JSON.parse)

        macroSectors = [...new Set(stockSectorCLF.map(item => {
            return {
                value: item.macro_sec_code,
                label: item.maco_sec_desc,
                key: item.macro_sec_code,
            }
        }))];
        macroSectors = Array.from(new Set(macroSectors.map(JSON.stringify))).map(JSON.parse)


    }



    const listDataRender = (item, index) => {


        if (type.value === 4) {
            var checkAllData = item.metric_col_code === "macro_sec_code" ? macroSectors :
                item.metric_col_code === "sector_code" ? sectors :
                    item.metric_col_code === "industry_code" ? industries :
                        item.metric_col_code === "nse_basic_ind_code" ? basicIndustries :
                            item.metric_col_code === "risk_box_code" ? risk_box_codes : item.metric_col_code === "style_box_code" ? stockStyles : []

            if (checkAllData.length > 0) {
                return {
                    render: (text, record) => <span >{checkAllData.filter((i) => String(i.value) === String(text))[0]?.label || "-"}</span>,
                    hidden: hiddenCols.includes(item.metric_col_code)
                }
            }

            if (stockCodes.includes(item.metric_col_code)) {
                return {
                    render: (text, record, index) => <span style={{ display: 'flex', gap: '5px', cursor: "pointer", alignItems: "center", justifyContent: "flex-end", filter: encryptedBoxType() ? "none" : "none" }}>
                        {/* {+parseFloat(Number(text)).toFixed(2) !== 0 && <AiFillAlert color={Number(text) < 30 ? "var(--red)" : Number(text) > 70 ? "var(--green)" : "var(--primary)"} />} */}
                        <span>
                            {displayValue({ text, metricObj: item })}
                        </span>
                    </span>,
                    hidden: hiddenCols.includes(item.metric_col_code)
                }
            }
        }


        return {
            render: (text, record) => <span >{displayValue({ text, metricObj: item })}</span>,
            hidden: hiddenCols.includes(item.metric_col_code)
        }
    }


    //getScreenerCategory
    var screener_Category = await apiHelper({ apiName: 'getScreenerCategory', data: type.strVal })
    //getScreenerSubCategory
    var screener_SubCategory = await apiHelper({ apiName: 'getScreenerSubCategory', data: type.strVal })

    if (type.value === 4) {
        // for universe dropdown hide in modal in stocks
        screener_SubCategory = screener_SubCategory.filter((item) => item.sub_category_id !== 1)
    }


    //getScreenerMetric
    var screener_Metric = await apiHelper({ apiName: 'getScreenerMetric', data: type.strVal })
    //getScreenerMetricDescription
    var screener_Metric_Desc = await apiHelperPY({ apiName: 'getMetricDescription', data: type.value })
    screener_Metric_Desc = JSON.parse(screener_Metric_Desc)



    screener_Metric = screener_Metric.map((item, index) => {

        var code = item.metric_col_code

        var floatType = item.metric_data_type === "float" && item.show_percentile === 1 && item.show_absolute === 1 ? "top" :
            item.metric_data_type === "float" && item.show_percentile === 1 ? "bot" : null

        var queriesFinal = item.metric_data_type === "string" ? queries[0] : item.metric_data_type === "boolean" ? queries[3] : item.metric_data_type === "Date" ? queries[4] :
            floatType === "top" ? queries[1]?.concat(queries[2]) :
                floatType === "bot" ? queries[1] :
                    floatType === null ? queries[2] : queries[0]

        return {
            ...item,
            direction_flag: item.direction_flag === 1 ? "top" : item.direction_flag === -1 ? "bot" : item.direction_flag,
            title: item.metric_name,
            dataIndex: item.metric_col_code,
            key: item.metric_col_code,
            isPro: true,
            isInitial: type.value === 1 || type.value === 2 ? setIsInitialCol(user, item, initialColumnsMFandETF, initialColumnsMFandETF) : setIsInitialCol(user, item, stock_FreeCols, stock_PaidCols),
            metric_desc: <span id="metric_desc" className='ql-editor textSM' style={{ lineHeight: "1.125rem !important" }} dangerouslySetInnerHTML={{ __html: String(screener_Metric_Desc?.filter((i) => i.desc_id === item.desc_id)[0]?.description)?.replaceAll('\"color: black;\"', '') }}></span>,
            align: item.metric_data_type === "float" ? "right" : 'right',//all right
            sorter: item.metric_data_type === 'Date' ? (a, b) => new Date(a[code]) - new Date(b[code]) : (a, b) => a[code] - b[code],
            width: 130,
            metricDefVal: item.metric_data_type === "float" ? Number(median(a, item.metric_col_code, item.direction_flag === 1 ? "top" : "bot")) : item.metric_data_type === "boolean" ? 'yes' : null,
            pervalue: floatType === "top" || floatType === "bot" ? perFilterValues[0]?.value : null,
            queryValue: queriesFinal[0]?.value,
            ...listDataRender(item, index)
        }
    })

    var ind1 = -1
    var ind2 = -1
    var ind3 = -1
    var m_category_id = ""
    var m_sub_category_id = ""
    var m_metric_col_code = ""
    var finalData = screener_Category.map((sc_category, ind_1) => {
        var screenerSubCat = screener_SubCategory.filter((su) => su.category_id === sc_category.category_id).map((sc_sub_category, ind_2) => {
            var metrics = screener_Metric.filter((me) => me.category_id === sc_category.category_id && me.sub_category_id === sc_sub_category.sub_category_id)
            if (isSignal === 1) {
                metrics = metrics.filter((me) => me.is_signal === 1)
            }
            if (type.value === 1) {
                //etf
                metrics = metrics.filter((item) => item.is_etf_metric === 1)
            }

            if (type.value === 2) {
                //etf
                metrics = metrics.filter((item) => item.is_mf_metric === 1)
            }




            for (var i = 0; i < metrics.length; i++) {
                if (ind1 === -1 && ind2 === -1 && ind3 === -1) {
                    if (!hiddenCols.includes(metrics[i]?.metric_col_code)) {
                        ind1 = ind_1
                        ind2 = ind_2
                        ind3 = i
                        m_category_id = sc_category.category_id
                        m_sub_category_id = sc_sub_category.sub_category_id
                        m_metric_col_code = metrics[i]?.metric_col_code
                        break;
                    }

                }
            }

            // console.log(metrics)

            return {
                ...sc_sub_category,
                key: "category_" + sc_category.category_id + "_subCategory_" + sc_sub_category.sub_category_id,
                isVisibleForCustomise: metrics.filter((i) => i.hidden !== true).length !== 0,
                isVisibleNormally: metrics.length !== 0,
                metric: metrics
            }
        })
        return {
            ...sc_category,
            key: "category_" + sc_category.category_id,
            sub_category: screenerSubCat,
            isVisibleForCustomise: screenerSubCat.filter((i) => i.isVisibleForCustomise === true).length !== 0,
            isVisibleNormally: screenerSubCat.filter((i) => i.isVisibleNormally === true).length !== 0,
        }
    })



    return {
        screenerMetricData: screener_Metric,
        screenerMetricDescData: screener_Metric_Desc,
        screenerFinalData: finalData,
        allFunds: a,
        levelVals: {
            level1: ind1,
            level2: ind2,
            level3: ind3,
            m_category_id: m_category_id,
            m_sub_category_id: m_sub_category_id,
            m_metric_col_code: m_metric_col_code
        },
        fabricatedData: {
            //stocks
            macroSectors: macroSectors,
            sectors: sectors,
            industries: industries,
            basicIndustries: basicIndustries,
            stockSectorCLF: stockSectorCLF,
            stockStyles: stockStyles,
            //mf and etfs
            mf_categories: categories,
            mf_amcs: amcs
        }
    }

}