import { Card } from "antd";
import React, { useEffect, useState } from "react";
import { FcDocument, FcFolder } from "react-icons/fc";
import { Link, useParams } from "react-router-dom";
import { getKnowledgeBaseData } from "./getKnowledgeBaseData";

const ResourceSidebar2 = () => {

    const [categories, setCategories] = useState([]);
    const [subCategory, setSubCategory] = useState({});
    const [blog, setBlog] = useState({});
    const [category, setCategory] = useState({});
    const params = useParams();

    const loadData = async () => {
        let res = await getKnowledgeBaseData();
        if (res.type) {
            setCategories(res.categories || {});
            setCategory(res.categories.filter((item) => item.Category.split(' ').join('_').toLowerCase() === params.category)[0] || {});
            setSubCategory(res?.subCategories?.filter((item) => item.SubCategory.split(' ').join('_').toLowerCase() === params.subCategory &&
                item.Category.split(' ').join('_').toLowerCase() === params.category)[0] || {});
            setBlog(res.blogs?.filter(item => item?.Category?.toLowerCase().split(' ').join('_') === params.category &&
                item?.SubCategory.toLowerCase().split(' ').join('_') === (params.subCategory || '') &&
                item?.URL.split('/')?.[2] === params.blog)?.[0] || {});
        }
    }

    const handleScroll = () => {
        let sidebar = document.getElementById('sidebar');
        if (window.innerHeight > sidebar.offsetHeight + sidebar.getBoundingClientRect().top) {
            sidebar.style.top = sidebar.getBoundingClientRect().top + 'px';
        }
        else sidebar.style.top = (window.innerHeight - sidebar.offsetHeight) + 'px';
    }
    useEffect(() => {
        loadData();
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    }, [params]);


    return (
        <React.Fragment>
            <div className="rightContainer" style={{ bottom: "20px", position: "sticky", height: "fit-content", alignSelf: "flex-start" }}
                id='sidebar'>
                {subCategory?.UID && (
                    <React.Fragment>
                        <Card bordered={false}>
                            <CardHeader heading={subCategory.SubCategory} />
                            {subCategory?.Resources?.map((item) => (
                                <div key={item.UID} className='resCategoryItem'>
                                    <Link to={item.Slug} className='textSM w-500'>
                                        <FcDocument size='0.8rem' />
                                        {item?.Title}
                                    </Link>
                                    <span className='textXS'>
                                        Read
                                    </span>
                                </div>
                            ))}
                        </Card>
                    </React.Fragment>
                )}
                {category?.UID && <Card bordered={false}>
                    <CardHeader heading={category.Category} />
                    {category?.SubCategories?.map((item) => (
                        <div key={item.UID} className='resCategoryItem'>
                            <Link to={item.FirstBlog} className='textSM w-500'>
                                <FcFolder size='0.7rem' />
                                {item?.SubCategory}
                            </Link>
                            <span className='textXS'>
                                ({item?.Resources?.length})
                            </span>
                        </div>
                    ))}
                    {category?.Resources?.map((item) => (
                        <div key={item.UID} className='resCategoryItem'>
                            <Link to={item.Slug} className='textSM w-500'>
                                <FcDocument size='0.8rem' />
                                {item?.Title}
                            </Link>
                            <span className='textXS'>
                                Read
                            </span>
                        </div>
                    ))}
                </Card>}
                {categories?.[0]?.UID && <Card bordered={false}>
                    <CardHeader heading={'Categories'} />

                    {categories?.map((item) => (
                        <div key={item.UID} className='resCategoryItem'>
                            <Link to={item.Slug} className='textSM w-500'>
                                <div style={{ background: getRandomColor(), borderRadius: "50%", height: "0.6rem", width: "0.6rem" }}></div>
                                {item?.Category}
                            </Link>
                            <span className='textXS'>
                                Explore
                            </span>
                        </div>
                    ))}
                </Card>}
            </div>
        </React.Fragment>
    )
}

export default ResourceSidebar2;

function getRandomColor() {
    let color = "hsl(" + Math.random() * 360 + ", 100%, 75%)";
    return color;
}

const CardHeader = ({ heading }) => {
    return <div className="d-flex" style={{ alignItems: "center", gap: "0.4rem", flex: 1 }}>
        <div type='vertical' style={{ width: "0.3rem", height: "1.4rem", backgroundColor: "var(--primary)", borderRadius: "4px" }} />
        <span className="textMD w-700" style={{ textTransform: "uppercase" }}>{heading}</span>
    </div>
}