import React from 'react';
import AMCIcon from './AMCIcon';


const FundConstituents = ({ navigation, data, onClick, screen, type = 'normal', content, isPortfolioView = false, showNextArrow = false }) => {

    const finalbg = [
        {
            id: 1,
            name: 'Indian equity',
            color: 'var(--indianEquity)'
        },
        {
            id: 2,
            name: 'Gold',
            color: 'var(--gold)'
        },
        {
            id: 3,
            name: 'Foreign equity',
            color: 'var(--internationalEquity)'
        },
        {
            id: 4,
            name: 'Debt',
            color: 'var(--debt)'
        },
        {
            id: 5,
            name: 'Cash',
            color: 'var(--cash)'
        },
        {
            id: 6,
            name: 'Silver',
            color: 'var(--silver)'
        }
    ];


    const finalbg2 = {
        Equity: {
            id: 1,
            name: 'Equity',
            color: 'var(--indianEquity)'
        },
        Debt: {
            id: 4,
            name: 'Debt',
            color: 'var(--debt)'
        },
        Commodities: {
            id: 5,
            name: 'Commodities',
            color: 'var(--gold)'
        },
        Hybrid: {
            id: 35,
            name: 'Hybrid',
            color: 'var(--cash)'
        },
        Others: {
            id: 6,
            name: 'Others',
            color: 'var(--internationalEquity)'
        }
    };

    const CreateData = (props) => (
        props.dataFinal.length > 0 ?
            <div key={props.classType}>
                <div style={{
                    display: 'flex', flexDirection: "row", justifyContent: "space-between", borderBottomColor: 'var(--lowestRisk)', paddingLeft: "16px", paddingRight: "16px", marginBottom: 2, marginTop: type === "small" ? 12 : 16
                }}>
                    <div style={{ color: finalbg[props.classType - 1].color, flex: 5 }}>{finalbg[props.classType - 1].name}</div>
                    <div style={{ color: finalbg[props.classType - 1].color, textAlign: "center", flex: 1 }}>{(props.dataFinal.map(item => item.CurrentWeightage || 0).reduce((prev, next) => prev + next)).toFixed(1)}%</div>
                </div>
                {props.dataFinal.map((data, index) => {
                    if ((data.CurrentWeightage === 0 || data.CurrentWeightage === undefined) && props.classType !== 3) {
                        return null
                    } else {
                        return <div onClick={() => {

                        }} key={data.FundCode} style={{
                            display: 'flex', flexDirection: "row", justifyContent: "space-between",
                            borderBottomColor: 'var(--lowestRisk)',
                            borderBottomWidth: 1,
                            paddingRight: 0,
                            paddingLeft: "16px", paddingRight: "16px", paddingTop: "12px", paddingBottom: "12px"
                        }}>
                            <div key={data.FundCode} style={{
                                display: 'flex', flexDirection: "row", flex: 3,
                            }}>
                                <AMCIcon type={type} amcName={data.AMCName} />
                                <div style={{ maxWidth: "60%", marginLeft: "16px", marginRight: "16px" }}>
                                    <div numberOfLines={2} style={{}} >{data.ETFName}</div>
                                    {/* {type !== "portfolio" ? null : <div style={{  }}>{'Balance: ' + CurrencyFormat({
                                    num: data.CurrentBalance
                                })}</div>} */}
                                </div>
                            </div>
                            <div style={{ flex: 1, textAlign: "center" }}>{Number(data.CurrentWeightage).toFixed(1)}%</div>
                        </div>
                    }
                })}
            </div>
            : null
    )


    const CreateData1 = (props) => {
        return props.dataFinal.length > 0 ?
            <div key={props.classType}>
                <div style={{
                    display: 'flex', flexDirection: "row", justifyContent: "space-between", borderBottomColor: 'var(--lowestRisk)', paddingLeft: "16px", paddingRight: "16px", paddingBottom: 12, marginBottom: 2, marginTop: type === "small" ? 12 : 16, borderBottomWidth: 0.5
                }}>
                    <div style={{
                        display: 'flex', flexDirection: "row", alignItems: "center", flex: 1
                    }}>
                        <div style={{ padding: 6, borderRadius: 10, backgroundColor: finalbg[props.classType - 1].color, marginEnd: 8 }}></div>
                        <div style={{}}>{finalbg[props.classType - 1].name}</div>
                    </div>

                    <div style={{ textAlign: "center" }}>{(props.dataFinal.map(item => item.CurrentWeightage || 0).reduce((prev, next) => prev + next)).toFixed(1)}%</div>
                </div>
                {/* {props.dataFinal.map((data) => {
                    return <div key={data.FundCode} style={{
                        display: 'flex', flexDirection: "row", justifyContent: "space-between",
                        borderBottomColor: 'var(--lowestRisk)',
                        borderBottomWidth: 1,
                        paddingRight: 0,
                        paddingLeft: "16px",paddingRight: "16px", paddingTop: "12px",paddingBottom: "12px"
                    }}>
                        <div key={data.FundCode} style={{
                            display: 'flex', flexDirection: "row", flex: 3,
                        }}>
                            <AMCIcon type={type} amcName={data.AMCName} />
                            <div style={{ maxWidth: "60%", marginLeft: "16px",marginRight: "16px" }}>
                                <div numberOfLines={2} style={{  }} >{data.ETFName}</div>
                                {type !== "portfolio" ? null : <div style={{  }}>{'Balance: ' + CurrencyFormat({
                                    num: data.CurrentBalance
                                })}</div>}
                            </div>
                        </div>
                        <div style={{  flex: 1, textAlign: "center" }}>{Number(data.CurrentWeightage).toFixed(1)}%</div>
                    </div>
                })} */}
            </div>
            : null

    }


    const CreateData2 = (props) => (
        props.dataFinal.length > 0 ?
            <div key={props.classType}>
                {/* <div style={{
                    display: 'flex', flexDirection: "row", justifyContent: "space-between", borderBottomColor: 'var(--lowestRisk)', paddingLeft: "16px",paddingRight: "16px", marginBottom: 4, marginTop: type === "small" ? 12 : 20
                }}>
                    <div style={{  color: finalbg[props.classType - 1].color, flex: 5 }}>{finalbg[props.classType - 1].name}</div>
                    <div style={{  color: finalbg[props.classType - 1].color, textAlign: "center", flex: 1 }}>{(props.dataFinal.map(item => item.CurrentWeightage).reduce((prev, next) => prev + next)).toFixed(1)}%</div>
                </div> */}
                {props.dataFinal.map((data) => {
                    return <div key={data.FundCode} style={{
                        display: 'flex', flexDirection: "row", justifyContent: "space-between",
                        borderBottomColor: 'var(--lowestRisk)',
                        borderBottomWidth: 1,
                        paddingRight: 0,
                        paddingLeft: "16px", paddingRight: "16px", paddingTop: "12px", paddingBottom: "12px"
                    }}>
                        <div key={data.FundCode} style={{
                            display: 'flex', flexDirection: "row", flex: 3,
                        }}>
                            <AMCIcon type={type} amcName={data.AMCName} />
                            <div style={{ maxWidth: "60%", marginLeft: "16px", marginRight: "16px" }}>
                                <div numberOfLines={2} style={{}} >{data.ETFName}</div>
                                {/* {type !== "portfolio" ? null : <div style={{  }}>{'Balance: ' + CurrencyFormat({
                                    num: data.CurrentBalance
                                })}</div>} */}
                            </div>
                        </div>
                        <div style={{ flex: 1, textAlign: "center" }}>{Number(data.CurrentWeightage).toFixed(1)}%</div>
                    </div>
                })}
            </div>
            : null
    )


    const CreateData3 = (props) => (
        props.dataFinal.length > 0 ?
            <div key={props.classType}>
                <div style={{
                    display: 'flex', flexDirection: "row", justifyContent: "space-between", borderBottomColor: 'var(--lowestRisk)', paddingLeft: "16px", paddingRight: "16px", marginBottom: 2, marginTop: type === "small" ? 12 : 16
                }}>
                    <div style={{ color: finalbg2[props.classType].color, flex: 5 }}>{finalbg2[props.classType].name}</div>
                    <div style={{ color: finalbg2[props.classType].color, textAlign: "right", flex: 1 }}>{(props.dataFinal.map(item => item.weights || 0).reduce((prev, next) => prev + next)).toFixed(1) * 100}%</div>
                </div>

                {screen === "only-assets" ? null : props.dataFinal.map((data) => {
                    if ((data.weights === 0 || data.weights === undefined)) {
                        return null
                    } else {
                        return <div onClick={() => {
                            data.onClick(data)
                        }} key={data.isin_code} style={{
                            display: 'flex', flexDirection: "row", justifyContent: "space-between",
                            borderBottomColor: 'var(--lowestRisk)',
                            borderBottomWidth: 1,
                            paddingRight: 0,
                            paddingLeft: "16px", paddingRight: "16px", paddingTop: "12px", paddingBottom: "12px"
                        }}>
                            <div key={data.isin_code} style={{
                                display: 'flex', flexDirection: "row", flex: 3,
                            }}>
                                <AMCIcon type={type} amcName={data.amc_full_name} />
                                <div style={{ maxWidth: "60%", marginLeft: "16px", marginRight: "16px" }}>
                                    <div numberOfLines={2} style={{}} >{data.basic_name}</div>
                                    <div style={{}}>{data.category_name}</div>
                                </div>
                            </div>
                            <div style={{ flex: 1, justifyContent: "space-between" }}>
                                <div style={{ textAlign: "right" }}>{Number(data.weights * 100).toFixed(1)}%</div>
                                {showNextArrow ? <div style={{ fontSize: 10, textAlign: "right", marginBottom: 2, color: 'var(--secondary)' }}>View details</div> : null}
                            </div>
                        </div>
                    }
                })}
            </div>
            : null
    )


    return (
        <div style={{ paddingBottom: 8 }}>
            {screen === "only-funds" ? <div style={{
                display: 'flex', flexDirection: "row", justifyContent: "space-between", paddingBottom: 4, marginTop: 10, paddingLeft: "16px", paddingRight: "16px"
            }}>
                <div style={{ color: "#6C6C6C" }}>Constituents</div>
                <div style={{ color: "#6C6C6C" }}>Allocation{type !== "portfolio" ? "*" : ""}</div>
            </div> : screen === "only-assets" ? <div style={{
                display: 'flex', flexDirection: "row", justifyContent: "space-between", paddingBottom: 4, marginTop: 10, paddingLeft: "16px", paddingRight: "16px"
            }}>
                <div style={{ color: "#6C6C6C" }}>Asset class</div>
                <div style={{ color: "#6C6C6C" }}>Allocation{type !== "portfolio" ? "*" : ""}</div>
            </div> :
                <div style={{
                    display: 'flex', flexDirection: "row", justifyContent: "space-between", paddingBottom: 4, marginTop: 10, paddingLeft: "16px", paddingRight: "16px"
                }}>
                    <div style={{ color: "#6C6C6C" }}>Asset class/ETF</div>
                    <div style={{ color: "#6C6C6C" }}>Allocation{type !== "portfolio" ? "*" : ""}</div>
                </div>
            }



            {isPortfolioView ? <>
                <CreateData3 screen={screen} classType={"Equity"} dataFinal={data.filter((item) => item.primary_category_name === "Equity")} />
                <CreateData3 screen={screen} classType={"Debt"} dataFinal={data.filter((item) => item.primary_category_name === "Debt")} />
                <CreateData3 screen={screen} classType={"Commodities"} dataFinal={data.filter((item) => item.primary_category_name === "Commodities")} />
                <CreateData3 screen={screen} classType={"Hybrid"} dataFinal={data.filter((item) => item.primary_category_name === "Hybrid")} />
                <CreateData3 screen={screen} classType={"Others"} dataFinal={data.filter((item) => item.primary_category_name === "Others")} />
            </> : screen === "only-funds" ? <>
                <CreateData2 classType={1} dataFinal={data.filter((item) => item.AssetClassId === 1)} />
                <CreateData2 classType={2} dataFinal={data.filter((item) => item.AssetClassId === 2)} />
                <CreateData2 classType={3} dataFinal={data.filter((item) => item.AssetClassId === 3)} />
                <CreateData2 classType={4} dataFinal={data.filter((item) => item.AssetClassId === 4)} />
                <CreateData2 classType={6} dataFinal={data.filter((item) => item.AssetClassId === 6)} />
                {/* <CreateData2 classType={5} dataFinal={data.filter((item) => item.AssetClassId === 5)} /> */}
            </> : screen === "only-assets" ? <>
                <CreateData1 classType={1} dataFinal={data.filter((item) => item.AssetClassId === 1)} />
                <CreateData1 classType={2} dataFinal={data.filter((item) => item.AssetClassId === 2)} />
                <CreateData1 classType={3} dataFinal={data.filter((item) => item.AssetClassId === 3)} />
                <CreateData1 classType={4} dataFinal={data.filter((item) => item.AssetClassId === 4)} />
                <CreateData1 classType={6} dataFinal={data.filter((item) => item.AssetClassId === 6)} />
                {/* <CreateData1 classType={5} dataFinal={data.filter((item) => item.AssetClassId === 5)} /> */}
            </> : <>
                <CreateData classType={1} dataFinal={data.filter((item) => item.AssetClassId === 1)} />
                <CreateData classType={2} dataFinal={data.filter((item) => item.AssetClassId === 2)} />
                <CreateData classType={3} dataFinal={data.filter((item) => item.AssetClassId === 3)} />
                <CreateData classType={4} dataFinal={data.filter((item) => item.AssetClassId === 4)} />
                <CreateData classType={6} dataFinal={data.filter((item) => item.AssetClassId === 6)} />
                {/* <CreateData classType={5} dataFinal={data.filter((item) => item.AssetClassId === 5)} /> */}
            </>}



            {type === "portfolio" || content === "no" ? null : <div style={{ marginTop: 8, paddingLeft: "16px", paddingRight: "16px" }}>{content || "*Allocation will change in the future to adjust portfolio risk as per our proprietary investment logic."}</div>}
        </div>
    );
};

export default FundConstituents;
