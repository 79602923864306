/* eslint-disable import/no-anonymous-default-export */
import db from "Libs/db";
import AdminServicesPY from "./AdminServicesPY";

const apiHelperPY = async ({ apiName, data = {}, getFresh = false, saveResponse = '' }) => {
    if (getFresh) {
        const res = await AdminServicesPY[apiName](data);
        if (res.type) {
            if (saveResponse === 'localStorage') {
                try {
                    await db.set(apiName + JSON.stringify(data), JSON.stringify(res.data))
                } catch {
                    console.log("storage full")
                }
            } else if (saveResponse === 'sessionStorage') {
                try {
                    sessionStorage.setItem(apiName + JSON.stringify(data), JSON.stringify(res.data))
                } catch {
                    console.log("storage full")
                }
            }
            return res.data
        }
        else {
            return null
        }
    } else if (saveResponse === 'localStorage') {
        return await db.get(apiName + JSON.stringify(data)).then(async (p) => {
            if (p === null) {
                const res = await AdminServicesPY[apiName](data);
                if (res.type) {
                    try {
                        await db.set(apiName + JSON.stringify(data), JSON.stringify(res.data))
                    } catch {
                        console.log("storage full")
                    }
                    return res.data
                }
                else {
                    return null
                }
            }
            else return p
        })
    } else if (saveResponse === 'sessionStorage') {
        const p = sessionStorage.getItem(apiName + JSON.stringify(data))
        if (p === null) {
            const res = await AdminServicesPY[apiName](data);
            if (res.type) {
                try {
                    sessionStorage.setItem(apiName + JSON.stringify(data), JSON.stringify(res.data))
                } catch {
                    console.log("storage full")
                }
                return res.data
            }
            else {
                return null
            }
        }
        else {
            return JSON.parse(p)
        }
    } else {
        const res = await AdminServicesPY[apiName](data);
        if (res.type) {
            return res.data
        }
        else {
            return null
        }
    }
}
export default apiHelperPY;
