import React from 'react'
import Lottie from 'react-lottie';
import animationData from 'Assets/lottie/MintboxLoader.json'
import { Card, Spin } from 'antd';

const ActivityLoaderMintbox = ({ type = '', margin = '0%' }) => {

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <div style={{ marginTop: margin, marginBottom: margin, textAlign: 'center' }}>
      <Lottie options={defaultOptions}
        height={"10rem"}
        width={"10rem"}
      />
      {/* <Spin size='large' /> */}
      {
        type === 'goal' ?
          <div style={{ fontSize: '24px', fontWeight: '500', marginTop: '16px', textAlign: 'center' }}>Building your plan</div>
          :
          <></>
      }
    </div>
  )
}

export default React.memo(ActivityLoaderMintbox)