import { Button, Card, Input, Tabs } from "antd"
import React from "react";
import { toast } from "react-toastify";
import AdminServices from "Services/api/AdminServices";
import executeSmallCaseTransaction from "./ExecuteOrder";
import CurrencyInput from 'react-currency-input-field';
import ActivityLoaderMintbox from "./ActivityLoaderMintbox";

const InvestmentCard = ({ SCREENTYPE = 1, FUNDDATA }) => {
    const [ltp, setLtp] = React.useState(35);

    const [trxnLoading, setTrxnLoading] = React.useState(false);

    const getFundQunatities = async (etfs, constituents, amount) => {
        var etfsData = await AdminServices.getETFsById(etfs.toString()).then(res => res)
        console.log(etfsData)
        if (etfsData.type) {
            console.log(etfsData)
            const weight = {};
            constituents.map((item) => {
                weight[item.Symbol] = item.CurrentWeightage / 100
            })
            const currentNav = {};
            etfsData.data.map(item => {
                currentNav[item.Symbol] = item.NAV
            })
            var dataFinal = {
                "weights": weight,
                "currentNav": currentNav,
                "investment": amount
            }
            console.log(JSON.stringify(dataFinal))
            var finalDATA = await AdminServices.getETFQuantities(JSON.stringify(dataFinal)).then(res => res)
            if (finalDATA.type) {
                var quant = finalDATA.data.Result
                quant = JSON.parse(quant).data
                console.log(finalDATA)
                console.log(quant)
                return quant
            } else {
                setTrxnLoading(false);
                toast.error('Error')
            }
        } else {
            setTrxnLoading(false);
            toast.error('Error')
        }

    }

    const createOrder = async (amount, isSIP, sipDate) => {
        console.log("crt ordr", amount, "isSip", isSIP, "sip date", sipDate)
        setTrxnLoading(true);
        if (SCREENTYPE === 1) {
            var constituents = [{
                "Symbol": FUNDDATA.FundCode,
                "CurrentWeightage": 100
            }]
            console.log("get fund quantities")
            // var orderQuant = await getFundQunatities([FUNDDATA.FundCode], constituents, amount)
            // const securities = Object.entries(orderQuant).map((e) => ({ "ticker": e[0], "quantity": e[1] + "", type: "BUY" }));
            // console.log(securities)
            const securities = [{ quantity: "1", ticker: "IDFCFIRSTB", type: "BUY" }]
            let res = await executeSmallCaseTransaction({
                // navigation: props.history,
                securities: securities,
                amount: amount,
                isSIP: isSIP,
                isUnitsBased: false,
                units: 0,
                sipDate: sipDate || 0,
                SCREENTYPE: 1,
                FUNDDATA: FUNDDATA,
                isInvest: true
            })
            console.log(res)
            if (res === 'market_closed') {
                toast.error('MARKET CLOSED')
            }
            setTrxnLoading(false)
        } else if (SCREENTYPE === 3) {
            var etfs = FUNDDATA.Constituents.map(etf => etf.Symbol)
            var orderQuant = await getFundQunatities(etfs, FUNDDATA.Constituents, amount)
            const securities = Object.entries(orderQuant).map((e) => ({ "ticker": e[0], "quantity": e[1] + "", type: "BUY" }));
            console.log(securities)
            let res = await executeSmallCaseTransaction({
                // navigation: props.history,
                securities: securities,
                amount: amount,
                isSIP: isSIP,
                isUnitsBased: false,
                units: 0,
                sipDate: sipDate,
                SCREENTYPE: 3,
                FUNDDATA: FUNDDATA,
                isInvest: true
            })
            setTrxnLoading(false)
            console.log(res)
            if (res === 'market_closed') {
                toast.error('MARKET CLOSED')
            }
        } else if (SCREENTYPE === 2) {
            toast.error("MF investments are paused")
        }
    }
    const OneTime = () => {
        const [oneTimeAmount, setOneTimeAmount] = React.useState();

        return <>
            <div className="mt-4" style={{ height: '20rem', display: 'flex', gap: '6rem', flexDirection: 'column', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', gap: '1rem', flexDirection: 'column', justifyContent: 'space-between' }}>
                    <CurrencyInput
                        className='normalInput rupee'
                        id="oneTimeAmount"
                        name="oneTimeAmount"
                        placeholder="10,000"
                        value={oneTimeAmount}
                        allowDecimals={false}
                        allowNegativeValue={false}
                        disableAbbreviations={false}
                        prefix=' '
                        onValueChange={(value) => setOneTimeAmount(value)}
                        intlConfig={{ locale: 'en-IN', currency: 'INR' }}
                    />
                    <Input addonBefore="LTP" value={ltp} disabled />
                </div>
                <div style={{ display: 'flex', gap: '1rem', flexDirection: 'column', justifyContent: 'space-between' }}>
                    <button className="btnPrimary" block style={{ color: 'var(--primary)' }} onClick={() => {
                        if (Number(oneTimeAmount) >= ltp) {
                            createOrder(Number(oneTimeAmount), false)
                        } else {
                            toast.error("Min investment amount is " + ltp)
                        }
                    }}>Buy</button>
                </div>
            </div>

        </>
    }

    const Sip = () => {
        const [sipAmount, setSipAmount] = React.useState();
        const [sipDate, setSipDate] = React.useState(new Date().getDate());

        return <>
            <div className="mt-4" style={{ height: '20rem', display: 'flex', gap: '6rem', flexDirection: 'column', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', gap: '1rem', flexDirection: 'column', justifyContent: 'space-between' }}>
                    <CurrencyInput
                        className='normalInput rupee'
                        id="sipAmount"
                        name="sipAmount"
                        placeholder="10,000"
                        value={sipAmount}
                        allowDecimals={false}
                        allowNegativeValue={false}
                        disableAbbreviations={false}
                        prefix=' '
                        onValueChange={(value) => setSipAmount(value)}
                        intlConfig={{ locale: 'en-IN', currency: 'INR' }}
                    />
                    <Input addonBefore="LTP" value={ltp} disabled />
                    <Input addonBefore="Date" value={sipDate} onChange={(e) => setSipDate(e.target.value)} />
                </div>
                <div style={{ display: 'flex', gap: '1rem', flexDirection: 'column', justifyContent: 'space-between' }}>
                    <button className="btnPrimary" block style={{ color: 'var(--primary)' }} onClick={() => {
                        if (Number(sipAmount) >= ltp && sipDate > 0 && sipDate < 29) {
                            createOrder(Number(sipAmount), true, sipDate)
                        } else {
                            toast.error("Min investment amount is " + ltp)
                        }
                    }}>Invest</button>
                </div>
            </div>
        </>
    }
    const options = [
        {
            label: 'One time',
            key: 'one',
            children: <OneTime />,
        },
        {
            label: 'Sip',
            key: 'sip',
            children: <Sip />,
        }
    ]
    return <Card bordered={false} >
        {
            trxnLoading ?
                <ActivityLoaderMintbox />
                :

                <Tabs className="textSM scoreboardTabs" tabBarStyle={{ fontSize: '10px' }} size="small" items={options} />
        }
    </Card>
}

export default InvestmentCard