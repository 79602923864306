import React from 'react';

export const Checkbox = ({ children, checked, onChange }) => {
    return (
        <React.Fragment>
            <div className='customCheckbox'>
                <input type='checkbox' className='customCheckboxInput' checked={checked} onChange={onChange} />
                <span className='customCheckmark'></span>
                <span className='customCheckboxLabel'>{children}</span>
            </div>
        </React.Fragment>
    )
}