import ActivityLoaderMintbox from "Components/ActivityLoaderMintbox";
import { getKnowledgeBaseData, getNextPrevBlog } from "Components/getKnowledgeBaseData";
import Head from "Layout/Head";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import AdminServices from "Services/api/AdminServices";
import { FcNext, FcPrevious } from 'react-icons/fc';
import { formatDate } from "Libs/date";
import ResourcesSidebar from "Components/ResourceSidebar";
import ResourceSidebar2 from "Components/ResourceSidebar2";
import { Card, Divider } from "antd";
import { Link } from "react-router-dom";
import { CardHeader } from "Components/atoms/SmallComponents";

const Resource = () => {
    const params = useParams();
    const [loading, setLoading] = useState(true);
    const [category, setCategory] = useState({});
    const [subCategory, setSubCategory] = useState({});
    const [blog, setBlog] = useState({});
    const [content, setContent] = useState([]);
    const [prevNext, setPrevNext] = useState({});

    const loadContent = async () => {
        let res = await AdminServices.readBlogFile(params.blog);
        if (res.type) {
            setContent(res.data);
        }
        else setContent('');
    }
    const loadData = async () => {
        let res = await getKnowledgeBaseData();
        if (res.type) {
            setCategory(res.categories?.filter(item => item?.Category?.toLowerCase().split(' ').join('_') === params.category)?.[0] || {});
            setSubCategory(res.subCategories?.filter(item => item?.Category?.toLowerCase().split(' ').join('_') === params.category &&
                item?.SubCategory.toLowerCase().split(' ').join('_') === params.subCategory)?.[0] || {});
            setBlog(res.blogs?.filter(item => item?.Category?.toLowerCase().split(' ').join('_') === params.category &&
                item?.SubCategory.toLowerCase().split(' ').join('_') === (params.subCategory || '') &&
                item?.URL.split('/')?.[2] === params.blog)?.[0] || {})
        }
        await loadContent();
        let prne = await getNextPrevBlog(params.blog);
        setPrevNext(prne);
        setLoading(false);
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        setLoading(true);
        loadData();
    }, [params]);

    useEffect(() => {
        if (!loading && content) document.getElementById('resource-container').innerHTML = content;
    }, [loading, content])

    return (
        <React.Fragment>
            <Head title={blog.Title || "Loading"} />
            <div className="mainContainer" style={{ height: "100%" }}>
                {/* <ResourcesSidebar /> */}
                <ResourceSidebar2 />
                {loading ?
                    <div className='d-flex justify-content-center flex-grow-1'>
                        <ActivityLoaderMintbox />
                    </div>
                    :
                    <div className="leftContainer">
                        <Card bordered={false} title={
                            <div>
                                <CardHeader heading={<span className='textXL'>{blog.Title}</span>} />
                                <div style={{ color: "var(--dark3)", marginLeft: "0.9rem" }} className='w-500 textSM'>
                                    by <span style={{ color: "var(--black)" }}>{blog.Author}</span> {formatDate(blog?.CreatedOn)}
                                </div>
                            </div>
                        } bodyStyle={{ padding: "2rem" }}>
                            <div id='resource-container' className="ql-editor textLG" style={{ padding: '0px', height: "auto" }}>
                            </div>
                        </Card>
                        <Card bordered={false} bodyStyle={{ padding: "2rem" }}>
                            <div className='d-flex align-items-center justify-content-between prevNextCont'>
                                {prevNext?.prev && (
                                    <Link to={prevNext?.prev?.Slug} style={{ textAlign: "left" }}>
                                        <div >
                                            <FcPrevious size='0.8rem' /> <span className='w-500 textXS'>PREVIOUS ARTICLE</span>
                                        </div>
                                        <div className='textXL w-700'>
                                            {prevNext?.prev?.Title}
                                        </div>
                                    </Link>
                                )}
                                {prevNext.next && (
                                    <Link to={prevNext?.next?.Slug} style={{ textAlign: "right" }}>
                                        <div>
                                            <span className='w-500 textXS'>NEXT ARTICLE</span> <FcNext size='0.8rem' />
                                        </div>
                                        <div className='textXL w-700'>
                                            {prevNext?.next?.Title}
                                        </div>
                                    </Link>
                                )}
                            </div>
                        </Card>
                    </div>
                }
            </div>
        </React.Fragment>
    )
}

export default Resource;