import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React from 'react';
import { changeObjKey } from './DataCustomizer';

const PerformanceChart3 = ({ chartData = [], benchName = "", fundName = "", portfolioType = 0, xAxisKey = "Date", yAxisKeys = [], chartType = 'spline' }) => {
    const fundColors = ['#4284f3', '#fabc05', '#24c1e0', '#fa7b16', '#b74447', '#333', 'purple', 'violet', 'red', 'darkgreen']
    const [noChart, setnoChart] = React.useState(false);

    var a = yAxisKeys.length === 0 ? Object.keys(JSON.parse(chartData)[0])?.filter((i) => i.toLowerCase() !== xAxisKey.toLowerCase()) : yAxisKeys

    try {
        var options = {
            chart: {
                type: chartType,
                backgroundColor: "transparent",
                height: 150,
            },
            title: {
                text: ''
            },
            exporting: {
                enabled: false
            },
            yAxis: {
                visible: false,
                tickAmount: 5,
                gridLineDashStyle: 'longdash',
                title: {
                    text: ''
                },
                gridLineColor: 'var(--border)',
            },
            xAxis: [{
                type: 'datetime',
                dateTimeLabelFormats: {
                    millisecond: '%H:%M:%S.%L',
                    second: '%H:%M:%S',
                    minute: '%H:%M',
                    hour: '%H:%M',
                    day: '%eth %b',
                    week: '%eth %b',
                    month: '%b \'%y',
                    year: '%Y'
                },
                lineColor: 'var(--border)',
                tickColor: 'var(--border)',
            }
            ],
            legend: {
                enabled: false
            },
            plotOptions: {
                series: {
                    label: {
                        connectorAllowed: false
                    },
                    marker: {
                        enabled: false
                    }
                }
            },
            tooltip: {
                shared: true,
                valueDecimals: 2,
                // valuePrefix: '₹',
            },
            credits: {
                enabled: false
            },
            boost: {
                useGPUTranslations: true,
            },
            series: a.map((item, index) => {
                return {
                    name: item === "Benchmark" ? benchName || item : fundName || item,
                    turboThreshold: 9e9,
                    color: 'var(--secondary)',
                    data: changeObjKey(chartData, [String(item), xAxisKey], ["y", "x"], "stockhistchart", 1)
                }
            }),
            scrollbar: {
                enabled: false
            },
        };
    } catch (error) {
        setnoChart(true)
    }




    try {
        return (
            noChart ? <div className='textXS' style={{ margin: "4rem", textAlign: "center" }}>
                No chart data available!
            </div>
                : <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', gap: '1rem', }}>

                    <HighchartsReact highcharts={Highcharts} options={options} />
                </div>
        );
    } catch (error) {
        console.log(error)
        return <div className='textSM' style={{ margin: "4%", textAlign: "center" }}>
            No chart data available!
        </div>
    }
};
export default PerformanceChart3;