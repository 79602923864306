import { Badge, Button, Card, Checkbox, Drawer, Input, Popover, Select, Table } from "antd";
import Highcharts from 'highcharts';
import HighchartsReact from "highcharts-react-official";
import Head from "Layout/Head";
import { mf_etf_categories, unique, useGetAssetType } from "Libs/utils";
import React, { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import * as ReactDOM from "react-dom";
import { AiOutlineClose } from "react-icons/ai";
import { BsArrowRightShort, BsFilter, BsTable } from "react-icons/bs";
import { HiLockClosed } from "react-icons/hi";
import { MdArrowDropDown, MdBubbleChart, MdCancel, MdDragIndicator } from "react-icons/md";
import { useNavigate } from "react-router";
import { Col, Row } from "reactstrap";
import { changeObjKey } from "./DataCustomizer";

require('highcharts/highcharts-more')(Highcharts)

const CustomListTable = ({ finalList = [], tableType = 0, tableHeader = "", screenerFinalData = [], initialColumns = [], otherCol = [], isPagination = false, paginationSize = 50, is_Open = 0,
    is_V1Open = 0,
    is_V2Open = 0, investInScreener = () => { }, defFilters = [], applicableFilters = [], hideCheckBox = false, lockInitialColumns = false, disableInvestBtn = false, encryptedBoxType = 1, showCategoryDropDown = false }) => {

    // tableType => 1 => screener , 0 => normal without filters icon but filters applied if passed , 2 => with filters, 3=>signal
    const navigate = useNavigate()
    const type = useGetAssetType();
    const [stockSectorCLF, setStockSectorCLF] = useState([])
    const [displayData, setDisplayData] = useState(finalList);
    const [tablesm, updateTableSm] = useState(false);
    const [isOpen, setisOpen] = useState(is_Open)
    const [isV1Open, setisV1Open] = useState(is_V1Open)
    const [isV2Open, setisV2Open] = useState(is_V2Open)
    const [searchText, setSearchText] = useState("");
    const [appliedFilters, setAppliedFilters] = useState({})
    const [modal, setmodal] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    const [itemPerPage, setItemPerPage] = useState(paginationSize);
    const [dataTableColumns, setDataTableColumns] = useState(initialColumns);
    const [pageData, setPageData] = useState({ page: 1, pageSize: paginationSize })
    const [chartFunds, setChartFunds] = useState([])
    const [magic_categoryType, setmagic_categoryType] = useState('Equity')
    const [selectedRows, setSelectedRows] = React.useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = React.useState([]);
    const [showBubbleChart, setShowBubbleChart] = useState(false);
    const [bubbleChartType, setbubbleChartType] = useState(1);

    const portal = document.createElement("div");
    document.body.appendChild(portal);

    // Get current list, pagination
    const indexOfLastItem = currentPage * itemPerPage;
    const indexOfFirstItem = indexOfLastItem - itemPerPage;
    const currentItems = displayData.slice(indexOfFirstItem, indexOfLastItem);

    var chartKeys = bubbleChartType === 1 ? ['sh_qual_comp', 'sh_val_comp', "proper_name"] : bubbleChartType === 2 ? ['sh_mom_comp', 'sh_val_comp', "proper_name"] : ['sh_mom_comp', 'sh_qual_comp', "proper_name"]
    var chartAxisNames = bubbleChartType === 1 ? ['sharpely Value Score', 'sharpely Quality Score'] : bubbleChartType === 2 ? ['sharpely Value Score', 'sharpely Momentum Score'] : ['sharpely Quality Score', 'sharpely Momentum Score']

    React.useEffect(() => {
        //console.log("hello brother", dataTableColumns[0], initialColumns)
        setDataTableColumns(unique(dataTableColumns.concat(initialColumns), "dataIndex"))
    }, [initialColumns])

    React.useEffect(() => {
        //console.log("sdffasadadssda")
        if (otherCol.length > 0) {
            setDataTableColumns(unique(dataTableColumns.concat(initialColumns).concat(otherCol), "dataIndex"))
        }
    }, [otherCol])
    // Change Page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const rowSelection = {
        selectedRowKeys: selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            if (selectedRowKeys.length > 4) {
                return
            }
            setSelectedRowKeys(selectedRowKeys);
            setSelectedRows(selectedRows);
            //console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        },
        getCheckboxProps: (record) => ({
            disabled: record.name === 'Disabled User', // Column configuration not to be checked
        }),
        hideSelectAll: true,
    };

    const onChangeFilter = ({ filter, value, checked }) => {

        if (checked) {
            if (appliedFilters[filter]) {
                setAppliedFilters({ ...appliedFilters, [filter]: [...appliedFilters[filter], value] })
            } else {
                setAppliedFilters({ ...appliedFilters, [filter]: [value] })
            }
        } else {
            setAppliedFilters({ ...appliedFilters, [filter]: appliedFilters[filter]?.filter(a => a !== value) || [] })
        }
    }


    const getChartFunds = (pageNum, pageSize) => {
        console.log(finalList)
        return [...finalList].slice(pageSize * (pageNum - 1), pageSize * pageNum)
    }

    // React.useEffect(() => {
    //     //console.log("sdffas")
    //     setChartFunds(getChartFunds(pageData.page, pageData.pageSize))
    // }, [pageData])

    React.useEffect(() => {
        //console.log("sdffasoye")
        var fundList = finalList
        if (type)
            if (searchText !== "") {
                fundList = fundList.filter((item) => {
                    return item[type.value === 4 ? 'proper_name' : 'basic_name'].toLowerCase().includes(searchText.toLowerCase());
                });
            }

        if (defFilters.length > 0) {
            defFilters.forEach(item => {
                //console.log(item)
                fundList = fundList.filter((fund) => {
                    //console.log(String(fund[String(item[0])]) === String(item[1]))
                    return String(fund[String(item[0])]) === String(item[1])
                })
            });
        }

        setDisplayData(fundList);
    }, [searchText, finalList, defFilters])

    const allfilters = type.value === 4 ?
        [
            {
                key: "market_cap",
                name: "Market Cap",
                filters: [
                    {
                        key: "Nifty 50",
                        label: "Nifty 50",
                    },
                    {
                        key: "Nifty 100",
                        label: "Nifty 100",
                    },
                    {
                        key: "Nifty 500",
                        label: "Nifty 500",
                    },
                    {
                        key: "Nifty Midcap 100",
                        label: "Nifty Midcap 100",
                    },
                    {
                        key: "Nifty Smallcap 100",
                        label: "Nifty Smallcap 100",
                    }
                ]
            },
            {
                key: "macro_sec_code",
                name: "Sectors",
                filters: [
                    {
                        key: "Financial Services",
                        label: "Financial Services",
                    },
                    {
                        key: "IT",
                        label: "IT",
                    },
                    {
                        key: "Healthcare",
                        label: "Healthcare",
                    },
                    {
                        key: "FMCG",
                        label: "FMCG",
                    },
                    {
                        key: "Commodities",
                        label: "Commodities",
                    },
                    {
                        key: "Energy",
                        label: "Energy",
                    },
                ]
            },
        ]
        : [
            {
                key: "primary_category_name",
                name: "Category",
                filters: [
                    {
                        key: "Equity",
                        label: "Equity"
                    },
                    {
                        key: "Debt",
                        label: "Debt",
                    },
                    {
                        key: "Commodities",
                        label: "Commodities"
                    },
                    {
                        key: "Hybrid",
                        label: "Hybrid"
                    },
                ]
            },
            {
                key: "risk_id",
                name: "Risk level",
                filters: [
                    {
                        key: "Low risk",
                        label: "Low risk",
                    },
                    {
                        key: "Low to moderate risk",
                        label: "Low to moderate risk",
                    },
                    {
                        key: "Moderate risk",
                        label: "Moderate risk",
                    },
                    {
                        key: "Moderately high risk",
                        label: "Moderately high risk",
                    },
                    {
                        key: "High risk",
                        label: "High risk",
                    },
                    {
                        key: "Very high risk",
                        label: "Very high risk",
                    },
                ]
            },
            {
                key: "popular",
                name: "Popular filters",
                filters: [
                    {
                        key: "Consistent performers",
                        label: "Consistent performers",
                    },
                    {
                        key: "Positive alpha funds",
                        label: "Positive alpha funds",
                    },
                    {
                        key: "Recent out performers",
                        label: "Recent out performers",
                    },
                    {
                        key: "5-year out perfromers",
                        label: "5-year out perfromers",
                    },
                    {
                        key: "Nifty 50 index funds",
                        label: "Nifty 50 index funds",
                    },
                    {
                        key: "International Equity FOFs",
                        label: "International Equity FOFs",
                    },
                ]
            }
        ]


    const dropdownContent = () => {
        return <>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {
                    allfilters.map(section => {
                        return <div style={{ flex: 1 }} key={section.key}>
                            <div className="textSM">{section.name}</div>
                            {
                                section.filters.map((f, ind) => {
                                    return <div key={ind}>
                                        <Checkbox
                                            id={f.key}
                                            checked={appliedFilters[section.key]?.some(fil => fil === f.key)}
                                            onChange={(e) => { e.preventDefault(); onChangeFilter({ filter: section.key, value: f.key, checked: e.target.checked }) }}
                                        >
                                            <span className="textSM">{f.label}</span>
                                        </Checkbox><br />
                                    </div>
                                })
                            }
                        </div>
                    })
                }
            </div>
            <div className="dropdown-foot between">
                <a
                    href="#reset"
                    onClick={(ev) => {
                        ev.preventDefault();
                        setAppliedFilters({})
                    }}
                    className="clickable"
                >
                    Reset Filters
                </a>
            </div>
        </>
    }
    const TableHeader = () => {
        return <>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '0.5rem' }}>
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '0.5rem' }}>
                    {
                        Object.keys(appliedFilters).map(fil => {
                            return appliedFilters[fil].map((a, ind) => {
                                return <button key={ind} size="sm"
                                    onClick={() => { onChangeFilter({ filter: fil, value: a, checked: false }) }}
                                >{a} <AiOutlineClose className="ms-2" /></button>
                            })
                        })
                    }
                </div>
                <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                    <Input
                        type="text"
                        className=""
                        placeholder="Search by name"
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        autoFocus
                        style={{ width: '300px' }}
                    />
                    <Popover placement="bottomRight" title={'Filter funds'} content={dropdownContent} trigger="click">
                        <Badge dot={Object.keys(appliedFilters).some(f => appliedFilters[f].length)} color="#333" offset={[-5, 5]}>
                            <button type="text" shape="circle" size="large" icon={<BsFilter size={20} />}>
                            </button>
                        </Badge>
                    </Popover>
                </div>
            </div>
            {/* <span className='fontH2'>showing <span className='fontH1'>{displayData.length}</span> out of {finalList.length} funds</span> */}
        </>
    }

    const onOrderChange = (items) => {
        setDataTableColumns(items);
    };

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    // ---------------------------------------------------------------------------
    // Item

    function PortalAwareItem({ provided, snapshot, item, index }) {
        const usePortal = snapshot.isDragging;

        const child = (
            <div
                className="mb-1"
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
            >
                <div style={{ display: "flex", flexDirection: "row", border: "0.9px solid #e5e5e5", borderRadius: 5 }}>
                    <div style={{ backgroundColor: "#252B3422", border: "0px solid #000B16", borderTopLeftRadius: 5, borderBottomLeftRadius: 5, display: "flex", justifyContent: "center", alignItems: "center", width: 30 }} >
                        <MdDragIndicator size={25} style={{ flex: 1 }} color="grey" />
                    </div>
                    <div className='py-2' style={{
                        display: "flex", flex: 1, alignItems: "center", justifyContent: 'normal'
                    }}>
                        <span className='textXS black mx-3' style={{ color: 'var(--black)' }}>{item.title}</span>
                    </div>
                    <div style={{ backgroundColor: "#252B3422", border: "0px solid #000B16", borderTopRightRadius: 5, borderBottomRightRadius: 5, display: "flex", justifyContent: "center", alignItems: "center", width: 30, cursor: "pointer" }} >
                        {item.disableDeleteInCustomize ? <HiLockClosed className='textXS black' style={{ flex: 1 }} color='var(--primary)' size={15} onClick={() => { }} /> :
                            <MdCancel className='textXS black' style={{ flex: 1 }} color='var(--red)' size={15} onClick={() => {
                                var a = [...dataTableColumns]
                                a.splice(index, 1)
                                setDataTableColumns(a)
                            }} />}


                    </div>
                </div>
            </div>
        );

        if (!usePortal) {
            return child;
        }

        // if dragging - put the item in a portal
        return ReactDOM.createPortal(child, portal);
    }

    // ---------------------------------------------------------------------------
    // List

    function VerticalList({ items, onChange }) {
        const onDragEnd = (result) => {
            if (!result.destination) return;
            onChange(reorder(items, result.source.index, result.destination.index));
        };
        return (
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="vertical-list">
                    {(droppableProvided, droppableSnapshot) => (
                        <div
                            ref={droppableProvided.innerRef}
                            {...droppableProvided.droppableProps}
                        >
                            {items.filter((i) => i.title !== "Name").map((item, index) => {
                                return <Draggable key={item.dataIndex} draggableId={item.dataIndex} index={index + 1}>
                                    {(draggableProvided, draggableSnapshot) => (
                                        <PortalAwareItem
                                            item={item}
                                            index={index + 1}//becoz Name ko remove kiya hai
                                            provided={draggableProvided}
                                            snapshot={draggableSnapshot}
                                        />
                                    )}
                                </Draggable>
                            })}
                            {droppableProvided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        );
    }

    const SelectView = ({ data, index }) => {
        // ////console.log({...data})
        var upDatedFilters = [...dataTableColumns]
        var currentMetricData = { ...data }





        return <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ backgroundColor: "#252B3422", border: "0px solid #000B16", borderTopLeftRadius: 5, borderBottomLeftRadius: 5, display: "flex", justifyContent: "center", alignItems: "center", width: 30 }} >
                <MdDragIndicator size={25} style={{ flex: 1 }} color="grey" />
            </div>
            <div className='py-2' style={{
                display: "flex", flex: 1, alignItems: "center", justifyContent: 'normal'
            }}>
                <span className='textXS black mx-3' style={{ color: 'var(--black)', width: "80px" }}>{currentMetricData.title}</span>
            </div>
            <div style={{ backgroundColor: "#252B3422", border: "0px solid #000B16", borderTopRightRadius: 5, borderBottomRightRadius: 5, display: "flex", justifyContent: "center", alignItems: "center", width: 30, cursor: "pointer" }} >
                {currentMetricData.disableDeleteInCustomize ? <HiLockClosed className='textXS black' style={{ flex: 1 }} color='var(--primary)' size={15} onClick={() => { }} /> :
                    <MdCancel className='textXS black' style={{ flex: 1 }} color='var(--red)' size={15} onClick={() => {
                        var a = [...dataTableColumns]
                        a.splice(index, 1)
                        setDataTableColumns(a)
                    }} />}

            </div>

        </div>

    }

    const AppliedFilterView = () => (<div className='box_shadow' style={{
        display: "flex", flex: 1, flexDirection: "column", borderRadius: 5,
    }}>
        <div style={{ overflow: 'auto', maxHeight: '600px' }}>
            <VerticalList
                items={dataTableColumns}
                onChange={onOrderChange}
            />
        </div>
    </div >
    )

    const renderRow = ({ item, index }) => {
        return <div key={"a" + index} className={'p-2 mb-1 px-3'} style={{
            display: "flex", justifyContent: "space-between", alignItems: "center", backgroundColor: index === isOpen ? "var(--lightBlueBG)" : "transparent", borderRadius: 2, cursor: "pointer"
        }}
            onClick={() => {
                setisOpen(index)
                setisV1Open(0)
                setisV2Open(0)
            }}
        >
            <span style={{ flex: 1, fontWeight: "bold", color: index === isOpen ? "var(--primary)" : "var(--black)" }} className="textSM">{item.category_name}</span>
            <BsArrowRightShort />
        </div>
    };

    const RenderIntialFlatlist = ({ item, index }) => {
        return item?.sub_category.filter((ele) => ele.isVisibleNormally !== false).map((subCategory, subCategoryIndex) => {
            console.log(subCategory)
            if (subCategory.isVisibleForCustomise) {
                return <div className='mb-3 ' key={"asd" + subCategoryIndex} style={{ borderRadius: 2 }}>
                    <div
                        style={{ padding: "8px", paddingLeft: "15px", display: "flex", alignItems: "center", flexDirection: "row", backgroundColor: "var(--lightBlueBG)", borderTopLeftRadius: 2, borderTopRightRadius: 2, }}
                    >
                        <span className="textXS black" style={{ fontWeight: "bold", flex: 1 }}> {subCategory?.sub_category_name}</span>
                    </div>

                    {subCategory.metric.map((metric, metricIndex) => {

                        var upDatedFilters = [...dataTableColumns]
                        var isPresentIndex = upDatedFilters.findIndex((met) => met.dataIndex === metric.metric_col_code) !== -1
                        if (metric.hidden !== true) {
                            return <div key={"SDF" + metricIndex} style={{ borderBottom: "0.5px solid #e5e5e5", borderBottomLeftRadius: 2, borderBottomRightRadius: 2, width: "100%", display: "flex", flexDirection: "row", padding: "2px", backgroundColor: isV1Open + "$" + isV2Open === subCategoryIndex + "$" + metricIndex ? "#252B3411" : "transparent", cursor: isPresentIndex ? "not-allowed" : "pointer", opacity: isPresentIndex ? 0.4 : 1 }} className={`px-3`}
                                onClick={isPresentIndex ? () => { } : () => {

                                    setisV1Open(subCategoryIndex)
                                    setisV2Open(metricIndex)
                                }}
                            >
                                <div className='pl-1' style={{ flex: 1 }}>
                                    <div className='py-1' style={{ display: "flex", flexDirection: "row" }}>
                                        <div className='textXS' >{metric.metric_name}</div>
                                    </div>
                                </div>
                            </div>
                        } else {
                            return <div key={"DSF" + metricIndex}></div>
                        }
                    })}
                </div>
            } else {
                return <div key={"DSF" + subCategoryIndex}></div>
            }
        })
    }


    const BubbleChart = () => {

        var options = {
            chart: {
                type: 'bubble',
                height: "100%",
                plotBorderWidth: 0,
                plotBorderBottomWidth: 0,
                plotBorderColor: "#ccd6eb"
            },

            legend: {
                enabled: false
            },

            title: {
                text: ''
            },

            exporting: {
                enabled: false
            },
            credits: {
                enabled: false
            },
            xAxis: {
                tickLength: 0,
                lineWidth: 0,
                labels: { enabled: false },
                gridLineWidth: 0.5,
                title: {
                    text: chartAxisNames[0]
                },
                accessibility: {
                    rangeDescription: 'Range: 0 to 100.'
                }
            },

            yAxis: {
                visible: false,
                tickLength: 0,
                labels: { enabled: false },
                gridLineWidth: 0.5,
                startOnTick: false,
                endOnTick: false,
                title: {
                    text: chartAxisNames[1]
                },
                maxPadding: 0.2,

                accessibility: {
                    rangeDescription: 'Range: 0 to 100.'
                }
            },

            tooltip: {
                useHTML: true,
                headerFormat: '<table>',
                pointFormat: '<tr><th colspan="2"><h6>{point.name}</h6></th></tr>' +
                    '<tr><th>sharpely Value Score:</th><td>{point.x}</td></tr>' +
                    '<tr><th>sharpely Quality Score:</th><td>{point.y}</td></tr>',
                footerFormat: '</table>',
                followPointer: true
            },

            plotOptions: {
                bubble: {
                    minSize: 50,
                    maxSize: 55
                }
            },

            series: [{
                data: changeObjKey(getChartFunds(pageData.page, pageData.pageSize), chartKeys, ["y", "x", "name"], "normal").map((item) => {
                    return {
                        ...item,
                        color: item.y < 30 && item.x < 30 ? "#ef4221" : item.y > 70 && item.x > 70 ? "#58975e" : "#FFB70077"
                    }
                }),
            }]
        };
        return <HighchartsReact highcharts={Highcharts} options={options} />
    }

    return (
        <React.Fragment>
            <Head title={type.name} />
            <div className="my-2" style={{ display: "flex", flexDirection: "row", gap: "1.5rem" }}>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", flex: 1 }}>
                    {tableHeader}
                    <div>
                        {type.value !== 4 && showCategoryDropDown ? <Select
                            className='textSM black'
                            allowClear={false}
                            suffixIcon={<MdArrowDropDown size={17} />}
                            value={magic_categoryType}
                            style={{
                                width: "150px",
                                marginTop: 0
                            }}
                            placeholder="Please select a category"
                            onChange={(value) => {
                                // onValueChange(value)
                                setmagic_categoryType(value)
                            }}
                            options={mf_etf_categories}
                        /> : <></>}
                        <button disabled={showBubbleChart} className="mx-1 btnSecondary" onClick={() => setmodal(true)}>Customise</button>
                        {!(tableType === 1 || tableType === 3) && <button className="mx-1 btnPrimary" disabled={selectedRows.length < 2} onClick={() => navigate(type.basePath + '/compare', { state: { "stocks": selectedRows } })}>Compare</button>}
                        {(tableType === 1 || tableType === 3) && <button className="mx-1 btnPrimary" disabled={disableInvestBtn || (tableType !== 1 && (selectedRows.length < 2))} onClick={tableType === 1 ? () => { investInScreener() } : () => setmodal(true)}>{tableType === 1 ? "Invest in screen" : tableType === 3 ? "Invest in signal" : "Create strategy"}</button>}
                    </div>
                </div>
                {(tableType === 1 || tableType === 3) && type.value === 4 && <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <button className={!showBubbleChart ? "mx-1 btnPrimary" : "mx-1 btnSecondary"} onClick={() => setShowBubbleChart(!showBubbleChart)}>
                        <BsTable />
                    </button>
                    <button className={showBubbleChart ? "mx-1 btnPrimary" : "mx-1 btnSecondary"} onClick={() => setShowBubbleChart(!showBubbleChart)}>
                        <MdBubbleChart />
                    </button>
                </div>}
            </div>
            <Row>
                <Col style={{ position: "relative" }}>
                    <Table
                        rowSelection={showBubbleChart || hideCheckBox || tableType === 1 || tableType === 3 ? false : rowSelection}
                        pagination={{
                            defaultPageSize: paginationSize
                        }}
                        defaultSortOrder='descend'
                        style={{
                            // backgroundColor: 'var(--backgroundCard) !important'
                        }}
                        size='small'
                        className='textXS black'
                        dataSource={displayData}
                        columns={dataTableColumns.filter((i) => i.hidden !== true)}
                        rowKey={(record) => record.plan_id || record.symbol}
                        scroll={{ x: "max-content" }}
                    />
                    {/* {(tableType === 1 || tableType === 3) && <div style={{ position: "absolute", right: "20vw", top: "20vh", flex: 1, zIndex: 1001 }}>
                        <EncryptedBox type={encryptedBoxType} msg="To view scores upgrade your plan">
                            <Card bordered={false}  style={{ height: "30vh", width: "40vh", backgroundColor: "transparent" }} >
                            </Card>
                        </EncryptedBox>
                    </div>} */}
                </Col>
            </Row>
            <Drawer
                width={"80vw"}
                title={<span className='fontH1 black m-1 mb-5' style={{ fontWeight: "bold", zIndex: 100000 }}>Customise table</span>} placement="right"
                onClose={() => {
                    setmodal(!modal)
                }}
                open={modal}
                footerStyle={{}}
                footer={false}>
                <Row className='p-2 ' style={{ display: "flex", flex: 1, gap: "5px", height: "100%" }}>
                    <Col id='metricCol1' className="col-2 p-1" style={{ display: "flex" }}>
                        <Card bordered={false} style={{ display: "flex", flex: 1, height: "100%", flexDirection: "column" }} bodyStyle={{ padding: 0 }}>
                            {screenerFinalData.map((item, index) => {
                                if (item.isVisibleNormally === true) {
                                    return renderRow({
                                        item, index
                                    })
                                } else {
                                    return <div key={index}></div>
                                }
                            }
                            )}
                        </Card>
                    </Col>
                    <Col id='metricCol2' className="col-3 p-1" style={{}}>
                        <Card bordered={false} style={{ display: "flex", flex: 1, height: "100%", flexDirection: "column" }} bodyStyle={{ padding: 0, overflowY: "auto" }}>
                            <RenderIntialFlatlist item={screenerFinalData[isOpen]} index={isOpen} />
                        </Card>
                    </Col>
                    <Col id='metricCol3' className="p-1 " style={{}}>
                        <Card bordered={false} style={{ display: "flex", flex: 1, height: "100%" }} bodyStyle={{ display: "flex", flexDirection: "column", width: "100%", overflowY: "auto" }}>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                                <span className='fontH2 black' style={{ fontWeight: "bold", flex: 1 }}>{screenerFinalData[isOpen]?.sub_category[isV1Open].metric[isV2Open].metric_name}</span>
                                <button className="textSM btnPrimary" onClick={() => {
                                    var upDatedFilters = [...dataTableColumns]
                                    var isPresentIndex = upDatedFilters.findIndex((met) => met.dataIndex === screenerFinalData[isOpen]?.sub_category[isV1Open].metric[isV2Open].metric_col_code)
                                    if (isPresentIndex === -1) {
                                        upDatedFilters.push({ ...screenerFinalData[isOpen]?.sub_category[isV1Open].metric[isV2Open] })
                                        setmodal(!modal)
                                        setDataTableColumns(upDatedFilters)
                                        // onChangeDataColumns(upDatedFilters)
                                    } else {
                                        alert("Metric already added")
                                    }
                                }}>Add column</button>
                            </div>
                            <span className='textSM mt-3'>{screenerFinalData[isOpen]?.sub_category[isV1Open].metric[isV2Open].metric_desc}</span>
                        </Card>
                    </Col>
                    <Col id='metricCol3' className="p-1 " style={{}}>
                        <Card bordered={false} style={{ display: "flex", flex: 1, height: "100%" }} bodyStyle={{ display: "flex", flexDirection: "column", width: "100%", overflowY: "auto" }}>
                            <div className="fontH2 w-500 mb-2">Currently selected columns</div>
                            <AppliedFilterView />
                        </Card>
                    </Col>
                </Row>
            </Drawer>

            <Drawer
                width={"80vw"}
                title={<span className='fontH1 black m-1 mb-5' style={{ fontWeight: "bold" }}>Bubble charts</span>} placement="right"
                onClose={() => {
                    setShowBubbleChart(!showBubbleChart)
                }}
                open={showBubbleChart}
                footerStyle={{}}
                footer={false}>
                <div style={{ display: "flex", flexDirection: "row", height: "calc(89vh)", gap: "0.2rem" }}>
                    <Col style={{ position: "relative", height: "100%", flex: 1 }}>
                        <Card bordered={false} style={{ padding: 0 }} bodyStyle={{ padding: 0 }}>
                            <div style={{ display: "flex", alignItems: "center", border: "0.5px solid #e5e5e5", borderTopLeftRadius: 5, borderTopRightRadius: 5, backgroundColor: "#fafafa", padding: "0.6rem 1.5rem" }}>
                                <button className={bubbleChartType === 1 ? "mx-1 btnPrimary" : "mx-1 btnSecondary"} onClick={() => setbubbleChartType(1)}>
                                    Quality v/s Value
                                </button>
                                <button className={bubbleChartType === 2 ? "mx-1 btnPrimary" : "mx-1 btnSecondary"} onClick={() => setbubbleChartType(2)}>
                                    Momentum v/s Value
                                </button>
                                <button className={bubbleChartType === 3 ? "mx-1 btnPrimary" : "mx-1 btnSecondary"} onClick={() => setbubbleChartType(3)}>
                                    Quality v/s Momentum
                                </button>
                            </div>
                            <BubbleChart />
                        </Card>
                    </Col>
                    <Col sm={5} style={{ position: "relative", height: "100%", overflowY: "scroll" }}>
                        <Table
                            rowSelection={showBubbleChart ? false : rowSelection}
                            pagination={{
                                defaultPageSize: paginationSize,
                                onChange: (page, pageSize) => {
                                    setPageData({
                                        page: page,
                                        pageSize: pageSize
                                    })
                                }
                            }}
                            defaultSortOrder='descend'
                            style={{
                                // backgroundColor: 'var(--backgroundCard) !important'
                            }}
                            size='small'
                            className='textXS black'
                            dataSource={finalList}
                            columns={dataTableColumns.filter((i) => i.hidden !== true && chartKeys.includes(i.dataIndex)).map((i) => {
                                return {
                                    ...i,
                                    sorter: false
                                }
                            })}
                            rowKey={(record) => record.plan_id || record.symbol}
                            scroll={{ x: "max-content" }}
                        />
                    </Col>
                </div>
            </Drawer>

            {/* <Modal
                title={<span className='fontH1 black m-1 mb-5' style={{ fontWeight: "bold" }}>{"Customise table"}</span>}
                centered
                open={modal}
                okButtonProps={{
                    className: "btnPrimary",
                    hidden: true
                }}
                cancelButtonProps={{
                    className: "btnSecondary",
                    hidden: true
                }}
                onCancel={() => setmodal(!modal)}
                width={"80vw"}

                zIndex={1000}

                style={{ top: -70 }}
                cancelText={"Close"}
            >




            </Modal> */}
        </React.Fragment >
    );
};

export default CustomListTable;
