import { Card } from 'antd';
import { getUser, useGetAssetType } from 'Libs/utils';
import React from 'react';
import { useLocation, useNavigate } from 'react-router';

export default function Profile(props) {

  const type = useGetAssetType()
  const loc = useLocation()
  const [user, setUser] = React.useState(undefined)
  const navigate = useNavigate();
  const loadUserData = async () => {
    const user = await getUser()
    setUser(user)
    console.log(user?.Client[0]?.SharpelyAccessLevel)
  }
  React.useEffect(() => {
    loadUserData()
  }, [loc])
  return (
    <div style={{ display: "flex", flex: 1, justifyContent: "center" }}>
      <Card bordered={false}  style={{ minWidth: "30vw", padding: "1.5rem" }}>
        {user !== undefined ? JSON.stringify(user) : <></>}
      </Card>
    </div>
  )
}

