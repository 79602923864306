import { Empty, Input } from "antd";
import React, { useEffect, useState } from "react";
import { BsSearch } from "react-icons/bs";
import { fetchFundData } from 'Libs/fetchFundData';
import AMCIcon from "Components/AMCIcon";
import MintboxIcon from "Components/MintboxIcon";
import { v4 } from 'uuid';
import checkmark from '../../Assets/icons/checkmark.png';
import { Checkbox } from "./Checkbox";

export const InstrumentListSearch = ({
    placeholder = '', type = 1, isMultiple = false, selectedValues = null, onChange = () => { }, openOnFocus = true, align = 'left'
}) => {

    const [searchText, setSearchText] = useState('');
    const [mainData, setMainData] = useState([]);
    const [searchData, setSearchData] = useState([]);
    const [selectedData, setSelectedData] = useState([]);
    const [showList, setShowList] = useState(false);
    const [uniqueId] = useState('su' + v4());

    const onSearch = (e) => {
        let text = e.target.value?.split(' ');
        setShowList(true)
        if (e?.target?.value?.length > 0) {
            if (type === 3) {
                let newData = mainData.filter(function (listItem) {
                    return text.every(function (el) {
                        return listItem.MintboxName.toLowerCase().indexOf(el.toLowerCase()) > -1;
                    });
                });
                setSearchData(newData);
                // fetchData(newData, text)
            } else if (type === 1) {
                let newData = mainData.filter(function (listItem) {
                    return text.every(function (el) {
                        return listItem.basic_name.toLowerCase().indexOf(el.toLowerCase()) > -1
                    });
                });
                setSearchData(newData);
                // fetchData(newData, text)
            } else if (type === 11) {
                let newData = mainData.filter(function (listItem) {
                    return text.every(function (el) {
                        return listItem.benchmark_name.toLowerCase().indexOf(el.toLowerCase()) > -1
                    });
                });
                setSearchData(newData);
                // fetchData(newData, text)
            } else if (type === 4) {
                let newData = mainData.filter(function (listItem) {
                    return text.every(function (el) {
                        return listItem.proper_name.toLowerCase().indexOf(el.toLowerCase()) > -1 || listItem.symbol.toLowerCase().indexOf(el.toLowerCase()) > -1;
                    });
                });
                setSearchData(newData);
                // fetchData(newData, text)
            } else {
                let newData = mainData.filter(function (listItem) {
                    return text.every(function (el) {
                        return listItem.basic_name.toLowerCase().indexOf(el.toLowerCase()) > -1;
                    });
                });
                setSearchData(newData);
                // fetchData(newData, text)
            }
        } else {
            setSearchData(mainData);
        }
        setSearchText(e.target.value);
    }

    const loadData = async () => {
        let res = await fetchFundData(type, false);
        if (res.length) {
            setMainData(res);
            setSearchData(res);
        }
    }

    const onSelect = (item = {}) => {
        if (!isMultiple) {
            setSelectedData([item]);
            onChange([item]);
            setShowList(false);
            return;
        }
        let temp = [...selectedData];
        if (type === 1 || type === 2) {
            temp = temp.filter((ele) => ele.plan_id === item.plan_id);
        }
        else if (type === 3) {
            temp = temp.filter((ele) => ele.UID === item.UID);
        }
        else if (type === 4) {
            temp = temp.filter((ele) => ele.symbol === item.symbol);
        }
        else {
            temp = temp.filter((ele) => ele.benchmark_id === item.benchmark_id);
        }
        if (!temp?.length) {
            setSelectedData([...selectedData, item]);
        }
        else {
            setSelectedData(selectedData?.filter(ele => (
                Object.keys(ele).join(' ') + Object.values(ele).join(' ') !== Object.keys(item).join(' ') + Object.values(item).join(' ')
            )))
        }
    }

    const handleDocumentClick = (e) => {
        let target = document.getElementById(`${uniqueId}`);
        let currTarget = e.target
        do {
            if (target === currTarget || currTarget.className === 'insListItemSelected') {
                return;
            }
            currTarget = currTarget.parentNode
        }
        while (currTarget) {
            setShowList(false)
        }
    }

    const onCompare = () => {
        if (selectedData.length > 0) onChange(selectedData);
        setShowList(false)
    }

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        setSelectedData(selectedValues || []);
    }, [selectedValues])

    useEffect(() => {
        document.addEventListener('click', handleDocumentClick);
        return () => {
            document.removeEventListener('click', handleDocumentClick);
        }
    }, []);

    return (
        <React.Fragment>
            <div className='insListSearch' id={uniqueId}>
                <Input prefix={<BsSearch className='dark2 textSM' style={{ marginRight: "0.3rem" }} />}
                    style={{ height: "2.5rem", border: "1px solid var(--grey2)", borderRadius: "var(--borderRadius)", background: "var(--grey3)" }}
                    className='insListSearchInput dark2 textXS'
                    placeholder={placeholder} value={searchText} onInput={onSearch}
                    onFocus={() => { setShowList(true) }} />
                {showList && (openOnFocus ? true : searchText?.length > 0) && <div className={`insListContainer ${align}`} >
                    <div className='insListContent'>
                        {/* {selectedData?.map((item, i) => {
                            let key = type === 1 || type === 2 ? item.plan_id : type === 3 ? item.UID : type === 4 ? item.symbol : item.benchmark_id
                            return (
                                <div key={i + 1} onClick={!isMultiple ? () => onSelect(item) : () => { onSelect(item) }}
                                    className={"insListItemSelected"}>
                                    <div>
                                        {isMultiple && <Checkbox onChange={isMultiple ? () => onSelect(item) : () => { }}
                                            checked={true}
                                        />}
                                        <span style={{ cursor: "pointer" }}>
                                            {type === 1 || type === 2 ? <AMCIcon height='1.25rem' width='1.25rem' amcName={item.amc_full_name} type='circle' />
                                                : type === 3 ? <MintboxIcon height='1.25rem' width='1.25rem'
                                                    id={item.MintboxId || Number(item.FundCode) || item.UID || item.mintbox_id || item.inv_MintboxId} />
                                                    : type === 4 ? <AMCIcon amcName={item.symbol} fundType={type} height='1.25rem'
                                                        width='1.25rem' type='circle' /> : <></>
                                            }
                                        </span>
                                        <div className='textMD dark4' style={{ flex: 1, whiteSpace: 'initial', cursor: "pointer" }}>
                                            {type === 3 ? item.MintboxName : type === 4 ? item.proper_name : type === 11 ? item.benchmark_name :
                                                item.basic_name}
                                        </div>
                                    </div>
                                    {!isMultiple && <div>
                                        <img src={checkmark} />
                                    </div>}
                                </div>
                            )
                        })} */}
                        {searchData?.map((item, i) => {
                            let isSelected = type === 1 || type === 2 ? selectedData?.filter(ele => ele.plan_id === item.plan_id)?.length > 0 :
                                type === 3 ? selectedData?.filter(ele => ele.UID === item.UID)?.length > 0 : type === 4 ?
                                    selectedData?.filter(ele => ele.symbol === item.symbol)?.length > 0 : type === 11 ?
                                        selectedData?.filter(ele => ele.benchmark_id === item.benchmark_id)?.length > 0 : false;
                            let key = type === 1 || type === 2 ? item.plan_id : type === 3 ? item.UID : type === 4 ? item.symbol : item.benchmark_id
                            return (
                                <div key={key} onClick={() => onSelect(item)}
                                    className={isSelected ? "insListItemSelected" : ""}>
                                    <div>
                                        {isMultiple && <Checkbox onChange={() => onSelect(item)}
                                            checked={isSelected}
                                        />}
                                        <span style={{ cursor: "pointer", marginLeft: "0.5rem" }}>
                                            {type === 1 || type === 2 ? <AMCIcon height='1.25rem' width='1.25rem' amcName={item.amc_full_name} type='circle' />
                                                : type === 3 ? <MintboxIcon height='1.25rem' width='1.25rem'
                                                    id={item.MintboxId || Number(item.FundCode) || item.UID || item.mintbox_id || item.inv_MintboxId} />
                                                    : type === 4 ? <AMCIcon amcName={item.symbol} fundType={type} height='1.25rem'
                                                        width='1.25rem' type='circle' /> : <></>
                                            }
                                        </span>
                                        <div className='textSM dark4' style={{
                                            flex: 1, whiteSpace: 'initial', cursor: "pointer",
                                            height: "fit-content"
                                        }}>
                                            {type === 3 ? item.MintboxName : type === 4 ? item.proper_name : type === 11 ? item.benchmark_name :
                                                item.basic_name?.replace(" - Direct Plan", "")}
                                        </div>
                                    </div>
                                    {!isMultiple && <div>
                                        {isSelected ? <img src={checkmark} /> : ""}
                                    </div>}
                                </div>
                            )
                        })}
                    </div>
                    {searchData.length > 0 && isMultiple && <div className='insListFooter'>
                        <button className="btnPrimary" onClick={onCompare}>
                            Compare
                        </button>
                    </div>}
                    {!searchData?.length ? (
                        <div className='d-flex align-items-center justify-content-center' style={{ padding: "0.5rem" }}>
                            <Empty />
                        </div>
                    ) : null}
                </div>}
            </div>
        </React.Fragment >
    )
}