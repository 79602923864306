import { createSlice } from '@reduxjs/toolkit'

export const userDataSlice = createSlice({
  name: 'userData',
  initialState: {
    value: undefined,
  },
  reducers: {
    updateUserData: (state, action) => {
      state.value = action.payload
    },
  },
})

export const loginModal = createSlice({
  name: 'loginModal',
  initialState: {
    value: false,
  },
  reducers: {
    toggleloginModal: (state, action) => {
      state.value = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { updateUserData } = userDataSlice.actions
export const { toggleloginModal } = loginModal.actions