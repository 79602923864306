import { Badge, Button, Card, Divider } from "antd";
import ActivityLoaderMintbox from "Components/ActivityLoaderMintbox";
import AMCIcon from "Components/AMCIcon";
import PerformanceChartCompare from "Components/PerformanceChartCompare";
import SearchScreen from "Components/SearchScreen";
import React from "react";
import { Col, Collapse, Modal, Row } from 'reactstrap';

import Head from "Layout/Head";
import { screenerMetricData, useGetAssetType } from "Libs/utils";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { MdCancel, MdDelete } from "react-icons/md";
import { useLocation, useNavigate } from "react-router";
import apiHelperPY from "Services/api/ApiHelperPY";
import { CardHeader } from "Components/atoms/SmallComponents";
import NewFundCard from "Components/NewFundCard";
import { AiOutlineCloseCircle, AiOutlinePlusCircle } from "react-icons/ai";
import InstrumentListTable from "Components/InstrumentListTable";
import { InstrumentListSearch } from "Components/CustomComponents/InstrumentListSearch";



const Compare = (props) => {

    const fundColors = ['#4284f3', '#fabc05', '#24c1e0', '#fa7b16', 'var(--yellow)', 'var(--green)', 'var(--red)']
    const type = useGetAssetType()// 1 = etf, 2 = mf, 4 = stocks
    const { state } = useLocation()
    const [stocks, setStocks] = React.useState([])
    const [allStocks, setAllStocks] = React.useState([])
    const [popularStocks, setPopularStocks] = React.useState([])
    const [screenerMetric, setScreenerMetric] = React.useState([])
    const [loading, setLoading] = React.useState(true)
    const [finalData, setFinalData] = React.useState([])
    const [keys, setKeys] = React.useState([])
    const [chartData, setChartData] = React.useState([])

    const navigate = useNavigate()
    const dataTableColumns = type.value === 4 ? [
        {
            "title": "Name",
            "dataIndex": "proper_name",
            render: (text, record, index) => <div style={{ display: 'flex', gap: '12px', cursor: "pointer" }} onClick={() => navigate({ pathname: type.basePath + `${type.value === 4 ? '/stock-detail/' + record.symbol : '/fund-detail/' + record?.basic_name?.replace(" - Direct Plan", "")?.replaceAll(' ', '-') + '?code=' + record.plan_id}` })}>
                <AMCIcon amcName={record.symbol} fundType={type.value} height='1.2rem' width="1.2rem" />
                <div>
                    <div className="textSM black">{record?.proper_name}</div>
                    {/* <div className="text-left textXS ">{stockSectorCLF?.find(s => s.industry_code === record.industry_code)?.industry_desc}</div> */}
                </div>
            </div>,
            width: 230,
            fixed: 'left',
            show: true
        }
    ]
        :
        [
            {
                "title": "Name",
                "dataIndex": "basic_name",
                render: (text, record, index) => <div style={{ display: 'flex', gap: '12px', cursor: "pointer" }} onClick={() => navigate({ pathname: type.basePath + `${type.value === 4 ? '/stock-detail/' + record.symbol : '/fund-detail/' + record?.basic_name?.replace(" - Direct Plan", "")?.replaceAll(' ', '-') + '?code=' + record.plan_id}` })}>
                    <AMCIcon amcName={record.amc_full_name} height='1.2rem' width="1.2rem" />
                    <div>
                        <div className="textSM black">{record?.basic_name?.replace(" - Direct Plan", "")}</div>
                        <div className="text-left textXS ">{record?.category_name}</div>
                    </div>
                </div>,
                width: 230,
                fixed: 'left',
                show: true
            }
        ]

    React.useEffect(() => {
        const dum = async () => {
            setLoading(true)
            setStocks(state?.stocks || [])
            var screener_datas = await screenerMetricData(type)

            setScreenerMetric(screener_datas?.screenerMetricData)
            setFinalData(screener_datas?.screenerFinalData)
            setAllStocks(screener_datas?.allFunds)
            setPopularStocks(screener_datas?.allFunds?.filter(x => !stocks.some(y => y.isin_code === x.isin_code)).sort((a, b) => a['21d_vol'] - b['21d_vol']).slice(0, 10))
            setLoading(false)
        }
        dum();
    }, [])

    React.useEffect(() => {
        getCompData()
    }, [stocks, finalData])

    React.useEffect(() => {
        console.log("ak sdkajkd", stocks)
        setPopularStocks(allStocks?.filter(x => !stocks.some(y => y.isin_code === x.isin_code)).sort((a, b) => a['21d_vol'] - b['21d_vol']).slice(0, 10))
    }, [stocks])

    const getCompData = async () => {
        if (stocks.length >= 2) {
            setLoading(true)
            // setKeys(finalData.map(section => {
            //     var metrics = []
            //     section.sub_category.map(s => {
            //         metrics = metrics.concat(s.metric)
            //     })
            //     return {
            //         key: section.category_id,
            //         header: section.category_name,
            //         children: metrics.map(sub => {
            //             const dataArr = stocks.map(o => o?.[sub.metric_col_code]).filter(a => a)
            //             const max = sub.direction_flag === 'bot' ? Math.min(...dataArr) : Math.max(...dataArr)
            //             return {
            //                 key: sub.metric_id,
            //                 labelHtml: (fund) => { return <th className="center-label black">{sub.metric_name}</th> },
            //                 valueHtml: (stock, index) => {
            //                     return stock ? <td className={`black fund${index}`}>
            //                         {
            //                             sub.metric_data_type === 'float' && stock[sub.metric_col_code] === max ?
            //                                 <Badge.Ribbon size={'small'} text={'best'} style={{ top: 2, color: 'var(--green)', backgroundColor: 'var(--green)' }}>
            //                                     <Card bordered={false} hoverable style={{ borderRadius: 'var(--borderRadius)' }} bodyStyle={{ padding: 0 }} size="small" className={'black compareBestValue'}>{sub.render(stock[sub.metric_col_code])}</Card>
            //                                 </Badge.Ribbon>
            //                                 :
            //                                 <div>{sub.render(stock[sub.metric_col_code])}</div>
            //                         }
            //                     </td>
            //                         : <></>
            //                 },
            //                 show: !sub.hidden && dataArr.length
            //             }
            //         })
            //     }
            // }))
            await getChartData(stocks.map(s => type.value === 4 ? s.symbol : s.isin_code))
            setLoading(false)
        }
    }

    const getChartData = async (isins) => {
        let res0 = await apiHelperPY({ apiName: 'getFundsComparisionData', data: { type: type.value, data: { isins: isins } }, saveResponse: '' })
        if (res0) {
            console.log("getFundsComparisionData", res0)
            setChartData(res0)
        }
        else {
            // setApiError(true)
            setChartData([])
        }
    }
    const onDeleteFund = (index) => {
        setStocks(stocks?.filter((f, i) => i !== index))
    }

    const addToCompare = (f) => {
        // console.log(f)
        // if (stocks?.length < 4) {
        if (stocks.some(s => type.value === 4 ? s.symbol === f.symbol : s.isin_code === f.isin_code)) {
            // already exists
        } else {
            setStocks([...stocks, f])
        }
        // }
    }
    return (
        <React.Fragment>
            <Head title={"Compare"} />
            <div className="normalContainer">
                <div className="mainContainer">
                    <div className="rightContainer">
                        <Card style={{}}>
                            <CardHeader heading={`Compare ${type.dname}`} />
                            <Divider style={{ margin: "0.75rem 0rem" }} />
                            <InstrumentListSearch placeholder={`Search and add ${type.dname}`} type={type.value} isMultiple={true} selectedValues={stocks} onChange={(x) => setStocks(x)} />
                            {
                                stocks.length ?
                                    <>
                                        <Divider style={{ margin: "0.75rem 0 0 0" }} />
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            {
                                                stocks.map((f, index) => {
                                                    return <>
                                                        <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center', width: '100%', padding: '1rem 0', borderBottom: '1px solid var(--grey2)' }}>
                                                            {/* <AMCIcon amcName={type.value === 4 ? f.symbol : f.amc_full_name} fundType={type.value} height='1.15rem' width="1.15rem" /> */}
                                                            <div style={{ height: '0.6rem', width: '0.6rem', backgroundColor: fundColors[index] }}></div>
                                                            <div className="textXS black" style={{ flex: 1 }}>{type.value === 4 ? f.proper_name : f.basic_name}</div>
                                                            <AiOutlineCloseCircle size={'1.25rem'} style={{ marginLeft: '1rem' }} onClick={() => onDeleteFund(index)} />
                                                        </div>
                                                    </>
                                                })
                                            }
                                        </div>
                                    </>
                                    :
                                    <></>
                            }
                        </Card>
                        {
                            stocks.length >= 2 ?
                                <Card style={{ height: '100%' }}>
                                    <CardHeader heading={`Popular ${type.dname}`} />
                                    <Divider style={{ margin: "0.75rem 0rem" }} />
                                    {
                                        popularStocks.length ?
                                            <>
                                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                    {
                                                        popularStocks.map((f, index) => {
                                                            return <>
                                                                <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center', width: '100%', padding: '1rem 0', borderBottom: '1px solid var(--grey2)' }}>
                                                                    <AMCIcon amcName={type.value === 4 ? f.symbol : f.amc_full_name} fundType={type.value} height='1.15rem' width="1.15rem" />
                                                                    {/* <div style={{ height: '0.6rem', width: '0.6rem', backgroundColor: fundColors[index] }}></div> */}
                                                                    <div className="textXS black" style={{ flex: 1 }}>{type.value === 4 ? f.proper_name : f.basic_name}</div>
                                                                    <AiOutlinePlusCircle size={'1.25rem'} style={{ marginLeft: '1rem' }} onClick={() => addToCompare(f)} />
                                                                </div>
                                                            </>
                                                        })
                                                    }
                                                </div>
                                            </>
                                            :
                                            <></>
                                    }
                                </Card>
                                :
                                <></>
                        }
                    </div>
                    <div className="leftContainer">
                        {
                            stocks.length >= 2 ?
                                <>
                                    <Card>
                                        {/* <CardHeader heading={'Performance comparision'} /> */}
                                        {
                                            type.value === 4 ?
                                                <PerformanceChartCompare
                                                    type={type.value}
                                                    fundColors={fundColors}
                                                    fundNames={stocks?.map((f) => {
                                                        var ob = {}
                                                        ob[f.symbol] = f.proper_name
                                                        return ob
                                                    })}
                                                    isin_code={stocks?.map((f) => { return f.symbol })}
                                                    chartData={chartData?.prices} benchName={"df"} />
                                                :
                                                <PerformanceChartCompare
                                                    fundColors={fundColors}
                                                    fundNames={stocks?.map((f) => {
                                                        var ob = {}
                                                        ob["fund_" + f.plan_id] = f.basic_name.replace(" - Direct Plan", "")
                                                        return ob
                                                    })}
                                                    isin_code={stocks.map((f) => { return f.plan_id })}
                                                    chartData={chartData?.prices} benchName={"df"} />
                                        }
                                    </Card>
                                </>
                                :
                                <Card>
                                    <CardHeader heading={`Popular ${type.dname}`} />
                                    <div style={{ display: 'flex', gap: '1.25rem', overflowX: 'scroll', marginTop: '1rem', alignItems: 'stretch' }}>
                                        {
                                            type.value === 4 ?
                                                allStocks.filter(x => stocks.some(y => y.symbol !== x.symbol)).sort((a, b) => a['21d_vol'] - b['21d_vol']).map(f => {
                                                    return <NewFundCard f={f} type={type} onClick={() => addToCompare(f)} />
                                                })
                                                :
                                                popularStocks.map(f => {
                                                    return <NewFundCard f={f} type={type} onClick={() => addToCompare(f)} />
                                                })
                                        }
                                    </div>
                                </Card>
                        }

                        {
                            stocks.length >= 2 ?
                                <InstrumentListTable
                                    finalList={stocks}
                                    tableType={5}
                                    hideCheckBox={true}
                                    initialColumns={[
                                        ...dataTableColumns,
                                        ...screenerMetric.filter((item) => item.isInitial === true),
                                    ]}
                                    tableHeader={'Detailed comparision'}

                                />
                                :
                                <></>
                        }
                    </div>
                </div>
            </div>
            <Modal isOpen={loading} className="loadingModal" style={{ border: 'none' }}>
                <ActivityLoaderMintbox margin='30%' />
            </Modal>
        </React.Fragment >
    );
};

export default Compare;