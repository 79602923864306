import { Card } from 'antd';
import ScrollToTop from 'Components/atoms/ScrollToTop';
import LoginSignupModal from 'Components/LoginSignupModal';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { updateStockPrices } from 'Libs/redux/StockLivePrice/reduxSlice';
import { updatePrevStockPrices } from 'Libs/redux/StocksPrevPrices/reduxSlice';
import { getStockPrevPrices } from 'Libs/utils';
import React from "react";
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import './App.scss';
import Layout from './Layout/Index';

export const globalStateContext = React.createContext(null);


function App() {

  // document.onkeydown = function (e) {
  //   if (e.keyCode == 123) {
  //     return false;
  //   }
  //   if (e.ctrlKey && e.shiftKey && e.keyCode == 'I'.charCodeAt(0)) {
  //     return false;
  //   }
  //   if (e.ctrlKey && e.shiftKey && e.keyCode == 'J'.charCodeAt(0)) {
  //     return false;
  //   }
  //   if (e.ctrlKey && e.keyCode == 'U'.charCodeAt(0)) {
  //     return false;
  //   }
  // }
  Card.defaultProps = {
    bordered: false
  }
  const dispatch = useDispatch();

  var themeNM = useSelector((state) => state?.changeTheme?.value)
  document.documentElement.setAttribute("data-theme", themeNM)

  const [livedata, setliveData] = React.useState([])

  const socketData = async () => {
    const socket = new WebSocket("wss://api.mintbox.ai/wss/live_prices");

    socket.onopen = async function (e) {
      console.log("opn")
      var res = await getStockPrevPrices().then((r) => r)
      // console.log(res)
      dispatch(updatePrevStockPrices(res))
    };

    socket.onmessage = async function (event) {
      // console.log(JSON.parse(event.data))
      console.log("msf")

      if (event.data.length > 0) {
        dispatch(updateStockPrices(JSON.parse(event.data)))
      }
    };

    socket.onclose = async function (event) {
      console.log(event)
    };

    socket.onerror = function (error) {
      console.log("er")
    };

  }


  React.useEffect(() => {
    socketData()
  }, [])

  Highcharts.setOptions({
    global: {
      useUTC: false,
    },
    lang: {
      decimalPoint: '.',
      thousandsSep: ','
    }
  })
  return (
    <React.Fragment>
      <ScrollToTop />
      <Layout />
    </React.Fragment>

  );
}

export default App;
