import { Card, Collapse } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { CardHeader } from './atoms/SmallComponents';
import { DownOutlined } from '@ant-design/icons';

const BlogsAndFaqs = ({ flexGrowEqual = true }) => {
    return (
        <React.Fragment>
            <div className='mainContainer'>
                <div className='normalContainer'>
                    <Card bordered={false} bodyStyle={{ padding: "1.25rem" }}
                        style={{ height: "fit-content", borderRadius: "0.5rem" }}>
                        <div className='d-flex align-items-center justify-content-between margin20Bottom'>
                            <CardHeader heading={<span className='textLG w-700 black'>Blogs</span>} />
                            <Link to='/blogs' className='textSM w-500' style={{ color: "var(--primary)" }}>
                                See all
                            </Link>
                        </div>
                        <div className='d-flex flex-column flex-grow-1' style={{ gap: "1.25rem" }}>
                            {blogs?.map((item) => (
                                <div className='mainContainer align-items-center' style={{ gap: "1rem" }}>
                                    <div style={{
                                        width: "5rem", height: "5rem", background: getRandomColor(),
                                        borderRadius: "var(--borderRadius)", flexShrink: 0
                                    }}>
                                    </div>
                                    <div className='d-flex flex-column align-items-start' style={{ gap: "0.375rem" }}>
                                        <div className='textSM w-700 black'>
                                            {item.title}
                                        </div>
                                        <div className='textSM dark2'>
                                            {item.createdon}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </Card>
                </div>
                <div className='normalContainer' style={{ flex: flexGrowEqual ? 1 : 1.83 }}>
                    <Card bordered={false} bodyStyle={{ padding: "1.25rem" }} style={{ borderRadius: "0.5rem" }}>
                        <div className='mb-1'>
                            <CardHeader heading={<span className='textLG w-700 black'>FAQS</span>} />
                        </div>
                        <Collapse expandIcon={({ isActive }) => <DownOutlined rotate={isActive ? 180 : 0} className='textSM' />} size='small'
                            bordered={false} expandIconPosition={'end'} style={{ background: "var(--white)" }} className='blogAndFaqs'>
                            {faqs?.map((item, i) => (
                                <Collapse.Panel key={i + 1} header={<span className='textSM w-700 black'>{item.header}</span>}
                                    style={{ borderBottom: "1px solid var(--grey3)", padding: "0px" }}>
                                    <span className='textSM dark2'>
                                        {item.content}
                                    </span>
                                </Collapse.Panel>
                            ))}
                        </Collapse>
                    </Card>
                </div>
            </div>
        </React.Fragment>
    )
}

export default BlogsAndFaqs;

const faqs = [
    {
        header: "What are US ETFs and how do they work?",
        content: "Lorem Ipsum Printing content default"
    },
    {
        header: "What are US ETFs and how do they work?",
        content: "Lorem Ipsum Printing content default"
    },
    {
        header: "What are US ETFs and how do they work?",
        content: "Lorem Ipsum Printing content default"
    },
    {
        header: "What are US ETFs and how do they work?",
        content: "Lorem Ipsum Printing content default"
    },
    {
        header: "What are US ETFs and how do they work?",
        content: "Lorem Ipsum Printing content default"
    }
]

const blogs = [
    {
        title: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        createdon: "Nov 15, 2021",
    },
    {
        title: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        createdon: "Nov 15, 2021",
    },
    {
        title: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        createdon: "Nov 15, 2021",
    }
]

function getRandomColor() {
    let color = "hsl(" + Math.random() * 360 + ", 100%, 75%)";
    return color;
}