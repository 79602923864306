import { Card } from "antd";
import { useNavigate } from "react-router";
import { StockLivePrice } from "Services/api/LivePricesWebSocket";
import AMCIcon from "./AMCIcon";
import { CurrencyFormat } from "./CurrencyFormat";
import { DecimalValueFormat } from "./DecimalValueFormat";
import Slider52Week from "./Slider52Week";

const NewFundCard = ({ f, type, onClick }) => {
    const navigate = useNavigate()
    return <Card bodyStyle={{ cursor: 'pointer', backgroundColor: 'var(--blue2)', minWidth: '15.625rem', height: '100%', borderRadius: 'var(--borderRadius)' }}
        onClick={onClick}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '0.688rem', justifyContent: 'space-between', height: '100%' }}>
            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', gap: '0.688rem' }}>
                <AMCIcon amcName={type.value === 4 ? f.symbol : f.amc_full_name} fundType={type.value} height='3.125rem' width="3.125rem" />
                <div style={{ marginTop: '0.688rem', textAlign: 'center' }} className="textXS w-500 black">{type.value === 4 ? f.proper_name : f.basic_name}</div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', gap: '0.688rem' }}>
                <Slider52Week high={250} low={120} value={100} />

                {
                    type.value === 1 || type.value === 4 ?
                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                            <div className="textXS w-500 black" style={{ whiteSpace: 'nowrap' }}>Market price</div>
                            <StockLivePrice symbol={f.nse_symbol || f.symbol} format={3} />
                        </div>
                        :
                        type.value === 2 ?
                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                <div className="textXS w-500 black" style={{ whiteSpace: 'nowrap' }}>NAV</div>
                                <div className="textSM w-500 black">{CurrencyFormat(f?.latest_nav, "long", 1)}</div>

                            </div>
                            :
                            <></>
                }
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <div className="textXS w-500 black" style={{ whiteSpace: 'nowrap' }}>P/E ratio</div>
                    <div className="textSM w-500 black">{DecimalValueFormat({ num: type.value === 4 ? f.pe_ttm : f.pe, decimals: 1 })}</div>
                </div>
            </div>
        </div>
    </Card>
}

export default NewFundCard;