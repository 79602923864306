import { Card, Popover } from "antd";

const CustomLabelValueCards = ({ x }) => {
    return <div style={{ display: 'flex', justifyContent: 'stretch', gap: '0.688rem', flexWrap: 'wrap' }}>
        {x.map(p => {
            if (p.show === false) {
                return <></>
            }
            else return (
                <Card bordered={false} key={p.key} style={{ minWidth: '13rem', borderRadius: '0.5rem', border: 'none', boxShadow: 'none' }} bodyStyle={{ padding: '0.875rem 1.5rem', border: '1px solid var(--grey2)', borderRadius: '0.5rem' }}>
                    <div className="textSM dark2" style={{ marginBottom: '0.5rem' }}>{p.heading}</div>
                    <div className="textMD w-700 dark4">{p.value}</div>
                </Card>
            )
        })}
    </div>
}

export default CustomLabelValueCards;