import { Skeleton, Table } from "antd";
import Highcharts from 'highcharts';
import React from "react";

require('highcharts/highcharts-more')(Highcharts)

const LoadingTableSkeleton = ({ }) => {

    var dataTableColumns = [...Array(7)].map((i, index) => {
        return {
            "title": <Skeleton className="pt-1" title={true} paragraph={false} />,
            "dataIndex": index,
            render: (text, record, index) => <Skeleton className="pt-1" title={true} paragraph={false} />,
            width: index === 0 ? 230 : 150,
            show: true
        }
    })

    var items = dataTableColumns.map((i, index) => {
        return {
            [index]: <Skeleton key={index} className="pt-1" title={true} paragraph={false} />,
            id: index
        }
    })

    // console.log(dataTableColumns)
    return (
        <div style={{ position: "relative", display: "flex", flexDirection: "column" }}>
            <div className="my-2" style={{ display: "flex", flexDirection: "row", gap: "1.5rem" }}>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", flex: 1 }}>
                    <Skeleton style={{ width: "250px" }} className="pt-1" title={true} paragraph={false} />
                    <div className="my-2">
                        <Skeleton.Button className="pt-1" size="small" />
                        <Skeleton.Button className="pt-1 px-2" size="small" />
                        <Skeleton.Button className="pt-1" size="small" />
                    </div>
                </div>

            </div>
            <Table

                bordered={true}
                size='small'
                className='textXS black'
                dataSource={items}
                // locale={{
                //     emptyText: <Skeleton title={true} />
                // }}
                columns={dataTableColumns}
                rowKey={(record) => record.id}
                scroll={{ x: "max-content" }}
            />
        </div>
    );
};

export default LoadingTableSkeleton;
