import { Card, Divider, Image } from "antd";
import ActivityLoaderMintbox from "Components/ActivityLoaderMintbox";
import { getKnowledgeBaseData } from "Components/getKnowledgeBaseData";
import KnowledgeBaseCategories from "Components/KnowledgeBaseCategories";
import Head from "Layout/Head";
import React, { useEffect, useState } from "react";
import logo from '../../Assets/images/illustrations/kb2.svg';
import KnowledgeBaseSearch from "Components/KnowledgeBaseSearch";
import { FiMail } from "react-icons/fi";
import { SlCallIn } from 'react-icons/sl';
import BlogsAndFaqs from "Components/BlogsAndFaqs";
import { Dropdown, Dropdown2 } from "Components/CustomComponents/DropDown";
import { Tooltip } from "Components/CustomComponents/Tooltip";
import { InstrumentListSearch } from "Components/CustomComponents/InstrumentListSearch";
import { Checkbox } from "Components/CustomComponents/Checkbox";
import Slider52Week from "Components/Slider52Week";

const KnowledgeBase = () => {
    const [allCategories, setAllCategories] = useState([]);
    const [resources, setResources] = useState([]);
    const [loading, setLoading] = useState(true);

    const loadData = async () => {
        let res = await getKnowledgeBaseData();
        if (res.type) {
            setAllCategories(res.categories || []);
            setResources(res.blogs || []);
            setLoading(false)
        }
    }
    const [value, setValue] = useState([])
    useEffect(() => {
        setLoading(true)
        loadData();
    }, [])

    if (loading) {
        return (
            <div className='flex-grow-1 mt-5'>
                <ActivityLoaderMintbox />
            </div>
        )
    }
    return (
        <React.Fragment>
            <Head title='Knowledge Base' />
            <div className='kbMidContainer'>
                <Card className='margin20Bottom'>
                    {/* <Dropdown2 placeholder='Select ETFS' showSearch={false} allowClear={false} value={['NIFTY 50 Total Return Index']} 
                    isMultiple={true}
                    /> */}
                </Card>
                <Card bodyStyle={{ background: "var(--white)", borderRadius: "0.5rem", padding: "0px" }}
                    className='margin20Bottom' style={{ borderRadius: "0.5rem", border: "1px solid var(--grey2)" }}>
                    <div className='d-flex justify-content-between align-items-center' style={{ gap: "1.25rem" }}>
                        <div style={{ width: "31.5rem", paddingLeft: "2.6rem" }}>
                            <h2 className='displayLG black w-700' style={{ fontWeight: "700" }}>
                                Knowledge Base
                            </h2>
                            <div className='textMD dark3'>
                                A knowledge base is a digital repository of information and data that is organized and structured for easy access and retrieval.
                            </div>
                        </div>
                        <Image src={logo} preview={false} style={{ borderRadius: "0.5rem", height: "19rem", objectFit: "cover", width: "33rem" }} />
                    </div>
                </Card>
                <KnowledgeBaseCategories allCategories={allCategories} />
                <div className='margin20Bottom'>
                    <BlogsAndFaqs flexGrowEqual={false} />
                </div>
                <div className='d-flex align-items-center flex-column' style={{ gap: "2.25rem" }}>
                    <div className='d-flex align-items-center flex-column' style={{ gap: "1rem", textAlign: "center" }}>
                        <div className='displaySM black w-500' style={{ fontWeight: "500" }}>
                            You still have a question?
                        </div>
                        <div className='textMD dark2' style={{ width: "28.5rem", margin: "auto" }}>
                            If you cannot find answer to your question in our FAQ, you can
                            always contact us. We will answer you shortly!
                        </div>
                    </div>
                    <div className='mainContainer' style={{ gap: "1.6rem", width: "100%" }}>
                        <div className='normalContainer'>
                            <Card bodyStyle={{ height: "100%", padding: "2.5rem 0px", }}
                                style={{ height: "100%", border: "1px solid var(--grey1)", borderRadius: "0.5rem" }}>
                                <div className="d-flex align-items-center justify-content-center h100">
                                    <div className="d-flex flex-column align-items-center" style={{ gap: "1rem" }}>
                                        <div className='textSM dark2'>
                                            Do you like to raise a query? Fill the form
                                        </div>
                                        <div className='textSM' style={{
                                            background: "var(--blueShade)", color: "var(--primary)",
                                            padding: "0.75rem 1rem", borderRadius: "0.25rem", cursor: "pointer"
                                        }}>
                                            Form
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        </div>
                        <div className='normalContainer'>
                            <Card bodyStyle={{ height: "100%", padding: "2.5rem 0px", }}
                                style={{ height: "100%", border: "1px solid var(--grey1)", borderRadius: "0.5rem" }} >
                                <div className="d-flex align-items-center justify-content-center h100">
                                    <div className="d-flex flex-column align-items-center dark2" style={{ gap: "1rem" }}>
                                        <div className='textMD' >
                                            <SlCallIn className='textXL' />
                                            <span >
                                                &nbsp; + (856X) 093-6-49-X
                                            </span>
                                        </div>
                                        <div className='textMD'>
                                            <FiMail className='textLG' /> <span>
                                                support@helpcenter.com
                                            </span>
                                        </div>
                                        <div className='textSM'>
                                            The best way to get answer faster.
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        </div>
                    </div>
                </div>
            </div >
        </React.Fragment >
    )
}

export default KnowledgeBase;
